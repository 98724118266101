

export default {
    install(Vue){

        function apiRequest(url){

            const options = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify(
                    {
                        token: Vue.prototype.$auth.getToken(),
                    }
                )
            }

            return fetch(url,options)
                .then(response => response.json())

        }


        var api = {
            getAssessments: ()=>{
                return apiRequest("api/all_assessments_by_date");
            },


        }


        Vue.prototype.$api = api;
    }
}