


<template>
<div class="survey flex flex-col h-full relative">

  <div class="flex-grow relative flex">
    <div class="text-sm w-32 mt-8 ml-8">
      <link-list class="" :links="sections" :selection="currentSection" @change="setSection"></link-list>
    </div>
    <div class="flex-grow ml-8 border-l px-8 h-full overflow-y-scroll relative">
      <div>
        <component class="my-8 max-w-6xl" :is="currentSection.component" @goSection="goToSection" @tab="onTab" @questionnaire="onQuestionnaire"></component>
      </div>
    </div>
  </div>
  <div>


  </div>


</div>
</template>

<script>
import LinkList from "@/components/LinkList";
import PreCustomerProfile from "@/components/PreCustomerProfile";
import PreAssessmentTips from "@/components/PreAssessmentTips";
import PreSelfAssessment from "@/components/PreSelfAssessment";

import PreSelfScorecard from "@/components/PreSelfScorecard";
import PreWalkthroughAssessment from "@/components/PreWalkthroughAssessment";
import PreWalkthroughScorecard from "@/components/PreWalkthroughScorecard";



export default {
    name: "assessment-survey-panel",
  components: {
    PreWalkthroughScorecard,
    PreWalkthroughAssessment,
    PreSelfScorecard, PreSelfAssessment, PreAssessmentTips, LinkList, PreCustomerProfile},
  props: {

  },
    data: function(){
        return {
        }
        },
    methods: {

      onQuestionnaire: function(){
        this.$emit("questionnaire");
      },


      setSection: function(section){
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: this.$route.query.tab,
            section: section.id
          }
        })
      },

      goToSection: function(sectionId){
        this.setSection({ id: sectionId });
      },

      onTab: function(tab){
        this.$emit("tab", tab)
      }
    },
    computed: {
      mailSections: function(){
        return [
          { label: "Customer Profile", id: "profile", component: "PreCustomerProfile",
            instructions: "Enter customer information."
          },
          { label: "Walkthrough Assessment", id: "walkthrough", component: "PreWalkthroughAssessment",
            instructions: "The Certified Walkthrough Specialist will complete the Walkthrough Assessment based on additional information gathered during the walkthrough. Provide a score (1-5 or N/A) that most closely represents their operation. There is room in each section to provide unique notes based on your evaluation of the operation."
          },
          { label: "Walkthrough Scorecard", id: "walkthroughscore", component: "PreWalkthroughScorecard",
            instructions: ""
          },
        ]
      },

      mapSections: function(){
        return [
          { label: "Customer Profile", id: "profile", component: "PreCustomerProfile",
            instructions: "Enter customer information."
          },
          { label: "Self Assessment", id: "selfassessment", component: "PreSelfAssessment",
            instructions: "Ask the customer how they perform each of the activities and provide a score (1-5 or N/A) that most closely represents their answers. There is room in each section to provide unique notes based on your discussion with the customer."
          },
          { label: "Self Assessment Scorecard", id: "selfscore", component: "PreSelfScorecard",
            instructions: ""
          },
          { label: "Walkthrough Assessment", id: "walkthrough", component: "PreWalkthroughAssessment",
            instructions: "The Certified Walkthrough Specialist will complete the Walkthrough Assessment based on additional information gathered during the walkthrough. Provide a score (1-5 or N/A) that most closely represents their operation. There is room in each section to provide unique notes based on your evaluation of the operation."
          },
          { label: "Walkthrough Scorecard", id: "walkthroughscore", component: "PreWalkthroughScorecard",
            instructions: ""
          },
        ]
      },

      pmpSections: function(){
        return [
          { label: "Customer Profile", id: "profile", component: "PreCustomerProfile",
            instructions: "Enter customer information."
          },
          { label: "Self Assessment", id: "selfassessment", component: "PreSelfAssessment",
            instructions: "Ask the customer how they perform each of the activities and provide a score (1-5 or N/A) that most closely represents their answers. There is room in each section to provide unique notes based on your discussion with the customer."
          },
          { label: "Self Assessment Scorecard", id: "selfscore", component: "PreSelfScorecard",
            instructions: ""
          },
          { label: "Walkthrough Assessment", id: "walkthrough", component: "PreWalkthroughAssessment",
            instructions: "The Certified Walkthrough Specialist will complete the Walkthrough Assessment based on additional information gathered during the walkthrough. Provide a score (1-5 or N/A) that most closely represents their operation. There is room in each section to provide unique notes based on your evaluation of the operation."
          },
          { label: "Walkthrough Scorecard", id: "walkthroughscore", component: "PreWalkthroughScorecard",
            instructions: ""
          },
        ]
      },

      ccmSections: function(){
        return [
          { label: "Customer Profile", id: "profile", component: "PreCustomerProfile",
            instructions: "Enter customer information."
          },
          { label: "Walkthrough Assessment", id: "walkthrough", component: "PreWalkthroughAssessment",
            instructions: "The Certified Walkthrough Specialist will complete the Walkthrough Assessment based on additional information gathered during the walkthrough. Provide a score (1-5 or N/A) that most closely represents their operation. There is room in each section to provide unique notes based on your evaluation of the operation."
          },
          { label: "Walkthrough Scorecard", id: "walkthroughscore", component: "PreWalkthroughScorecard",
            instructions: ""
          },
        ]
      },

      workflowSections: function(){
        return [
          { label: "Customer Profile", id: "profile", component: "PreCustomerProfile",
            instructions: "Enter customer information."
          },
          { label: "What To Look For", id: "prepinfo", component: "PreAssessmentTips",
            instructions: "Examples of things to look for when performing the assessment, general information, and questions to aske to identify if a customer is a potential prospect for an MIS solution."
          },
          { label: "Self Assessment", id: "selfassessment", component: "PreSelfAssessment",
            instructions: "Ask the customer how they perform each of the activities and provide a score (1-5 or N/A) that most closely represents their answers. There is room in each section to provide unique notes based on your discussion with the customer."
          },
          { label: "Self Assessment Scorecard", id: "selfscore", component: "PreSelfScorecard",
            instructions: ""
          },
          { label: "Walkthrough Assessment", id: "walkthrough", component: "PreWalkthroughAssessment",
            instructions: "The Certified Walkthrough Specialist will complete the Walkthrough Assessment based on additional information gathered during the walkthrough. Provide a score (1-5 or N/A) that most closely represents their operation. There is room in each section to provide unique notes based on your evaluation of the operation."
          },
          { label: "Walkthrough Scorecard", id: "walkthroughscore", component: "PreWalkthroughScorecard",
            instructions: ""
          },
        ]
      },

      sections: function(){
        if(this.$store.state.workflowAssessment){
          if(this.$store.state.workflowAssessment.assessmentType === "Mail"){
            return this.mailSections;
          }
          if(this.$store.state.workflowAssessment.assessmentType === "CCM"){
            return this.ccmSections;
          }

          if(this.$store.state.workflowAssessment.assessmentType === "MAP"){
            return this.mapSections;
          }

          if(this.$store.state.workflowAssessment.assessmentType === "PMP"){
            return this.pmpSections;
          }
        }

        return this.workflowSections;
      },

      currentSection: function(){
        //console.log("current section?");

        //console.log(this.sections);
        var section = this.sections.find((item) => {
          return item.id == this.$route.query.section;
        })

        if(!section){
          section = this.sections[0];
        }

        return section;
      }
    }
}
</script>

<style scoped>


.survey {

}

</style>