


<template>
<div class="pre-customer-profile">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold">{{ options.instructions }}</p>
  </div>
  <div>
    <h2 class="font-bold text-xl pb-2 border-b-2 pl-4">{{ options.label }}</h2>
  </div>
  <div class="" v-if="costs">

    <table class="w-full bg-white border-r border-l text-sm">
      <thead>
        <tr class="">
          <th class="text-left px-4 align-bottom bg-gray-100" colspan="3">PRE-SORT, COMINGLING AND CO-PALLETIZING POSTAGE COST SAVINGS OPPORTUNITY</th>
          <th class="text-left px-4 align-bottom bg-gray-100 text-right" colspan="3">
            <button class="px-4 py-2 bg-brand-green text-white rounded" @click="updateRates" v-if="!hasCurrentRates">Update to Current Rates</button>
          </th>
        </tr>
        <tr class="">
          <th class="text-left px-4 align-bottom bg-gray-100"></th>
          <th class="text-left px-4 align-bottom bg-gray-100 border-l">Current Postage Costs</th>
          <th class="text-left px-4 align-bottom bg-gray-100 border-l">Current Volume</th>
          <th class="text-left px-4 align-bottom bg-gray-100 border-l">Current Cost</th>
          <th class="text-left px-4 align-bottom bg-gray-100 border-l">Future Volume</th>
          <th class="text-left px-4 align-bottom bg-gray-100 border-l">Future Cost</th>
        </tr>
      </thead>
      <tbody>

      <tr v-for="rate in rates" :key="rate.key">
        <td>{{ rate.label }}</td>
        <td>${{ costs[rate.key].currentPostage | decimal(3) }}</td>
        <td>{{ costs[rate.key].currentVolume | decimal(0) }}</td>
        <td>${{ costs[rate.key].currentCost | decimal(0) }}</td>
        <td>{{ costs[rate.key].futureVolume | decimal(0) }}</td>
        <td>${{ costs[rate.key].futureCost | decimal(0) }}</td>
      </tr>
      <tr class="font-bold">
        <td>YEARLY COST OF POSTAGE</td>
        <td></td>
        <td>{{ costs.yearlyCurrentVolume | decimal(0)}}</td>
        <td>${{ costs.yearlyCurrentCost | decimal(0)}}</td>
        <td>{{ costs.yearlyFutureVolume | decimal(0)}}</td>
        <td>${{ costs.yearlyFutureCost | decimal(0)}}</td>
      </tr>
      <tr class="font-bold">
        <td colspan="5">YEARLY COST SAVINGS FROM PRE-SORT, COMINGLING AND CO-PALLETIZING POSTAGE</td>
        <td>${{ costs.yearlyCostSavings | decimal(0) }}</td>
      </tr>
      </tbody>

    </table>






    <div class="pt-4 border-t">
      <div class="pb-4 flex justify-between">
        <div class="pr-4">
          <button class="button button-green" @click="$emit('summary')">Save & Go to Summary</button>
        </div>
        <div class="pl-4">
          <button class="button button-blue button-next" @click="$emit('next')">Save & Next ROI <img class="inline-block ml-2 h-3" src="../assets/icons/next-arrow-icon.svg" /></button>
        </div>

      </div>
    </div>

  </div>
</div>
</template>

<script>
//import NumberInput from "@/components/NumberInput";
import mailing from "@/schema/mailing";


export default {
    name: "pre-sort-roi-table",
  components: {},
  props: {
      options: {
        default: function(){
          return {
            label: "Pre-Sort ROI",
            costsKey: "",
            instructions: "ROI instructions"
          }
        }
      },
    },
    data: function(){
        return {
          rates: [
            { label: "5-Digit Automation", key: "fiveDigit", questionId: "X3055B1"},
            { label: "AADC Automation", key: "AADC", questionId: "X3055B2"},
            { label: "Mixed AADC Automation", key: "mixedAADC", questionId: "X3055B3"},
            { label: "AADC Nonautomation Machinable", key: "AADCNonAuto", questionId: "X3055B4"},
            { label: "Mixed AADC Nonautomation Machinable", key: "mixedAADCNonAuto", questionId: "X3055B5"},
            { label: "5-Digit Nonautomation Nonmachinable", key: "fiveDigitNonAuto", questionId: "X3055B6"},
            { label: "3-Digit Nonautomation Nonmachinable", key: "threeDigitNonAuto", questionId: "X3055B7"},
            { label: "Mixed ADC Nonautomation Nonmachinable", key: "mixedADC", questionId: "X3055B8"},
            { label: "Meter", key: "meter", questionId: "X3055B9"},
            { label: "Stamp", key: "stamp", questionId: "X3055B10"},

          ]
        }
        },
    methods: {
      onChange: function(){
        this.$emit("change");
      },
      updateRates: function(){
        if(confirm("Update all postage costs to the most current rates?")){
          this.rates.forEach((rate)=>{
            let defaultRate = mailing.questions[rate.questionId].defaults[0];
            this.$store.state.workflowAssessment.interview.questions[rate.questionId].answer.final = defaultRate;
          })

          this.$sdm.calculate(this.$store.state.workflowAssessment);

          this.$emit("change")

        }

      }
    },
    computed: {
      hasCurrentRates: function(){

        let hasOldRates = this.rates.find((rate)=>{
          let defaultRate = mailing.questions[rate.questionId].defaults[0];
          let currentRate = this.costs[rate.key].currentPostage;
          return (defaultRate !== currentRate)
        })

        return hasOldRates?false:true;
      },

      costs: function(){
        if(this.$store.state.workflowAssessment && this.$store.state.workflowAssessment.costs){
            return this.$store.state.workflowAssessment.costs.preSort;
        }

        return null;
      }
    }
}
</script>

<style scoped>


.pre-customer-profile {

}

td {
  @apply text-right py-2 px-4 border bg-white w-16;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}

</style>