


<template>
<div class="survey relative flex flex-col h-full">
  <div class="flex-shrink-0 w-full p-2 py-6 pt-8 bg-brand-blue-dark">
    <progress-dot-bar :markers="stages" :selected="selectedStageIndex" @select="onProgressSelect"></progress-dot-bar>
  </div>
  <div class="bg-brand-blue text-white text-sm">
    <p class="px-8 py-4">{{ currentStage.instructions }}</p>
  </div>
  <div ref="questions" class="w-full flex-grow overflow-y-scroll bg-gray-50 relative" v-if="currentStage">
      <section v-for="(section) in currentSections" :key="section.id" :id="section.id" class="section relative" :ref="section.id">
        <h2 class="z-10 text-2xl font-bold sticky top-0 bg-gray-50 border-b-2 pb-2 pt-6 mx-8 border-b-2 border-gray-400 relative">{{ section.label }}</h2>

        <div class="relative" v-for="question in section.questions" :key="question.id"  >

          <div class="relative pt-4 mr-2" v-if="!answers[question.id].hidden">


            <component v-if="question" :is="questionTypes[question.type]"  :question="question" :answer="answers[question.id]" class="question flex items-center pb-4 border-b bg-gray-50 mx-8"  @change="onChange">
            </component>
            <label class="text-xs ml-8 text-gray-400 top-0 absolute mt-2">{{ question.id }}</label>
          </div>
        </div>


      </section>
    <div class="flex justify-between items-center pt-4 border-b bg-gray-100 px-8 pb-12">
      <div class="">
        <button class="button button-blue" @click="showPrevious">Previous: {{ previousLink.label }}</button>
      </div>
      <div class="">
        <button class="button button-green" @click="showNext">Next: {{ nextLink.label }}</button>
      </div>
    </div>



  </div>
</div>
</template>

<script>
//import LinkList from "@/components/LinkList";
import ProgressDotBar from "@/components/ProgressDotBar";
import AssessmentInterviewQuestionMultiCol from "@/components/AssessmentInterviewQuestionMultiCol";
import AssessmentInterviewQuestionSingleCol from "@/components/AssessmentInterviewQuestionSingleCol";
import AssessmentInterviewQuestionTextField from "@/components/AssessmentInterviewQuestionTextField";
import AssessmentInterviewQuestionBoolean from "@/components/AssessmentInterviewQuestionBoolean";
import AssessmentInterviewQuestionStatement from "@/components/AssessmentInterviewQuestionStatement";
import AssessmentInterviewQuestionMultiChoice from "@/components/AssessmentInterviewQuestionMultiChoice";
import AssessmentInterviewQuestionSelect from "@/components/AssessmentInterviewQuestionSelect";
export default {
    name: "assessment-interview-panel",
  components: {
    AssessmentInterviewQuestionSelect,
    AssessmentInterviewQuestionMultiChoice,
    AssessmentInterviewQuestionStatement,
    AssessmentInterviewQuestionBoolean,
    AssessmentInterviewQuestionTextField,
    AssessmentInterviewQuestionSingleCol, AssessmentInterviewQuestionMultiCol, ProgressDotBar},
  mounted: function() {

  },
  props: ['assessment'],
    data: function(){
        return {
            sectionEls: [],
            selectedStageIndex: 0,
          }
        },
    methods: {
      setSection: function(section){
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: this.$route.query.tab,
            section: section.id
          }
        })
      },



      onProgressSelect: function(index){
        //console.log("index");
        this.setStageIndex(index);
      },

      setStageIndex: function(index){
        this.selectedStageIndex = index;
        this.$emit("autosave");
        this.$nextTick(()=>{
          window.scrollTo(0,0);
        })
      },

      onChange: function(){
        //this.$sdm.calculate(this.assessment);
        this.$emit("recalculate");
      },

      showPrevious: function(){
        if(this.previousLink.id){
          this.setStageIndex((this.selectedStageIndex - 1));
        }else{
          this.$emit("tab","pre");
        }
      },

      showNext: function(){
        if(this.nextLink.id){
          this.setStageIndex((this.selectedStageIndex + 1));
        }else{
          if(this.isCCM){
            this.$emit("tab","summary");
          }else{
            this.$emit("tab","costs");
          }

        }
      },

    },
    computed: {

      isCCM: function(){
        return (this.assessment.customerProfile.assessmentType === "CCM")
      },

      questionTypes: function(){
          return this.$store.getters.questionTypes;
      },

      answers: function(){
        return this.$store.getters.interviewAnswers;
      },

      previousLink: function(){
        if(this.selectedStageIndex > 0){
          return { ...this.stages[this.selectedStageIndex - 1] };
        }

        return {
            label: "Pre-Assessment",
            tab: "pre"
        }
      },

      nextLink: function(){
        if((this.selectedStageIndex + 1) < this.stages.length){
          return { ...this.stages[this.selectedStageIndex + 1] };
        }

        if(this.isCCM){
          return {
            label: "Summary",
            tab: "summary"
          }
        }

        return {
          label: "Solutions",
          tab: "costs"
        }
      },






      stages: function(){
        if(this.sections){
          let stageIds = Object.keys(this.$store.state.interviewStages);
          return stageIds.map((stageId) => {
            let stage = this.$store.state.interviewStages[stageId];
            let stageSections = stage.sections.map((sectionId) => {
              return this.sections.find((section) => {
                //console.log(section.id, sectionId);
                return (section.id == sectionId);
              })
            })
            return { id: stage.id, label:stage.label, instructions: stage.instructions, sections:stageSections }
          })
        }

        return [];
      },

      sections: function(){
         if(this.$store.state.interviewGroups){
           let groupIds = Object.keys(this.$store.state.interviewGroups);

           let _sections = [];
           groupIds.forEach((groupId) => {

             let section = this.$store.state.interviewGroups[groupId];


             let questions = section.questions.map((questionId) => {
               return this.$store.state.interviewQuestions[questionId];
             })

             let sectionObj =  { id: section.id, label:section.label, component: "InterviewSection", questions: questions };
             //console.log(section.id, sectionObj.id);
             _sections.push(sectionObj);
           })

           //console.log(_sections);
           return _sections;
         }

         return null;
      },

      currentStage: function(){
        if(this.stages){
          return this.stages[this.selectedStageIndex];
        }

        return null;
      },

      currentSections: function(){
        if(this.currentStage){
          console.log("Current Stage",this.currentStage)
          return this.currentStage.sections;
        }

        return [];
      },

      currentSection: function(){
        if(this.sections){
          var section = this.sections.find((item) => {
            return item.id == this.$route.query.section;
          })

          if(!section){
            section = this.sections[0];
          }

          return section;
        }

        return null;

      },

    }
}
</script>

<style scoped>


.survey {

}







</style>