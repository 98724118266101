import {
    AlignmentType,
    BorderStyle,
    Document,
    HeadingLevel,
    ImageRun,
    Packer,
    Paragraph,
    ShadingType,
    Table,
    TableCell, TableOfContents,
    TableRow,
    TextRun,
    WidthType,
    StyleLevel
} from "docx";
import {saveAs} from "file-saver"

export default {
    install(Vue) {

        function asyncImageLoader(src){
            return new Promise((resolve, reject) => {
                let img = new Image();
                img.onload = () => resolve(img)
                img.onerror = reject;
                img.src = src;
            })
        }

        async function processSVGs(html){
            let images = {};
            let imgs = html.querySelectorAll("svg");

            for(let i=0; i<imgs.length; i++){

                let svg = imgs[i];

                let svgXML = (new XMLSerializer()).serializeToString(svg);
                let canvas = document.createElement('canvas');

                let w, h;

                if(svg.getAttribute("width") && svg.getAttribute("height")){
                    w = parseInt(svg.getAttribute("width"));
                    h = parseInt(svg.getAttribute("height"));
                }else{
                    let svgRect = svg.getBoundingClientRect();
                    let aspectRatio = svgRect.height / svgRect.width;
                    w = 1000;
                    h = w * aspectRatio;
                }

                canvas.width = w;
                canvas.height = h;
                let ctx = canvas.getContext('2d');

                let srcString =  "data:image/svg+xml;base64," + btoa(svgXML);

                let tmpImage = await asyncImageLoader(srcString);
                ctx.drawImage(tmpImage, 0, 0);

                let pngSrc = canvas.toDataURL("image/png");

                let imgFile = await fetch(pngSrc).then(r => {
                    //console.log(r);
                    return r
                });


                let imgRatio =  h/w;

                let imgOptions = {
                    data: imgFile.blob(),
                    transformation: {
                        width: 600,
                        height: (600 * imgRatio)
                    }
                };

                if(imgs[i].dataset.float) {
                    imgOptions.floating = {
                        behindDocument: true,

                        horizontalPosition: {
                            offset: 0
                        },
                        verticalPosition: {
                            offset: 0
                        }
                    }


                    imgOptions.transformation = {
                        width: 815,
                        height: (815 * imgRatio)
                    }
                }

                let imageRun = new ImageRun(imgOptions)

                images["svg"+i] = {
                    image: imageRun
                };
                imgs[i].dataset.imgId = "svg"+i;
            }

            return images;
        }

        async function processImages(html){

            var images = {};
            let imgs = html.querySelectorAll("img");


            for(var i=0; i<imgs.length; i++){


                //console.log("fetching", imgs[i].src);
                let imgFile = await fetch(imgs[i].src).then(r => {
                    //console.log(r);
                    return r
                });


                let imgRatio =  parseFloat(imgs[i].dataset.ratio);

                let imgOptions = {
                    data: imgFile.blob(),
                    transformation: {
                        width: 600,
                        height: (600 * imgRatio)
                    }
                };

                if(imgs[i].dataset.float) {
                    imgOptions.floating = {
                        behindDocument: true,

                        horizontalPosition: {
                            offset: 0
                        },
                        verticalPosition: {
                            offset: 0
                        }
                    }


                    imgOptions.transformation = {
                        width: 815,
                        height: (815 * imgRatio)
                    }
                }

                let imageRun = new ImageRun(imgOptions)

                images["image"+i] = {
                    image: imageRun
                };

                imgs[i].dataset.imgId = "image"+i;

            }

            return images;
        }

        async function assembleImages(html){
            let imgs = await processImages(html)
            let svgs = await processSVGs(html);
            return { ...imgs, ...svgs }
        }

        function createHR(){
            return new Paragraph({
                text: "",
                border: {
                    top: {
                        color: "000000",
                        space: 1,
                        value: "single",
                        size: 6
                    }
                },

                spacing: {
                    before: 0,
                    after: 120
                }
            });
        }

        function createBR(){
            return new Paragraph({
                text: "",
                pageBreakBefore: true,
                spacing: {
                    before: 0,
                    after: 0
                }
            })

        }

        function createTable(node, images){

            let defaultCellStyle = "Basic";

            if(node.dataset.cellStyle){
                defaultCellStyle = node.dataset.cellStyle;
            }


            let tableRows = [];

            let rows = [];


            node.children.forEach((tgroup) => {
                if(tgroup.nodeName === "TR"){
                    rows.push(tgroup);
                }else{
                    tgroup.children.forEach((trow) => {
                        rows.push(trow);
                    })
                }
            })

            rows.forEach((row) => {
                let cells = [];

                row.children.forEach((col) => {
                    let cellStyle = col.dataset.cellStyle?col.dataset.cellStyle:defaultCellStyle;

                    let cellContents;

                    if(col.children.length){



                        let cellChildren = [];
                        col.children.forEach((colChild) => {
                            cellChildren = processNode(colChild,cellChildren,images);
                        })

                        cellContents = cellChildren;
                    }else{
                        cellContents = [new Paragraph({ text: col.innerText, style: cellStyle})];
                    }

                    let margin = 120;
                    if(defaultCellStyle.startsWith('Appendix')){
                        margin = 60;
                    }

                    let borders = (cellStyle === "BlankCell")?noCellBorders():cellBorders();
                    let colspan = parseInt(col.colSpan?col.colSpan:1);



                    let shading = (col.nodeName === "TH")?{fill: "DBE5F1", val: ShadingType.SOLID, color: "DBE5F1"}:undefined;
                    let cell = new TableCell({
                        children: cellContents,
                        borders: borders,
                        shading: shading,
                        columnSpan: colspan,
                        margins: {
                            top: margin,
                            left: margin,
                            right: margin,
                            bottom: margin
                        }

                    })
                    cells.push(cell);
                })

                let rowOptions = {
                    children: cells,
                    // height: { value: "0.125in", rule: "exact"}
                }

                if(row.dataset.height){
                    rowOptions.height = {
                        value: row.dataset.height
                    }

                    if(row.dataset.heightRule){
                        rowOptions.height.rule = row.dataset.heightRule
                    }

                    console.log(rowOptions)
                }



                let tr = new TableRow(rowOptions)

                tableRows.push(tr);
            })


            return new Table({
                rows: tableRows,
                width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                }
            })

        }

        function createTitleBox(node){
            let tableRows = [];
            node.children.forEach((row) => {

                let cells = [];

                row.children.forEach((col) => {
                    let cellStyle = col.dataset.cellStyle?col.dataset.cellStyle:"Basic";
                    let cellContents = new Paragraph({ text: col.innerText, style: cellStyle});
                    let cell = new TableCell({
                        children: [cellContents],
                        borders: noCellBorders(),
                        margins: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0
                        }

                    })
                    cells.push(cell);
                })
                let tr = new TableRow({
                    children: cells
                })

                tableRows.push(tr);
            })

            return new Table({
                rows: tableRows,
                width: { size: 35, type: WidthType.PERCENTAGE },
            })

        }


        function createImage(node, images){
            return new Paragraph(
                {
                    children: [images[node.dataset.imgId].image]
                }
            );

        }

        function createTOC(alias, levels){

            return new TableOfContents(alias, {
                    headingStyleRange: levels,

                    stylesWithLevels: [
                        new StyleLevel("TOC 1",1),
                        new StyleLevel("TOC 2",2),
                        new StyleLevel("TOC 3",3),
                    ]
                })



            // return [
            //     new Paragraph({
            //         text: "TABLE OF CONTENTS",
            //         alignment: AlignmentType.CENTER,
            //         style: "TOC HEAD"
            //
            //     }),
            //     new TableOfContents(alias, {
            //     headingStyleRange: levels,
            //     stylesWithLevels: [
            //         new StyleLevel("TOC 1",1)
            //     ]
            // })]
        }

        function createUL(node){
            let bullets = []
            node.children.forEach((bullet) => {
                //let bText = bullet.innerText;
                let bP = new Paragraph({
                    children: createTextRuns(bullet),
                    bullet: {
                        level: 0
                    }
                })
                bullets.push(bP);
            })

            return bullets
        }

        function createBullet(node){
            let runChildren = [];

            node.childNodes.forEach((span) => {

                let options = {

                }

                if(span.classList){
                    options.text = span.innerText;
                    if(span.classList.contains('font-bold')){
                        options.bold = true;
                    }
                    if(span.classList.contains('text-gray-500')){
                        options.color = "888888";
                    }
                    if(span.classList.contains('text-sm')){
                        options.size = 12;
                    }
                }else{
                    options.text = span.textContent;
                }

                let bP = new TextRun(options)
                runChildren.push(bP);
            })

            return new Paragraph({
                children: runChildren,
                bullet: {
                    level: 0
                }
            })

        }

        function createP(node,options){

            let text = node.innerText;

            console.log('createP', createTextRuns(node))
            let nodeName = node.nodeName;
            let heading = nodeName.startsWith("H")?HeadingLevel['HEADING_'+nodeName.slice(1)]:"";
            if(heading){
                if(options && options.counters){
                    if(nodeName === "H1"){
                        options.counters.H1++;
                        options.counters.H2 = 0;
                        options.counters.H3 = 0;
                        text = options.counters.H1 + "  "+ text;
                    }else if(nodeName === "H2"){
                        options.counters.H2++;
                        options.counters.H3 = 0;
                        text = options.counters.H1 +"."+options.counters.H2+ "  "+ text;
                    }else if(nodeName === "H3"){
                        options.counters.H3++;
                        text = options.counters.H1 +"."+options.counters.H2+"."+options.counters.H3+ "  "+ text;
                    }
                }
            }

            let spacing = {};
            let pstyle = undefined;

            if(node.classList.contains("bold-label")){
                pstyle = "BoldLabel"
            }

            if(node.classList.contains("toc-title")){
                pstyle = "TOC HEAD"
            }

            if(node.classList.contains("title")){
                pstyle = "Title"
            }

            if(node.classList.contains("subtitle")){
                pstyle = "Subtitle"
            }

            if(node.classList.contains("titlebox")){
                pstyle = "Titlebox"
            }

            if(node.classList.contains("footnote")){
                pstyle = "Footnote"
            }


            if(node.classList.contains("no-mb")){
                spacing.after = 0;
            }

            if(node.classList.contains("mb-0")){
                spacing.after = 0;
            }

            if(node.classList.contains("mt-0")){
                spacing.before = 0;
            }

            if(node.classList.contains("mt-2")){
                spacing.before = 120;
            }

            if(node.classList.contains("mt-4")){
                spacing.before = 240;
            }

            if(node.classList.contains("mt-8")){
                spacing.before = 480;
            }

            let pOptions = {
                heading: heading,
                spacing: spacing,
                style: pstyle,
            }

            if(heading){
                pOptions.text = text;
            }else{
                pOptions.children = createTextRuns(node)
            }

            if(node.classList.contains("bold-label")){
                pOptions.keepNext = true
            }

            if(heading){

                //console.log(pOptions);

                pOptions.keepNext = true;

                if(options && options.numberHeadings){
                    if(nodeName === "H1") {
                        if(options.numberHeadings[nodeName]){
                            pOptions.numbering = {
                                level: 0,
                                reference: 'report-numbering'
                            }
                        }


                    }else if(nodeName === "H2"){
                        if(options.numberHeadings[nodeName]) {
                            pOptions.numbering = {
                                level: 1,
                                reference: 'report-numbering'
                            }
                        }

                    }else if(nodeName === "H3"){
                        if(options.numberHeadings[nodeName]) {
                            pOptions.numbering = {
                                level: 2,
                                reference: 'report-numbering'
                            }
                        }
                    }
                }


            }




            let p = new Paragraph(pOptions)





            if(node.classList.contains('text-gray-500')){
                p.color = "888888";
            }



            return p;
        }

        function createTextRuns(node){
            let nodes = Array.from(node.childNodes);

            if(nodes){
                let runs = nodes.map((node)=>{

                    return new TextRun(
                        {
                            text: (node.nodeName === 'BR') ? '':node.textContent,
                            bold: node.nodeName === 'B',
                            italics: node.nodeName === 'I',
                            break: (node.nodeName === 'BR')?1:0
                        }

                    )
                })

                return runs
            }

            return []

        }

        function createScoreFrame(node,options){
            let text = node.innerText;
            let nodeName = node.nodeName;
            let heading = nodeName.startsWith("H")?HeadingLevel['HEADING_'+nodeName.slice(1)]:"";
            if(heading){
                if(options && options.counters){
                    if(nodeName === "H1"){
                        options.counters.H1++;
                        options.counters.H2 = 0;
                        options.counters.H3 = 0;
                        text = options.counters.H1 + "  "+ text;
                    }else if(nodeName === "H2"){
                        options.counters.H2++;
                        options.counters.H3 = 0;
                        text = options.counters.H1 +"."+options.counters.H2+ "  "+ text;
                    }else if(nodeName === "H3"){
                        options.counters.H3++;
                        text = options.counters.H1 +"."+options.counters.H2+"."+options.counters.H3+ "  "+ text;
                    }
                }
            }

            let spacing = {};
            let pstyle = undefined;

            if(node.classList.contains("bold-label")){
                pstyle = "BoldLabel"
            }

            if(node.classList.contains("toc-title")){
                pstyle = "TOC HEAD"
            }

            if(node.classList.contains("title")){
                pstyle = "Title"
            }

            if(node.classList.contains("subtitle")){
                pstyle = "Subtitle"
            }

            if(node.classList.contains("titlebox")){
                pstyle = "Titlebox"
            }

            if(node.classList.contains("footnote")){
                pstyle = "Footnote"
            }


            if(node.classList.contains("no-mb")){
                spacing.after = 0;
            }

            if(node.classList.contains("mb-0")){
                spacing.after = 0;
            }

            if(node.classList.contains("mt-0")){
                spacing.before = 0;
            }

            if(node.classList.contains("mt-2")){
                spacing.before = 120;
            }

            if(node.classList.contains("mt-4")){
                spacing.before = 240;
            }

            if(node.classList.contains("mt-8")){
                spacing.before = 480;
            }

            let pOptions = {
                text: text,
                heading: heading,
                spacing: spacing,
                style: pstyle,
                shading: {
                    type: ShadingType.SOLID,
                    color: "#FAFAFA"
                },
                border: {
                    top: {
                        color: "000000",
                        space: 10,
                        value: "single",
                        size: 2
                    },
                    bottom: {
                        color: "000000",
                        space: 10,
                        value: "single",
                        size: 2
                    },
                    left: {
                        color: "000000",
                        space: 10,
                        value: "single",
                        size: 2
                    },
                    right: {
                        color: "000000",
                        space: 10,
                        value: "single",
                        size: 2
                    }
                }
            }

            if(node.classList.contains("bold-label")){
                pOptions.keepNext = true
            }

            if(heading){

                //console.log(pOptions);

                pOptions.keepNext = true;

                if(options && options.numberHeadings){
                    if(nodeName === "H1") {
                        if(options.numberHeadings[nodeName]){
                            pOptions.numbering = {
                                level: 0,
                                reference: 'report-numbering'
                            }
                        }


                    }else if(nodeName === "H2"){
                        if(options.numberHeadings[nodeName]) {
                            pOptions.numbering = {
                                level: 1,
                                reference: 'report-numbering'
                            }
                        }

                    }else if(nodeName === "H3"){
                        if(options.numberHeadings[nodeName]) {
                            pOptions.numbering = {
                                level: 2,
                                reference: 'report-numbering'
                            }
                        }
                    }
                }


            }




            let p = new Paragraph(pOptions)





            if(node.classList.contains('text-gray-500')){
                p.color = "888888";
            }



            return p;
        }

        function processNode(node, children, images, options){

            if(node.dataset.container){
                node.children.forEach((child) => {
                    children = processNode(child, children, images, options);
                })
                return children;
            }

            let nodeName = node.nodeName;

            let baseTags = {
                "HR": createHR,
                "BR": createBR,
                "TABLE": createTable,
                "svg": createImage,
                "IMG": createImage,
                "UL": createUL
            }

            console.log("Node name", nodeName)

            if(node.dataset.toc){
                children.push(createTOC(node.dataset.alias, node.dataset.levels))
            }else if(node.classList.contains('titlebox')){
                children.push(createTitleBox(node))
            }else if(node.classList.contains('scoreframe')){
                children.push(createScoreFrame(node))
            }else if(node.dataset.bullet){
                children.push(createBullet(node))
            }else{

                if(baseTags[nodeName]){
                    let p = baseTags[nodeName](node,images)
                    if(p.length){ //in case of UL or other with multiple children
                        p.forEach((item)=>{
                            children.push(item)
                        })
                    }else{
                        children.push(p)
                    }
                }else{
                    children.push(createP(node,options))
                }
            }

            return children;
        }

        function processSections(html, images, options){
            let docSections = [];
            let sections = html.querySelectorAll('section');
            if(sections.length){

                sections.forEach((section) => {
                    let children = [];
                    section.children.forEach((node) => {
                        if(node.dataset.container){
                            node.children.forEach((child) => {
                                children = processNode(child, children, images, options);
                            })
                        }else{
                            children = processNode(node, children, images, options);
                        }
                    })

                    docSections.push({
                        children: children,
                        style: {

                        },
                        size: {
                            width: 12240,
                            height: 15840
                        }
                    })
                })
            }

            return docSections
        }

        function convertInchesToTwip(inches) {
            return Math.floor(inches * 72 * 20);
        }

        function cellBorders(){
            return {
                top: {
                    style: BorderStyle.SINGLE,
                    size: 1,
                    color: "black"
                },
                left: {
                    style: BorderStyle.SINGLE,
                    size: 1,
                    color: "black"
                },
                right: {
                    style: BorderStyle.SINGLE,
                    size: 1,
                    color: "black"
                },
                bottom: {
                    style: BorderStyle.SINGLE,
                    size: 1,
                    color: "black"
                }
            }
        }

        function noCellBorders(){
            return {
                top: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "black"
                },
                left: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "black"
                },
                right: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "black"
                },
                bottom: {
                    style: BorderStyle.NONE,
                    size: 0,
                    color: "black"
                }
            }
        }

        function docStyles(){

            return {
                default: {
                    heading1: {
                        run: {
                            size: 32,
                            bold: true,
                            color: "000000"
                        },
                        paragraph: {
                            spacing: {
                                after: 120
                            }
                        }
                    },
                    heading2: {
                        run: {
                            size: 26,
                            bold: true,
                            color: "C00000"
                        },
                        paragraph: {
                            spacing: {
                                before: 960,
                                after: 120
                            }
                        }
                    },
                    heading3: {
                        run: {
                            size: 20,
                            bold: true,
                            color: "C00000"
                        },
                        paragraph: {
                            spacing: {
                                after: 0
                            }
                        }
                    },
                    heading5: {
                        run: {
                            size: 12,
                            bold: true,
                            color: "000000"
                        },
                        paragraph: {
                            spacing: {
                                after: 0
                            }
                        }
                    }
                },
                paragraphStyles: [
                    {

                        name: 'TOC 1',
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            color: 'C00000',
                            font: "Helvetica",
                            bold: true
                        },
                        paragraph: {
                            spacing: {
                                before: 240,
                                after: 120
                            }
                        }
                    },
                    {

                        name: 'TOC 2',
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            color: '000000',
                            font: "Helvetica",
                        },
                        paragraph: {
                            indent: {
                                left: convertInchesToTwip(0.31)
                            },
                            leftTabStop: convertInchesToTwip(0.61),
                            rightTabStop: convertInchesToTwip(6.26),
                            spacing: {
                                before: 120,
                                after: 120
                            }
                        }
                    },
                    {

                        name: 'TOC 3',
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            color: '000000',
                            font: "Helvetica",
                        },
                        paragraph: {
                            indent: {
                                left: convertInchesToTwip(0.61)
                            },
                            leftTabStop: convertInchesToTwip(0.61),
                            rightTabStop: convertInchesToTwip(6.26),
                            spacing: {
                                before: 120,
                                after: 120
                            }
                        }
                    },
                    {

                        name: 'TOC HEAD',
                        basedOn: "Normal",
                        next: "TOC HEAD",
                        quickFormat: true,
                        run: {
                            size: 26,
                            color: '000000',
                            font: "Helvetica",
                            bold: true
                        },
                        paragraph: {
                            spacing: {
                                before: 180,
                                after: 120
                            }
                        }
                    },
                    {

                        name: 'Normal',
                        run: {
                            size: 20,
                            color: '000000',
                            font: "Helvetica"
                        },
                        paragraph: {
                            spacing: {
                                before: 0,
                                after: 240
                            }
                        }
                    },
                    {

                        name: 'Basic',
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            color: '000000',
                        },
                        paragraph: {
                            spacing: {
                                before: 0,
                                after: 0
                            }
                        }
                    },
                    {

                        name: 'Title',
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 26,
                            color: 'FFFFFF',
                            bold: true
                        },
                        paragraph: {
                            spacing: {
                                before: 0,
                                after: 0,
                            }
                        }
                    },

                    {

                        name: 'Subtitle',
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            color: 'FFFFFF',
                            bold: true
                        },
                        paragraph: {
                            spacing: {
                                before: 0,
                                after: 240
                            }
                        }
                    },

                    {

                        name: 'BoldLabel',
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            color: '000000',
                            bold: true
                        },
                        paragraph: {
                            spacing: {
                                before: 240,
                                after: 180
                            }
                        }
                    },

                    {

                        name: 'Titlebox',
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            color: 'FFFFFF',
                        },
                        paragraph: {
                            spacing: {
                                before: 0,
                                after: 0,
                                right: 500
                            }
                        }
                    },
                    {

                        name: 'Footnote',
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            color: '888888',
                        },
                        paragraph: {
                            spacing: {
                                before: 0,
                                after: 0,
                                right: 500
                            }
                        }
                    },
                    {
                        name: 'TitlePageTitleCell',
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 26,
                            bold:true,
                            color: 'FFFFFF',
                        },
                        paragraph: {
                            spacing: {
                                before: 0,
                                after: 0
                            }
                        }
                    },
                    {
                        name: 'TitlePageSubtitleCell',
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 26,
                            color: 'FFFFFF',
                        },
                        paragraph: {
                            spacing: {
                                before: 180,
                                after: 240
                            }
                        }
                    },

                    {
                        name: 'TitlePageCell',
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 16,
                            color: 'FFFFFF',
                        },
                        paragraph: {
                            spacing: {
                                before: 0,
                                after: 0
                            }
                        }
                    },

                    {
                        name: 'AppendixCell',
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 11,
                            color: '000000',
                        },
                        paragraph: {
                            spacing: {
                                before: 0,
                                after: 0
                            }
                        }
                    },

                    {
                        name: 'AppendixBoldCell',
                        basedOn: "AppendixCell",
                        quickFormat: true,
                        run: {
                            bold:true,
                        }
                    },

                    {
                        name: 'BlankCell',
                        basedOn: "AppendixCell",
                        quickFormat: true,
                    },
                    {

                        name: "Heading 1",
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 32,
                            bold: true,
                            color: "000000"
                        },
                        paragraph: {
                            spacing: {
                                before: 240,
                                after: 120
                            }
                        }

                    },
                    {

                        name: "Heading 2",
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 26,
                            bold: true,
                            color: "C00000"
                        },
                        paragraph: {
                            spacing: {
                                before: 480,
                                after: 120
                            }
                        }

                    },
                    {

                        name: "Heading 3",
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            bold: true,
                            color: "000000"
                        },
                        paragraph: {
                            spacing: {
                                before: 0,
                                after: 120
                            }
                        }

                    },
                    {

                        name: "Heading 4",
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            bold: true,
                            color: "000000",
                        },
                        paragraph: {
                            spacing: {
                                before: 480,
                                after: 0
                            }
                        }

                    },
                    {

                        name: "HEADING_5",
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 12,
                            bold: true,
                            color: "000000",
                        },
                        paragraph: {
                            spacing: {
                                before: 480,
                                after: 0
                            }
                        }

                    },
                    {

                        name: "List Paragraph",
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {

                        },
                        paragraph: {
                            spacing: {
                                before: 0,
                                after: 120
                            }
                        }

                    }
                ]
            }
        }

        function docNumbering(){
            return {
                config: [
                    {
                        reference: "report-numbering",
                        levels: [
                            {
                                level: 0,
                                format: "decimal",
                                text: "%1.",
                                alignment: AlignmentType.LEFT,
                                style: {
                                    paragraph: {
                                        indent: { left: convertInchesToTwip(0.25), hanging: convertInchesToTwip(0.25) },
                                    },
                                },
                            },
                            {
                                level: 1,
                                format: "decimal",
                                text: "%1.%2",
                                alignment: AlignmentType.LEFT,
                                style: {
                                    paragraph: {
                                        indent: { left: convertInchesToTwip(0.25), hanging: convertInchesToTwip(0.25) },
                                    },
                                },
                            },
                            {
                                level: 2,
                                format: "decimal",
                                text: "%1.%2.%3",
                                alignment: AlignmentType.LEFT,
                                style: {
                                    paragraph: {
                                        indent: { left: convertInchesToTwip(0.25), hanging: convertInchesToTwip(0.25) },
                                    },
                                },
                            }
                        ],
                    },
                ]}
        }

        async function generateFile(html, options){
            console.log("File Generated")

            if(html){
                let images = await(assembleImages(html))
                let sections = processSections(html, images, options)

                let doc = new Document({
                    title: options.title?options.title:'Findings Report',
                    description: options.description?options.description:"",
                    styles: docStyles(),
                    numbering: docNumbering(),
                    sections: sections,
                    features:{
                        updateFields: true
                    }
                });

                return Packer.toBlob(doc).then((blob) => {
                    //console.log(blob);
                    saveAs(blob, `${options.title?options.title:'Findings Report'}.docx`);
                    //console.log("Report exported");
                })
            }


        }

        Vue.prototype.$docx = {
            generateFile
        }
    }
}