


export default {
    install(Vue, options){

        var store = options.store;
        var q = Vue.prototype.$db.query;
        //var collection = "colorProfiles";



        function generateProfile(customer){

            //console.log("generating profile", customer);

            let userprofile = store.getters.getUserProfile;
            let created = Date.now();




            let colorProfile = {

                created: created,
                documentType: "Color Profile",
                admin: userprofile.userid,
                user: userprofile.userid,
                userProfile: userprofile,
                customer: {
                    "customerContact" : customer.contactName,
                    "customerCode": customer.customerCode,
                    "address" : "",
                    "organizationName" : customer.organizationName,
                    "city" : "",
                    "state" : "",
                    "country" : "",
                    "postalcode" : "",
                    "email": customer.contactEmail,
                    "phone": ""
                },
                equipment: {
                    name: customer.equipmentModel,
                    model: customer.equipmentModel
                },
                rates: {
                    summary: {
                        adminEmployees: 0,
                        productionEmployees: 0,
                        totalEmployees: 0,
                        markup: 0,
                        impressionCostColor: 0,
                        impressionCostBW: 0,
                        impressionRateColor: 0,
                        impressionRateBW: 0
                    },
                    overhead: {
                        facility: {
                            details: {
                                mortgage: 0,
                                taxes: 0,
                                utilities: 0,
                                maintenance: 0,
                                deprecation: 0,
                                moving: 0,
                                insurance: 0,
                                janitorial: 0,
                                supplies: 0,
                                waste: 0,
                                recycling: 0,
                                other: 0,
                            },
                            summarized: true,
                            total: 0
                        },
                        admin: {

                            details: {
                                banking: 0,
                                salestax: 0,
                                debt: 0,
                                legal: 0,
                                consulting: 0,
                                unions: 0,
                                advertising: 0,
                                postal: 0,
                                dues: 0,
                                donations: 0,
                                safety: 0,
                                permits: 0,
                                office: 0,
                                computers: 0,
                                software: 0,
                                shipping: 0,
                                meals: 0,
                                transportation: 0,
                                vehicleLease: 0,
                                vehicleRepairs: 0,
                                gas: 0,
                                gifts: 0,
                                other: 0
                            },
                            total: 0,
                            summarized: true
                        },
                        personnel: {

                            details: {
                                payroll: 0,
                                medical: 0,
                                life: 0,
                                workerscomp: 0,
                                retirement: 0,
                                training: 0,
                                education: 0,
                                awards: 0,
                                bonus: 0,
                                other: 0,
                                salaries: 0
                            },
                            total: 0,
                            summarized: true
                        },
                        total: 0
                    },
                    equipment: {
                        expenses: {
                            lease: 0,
                            repairs: 0,
                            labor: 0,
                            wage: 0,
                            operators: 0,
                            total: 0,
                            summarized: true
                        },

                        allocation: 0,
                        costPerClickColor: 0,
                        impressionsColor: 0,
                        costPerClickBW: 0,
                        impressionsBW: 0,
                        total: 0
                    },
                },
            };

            return colorProfile;
        }

        function generateColorCalc(){
            let userprofile = store.getters.getUserProfile;
            let created = Date.now();
            let record = {
                created: created,

                documentType: "Specialty Color Cost Calc",
                admin: userprofile.userid,
                user: userprofile.userid,
                customer: userprofile,
                userProfile: userprofile,
                projectName: "",
                equipment: "",
                tonerPrice: {
                    clear: 0,
                    white: 0,
                    yellow: 0,
                    pink: 0,
                    red: 0,
                    silver: 0,
                    gold: 0
                },
                color: "",
                density: 0,
                percentSpecial: 0,
                paperSize: "8.5 x 11",
                impressions: 0,
                impressionCost: 0,
                totalCost: 0,
            }

            return record;

        }

        function copyRecord(old){


            let newProject;

            let recordType = old.documentType;

            if(recordType == "Specialty Color Project"){
                newProject = generateProject();
            }else if(recordType == "Equipment Rate Calc"){
                newProject = generateEquipmentRate()
            }else if(recordType == "Specialty Color Cost Calc"){
                newProject = generateColorCalc()
            }else{
                return;
            }


            //these key will keep new values, all else will written over from old
            let metaKeys = {
                created: true,
                documentType: true,
                admin: true,
                user: true,
                userProfile: true,
                customer: true,
            }

            if(old) {
                Object.keys(newProject).forEach(key =>
                {
                    if(!metaKeys[key]){
                        newProject[key] = old[key];
                    }

                })
            }


            return newProject;

        }

        function generateProject(){

            let userprofile = store.getters.getUserProfile;
            let created = Date.now();

            let project = {

                created: created,
                documentType: "Specialty Color Project",
                admin: userprofile.userid,
                user: userprofile.userid,
                userProfile: userprofile,
                customer: userprofile,
                equipment: "",
                projectName: "",
                quantity: 0,
                paperSize: "8.5 x 11",
                pages: 0,
                pagesColor: 0,
                pagesBW: 0,
                up: 1,
                materialCost: 0,
                spoilage: 0,
                impressionsColor: 0,
                impressionsBW: 0,
                changeTime: 0,
                operatorWage: 0,
                costPerClickColor: 0,
                costPerClickBW: 0,
                costPerClickCalculated: false,
                impressionCostColor: 0,
                impressionCostBW: 0,
                changeoverCost: 0,
                prepCost: 0,
                totalMaterialCost: 0,
                finishingSupplies: 0,
                binderyCost:0,
                totalCost: 0,
                impressionMarkup: 0,
                specialtyColorMarkup: 0,
                prepMarkup: 0,
                materialMarkup: 0,
                finishingMarkup: 0,
                totalPrice: 0,
                profitability: 0,

                specialtyColor: {
                    tonerPrice: {
                        clear: 0,
                        white: 0,
                        yellow: 0,
                        pink: 0,
                        red: 0,
                        silver: 0,
                        gold: 0
                    },
                    color: "",
                    density: 0,
                    percentSpecial: 0,
                    paperSize: "8.5 x 11",
                    impressions: 0,
                    impressionCost: 0,
                    totalCost: 0,
                    calculated: false
                }

            };

            return project;

        }

        function generateEquipmentRate(){

            let userprofile = store.getters.getUserProfile;
            let created = Date.now();

            let rates =  {
                created: created,
                documentType: "Equipment Rate Calc",
                admin: userprofile.userid,
                user: userprofile.userid,
                userProfile: userprofile,
                customer: userprofile,
                projectName: "",
                summary: {
                    adminEmployees: 0,
                        productionEmployees: 0,
                        totalEmployees: 0,
                        markup: 0,
                        impressionCostColor: 0,
                        impressionCostBW: 0,
                        impressionRateColor: 0,
                        impressionRateBW: 0
                },
                overhead: {
                    facility: {
                        details: {
                            mortgage: 0,
                                taxes: 0,
                                utilities: 0,
                                maintenance: 0,
                                deprecation: 0,
                                moving: 0,
                                insurance: 0,
                                janitorial: 0,
                                supplies: 0,
                                waste: 0,
                                recycling: 0,
                                other: 0,
                        },
                        summarized: true,
                            total: 0
                    },
                    admin: {

                        details: {
                            banking: 0,
                                salestax: 0,
                                debt: 0,
                                legal: 0,
                                consulting: 0,
                                unions: 0,
                                advertising: 0,
                                postal: 0,
                                dues: 0,
                                donations: 0,
                                safety: 0,
                                permits: 0,
                                office: 0,
                                computers: 0,
                                software: 0,
                                shipping: 0,
                                meals: 0,
                                transportation: 0,
                                vehicleLease: 0,
                                vehicleRepairs: 0,
                                gas: 0,
                                gifts: 0,
                                other: 0
                        },
                        total: 0,
                            summarized: true
                    },
                    personnel: {

                        details: {
                            payroll: 0,
                                medical: 0,
                                life: 0,
                                workerscomp: 0,
                                retirement: 0,
                                training: 0,
                                education: 0,
                                awards: 0,
                                bonus: 0,
                                other: 0,
                                salaries: 0
                        },
                        total: 0,
                            summarized: true
                    },
                    total: 0
                },
                equipment: {
                    expenses: {
                        lease: 0,
                            repairs: 0,
                            labor: 0,
                            wage: 0,
                            operators: 0,
                            total: 0,
                            summarized: true
                    },

                    allocation: 0,
                        costPerClickColor: 0,
                        impressionsColor: 0,
                        costPerClickBW: 0,
                        impressionsBW: 0,
                        total: 0
                },

            }



            return rates;

        }

        function create(obj, collection){
            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })


            var createQ = client.query(
                q.Create(
                    q.Collection(collection),
                    {
                        data: obj
                    }
                ),
                { secret: store.getters.getToken }
            )

            return createQ.then((response) =>{
                return response;
            })
        }

        function update(obj, refId, collection){
            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })


            var updateQ = client.query(
                q.Update(
                    q.Ref(q.Collection(collection), refId),
                    {
                        data: obj
                    })
            )

            return updateQ.then((response) =>{
                return response;
            })
        }

        function remove(refId, collection){
            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })


            var updateQ = client.query(
                q.Delete(
                    q.Ref(q.Collection(collection), refId))
            )

            return updateQ.then((response) =>{
                return response;
            })
        }

        function load(refId, collection){

            //console.log("loading record", refId, collection);
            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })



            return client.query(
                q.Get(q.Ref(q.Collection(collection), refId))
            ).then((response) => {


                if(response.data){
                    let record = response.data;
                    record.id = response.ref.value.id;



                    return record;
                }else{
                    throw new Error("Document error.");
                }
            })

        }

        function loadProfilesForAdmin(adminRefId){
            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })

            //console.log("loading profiles for admin", adminRefId);

            return client.query(

                    q.Paginate(
                        q.Match(
                            q.Index("color_profiles_for_admin"),
                            adminRefId
                        )
                    )
            )
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    new Error("Network error.");
                })

        }

        function loadProjectsForProfile(profileId){
            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })

            //console.log("loading profiles for profile", profileId);

            return client.query(

                q.Paginate(
                    q.Match(
                        q.Index("color_projects_for_user"),
                        profileId
                    )
                )
            )
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    new Error("Network error.");
                })

        }

        function loadColorCalcsForProfile(profileId){
            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })

            //console.log("loading color calc for profile", profileId);

            return client.query(

                q.Map(
                    q.Paginate(
                        q.Match(
                            q.Index("color_calcs_for_user_by_ts"),
                            profileId
                        )
                    ),
                    q.Lambda("calc", q.Get(q.Select([1], q.Var("calc"))))
                )
            )
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    new Error("Network error.");
                })

        }

        function loadLastColorCalcsForProfile(profileId){
            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })

            //console.log("loading color calc for profile", profileId);

            return client.query(
                q.Map(
                    q.Paginate(
                        q.Match(
                            q.Index("color_calcs_for_user_by_ts"),
                            profileId
                        ),
                        { size: 1 }
                    ),
                    q.Lambda("calc", q.Get(q.Select([1], q.Var("calc"))))
                )
            )
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    new Error("Network error.");
                })

        }

        function loadEquipmentRatesForProfile(profileId){
            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })

            //console.log("loading rates for profile", profileId);

            return client.query(

                q.Paginate(
                    q.Match(
                        q.Index("rate_calcs_for_user"),
                        profileId
                    )
                )
            )
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    new Error("Network error.");
                })

        }

        function loadUserProfile(refId){
            let token = store.getters.getToken;
            //console.log("loading profile", refId, token);
            var client = new Vue.prototype.$db.Client({ secret: token })



            return client.query(
                q.Get(q.Ref(q.Collection("users"), refId))
            ).then((response) => {


                if(response.data){
                    let record = response.data;
                    record.id = response.ref.value.id;
                    return record;
                }else{
                    throw new Error("Document error.");
                }
            })

        }



        Vue.prototype.$colorCalc = {
            generateProfile: generateProfile,
            generateColorCalc: generateColorCalc,
            generateEquipmentRate: generateEquipmentRate,
            generateProject: generateProject,
            loadProjectsForProfile: loadProjectsForProfile,
            loadColorCalcsForProfile: loadColorCalcsForProfile,
            loadLastColorCalcsForProfile:loadLastColorCalcsForProfile,
            loadEquipmentRatesForProfile: loadEquipmentRatesForProfile,
            loadProfilesForAdmin: loadProfilesForAdmin,
            loadUserProfile: loadUserProfile,
            create: create,
            load: load,
            update: update,
            remove: remove,
            copyRecord: copyRecord
        };
    }
}
