


<template>
<div class="progress-dot-bar flex">

      <div class="text-center w-24 relative marker cursor-pointer" v-for="(marker, index) in markers" :key="index" :data-active="index == selected" @click="onMarkerClick(index)">
        <div class="w-full relative">
          <div class="progress-border bg-gray-400"></div>
          <div class="dot inline-block h-4 w-4 border-4 border-gray-400 rounded-full text-center relative bg-white"></div>
        </div>

          <p class="text-xs mt-1 text-white label opacity-50" v-html="marker.label"></p>
      </div>

</div>
</template>

<script>
export default {
    name: "progress-dot-bar",
    props: {
      markers: {
        type: Array,
        default: function(){
          return [];
        }
      },

      selected: {
        default: 0
      }
    },
    data: function(){
        return {}
        },
    methods: {
      onMarkerClick: function(index){
        //console.log("marker",index);
        this.$emit("select", index);
      }
    },
    computed: {}
}
</script>

<style scoped>


.progress-dot-bar {

}



.progress-border {
  width: 100%;
  height: 4px;
  position: absolute;
  top: calc(50% - 4px);
}

.progress-dot-bar .marker:first-child .progress-border {
  width: 50%;
  left: 50%;
}

.progress-dot-bar .marker:last-child .progress-border {
  width: 50%;
}

.marker .dot {
  transition: all 0.3s;
}

.marker[data-active="true"] .dot {
  @apply border-white;
  @apply bg-brand-blue;
  @apply border-2;
  transform: scale(1.5);
}

.marker[data-active="true"] .label {
  opacity: 1.0;
}

.marker:hover .dot {
  transform: scale(1.5);
  border-width: 2px;
}

.marker:hover .label {
  opacity: 1.0;
}

</style>