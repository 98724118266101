


<template>
<div class="pre-customer-profile">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold">This worksheet is to be filled out from the Quote/Proposal document presented by Ricoh to the customer.</p>
  </div>
  <div>
    <h2 class="font-bold text-xl pb-2 border-b-2 pl-4">Mail Solution Costs</h2>
  </div>
  <div class="" v-if="costs">

    <table class="w-full bg-white border-r border-l text-sm">
      <thead>
        <tr class="">
          <th class="text-left px-4 align-bottom border-r bg-gray-100 w-32">Capital</th>
          <th class="align-bottom border-r bg-gray-100">Initial Investment</th>
          <th class="align-bottom border-r bg-gray-100">Year 1</th>
          <th class="align-bottom border-r bg-gray-100">Year 2</th>
          <th class="align-bottom border-r bg-gray-100">Year 3</th>
          <th class="align-bottom border-r bg-gray-100">Year 4</th>
          <th class="align-bottom bg-gray-100">Year 5</th>
        </tr>
      </thead>
      <tbody>

          <tr v-for="capRow in capitalRows" :key="capRow.key">
            <td class="py-2 px-4 border-r w-32">{{ capRow.label }}</td>
            <td class="border-r p-1" v-for="capCol in cols" :key="capRow.key +'_'+ capCol.key">
              <number-input class="w-full px-2 py-1 text-right rounded-none" format="$" precision="0" @change="onChange" v-model="costs[capRow.key][capCol.key]" />
            </td>
          </tr>
          <tr>
            <td class="py-2 px-4 border-r w-32"></td>
            <td class="border-r p-1 py-2 text-right " v-for="capCol in cols" :key="'capital_'+ capCol.key">
              <span class="w-full px-2 rounded-none">{{ costs.capitalTotals[capCol.key] | currency(true) }}</span>
            </td>
          </tr>


      </tbody>

    </table>

    <table class="w-full bg-white border-r border-l text-sm">
      <thead>
      <tr class="">
        <th class="text-left px-4 align-bottom border-r bg-gray-100 w-32">Operating Expenses</th>
        <th class="align-bottom border-r bg-gray-100">Initial Investment</th>
        <th class="align-bottom border-r bg-gray-100">Year 1</th>
        <th class="align-bottom border-r bg-gray-100">Year 2</th>
        <th class="align-bottom border-r bg-gray-100">Year 3</th>
        <th class="align-bottom border-r bg-gray-100">Year 4</th>
        <th class="align-bottom bg-gray-100">Year 5</th>
      </tr>
      </thead>
      <tbody>

      <tr v-for="expRow in expensesRows" :key="expRow.key">
        <td class="py-2 px-4 border-r">{{ expRow.label }}</td>
        <td class="border-r p-1" v-for="expCol in cols" :key="expRow.key +'_'+ expCol.key">
          <number-input class="w-full px-2 py-1 text-right rounded-none" format="$" precision="0" @change="onChange" v-model="costs[expRow.key][expCol.key]" />
        </td>
      </tr>
      <tr>
        <td class="py-2 px-4 border-r"></td>
        <td class="border-r p-1 py-2 text-right " v-for="expCol in cols" :key="'expense_'+ expCol.key">
          <span class="w-full px-2 rounded-none">{{ costs.expenseTotals[expCol.key] | currency(true) }}</span>
        </td>
      </tr>

      </tbody>
      <tfoot>
      <tr class="text-base">
        <th class="py-2 px-4 border-r text-left"></th>
        <th class="border-r p-1 py-1 text-right" v-for="totalCol in cols" :key="'spacer_'+ totalCol.key">
          <span class="w-full px-2 rounded-none font-bold"></span>
        </th>
      </tr>

        <tr class="text-base bg-gray-100">
          <th class="py-2 px-4 border-r text-left">TOTAL</th>
          <th class="border-r p-1 py-2 text-right" v-for="totalCol in cols" :key="'totals_'+ totalCol.key">
            <span class="w-full px-2 rounded-none font-bold">{{ costs.totals[totalCol.key] | currency(true) }}</span>
          </th>
        </tr>

      </tfoot>

    </table>

    <div class="mt-8 relative">
      <div class="pb-4 flex justify-between w-full border-b items-center pl-4">
        <label class="flex-grow pr-8 ">Discount Rate</label>
        <number-input format="%" prefix="%" class="flex-grow-0 block border p-2 mt-1 rounded w-32 text-right" @change="onChange" v-model="costs.discountRate"></number-input>
      </div>
      <div class="pb-4 flex justify-between w-full border-b items-center pl-4">
        <label class="flex-grow pr-8 ">Implementation Time</label>
        <number-input data-format="#" class="flex-grow-0 block border p-2 mt-1 rounded w-32 text-right" @change="onChange" v-model="costs.implementationTime"></number-input>
      </div>
      <div class="py-4 flex justify-between w-full border-b items-center pl-4">
        <label class="flex-grow pr-8 ">Adoption Time</label>
        <number-input data-format="#" class="flex-grow-0 block border p-2 mt-1 rounded w-32 text-right" @change="onChange" v-model="costs.adoptionTime"></number-input>
      </div>
      <div class="py-4 flex justify-between w-full items-center pl-4">
        <label class="flex-grow pr-8 ">First month to realize investment</label>
        <number-input data-format="#" class="flex-grow-0 block border p-2 mt-1 rounded w-32 text-right" @change="onChange" v-model="costs.firstMonthToRealization" disabled="true"></number-input>
      </div>
    </div>


    <div class="pt-4 border-t">
      <div class="pb-4 flex justify-between">
        <div class="pr-4">
          <button class="button button-green" @click="$emit('summary')">Save & Go to Summary</button>
        </div>
        <div class="pl-4">
          <button class="button button-blue button-next" @click="$emit('next')">Save & Next ROI <img class="inline-block ml-2 h-3" src="../assets/icons/next-arrow-icon.svg" /></button>
        </div>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import NumberInput from "@/components/NumberInput";
export default {
    name: "mail-solution-costs-table",
  components: {NumberInput},
  props: {
      options: {
        default: function(){
          return {
            label: "Solution Costs",
            costsKey: "",
            instructions: "Costs instructions"
          }
        }
      }
    },
    data: function(){
        return {
          capitalRows: [
            { label: "Software", key:"software" },
            { label: "Hardware", key:"hardware" },
            { label: "Installation", key:"installation" },
          ],
          expensesRows: [
            { label: "Annual Lease Expense", key:"lease" },
            { label: "Maintenance", key:"maintenance" },
            { label: "Training", key:"training" },
          ],
          cols: [
              { label: "Initial Investment", key: "initial"},
            { label: "Year 1", key: "year1"},
            { label: "Year 2", key: "year2"},
            { label: "Year 3", key: "year3"},
            { label: "Year 4", key: "year4"},
            { label: "Year 5", key: "year5"},

          ]
        }
        },
    methods: {
      onChange: function(){
        this.$emit("change");
      }
    },
    computed: {
      costs: function(){
        if(this.$store.state.workflowAssessment && this.$store.state.workflowAssessment.costs){
          return this.$store.state.workflowAssessment.costs.solution
        }

        return null;
      }
    }
}
</script>

<style scoped>


.pre-customer-profile {

}

</style>