


<template>
  <div class="color-calulator bg-gray-50">
    <div class="text-xs px-8 py-2 pt-4 bg-white">
      <router-link to="/">Home</router-link> /
      <router-link to="/calculators">Calculators</router-link> /
      <span>Specialty Color Cost Estimate</span>
    </div>
    <div class="sm:flex justify-between items-center px-8 pt-0 pb-5 bg-white">
      <h1 class="text-3xl leading-none flex items-baseline"><span v-if="profile" class="mr-2">{{ profile.organization?profile.organization:"Production Printing" }}</span></h1>
      <div class="flex pt-4 sm:pt-0 sm:-mt-2 text-sm">
        <button class="button mr-2" @click="showProjects"><img class="inline-block mr-2 h-4" src="../assets/icons/list-icon.svg" /> Saved <span class="hidden sm:inline">Calculations</span></button>
        <div class="dropdown">
          <button class="button relative" >Options</button>
          <div class="options absolute z-50 hidden pt-1">
            <div class="bg-white border rounded">
              <div class="px-4 py-3 border-b cursor-pointer hover:bg-gray-100" @click="saveProject"><img class="w-3 object-contain inline-block mr-1 h-3" src="../assets/icons/save-icon.svg" /> Save Changes</div>
              <div class="px-4 py-3 border-b cursor-pointer hover:bg-gray-100" @click="copyProject"><img class="w-3 object-contain inline-block mr-1 h-3" src="../assets/icons/copy-icon.svg" /> Create Copy</div>
              <div class="px-4 py-3 border-b cursor-pointer hover:bg-gray-100" @click="resetProject"><img class="w-3 object-contain inline-block mr-1 h-3" src="../assets/icons/list-icon.svg" /> Clear All Fields</div>
              <div class="px-4 py-3 border-b cursor-pointer hover:bg-gray-100" @click="newProject"><img class="w-3 object-contain inline-block mr-1 h-3" src="../assets/icons/new-doc-icon-dark.svg" /> Start New Project</div>
              <div class="px-4 py-3 cursor-pointer hover:bg-red-600 hover:text-white" @click="deleteProject"><svg class="w-3 h-3 inline-block mr-1 ml-0 fill-current" xmlns="http://www.w3.org/2000/svg" width="21" height="27" viewBox="0 0 21 27">
                <path d="M9,28.5a3.009,3.009,0,0,0,3,3H24a3.009,3.009,0,0,0,3-3v-18H9Zm3.69-10.68L14.805,15.7,18,18.885l3.18-3.18L23.3,17.82,20.115,21l3.18,3.18L21.18,26.3,18,23.115,14.82,26.3,12.7,24.18,15.885,21ZM23.25,6l-1.5-1.5h-7.5L12.75,6H7.5V9h21V6Z" transform="translate(-7.5 -4.5)"/>
              </svg>
                Delete Project</div>
            </div>
          </div>
        </div>
        <button class="button ml-2 border-red-500 text-red-500" @click="showTutorial"><span class="">Tutorial</span></button>
        <div class="w-8 h-8 p-2 border-gray-300 border-2 rounded-full flex justify-center items-center mt-1 ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="exitProject">
          <img  class="h-3 inline-block " src="../assets/icons/close-icon.svg" />
        </div>

      </div>

    </div>
    <div v-if="specialtyColor" class="border-t-4 text-sm px-8">


      <h2 class="font-bold uppercase text-xl pb-2 pt-6">SPECIALTY COLOR COST CALCULATION <span v-if="loading" class="text-sm opacity-50">Loading...</span></h2>
    <div class="border-t pt-4 max-w-4xl">
      <div class="">


        <div class="flex items-center justify-between pt-1 ">
          <label>Project Name</label>

          <div class="w-96 relative group cursor-pointer">
            <input class="w-full p-2 mt-1 rounded text-left border"  v-model="specialtyColor.projectName"  @change="onChange" />
          </div>
        </div>

        <div class="flex items-center justify-between pt-1 mt-1">
          <label>Equipment</label>
          <div class="w-72 relative group cursor-pointer">
            <select-input :options="models" prompt-text="Select Model" v-model="specialtyColor.equipment" @change="onChange"></select-input>
          </div>
        </div>
      </div>


      <div class="mt-4 pr-8">
        <h3 class="font-bold mb-2"><span class="border-b pb-2">Specialty Toner Price per Bottle</span></h3>
        <div class="flex items-center justify-between pt-1">
          <label>Clear</label>

            <div class="w-48 relative group cursor-pointer border-b hover:border-black">
              <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="specialtyColor.tonerPrice.clear" format="$" precision="2" @change="onChange"></number-input>
              <div class="absolute right-0 top-0 w-4 h-4 mt-3 -mr-6 border" style=""></div>
            </div>


        </div>
        <div class="flex items-center justify-between pt-1">
          <label>White</label>
          <div class="w-48 relative group cursor-pointer border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="specialtyColor.tonerPrice.white" format="$" precision="2" @change="onChange" ></number-input>
            <div class="absolute right-0 top-0 w-4 h-4 mt-3 -mr-6 border" style="background-color: white;"></div>
          </div>
        </div>
        <div class="flex items-center justify-between pt-1">
          <label>Neon Yellow</label>
          <div class="w-48 relative group cursor-pointer border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="specialtyColor.tonerPrice.yellow" format="$" precision="2" @change="onChange" ></number-input>
            <div class="absolute right-0 top-0 w-4 h-4 mt-3 -mr-6 border" style="background-color: yellow;"></div>
          </div>
        </div>

        <div class="flex items-center justify-between pt-1">
          <label>Neon Pink</label>
          <div class="w-48 relative group cursor-pointer border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="specialtyColor.tonerPrice.pink" format="$" precision="2" @change="onChange" ></number-input>
            <div class="absolute right-0 top-0 w-4 h-4 mt-3 -mr-6 border" style="background-color: hotpink;"></div>
          </div>
        </div>

        <div class="flex items-center justify-between pt-1">
          <label>Invisible Red</label>
          <div class="w-48 relative group cursor-pointer border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="specialtyColor.tonerPrice.red" format="$" precision="2" @change="onChange" ></number-input>
            <div class="absolute right-0 top-0 w-4 h-4 mt-3 -mr-6 border" style="background-color: red;"></div>
          </div>
        </div>

        <div class="flex items-center justify-between pt-1" :class="silverGoldCompatible?'':'opacity-50 pointer-events-none'">
          <label>Silver</label>
          <div class="w-48 relative group cursor-pointer border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="specialtyColor.tonerPrice.silver" format="$" precision="2" @change="onChange" :disabled="!silverGoldCompatible"></number-input>
            <div class="absolute right-0 top-0 w-4 h-4 mt-3 -mr-6 border" style="background-color: silver;"></div>
          </div>
        </div>

        <div class="flex items-center justify-between pt-1" :class="silverGoldCompatible?'':'opacity-50 pointer-events-none'">
          <label>Gold</label>
          <div class="w-48 relative group cursor-pointer border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="specialtyColor.tonerPrice.gold" format="$" precision="2" @change="onChange" :disabled="!silverGoldCompatible"></number-input>
            <div class="absolute right-0 top-0 w-4 h-4 mt-3 -mr-6 border" style="background-color: gold;"></div>
          </div>
        </div>
      </div>

      <div class="mt-8 pr-8">
        <h3 class="font-bold mb-2"><span class="border-b pb-2">Project Specs</span></h3>
        <div class="flex items-center justify-between pt-1">
          <label>Specialty Color</label>
          <div class="w-48 relative group cursor-pointer">
            <select-input :options="colorChoices" prompt-text="Select Color" v-model="specialtyColor.color" @change="onChange"></select-input>
          </div>
        </div>

        <div class="flex items-center justify-between pt-1">
          <label>Paper Size</label>
          <div class="w-48 relative group cursor-pointer ">
            <select-input :options="paperChoices" prompt-text="Select Paper Size" v-model="specialtyColor.paperSize" @change="onChange"></select-input>
          </div>
        </div>

        <div class="flex items-center justify-between pt-1">
          <label>Machine Density Settings - Maximum Image Density</label>
          <div class="w-48 relative group cursor-pointer ">
            <select-input :options="densityRatings" prompt-text="0" v-model="specialtyColor.density" @change="onChange"></select-input>
          </div>
        </div>

        <div class="flex items-center justify-between pt-1">
          <label>% of Specialty Color <span class="text-xs">( <a tabindex="-1" class="underline text-brand-blue" href="samples/Toner_Percent_Samples-2021.pdf" target="_blank">View Examples</a> )</span></label>
          <div class="w-48 relative group border-b">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="specialtyColor.percentSpecial" format="%" precision="0"  @change="onChange"></number-input>
          </div>
        </div>



        <div class="flex items-center justify-between pt-1">
          <label># of Specialty Color Impressions</label>
          <div class="w-48 relative group border-b">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="specialtyColor.impressions" format="" precision="0" @change="onChange"></number-input>
          </div>
        </div>


      </div>


      <h3 class="font-bold text-brand-blue mt-8"><span class="">Your Calculated Costs</span></h3>




      <div class="mt-2 p-4 pt-3 pr-4 mr-2 border border-brand-blue bg-white text-brand-blue rounded">
        <div class="flex items-center justify-between pt-1">
          <label class="">Specialty Color Impression (8.5 x 11) Cost*</label>
          <div class="w-48">
            <p class="text-xl w-full px-2 pb-1 text-right font-bold border-b-2 border-brand-blue text-brand-blue">${{ specialtyColor.impressionCost | decimal(4) }}</p>
          </div>
        </div>

        <div class="flex items-center justify-between pt-1">
          <label class="">Specialty Color Cost per Paper Size Selected {{ specialtyColor.paperSize?`(${specialtyColor.paperSize})`:"" }}*</label>
          <div class="w-48">
            <p class="text-xl w-full px-2 pb-1 text-right font-bold border-b-2 border-brand-blue text-brand-blue">${{ costPerPaperSize | decimal(4) }}</p>
          </div>
        </div>

        <div class="flex items-center justify-between pt-2">
          <label class="">Total Specialty Color Cost for this Project*</label>
          <div class="w-48">
            <p class="text-xl w-full px-2 pb-1 text-right font-bold border-b-2 border-brand-blue text-brand-blue">{{ specialtyColor.totalCost  | currency }}</p>
          </div>
        </div>
      </div>
    </div>


  </div>

    <div class="px-8 text-sm text-brand-blue">
      <p class="mt-4">* Ricoh does not warrant financial or performance measures.  Your results may vary.</p>
      <p class="mt-2">* Calculations are based on assumptions and may not represent actual use or yields and are used for job estimates only.</p>
    </div>

    <div class="pt-4 mt-4 border-t mx-8 max-w-4xl">
      <div class="pb-4 sm:flex justify-between">
        <div class="sm:pr-4">
          <button  class="mt-2 sm:mt-0 button mr-4 w-full sm:w-auto" @click="resetProject">Clear Fields</button>


        </div>
        <div class="sm:pl-4 sm:flex">
          <button  class="mt-2 sm:mt-0 button mr-4 w-full sm:w-auto" @click="exitProject">Return to Calculators</button>
          <button class="mt-2 sm:mt-0 button button-blue w-full sm:w-auto" @click="saveProject"><span>Save Calculation <img v-if="saving" class="ml-2 inline-block h-4" src="../assets/icons/tail-spin.svg" /></span></button>
        </div>

      </div>
    </div>





    <transition name="fade-on">
      <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="sidePanel && profile">
        <side-panel-color-calcs class=""  @load="loadSavedProject" :has-changes="hasUnsavedChanges"  @close="closeSidePanel"></side-panel-color-calcs>
      </div>
    </transition>

    <transition name="fade-on">
      <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="tutorial">
        <video-window class="" video="speciality-color-tutorial.mp4" title="Tutorial" @close="closeTutorial"></video-window>
      </div>
    </transition>



</div>
</template>

<script>
// import TabBar from "@/components/TabBar";
// import ColorRateCalculatorRates from "@/components/ColorRateCalculatorRates";
// import ColorRateCalculatorOverhead from "@/components/ColorRateCalculatorOverhead";



import NumberInput from "@/components/NumberInput";
import SelectInput from "@/components/SelectInput";
import SidePanelColorCalcs from "@/components/SidePanelColorCalcs";
import bodyScroll from 'body-scroll-toggle';
import VideoWindow from "@/components/ColorCalc/VideoWindow";

export default {
    name: "specialty-color-cost-calculator",
  components: {
    SidePanelColorCalcs,
    SelectInput, NumberInput, VideoWindow},
  mounted: function(){



    if(this.$route.params.project){
      this.loadProject(this.$route.params.project);
    }else{
      this.specialtyColor = this.$colorCalc.generateColorCalc();
      this.modalOptions = true;
      //console.log(this.profile);
      this.$colorCalc.loadLastColorCalcsForProfile(this.profile.userid)
          .then((calcs) => {
            if(calcs.data && calcs.data.length){
              this.specialtyColor.tonerPrice = calcs.data[0].data.tonerPrice;
            }
          })
    }
  },
  props: {},
    data: function(){
        return {
          modalOptions: false,
          saving: false,
          loading: false,
          hasUnsavedChanges: false,
          modalScrollPos: 0,
          sidePanel: "",
          currentTabIndex: 1,
          specialtyColor: undefined,
          tutorial: false,

          paperChoices: [
            '8.5 x 11',
            '11 x 17',
            '12 x 18',
            '13 x 19',
              '13 x 27.5'
          ],
          densityRatings: [ 5, 4, 3, 2, 1, 0, -1, -2, -3, -4, -5]
        }
        },
    methods: {

      showProjects: function(){
        bodyScroll.disable();
        this.sidePanel = true;
        this.modalOptions = false;
      },

      showTutorial: function(){
        bodyScroll.disable();
        this.tutorial = true;
      },

      closeTutorial: function(){
        bodyScroll.enable();
        this.tutorial = false;
      },

      copyProject: function(){

        let newName = prompt("Enter the new project name:");

        if(newName){
          let newProject = this.$colorCalc.copyRecord(this.specialtyColor);

          newProject.projectName = newName;

          this.specialtyColor = newProject;

          this.saveNewProject();
        }

      },

      saveProject: function(){

        if(!this.specialtyColor.projectName){
          window.scrollTo(0,0);
          alert("You must enter a project name.");
          return;
        }else if( !this.specialtyColor.id ){
          this.saveNewProject();
          return;
        }

        this.saving = true;
        this.$colorCalc.update(this.specialtyColor, this.specialtyColor.id, 'colorCalcs')
            .then(() => {
              this.lastSave = Date.now();
              this.saving = false;
              this.hasUnsavedChanges = false;
              alert("This project has been saved.")
            }).catch((error) => {
          console.error("Error", error);
          this.saving = false;
          alert("Something went wrong with this operation. Please try again.")
        })

      },

      newProject: function(){
        if(this.hasUnsavedChanges){
          if(!confirm("This will erase any unsaved changes. Click OK to proceed.")) {
            return;
          }
        }


        let tonerPrices;
        if(this.specialtyColor){
          tonerPrices = { ...this.specialtyColor.tonerPrice };
        }


        this.specialtyColor = this.$colorCalc.generateColorCalc();
        this.specialtyColor.tonerPrice = tonerPrices;



        this.hasUnsavedChanges = false;

        if(this.$route.params.project){
          this.$router.push({
            name: "New Specialty Color Cost Calc",
          })
        }

      },

      saveNewProject: function(){
        this.$colorCalc.create(this.specialtyColor, "colorCalcs")
            .then((response) => {

              if(response.data && response.data.projectName){
                this.specialtyColor.id = response.ref.value.id;
                alert("This project has been saved.")
                //console.log("redirecting", response.ref);
                this.$router.push({
                  name: "Specialty Color Cost Calc",
                  params: {
                    project: this.specialtyColor.id
                  }
                }).catch(()=>{
                  //console.log("");
                })
              }
            })
            .catch((error) => {
              console.error("Error", error);
              alert("Something went wrong with this operation. Please try again.");
            })
      },

      deleteProject: function(){
        if(this.specialtyColor){
          if(confirm('Are you sure you want to delete this calculation?')){

            if(this.specialtyColor.id){
              this.$colorCalc.remove(this.specialtyColor.id, 'colorCalcs')
                  .then(() => {
                    this.$router.push({
                      name: "Calculators",
                    })
                  })
                  .catch((error) => {
                    console.error(error);
                    alert("There was a problem with this operation. Try again.");

                  })

            }else{
              this.$router.push({
                name: "Calculators",
              })
            }
          }
        }

      },

      resetProject: function(){

        if(confirm("This will clear you current field values. Click OK to proceed.")){

          this.specialtyColor.tonerPrice = {
              clear: 0,
              white: 0,
              yellow: 0,
              pink: 0,
              red: 0,
              silver: 0,
              gold: 0
            }

            //this.specialtyColor.projectName = "";
            this.specialtyColor.equipment = "";
            this.specialtyColor.color = "";
            this.specialtyColor.density = 0;
            this.specialtyColor.percentSpecial = 0;
            this.specialtyColor.paperSize = "8.5 x 11";
            this.specialtyColor.impressions = 0;
            this.specialtyColor.impressionCost = 0;
            this.specialtyColor.totalCost = 0;


          this.hasUnsavedChanges = true;
          this.calculate();

        }

      },

      exitProject: function(){
        if(this.hasUnsavedChanges){
          if(confirm("There are unsaved changes to this calculation. If you exit before saving you will lose these changes.")){
            this.$router.push({
              name: "Calculators",
            })
          }
        }else{
          this.$router.push({
            name: "Calculators",
          })
        }
      },

      loadSavedProject: function(project){
        bodyScroll.enable();
        this.$router.push({
          name: "Specialty Color Cost Calc",
          params: {
            project: project.id
          }
        }).catch(() => {
          //console.log("");
        })
        this.loadProject(project.id);
        this.sidePanel = false;
      },

      loadProject: function(id){

        this.loading = true;

        this.$colorCalc.load(id, 'colorCalcs')
            .then((response) => {
              if(response.tonerPrice){
                // eslint-disable-next-line no-prototype-builtins
                if(!response.tonerPrice.hasOwnProperty("gold")){
                  response.tonerPrice.gold = 0;
                  response.tonerPrice.silver = 0;
                }
              }

              this.specialtyColor = response;
              this.loading = false;
            })
            .catch((error) => {
              console.error("Error", error);
              this.loading = false;
            })
      },


      applyRate: function(){
        //console.log("apply");

        this.$colorCalc.update(this.profile, this.profile.userid, 'colorProfiles').then(() => {
          this.$emit('change');
          this.$emit('close');
        }).catch((error) => {
          console.error("Error", error);
          alert("Something went wrong with this operation. Try again.")
        })


      },

      cancel: function(){
        //console.log('close');
          this.$emit('close');
      },

      closeSidePanel: function(){
        //console.log("close panel");
        bodyScroll.enable();
        this.sidePanel = "";
      },

      calculate: function(){
          let calcCoverage = 0;

          if(!this.specialtyColor.color || !this.specialtyColor.equipment){
            return;
          }

        if(this.specialtyColor.color === "White"){
            calcCoverage = (16.3 * this.specialtyColor.density) * .01 + (this.specialtyColor.percentSpecial * 0.01);
        }else{
            calcCoverage = (4.5 * this.specialtyColor.density) * .01  + (this.specialtyColor.percentSpecial * 0.01);
        }

        if(calcCoverage > 0.99){
          calcCoverage = 0.99
        }

        if(calcCoverage < 0.02){
          calcCoverage = 0.02
        }

        let modelPrefix = "50";

        if(this.specialtyColor.impressions < 50){
          modelPrefix = "5";
        }

        let modelKey = modelPrefix + "P/J";
        let model = this.modelCodes[this.specialtyColor.equipment];

        let modelMode = "simplex";





        let yieldData = this.coverageTables[model][this.specialtyColor.color][modelKey][modelMode];


        let maxIndex = this.coverageCols.findIndex((pct) => {
          return (calcCoverage <= pct);
        })

        //console.log("Percent Coverage", calcCoverage);

        if(maxIndex < 0){
          maxIndex = this.coverageCols.length - 1;
        }

        let minIndex = maxIndex - 1;
        if(minIndex < 0){
          minIndex = 0;
        }

        if(minIndex === maxIndex){
          maxIndex = maxIndex + 1;
        }

        let interpYield = 0;



        let maxYield = yieldData[maxIndex];
        let maxCoverage = this.coverageCols[maxIndex];
        let minYield = yieldData[minIndex];
        let minCoverage = this.coverageCols[minIndex];



        let yRange = Math.abs(maxYield - minYield);
        let cRange = maxCoverage - minCoverage;

        let rangeRatio = (calcCoverage - minCoverage) / cRange;

        console.log(maxCoverage, minCoverage, cRange, yRange, rangeRatio);
        let yIntersect = yRange * rangeRatio;

        interpYield = minYield - yIntersect;


        let colorCode = this.specialtyColor.color.split(" ").pop().toLowerCase();
        console.log(calcCoverage, model, modelKey, minYield, maxYield, minCoverage, yIntersect, interpYield, colorCode);

        let tonerCost = this.specialtyColor.tonerPrice[colorCode];

        let rawimpCost = tonerCost / (interpYield * 1000);
        let impCost = Math.ceil((rawimpCost + Number.EPSILON) * 10000) / 10000
        let sizeMultiplier = this.paperSizeAdjustment[this.specialtyColor.paperSize]

        let totalCost = (tonerCost * this.specialtyColor.impressions * sizeMultiplier) / (interpYield * 1000);

        this.specialtyColor.impressionCost = impCost?impCost:0;
        this.specialtyColor.totalCost = totalCost?totalCost:0;



      },






      onChange: function(){
        //console.log("change");
        if(!this.silverGoldCompatible){
          if((this.specialtyColor.color === "Silver") || (this.specialtyColor.color === "Gold")){
            this.specialtyColor.color = ""
          }
        }


        this.calculate();
        this.hasUnsavedChanges = true;

      },

      onChangeToSummary: function(ref){
        //console.log("change to summary");
        if(ref.total){
          ref.summarized = true;
        }
        this.calculate();

      },

      onCostSummaryChange: function(){
        this.calculate();
      },



    },
    computed: {

      colorChoices: function(){
        if(this.silverGoldCompatible){
          return [
            'Clear',
            'White',
            'Neon Yellow',
            'Neon Pink',
            'Invisible Red',
            'Silver',
            'Gold'
          ]
        }else{
          return [
            'Clear',
            'White',
            'Neon Yellow',
            'Neon Pink',
            'Invisible Red',
          ]
        }
      },


      silverGoldCompatible: function(){
        if(this.specialtyColor){
          return ((this.specialtyColor.equipment === 'Pro C72xxX / 72xxSX') || (this.specialtyColor.equipment === 'Pro C7500'))
        }

        return false
      },

      profile: function(){
        return this.$store.getters.getUserProfile;
      },

      paperSizeAdjustment: function(){
        return {
          "8.5 x 11":1,
          "11 x 17": 2,
          "12 x 18": 2.31,
          "13 x 19": 2.642,
          "13 x 27.5": 3.8235
        }
      },

      models: function(){
        return [
          'Pro C7500',
          "Pro C72xxX / 72xxSX",
          "Pro C71xxX / 71xxSX"
        ]
      },

      modelCodes: function(){
        return {
          'Pro C7500': 'C75xx',
          "Pro C72xxX / 72xxSX": "C72xx",
          "Pro C71xxX / 71xxSX":"C71xx"
        }
      },

      coverageCols: function(){
        //coverage percentages used in table calcs
        return [.02, .05, .15, .25, .5, .75, 1.0];
      },

      costPerPaperSize: function(){

        if(this.specialtyColor.impressions && this.specialtyColor.totalCost){
          return (this.specialtyColor.totalCost / this.specialtyColor.impressions)
        }

        return 0;
      },

      coverageTables: function(){
        return {
          "C75xxD": {
            "Clear": {
              "5P/J": {
                "simplex": [69.8,68.5,29.1,17.7,9.0,6.0,4.5],
                "duplex": [42.1,41.6,28.3,17.5,8.9,6.0,4.5]
              },
              "50P/J": {
                "simplex": [85.8, 82.6, 29.3, 17.8, 9.0, 6.0, 4.5],
                "duplex": [77.0, 75.4, 29.2, 17.8, 9.0, 6.0, 4.5]
              },
            },
            "White": {
              "5P/J": {
                "simplex": [46.4, 45.5, 19.0, 11.5, 5.8, 3.9, 2.9],
                "duplex": [28.0, 27.7, 18.6, 11.4, 5.8, 3.9, 2.9]
              },
              "50P/J": {
                "simplex": [57.1, 54.9, 19.0, 11.5, 5.8, 3.9, 2.9],
                "duplex": [51.2, 50.1, 19.0, 11.5, 5.8, 3.9, 2.9]
              },
            },
            "Neon Yellow": {
              "5P/J": {
                "simplex": [66.4, 65.2, 27.7, 16.9, 8.5, 5.7, 4.3],
                "duplex": [40.0, 39.6, 27.0, 16.6, 8.5, 5.7, 4.3]
              },
              "50P/J": {
                "simplex": [81.7, 78.6, 27.9, 17.0, 8.6, 5.7, 4.3],
                "duplex": [73.3, 71.8, 27.8, 16.9, 8.6, 5.7, 4.3]
              },
            },
            "Neon Pink": {
              "5P/J": {
                "simplex": [66.4,65.2,27.7,16.9,8.5,5.7,4.3],
                "duplex": [40.0,39.6,27.0,16.6,8.5,5.7,4.3]
              },
              "50P/J": {
                "simplex": [81.7,78.6,27.9,17.0,8.6,5.7,4.3],
                "duplex": [73.3,71.8,27.8,16.9,8.6,5.7,4.3]
              },
            },
            "Invisible Red": {
              "5P/J": {
                "simplex": [49.7,48.8,20.5,12.5,6.3,4.2,3.2],
                "duplex": [30.0,29.7,20.1,12.3,6.3,4.2,3.2]
              },
              "50P/J": {
                "simplex": [61.2,58.9,20.7,12.5,6.3,4.2,3.2],
                "duplex": [54.9,53.7,20.6,12.5,6.3,4.2,3.2]
              },
            },
            "Silver": {
              "5P/J": {
                "simplex": [45.2,44.4,18.9,11.5,5.8,3.9,2.9],
                "duplex": [27.3,27.0,18.4,11.4,5.8,3.9,2.9]
              },
              "50P/J": {
                "simplex": [55.6,53.5,19.1,11.6,5.9,3.9,2.9],
                "duplex": [49.9,48.9,19.0,11.6,5.9,3.9,2.9]
              },
            },
            "Gold": {
              "5P/J": {
                "simplex": [45.2,44.4,18.9,11.5,5.8,3.9,2.9],
                "duplex": [27.3,27.0,18.4,11.4,5.8,3.9,2.9]
              },
              "50P/J": {
                "simplex": [55.6,53.5,19.1,11.6,5.9,3.9,2.9],
                "duplex": [49.9,48.9,19.0,11.6,5.9,3.9,2.9]
              },
            },
          },
          "C75xx": {
            "Clear": {
              "5P/J": {
                "simplex": [69.8,68.5,29.1,17.7,9.0,6.0,4.5],
                "duplex": [42.1,41.6,28.3,17.5,8.9,6.0,4.5]
              },
              "50P/J": {
                "simplex": [85.8, 82.6, 29.3, 17.8, 9.0, 6.0, 4.5],
                "duplex": [77.0, 75.4, 29.2, 17.8, 9.0, 6.0, 4.5]
              },
            },
            "White": {
              "5P/J": {
                "simplex": [46.4, 45.5, 19.0, 11.5, 5.8, 3.9, 2.9],
                "duplex": [28.0, 27.7, 18.6, 11.4, 5.8, 3.9, 2.9]
              },
              "50P/J": {
                "simplex": [57.1, 54.9, 19.0, 11.5, 5.8, 3.9, 2.9],
                "duplex": [51.2, 50.1, 19.0, 11.5, 5.8, 3.9, 2.9]
              },
            },
            "Neon Yellow": {
              "5P/J": {
                "simplex": [66.4, 65.2, 27.7, 16.9, 8.5, 5.7, 4.3],
                "duplex": [40.0, 39.6, 27.0, 16.6, 8.5, 5.7, 4.3]
              },
              "50P/J": {
                "simplex": [81.7, 78.6, 27.9, 17.0, 8.6, 5.7, 4.3],
                "duplex": [73.3, 71.8, 27.8, 16.9, 8.6, 5.7, 4.3]
              },
            },
            "Neon Pink": {
              "5P/J": {
                "simplex": [66.4,65.2,27.7,16.9,8.5,5.7,4.3],
                "duplex": [40.0,39.6,27.0,16.6,8.5,5.7,4.3]
              },
              "50P/J": {
                "simplex": [81.7,78.6,27.9,17.0,8.6,5.7,4.3],
                "duplex": [73.3,71.8,27.8,16.9,8.6,5.7,4.3]
              },
            },
            "Invisible Red": {
              "5P/J": {
                "simplex": [49.7,48.8,20.5,12.5,6.3,4.2,3.2],
                "duplex": [30.0,29.7,20.1,12.3,6.3,4.2,3.2]
              },
              "50P/J": {
                "simplex": [61.2,58.9,20.7,12.5,6.3,4.2,3.2],
                "duplex": [54.9,53.7,20.6,12.5,6.3,4.2,3.2]
              },
            },
            "Silver": {
              "5P/J": {
                "simplex": [45.2,44.4,18.9,11.5,5.8,3.9,2.9],
                "duplex": [27.3,27.0,18.4,11.4,5.8,3.9,2.9]
              },
              "50P/J": {
                "simplex": [55.6,53.5,19.1,11.6,5.9,3.9,2.9],
                "duplex": [49.9,48.9,19.0,11.6,5.9,3.9,2.9]
              },
            },
            "Gold": {
              "5P/J": {
                "simplex": [45.2,44.4,18.9,11.5,5.8,3.9,2.9],
                "duplex": [27.3,27.0,18.4,11.4,5.8,3.9,2.9]
              },
              "50P/J": {
                "simplex": [55.6,53.5,19.1,11.6,5.9,3.9,2.9],
                "duplex": [49.9,48.9,19.0,11.6,5.9,3.9,2.9]
              },
            },
          },
          "C72xx": {
            "Clear": {
              "5P/J": {
                "simplex": [81.7, 70.1, 24.5, 14.9, 7.5, 5.0, 3.8],
                "duplex": [49.3, 48.6, 24.1, 14.7, 7.5, 5.0, 3.8]
              },
              "50P/J": {
                "simplex": [100.3, 71.0, 24.7, 14.9, 7.5, 5.0, 3.8],
                "duplex": [90.0, 70.6, 24.6, 14.9, 7.5, 5.0, 3.8]
              },
            },
            "White": {
              "5P/J": {
                "simplex": [53.9, 46.0, 15.9, 9.6, 4.8, 3.2, 2.4],
                "duplex": [32.6, 32.0, 15.7, 9.5, 4.8, 3.2, 2.4]
              },
              "50P/J": {
                "simplex": [66.2, 46.5, 15.9, 9.6, 4.8, 3.2, 2.4],
                "duplex": [59.4, 46.2, 15.9, 9.6, 4.8, 3.2, 2.4]
              },
            },
            "Neon Yellow": {
              "5P/J": {
                "simplex": [78.1, 67.0, 23.5, 14.2, 7.2, 4.8, 3.6],
                "duplex": [47.2, 46.5, 23.1, 14.1, 7.1, 4.8, 3.6]
              },
              "50P/J": {
                "simplex": [96.0, 68.0, 23.6, 14.3, 7.2, 4.8, 3.6],
                "duplex": [86.2, 67.5, 23.5, 14.3, 7.2, 4.8, 3.6]
              },
            },
            "Neon Pink": {
              "5P/J": {
                "simplex": [78.1, 67.0, 23.5, 14.2, 7.2, 4.8, 3.6],
                "duplex": [47.2, 46.5, 23.1, 14.1, 7.1, 4.8, 3.6]
              },
              "50P/J": {
                "simplex": [96.0, 68.0, 23.6, 14.3, 7.2, 4.8, 3.6],
                "duplex": [86.2, 67.5, 23.5, 14.3, 7.2, 4.8, 3.6]
              },
            },
            "Invisible Red": {
              "5P/J": {
                "simplex": [57.6, 49.4, 17.3, 10.5, 5.3, 3.5, 2.7],
                "duplex": [34.8, 34.2, 17.0, 10.4, 5.3, 3.5, 2.6]
              },
              "50P/J": {
                "simplex": [70.7, 50.1, 17.4, 10.5, 5.3, 3.5, 2.7],
                "duplex": [63.5, 49.7, 17.3, 10.5, 5.3, 3.5, 2.7]
              },
            },
            "Silver": {
              "5P/J": {
                "simplex": [58.3, 52.0, 18.3, 11.1, 5.6, 3.7, 2.8],
                "duplex": [35.2, 34.7, 17.9, 11.0, 5.6, 3.7, 2.8]
              },
              "50P/J": {
                "simplex": [71.0, 52.7, 18.3, 11.1, 5.6, 3.7, 2.8],
                "duplex": [63.7, 52.4, 18.3, 11.1, 5.6, 3.7, 2.8]
              },
            },
            "Gold": {
              "5P/J": {
                "simplex": [58.3, 52.0, 18.3, 11.1, 5.6, 3.7, 2.8],
                "duplex": [35.2, 34.7, 17.9, 11.0, 5.6, 3.7, 2.8]
              },
              "50P/J": {
                "simplex": [71.0, 52.7, 18.3, 11.1, 5.6, 3.7, 2.8],
                "duplex": [63.7, 52.4, 18.3, 11.1, 5.6, 3.7, 2.8]
              },
            },
          },
          "C71xx": {
            "Clear": {
              "5P/J": {
                "simplex": [90.0, 80.3, 28.2, 17.1, 8.6, 5.8, 4.3],
                "duplex": [42.9, 42.3, 27.4, 16.8, 8.6, 5.8, 4.3]
              },
              "50P/J": {
                "simplex": [109.5, 81.4, 28.4, 17.2, 8.6, 5.8, 4.3],
                "duplex": [96.6, 80.7, 28.3, 17.2, 8.6, 5.8, 4.3]
              },
            },
            "White": {
              "5P/J": {
                "simplex": [58.6, 52.1, 18.0, 10.9, 5.5, 3.6, 2.7],
                "duplex": [27.9, 27.6, 17.6, 10.7, 5.5, 3.6, 2.7]
              },
              "50P/J": {
                "simplex": [71.3, 52.6, 18.0, 10.9, 5.5, 3.6, 2.7],
                "duplex": [62.9, 52.3, 18.0, 10.9, 5.5, 3.6, 2.7]
              },
            },
            "Neon Yellow": {
              "5P/J": {
                "simplex": [74.8, 72.6, 25.6, 15.6, 7.8, 5.2, 3.9],
                "duplex": [45.2, 44.5, 25.1, 15.4, 7.5, 5.2, 3.9]
              },
              "50P/J": {
                "simplex": [89.3, 73.6, 25.7, 15.6, 7.9, 5.3, 3.9],
                "duplex": [80.1, 73.0, 25.7, 15.6, 7.9, 5.2, 3.9]
              },
            },
            "Neon Pink": {
              "5P/J": {
                "simplex": [74.8, 72.6, 25.6, 15.6, 7.8, 5.2, 3.9],
                "duplex": [45.2, 44.5, 25.1, 15.4, 7.5, 5.2, 3.9]
              },
              "50P/J": {
                "simplex": [89.3, 73.6, 25.7, 15.6, 7.9, 5.3, 3.9],
                "duplex": [80.1, 73.0, 25.7, 15.6, 7.9, 5.2, 3.9]
              },
            },
            "Invisible Red": {
              "5P/J": {
                "simplex": [55.3, 53.7, 18.9, 11.5, 5.8, 3.9, 2.9],
                "duplex": [33.4, 32.9, 18.6, 11.4, 5.8, 3.9, 2.9]
              },
              "50P/J": {
                "simplex": [66.1, 54.4, 19.0, 11.5, 5.8, 3.9, 2.9],
                "duplex": [59.2, 54.0, 19.0, 11.5, 5.8, 3.9, 2.9]
              },
            },

          }
        }

      },


    }
}
</script>

<style scoped>


.color-rate-calculator {

}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transition: all 0.3s ease;
}

.slide-enter-to {

}

.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-leave {

}

.slide-leave-to {
  transform: translateX(100%);
}

</style>

<!--

=( VLOOKUP(B12,A6:B10,2,0) * B18 * VLOOKUP(B16,Pro_C71xx_Tables!$A$43:$B$46,2,0) )
/
(VLOOKUP(CONCATENATE(B12,IF(B18>49,"50","5"),"simplex"),Pro_C72xx_Tables!$K:$M,3,0)*1000)

=ROUNDUP(
    IFERROR(  VLOOKUP(B12,A6:B10,2,0),0  )
    /
    (VLOOKUP(CONCATENATE(B12,IF(B18>49,"50","5"),"simplex"),Pro_C72xx_Tables!$K:$M,3,0)*1000)

    ,4)


-->



<!--

=IF('Cost Calculator-72xx Series'!B12="white",(16.3*'Cost Calculator-72xx Series'!B14)/100,(4.5*'Cost Calculator-72xx Series'!B14)/100)+('Cost Calculator-72xx Series'!B15)


(16.3*'Cost Calculator-72xx Series'!B14)/100

(4.5*'Cost Calculator-72xx Series'!B14)/100

('Cost Calculator-72xx Series'!B15)

Calculator Coverage
=IF(
      'Cost Calculator-72xx Series'!B12="white",
         WHITE = (16.3*'Cost Calculator-72xx Series'!B14)/100,

         OTHER =  (4.5*'Cost Calculator-72xx Series'!B14)/100)+('Cost Calculator-72xx Series'!B15)


         =IF('Cost Calculator-72xx Series'!B12="white",
         (16.3*'Cost Calculator-72xx Series'!B14)/100,
         (4.5*'Cost Calculator-72xx Series'!B14)/100)+('Cost Calculator-72xx Series'!B15)

         -->
