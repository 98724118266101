


<template>
  <div class="flex border-b-8 border-brand-blue-dark -mx-8 px-8 text-sm">
    <div v-for="tab in tabs" :key="tab.id" class="pt-2 pb-1 mr-2 px-4 cursor-pointer rounded-t border-t border-l border-r border-gray-500 bg-gray-200 uppercase" :class="(tab.id == selection.id)?'bg-brand-blue-dark text-white font-bold':'text-gray-700 hover:bg-gray-300 hover:underline'" @click="selectTab(tab)">
      {{  tab.label }}
    </div>

  </div>
</template>

<script>
export default {
    name: "tab-bar",
    props: {
      tabs: {
        type: Array,
        default: function(){
          return [];
        }
      },

      selection: {
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {
      selectTab: function(tab){
        this.$emit('change',tab);
      }
    },
    computed: {}
}
</script>

<style scoped>


.tab-bar {

}

</style>