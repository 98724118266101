<template>


  <div id="app"  class="flex flex-col h-screen relative">
    <global-header-bar></global-header-bar>

    <div class="w-full relative flex-grow h-2/3" v-if="loaded">
      <router-view/>
    </div>

  </div>
</template>


<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#sidenav .link {
  @apply block uppercase text-sm tracking-wide px-4 py-3 text-white;
  @apply bg-gray-800;
}

#sidenav .link[data-active="true"]{
  @apply bg-gray-600;
}

.dropdown:hover .options {
  display: block;
}


</style>
<script>
import GlobalHeaderBar from "@/components/GlobalHeaderBar";
export default {
  components: {GlobalHeaderBar},
  data: function(){
    return {
      loaded: false
    }
  },
  mounted: async function() {

    //console.log(window.location.hash);
    if(window.location.hash.startsWith('#/reset?')){
      this.$store.state.initialized = true;
      this.loaded = true;
      return;
    }

    let user = await this.$store.dispatch("authorize");
    //console.log("loaded", user);

    this.$store.state.initialized = true;
    this.loaded = true;

    if(!user && (this.$route.name !== "Login")){
      this.$router.push({
        name: "Login"
      }).catch(() => {
        console.log("avoid duplicate route")
      })
    }

  },
  computed: {
    authorized() {
      return this.$store.state.authorized;
    }
  }
}
</script>