<template>
  <div class="side-panel absolute top-0 right-0 w-full h-full bg-white border-l bg-gray-50">
    <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Create Assessment</h2>
      <div class="w-8 h-8 p-2 border-gray-300 border-2 rounded-full flex justify-center items-center mt-1 ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="close">
        <img  class="h-3 inline-block " src="../assets/icons/close-icon.svg" />
      </div>
    </div>
    <div class="p-8">
      <div class="">


        <div class="">
          <label class="text-sm">Organization Name</label>
          <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.organizationName" />
        </div>



        <div class="flex mt-4">

          <div class="w-1/2 pr-4">
            <label class="text-sm">ORC CF Cust #</label>
            <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.organizationCode" />
          </div>

        <div class="w-1/2 pl-4">
          <label class="text-sm">ORC CS Cust #</label>
          <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.organizationCode2" />
        </div>
        </div>

        <div class="mt-4">
          <div class="pb-4">
              <label class="text-sm">Assessment Type</label>
              <select-input v-model="customerProfile.assessmentType" :options="assessmentOptions" prompt-text="Select Assessment Type"/>
          </div>
        </div>
        <div class="pt-6 mt-4 border-t">
          <div class="pb-4 flex justify-between">
            <div class="mr-8">
              <button class="button border-gray-400" @click="close">Cancel</button>
            </div>
            <div class="">
              <button class="button button-blue" @click="create">Create</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import SelectInput from "@/components/SelectInput";

export default {
  name: "side-panel-new-assessment",
  components: { SelectInput},
  props: {},
  data: function () {
    return {
      customerProfile: {
        organizationName: "",
        organizationCode: "",
        organizationCode2: "",
        organizationId: "",
        assessmentType: ""
      }
    }
  },
  methods: {


    close: function(){
      this.$emit('close');
    },

    create: function(){

      if(this.customerProfile.assessmentType && this.customerProfile.organizationName) {


        let newAssessment = this.$sdm.createAssessment(this.customerProfile);

        if(newAssessment){
          this.$sdm.saveNewAssessment(newAssessment).then((response) => {
            console.log(response);
            this.$emit("created");
          })
          .catch((error) => {
            console.log(error)
          })
        }else{
          alert("Uh oh. There was a problem creating this record. Please try again.");
        }

      }else{
        alert("Organization Name and Assessment Type are required fields.")
      }
    }
  },
  computed: {
    assessmentOptions: function(){

      let types = [];
      if(this.$store.getters.hasWorkflowAccess){
        types.push('Production Workflow')
      }

      if(this.$store.getters.hasCCMAccess){
        types.push('CCM')
      }

      if(this.$store.getters.hasMailingAccess){
        types.push('Mail')
      }

      if(this.$store.getters.hasMarketingAccess){
        types.push('MAP')
      }

      if(this.$store.getters.hasMarketingAccess){
        types.push('PMP')
      }

      return types;
    }
  }
}
</script>

<style scoped>


.side-panel-new-assessment {

}

</style>