


<template>
<div class="pre-self-scorecard">
  <assessment-scorecard class="scorecard bg-white border" v-if="assessment" :title="cardTitle" answer-key="final" :assessment="assessment" :questions="survey"></assessment-scorecard>
  <div class="pt-4 mt-4 border-t">
    <div class="pb-4 flex justify-between">
      <div class="pr-4">
        <button class="button button-back" @click="$emit('goSection', 'walkthrough')"><img class="inline-block mr-2 h-3 transform rotate-180" src="../assets/icons/next-arrow-icon-black.svg" /> Walkthrough Assessment</button>
      </div>
      <div class="pl-4">
        <button class="button button-green button-next mr-2" @click="saveAsPng">Export PNG</button>
        <button class="button button-blue button-next" @click="$emit('tab', 'assessment')">Begin Assessment <img class="inline-block ml-2 h-3" src="../assets/icons/next-arrow-icon.svg" /></button>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import AssessmentScorecard from "@/components/AssessmentScorecard";
export default {
    name: "pre-walkthrough-scorecard",
  components: {AssessmentScorecard},
  mounted: function(){
      if(this.assessment){
        this.$sdm.calculate(this.assessment);
      }
  },
  props: {},
    data: function(){
        return {}
        },
    methods: {
      saveAsPng: function(){
        this.$exportTools.saveSvgToPng(document.querySelector(".scorecard"),"Scorecard", 1000, 950);
      },
    },
    computed: {
      assessment: function(){
        return this.$store.state.workflowAssessment
      },

      survey: function(){
        return this.$store.state.surveyQuestions;
      },

      isCCM: function(){
        if(this.$store.state.workflowAssessment){
          return (this.$store.state.workflowAssessment.customerProfile.assessmentType === "CCM")
        }

        return false;

      },

      isMail: function(){
        if(this.$store.state.workflowAssessment){
          return (this.$store.state.workflowAssessment.customerProfile.assessmentType === "Mail")
        }

        return false;

      },

      isMAP: function(){
        if(this.$store.state.workflowAssessment){
          return (this.$store.state.workflowAssessment.customerProfile.assessmentType === "MAP")
        }

        return false;

      },

      isPMP: function(){
        if(this.$store.state.workflowAssessment){
          return (this.$store.state.workflowAssessment.customerProfile.assessmentType === "PMP")
        }

        return false;

      },

      cardTitle: function(){
        if(this.isCCM){
          return "CCM Snapshot Assessment Scorecard"
        }

        if(this.isMail){
          return "Mail Assessment Scorecard"
        }

        if(this.isMAP){
          return "MAP Assessment Scorecard"
        }

        if(this.isPMP){
          return "PMP Assessment Scorecard"
        }

        return "Production Workflow Scorecard";
      }
    }
}
</script>

<style scoped>


.pre-self-scorecard {

}

</style>
