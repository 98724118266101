


<template>
<div class="organization-input">
  <div class="relative">
    <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="searchString" @input="onSearchChange" @focus="onSearchFocus" :readonly="blockInput" placeholder="Search Database"/>
    <div class="absolute right-0 top-0 mt-4 mr-4 opacity-50 hover:opacity-100 outline-none cursor-pointer" @click="clearSearchString"><img class="w-3 h-3" src="../assets/icons/close-icon.svg" /></div>
    <p class="my-2 px-2 bg-yellow-100" v-if="warning">{{ warning }}</p>
  </div>

  <div v-if="searchResults && searchResults.length" class="border">
    <div class="p-2 border-b bg-white hover:bg-gray-100 hover:underline cursor-pointer" v-for="entry in searchResults" :key="entry.ref.id" @click="selectSearchItem(entry)">
      {{ entry.data.name }}
    </div>
  </div>
</div>
</template>

<script>
export default {
    name: "organization-input",
    props: ["value"],
    watch: {
      "value": "updateSearchString"
    },

    mounted() {
      if(this.value.organizationName){
        this.searchString = this.value.organizationName;
      }
    },


  data: function(){
        return {
          lastSearch: "",
          searchString: "",
          searching: false,
          searchResults: undefined,
          warning: ""
        }
        },
    methods: {
      updateSearchString: function(val){
        if(val.organizationName){
          this.searchString = val.organizationName;
        }
      },

      onSearchFocus: function(){
        if(this.blockInput){
          this.warning = "Use the X to clear this selection before searching again."
        }
      },


      onSearchChange: function(){
        ////console.log(this.searchString);
        if(this.searchString !== this.customerProfile.organizationName){

          this.customerProfile = {
            organizationName: "",
            organizationCode: "",
            organizationCode2: "",
            organizationId: ""
          }
        }
        this.searchOrgs();
      },

      clearSearchString: function(){
        this.searchString = "";
        this.customerProfile = {
          organizationName: "",
          organizationCode: "",
          organizationCode2: "",
          organizationId: ""
        }

        this.$emit("change");

        this.warning = "";
      },

      selectSearchItem: function(entry){
        this.searchString = entry.data.name;
        this.searchResults = undefined;

        this.customerProfile = {
          organizationName: entry.data.name,
          organizationCode: entry.data.orgCode,
          organizationCode2: entry.data.orgCode2,
          organizationId: entry.ref.id
        }

        this.$emit("change");
      },

      searchOrgs: async function(){

        if(this.searchString) {

          this.searching = true;
          this.warning = "Searching records..."

          let q = this.$db.query;
          let client = new this.$db.Client({secret: this.$store.getters.getToken});

          await client.query(
              q.Map(
                  q.Filter(
                      q.Paginate(
                          q.Match(
                              q.Index("all_customers")
                          ),
                          {size: 100}
                      ),

                      q.Lambda("X",
                          q.ContainsStr(
                              q.LowerCase(q.Select(["data", "name"], q.Get(q.Var("X")))),
                              this.searchString.toLowerCase()
                          )
                      )
                  ),
                  q.Lambda("X", q.Get(q.Var("X")))
              )
          )
              .then((response) => {
                console.log("organizations", response);

                if (response.data) {
                  this.searchResults = response.data;
                  if(response.data.length){
                    this.warning = "";
                  }else{
                    this.warning = "No matches found.";
                  }
                }else{
                  this.warning = "No matches found.";
                }
                this.searching = false;
              })
              .catch((error) => {
                console.error(error);
                this.searching = false;
                this.warning = "Search failed.";
              })
        }else{
          this.searchResults = [];
        }

      },
    },
    computed: {
      blockInput: function(){
       if(this.customerProfile.organizationName){
          return true;
        }

       return false;
      },


      customerProfile: {
        get: function(){
          return {
            organizationName: this.value.organizationName,
            organizationCode: this.value.organizationCode,
            organizationCode2: this.value.organizationCode2,
            organizationId: this.value.organizationId
          }

        },
        set: function(modifiedValue){

          //console.log("setting org", modifiedValue);

          let newValue = {...this.value};
          newValue.organizationName = modifiedValue.organizationName;
              newValue.organizationCode = modifiedValue.organizationCode;
              newValue.organizationCode2 = modifiedValue.organizationCode2;
              newValue.organizationId = modifiedValue.organizationId;

              this.$emit("input", newValue);

        }
      }
    }
}
</script>

<style scoped>


.organization-input {

}

</style>