


<template>
<div class="question" :data-inactive="!answer.active">
  <div class="w-full">
    <div class="pr-8 flex-grow">

      <label class="pt-2 inline-block relative"><input class="float-left mt-1 mr-2" v-if="question.optional" type="checkbox" v-model="answer.active" @change="onChange"/> <span>{{ question.question }} <hint class="inline-block ml-1" direction="absolute left-full  mb-16" v-if="question.hint" :message="question.hint"></hint></span> </label>


    </div>
    <div class="flex-shrink-0 flex-grow-0">

      <textarea class="w-full border p-2 block rounded mt-1" v-model="answer.notes" placeholder="" :disabled="!answer.active" @change="onChange"></textarea>

    </div>
  </div>
</div>


</template>

<script>



import Hint from "@/components/Hint";

export default {
    name: "assessment-interview-question-text-field",
  components: { Hint },
  props: {
      question: {
        required: true
      },
      answer: {
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {

      onChange: function(){
        this.$emit("change");
      }

    },
    computed: {

    }
}
</script>

<style scoped>


.question[data-inactive="true"] {
  color: gray;
}

</style>