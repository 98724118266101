


<template>
<div class="pre-customer-profile">
  <div>
    <h2 class="font-bold text-xl pb-2 border-b-2 pl-4">ROI Summary</h2>
  </div>
  <div class="">

    <roi-table-mail :assessment="assessment"></roi-table-mail>

  </div>
</div>
</template>

<script>


// import RoiTableMis from "@/components/ROITableMIS";
// import RoiChartMisPotential from "@/components/ROIChartMISPotential";
// import RoiChartMisCurrentFuture from "@/components/ROIChartMISCurrentFuture";
import RoiTableMail from "@/components/ROITableMail";
export default {
    name: "summary-mail-tables",
  components: {RoiTableMail},
  mounted: function() {

  },
  props: {
      label: { required: false },
      instructions:  { required: false },
    },
    data: function(){
        return {
        }
        },
    methods: {
      onChange: function(){
        this.$emit("change");
      },
      formattedValue: function(cell){
        if(cell.filter){
          if(cell.filter === "$"){
            console.log(cell);
            return this.$options.filters.currency(cell.value);
          }
        }
        return cell.value;
      }
    },
    computed: {

      assessment: function(){
        return this.$store.state.workflowAssessment
      },

      costs: function(){
        if(this.assessment){
          return this.assessment.costs.solution;
        }
        return null;
      },


    }
}
</script>

<style scoped>


.pre-customer-profile {

}

.chart-container-1 {
  height: 0;
  padding-bottom: 50%;
  position: relative;
}

.chart-container-2 {
  height: 0;
  padding-bottom: 42%;
  position: relative;
}

td {
  @apply text-right py-2 px-4 border bg-white w-16;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}




td[data-null="true"]{

}



</style>