


<template>
<div class="link-list">
  <div class="border-b py-2 link-list-item cursor-pointer" v-for="link in links" :key="link.id" :class="(selection.id == link.id)?'text-brand-blue':'text-black'" @click="selectLink(link)">{{ link.label }}</div>
</div>
</template>

<script>
export default {
    name: "link-list",
    props: {
      links: {
        type: Array,
        default: function(){
          return [];
        }
      },
      selection: {
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {
      selectLink: function(link){
        this.$emit("change", link);
      }
    },
    computed: {}
}
</script>

<style scoped>


.link-list {

}

.link-list .link-list-item {

}

</style>