<template>
  <div class="">
    <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Administrative Expenses</h2>
      <div class="text-xs">
        <button class="button border-gray-400 mr-4" @click="save"><img class="-ml-2 cursor-pointer opacity-50 hover:opacity-100 h-3 inline-block transform rotate-180 mr-1" src="../../assets/icons/next-arrow-icon-black.svg" /> Back to Rates</button>
      </div>
    </div>
    <div class="p-8 pt-4">
      <div class="text-sm border-b border-brand-blue mb-2">
        <span class="inline-block pb-1 mr-8 cursor-pointer hover:text-brand-blue hover:opacity-100" :class="admin.summarized?'opacity-50':'border-b-4 border-brand-blue font-bold text-brand-blue'" @click="setSummarized(false)">Use Itemized Calculation</span>
        <span class="inline-block pb-1 cursor-pointer hover:text-brand-blue hover:opacity-100" :class="admin.summarized?'border-b-4 border-brand-blue font-bold text-brand-blue':'opacity-50'" @click="setSummarized(true)">Use Summarized Value</span>
      </div>

      <div class="" v-if="admin.summarized">
        <p class="flex items-center justify-between pt-2 text-sm field">
          <label>Total Administrative Overhead</label>
          <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="admin.total" format="$" precision="0" @change="onChange"></number-input>
          </span>
        </p>
      </div>


      <div class="fields pt-2" v-else >


        <p class="flex items-center justify-between pt-0 text-sm field" v-for="field in fields" :key="field.key">
          <label :class="field.key?'':'pt-4 border-b pb-2 opacity-75 w-full'">{{  field.label  }}</label>
          <span class="input w-48 border-b hover:border-black" v-if="field.key">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="admin.details[field.key]" format="$" precision="0" @change="onChange"></number-input>
          </span>
        </p>

        <div class="total flex items-center justify-between mt-4">
          <label class="font-bold">Total Administrative Overhead</label>
          <div class="w-48">
            <span class="block w-full px-2 text-right font-bold">{{ admin.total | currency(1) }}</span>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import NumberInput from "@/components/NumberInput";
export default {
  name: "side-panel-rates-admin",
  components: {NumberInput},
  props: ["rates"],
  mounted: function(){
    this.hydrate();
  },
  data: function () {
    return {
      admin: {

        details: {
          banking: 0,
          salestax: 0,
          debt: 0,
          legal: 0,
          consulting: 0,
          unions: 0,
          advertising: 0,
          postal: 0,
          dues: 0,
          donations: 0,
          safety: 0,
          permits: 0,
          office: 0,
          computers: 0,
          software: 0,
          shipping: 0,
          meals: 0,
          transportation: 0,
          vehicleLease: 0,
          vehicleRepairs: 0,
          gas: 0,
          gifts: 0,
          other: 0
        },
        total: 0,
        summarized: false
      },
    }
  },
  methods: {
    setSummarized: function(summarize){
      if(!summarize){

        let currentTotal = Object.keys(this.admin.details).reduce((total, key) => {
          let value = this.admin.details[key];
          if (value) {
            total += value;
          }
          return total;
        }, 0)

        if(currentTotal != this.admin.total){
          if(confirm("Switching to itemized will calculate your total based on the available itemized expense values. Do you want to switch to an itemized calculation?")){
            this.admin.summarized = summarize;
            this.calculate();
          }
        }else{
          this.admin.summarized = summarize;
          this.calculate();
        }

      }else{
        this.admin.summarized = summarize;
        this.calculate();
      }
    },

    close: function(){
      this.$emit('close');
    },

    cancel: function(){
      this.$emit('close');
    },

    save: function(){
      Object.keys(this.admin.details).forEach((key) => {
         this.rates.overhead.admin.details[key] = this.admin.details[key];
      })
      this.rates.overhead.admin.total = this.admin.total;
      this.rates.overhead.admin.summarized = this.admin.summarized;
      this.$emit('change');
      this.$emit('close');
    },

    hydrate: function(){
      this.admin.summarized = this.rates.overhead.admin.summarized;

      Object.keys(this.admin.details).forEach((key) => {
        this.admin.details[key] = this.rates.overhead.admin.details[key];
      })
      this.admin.total = this.rates.overhead.admin.total;

    },

    onChange: function(){
      this.calculate();
    },

    calculate: function(){

      if(!this.admin.summarized) {
        this.admin.total = Object.keys(this.admin.details).reduce((total, key) => {
          let value = this.admin.details[key];
          if (value) {
            total += value;
          }
          return total;
        }, 0)
      }
    }

  },
  computed: {

    fields: function(){
      return [
        { label: "Bank/Credit Card Fees", key: "banking" },
        { label: "Sales/Use Tax", key: "salestax" },
        { label: "Bad Debt", key: "debt" },
        { label: "Legal Fees", key: "legal" },
        { label: "Consulting Fees", key: "consulting" },
        { label: "Union Expense", key: "unions" },
        { label: "Advertising and Promotion", key: "advertising" },
        { label: "Postage (Internal - Not Charged Back on a Customer Invoice)", key: "postal" },
        { label: "Dues and Subscriptions", key: "dues" },
        { label: "Contributions/Donations", key: "donations" },
        { label: "OSHA/Safety", key: "safety" },
        { label: "Licenses/Permits/Service Charges", key: "permits" },
        { label: "Office Supplies", key: "office" },
        { label: "Computer Support/Supplies", key: "computers" },
        { label: "Software", key: "software" },
        { label: "Freight/Shipping (Not Charged Back on a Customer Invoice)", key: "shipping" },
        { label: "Travel & Entertainment:", key: "" },
        { label: "Meals and Entertainment", key: "meals" },
        { label: "Transportation and Lodging", key: "transportation" },
        { label: "Vehicle - Lease", key: "vehicleLease" },
        { label: "Vehicle - Repairs", key: "vehicleRepairs" },
        { label: "Vehicle - Gas", key: "gas" },
        { label: "Client Gifts", key: "gifts" },
        { label: "Other Expenses", key: "other" }

      ]
    }
  }
}
</script>




<style scoped>


.side-panel-new-assessment {

}

.fields p:last-of-type {
  @apply border-black border-b pb-2;
}

.fields p:last-of-type .input {
  border-color: transparent;
}


</style>