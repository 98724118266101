<template>
  <div class="pre-self-assessment">
    <div class="grid gap-8 w-full">
      <div>
        <div class="mb-8">
          <p class="py-4 px-6 bg-gray-100 border rounded font-bold">Ask the customer how they perform each of these activities and provide a score (1-5 or N/A) that most closely represents their answers. There is room in each section to provide unique notes based on your discussion with the customer.</p>
        </div>
        <div class="list-decimal list-inside text-lg font-bold" v-if="surveyQuestions">
          <div v-for="(question, index) in surveyQuestions" :key="question.id" class="px-6 pt-6 -mx-4 pb-6 border-b hover:bg-white cursor-pointer" :class="(question == focusedQuestion)?'shadow-lg rounded border-l border-r bg-white':''">
            <survey-question  :num="index + 1" :question="question" :answer="answers[question.id]"  @focus="onFocusQuestion"  @info="showInfo" @change="setAnswer" :show-scale="(question == focusedQuestion)"></survey-question>
          </div>
        </div>

        <div class="flex justify-end items-center leading-none pt-4">
          <p class="mr-4 text-gray-500">Overall Score (Weighted):</p>
          <div class="flex-shrink-0 w-12 h-12 border-4 tracking-tighter mx-2 text-center text-2xl font-bold totalAvg flex items-center justify-center" :data-color="scoreColor"><span>{{ weightedScore }}</span></div>
        </div>

        <div class="pt-4 mt-4 border-t">
          <div class="pb-4 flex justify-between">
            <div class="pr-4">
              <button class="button button-back" @click="$emit('goSection', 'prepinfo')"><img class="inline-block mr-2 h-3 transform rotate-180" src="../assets/icons/next-arrow-icon-black.svg" /> What To Look For</button>
            </div>
            <div class="pl-4">
              <button class="button button-blue button-next" @click="$emit('goSection', 'selfscore')">Self Assessment Scorecard <img class="inline-block ml-2 h-3" src="../assets/icons/next-arrow-icon.svg" /></button>
            </div>

          </div>
        </div>
      </div>
      <div class="relative hidden">
        <div class="h-full">
          <div class="p-4 border rounded">
            <h2 class="font-bold text-base">Scorecard Submissions</h2>
            <p class="mt-4">Stakeholders that have been requested to fill out this scorecard:</p>
            <button @click="addRecipient" class="mt-4 p-1 bg-brand-green text-white rounded text-sm px-4 flex gap-2 items-center"><svg class="w-6" xmlns="http://www.w3.org/2000/svg" width="45" height="36" viewBox="0 0 45 36">
              <path d="M43.875,14.625h-4.5v-4.5A1.128,1.128,0,0,0,38.25,9H36a1.128,1.128,0,0,0-1.125,1.125v4.5h-4.5A1.128,1.128,0,0,0,29.25,15.75V18a1.128,1.128,0,0,0,1.125,1.125h4.5v4.5A1.128,1.128,0,0,0,36,24.75h2.25a1.128,1.128,0,0,0,1.125-1.125v-4.5h4.5A1.128,1.128,0,0,0,45,18V15.75A1.128,1.128,0,0,0,43.875,14.625ZM15.75,18a9,9,0,1,0-9-9A9,9,0,0,0,15.75,18Zm6.3,2.25H20.876a12.24,12.24,0,0,1-10.252,0H9.45A9.452,9.452,0,0,0,0,29.7v2.925A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V29.7A9.452,9.452,0,0,0,22.05,20.25Z" fill="#fff"/>
            </svg> Add Recipient</button>
          </div>
        </div>


      </div>
    </div>


    <transition name="fade-on">
      <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="info">
        <side-panel-survey-rating :question="info"  @close="closeInfo"></side-panel-survey-rating>
      </div>

    </transition>

  </div>
</template>

<script>
import SurveyQuestion from "@/components/SurveyQuestion";
import bodyScroll from "body-scroll-toggle";
import SidePanelSurveyRating from "@/components/SidePanelSurveyRating";

export default {
  name: "pre-self-assessment",
  components: {SidePanelSurveyRating, SurveyQuestion},
  mounted: function(){

  },
  props: {},
  data: function () {
    return {
      focusedQuestion: undefined,
      info: undefined
    }
  },
  methods: {

    addRecipient: function(){
      alert("Feature coming soon.")
    },

    onFocusQuestion: function(question){

      if(this.focusedQuestion !== question){
        this.focusedQuestion = question;
      }else{
        this.focusedQuestion = null;
      }
    },

    showInfo: function(question){
      bodyScroll.disable();
      this.info = question;

    },

    closeInfo: function(){

      this.info = undefined
      bodyScroll.enable();
    },

    setAnswer: function(e){

        this.$store.commit("setSurveyAnswer", {
          key: "pre",
          value: e.value,
          id: e.question.id
        })

      this.$sdm.calculate(this.$store.state.workflowAssessment);


      this.$store.state.hasUnsavedChanges = true;
    }
  },
  computed: {

    weightedScore: function(){
      if(this.$store.state.workflowAssessment){
        return this.$store.state.workflowAssessment.scorecard.pre;
      }

      return "";
    },

    scaleColors: function(){
      return this.$store.getters.scaleColorClasses;
    },

    scoreColor: function(){
      if(this.weightedScore){
        return parseInt(this.weightedScore);
      }

      return "NA"
    },

    surveyQuestions: function(){
      if(this.$store.state.surveyQuestions){
        return Object.keys(this.$store.state.surveyQuestions).map((key) => {
          return this.$store.state.surveyQuestions[key];
        })
      }

      return [];
    },

    answers: function(){
      return this.$store.getters.surveyAnswers;
    },



  }
}
</script>

<style scoped>


.pre-self-assessment .grid {
  grid-template-columns: 1fr 240px;
}

ol li:last-child hr {
  display: none;
}

.totalAvg[data-color="5"] {
  @apply border-brand-green text-brand-green;
}

.totalAvg[data-color="4"] {
  @apply border-brand-green-light text-brand-green-light;
}

.totalAvg[data-color="3"] {
  @apply border-yellow-500 text-yellow-500;
}

.totalAvg[data-color="2"] {
  @apply border-red-500 text-red-500;
}

.totalAvg[data-color="1"] {
  @apply border-red-500 text-red-500;
}

</style>