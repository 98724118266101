


<template>

  <table v-if="costs" class="vdp-summary text-xs" data-cell-style="AppendixCell">
    <thead>
    <tr><th colspan="8">ROI Summary - Online Storefront</th></tr>
    <tr>
      <th></th>
      <th>Initial Investment</th>
      <th>Year 1</th>
      <th>Year 2</th>
      <th>Year 3</th>
      <th>Year 4</th>
      <th>Year 5</th>
      <th>Total</th>
    </tr>

    </thead>
    <tbody>
    <tr>
      <th colspan="8">Cash Flow</th>
    </tr>
    <tr>
      <td>Capital (Software, Hardware, Installation)</td>
      <td>${{ costs.capitalTotals.initial | decimal(0) }}</td>
      <td>${{ costs.capitalTotals.year1 | decimal(0) }}</td>
      <td>${{ costs.capitalTotals.year2 | decimal(0) }}</td>
      <td>${{ costs.capitalTotals.year3 | decimal(0) }}</td>
      <td>${{ costs.capitalTotals.year4 | decimal(0) }}</td>
      <td>${{ costs.capitalTotals.year5 | decimal(0) }}</td>
      <td>${{ costs.capitalTotals.total | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Annual Lease Expenses</td>
      <td>${{ costs.lease.initial | decimal(0) }}</td>
      <td>${{ costs.lease.year1 | decimal(0) }}</td>
      <td>${{ costs.lease.year2 | decimal(0) }}</td>
      <td>${{ costs.lease.year3 | decimal(0) }}</td>
      <td>${{ costs.lease.year4 | decimal(0) }}</td>
      <td>${{ costs.lease.year5 | decimal(0) }}</td>
      <td>${{ costs.lease.total | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Training</td>
      <td>${{ costs.training.initial | decimal(0) }}</td>
      <td>${{ costs.training.year1 | decimal(0) }}</td>
      <td>${{ costs.training.year2 | decimal(0) }}</td>
      <td>${{ costs.training.year3 | decimal(0) }}</td>
      <td>${{ costs.training.year4 | decimal(0) }}</td>
      <td>${{ costs.training.year5 | decimal(0) }}</td>
      <td>${{ costs.training.total | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Maintenance</td>
      <td>${{ costs.maintenance.initial | decimal(0) }}</td>
      <td>${{ costs.maintenance.year1 | decimal(0) }}</td>
      <td>${{ costs.maintenance.year2 | decimal(0) }}</td>
      <td>${{ costs.maintenance.year3 | decimal(0) }}</td>
      <td>${{ costs.maintenance.year4 | decimal(0) }}</td>
      <td>${{ costs.maintenance.year5 | decimal(0) }}</td>
      <td>${{ costs.maintenance.total | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Total</td>
      <td>${{ costs.totals.initial | decimal(0) }}</td>
      <td>${{ costs.totals.year1 | decimal(0) }}</td>
      <td>${{ costs.totals.year2 | decimal(0) }}</td>
      <td>${{ costs.totals.year3 | decimal(0) }}</td>
      <td>${{ costs.totals.year4 | decimal(0) }}</td>
      <td>${{ costs.totals.year5 | decimal(0) }}</td>
      <td>${{ costs.totals.total | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Annual Cost Savings</td>
      <td>${{ costs.savings.initial | decimal(0) }}</td>
      <td>${{ costs.savings.year1 | decimal(0) }}</td>
      <td>${{ costs.savings.year2 | decimal(0) }}</td>
      <td>${{ costs.savings.year3 | decimal(0) }}</td>
      <td>${{ costs.savings.year4 | decimal(0) }}</td>
      <td>${{ costs.savings.year5 | decimal(0) }}</td>
      <td>${{ costs.savings.total | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Total Cash Flow Impact</td>
      <td>${{ costs.cashFlow.initial | decimal(0) }}</td>
      <td>${{ costs.cashFlow.year1 | decimal(0) }}</td>
      <td>${{ costs.cashFlow.year2 | decimal(0) }}</td>
      <td>${{ costs.cashFlow.year3 | decimal(0) }}</td>
      <td>${{ costs.cashFlow.year4 | decimal(0) }}</td>
      <td>${{ costs.cashFlow.year5 | decimal(0) }}</td>
      <td>${{ costs.cashFlow.total | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Discount Rate</td>
      <td>{{ costs.discountRate | decimal(0) }}%</td>
    </tr>


    </tbody>
    <tbody>
    <tr>
      <td colspan="2"></td>
    </tr>
    <tr>
      <td>Capital Expense for this Project</td>
      <td>${{ costs.firstYearCapitalExpense | decimal(0) }}</td>
    </tr>
    <tr>
      <td>First Year Operating Expense for this Project</td>
      <td>${{ costs.firstYearOperatingExpense | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Direct - NPV (Net Present Value) (with Discount Rate listed above)</td>
      <td>${{ costs.directNPV | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Direct - IRR (Internal Rate of Return)</td>
      <td>{{ costs.directIRR | decimal(0) }}%</td>
    </tr>
    <tr>
      <td>Payback Period based on Cost Savings (months)</td>
      <td>{{ costs.paybackPeriod | decimal(0) }}</td>
    </tr>

    </tbody>
    <tbody>
    <tr>
      <td colspan="2"></td>
    </tr>
    <tr>
      <td>Yearly Man Hour Savings</td>
      <td>{{ assessment.savings.hoursStorefrontSavingsAnnual | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Value of Man Hour Savings</td>
      <td>${{ assessment.savings.costStorefrontSavingsAnnual | decimal(0) }}</td>
    </tr>
    </tbody>
  </table>



</template>

<script>
export default {
    name: "roi-table-storefront",
    props: ["assessment"],
    data: function(){
        return {}
        },
    methods: {
      formattedValue: function(cell){
        if(cell.filter){
          if(cell.filter === "$"){
            console.log(cell);
            return this.$options.filters.currency(cell.value);
          }
        }
        return cell.value;
      }
    },
    computed: {
      costs: function(){
        if(this.assessment){
          return this.assessment.costs.storefront;
        }
        return null;
      },
    }
}
</script>

<style scoped>


td {
  @apply text-right py-2 px-4 border bg-white w-16;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}

</style>