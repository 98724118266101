


<template>
<div class="pre-customer-profile">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold">Enter the organization and customer contact information. You can also use the Online Questionnaire Form button below to generate an online link that will allow the customer to help supply some of their own workflow details.</p>
  </div>
  <div class="">
    <div class="pb-4">
      <label>Organization Name</label>
      <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.organizationName" />

    </div>
    <div class="pb-4 flex">
      <div class="w-1/2 pr-4">
        <label>ORC CF Cust #</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.organizationCode"  />
      </div>
      <div class="w-1/2 pl-4">
        <label>ORC CS Cust #</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.organizationCode2"  />
      </div>
    </div>
    <div class="pb-4">
      <label>Customer Contact Name</label>
      <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.customerContact" @change="onProfileChange"/>
    </div>
    <div class="pb-4">
      <label>Street Address</label>
      <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.address" @change="onProfileChange"/>
    </div>
    <div class="pb-4 flex">
      <div class="pr-4 w-1/2">
        <label>City</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.city" @change="onProfileChange"/>
      </div>
      <div class="pl-4 w-1/2">
        <label>State / Province</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.state" @change="onProfileChange"/>
      </div>

    </div>

    <div class="pb-4 flex">
      <div class="pr-4 w-1/2">
        <label>Postal Code</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.postalcode" @change="onProfileChange"/>
      </div>
      <div class="pl-4 w-1/2">
        <label>Country</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.country" @change="onProfileChange"/>
      </div>

    </div>

    <div class="pt-4 mt-4 border-t">
      <div class="pb-4 flex">
        <div class="pr-4 w-1/2">
          <label>Assessment Date</label>
          <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.assessmentDate" @change="onProfileChange"/>
        </div>
        <div class="pl-4 w-1/2">
          <label>Assessment Type</label>
          <input type="text" class="block w-full border p-2 mt-1 rounded" disabled v-model="customerProfile.assessmentType" />
        </div>

      </div>
    </div>

    <div class="pt-4 mt-4 border-t" v-if="isCCM">
      <div class="pb-4">

          <assessment-interview-question-multi-choice :answer="assessment.interview.questions.X106" :question="$store.state.interviewQuestions['X106']" @change="onProfileChange"></assessment-interview-question-multi-choice>



      </div>
    </div>
    <div class="pt-4 mt-4 border-t">
      <div class="pb-4 flex justify-between">
        <div class="pr-4">
          <button @click="startOnlineForm" class="relative button button-green">Online Questionnaire Form <span v-if="hasQuestionnaireSubmission" class="absolute flex justify-center items-center right-0 top-0 w-6 h-6 text-white bg-brand-green border-2 border-white p-1 rounded-full -mr-3 -mt-3">1</span></button>
        </div>
        <div class="pl-4">
          <button class="button button-blue button-next" @click="onBegin">Begin <span v-if="isCCM || isMail">Walkthrough </span><span v-else>Self-</span>Assessment <img class="inline-block ml-2 h-3" src="../assets/icons/next-arrow-icon.svg" /></button>
        </div>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import AssessmentInterviewQuestionMultiChoice from "@/components/AssessmentInterviewQuestionMultiChoice";
//import OrganizationInput from "@/components/OrganizationInput";
export default {
    name: "pre-customer-profile",
  components: {AssessmentInterviewQuestionMultiChoice},

  mounted: function() {
      if(this.assessment){
        this.checkForQuestionnaires();
      }
  },
  props: {},
    data: function(){
        return {
          loadingQuestionnaires: false,
        }
    },
    methods: {
      checkForQuestionnaires: function(){

        this.loadingQuestionnaires = true;
        //console.log("Getting questionnaires for", this.$route.params.assessment);
        this.$sdm.getQuestionnairesForAssessment(this.$route.params.assessment)
        .then((response) => {
          if(response && response.length){
            //this.questionnaire = response[0];

            //console.log("response q", response )
            let last = response[0];

            let _q = {
              id: last[0],
              submitted: last[1],
              email: last[2],
              code: last[3],

            }

            //console.log("setting q", _q);

            this.$store.state.questionnaire = _q;
            //this.$emit("questionnaire", response[0])
            //console.log(response)
          }else{
            this.$store.state.questionnaire = undefined;
            console.log("No questionnaires");
          }
          this.loadingQuestionnaires = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingQuestionnaires = false;
        })
      },

      startOnlineForm: function(){

        this.$emit("questionnaire");

      },

      onProfileChange: function(){
        this.$store.state.hasUnsavedChanges = true;
      },

      onBegin: function(){

        if(this.isCCM || this.isMail){
          this.$emit('goSection', 'walkthrough');
        }else{
          this.$emit('goSection', 'selfassessment');
        }

      }
    },
    computed: {

      assessment: function(){
        return this.$store.state.workflowAssessment
      },

      isCCM: function(){
        return (this.customerProfile.assessmentType === 'CCM')
      },

      isMail: function(){
        return (this.customerProfile.assessmentType === 'Mail')
      },

      customerProfile: function(){
        if(this.assessment){
          return this.assessment.customerProfile;
        }

        return null;
      },

      questionnaire: function(){
        return this.$store.state.questionnaire
      },

      hasQuestionnaireSubmission: function(){
        if(this.questionnaire){
          if(this.questionnaire.submitted){
            if(this.assessment.importStatus){
              return (this.assessment.importStatus.imported < this.questionnaire.submitted);
            }else{
              return true;
            }
          }
        }

        return false;
      }
    }
}
</script>

<style scoped>


.pre-customer-profile {

}

</style>