


<template>
<div class="pre-self-scorecard">
  <assessment-scorecard class="scorecard border" :title="cardTitle" answer-key="pre" :assessment="assessment" :questions="survey"></assessment-scorecard>
  <div class="pt-4 mt-4 border-t">
    <div class="pb-4 flex justify-between">
      <div class="pr-4">
        <button class="button button-back" @click="$emit('goSection', 'selfassessment')"><img class="inline-block mr-2 h-3 transform rotate-180" src="../assets/icons/next-arrow-icon-black.svg" /> Self Assessment</button>
      </div>
      <div class="pl-4">
        <button class="button button-green button-next mr-2" @click="saveAsPng">Export PNG</button>
        <button class="button button-blue button-next" @click="$emit('goSection', 'walkthrough')">Walkthrough Assessment <img class="inline-block ml-2 h-3" src="../assets/icons/next-arrow-icon.svg" /></button>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import AssessmentScorecard from "@/components/AssessmentScorecard";
export default {
    name: "pre-self-scorecard",
  components: {AssessmentScorecard},
  props: {},
    data: function(){
        return {}
        },
    methods: {
      saveAsPng: function(){
        this.$exportTools.saveSvgToPng(document.querySelector(".scorecard"),"Scorecard", 1000, 950);
      }
    },
    computed: {
      assessment: function(){
        return this.$store.state.workflowAssessment
      },

      survey: function(){
        return this.$store.state.surveyQuestions;
      },

      cardTitle: function(){
        if(this.assessment.assessmentType === "MAP"){
          return "MAP Assessment Scorecard (Self Scored)"
        }

        if(this.assessment.assessmentType === "PMP"){
          return "PMP Assessment Scorecard (Self Scored)"
        }

        return "Production Workflow Scorecard (Self Scored)"
      }
    }
}
</script>

<style scoped>


.pre-self-scorecard {

}

</style>
