<template>
  <div class="flex flex-col h-screen">
    <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Tutorial</h2>
      <div class="text-xs">
        <button class="button border-gray-400 mr-4" @click="close">Close</button>
      </div>
    </div>
    <div class="p-8 pt-4 bg-white overflow-y-scroll pb-32">

      <div class="">
        <div class="max-w-screen-sm">The videos below will teach you how to price a Ricoh 5th Station Job. Click the video title to play each video full screen. Press ESC or click on the Fullscreen icon to exit back to the video list.</div>
        <div class="mt-8">
          <p @click="fullscreenVideo('video1')" class="my-2 hover:underline cursor-pointer font-bold">1. Specialty Color Cost Estimate</p>
          <video ref="video1" id="video1" width="1280" height="720" class="max-w-screen-sm max-w-screen-sm border" controls>
            <source src="/tutorials/5th-Station-Cost-Estimator-Video-1.mp4" type="video/mp4">
          </video>

        </div>
        <div class="mt-8">
          <p @click="fullscreenVideo('video2')" class="my-2 hover:underline cursor-pointer font-bold">2. Equipment Rate Development</p>
          <video ref="video2"  id="video3" width="1280" height="720" class="max-w-screen-sm max-w-screen-sm border" controls>
            <source src="/tutorials/5th-Station-Cost-Estimator-Video-2.mp4" type="video/mp4">
          </video>

        </div>

        <div class="mt-8">
          <p @click="fullscreenVideo('video3')" class="my-2 hover:underline cursor-pointer font-bold">3. Project Cost & Revenue Estimate</p>
          <video ref="video3"  id="video3" width="1280" height="720" class="max-w-screen-sm max-w-screen-sm border" controls>
            <source src="/tutorials/5th-Station-Cost-Estimator-Video-3.mp4" type="video/mp4">
          </video>

        </div>

      </div>



    </div>
  </div>
</template>

<script>


export default {
  name: "video-window",
  components: {},
  props: [],
  mounted: function(){

  },
  data: function () {
    return {

    }
  },
  methods: {

    fullscreenVideo: function(id){
      console.log(id);
      let video = this.$refs[id]
      console.log(video)
      video.play()
      video.requestFullscreen()
    },


    close: function(){
      this.$emit('close');
    },


  },
  computed: {
    
  }
}
</script>




<style scoped>


.side-panel-new-assessment {

}

.fields p:last-of-type {
  @apply border-black border-b pb-2;
}

.fields p:last-of-type .input {
  border-color: transparent;
}


</style>