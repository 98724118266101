


<template>
  <table class="text-xs"  data-cell-style="AppendixCell">
    <tbody>
    <tr>
      <th class="sticky top-0 relative text-left ">RETURNED MAIL</th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative ">Cost</th>
    </tr>
    <tr>
      <td>Monthly Return Mail Volume</td>
      <td>{{ costs.returnMail.monthlyVolume | decimal(0) }}</td>
      <td></td>
      <td></td>
      <td></td>
      <td>${{ costs.returnMail.monthlyCostBase | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Average Postage Cost per Piece for Returned Mail</td>
      <td>${{ costs.returnMail.averagePostageCost | decimal(2) }}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Average Print Cost per Piece for Envelope and Contents of Returned Mail</td>
      <td>${{ costs.returnMail.averagePrintCost | decimal(2) }}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Average Number of Times a Piece of Returned Mail is Resent to Recipients</td>
      <td>{{ costs.returnMail.averageResentBack | decimal(0) }}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Monthly Processing Cost of Returned Mail</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>${{ costs.returnMail.monthlyProcessingCost | decimal(0) }}</td>
    </tr>
    <tr class="font-bold">
      <td colspan="5">Current Monthly Cost of Returned Mail</td>
      <td>${{ costs.returnMail.monthlyCostReturned | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Cost to Update Data in CRM to Fix Addresses</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>${{ costs.returnMail.costToUpdateCRM | decimal(0) }}</td>
    </tr>
    <tr>
      <td>% of Returned Mail that is Reprinted</td>
      <td>{{ costs.returnMail.percentReprinted | decimal(0) }}%</td>
      <td></td>
      <td></td>
      <td></td>
      <td>${{ costs.returnMail.percentReprintedCost | decimal(0) }}</td>
    </tr>
    <tr>
      <td>% of Returned Mail that is Resent to Recipients</td>
      <td>{{ costs.returnMail.percentResent | decimal(0) }}%</td>
      <td></td>
      <td></td>
      <td></td>
      <td>${{ costs.returnMail.percentResentCost | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Cost of Resent Mail</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>${{ costs.returnMail.monthlyCostResent | decimal(0) }}</td>
    </tr>
    <tr class="font-bold">
      <td colspan="5">Total Cost of Returned Mail</td>
      <td>${{ costs.returnMail.monthlyCostTotal | decimal(0) }}</td>
    </tr>
    <tr>
      <td>% of Returned Mail that could be reduced by cleansing the mail list</td>
      <td>{{ costs.returnMail.percentCleanseReduction | decimal(0) }}%</td>
      <td></td>
      <td></td>
      <td></td>
      <td>${{ costs.returnMail.monthlyCostSavings | decimal(0) }}</td>
    </tr>
    <tr class="font-bold">
      <td colspan="5">Yearly Cost of Returned Mail</td>
      <td>${{ costs.returnMail.yearlyCostTotal | decimal(0) }}</td>
    </tr>
    <tr class="font-bold">
      <td colspan="5">Yearly Cost Savings by Cleansing Lists (Reducing Returns and Eliminating the Cost to Resend Mail)</td>
      <td>${{ costs.returnMail.yearlyCostSavings | decimal(0) }}</td>
    </tr>


    </tbody>
    <tbody>
    <tr class="">
      <th class="text-left align-bottom bg-gray-100">PRE-SORT, COMINGLING AND CO-PALLETIZING POSTAGE</th>
      <th class="text-left align-bottom bg-gray-100 border-l">Current Postage Costs</th>
      <th class="text-left  align-bottom bg-gray-100 border-l">Current Volume</th>
      <th class="text-left align-bottom bg-gray-100 border-l">Current Cost</th>
      <th class="text-left  align-bottom bg-gray-100 border-l">Future Volume</th>
      <th class="text-left align-bottom bg-gray-100 border-l">Future Cost</th>
    </tr>
    <tr v-for="rate in preSortRates" :key="rate.key">
      <td>{{ rate.label }}</td>
      <td>${{ costs.preSort[rate.key].currentPostage | decimal(3) }}</td>
      <td>{{ costs.preSort[rate.key].currentVolume | decimal(0) }}</td>
      <td>${{ costs.preSort[rate.key].currentCost | decimal(0) }}</td>
      <td>{{ costs.preSort[rate.key].futureVolume | decimal(0) }}</td>
      <td>${{ costs.preSort[rate.key].futureCost | decimal(0) }}</td>
    </tr>
    <tr class="font-bold">
      <td>Yearly cost of postage</td>
      <td></td>
      <td>{{ costs.preSort.yearlyCurrentVolume | decimal(0)}}</td>
      <td>${{ costs.preSort.yearlyCurrentCost | decimal(0)}}</td>
      <td>{{ costs.preSort.yearlyFutureVolume | decimal(0)}}</td>
      <td>${{ costs.preSort.yearlyFutureCost | decimal(0)}}</td>
    </tr>
    <tr class="font-bold">
      <td colspan="5">Yearly Cost Savings from Pre-Sort, Comingling and Co-Palletizing Postage Costs</td>
      <td>${{ costs.preSort.yearlyCostSavings | decimal(0) }}</td>
    </tr>


    </tbody>
    <tbody>
      <tr>
        <th class="sticky top-0 relative text-left">POSTAGE PROMOTIONS</th>
        <th class="sticky top-0 relative"></th>
        <th class="sticky top-0 relative"></th>
        <th class="sticky top-0 relative"></th>
        <th class="sticky top-0 relative"></th>
        <th class="sticky top-0 relative"></th>
      </tr>
      <tr>
        <td>What volume of mail could qualify for postal promotions within a year?</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ costs.postage.annualPromoVolume | decimal(0) }}</td>
      </tr>
      <tr class="font-bold">
        <td colspan="6">Postage cost for each piece that qualifies for a postal promotion:</td>
      </tr>
      <tr v-for="rate in promotionsRates" :key="rate.key">
        <td>{{ rate.label }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>${{ costs.postage.rates[rate.key] | decimal(3) }}</td>
      </tr>
      <tr class="font-bold">
        <td>Average postage cost</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>${{ costs.postage.averagePostageRate | decimal(3) }}</td>
      </tr>
      <tr>
        <td>Discount for postal promotions that qualify?</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ costs.postage.discountRate | decimal(2) }}%</td>

      </tr>
      <tr class="font-bold">
        <td colspan="5">Yearly Cost Savings for Postal Promotions</td>
        <td>${{ costs.postage.yearlyCostSavings | decimal(0) }}</td>

      </tr>
    </tbody>
    <tbody>
    <tr>
      <th class="sticky top-0 relative text-left">RISK MITIGATION - REGULATORY COMPLIANCE</th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
    </tr>
    <tr>
      <td>What was the total value of fines for non-compliance?</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{{ costs.compliance.totalFines | decimal(0) }}</td>
    </tr>

    <tr>
      <td>What % of the fines could be eliminated with the proposed solution?</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{{ costs.compliance.pctFinesEliminated | decimal(1) }}%</td>
    </tr>
    <tr class="font-bold">
      <td colspan="5">Yearly Cost Savings With Compliance</td>
      <td>${{ costs.compliance.yearlyCostSavings | decimal(0) }}</td>
    </tr>
    </tbody>
    <tbody>
    <tr>
      <th class="sticky top-0 relative text-left">WORKFLOW</th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
    </tr>
    <tr>
      <td>Hourly Rate</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>${{ costs.workflow.hourlyRate | decimal(2) }}</td>
    </tr>

    <tr>
      <td>Current monthly hours to complete task (Design, Job Intake, Prep, File Routing)</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{{ costs.workflow.currentMonthlyHoursForTask | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Future monthly hours to complete task (Design, Job Intake, Prep, File Routing)</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{{ costs.workflow.futureMonthlyHoursForTask | decimal(0) }}</td>
    </tr>
    <tr class="font-bold">
      <td colspan="5">Yearly Cost Savings for Reduced Design, Job Intake, Prep and File Routing</td>
      <td>${{ costs.workflow.yearlyCostSavings | decimal(0) }}</td>
    </tr>
    </tbody>
    <tbody>
    <tr>
      <th class="sticky top-0 relative text-left">MAKE VS. BUY</th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
      <th class="sticky top-0 relative"></th>
    </tr>
    <tr>
      <td>Cost per record for cleansing a mailing list</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>${{ costs.makeVsBuy.costPerRecord | decimal(2) }}</td>
    </tr>

    <tr>
      <td># of records processed per year</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{{ costs.makeVsBuy.yearlyRecordsProcessed | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Yearly external cost to cleanse mailing lists</td>
      <td>${{ costs.makeVsBuy.yearlyExternalCleanseCost | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Yearly external costs (excluding cleanings lists)</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>${{ costs.makeVsBuy.yearlyExternalCost | decimal(0) }}</td>
    </tr>
    <tr>
      <td># of Hours</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{{ costs.makeVsBuy.yearlyHours | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Cost of Labor to Process Mail Internally</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>${{ costs.makeVsBuy.yearlyInternalCost | decimal(0) }}</td>
    </tr>
    <tr class="font-bold">
      <td colspan="5">Yearly Cost Savings for Make vs. Buy</td>
      <td>${{ costs.makeVsBuy.yearlyCostSavings | decimal(0) }}</td>
    </tr>
    </tbody>
    <tfoot>
    <tr class="font-bold text-lg">
      <td class="text-left align-bottom bg-gray-100" colspan="5">TOTAL YEARLY MAIL SOLUTION COST SAVINGS</td>
      <td class="align-bottom bg-gray-100 border-l">${{ costs.totalSavingsAnnual | decimal(0) }}</td>
    </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
    name: "savings-details-table-mail",
    props: ['assessment'],
    data: function(){
        return {}
    },
    methods: {
      resolveValue: function(group, row, col){
        if(this.savings[group][row] !== undefined){
          if(this.savings[group][row][col.key] !== undefined){
            if(col.format === "$"){
              return ("$"+this.$options.filters.decimal(this.savings[group][row][col.key], 0));
            }else{
              return this.$options.filters.decimal(this.savings[group][row][col.key], 0);
            }

          }
        }
      }
    },
  computed: {


    savings: function(){
      if(this.assessment){
        return this.assessment.savings;
      }

      return null;
    },

    detailGroups: function(){
      return [
        { label: "Estimate Development", key: "estimates", rows: [
            { label: "Offset Print Estimates", key: "offset" },
            { label: "Digital Print Estimates", key: "digital" },
            { label: "Large Format Print Estimates", key: "largeFormat" },
            { label: "  Total", key: "subtotal" },
            { label: "Quote letters", key: "quoteLetters" },
          ] },
        { label: "Job Ticket Development", key: "jobTickets", rows: [
            { label: "1) Production", key: "production" },
            { label: "2) Fulfillment Only (already produced and in inventory)", key: "fulfillmentOnly" },
            { label: "Job Tickets Created Manually Each Month (From e-Mail, Phone, Snail Mail)", key: "" },
            { label: "3) Jobs Converted from Estimates", key: "converted" },
            { label: "4) Orders with all Job Specs Identified", key: "withSpecs" },
            { label: "5) Orders - You Develop all Job Specs", key: "developed" },
            { label: "6) Re-order (all production info in system from previous order)", key: "reorder" },
            { label: "7) Fulfillment Orders (already produced and in inventory)", key: "fulfillmentManual" },
            { label: "Total for All Jobs Produced per Year", key: "totals" },
          ] },
        { label: "Inventory", key: "inventory", rows: [
            { label: "Time Spent Managing Inventory", key: "totals" },
          ] },
        { label: "Job Tracking / Scheduling", key: "jobTracking", rows: [
            { label: "Administration/CSR Job Tracking", key: "admin" },
            { label: "Owner/Sales/Production Manager/Supervisor Job Tracking", key: "owner" },
            { label: "Production Meetings", key: "meetings" },
            { label: "Total", key: "totals" },
          ] },
        { label: "Shipment Tracking", key: "shipments", rows: [
            { label: "Amount of Time to Track each UPS/FedEx Package per Job", key: "tracking" },
            { label: "Time to Create Shipping Docs", key: "docs" },
            { label: "Total", key: "totals" },
          ] },
        { label: "Purchasing/Accounts Payable Processing", key: "purchasing", rows: [
            { label: "Create PO", key: "create" },
            { label: "Reconcile PO", key: "reconcile" },
            { label: "Accounts Payable Processing (invoices for purchases for jobs)", key: "processing" },
            { label: "Total", key: "totals" },
          ] },
        { label: "External Purchase vs. Internally Produced", key: "externalVsInternal", rows: [
            { label: "Cost", key: "totals" },
          ] },
        { label: "Billing/Accounts Receivable Processing", key: "billing", rows: [
            { label: "Create Invoice", key: "create" },
            { label: "Log Info into Accounting System", key: "accounting" },
            { label: "Cost of Carrying $s on P&L", key: "plCost" },
            { label: "Total", key: "totals" },
          ] },
        { label: "Labor Tracking", key: "labor", rows: [
            { label: "Time Spent Tracking Labor per Day per Employee", key: "totals" },
          ] },
        { label: "Errors/Rework/Waste", key: "errors", rows: [
            { label: "Reduced Errors - Result of Information Accuracy in Software", key: "reducedSoftware" },
            { label: "Reduced Errors - Content Errors Charged Internally", key: "reducedContent" },
          ] },
        { label: "Billable Customer Changes", key: "changes", rows: [
            { label: "# of Jobs where Billable Changes are Missed when Invoicing", key: "totals" },
          ] },
        { label: "Report Development", key: "reports", rows: [
            { label: "Sales by Customer", key: "sales" },
            { label: "Actual vs. Estimate", key: "actualVsEstimate" },
            { label: "Customer Profitability", key: "customerProfit" },
            { label: "Product Line Profitability", key: "productProfit" },
            { label: "Weekly Sales", key: "weeklySales" },
            { label: "Monthly Sales", key: "monthlySales" },
            { label: "Jobs Shipped and not Invoiced", key: "notInvoiced" },
            { label: "Employee Productivity", key: "productivity" },
            { label: "Real Time Schedule Report", key: "schedule" },
            { label: "Payroll", key: "payroll" },
            { label: "", key: "totals" },
          ] },
        { label: "Miscellaneous", key: "misc", rows: [
            { label: "Opportunity for Cost Savings Not Identified Above", key: "" },
            { label: "Info from Customer - List Area of Savings Opportunity", key: "totals" },
          ] },

      ]
    },

    promotionsRates: function(){
      return [
          { label: "5-Digit Automation", key: "fiveDigit"},
          { label: "AADC", key: "AADC"},
          { label: "Mixed AADC", key: "MixedAADC"},
          { label: "3-Digit", key: "threeDigit"},
          { label: "Mixed ADC", key: "mixedADC"},
          { label: "Meter", key: "meter"},
          { label: "Stamp", key: "stamp"},

        ]
    },

    preSortRates: function() {return [
      { label: "5-Digit Automation", key: "fiveDigit"},
      { label: "AADC Automation", key: "AADC"},
      { label: "Mixed AADC Automation", key: "mixedAADC"},
      { label: "AADC Nonautomation Machinable", key: "AADCNonAuto"},
      { label: "Mixed AADC Nonautomation Machinable", key: "mixedAADCNonAuto"},
      { label: "5-Digit Nonautomation Nonmachinable", key: "fiveDigitNonAuto"},
      { label: "3-Digit Nonautomation Nonmachinable", key: "threeDigitNonAuto"},
      { label: "Mixed ADC Nonautomation Nonmachinable", key: "mixedADC"},
      { label: "Meter", key: "meter"},
      { label: "Stamp", key: "stamp"},

    ]},

    summaryGroups: function(){
      return [
        { label: "Estimate Development", key: "estimates"},
        { label: "Job Ticket Development", key: "jobTickets"},
        { label: "Inventory", key: "inventory" },
        { label: "Job Tracking / Scheduling", key: "jobTracking" },
        { label: "Shipment Tracking", key: "shipments" },
        { label: "Purchasing/Accounts Payable Processing", key: "purchasing" },
        { label: "Billing/Accounts Receivable Processing", key: "billing" },
        { label: "Labor Tracking", key: "labor" },
        { label: "Errors/Rework/Waste", key: "errors" },
        { label: "Billable Customer Changes", key: "changes" },
        { label: "Report Development", key: "reports" },
        { label: "Miscellaneous", key: "misc" },
      ]
    },

    detailCols: function(){
      return [
        { key: "hours", format:""},
        { key:"cost", format: "$"},
        { key:"hoursMIS", format: ""},
        { key:"costMIS", format: "$"},
        { key:"hoursMISSavings", format: ""},
        { key:"costMISSavings", format: "$"},
        { key:"hoursStorefront", format: ""},
        { key:"costStorefront", format: "$"},
        { key:"hoursStorefrontSavings", format: ""},
        { key:"costStorefrontSavings", format: "$"}]
    },

    costs: function(){
      if(this.assessment){
        return this.assessment.costs;
      }
      return null;
    },


  }
}
</script>

<style scoped>


.savings-summary-table {

}

tr {
  @apply bg-white;
}

th {
  @apply border bg-gray-100 align-bottom;
  padding: 0.5rem 0.5rem;
}

th.row-label {
  @apply px-4;
}


td {
  @apply text-right border w-16;
  padding: 0.5rem 1.0rem;
  text-align: right;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}

.group-label {
  @apply text-left font-bold underline pt-8;
}
.row-label {
  @apply text-left;
}





td[data-null="true"]{

}

tbody tr:hover td {
  @apply bg-blue-50 cursor-pointer;
}

tr.blank {
  @apply bg-transparent;
}

tr.blank td {
  @apply border-none;
}

</style>