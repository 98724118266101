


<template>
<div class="summary-export max-w-3xl">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold" v-if="options.instructions">{{ options.instructions }}</p>
  </div>
  <div>
    <h2 class="font-bold text-xl pb-2 border-b-2">Export Options</h2>
  </div>

  <div class="py-4 border-b flex justify-between items-center">
    <div class="flex items-center">
      <h2 class="text-lg">CCM Scorecard</h2>
    </div>
    <div>
      <button class="button button-blue" @click="onPreview('scorecard')">Preview</button>
    </div>
  </div>

  <div class="py-4 pt-12">
    <div>
      <h2 class="font-bold text-xl pb-2 border-b-2">Findings Report</h2>

    </div>
    <div>
      <h3 class="text-lg font-bold mt-6 mb-2">Background Statement</h3>
      <div class="p-4 border mb-2 rounded" :class="(assessment.exportSettings.option == '1')?'bg-white':''">
        <div class="flex items-center pb-2">
          <input @change="onChange"  type="radio" class="flex-shrink-0 w-6 h-6" value="1" v-model="assessment.exportSettings.option"/>
          <h2 class="ml-4 text-lg font-bold">Option 1</h2>

        </div>
        <div>
          <p>Many businesses are limited due to an extremely manual process for line of business communication creation and deployment. Complex communications are replicated over and over, with a lack of clarity on data, graphics, standards, and version control. While some business users may feel they are a “master” of their current processes - the risks they pose to the business from a compliance and legal standpoint is extremely high. Common challenges in the current practice are as follows: </p>
          <p class="mt-2 italic font-bold">...Continues with list of challenges (Risk, Disparate Data, Poor Audit Trails, etc..)</p>
        </div>
      </div>
      <div class="p-4 border rounded mb-4" :class="(assessment.exportSettings.option == '2')?'bg-white':''">
        <div class="flex items-center pb-2">
          <input @change="onChange"  type="radio" class="flex-shrink-0 w-6 h-6" value="2" v-model="assessment.exportSettings.option"/>
          <h2 class="ml-4 text-lg font-bold">Option 2</h2>
        </div>
        <div>
          <p>Now, more than ever, forward-thinking organizations must understand how their customers want to communicate with them. Today’s multi-generational customers expect to choose how, when, and where to receive information – true omni-channel. Along with highly personalized correspondence, they want the ability to have electronic payment methods and in real-time, two-way digital communication. In addition, the business workflows that manage customer data for the processing of transactional documents are shifting due to the convergence of digital distribution and paper-based solutions. Understanding workflow processes, organizational goals and customer preferences are the foundation to defining opportunities to enhance the customer’s experience.  Simplifying workflow processes contribute to organizational efficiency and the potential for cost savings.</p>
          <p class="mt-2">Ricoh’s CCM Assessment reviews the components of an organization’s customer communication management strategies and provides recommendations and scalable solutions to automate and simplify current CCM processes. The CCM Assessment was conducted to benchmark the current state against industry best practices and identify solutions to enhance
            {{ assessment.customerProfile.organizationName }}’s customer communication initiatives.</p>


        </div>
      </div>

    </div>
    <h3 class="text-lg font-bold mt-6 mb-2">Recommended Solutions</h3>
    <div class="flex flex-wrap">



        <div class="flex items-center py-2 w-1/2">
          <input type="checkbox" @change="onChange" v-model="assessment.exportSettings.solutions.Other" id="solution1" value="CampaignServices" class="flex-shrink-0 w-6 h-6" />
          <h2 class="ml-4 text-lg">CCM Campaign Services</h2>
        </div>
        <div class="flex items-center py-2 w-1/2">
          <input type="checkbox" @change="onChange" v-model="assessment.exportSettings.solutions.Other" id="solution2" value="CCMDesign" class="flex-shrink-0 w-6 h-6" />
          <h2 class="ml-4 text-lg">CCM Design as a Service</h2>
        </div>
        <div class="flex items-center py-2 w-1/2">
          <input type="checkbox" @change="onChange" v-model="assessment.exportSettings.solutions.Other" id="solution3" value="EFICrossMedia" class="flex-shrink-0 w-6 h-6" />
          <h2 class="ml-4 text-lg">EFI MarketDirect Cross Media</h2>
        </div>

        <div class="flex items-center py-2 w-1/2">
          <input type="checkbox" @change="onChange" v-model="assessment.exportSettings.solutions.Other" id="solution4" value="EFICrossMediaCampaigns" class="flex-shrink-0 w-6 h-6" />
          <h2 class="ml-4 text-lg">EFI MarketDirect Cross Media Campaigns</h2>
        </div>
        <div class="flex items-center py-2 w-1/2">
          <input type="checkbox" @change="onChange" v-model="assessment.exportSettings.solutions.Other" id="solution5" value="ObjectifLuneProducts" class="flex-shrink-0 w-6 h-6" />
          <h2 class="ml-4 text-lg">Objectif Lune Products</h2>
        </div>

        <div class="flex items-center py-2 w-1/2">
          <input type="checkbox" @change="onChange" v-model="assessment.exportSettings.solutions.Other" id="solution7" value="QuadientInspire" class="flex-shrink-0 w-6 h-6" />
          <h2 class="ml-4 text-lg">Quadient Inspire</h2>
        </div>
        <div class="flex items-center py-2 w-1/2">
          <input type="checkbox" @change="onChange" v-model="assessment.exportSettings.solutions.Other" id="solution8" value="InteractiveVideo" class="flex-shrink-0 w-6 h-6" />
          <h2 class="ml-4 text-lg">Interactive Personalized Video </h2>
        </div>
        <div class="flex items-center py-2 w-1/2">
          <input type="checkbox" @change="onChange" v-model="assessment.exportSettings.solutions.Other" id="solution9" value="ProcessDirector" class="flex-shrink-0 w-6 h-6" />
          <h2 class="ml-4 text-lg">RICOH ProcessDirector</h2>
        </div>

        <div class="flex items-center py-2 w-1/2">
          <input type="checkbox" @change="onChange" v-model="assessment.exportSettings.solutions.Other" id="solution10" value="ColorGap" class="flex-shrink-0 w-6 h-6" />
          <h2 class="ml-4 text-lg">Color Gap Analysis</h2>
        </div>
        <div class="flex items-center py-2 w-1/2">
          <input type="checkbox" @change="onChange" v-model="assessment.exportSettings.solutions.Other" id="solution11" value="RicohConsultingServices" class="flex-shrink-0 w-6 h-6" />
          <h2 class="ml-4 text-lg">Ricoh Consulting Services</h2>
        </div>

        <div class="flex items-center py-2 w-1/2">
          <input type="checkbox" @change="onChange" v-model="assessment.exportSettings.solutions.Other" id="solution12" value="ProductionWorkflow" class="flex-shrink-0 w-6 h-6" />
          <h2 class="ml-4 text-lg">Production Workflow Automation Services</h2>
        </div>

      <div class="flex items-center py-2 w-1/2">
        <input type="checkbox" @change="onChange" v-model="assessment.exportSettings.solutions.Other" id="solution13" value="MailAssessment" class="flex-shrink-0 w-6 h-6" />
        <h2 class="ml-4 text-lg">Mail Assessment</h2>
      </div>







    </div>


  </div>

  <div class="pt-8 mt-4 border-t">
    <div class="pb-4 flex justify-between">
      <div class="pr-4">

      </div>
      <div class="pl-4">
        <button class="button button-blue button-next" @click="openPreview">Generate CCM Report</button>
      </div>

    </div>
  </div>


</div>
</template>

<script>
export default {
    name: "summary-ccm-export",
    props: {
      options: {
        default: function(){
          return {
            label: "Solution Costs",
            costsKey: "",
            instructions: "Costs instructions"
          }
        }
      }
    },
    data: function(){
        return {
          hasUnsavedChanges: false
        }
        },
    methods: {
      onPreview: function(id){

        if(id === "scorecard"){
          this.$router.push({
            name: "Assessment",
            params: this.$route.params,
            query: {
              tab: "pre",
              section: "walkthroughscore"
            }
          })
        }
      },

      onChange: function(){
        this.hasUnsavedChanges = true;
        this.$emit("change");
      },

      openPreview: function(){

        let updateSettings = {
          exportSettings: this.assessment.exportSettings
        }
        if(this.hasUnsavedChanges){
          this.$sdm.updateAssessment(updateSettings, this.assessment.id).then(()=>{
            this.$router.push({
              name: "Reports",
              query: {
                r: this.$route.params.assessment,
                t: "ccm"
              }
            })
          })
        }else{
          this.$router.push({
            name: "Reports",
            query: {
              r: this.$route.params.assessment,
              t: "ccm"
            }
          })
        }

      },
    },
    computed: {
      assessment: function(){
        return this.$store.state.workflowAssessment;
      }
    }
}
</script>

<style scoped>


.summary-export {

}

</style>