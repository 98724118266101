


<template>
<div class="question" :data-inactive="!answer.active">
  <div class="w-full flex items-center">
    <div class="pr-8 flex-grow">
      <input class="float-left mt-1 mr-2" v-if="question.optional" type="checkbox" v-model="answer.active" @change="$emit('change')"/> <label>{{ question.question }}</label>


    </div>
    <div class="flex-shrink-0 flex-grow-0 w-1/4">
      <hint class="block absolute -ml-8 mt-3" direction="absolute right-0 mr-8" v-if="question.hint" :message="question.hint"></hint>
      <number-input v-if="question.dataFormat" class="w-full border p-2 block rounded text-right" type="text" v-model="answer.answer.final" :format="question.dataFormat" :precision="question.precision" @change="onChange" :disabled="!answer.active || !enabled"></number-input>
      <input v-else type="text" class="w-full border p-2 block rounded text-right" v-model="answer.answer.final" @change="onChange" :disabled="!answer.active || !enabled" />

    </div>
  </div>

</div>
</template>

<script>
// import PercentInput from "@/components/PercentInput";
import NumberInput from "@/components/NumberInput";
import Hint from "@/components/Hint"
export default {
    name: "assessment-interview-question-single-col",
  components: {NumberInput, Hint},
  props: {
      question: {
        required: true
      },
      answer: {
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {
      onChange: function(e){
        if(e.target){
          this.answer.answer.final = e.target.value;
        }else{
          this.answer.answer.final = e;
        }

        this.$emit("change", e);
      }
    },
    computed: {

      enabled: function(){
        if(this.question.enabled){
          return this.question.enabled[0]
        }

        return true;
      },

    }
}
</script>

<style scoped>


.question[data-inactive="true"] {
  color: gray;
}

.question[data-inactive="true"] .col-input {
  color: rgba(0,0,0,0.2);
}

.question[data-inactive="true"] .col-input::placeholder {
  color: rgba(0,0,0,0.2);
}

</style>