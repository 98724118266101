


<template>
<div class="login overflow-auto">
  <div class="max-w-md mx-auto p-8 border mt-12 bg-white rounded text-center shadow-lg">
        <p class="text-3xl font-bold border-b pb-4 mb-2">Ricoh Consulting Services</p>
        <h1 class="text-lg font-bold">User Portal</h1>
        <form class="mx-auto mt-6" @submit.prevent="submitLogin" v-if="!showVerification && !showReset">
          <input class="rounded block w-full p-2 border my-2" type="text" name="username" placeholder="Username" v-model="username"/>
          <input class="rounded block w-full p-2 border my-2" type="password" name="password" placeholder="Password" v-model="password"/>
          <p class="text-red-500 my-2" v-if="error">
            {{ error }}
          </p>
          <input class="button button-blue mt-8 block w-full p-2" :class="processing?'opacity-50':'cursor-pointer'" type="submit" :value="processing?'AUTHORIZING...':'LOGIN'" :disabled="processing"/>

          <p class="text-center mt-2">
            Forgot your password?
            <span class="text-sm mt-4 text-center underline opacity-50 hover:opacity-100 cursor-pointer" @click="resetPassword">Reset Password</span>
          </p>
        </form>

    <form class="mx-auto mt-6" @submit.prevent="submitReset" v-if="!showVerification && showReset">
      <p class="text-sm text-left text-gray-500">Account email:</p>
      <input class="rounded block w-full p-2 border my-2 mt-1" type="text" name="username" placeholder="Username" v-model="username"/>

      <p class="text-red-500 my-2" v-if="error">
        {{ error }}
      </p>
      <input class="button button-blue text-white mt-8 block w-full p-2 cursor-pointer" type="submit" :value="'SEND RESET INFO'" />

      <p class="text-center mt-2">
        <span class="text-sm mt-4 text-center underline opacity-50 hover:opacity-100 cursor-pointer" @click="cancelReset">Cancel Reset</span>
      </p>
    </form>

        <div class="mx-auto mt-6"  v-if="showVerification">
          <div v-if="!expired">
            <p class="pb-4">A verification code has been sent to:<br> <b>{{ username }}</b> <br>Please enter that code here to continue:</p>

            <input class="rounded block w-full p-2 border my-2 text-center bg-gray-50 border-gray-500 uppercase" type="text" name="code" placeholder="ACCESS CODE" v-model="accessCode"/>

            <p class="text-red-500 my-2 text-red-500 py-2" v-if="error">
              {{ error }}
            </p>
            <button @click="verifyUser" class="button button-blue mt-8 block w-full p-2 cursor-pointer">VERIFY ACCOUNT</button>

            <p  class="text-sm mt-4 text-center underline opacity-50 hover:opacity-100 cursor-pointer" @click="resetCode">Send a new code to my email.</p>
          </div>
          <div v-else>
            <p class="pb-4 text-red-500">The account for  <b>{{ username }}</b> has expired.</p>
            <p class="pb-4">Please contact your account administrator to resolve this issue.</p>

          </div>


        </div>

  </div>
  <p class="text-center mt-4 opacity-75 text-sm">© 2022 Ricoh USA, Inc. All Rights Reserved.</p>
</div>
</template>

<script>
export default {
    name: "login",
    props: {},
    data: function(){
        return {
          username: "",
          password: "",
          accessCode: "",
          error: "",
          verfication: "",
          showVerification: false,
          showReset: false,
          processing: false,
          expired: false
        }
        },
    mounted() {
      //console.log("mounted login");
      this.$store.commit("logout");
    },
  methods: {
      resetCode: function(){

      },

      resetPassword: function(){
        this.showReset = true;
        this.showVerification = false;
      },

      cancelReset: function(){
        this.showReset = false;
      },

      verifyUser: async function(){
        let credentials = {
          username: this.username.toLowerCase(),
          verification: this.verfication,
          code: this.accessCode
        }

        let verified = await this.$store.dispatch('verifyLogin', credentials);

        //console.log("verified", verified);

        //console.log("VERIFIED", verified);

        if(verified && verified.verified) {
          if (this.$store.getters.hasWorkflowAccess && !this.$store.getters.hasCalculatorAccess) {
            this.$router.push("/assessments");
          } else if (this.$store.getters.hasCalculatorAccess && !this.$store.getters.hasWorkflowAccess) {
            this.$router.push("/calculators");
          } else {
            this.$router.push("/");
          }
        }else{
            if(verified && verified.expired){
              this.error = "This account has expired. Please contact your account administrator."
              this.expired = true;
            }else{
              this.error = "Verification failed. Please try again."
            }

        }

      },

      authenticateUser: async  function(){
        let user;

        user = await this.$store.dispatch('authorize');
        if(user){
          if(this.$store.getters.hasWorkflowAccess && !this.$store.getters.hasCalculatorAccess){
            this.$router.push("/assessments");
          } else if(this.$store.getters.hasCalculatorAccess && !this.$store.getters.hasWorkflowAccess){
            this.$router.push("/calculators");
          }else{
            this.$router.push("/");
          }
        }

        if(!user){
          this.error = "Login failed. Please try again."
        }
      },

      submitLogin: async function(){




        if(this.username && this.password){

          let credentials = {
            username: this.username.toLowerCase(),
            password: this.password
          }

          //console.log("login", credentials);

          this.processing = true;

          let handshake = await this.$store.dispatch('login', credentials)
          if(handshake){
            this.verfication = handshake;
            this.error = "";
            this.processing = false;
            this.showVerification = true;
          }else{
            this.error = "Invalid credentials. Please try again."
            this.processing = false;
          }

        }else{
          this.error = "Invalid credentials. Please try again."
        }

      },

    submitReset: async function(){

        if(this.username) {

            await this.$store.dispatch("sendReset",this.username.toLowerCase() )
                .then(() => {
                  //console.log(response)
                })
                .catch((error) => {
                  console.error(error)
                })

          alert("A password reset email has been sent.");
          this.showReset = false;
          this.showVerification = false;

        }

      }
    },
    computed: {}
}
</script>

<style scoped>


.login {

}

</style>
