


<template>
<div class="survey flex flex-col h-full relative">

  <div class="flex-grow relative flex overflow-hidden">
    <div class="text-sm w-32 mt-8 ml-8">
      <link-list class="" :links="sections" :selection="currentSection" @change="setSection"></link-list>
    </div>
    <div class="flex-grow ml-8 border-l px-8 h-full overflow-y-scroll relative" ref="tables">
      <div>
        <component class="my-8 max-w-3xl" :is="currentSection.component" @next="goNext" @summary="goSummary" :instructions="currentSection.instructions" :options="currentSection" @change="recalculate" @tab="onTab"></component>
      </div>
    </div>
  </div>
  <div>




  </div>

</div>
</template>

<script>
import LinkList from "@/components/LinkList";
import MailROITable from "@/components/MailROITable";
import ReturnMailROITable from "@/components/ReturnMailROITable";
import PostageROITable from "@/components/PostageROITable";
import RiskMitigationRoiTable from "@/components/RiskMitigationROITable";
import WorkflowROITable from "@/components/WorkflowROITable";
import MakeVsBuyROITable from "@/components/MakeVsBuyROITable";
import PreSortROITable from "@/components/PreSortROITable";
import MailSolutionCostsTable from "@/components/MailSolutionCostsTable";
//import SolutionCostsVdpDetails from "@/components/SolutionCostsVdpDetails";




export default {
    name: "mail-solution-costs-panel",
  components: {
    MailSolutionCostsTable,
    PreSortROITable,
    MakeVsBuyROITable,
    WorkflowROITable, RiskMitigationRoiTable, ReturnMailROITable, PostageROITable, MailROITable, LinkList },
  props: {

  },
  mounted: function(){

  },
    data: function(){
        return {
          sections:[
            { label: "Solution Costs", id: "solution", component: "MailSolutionCostsTable",
              instructions: "Mail Solution Cost worksheet is to be filled out from the Quote/Proposal document presented by Ricoh to the customer.",
              costsKey:"storefront"
            },
            { label: "Return Mail", id: "returnedMail", component: "ReturnMailROITable",
              instructions: "RETURN MAIL ROI worksheet is to be filled out from the Quote/Proposal document presented by Ricoh to the customer.",
              costsKey:"storefront"
            },
            { label: "Postage", id: "equipment", component: "PreSortROITable",
              instructions: "POSTAGE ROI worksheet is to be filled out from the Quote/Proposal document presented by Ricoh to the customer.",
              costsKey:"vdp"
            },
            { label: "Postage Promotions", id: "postage", component: "PostageROITable",
              instructions: "POSTAGE PROMOTIONS ROI worksheet is to be filled out from the Quote/Proposal document presented by Ricoh to the customer.",
              costsKey:"mis"
            },

            { label: "Risk Mitigation", id: "risk", component: "RiskMitigationRoiTable",
              instructions: "Risk Mitigation - Regulatory Compliance ROI worksheet is to be filled out from the Quote/Proposal document presented by Ricoh to the customer.",
              costsKey:"mis"
            },

            { label: "Workflow", id: "workflow", component: "WorkflowROITable",
              instructions: "WORKFLOW ROI worksheet is to be filled out from the Quote/Proposal document presented by Ricoh to the customer.",
              costsKey:"vdp"
            },
            { label: "Make vs Buy", id: "buyVsMake", component: "MakeVsBuyROITable",
              instructions: "MAKE vs. BUY ROI worksheet is to be filled out from the Quote/Proposal document presented by Ricoh to the customer.",
              costsKey:"vdp"
            },

          ]
        }
        },
    methods: {

      recalculate: function(){
        this.$emit("recalculate");
      },


      setSection: function(section){

        //console.log("goto", section.id);
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: this.$route.query.tab,
            section: section.id
          }
        })

        window.scrollTo(0,0);
      },

      goToSection: function(sectionId){
        this.setSection({ id: sectionId });
      },

      goNext: function(){
        let currentIndex = this.sections.indexOf(this.currentSection);


        let nextSection = this.sections[currentIndex + 1];


        if((currentIndex + 1) < this.sections.length){
          this.setSection(nextSection);
        }
      },

      goSummary: function(){

        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: "summary",
          }
        })
      },

      onTab: function(tab){
        this.$emit("tab",tab);
        window.scrollTo(0,0);
      }
    },
    computed: {
      currentSection: function(){
        //console.log("current section?");

        //console.log(this.sections);
        var section = this.sections.find((item) => {
          return item.id == this.$route.query.section;
        })

        if(!section){
          section = this.sections[0];
        }

        return section;
      }
    }
}
</script>

<style scoped>


.survey {

}

</style>