


<template>
<div class="user-profile" :key="userRef">
  <div class="text-xs px-8 py-2 pt-4 bg-white">
    <router-link to="/">Home</router-link> /
    <router-link to="/admin" v-if="hasAdminAccess && userRef">Admin  / </router-link>
    <span>User</span> /
  </div>

  <div class="pt-0 bg-white">

    <div class="flex justify-between w-full border-b-4 pb-4 px-8">
      <h1 class="text-3xl leading-none">{{ username }}</h1>
      <div class="flex -mt-2 text-sm" v-if="hasAccess">
        <button v-if="isAdmin && ($route.name !=='Profile')" class="button bg-red-500 hover:bg-red-700 text-white flex items-center mr-4" @click="removeUser"><span>Delete</span></button>
        <button v-if="!hasVerifiedPassword" class="button button-blue flex items-center mr-4" @click="sendInvite"><span>Send Invite</span></button>
        <button v-if="hasVerifiedPassword" class="button flex items-center mr-4" @click="resetPassword"><span>Reset Password</span></button>
        <button v-if="profile" class="button flex items-center" @click="update" :class="!hasUnsavedChanges?'opacity-75 pointer-events-none':'button-green'"><span>Save</span><img class="inline-block w-4 h-4 ml-2" v-if="isSaving" src="../assets/icons/tail-spin.svg" /></button>
      </div>
    </div>


  </div>

  <div class="px-8 py-4 w-full max-w-3xl" v-if="profile">
    <div class="sm:flex w-full">
      <div class="sm:pr-8 sm:w-1/2">
        <label class="text-sm">First Name</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded"  v-model="profile.firstname"  @change="onChange"/>
      </div>
      <div class="mt-4 sm:mt-0 sm:pl-4 sm:w-1/2">
        <label class="text-sm">Last Name</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded"  v-model="profile.lastname"  @change="onChange"/>
      </div>
    </div>

    <div class="mt-4">
      <label class="text-sm">Title</label>
      <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="profile.title"  @change="onChange"/>
    </div>

    <div class="mt-4">
      <label class="text-sm">Organization</label>

      <input v-if="!hasAdminAccess" type="text" class="block w-full border p-2 mt-1 rounded" v-model="profile.organization"  disabled readonly/>
      <organization-input v-else v-model="orgProfile" @change="onOrgChange"></organization-input>
      <span class="mt-2 inline-block text-sm bg-red-600 text-white px-1" v-if="!profile.organizationId && hasAdminAccess">Warning: This org is not in database. Please update!</span>
    </div>



      <div class="mt-4">
        <label class="text-sm">Contact Email</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" disabled v-model="profile.email" />
      </div>

    <div class="mt-4 relative">
      <label class="text-sm">Account Type</label>
      <p type="text" class="block w-full border p-2 mt-1 rounded capitalize" @click="toggleLevels">{{ levels[profile.level]?levels[profile.level].label:'No Access' }}</p>

      <div v-if="showAccessMenu" class="absolute left-0 font-normal mt-2 z-50 border bg-white w-full rounded">

        <div v-for="(level, index) in levelChoices" :key="index" class="border-b w-full px-2 pt-2 cursor-pointer hover:bg-gray-100 hover:underline" :data-active="level.id === profile.level" @click="setAccess(level)">
          <p class="p-2 py-2">{{ level.label }}</p>
        </div>

      </div>

    </div>

    <div class="mt-4">
      <label class="text-sm">Expire On <span class="text-xs"> (Deny access to this user after a certain date)</span></label>

      <input v-if="!hasAdminAccess" type="text" class="block w-full border p-2 mt-1 rounded" v-model="profile.expiration"  disabled readonly/>
      <input type="date" class="block w-full border p-2 mt-1 rounded" v-else v-model="profile.expiration" @change="onExpirationChange" />
      <span class="mt-2 inline-block text-sm bg-red-600 text-white px-1" v-if="isExpired">Warning: This expiration date has passed. Please review!</span>
    </div>

    <div class="mt-4" v-if="userRef && hasWorkflowAccess">
      <h3 class="text-lg font-bold mt-6 mb-2">Assessment Apps</h3>
      <div class="flex items-center py-2">
        <input @change="onChange"  type="checkbox" class="w-6 h-6" value="PWF" v-model="userApps"/>
        <h2 class="ml-4 text-lg">Production Workflow</h2>
      </div>
      <div class="flex items-center py-2">
        <input @change="onChange"  type="checkbox" class="w-6 h-6" value="CCM" v-model="userApps"/>
        <h2 class="ml-4 text-lg">CCM</h2>
      </div>
      <div class="flex items-center py-2">
        <input @change="onChange"  type="checkbox" class="w-6 h-6" value="MLG" v-model="userApps"/>
        <h2 class="ml-4 text-lg">Mailing</h2>
      </div>
      <div class="flex items-center py-2">
        <input @change="onChange"  type="checkbox" class="w-6 h-6" value="MAP" v-model="userApps"/>
        <h2 class="ml-4 text-lg">MAP</h2>
      </div>
      <div class="flex items-center py-2">
        <input @change="onChange"  type="checkbox" class="w-6 h-6" value="PMP" v-model="userApps"/>
        <h2 class="ml-4 text-lg">PMP</h2>
      </div>
    </div>

    <div class="mt-4" v-if="userRef && hasCalculatorAccess">
      <h3 class="text-lg font-bold mt-6 mb-2">Calculators</h3>
      <div class="flex items-center py-2">
        <input @change="onChange"  type="checkbox" class="w-6 h-6" value="EQR" v-model="userApps"/>
        <h2 class="ml-4 text-lg">Equipment Rate</h2>
      </div>
      <div class="flex items-center py-2">
        <input @change="onChange"  type="checkbox" class="w-6 h-6" value="SPC" v-model="userApps"/>
        <h2 class="ml-4 text-lg">Specialty Color Costs</h2>
      </div>

      <div class="flex items-center py-2">
        <input @change="onChange"  type="checkbox" class="w-6 h-6" value="PRC" v-model="userApps"/>
        <h2 class="ml-4 text-lg">Project Cost Estimate</h2>
      </div>
    </div>

    <div class="mt-4" v-if="userRef && hasPrintShopConfigAccess">
      <h3 class="text-lg font-bold mt-6 mb-2">Configurators</h3>
      <div class="flex items-center py-2">
        <input @change="onChange"  type="checkbox" class="w-6 h-6" value="CFG" v-model="userApps"/>
        <h2 class="ml-4 text-lg">Print Shop Configurator</h2>
      </div>
    </div>

    <div class="mt-4" v-if="userRef">
      <h3 class="text-lg font-bold mt-6 mb-2">Analysis</h3>
      <div class="flex items-center py-2">
        <input @change="onChange"  type="checkbox" class="w-6 h-6" value="NA" v-model="userApps"/>
        <h2 class="ml-4 text-lg">Needs Analysis</h2>
      </div>
    </div>

    <div class="pt-4 mt-4 border-t">
        <div>
          <button class="button flex items-center" @click="loadActivityLog"><img class="w-4 h-4 mr-2 inline-block" src="../assets/icons/list-icon.svg" /> Load Activity Logs</button>
        </div>
        <div class="mt-4 border rounded text-sm" v-if="activityLog">
          <table class="w-full">
            <thead>
              <tr class="bg-gray-100">
                <th class="pl-4 text-left">Date</th>
                <th class="text-left">Action</th>
                <th class="pr-4 text-left">Details</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="log in activityLog" :key="log[1]">
                <td class="px-4">{{ log[0] | formatTS }}</td>
                <td class="pr-4">{{ log[2] }}</td>
                <td class="pr-4">{{ log[3] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>

    <p class="mt-8 text-gray-500">
      This portal is restricted to authorized Ricoh account managers and customers. Please contact your system administrator for any account issues.
    </p>





  </div>
</div>
</template>

<script>


import OrganizationInput from "@/components/OrganizationInput";
export default {
    name: "user-profile",
  components: {OrganizationInput},
  created: function(){
      //console.log("created");
      this.loadProfile();


    },

  watch: {
    // call again the method if the route changes
    '$route': 'loadProfile'
  },

    props: {},
    data: function(){
        return {
          isSaving: false,
          profile: undefined,
          hasUnsavedChanges: false,
          userApps: [],
          showAccessMenu: false,
          activityLog: undefined,
          orgProfile: {
            "organizationName" :"",
            "organizationCode":"",
            "organizationCode2":"",
            "organizationId":"",
          }
        }
    },
    methods: {

      onExpirationChange: function(){
        //console.log(this.profile.expiration)
        this.hasUnsavedChanges = true;
      },

      removeUser: function(){

        if(!this.isAdmin){
          alert("You are not authorized to perform this operation.");
          return;
        }

        if(this.userRef === this.$store.state.userprofile.userid){
          alert("You cannot delete your own account here. Please contact your system administrator to perform this operation.");
          return;
        }

        if(confirm("Are you sure you want to delete this user?")){
          let q = this.$db.query;
          let client = new this.$db.Client({ secret: this.$store.getters.getToken });
          client.query(
              q.Delete(q.Ref(q.Collection('users'), this.profile.id))
          ).then((response) => {
            console.log("Deleted", response);
            alert("User Deleted");
            this.$router.push({
              name: "Admin"
            })
          })
          .catch((error) => {
            console.error(error);
            alert("Something went wrong with this operation. "+error.description);
          })
        }
      },


      loadActivityLog: function(){
        let q = this.$db.query;
        let client = new this.$db.Client({ secret: this.$store.getters.getToken });

        client.query(

                q.Paginate(
                    q.Match(
                        q.Index("activity_for_user_by_ts"),
                        this.profile.email
                    ),
                    { size: 50 }
                ),


        )
            .then((response) => {
              //console.log("logs", response);
              if(response.data){
                this.activityLog = response.data;
              }
            })
            .catch((error) => {
              console.error(error)
            })

      },

      sendInvite: function(){
        if(confirm(`Send an automated invite email to ${this.profile.email}?`)){
          this.$store.dispatch("sendInvite",this.profile )
              .then(() => {
                alert("An invite email has been sent.");
              })
              .catch((error) => {
                alert("Something went wrong with this operation.");
                console.error(error)
              })
        }
      },

      resetPassword: function(){
        if(confirm(`Initiate a password reset for ${this.profile.email}?`)){
          this.$store.dispatch("sendReset",this.profile.email )
          .then(() => {
            //console.log(response)
            alert("A password reset email has been sent.");
          })
          .catch((error) => {
            alert("Something went wrong with this operation.");
            console.error(error)
          })
        }
      },



      setAccess: function(level){
        if(this.profile.level !== level.level){
          this.hasUnsavedChanges = true;
        }
        this.profile.level = level.level;
      },

      toggleLevels: function(e){
        if(this.userRef && this.hasAdminAccess){
          this.showAccessMenu = !this.showAccessMenu;

          if(this.showAccessMenu){
            e.stopPropagation();
            document.addEventListener('click', ()=>{
              this.showAccessMenu = false;
            }, {once: true});
          }


        }
      },

      loadProfile: function(){
        if(this.userRef && (this.userRef !== this.$store.state.userprofile.userid)){
          let q = this.$db.query;
          let client = new this.$db.Client({ secret: this.$store.getters.getToken });

          return client.query(
              q.Get(q.Ref(q.Collection("users"), this.userRef))
          ).then((response) => {


            if(response.data){
              let record = response.data;
              record.id = response.ref.value.id;
              this.profile = record;
              this.orgProfile.organizationName = this.profile.organization;
              this.orgProfile.organizationId = this.profile.organizationId;

              //console.log(this.profile);

              this.setProfileApps();


            }else{
              throw new Error("Document error.");
            }
          }).catch((error) => {
            console.error(error);
            alert("Something went wrong this operation. Please try again.")
          })
        }else{
          this.profile = this.$store.state.userprofile;
          this.orgProfile.organizationName = this.profile.organization;
          this.orgProfile.organizationId = this.profile.organizationId;
          this.setProfileApps();
        }
        this.hasUnsavedChanges = false;
      },

      setProfileApps: function(){
        if(this.profile.domain === "*"){
          this.userApps = ["PWF","CCM","EQR","SPC","PRC","NA"]
        }else{



          this.userApps = this.profile.domain.split(",").filter((i)=>{
             return i !== ""
          })

        }
      },

      onBack: function(){
        this.$router.back();
      },

      onChange: function(){
        this.hasUnsavedChanges = true;
      },

      onOrgChange: function(){
        this.profile.organization = this.orgProfile.organizationName;
        this.profile.organizationId = this.orgProfile.organizationId;
        this.hasUnsavedChanges = true;
      },

      update: function(){
        if(this.hasUnsavedChanges && !this.isSaving){

          this.isSaving = true;

          let update = { ...this.profile, token: localStorage.getItem("token")};

          update.organization = this.orgProfile.organizationName;
          update.organizationId = this.orgProfile.organizationId;

          if(this.userRef && this.hasAdminAccess){
            update.domain = this.userApps.join();
            update.admin = this.admin.email
            update.userid = this.userRef
          }

          const url = "/api/updateUser"
          const options = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(
                update
            )
          }

          //console.log("updating...",options);

          fetch(url,options)
              .then(response => response.json())
              .then(data => {
                //console.log(data);
                if(data.ref && (this.userRef === this.admin.userid)){
                  this.$store.commit("setUserProfile", data.data);
                }

                this.isSaving = false;
                alert("This profile has been updated.")
                this.hasUnsavedChanges = false;
              })
              .catch((error) => {
                  this.isSaving = false;
                  console.error("Error", error);
                  alert("Something went wrong with this operation. Try again later.")
                })

        }

      }

    },
    computed: {
      userRef: function(){
        return this.$route.params.userid
      },

      username: function(){
         if(this.profile){
           return this.profile.firstname + " " + this.profile.lastname;
         }

         return "Loading...";
      },

      isExpired: function(){
        if(this.profile.expiration){
          let expires = new Date(this.profile.expiration);
          if(expires < Date.now()){
            return true;
          }
        }
        return false;
      },



      levels: function(){
          return {



            "0": {level: 0, label: "0 - No Access"},
            "user": {level: "user", label: "1 - App User"},
            "manager": {level: "manager", label: "2 - App Manager"},
            "admin": {level: "admin", label: "3 - Administrator" },
          }

      },

      subLevels: function() {
        return {
          "0": {level: 0, label: "No Access"},
          "user": {level: "user", label: "App User"}

        }
      },

      levelChoices: function(){
        if(this.isAdmin){
          return this.levels
        }

        return this.subLevels;
      },


      isAdmin: function(){
        return this.$store.getters.isAdmin;
      },

      hasAdminAccess: function(){
        return this.$store.getters.hasAdminAccess;
      },

      admin: function(){
        return this.$store.getters.getUserProfile;
      },

      hasWorkflowAccess: function(){
        return this.$store.getters.hasWorkflowAccess
      },

      hasCalculatorAccess: function(){
        return this.$store.getters.hasCalculatorAccess
      },

      hasPrintShopConfigAccess: function(){
        return this.$store.getters.hasPrintShopConfigAccess
      },

      hasNeedsAnalysisAccess: function(){
        return this.$store.getters.hasNeedsAnalysisAccess
      },

      hasAccess: function(){
        return this.$store.getters.hasAccess;
      },

      hasVerifiedPassword: function(){
        if(this.profile && this.profile.status){
          return (this.profile.status === "verified");
        }

        return false;
      }
    }
}
</script>

<style scoped>


.user-profile {

}

</style>
