<template>
  <div class="">
    <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Facility Expenses</h2>
      <div class="text-xs">
        <button class="button border-gray-400 mr-4" @click="save"><img class="-ml-2 cursor-pointer opacity-50 hover:opacity-100 h-3 inline-block transform rotate-180 mr-1" src="../../assets/icons/next-arrow-icon-black.svg" /> Back to Rates</button>
      </div>
    </div>
    <div class="p-8 pt-4">
      <div class="text-sm border-b border-brand-blue mb-2">
        <span class="inline-block pb-1 mr-8 cursor-pointer hover:text-brand-blue hover:opacity-100" :class="facility.summarized?'opacity-50':'border-b-4 border-brand-blue font-bold text-brand-blue'" @click="setSummarized(false)">Use Itemized Calculation</span>
        <span class="inline-block pb-1 cursor-pointer hover:text-brand-blue hover:opacity-100" :class="facility.summarized?'border-b-4 border-brand-blue font-bold text-brand-blue':'opacity-50'" @click="setSummarized(true)">Use Summarized Value</span>
      </div>

      <div class="" v-if="facility.summarized">
        <p class="flex items-center justify-between pt-2 text-sm field">
          <label>Total Facility Overhead</label>
          <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="facility.total" format="$" precision="0" @change="onChange"></number-input>
          </span>
        </p>
      </div>


      <div class="fields pt-2" v-else >


        <p class="flex items-center justify-between pt-0 text-sm field" v-for="field in fields" :key="field.key">
          <label>{{  field.label  }}</label>
          <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="facility.details[field.key]" format="$" precision="0" @change="onChange"></number-input>
          </span>
        </p>

        <div class="total flex items-center justify-between mt-4">
          <label class="font-bold">Total Facility Overhead</label>
          <div class="w-48">
            <span class="block w-full px-2 text-right font-bold">{{ facility.total | currency(1) }}</span>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import NumberInput from "@/components/NumberInput";
export default {
  name: "side-panel-rates-facility",
  components: {NumberInput},
  props: ["rates"],
  mounted: function(){
    this.hydrate();
  },
  data: function () {
    return {
      facility: {
        details: {
          mortgage: 0,
          taxes: 0,
          utilities: 0,
          maintenance: 0,
          deprecation: 0,
          moving: 0,
          insurance: 0,
          janitorial: 0,
          supplies: 0,
          waste: 0,
          recycling: 0,
          other: 0,
        },
        total: 0,
        summarized: false
      }
    }
  },
  methods: {
    setSummarized: function(summarize){
      if(!summarize){

        let currentTotal = Object.keys(this.facility.details).reduce((total, key) => {
          let value = this.facility.details[key];
          if (value) {
            total += value;
          }
          return total;
        }, 0)

        if(currentTotal != this.facility.total){
          if(confirm("Switching to itemized will calculate your total based on the available itemized expense values. Do you want to switch to an itemized calculation?")){
            this.facility.summarized = summarize;
            this.calculate();
          }
        }else{
          this.facility.summarized = summarize;
          this.calculate();
        }

      }else{
        this.facility.summarized = summarize;
        this.calculate();
      }

    },

    close: function(){
      this.$emit('close');
    },

    cancel: function(){
      this.$emit('close');
    },

    save: function(){
      Object.keys(this.facility.details).forEach((key) => {
         this.rates.overhead.facility.details[key] = this.facility.details[key];
      })
      this.rates.overhead.facility.total = this.facility.total;
      this.rates.overhead.facility.summarized = this.facility.summarized;
      this.$emit('change');
      this.$emit('close');
    },

    hydrate: function(){

      this.facility.summarized = this.rates.overhead.facility.summarized;
      Object.keys(this.facility.details).forEach((key) => {
        this.facility.details[key] = this.rates.overhead.facility.details[key];
      })
      this.facility.total = this.rates.overhead.facility.total;


    },

    onChange: function(){
      this.calculate();
    },

    calculate: function(){

      if(!this.facility.summarized) {
        this.facility.total = Object.keys(this.facility.details).reduce((total, key) => {
          let value = this.facility.details[key];
          if (value) {
            total += value;
          }
          return total;
        }, 0)
      }
    }

  },
  computed: {
    fields: function(){
      return [
        { label: "Building Lease/Mortgage per Year", key: "mortgage" },
        { label: "Real Estate Taxes", key: "taxes" },
        { label: "Utilities - Gas/Electric/Phone", key: "utilities" },
        { label: "Repair & Maintenance - Building", key: "maintenance" },
        { label: "Building/Facility Depreciation Expense", key: "deprecation" },
        { label: "Moving/Rearrangement", key: "moving" },
        { label: "Insurance - General Liability", key: "insurance" },
        { label: "Janitorial Supplies", key: "janitorial" },
        { label: "Production/Shop Supplies", key: "supplies" },
        { label: "Waste Disposal", key: "waste" },
        { label: "Recycling Costs", key: "recycling" },
        { label: "Other Facility Expenses", key: "other" }
      ]
    }
  }
}
</script>




<style scoped>


.side-panel-new-assessment {

}

.fields p:last-of-type {
  @apply border-black border-b pb-2;
}

.fields p:last-of-type .input {
  border-color: transparent;
}


</style>