


<template>
<svg class="w-full" width="1000" height="500" ref="chart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 500">
  <rect width="1000" height="500" x="0" y="0" fill="white" stroke="black"></rect>
  <g>
    <text text-anchor="middle" x="275" y="30" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Current Workflow Hours vs</text>

    <text text-anchor="middle" x="275" y="50" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Future Workflow Hours with Print MIS</text>
    <rect width="350" height="300" x="100" y="70" fill="none" stroke="black"></rect>

    <text text-anchor="end" x="90" y="76" font-family="Arial, Helvetica, sans-serif" font-size="12">{{  hours.ceiling | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="151" font-family="Arial, Helvetica, sans-serif" font-size="12">{{ (hours.increment * 3) | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="226" font-family="Arial, Helvetica, sans-serif" font-size="12">{{ (hours.increment * 2) | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="301" font-family="Arial, Helvetica, sans-serif" font-size="12">{{ hours.increment | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="372" font-family="Arial, Helvetica, sans-serif" font-size="12">0</text>


    <line x1="100" y1="151" x2="450" y2="151" stroke="black" stroke-width="0.8"></line>
    <line x1="100" y1="226" x2="450" y2="226" stroke="black" stroke-width="0.8"></line>
    <line x1="100" y1="301" x2="450" y2="301" stroke="black" stroke-width="0.8"></line>


    <g v-for="(amount, index) in hours.data" :key="index">
      <rect width="100" :height="amount[0]" :x="137 + (index * 175)" :y="370 - amount[0]" fill="#2a83c1" stroke="none"></rect>
      <rect width="100" :height="amount[1]" :x="137 + (index * 175)" :y="370 - (amount[0] + amount[1])" fill="#fed789" stroke="none"></rect>
      <rect width="100" :height="amount[2]" :x="137 + (index * 175)" :y="370 - (amount[0] + amount[1] +amount[2])" fill="#4ac44c" stroke="none"></rect>
    </g>






    <text text-anchor="middle" x="187" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Hrs. per Year without</text>
    <text text-anchor="middle" x="187" y="403" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Online Storefront</text>
    <text text-anchor="middle" x="362" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Hrs. per Year with</text>
    <text text-anchor="middle" x="362" y="403" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Online Storefront</text>

    <rect width="15" height="15" x="190" y="420" fill="#2a83c1" stroke="none"></rect>
    <text text-anchor="start" x="215" y="432" font-family="Arial, Helvetica, sans-serif" font-size="12">Time to Create Estimates</text>
    <rect width="15" height="15" x="190" y="440" fill="#fed789" stroke="none"></rect>
    <text text-anchor="start" x="215" y="452" font-family="Arial, Helvetica, sans-serif" font-size="12">Time to Create Quote Letters</text>
    <rect width="15" height="15" x="190" y="460" fill="#4ac44c" stroke="none"></rect>
    <text text-anchor="start" x="215" y="472" font-family="Arial, Helvetica, sans-serif" font-size="12">Time to Create Job Tickets</text>

  </g>

  <g>
    <text text-anchor="middle" x="775" y="30" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Current Workflow Costs vs</text>

    <text text-anchor="middle" x="775" y="50" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Future Workflow Costs with Print MIS</text>
    <rect width="350" height="300" x="600" y="70" fill="none" stroke="black"></rect>

    <text text-anchor="end" x="590" y="76" font-family="Arial, Helvetica, sans-serif" font-size="12">${{  cost.ceiling | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="151" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ (cost.increment * 3) | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="226" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ (cost.increment * 2) | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="301" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ cost.increment | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="372" font-family="Arial, Helvetica, sans-serif" font-size="12">$0</text>


    <line x1="600" y1="151" x2="950" y2="151" stroke="black" stroke-width="0.8"></line>
    <line x1="600" y1="226" x2="950" y2="226" stroke="black" stroke-width="0.8"></line>
    <line x1="600" y1="301" x2="950" y2="301" stroke="black" stroke-width="0.8"></line>


    <g v-for="(amount, index) in cost.data" :key="index">
      <rect width="100" :height="amount[0]" :x="637 + (index * 175)" :y="370 - amount[0]" fill="#2a83c1" stroke="none"></rect>
      <rect width="100" :height="amount[1]" :x="637 + (index * 175)" :y="370 - (amount[0] + amount[1])" fill="#fed789" stroke="none"></rect>
      <rect width="100" :height="amount[2]" :x="637 + (index * 175)" :y="370 - (amount[0] + amount[1] +amount[2])" fill="#4ac44c" stroke="none"></rect>
    </g>






    <text text-anchor="middle" x="687" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Cost per Year without</text>
    <text text-anchor="middle" x="687" y="403" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Online Storefront</text>
    <text text-anchor="middle" x="862" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Cost per Year with</text>
    <text text-anchor="middle" x="862" y="403" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Online Storefront</text>

    <rect width="15" height="15" x="690" y="420" fill="#2a83c1" stroke="none"></rect>
    <text text-anchor="start" x="715" y="432" font-family="Arial, Helvetica, sans-serif" font-size="12">Cost to Create Estimates</text>
    <rect width="15" height="15" x="690" y="440" fill="#fed789" stroke="none"></rect>
    <text text-anchor="start" x="715" y="452" font-family="Arial, Helvetica, sans-serif" font-size="12">Cost to Create Quote Letters</text>
    <rect width="15" height="15" x="690" y="460" fill="#4ac44c" stroke="none"></rect>
    <text text-anchor="start" x="715" y="472" font-family="Arial, Helvetica, sans-serif" font-size="12">Cost to Create Job Tickets</text>


  </g>




</svg>
</template>

<script>
export default {
    name: "roi-chart-storefront-comparison",
    props: ["assessment"],
    data: function(){
        return {}
        },
    methods: {
      ceilingVal: function(max){

        let multiplier = 1000;
        if(max < 10000){
          multiplier = 100
        }
        if(max < 1000){
          multiplier = 10
        }
        return Math.ceil((max / 4)/multiplier) * 4 * multiplier;
      },
    },
    computed: {

      hours: function(){


        let amounts = {
          estimates : {
            current: this.assessment.savings.estimates.subtotal.hours,
            storefront: this.assessment.savings.estimates.subtotal.hoursStorefront
          },
          quoteLetters : {
            current: this.assessment.savings.estimates.quoteLetters.hours,
            storefront: this.assessment.savings.estimates.quoteLetters.hoursStorefront
          },
          jobTickets : {
            current: this.assessment.savings.summary.jobTickets.hours,
            storefront: this.assessment.savings.summary.jobTickets.hoursStorefront
          }
        }

        let maxValue = 1000;
        let scale = 300;
        let total = amounts.estimates.current + amounts.quoteLetters.current + amounts.jobTickets.current;

        if(total > maxValue){
          maxValue = total;
        }

        let ceiling = this.ceilingVal(maxValue * 1.1);

        return {
          ceiling: ceiling,
          increment: ceiling / 4,
          data: [
              [
                (amounts.estimates.current/ceiling) * scale,
                (amounts.quoteLetters.current/ceiling) * scale,
                (amounts.jobTickets.current/ceiling) * scale,
              ],
            [
              (amounts.estimates.storefront/ceiling) * scale,
              (amounts.quoteLetters.storefront/ceiling) * scale,
              (amounts.jobTickets.storefront/ceiling) * scale,
            ]
          ],
        }
      },
      cost: function(){
        let amounts = {
          estimates : {
            current: this.assessment.savings.estimates.subtotal.cost,
            storefront: this.assessment.savings.estimates.subtotal.costStorefront
          },
          quoteLetters : {
            current: this.assessment.savings.estimates.quoteLetters.cost,
            storefront: this.assessment.savings.estimates.quoteLetters.costStorefront
          },
          jobTickets : {
            current: this.assessment.savings.summary.jobTickets.cost,
            storefront: this.assessment.savings.summary.jobTickets.costStorefront
          }
        }

        let maxValue = 1000;
        let scale = 300;
        let total = amounts.estimates.current + amounts.quoteLetters.current + amounts.jobTickets.current;

        if(total > maxValue){
          maxValue = total;
        }

        let ceiling = this.ceilingVal(maxValue * 1.1);

        return {
          ceiling: ceiling,
          increment: ceiling / 4,
          data: [
            [
              (amounts.estimates.current/ceiling) * scale,
              (amounts.quoteLetters.current/ceiling) * scale,
              (amounts.jobTickets.current/ceiling) * scale,
            ],
            [
              (amounts.estimates.storefront/ceiling) * scale,
              (amounts.quoteLetters.storefront/ceiling) * scale,
              (amounts.jobTickets.storefront/ceiling) * scale,
            ]
          ],
        }
      }
    }
}
</script>

<style scoped>


.roichart-mispotential {

}

</style>