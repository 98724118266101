import Vue from 'vue'
import Vuex from 'vuex'
//import auth from "@/plugins/auth";

Vue.use(Vuex)

let token = localStorage.getItem("token");
let verification = localStorage.getItem("verification");
let username = localStorage.getItem("username");
let userid = localStorage.getItem("userid");
let email = localStorage.getItem("email");
let level = localStorage.getItem("level");
let domain = localStorage.getItem("domain");
let title = localStorage.getItem("title");
let firstname = localStorage.getItem("firstname");
let lastname = localStorage.getItem("lastname");
let organization = localStorage.getItem( "organization");

let userProfile = {
  username, userid, email, level, title, domain, firstname, lastname, organization
}


export default new Vuex.Store({
  state: {
    initialized: false,
    users: undefined,
    authorized: token?true:false,
    verification: verification,
    token: token,
    activeModule: "",
    assessments: {
      data: undefined,
      ts: undefined
    },
    configurations: {
      data: undefined,
      ts: undefined
    },
    questionnaire: undefined,
    assessmentMode: "workflow",
    surveyQuestions: undefined,
    interviewQuestions: undefined,
    interviewGroups: undefined,
    interviewStages: undefined,
    workflowAssessment: undefined,
    hasUnsavedChanges: false,
    userprofile: userProfile,
    customerProfile: {
      "customerContact" : "",
      "address" : "",
      "organizationName" : "",
      "city" : "",
      "state" : "",
      "country" : "",
      "postalcode" : "",
      "email": "",
      "phone": ""
    },
    colorProfile: undefined,
    colorProject: undefined,
    colorProjects: []

  },
  getters: {

    userApps: state => {
      return ((state.userprofile && state.userprofile.domain)?state.userprofile.domain.split(","):[])
    },

    isAdmin: state => {
      return (state.userprofile && state.userprofile.level === "admin");
    },

    isManager: state => {
      return (state.userprofile && state.userprofile.level === "manager");
    },

    hasAdminAccess: (state,getters) => {
      return (getters.isAdmin || getters.isManager);
    },

    hasAccess: state => {
      return (state.userprofile && state.userprofile.level);
    },



    hasEquipmentRateAccess: (state, getters)  => {
      return ["*","EQR"].reduce( (access, app) => { if(getters.userApps.includes(app)){ access = true; } return access; }, false);
    },

    hasSpecialtyColorAccess: (state, getters)  => {
      return ["*","SPC"].reduce( (access, app) => { if(getters.userApps.includes(app)){ access = true; } return access; }, false);
    },

    hasProjectEstimateAccess: (state, getters)  => {
      return ["*","PRC"].reduce( (access, app) => { if(getters.userApps.includes(app)){ access = true; } return access; }, false);
    },

    hasCalculatorAccess: (state, getters)  => {
      return (getters.hasAccess && (getters.hasEquipmentRateAccess || getters.hasProjectEstimateAccess || getters.hasSpecialtyColorAccess))
    },

    hasPrintShopConfigAccess: (state, getters)  => {
      return ["*","CFG"].reduce( (access, app) => { if(getters.userApps.includes(app)){ access = true; } return access; }, false);
    },

    hasNeedsAnalysisAccess: (state, getters)  => {
      return ["*","NA"].reduce( (access, app) => { if(getters.userApps.includes(app)){ access = true; } return access; }, false);
    },

    hasCCMAccess: (state, getters) => {
      return ["*","CCM"].reduce( (access, app) => { if(getters.userApps.includes(app)){ access = true; } return access; }, false);
    },

    hasPWFAccess: (state, getters) => {
      return ["*","PWF"].reduce( (access, app) => { if(getters.userApps.includes(app)){ access = true; } return access; }, false);
    },

    hasWorkflowAccess: (state, getters) => {

      return (getters.hasAccess && (getters.hasPWFAccess || getters.hasCCMAccess || getters.hasMailingAccess || getters.hasMarketingAccess));
    },

    hasMailingAccess: (state, getters) => {

      return ["*","MLG"].reduce( (access, app) => { if(getters.userApps.includes(app)){ access = true; } return access; }, false);
    },

    hasMarketingAccess: (state, getters) => {

      return ["*","MAP","PMP"].reduce( (access, app) => { if(getters.userApps.includes(app)){ access = true; } return access; }, false);
    },



    colorProjects: state => {
      return state.colorProjects;
    },

    getUserProfile: state => {
      return state.userprofile;
    },

    getToken: state => {
      return state.token;
    },

    getVerfication: state => {
      return state.verification;
    },

    isLoggedIn: state => {
      return state.token?true:false;
    },
    configurations: state => {
      if(state.configurations.data){
        return state.configurations.data;
      }

      return [];
    },
    assessments: state => {
      if(state.assessments.data){
        return state.assessments.data;
      }

      return [];
    },

    questionTypes: () => {
      return {
        1: "SurveyQuestion",
        2: "AssessmentInterviewQuestionSingleCol",
        3: "AssessmentInterviewQuestionTextField",
        4: "AssessmentInterviewQuestionMultiCol",
        5: "AssessmentInterviewQuestionSelect",
        6: "AssessmentInterviewQuestionBoolean",
        7: "AssessmentInterviewQuestionStatement",
        8: "AssessmentInterviewQuestionMultiChoice",
      }
    },

    scaleColorClasses: () => {
      return {
        "5": "border-brand-green text-brand-green",
            "4": "border-brand-green-light text-brand-green-light",
            "3": "border-yellow-500 text-yellow-500",
            "2": "border-red-500 text-red-500",
            "1": "border-red-500 text-red-500",
            "NA": "",
            "":""
      }
    },

    scaleColors: () => {
      return {
        "5": "#519F50",
        "4": "#44C644",
        "3": "#F59E0C",
        "2": "#ED4343",
        "1": "#ED4343",
        "NA": "#E5E7EB"
      }
    },

    surveyAnswers: state => {
      return state.workflowAssessment.survey.questions;
    },

    interviewAnswers: state => {
      return state.workflowAssessment.interview.questions;
    },

    solutionCosts: state => {
      return state.workflowAssessment.costs;
    },

  },
  mutations: {
    setToken: (state, token)  => {
      localStorage.setItem("token", token);
      state.token = token;
    },

    setVerfication: (state, token)  => {
      localStorage.setItem("verfication", token);
      state.verification = token;
    },

    loadUserProfile: (state)  => {

      localStorage.setItem("username", userProfile.username);
      localStorage.setItem("userid", userProfile.userid);
      localStorage.setItem("email", userProfile.email);
      localStorage.setItem("level", userProfile.level);
      localStorage.setItem("domain", userProfile.domain);
      localStorage.setItem("organization", userProfile.organization);
      localStorage.setItem("title", userProfile.title);
      localStorage.setItem("firstname", userProfile.firstname);
      localStorage.setItem("lastname", userProfile.lastname);

      state.userprofile = userProfile;

    },

    setUserProfile: (state, userProfile)  => {

      localStorage.setItem("username", userProfile.username);
      localStorage.setItem("userid", userProfile.userid);
      localStorage.setItem("email", userProfile.email);
      localStorage.setItem("level", userProfile.level);
      localStorage.setItem("domain", userProfile.domain);
      localStorage.setItem("organization", userProfile.organization);
      localStorage.setItem("title", userProfile.title);
      localStorage.setItem("firstname", userProfile.firstname);
      localStorage.setItem("lastname", userProfile.lastname);

      state.userprofile = userProfile;

    },
    setActiveModule: (state, module) => {
      state.activeModule = module;
    },
    setAssessments: (state, data) => {
      state.assessments.data = data;
    },

    setConfigurations: (state, data) => {
      state.configurations.data = data;
    },

    prepareInterviewQuestions: (state,interviewData) => {
      state.surveyQuestions = interviewData.survey;
      state.interviewQuestions = interviewData.questions;
      state.interviewGroups = interviewData.groups;
      state.interviewStages = interviewData.stages;
    },

    logout: (state) => {
        localStorage.setItem("token", "");
        state.token = "";
        state.authorized = false;

    },

    setAssessment: (state, assessment) => {

      let interviewData = Vue.prototype.$sdm.getAssessmentSchema(assessment.assessmentType);

      console.log("setAssessment", interviewData.questions)
      state.surveyQuestions = interviewData.survey;
      state.interviewQuestions = interviewData.questions;
      state.interviewGroups = interviewData.groups;
      state.interviewStages = interviewData.stages;


      state.workflowAssessment = assessment;
    },

    setSurveyAnswer: (state, answer) => {
      state.workflowAssessment.survey.questions[answer.id].answer[answer.key] = answer.value
    },

  },
  actions: {

    getReset: async function(context, resetId){

      const url = "/api/getReset"
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            { resetId }
        )
      }



      return fetch(url,options)
          .then(response => response.json())
          .then(data => {
            return data;
          })

    },

    setReset: async function(context, payload){

      const url = "/api/confirmReset"
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            payload
        )
      }



      return fetch(url,options)
          .then(response => response.json())


    },

    sendReset: async function(context, email){

      let currentUser = context.getters.getUserProfile;
      let sender = currentUser?currentUser.email:email;


      const url = "/api/reset"
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {email,sender}
        )
      }

      //console.log(options);

      let newUser = await  fetch(url,options)
          .then(response => response.json())
          .then(data => {
            //console.log("new user", data);
            return data;

          })
          .catch((error) => {

            console.error("User creation failed...", error);
            return false;

          })

      return newUser;

    },


    sendInvite: async function(context, invitee){

      let sender = context.getters.getUserProfile;


      const url = "/api/invite"
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {invitee,sender}
        )
      }

      //console.log(options);

      let invite = await  fetch(url,options)
          .then(response => response.json())
          .then(data => {
            //console.log("invite", data);
            return data;

          })
          .catch((error) => {

            console.error("Invite failed...", error);
            return false;

          })

      return invite;

    },

    addUser: async function(context, payload){
      //console.log("authorizing...");
      let adminId = localStorage.getItem("userid");
      let token = localStorage.getItem("token");

      if(!token || !adminId ){
        context.state.authorized = false;
        return false;
      }

      let user = payload.user;
      let pwd = payload.pwd;
      let admin = payload.admin;

      let credentials = {
        userid: adminId,
        token: pwd,
        temporary: payload.temp
      }

      const url = "/api/initUser"
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {token, user, admin, credentials}
        )
      }

      //console.log(options);

      let newUser = await  fetch(url,options)
          .then(response => response.json())
          .then(data => {
            //console.log("new user", data);
            return data;

          })
          .catch((error) => {

            console.error("User creation failed...", error);
            return false;

          })

      return newUser;


    },

    authorize: async function(context){
      //console.log("authorizing...");
      let refId = localStorage.getItem("userid");
      let token = localStorage.getItem("token");
      let username = localStorage.getItem("email");

      if(!token || !refId ){
        context.state.authorized = false;
        return false;
      }



      const url = "/api/userSession"
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {token, refId, username, collection:"users"}
        )
      }

      let authorized = false;

      //console.log(options);

      await fetch(url,options)
          .then(response => response.json())
          .then(data => {

            //console.log("authorized", data, data.data.expiration);

            if(data.data.expiration){
              let expireDate = new Date(data.data.expiration);
              console.log("Expire",expireDate);
              if(expireDate && (expireDate.getTime() < Date.now())){
                context.state.authorized = false
                return;
              }
            }


            authorized = data.data;
            authorized.userid = refId;
            context.commit("setUserProfile", authorized);
            context.state.authorized = true;

          })
          .catch((error) => {
            context.state.authorized = false;
            console.error("Unauthorized", error);

          })

      return authorized;



    },

    login: async function(context, credentials){
      //console.log("logging in...", credentials);

      const url = "/api/login"
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            credentials
        )
      }




      let verificationId = await fetch(url,options)
          .then(response => response.json())
          .then(data => {
            //console.log("authorized", data);

            if(data.secret && data.handshake){
              let userId = data.instance['@ref'].id;
              context.commit('setToken', data.secret);
              localStorage.setItem("userid", userId);
              localStorage.setItem("email", credentials.username);

              context.state.authorized = false;

            }else{
              context.commit('setToken', "");
              context.commit('setVerfication', "");
              context.state.authorized = false;
            }
            return data.handshake;

          })
          .catch((error) => {
            context.state.authorized = false;
            console.error("unuthorized", error);
          })

      return verificationId;



    },

    verifyLogin: async function(context, credentials){
      //console.log("verifying...", credentials);

      //console.log("authorizing...");
      let refId = localStorage.getItem("userid");
      let token = localStorage.getItem("token");

      if(!token || !refId ){
        context.state.authorized = false;
        return false;
      }



      const url = "/api/verify"
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {token, refId, credentials}
        )
      }

      let authorized = false;

      //console.log(options);

      await fetch(url,options)
          .then(response => response.json())
          .then(data => {
            //console.log("verification",data);
            if(data.expired){
              authorized = data;
              authorized.data.userid = refId;
              context.commit("setUserProfile", authorized.data);
              context.commit("setVerfication", "");
              context.state.authorized = false;
            }else if(data.verified){
              authorized = data;
              authorized.data.userid = refId;
              context.commit("setUserProfile", authorized.data);
              context.commit("setVerfication", credentials.verification);
              context.state.authorized = true;
            }
          })
          .catch((error) => {
            context.state.authorized = false;
            console.error("unuthorized", error);

          })

      return authorized;



    },

    autosave: function(context){
      if(context.state.workflowAssessment && context.state.hasUnsavedChanges){
        //console.log("autosaving changes");
        Vue.prototype.$sdm.updateAssessment(context.state.workflowAssessment, context.state.workflowAssessment.id).then(() => {
          context.state.hasUnsavedChanges = false;
        }).catch((error) => {
          console.log("Error", error);
        })
      }
    },

    loadAssessmentQuestions: (context, assessmentType) => {
        //only load these once.
        if(!context.state.interviewQuestions || assessmentType != context.state.assessmentMode){

          context.state.assessmentMode = assessmentType;



          let dataURL = "data/"+assessmentType+".json";

          //console.log("loading", dataURL);

          return fetch(dataURL)
              .then((response) =>{
                return response.json();
              })
              .then((data) => {
                context.commit("prepareInterviewQuestions", data);
                //this.$store.commit("createAssessment");
                return true;
              })
        }else{
          //this.loadData();
          context.state.assessmentMode = assessmentType;
          return new Promise((resolve) => {
            resolve(true);
          })
        }




    }
  },
  modules: {
  }
})
