import Vue from 'vue'
import App from './App.vue'
import router from './router'


import faunadb from 'faunadb';
import store from './store'
//import auth from './plugins/auth'
import exportTools from "@/plugins/exportTools";
import api from './plugins/api'
import sdmCalc from './plugins/sdm'
import colorCalc from './plugins/colorcalc'
import tableParser from "@/plugins/tableParser";
import htmlToDocx from "@/plugins/htmlToDocx";

import TimeAgo from 'javascript-time-ago'

// English.
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

// Create formatter (English).








import './assets/styles/index.css'

Vue.config.productionTip = false
//Vue.use(auth);
Vue.prototype.$db = faunadb;
Vue.prototype.$timeAgo = new TimeAgo('en-US');
Vue.use(api);
Vue.use(exportTools);

Vue.use(sdmCalc, { store });
Vue.use(colorCalc, { store });
Vue.use(tableParser);
Vue.use(htmlToDocx);

Vue.filter('formatTS', function(value, unit, time = true) {
  if (value) {
    let precision = (unit === 'ms')?1:1000;
    let d = new Date(value/precision);
    return `${d.toLocaleDateString()}${time?(" " + d.toLocaleTimeString()):""}`;
  }
});

Vue.filter('currency', function(value, wholeNum) {
  if (value) {
    let d = parseFloat(value).toFixed(wholeNum?0:2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    return "$"+d;
  }else{
    return "$0"
  }
});

Vue.filter('decimal', function(value, decimals) {
  if (value) {
    //let d = parseFloat(value).toFixed(decimals).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    let d = parseFloat(parseFloat(value).toFixed(decimals)).toLocaleString('en', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });

    return d;
  }else{
    return "0"
  }
});


router.beforeEach((to, from, next) => {


  if(to.matched.some(record => record.meta.requiresAuth)){



    if(!store.state.authorized){
      if((to.meta.module === "reset")){
        next({ name: 'Reset' })
      }else{
        next({ name: 'Login' })
      }
    }else{
      //console.log(to);
      if((from.meta.module === "assessments")){
        store.dispatch("autosave");
      }

      if((to.meta.module === "assessments") && !store.getters.hasWorkflowAccess){
        next({ name: 'Home' })
      }else if((to.meta.module === "calculators") && !store.getters.hasCalculatorAccess){
        next({ name: 'Home' })
      }else if((to.meta.module === "printshopconfig") && !store.getters.hasPrintShopConfigAccess){
        next({ name: 'Home' })
      }else if((to.meta.module === "admin") && !store.getters.hasAdminAccess){
        next({ name: 'Home' })
      }

      next()
    }
  }else{
    next()
  }
})



router.afterEach((to) => {
  store.commit("setActiveModule", to.meta.module )
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


