


<template>
  <div class="question" :data-inactive="!answer.active">
    <div class="w-full">
      <div class="mb-2">

        <label class="pt-2 inline-block"><input class="float-left mt-1 mr-2" v-if="question.optional" type="checkbox" v-model="answer.active" @change="onChange"/> <span>{{ question.question }} <hint class="inline-block ml-1" direction="absolute left-full  mb-16" v-if="question.hint" :message="question.hint"></hint></span></label>
        <p class="text-sm opacity-50" v-if="question.selectOne">(Select one)</p>
        <p class="text-sm opacity-50" v-else>(Select all that apply)</p>


      </div>



      <multi-choice-input class="p-4 border bg-white rounded" v-model="answer.notes" :choices="question.choices" @change="onChange" :select-one="question.selectOne"></multi-choice-input>
      <input type="text" v-model="answer.answer.final" :placeholder="question.otherPlaceholder?question.otherPlaceholder:'Enter other items here...'" class="mt-2 w-full block px-4 py-2 border bg-white rounded" v-if="hasOther" @change="onChange"/>


    </div>
  </div>
</template>

<script>
// import PercentInput from "@/components/PercentInput";

import MultiChoiceInput from "@/components/MultiChoiceInput";
export default {
    name: "assessment-interview-question-multi-choice",
  components: {MultiChoiceInput},
  props: {
      question: {
        required: true
      },
      answer: {
        required: true
      }
    },
    data: function(){
        return {

        }
        },
    methods: {
      onChange: function(e){
        //this.answer.answer.final = e;
        this.$emit("change", e);
        if(!this.hasOther){
          this.answer.answer.final = "";
        }
      }
    },
    computed: {

      enabled: function(){
        if(this.question.enabled){
          return this.question.enabled[0]
        }

        return true;
      },

      hasOther: function(){
        if(this.question.allowOther){
          if(this.answer.notes.includes(this.question.choices[this.question.choices.length - 1])){
            //console.log("Other value");
              return true;
          }
          console.log("Other value inactive");
        }

        return false;
      }



    }
}
</script>

<style scoped>


.question[data-inactive="true"] {
  color: gray;
}

.question[data-inactive="true"] .col-input {
  color: rgba(0,0,0,0.2);
}

.question[data-inactive="true"] .col-input::placeholder {
  color: rgba(0,0,0,0.2);
}

</style>