<template>
  <div class="side-panel absolute top-0 right-0 w-full h-full bg-white border-l bg-gray-50">
    <div class="flex justify-between px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Document Settings</h2>
      <img @click="close" class="h-4 inline-block mt-1 cursor-pointer opacity-50 hover:opacity-100" src="../assets/icons/close-icon.svg" />
    </div>
    <div class="p-8 pt-6">
      <div class="">

        <div class="text-gray-500">
          <label>Document Owner:</label>
        </div>

        <div class="mt-2">
          <select v-model="assignedUser" class="w-full p-2 pr-10 border rounded appearance-none" :disabled="!admin">
            <option class="py-1" :value="undefined"></option>
            <option class="py-1" v-for="user in sortedUsers" :key="user.id" :value="user">
              {{ user.email }}
            </option>
          </select>

          <p v-if="error" class="my-4 p-2 bg-red-500 text-white">{{ error }}</p>

          <p v-if="warning" class="my-4 p-2 bg-yellow-100 text-black border">{{ warning }}</p>

        </div>

        <div class=" mt-4">
          <label class="text-sm text-gray-500">Add Shared Users (Select other {{ assignedUser?assignedUser.organization:"" }} users)</label>
        </div>

        <div class="flex gap-2 mt-2">
          <select v-model="sharedUser" class="flex-grow w-full p-2 pr-10 border rounded appearance-none" :disabled="!assignedUser">
            <option class="py-1" :value="undefined"><span style="font-size:0.5em;">{{ orgUsers.length?"":"No other eligible users"}}</span></option>
            <option class="py-1" v-for="user in orgUsers" :key="user.id" :value="user">
              {{ user.email }}
            </option>
          </select>
            <button @click="addSharedUser" class="flex items-center gap-2 border px-4 rounded" :class="sharedUser?'bg-brand-blue text-white hover:bg-brand-blue-dark':''">Add</button>
        </div>

        <div class="mt-4">
          <span class="border px-2 py-2 rounded" v-for="(userEmail, index) in sharedUsers" :key="userEmail"><span class="inline-flex items-center gap-2">{{userEmail}} <button @click="removeSharedUser(index)" class="text-sm px-1 rounded border hover:bg-red-500 hover:text-white">Remove</button></span></span>
        </div>


        <div class="mt-6">
          <div class="flex items-center" :class="allowPublicEdits?'text-black':'text-gray-500'">
            <input type="checkbox" v-model="allowPublicEdits" class="mr-2 w-4 h-4" />
            <label>Allow Public Edits</label>

          </div>
          <p v-if="allowPublicEdits" class="my-4 p-2 bg-yellow-100 text-black border">This makes document editable by all app users.</p>

        </div>








        <div class="pt-6 mt-6 border-t">
          <div class="pb-4 flex justify-between">
            <div class="mr-8">
              <button class="button border-gray-400" @click="close">Cancel</button>
            </div>
            <div class="">
              <button class="button button-blue" @click="saveSettings">{{ processing?'Saving...':'Save' }}</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "side-panel-doc-settings",
  components: {},
  props: {
    record: {
      type: Object,
      required: true
    },
    admin: {
      type: Boolean,
      default: false
    },
    listLabel: {
      type: String,
      default: "Available Users"
    },
    api: {
      type: Object,
      required: true
    },
    query: {
      type: Object,
      default: function(){
        return {
          index: "all_users",
          size: 300,
          filter: {
            select: ["data","domain"],
            search: "PWF"
          }
        }
      }
    }
  },
  data: function () {
    return {
      assignedUser: undefined,
      sharedUser: undefined,
      sharedUsers: [],
      allowPublicEdits: false,
      users: [],
      error: "",
      processing: false,
    }
  },
  mounted: function() {
    this.loadUsers();
    this.allowPublicEdits = this.record.allowPublicEdits;
    if(this.record.sharedUsers){
      this.sharedUsers = this.record.sharedUsers;
    }
  },
  methods: {
    addSharedUser: function(){
      if(!this.sharedUser){ return }
      let shared = [...this.sharedUsers];
      console.log(this.sharedUser);
      shared.push(this.sharedUser.email);
      console.log(shared)
      this.sharedUsers = shared;
      this.sharedUser = undefined;
    },


    close: function(){
      this.$emit('close');
    },

    removeSharedUser: function(index){
      let editedUsers = [...this.sharedUsers];
      editedUsers.splice(index,1)
      this.sharedUsers = editedUsers;
    },

    selectAssignedUser: function(user){
      this.assignedUser = user;
    },

    saveSettings: async function(){
      if(this.assignedUser){
        this.processing = true;
        this.record.user = this.assignedUser.id;
        this.record.userProfile = this.assignedUser;
        this.record.allowPublicEdits = this.allowPublicEdits?true:false;
        this.record.sharedUsers = this.sharedUsers;


        await this.api.update(this.record.id,this.record,this.currentUser.email).then((response) => {
          if(response){
            this.close();
          }else{
            alert("Settings could not be updated. Please try again.")
          }
        }).catch((error)=>{
          alert("Settings could not be updated. Please try again.")
          console.log(error);
        })

        this.processing = false;

      }else{
        this.error = "No document owner is specified."
      }
    },

    loadUsers: function(){

      console.log("load users", this.record)

      if(this.admin){
        this.queryUsers().then((userList)=>{



          this.users = userList.data.map((rec) => {
            rec.data.id = rec.ref.value.id;
            rec.data.userid = rec.ref.value.id;
            return rec.data
          })

          console.log("user list", this.users, this.assignedUser)

          if(this.record.userProfile){
            this.assignedUser = this.users.find((user) => {
              return (this.record.userProfile.email === user.email);
            })

            if(!this.assignedUser){
              console.log("assigned default user", this.record.userProfile)
              this.assignedUser = this.record.userProfile;
              this.users = [this.record.userProfile, ...this.users]
            }

          }else{
            this.assignedUser = undefined;
          }
        })
      }else{
        this.users = [this.record.userProfile]
        this.assignedUser = this.record.userProfile;
      }





    },

    queryUsers: function(){

      console.log("query users", this.query)

      let token = localStorage.getItem("token")

      var client = new this.$db.Client({ secret: token })

      let q = this.$db.query;

      let appType = "PWF"

      if(this.record.assessmentType === "Mail"){
        appType = "MLG"
      }

      if(this.record.assessmentType === "CCM"){
        appType = "CCM"
      }

      console.log(appType)


      if(this.query && this.query.filter){
        return client.query(
            q.Filter(
                q.Map(
                    q.Paginate(q.Match(q.Index(this.query.index)), { size: this.query.size?this.query.size:300 }),
                    q.Lambda('X', q.Get(q.Var('X')))
                ),

                q.Lambda(
                    'Y',
                    q.ContainsStr(
                        q.Select(this.query.filter.select, q.Var('Y')),
                        appType
                    )

                )

            ),

        )
      }else{
        return client.query(
            q.Map(
                q.Paginate(q.Match(q.Index(this.query.index)), { size: this.query.size?this.query.size:300 }),
                q.Lambda('X', q.Get(q.Var('X')))
            ),
        )
      }




    }
  },
  computed: {

    userLabel: function(){
      if(this.assignedUser){
        return (this.assignedUser.firstname + " " + this.assignedUser.lastname);
      }

      return "?"
    },

    currentUser: function(){
      return this.$store.state.userprofile
    },


    isValid: function(){
      return this.assignedUser;
    },

    warning: function(){
      if(this.users.length && !this.isValid){

        return "Please assign a user"
      }

      return ""

    },

    sortedUsers: function(){
      if(this.users){
        return [...this.users].sort((a, b) => {
          if(a.firstname < b.firstname){
            return -1;
          }else if(a.firstname > b.firstname){
            return 1;
          }else{
            if(a.lastname < b.lastname){
              return -1;
            }else if(a.lastname > b.lastname){
              return 1;
            }else{
              return 0
            }
          }
        });
      }

      return [];
    },

    orgUsers: function(){
      if(this.assignedUser && this.assignedUser.organizationId){

        console.log("Org sort", this.sortedUsers, this.assignedUser)
        return this.sortedUsers.filter((user)=>{
          let alreadyUsed = this.sharedUsers.find((i)=>{
            return i === user.email;
          })
          console.log(user.email,user.organization, user.organizationId, this.assignedUser.organizationId)
          return !alreadyUsed && (user.id !== this.assignedUser.id) && (user.organizationId === this.assignedUser.organizationId)
        })
      }

      return []
    },
  }
}
</script>

<style scoped>


.side-panel-new-user {

}

label[data-invalid="true"]{
  @apply bg-yellow-200
}

</style>
