


<template>
<div class="pre-customer-profile">
  <div class="flex justify-between items-center pb-2">
    <h2 class="font-bold text-xl pl-4">Print MIS and Digital Storefront Savings / Additional Revenue Details</h2>
    <button class="button button-green text-xs" @click="exportXLSX">Export XLSX</button>
  </div>
  <div v-if="savings" class="">

    <savings-details-table class="savings-detail" :assessment="assessment"></savings-details-table>

    <div class="mt-8">
      <h2 class="font-bold text-xl pb-2 border-b-2 pl-4">Print MIS and Digital Storefront Savings / Additional Revenue Summary</h2>
    </div>

    <savings-summary-table class="savings-summary" :assessment="assessment"></savings-summary-table>


  </div>
</div>
</template>

<script>


import SavingsSummaryTable from "@/components/SavingsSummaryTable";
import SavingsDetailsTable from "@/components/SavingsDetailsTable";
import {utils, writeFileXLSX} from 'xlsx'


export default {
    name: "summary-savings-tables",
  components: {SavingsDetailsTable, SavingsSummaryTable},
  mounted: function() {
      if(this.assessment){
        this.$sdm.calculate(this.assessment);
      }

  },
  props: {
      label: { required: false },
      instructions:  { required: false },
    },
    data: function(){
        return {
          orderCellOverrides: {
            "A:0": { colspan: 10 },
            "A:1": { colspan: 10 }
          }
        }
        },
    methods: {
      exportXLSX: function(){
        let workbook = utils.book_new();
        if(!workbook.Props) workbook.Props = {};
        workbook.Props.Title = "Print MIS and Digital Storefront Savings / Additional Revenue Details";
        let details = utils.table_to_sheet(document.querySelector(".savings-detail"));
        let summary = utils.table_to_sheet(document.querySelector(".savings-summary"));

        summary['!cols'] = [
          { wpx: 200 }
        ]

        details['!cols'] = [
          { wpx: 200 }
        ]
        utils.book_append_sheet(workbook,summary,"Summary");
        utils.book_append_sheet(workbook,details,"Detail");
        writeFileXLSX(workbook, 'Savings Summary.xlsx');


      },


      onChange: function(){
        this.$emit("change");
      },
      formattedValue: function(cell){
        if(cell.filter){
          if(cell.filter === "$"){
            console.log(cell);
            return this.$options.filters.currency(cell.value);
          }
        }
        return cell.value;
      },
      resolveValue: function(group, row, col){
        if(this.savings[group][row] !== undefined){
          if(this.savings[group][row][col.key] !== undefined){
            if(col.format === "$"){
              return ("$"+this.$options.filters.decimal(this.savings[group][row][col.key], 0));
            }else{
              return this.$options.filters.decimal(this.savings[group][row][col.key], 0);
            }

          }
        }
      }
    },
    computed: {


      savings: function(){
        if(this.assessment){
          return this.assessment.savings;
        }

        return null;
      },

      detailGroups: function(){
        return [
          { label: "Estimate Development", key: "estimates", rows: [
              { label: "Offset Print Estimates", key: "offset" },
              { label: "Digital Print Estimates", key: "digital" },
              { label: "Large Format Print Estimates", key: "largeFormat" },
              { label: "  Total", key: "subtotal" },
              { label: "Quote letters", key: "quoteLetters" },
            ] },
          { label: "Job Ticket Development", key: "jobTickets", rows: [
              { label: "1) Production", key: "production" },
              { label: "2) Fulfillment Only (already produced and in inventory)", key: "fulfillmentOnly" },
              { label: "Job Tickets Created Manually Each Month (From e-Mail, Phone, Snail Mail)", key: "" },
              { label: "3) Jobs Converted from Estimates", key: "converted" },
              { label: "4) Orders with all Job Specs Identified", key: "withSpecs" },
              { label: "5) Orders - You Develop all Job Specs", key: "developed" },
              { label: "6) Re-order (all production info in system from previous order)", key: "reorder" },
              { label: "7) Fulfillment Orders (already produced and in inventory)", key: "fulfillmentManual" },
              { label: "Total for All Jobs Produced per Year", key: "totals" },
            ] },
          { label: "Inventory", key: "inventory", rows: [
              { label: "Time Spent Managing Inventory", key: "totals" },
            ] },
          { label: "Job Tracking / Scheduling", key: "jobTracking", rows: [
              { label: "Administration/CSR Job Tracking", key: "admin" },
              { label: "Owner/Sales/Production Manager/Supervisor Job Tracking", key: "owner" },
              { label: "Production Meetings", key: "meetings" },
              { label: "Total", key: "totals" },
            ] },
          { label: "Shipment Tracking", key: "shipments", rows: [
              { label: "Amount of Time to Track each UPS/FedEx Package per Job", key: "tracking" },
              { label: "Time to Create Shipping Docs", key: "docs" },
              { label: "Total", key: "totals" },
            ] },
          { label: "Purchasing/Accounts Payable Processing", key: "purchasing", rows: [
              { label: "Create PO", key: "create" },
              { label: "Reconcile PO", key: "reconcile" },
              { label: "Accounts Payable Processing (invoices for purchases for jobs)", key: "processing" },
              { label: "Total", key: "totals" },
            ] },
          { label: "External Purchase vs. Internally Produced", key: "externalVsInternal", rows: [
              { label: "Cost", key: "totals" },
            ] },
          { label: "Billing/Accounts Receivable Processing", key: "billing", rows: [
              { label: "Create Invoice", key: "create" },
              { label: "Log Info into Accounting System", key: "accounting" },
              { label: "Cost of Carrying $s on P&L", key: "plCost" },
              { label: "Total", key: "totals" },
            ] },
          { label: "Labor Tracking", key: "labor", rows: [
              { label: "Time Spent Tracking Labor per Day per Employee", key: "totals" },
            ] },
          { label: "Errors/Rework/Waste", key: "errors", rows: [
              { label: "Reduced Errors - Result of Information Accuracy in Software", key: "reducedSoftware" },
              { label: "Reduced Errors - Content Errors Charged Internally", key: "reducedContent" },
            ] },
          { label: "Billable Customer Changes", key: "changes", rows: [
              { label: "# of Jobs where Billable Changes are Missed when Invoicing", key: "totals" },
            ] },
          { label: "Report Development", key: "reports", rows: [
              { label: "Sales by Customer", key: "sales" },
              { label: "Actual vs. Estimate", key: "actualVsEstimate" },
              { label: "Customer Profitability", key: "customerProfit" },
              { label: "Product Line Profitability", key: "productProfit" },
              { label: "Weekly Sales", key: "weeklySales" },
              { label: "Monthly Sales", key: "monthlySales" },
              { label: "Jobs Shipped and not Invoiced", key: "notInvoiced" },
              { label: "Employee Productivity", key: "productivity" },
              { label: "Real Time Schedule Report", key: "schedule" },
              { label: "Payroll", key: "payroll" },
              { label: "", key: "totals" },
            ] },
          { label: "Miscellaneous", key: "misc", rows: [
              { label: "Opportunity for Cost Savings Not Identified Above", key: "" },
              { label: "Info from Customer - List Area of Savings Opportunity", key: "totals" },
            ] },

        ]
      },

      summaryGroups: function(){
        return [
          { label: "Estimate Development", key: "estimates"},
          { label: "Job Ticket Development", key: "jobTickets"},
          { label: "Inventory", key: "inventory" },
          { label: "Job Tracking / Scheduling", key: "jobTracking" },
          { label: "Shipment Tracking", key: "shipments" },
          { label: "Purchasing/Accounts Payable Processing", key: "purchasing" },
          { label: "Billing/Accounts Receivable Processing", key: "billing" },
          { label: "Labor Tracking", key: "labor" },
          { label: "Errors/Rework/Waste", key: "errors" },
          { label: "Billable Customer Changes", key: "changes" },
          { label: "Report Development", key: "reports" },
          { label: "Miscellaneous", key: "misc" },
        ]
      },

      detailCols: function(){
        return [
          { key: "hours", format:""},
          { key:"cost", format: "$"},
          { key:"hoursMIS", format: ""},
          { key:"costMIS", format: "$"},
          { key:"hoursMISSavings", format: ""},
          { key:"costMISSavings", format: "$"},
          { key:"hoursStorefront", format: ""},
          { key:"costStorefront", format: "$"},
          { key:"hoursStorefrontSavings", format: ""},
          { key:"costStorefrontSavings", format: "$"}]
      },









      assessment: function(){
        return this.$store.state.workflowAssessment
      },

      costs: function(){
        if(this.assessment){
          return this.assessment.costs.vdp;
        }
        return null;
      },


    }
}
</script>

<style scoped>


.pre-customer-profile {

}

tr {
  @apply bg-white;
}

th {
  @apply border bg-gray-100 align-bottom;
  padding: 0.5rem 0.5rem;
}

th.row-label {
  @apply px-4;
}


td {
  @apply text-right border w-16;
  padding: 0.5rem 1.0rem;
  text-align: right;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}

.group-label {
  @apply text-left font-bold underline pt-8;
}
.row-label {
  @apply text-left;
}





td[data-null="true"]{

}

tbody tr:hover td {
  @apply bg-blue-50 cursor-pointer;
}

tr.blank {
  @apply bg-transparent;
}

tr.blank td {
  @apply border-none;
}




</style>
