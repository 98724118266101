


<template>
<div class="pre-customer-profile">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold">{{ options.instructions }}</p>
  </div>
  <div>
    <h2 class="font-bold text-xl pb-2 border-b-2 pl-4">{{ options.label }}</h2>
  </div>
  <div class="" v-if="costs">

    <table class="w-full bg-white border-r border-l text-sm">
      <thead>
        <tr class="">
          <th class="text-left px-4 align-bottom border-r bg-gray-100" colspan="2">MAKE VS. BUY</th>

        </tr>
      </thead>
      <tbody>

      <tr>
        <td>Cost per record for cleansing a mailing list</td>
        <td>${{ costs.costPerRecord | decimal(2) }}</td>
      </tr>

      <tr>
        <td># of records processed per year</td>
        <td>{{ costs.yearlyRecordsProcessed | decimal(0) }}</td>
      </tr>
      <tr>
        <td>Yearly external cost to cleanse mailing lists</td>
        <td>${{ costs.yearlyExternalCleanseCost | decimal(0) }}</td>
      </tr>
      <tr>
        <td>Yearly external costs (excluding cleanings lists)</td>
        <td>${{ costs.yearlyExternalCost | decimal(0) }}</td>
      </tr>
      <tr>
        <td># of Hours</td>
        <td>{{ costs.yearlyHours | decimal(0) }}</td>
      </tr>
      <tr>
        <td>Cost of Labor to Process Mail Internally</td>
        <td>${{ costs.yearlyInternalCost | decimal(0) }}</td>
      </tr>
      <tr class="font-bold">
        <td>YEARLY COST SAVINGS<br>FOR MAKE VS. BUY</td>
        <td>${{ costs.yearlyCostSavings | decimal(0) }}</td>
      </tr>
      </tbody>

    </table>






    <div class="pt-4 border-t">
      <div class="pb-4 flex justify-between">
        <div class="pr-4">
          <button class="button button-green" @click="$emit('summary')">Go to Summary</button>
        </div>

      </div>
    </div>

  </div>
</div>
</template>

<script>
//import NumberInput from "@/components/NumberInput";
export default {
    name: "make-vs-buy-roi-table",
  components: {},
  props: {
      options: {
        default: function(){
          return {
            label: "Make vs Buy ROI",
            costsKey: "",
            instructions: "Make vs Buy instructions"
          }
        }
      },
    },
    data: function(){
        return {
          rates: [
            { label: "5-Digit Automation", key: "fiveDigit"},
            { label: "AADC", key: "AADC"},
            { label: "Mixed AADC", key: "MixedAADC"},
            { label: "3-Digit", key: "threeDigit"},
            { label: "Mixed ADC", key: "mixedADC"},
            { label: "Meter", key: "meter"},
            { label: "Stamp", key: "stamp"},

          ]
        }
        },
    methods: {
      onChange: function(){
        this.$emit("change");
      }
    },
    computed: {
      costs: function(){
        if(this.$store.state.workflowAssessment && this.$store.state.workflowAssessment.costs){
            return this.$store.state.workflowAssessment.costs.makeVsBuy;
        }

        return null;
      }
    }
}
</script>

<style scoped>


.pre-customer-profile {

}

td {
  @apply text-right py-2 px-4 border bg-white w-16;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}

</style>