


<template>
<div class="survey flex flex-col h-full relative">

  <div class="flex-grow relative flex overflow-hidden">
    <div class="text-sm w-32 mt-8 ml-8">
      <link-list class="" :links="sections" :selection="currentSection" @change="setSection"></link-list>
    </div>
    <div class="flex-grow ml-8 border-l px-8 h-full overflow-y-scroll relative" ref="tables">
      <div>
        <component class="my-8" :is="currentSection.component" @next="goNext" @summary="goSummary" :instructions="currentSection.instructions" :options="currentSection" @change="recalculate"></component>
      </div>
    </div>
  </div>
  <div>


  </div>

</div>
</template>



<script>
import LinkList from "@/components/LinkList";
import SummaryMailExport from "@/components/SummaryMailExport";
import SummaryMailSavingsTables from "@/components/SummaryMailSavingsTables";
import SummaryMailTables from "@/components/SummaryMailTables";





export default {
    name: "assessment-mail-summary-panel",
  components: {
    SummaryMailTables, SummaryMailSavingsTables, SummaryMailExport, LinkList },
  props: {

  },
  mounted: function(){

  },
    data: function(){
        return {
          sections:[
            { label: "Export", id: "reportexport", component: "SummaryMailExport",
              instructions: "Items in the list below are available for preview or export.",
            },
            { label: "ROI Summary", id: "roi", component: "SummaryMailTables",
              instructions: "",
            },
            { label: "Savings Details", id: "savings", component: "SummaryMailSavingsTables",
              instructions: "",
            }
          ]
        }
        },
    methods: {

      recalculate: function(){
        this.$emit("recalculate");
      },


      setSection: function(section){

        //console.log("goto", section.id);
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: this.$route.query.tab,
            section: section.id
          }
        })

        this.$refs.tables.scrollTo(0,0);
      },

      goToSection: function(sectionId){
        this.setSection({ id: sectionId });
      },

      goNext: function(){
        let currentIndex = this.sections.indexOf(this.currentSection);


        let nextSection = this.sections[currentIndex + 1];


        if((currentIndex + 1) < this.sections.length){
          this.setSection(nextSection);
        }
      },

      goSummary: function(){

      }
    },
    computed: {
      currentSection: function(){
        //console.log("current section?");

        //console.log(this.sections);
        var section = this.sections.find((item) => {
          return item.id == this.$route.query.section;
        })

        if(!section){
          section = this.sections[0];
        }

        return section;
      }
    }
}
</script>

<style scoped>


.survey {

}

</style>