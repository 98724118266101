export default {
  "groups":{
    "Organization Statistics":{ "label":"Organization Statistics", "id":"org","questions":["X3000A","X3000B","I1001","I1010","I1010A","I1014","I1010B","X3001K","X3001L","X3001D","X3001E","X3001F","X3001G","I1010H","X3001H","X3001I","X3001J","X3002","X3003","X3003A","X3003B","X3004","X3005", "X3006","X3021","X3033", "X3034", "X3035","X3046","X3047", "X3048", "X3049", "X3050", "X3051","X3057","X3108"]},
    "Production Volume":{ "label":"Production Volume", "id":"volume","questions":["I1003", "X3002A","X3002B", "X3002C", "X3063", "X3064", "X3065A", "X3065B", "X3065C", "X3065D", "X3065E", "X3065F", "X3065G", "X3065H","X3065"]},
    "Mail Stats":{ "label":"Mail Stats", "id":"mailstats","questions":["X3007", "X3008", "X3009", "X3010", "X3011", "X3012","X3012A", "X3012B", "X3012C", "X3012D", "X3012E", "X3013", "X3014A", "X3014B", "X3014C", "X3014D", "X3014E", "X3014F", "X3014G", "X3015", "X3018", "X3019A", "X3019B", "X3020A", "X3020B", "X3023"]},
    "Workflow Processes":{ "label":"Workflow Processes", "id":"workflow","questions":["X3016","X3017","X3022","X3022A","X3024","X3025","X3026","X3027","X3028","X3029","X3030","X3031","X3032","X3036","X3037","X3038","X3039","X3040","X3041","X3041A","X3052","X3053","X3054","X3058"]},
    "QC / Compliance":{ "label":"QC / Compliance", "id":"qc","questions":["X3042", "X3042A", "X3042B", "X3043B", "X3043", "X3044", "X3044B","X3045","X3045B",  "X3045A"]},
    "Finance / Postage":{ "label":"Finance / Postage", "id":"finance","questions":["X3055A","X3055A1","X3055A2","X3055A3","X3055A4","X3055A5","X3055A6","X3055A7","X3055A8","X3055A9","X3055A10","X3055B","X3055B1","X3055B2","X3055B3","X3055B4","X3055B5","X3055B6","X3055B7","X3055B8","X3055B9","X3055B10",  "X3059", "X3060", "X3060A", "X3061", "X3062", "X3062A"]},
    "Returned Mail":{ "label":"Returned Mail", "id":"returned","questions":["X3086", "X3087", "X3088", "X3089", "X3090", "X3090A", "X3090B", "X3090C", "X3090D", "X3090E", "X3091"]},
    "Production Operations":{ "label":"Production Operations", "id":"operations","questions":["X3066", "X3067", "X3068", "X3069", "X3070", "X3071", "X3072", "X3073", "X3074", "X3075", "X3076", "X3077", "X3078", "X3079", "X3080", "X3081", "X3081A", "X3081B", "X3082", "X3083", "X3084", "X3085", "X3092", "X3093", "X3094", "X3095", "X3096", "X3097", "X3098", "X3099", "X3100", "X3101", "X3102", "X3103", "X3104", "X3105", "X3106", "X3107", "X3109", "X3110", "X3111", "X3112"]},

  },

  "stages":{
    "Organization Statistics":{ "id": "org", "markerLabel": "Organization<br>Statistics", "label": "Organization Statistics", "sections":["org"],
      "instructions": "Please fill out the forms below from information provided by the Customer."
    },
    "Production Volume":{ "id": "volume", "markerLabel": "Production<br>Volume", "label": "Production Volume", "sections":["volume"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Mail Stats":{ "id": "mailstats", "markerLabel": "Mail Stats", "label": "Mail Stats", "sections":["mailstats"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Workflow Processes":{ "id": "workflow", "markerLabel": "Workflow<br>Processes", "label": "Workflow Processes", "sections":["workflow"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "QC / Compliance":{ "id": "qc", "markerLabel": "QC/Compliance", "label": "QC / Compliance", "sections":["qc"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Finance / Postage":{ "id": "finance", "markerLabel": "Finance /<br> Postage.", "label": "Finance / Postage", "sections":["finance"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Returned Mail":{ "id": "returned", "markerLabel": "Returned<br>Mail", "label": "Returned Mail", "sections":["returned"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Production Operations":{ "id": "operations", "markerLabel": "Production<br>Operations", "label": "Production Operations", "sections":["operations"], "instructions": "Please fill out the forms below from information provided by the Customer."}
  },


  "questions":{

    "X3000A":{
      "id": "X3000A",
      "num": 1,
      "type":3,
      "question": "Hours of Operation",
      "caption": "",
      "hint": "Open and Close Times",
      "cols":[],
      "defaults":[]
    },

    "X3000B":{
      "id": "X3000B",
      "num": 1,
      "type":3,
      "question": "Days of Operation",
      "caption": "#",
      "hint": "Days of the Week",
      "cols":[],
      "defaults":[]
    },

    "I1001":{
      "id": "I1001",
      "num": 1,
      "type":2,
      "question": "Annual Sales",
      "caption": "$",
      "dataFormat":"$",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "I1010":{
      "id": "I1010",
      "num": 10,
      "type":2,
      "question": "Total Number of Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": null,
      "cols":[],
      "defaults":[],
      "enabled":[false]
    },

    "I1010A":{
      "id": "I1010A",
      "num": 10,
      "type":2,
      "question": "Number of Management Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$b"},
      "calcVars":{"a":"I1013.final", "b":"I1010.final"}
    },
    "I1010B":{
      "id": "I1010B",
      "num": 10,
      "type":2,
      "question": "Number of Administrative Employees / Job Ticket Developers / CSRs",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$a"},
      "calcVars":{"a":"I1013.final"}
    },
    "I1014":{
      "id": "I1014",
      "num": 14,
      "type":2,
      "question": "Number of Sales People",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3001D":{
      "id": "X3001D",
      "num": 14,
      "type":2,
      "question": "Number of Mail Piece Design and Development Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3001E":{
      "id": "X3001E",
      "num": 14,
      "type":2,
      "question": "Number of Employees who Prep Mailing Lists",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3001F":{
      "id": "X3001F",
      "num": 14,
      "type":2,
      "question": "Number of Insertion Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3001G":{
      "id": "X3001G",
      "num": 14,
      "type":2,
      "question": "Number of Employees who Manage Inventory",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "I1010H":{
      "id": "I1010H",
      "num": 10,
      "type":2,
      "question": "Number of Shipping / Receiving / Delivery Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": null,
      "cols":[],
      "defaults":[],
    },
    "X3001H":{
      "id": "X3001H",
      "num": 17,
      "type":2,
      "question": "Average Hourly Wage of an Administrative/Job Ticket Developer/CSR and Mail Piece Design and Development Employee",
      "caption": "#",
      "dataFormat":"$",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[18.00]
    },
    "X3001I":{
      "id": "X3001I",
      "num": 17,
      "type":2,
      "question": "Average Hourly Wage of an Insertion Employee",
      "caption": "#",
      "dataFormat":"$",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[17.00]
    },
    "X3001J":{
      "id": "X3001J",
      "num": 19,
      "type":2,
      "question": "What is your Burden Rate Percentage for Employee Benefits?",
      "caption": "#",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3001K":{
      "id": "X3001K",
      "num": 14,
      "type":2,
      "question": "Total Employees in Administrative Roles (including customer service, management and sales)",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "cols":[],
      "enabled":[false],
      "defaults":[]
    },

    "X3001L":{
      "id": "X3001L",
      "num": 19,
      "type":2,
      "question": "Employees who process files and prepare mailing lists for production processing",
      "caption": "#",
      "dataFormat":"0.0",
      "precision": 2,
      "hint": "",
      "enabled":[false],
      "cols":[],
      "defaults":[]
    },



    "X3002":{
      "id": "X3002",
      "num": 24,
      "type":3,
      "question": "Products and Services Include ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3002A":{
      "id": "X3002A",
      "num":29 ,
      "type":5,
      "question": "Do you provide printing services for the mailings that you process?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },




    "X3003":{
      "id": "X3003",
      "num":37 ,
      "type":3,
      "question": "USPS Postal Promotions that are utilized include:",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },

    "X3003A":{
      "id": "X3003A",
      "num": 1,
      "type":2,
      "question": "What volume of mail could be processed taking advantage of USPS Postal Promotions?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3003B":{
      "id": "X3003B",
      "num": 19,
      "type":2,
      "question": "What % of a discount could be gained with USPS Postal Promotions?",
      "caption": "#",
      "dataFormat":"%",
      "hint": "Generally 3-6%",
      "cols":[],
      "defaults":[]
    },
    "X3004":{
      "id": "X3004",
      "num":37 ,
      "type":3,
      "question": "Organizational software includes:",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "X3005":{
      "id": "X3005",
      "num":37 ,
      "type":3,
      "question": "Equipment includes:",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "X3006":{
      "id": "X3006",
      "num":37 ,
      "type":3,
      "question": "The main customers of the mail operation include:",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "X3021":{
      "id": "X3021",
      "num": 23,
      "type":8,
      "question": "The design of the mail piece is completed by...",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["In-house", "Customer", "Other"]

    },
    "X3033":{
      "id": "X3033",
      "num": 1,
      "type":2,
      "question": "Turnaround time/SLAs are generally how many days?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3034":{
      "id": "X3034",
      "num": 1,
      "type":3,
      "question": "If days of operation fall on a holiday, the expectations for turnaround times are:",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "X3035":{
      "id": "X3035",
      "num": 1,
      "type":3,
      "question": "Peak periods for processing include ...",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "X3046":{
      "id": "X3046",
      "num": 23,
      "type":8,
      "question": "The following types of postage meters are used:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Metered - IBI", "Metered - IMI (Intelligent Mail Indicia)", "Metered - IBIP (Information Based Indicia)","Other"]

    },
    "X3047":{
      "id": "X3047",
      "num": 23,
      "type":8,
      "question": "The following classes of mail are processed:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["First Class", "Marketing", "Periodicals","Packages","Priority Mail","Priority Mail Express","Non-Profit","Other"]

    },
    "X3048":{
      "id": "X3048",
      "num": 23,
      "type":8,
      "question": "Additional services required by customers include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Delivery confirmation", "Return receipt", "Certified mail processing","Registered mail","Business reply","Returned mail processing","Other"]

    },
    "X3049":{
      "id": "X3049",
      "num": 23,
      "type":8,
      "question": "The types of mail that are processed include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Letters", "Cards", "Flats","Parcels/Packages","Other"]

    },
    "X3050":{
      "id": "X3050",
      "num": 1,
      "type":2,
      "question": "How many mailing permits are managed by the organization?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3051":{
      "id": "X3051",
      "num": 23,
      "type":8,
      "question": "The types of mail that are processed include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Presort First Class", "Marketing Mail", "Bound Printed Matter","Non-Profit","Other"]

    },
    "X3056":{
      "id": "X3056",
      "num":29 ,
      "type":5,
      "question": "Do you utilize a presort bureau?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3057":{
      "id": "X3057",
      "num": 23,
      "type":8,
      "question": "The following mailing services are utilized:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Every Door Direct", "USPS Informed Delivery","Other"]

    },

    "X3108":{
      "id": "X3108",
      "num":25 ,
      "type":3,
      "question": "The organization has the following disaster recovery program in place for documents processed by the print and/or mail operation:",
      "caption": "",
      "hint": "(include the procedures to follow in the event of a disaster)",
      "cols":[],
      "defaults":[]
    },


    "X3007":{
      "id": "X3007",
      "num": 1,
      "type":2,
      "question": "Number of times mail is routed to the USPS each day:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3008":{
      "id": "X3008",
      "num":29 ,
      "type":5,
      "question": "Do you have a Mailer ID?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },

    "X3009":{
      "id": "X3009",
      "num": 1,
      "type":3,
      "question": "Mailing addresses serviced include:",
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },

    "X3010":{
      "id": "X3010",
      "num": 1,
      "type":2,
      "question": "The value of postage processed by the organization is estimated at:",
      "caption": "$",
      "dataFormat":"$",

      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3011":{
      "id": "X3011",
      "num": 23,
      "type":8,
      "question": "The classifications of mail include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Certified", "Priority",	"First-Class",	"Periodicals",	"Marketing Mail",	"Packages", "Non-Profit","Other"]

    },

    "X3012":{
      "id": "X3012",
      "num":29 ,
      "type":5,
      "question": "Is First Class Mail pre-sorted?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3012A":{
      "id": "X3012A",
      "num":29 ,
      "type":5,
      "question": "Do you use a pre-sort vendor for processing your mail?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3012B":{
      "id": "X3012B",
      "num": 1,
      "type":2,
      "question": "What does the pre-sort vendor charge per record for cleansing your mailing lists?",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3012C":{
      "id": "X3012C",
      "num": 1,
      "type":2,
      "question": "How many records per year does your pre-sort vendor process for cleansing?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3012D":{
      "id": "X3012D",
      "num": 1,
      "type":2,
      "question": "What is the estimated yearly cost for the pre-sort vendor to process your mail?",
      "caption": "$",
      "dataFormat":"$",
      "hint": "(not including the cost to cleanse mailing lists)",
      "cols":[],
      "defaults":[]
    },
    "X3012E":{
      "id": "X3012E",
      "num": 1,
      "type":2,
      "question": "Estimated hours it will take your operation to process mail in a year?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "(job intake, file processing/routing and inserting)",
      "cols":[],
      "defaults":[]
    },

    "X3013":{
      "id": "X3013",
      "num": 23,
      "type":8,
      "question": "Postage is applied through:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Stamps Meter","Indicia/Postal Permit", "Permit","Other"]

    },
    "X3014A":{
      "id": "X3014A",
      "num": 1,
      "type":2,
      "question": "The average cost of Certified Mail postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3014B":{
      "id": "X3014B",
      "num": 1,
      "type":2,
      "question": "The average cost of Priority Mail postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3014C":{
      "id": "X3014C",
      "num": 1,
      "type":2,
      "question": "The average cost of First-Class Mail postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3014D":{
      "id": "X3014D",
      "num": 1,
      "type":2,
      "question": "The average cost of Periodical postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3014E":{
      "id": "X3014E",
      "num": 1,
      "type":2,
      "question": "The average cost of Marketing Mail postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3014F":{
      "id": "X3014F",
      "num": 1,
      "type":2,
      "question": "The average cost of Packages postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3014G":{
      "id": "X3014G",
      "num": 1,
      "type":2,
      "question": "The average cost of Non-Profit postage is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3015":{
      "id": "X3015",
      "num":29 ,
      "type":5,
      "question": "Are postal reports accessed through a Postal1 account?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3018":{
      "id": "X3018",
      "num": 23,
      "type":8,
      "question": "The following types of mail pieces are processed:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["#10 Envelopes",	"6 x 9 Flats",	"9 x 12 Flats",	"Direct Mail Post Cards","Other"]

    },

    "X3019A":{
      "id": "X3019A",
      "num": 1,
      "type":3,
      "question": "Low-end weight of a mail piece is?",
      "caption": "#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3019B":{
      "id": "X3019B",
      "num": 1,
      "type":3,
      "question": "High-end weight of a mail piece is?",
      "caption": "#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3020A":{
      "id": "X3020A",
      "num": 1,
      "type":3,
      "question": "Low-end thickness of a mail piece is?",
      "caption": "#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3020B":{
      "id": "X3020B",
      "num": 1,
      "type":3,
      "question": "High-end thickness of a mail piece is?",
      "caption": "#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3023":{
      "id": "X3023",
      "num": 23,
      "type":8,
      "question": "Mail optimization processes include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Address Optimization","Pre-sort",	"Bar Code Development","Other"]

    },

    "I1003":{
      "id": "I1003",
      "num": 3,
      "type":2,
      "question": "Total Number of Mail Pieces per Year",
      "caption": "#",
      "dataFormat":"#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$a"},
      "calcVars":{"a":"I1157.final"}
    },

    "X3002B":{
      "id": "X3002B",
      "num": 1,
      "type":2,
      "question": "What is the volume of b/w 8.5 x 11 pages produced per year?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3002C":{
      "id": "X3002C",
      "num": 1,
      "type":2,
      "question": "What is the volume of color 8.5 x 11 pages produced per year?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3063":{
      "id": "X3063",
      "num": 1,
      "type":2,
      "question": "What is the average volume of envelopes per day?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3064":{
      "id": "X3064",
      "num": 1,
      "type":2,
      "question": "What is the maximum volume of envelopes per day?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065A":{
      "id": "X3065A",
      "num": 1,
      "type":2,
      "question": "The total volume of envelopes processed annually is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065B":{
      "id": "X3065B",
      "num": 1,
      "type":2,
      "question": "The average postage cost for each mail piece is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065C":{
      "id": "X3065C",
      "num": 1,
      "type":2,
      "question": "The total volume of flats processed annually is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065D":{
      "id": "X3065D",
      "num": 1,
      "type":2,
      "question": "The average postage cost for each flat is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065E":{
      "id": "X3065E",
      "num": 1,
      "type":2,
      "question": "The total volume of cards processed annually is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065F":{
      "id": "X3065F",
      "num": 1,
      "type":2,
      "question": "The average postage cost for each card is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":3,
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065G":{
      "id": "X3065G",
      "num": 1,
      "type":2,
      "question": "The total volume of parcels/packages processed annually is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3065H":{
      "id": "X3065H",
      "num": 1,
      "type":2,
      "question": "The average postage cost for each parcel/package is...",
      "caption": "$",
      "dataFormat":"$",
      "precision":2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3065":{
      "id": "X3065",
      "num": 1,
      "type":2,
      "question": "The total mailing pieces processed annually is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "X3065A + X3065C + X3065E + X3065G",
      "enabled": [false],
      "cols":[],
      "defaults":[]
    },

    "X3016":{
      "id": "X3016",
      "num": 24,
      "type":3,
      "question": "Orders are submitted through ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3017":{
      "id": "X3017",
      "num": 24,
      "type":3,
      "question": "Job tickets are developed by entering information received in ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3022":{
      "id": "X3022",
      "num": 23,
      "type":8,
      "question": "Personalization and Programming of the Conditional Logic is processed by:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["In-house","Customer","Other"]

    },

    "X3022A":{
      "id": "X3022A",
      "num":29 ,
      "type":5,
      "question": "Is there at least one staff member certified by the USPS as a mail design professional?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },

    "X3024":{
      "id": "X3024",
      "num": 24,
      "type":8,
      "question": "The following mailing list services are provided:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["NCOA", "CASS Certification", "PAVE",	"DPV",	"LACSLink",	"SUITELink",	"eLOT",	"RDI Service",	"PCOA",	"Death",	"Incarceration", "Other",]
    },
    "X3025":{
      "id": "X3025",
      "num":29 ,
      "type":5,
      "question": "Are you running any address resolution services?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },

    "X3026":{
      "id": "X3026",
      "num": 23,
      "type":5,
      "question": "Address resolution is provided in the following frequency:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["by job","monthly","quarterly","every 6 months","yearly","Other"]

    },
    "X3027":{
      "id": "X3027",
      "num": 23,
      "type":8,
      "question": "The following software is used to provide address resolution:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["BCC",	"Lorton","Accuzip","Other"]

    },
    "X3028":{
      "id": "X3028",
      "num": 23,
      "type":8,
      "question": "The file types that are processed include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["PDF",	"Flat", "Text",	"XML",	"JSON",	"Postscript",	"PCL",	"CSV",	"IPDS","Other"]

    },
    "X3029":{
      "id": "X3029",
      "num": 23,
      "type":8,
      "question": "The origin of the mailing list file is?",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Internal","Customer supplied","Sourced","Other"]

    },
    "X3030":{
      "id": "X3030",
      "num": 1,
      "type":2,
      "question": "The number of files that are processed monthly?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3031":{
      "id": "X3031",
      "num": 1,
      "type":2,
      "question": "The number of records/images/pages that are processed monthly?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3032":{
      "id": "X3032",
      "num": 1,
      "type":2,
      "question": "The highest volume of records/images/pages that is processed in one day is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3036":{
      "id": "X3036",
      "num": 23,
      "type":8,
      "question": "In addition to USPS mailing, other methods of communication that are processed by this organization include?",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Email",	"Text",	"Online/Web","Other"]

    },
    "X3037":{
      "id": "X3037",
      "num": 23,
      "type":8,
      "question": "Customer communication and/or billing preferences are managed by:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["In-house",	"Customer",	"Other"]

    },

    "X3038":{
      "id": "X3038",
      "num": 24,
      "type":3,
      "question": "The system in place for customers to indicate their preference of communications includes ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3039":{
      "id": "X3039",
      "num": 23,
      "type":8,
      "question": "The following methods of communication can be selected by customers:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Mail", "Email",	"Text Messaging", "Payment Methods - Credit Card, Direct Bill, Electronic Funds Transfer (EFT)",	"Other"]

    },
    "X3040":{
      "id": "X3040",
      "num": 23,
      "type":8,
      "question": "Communication content that customers can opt in for include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Events and webinars",	"Industry-related trends and thought leadership",	"New product information",	"Promotions","None","Other"]
    },
    "X3041":{
      "id": "X3041",
      "num":29 ,
      "type":5,
      "question": "Do your print projects require approvals?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3041A":{
      "id": "X3041A",
      "num":29 ,
      "type":3,
      "question": "Please explain the process for approvals:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
    },
    "X3052":{
      "id": "X3052",
      "num": 23,
      "type":8,
      "question": "Mail is processed in the following classifications:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Automation",	"Machinable",	"Nonmachinable","Other"]
    },
    "X3053":{
      "id": "X3053",
      "num": 23,
      "type":8,
      "question": "The types of barcodes incorporated into mailings include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Address block barcodes",	"IMB barcodes","Other"]
    },
    "X3054":{
      "id": "X3054",
      "num": 23,
      "type":5,
      "question": "How are barcodes added to mailing pieces?",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["printed",	"labeled"]
    },

    "X3058":{
      "id": "X3058",
      "num":29 ,
      "type":5,
      "question": "Do you present an electronic manifest when presenting mail to the post office?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },

    "X3066":{
      "id": "X3066",
      "num": 24,
      "type":2,
      "question": "How many types of outer envelopes are used?",
      "caption": "#",
      "dataFormat": "#",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3067":{
      "id": "X3067",
      "num": 24,
      "type":3,
      "question": "List the sizes of envelopes used...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3068":{
      "id": "X3068",
      "num": 24,
      "type":3,
      "question": "What would be required to reduce the number of Outer Envelopes?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3069":{
      "id": "X3069",
      "num": 1,
      "type":2,
      "question": "What is the average number of pieces in a mailing?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3070":{
      "id": "X3070",
      "num": 23,
      "type":8,
      "question": "The substrates that are printed include:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["White Paper",	"Preprinted Forms","Other"]
    },
    "X3071":{
      "id": "X3071",
      "num":29 ,
      "type":5,
      "question": "Do you commingle several customer jobs into one mail stream or do you commingle one customers mailings with other customer mailings?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3072":{
      "id": "X3072",
      "num":29 ,
      "type":5,
      "question": "Do you provide householding services for mailings?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3073":{
      "id": "X3073",
      "num":29 ,
      "type":5,
      "question": "Are inserts included in customer mailings?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3074":{
      "id": "X3074",
      "num": 24,
      "type":3,
      "question": "How many inserts can you accommodate on your inserters?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3075":{
      "id": "X3075",
      "num":29 ,
      "type":5,
      "question": "Can the inserts be selective (different inserts for each envelope's contents?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3076":{
      "id": "X3076",
      "num":29 ,
      "type":5,
      "question": "Is it a requirement to pull records from the data that needs to be handled separately from the mailing?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3077":{
      "id": "X3077",
      "num": 24,
      "type":3,
      "question": "The process for handling the pulling of records within a mailing includes...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3078":{
      "id": "X3078",
      "num": 19,
      "type":2,
      "question": "What % of work is hand inserted?",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3079":{
      "id": "X3079",
      "num": 24,
      "type":3,
      "question": "Hand inserting vs. machine insertion is required due to...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3080":{
      "id": "X3080",
      "num":29 ,
      "type":5,
      "question": "Are you currently outsourcing mail processing due to capacity constraints?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3081":{
      "id": "X3081",
      "num": 19,
      "type":2,
      "question": "What percent of mail processing is outsourced due to capacity constraints?",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3081A":{
      "id": "X3081A",
      "num":29 ,
      "type":5,
      "question": "Is the productivity of employees tracked?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3081B":{
      "id": "X3081B",
      "num": 19,
      "type":2,
      "question": "What is the current % of productive hours for production employees?",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3082":{
      "id": "X3082",
      "num": 23,
      "type":8,
      "question": "Please indicate areas where there are documented Standard Operating Procedures for the operation:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Job intake/ticketing",	"Mail piece design",	"Prep/mailing list processing",	"Inserter production",	"Delivery and distribution","Other"]
    },
    "X3083":{
      "id": "X3083",
      "num": 24,
      "type":3,
      "question": "Job workflow and the software used in processing projects includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3084":{
      "id": "X3084",
      "num": 24,
      "type":3,
      "question": "The process for reprinting mail pieces that were damaged in the production process includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3085":{
      "id": "X3085",
      "num": 24,
      "type":3,
      "question": "The process for managing reprints for mailings processed by a presort bureau includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3092":{
      "id": "X3092",
      "num": 24,
      "type":3,
      "question": "The following software is used to manage inventory:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3093":{
      "id": "X3093",
      "num": 24,
      "type":3,
      "question": "The following types, sizes and estimated qty. of envelopes are held in inventory:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3094":{
      "id": "X3094",
      "num":29 ,
      "type":5,
      "question": "Are there automated notifications for triggers or alerts for stocking levels, reorder requirements or low inventory?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3095":{
      "id": "X3095",
      "num": 24,
      "type":3,
      "question": "Quality control procedure for monitoring mail processing includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3096":{
      "id": "X3096",
      "num": 24,
      "type":3,
      "question": "Compliance requirements for tracking mail include:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3097":{
      "id": "X3097",
      "num": 24,
      "type":3,
      "question": "Software and methods for scheduling jobs include:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3098":{
      "id": "X3098",
      "num": 24,
      "type":3,
      "question": "Production status reports are provided by:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3099":{
      "id": "X3099",
      "num":29 ,
      "type":5,
      "question": "Is dynamic scheduling provided through the software solution managing production processes?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3100":{
      "id": "X3100",
      "num": 23,
      "type":8,
      "question": "Customers submit jobs through the following methods:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Email",	"Ecommerce/Storefront",	"Homegrown Job Intake System",	"Phone",	"In Person","Other"]
    },
    "X3101":{
      "id": "X3101",
      "num": 24,
      "type":3,
      "question": "The following reports are used to manage the production and financial aspects of the operation:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3102":{
      "id": "X3102",
      "num": 24,
      "type":3,
      "question": "Other reports that would be beneficial in or to monitor  production and report required data back to customers include:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3103":{
      "id": "X3103",
      "num": 24,
      "type":3,
      "question": "The following preventative maintenance is performed by the operation's employees:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3104":{
      "id": "X3104",
      "num": 24,
      "type":3,
      "question": "The process for vendor provided equipment maintenance includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3105":{
      "id": "X3105",
      "num": 24,
      "type":3,
      "question": "The process for onboarding new customers includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3106":{
      "id": "X3106",
      "num": 24,
      "type":3,
      "question": "The process for onboarding new employees includes :",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3107":{
      "id": "X3107",
      "num": 24,
      "type":3,
      "question": "The following training and cross-training procedures are provided to employees:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3109":{
      "id": "X3109",
      "num": 1,
      "type":4,
      "question": "How much time is spent designing mail pieces in one month?",
      "caption": "#",
      "dataFormat":"0.0",
      "precision":2,
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3110":{
      "id": "X3110",
      "num": 1,
      "type":4,
      "question": "How much time is spent receiving orders and creating job tickets in one month?",
      "caption": "#",
      "dataFormat":"0.0",
      "precision":2,
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3111":{
      "id": "X3111",
      "num": 1,
      "type":4,
      "question": "How much time is spent cleansing mailing lists per month?",
      "caption": "#",
      "dataFormat":"0.0",
      "precision":2,
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3112":{
      "id": "X3112",
      "num": 1,
      "type":4,
      "question": "How much time is spent routing mailing files per month?",
      "caption": "#",
      "dataFormat":"0.0",
      "precision":2,
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },

    "X3042":{
      "id": "X3042",
      "num": 23,
      "type":8,
      "question": "Compliance controls are required to be in place for the following:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["Regulatory compliance",	"HIPAA/PII","Other"]
    },
    "X3042A":{
      "id": "X3042A",
      "num": 1,
      "type":2,
      "question": "How many times has the organization been fined for non-compliance/regulatory issues in the past year?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3042B":{
      "id": "X3042B",
      "num": 1,
      "type":2,
      "question": "What was the total value of fines for non-compliance?",
      "caption": "$",
      "dataFormat":"$",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3043B":{
      "id": "X3043B",
      "num": 19,
      "type":2,
      "question": "What % of the fines could be eliminated with the proposed solution?",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X3043":{
      "id": "X3043",
      "num": 24,
      "type":3,
      "question": "Please explain the requirements to ensure compliance.",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3044":{
      "id": "X3044",
      "num":29 ,
      "type":5,
      "question": "Do you require closed loop reporting to be ingested into another system?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3044B":{
      "id": "X3044B",
      "num":29 ,
      "type":3,
      "question": "What System is the mail data imported into?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3045":{
      "id": "X3045",
      "num":29 ,
      "type":5,
      "question": "Is data required to be deleted from your system?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3045B":{
      "id": "X3045B",
      "num":29 ,
      "type":2,
      "question": "How many days after a project is completed does data need to be deleted from your system?",
      "caption": "#",
      "dataFormat":"#",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "X3045A":{
      "id": "X3045A",
      "num": 24,
      "type":3,
      "question": "The following data needs to be deleted from our system:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3055A":{
      "id": "X3055A",
      "num": 23,
      "type":7,
      "question": "Presort first class mailings are processed with the following classifications and percentages:",
      "caption": "",
      "hint": "Each column must add up to 100%",
      "cols":[],
      "defaults":[],

    },

    "X3055A1":{
      "id": "X3055A1",
      "num": 19,
      "type":4,
      "question": "5-Digit - Automation",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A2":{
      "id": "X3055A2",
      "num": 19,
      "type":4,
      "question": "AADC - Automation",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A3":{
      "id": "X3055A3",
      "num": 19,
      "type":4,
      "question": "Mixed AADC - Automation",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A4":{
      "id": "X3055A4",
      "num": 19,
      "type":4,
      "question": "AADC - Nonautomation",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A5":{
      "id": "X3055A5",
      "num": 19,
      "type":4,
      "question": "Mixed AADC - Nonautomation",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A6":{
      "id": "X3055A6",
      "num": 19,
      "type":4,
      "question": "5-Digit Nonautomation Nonmachinable",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A7":{
      "id": "X3055A7",
      "num": 19,
      "type":4,
      "question": "3-Digit Nonautomation Nonmachinable",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A8":{
      "id": "X3055A8",
      "num": 19,
      "type":4,
      "question": "Mixed ADC Nonautomation Nonmachinable",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A9":{
      "id": "X3055A9",
      "num": 19,
      "type":4,
      "question": "Meter",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055A10":{
      "id": "X3055A10",
      "num": 19,
      "type":4,
      "question": "Stamp",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":["Current","Solution"],
      "defaults":[]
    },
    "X3055B":{
      "id": "X3055B",
      "num": 23,
      "type":7,
      "question": "Current Postage Rates for different Classifications:",
      "caption": "",
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[],
    },

    "X3055B1":{
      "id": "X3055B1",
      "num": 19,
      "type":2,
      "question": "5-Digit - Automation",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.507]
    },
    "X3055B2":{
      "id": "X3055B2",
      "num": 19,
      "type":2,
      "question": "AADC - Automation",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.547]
    },
    "X3055B3":{
      "id": "X3055B3",
      "num": 19,
      "type":2,
      "question": "Mixed AADC - Automation",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.571]
    },
    "X3055B4":{
      "id": "X3055B4",
      "num": 19,
      "type":2,
      "question": "AADC - Nonautomation",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.553]
    },
    "X3055B5":{
      "id": "X3055B5",
      "num": 19,
      "type":2,
      "question": "Mixed AADC - Nonautomation",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.586]
    },
    "X3055B6":{
      "id": "X3055B6",
      "num": 19,
      "type":2,
      "question": "5-Digit Nonautomation Nonmachinable",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.716]
    },
    "X3055B7":{
      "id": "X3055B7",
      "num": 19,
      "type":2,
      "question": "3-Digit Nonautomation Nonmachinable",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.823]
    },
    "X3055B8":{
      "id": "X3055B8",
      "num": 19,
      "type":2,
      "question": "Mixed AADC Nonautomation Nonmachinable",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.943]
    },
    "X3055B9":{
      "id": "X3055B9",
      "num": 19,
      "type":2,
      "question": "Meter",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.64]
    },
    "X3055B10":{
      "id": "X3055B10",
      "num": 19,
      "type":2,
      "question": "Stamp",
      "caption": "$",
      "dataFormat":"$",
      "precision": 3,
      "hidden": true,
      "hint": "",
      "cols":[],
      "defaults":[0.68]
    },

    "X3059":{
      "id": "X3059",
      "num": 24,
      "type":3,
      "question": "The process for billing includes ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3060":{
      "id": "X3060",
      "num":29 ,
      "type":5,
      "question": "Do you bill back to account codes?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },
    "X3060A":{
      "id": "X3060A",
      "num": 24,
      "type":3,
      "question": "What is the format of the characters for account codes?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3061":{
      "id": "X3061",
      "num": 24,
      "type":3,
      "question": "Postage costs that are processed on meters is billed back to customers by ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3062":{
      "id": "X3062",
      "num":29 ,
      "type":5,
      "question": "Are you billing back actual postage costs to customers?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Yes","No"]
    },

    "X3062A":{
      "id": "X3062A",
      "num": 24,
      "type":3,
      "question": "The barrier to billing back actual postage costs is  ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },

    "X3086":{
      "id": "X3086",
      "num": 19,
      "type":2,
      "question": "What % of mailing pieces processed are returned?",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3087":{
      "id": "X3087",
      "num": 1,
      "type":2,
      "question": "What is the monthly volume (# of mail pieces) that are Returned Mail?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3088":{
      "id": "X3088",
      "num": 1,
      "type":2,
      "question": "The average postage cost (per piece) for returned mail is estimated at...",
      "caption": "$",
      "dataFormat":"$",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3089":{
      "id": "X3089",
      "num": 1,
      "type":2,
      "question": "The average print/material costs (per piece) for the envelope and contents for each piece of returned mail is estimated at (include cost of envelope and all contents including inserts)...",
      "caption": "$",
      "dataFormat":"$",
      "precision": 2,
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3090":{
      "id": "X3090",
      "num": 1,
      "type":2,
      "question": "The administrative time for processing returned mail on a monthly basis is estimated at...",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3090A":{
      "id": "X3090A",
      "num": 19,
      "type":2,
      "question": "What % of returned mail is reprinted?",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3090B":{
      "id": "X3090B",
      "num": 19,
      "type":2,
      "question": "What % of returned mail is resent to recipients",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3090C":{
      "id": "X3090C",
      "num": 1,
      "type":2,
      "question": "How many times do you need to resend mail in order to reach recipients?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3090D":{
      "id": "X3090D",
      "num": 19,
      "type":2,
      "question": "What is the estimated % of returned mail could be reduced by cleansing mailing lists",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3090E":{
      "id": "X3090E",
      "num": 1,
      "type":2,
      "question": "The administrative time to update the CRM to fix bad addresses on a monthly basis?",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X3091":{
      "id": "X3091",
      "num": 23,
      "type":8,
      "question": "Returned mail is processed within the operation by:",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["returning to the lines of business/customer for processing",	"entering the returned mail information into a tracking system and reporting to originator","reviewing the address in the originating system and updating to ensure addresses are correct for the next mailing","secure destruction","Other"]
    },






    "V1009": {
      "id": "V1009",
      "num": 60,
      "question": "How many Minutes does it take to prepare files for Print Production:",
      "type": 7,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "defaults": []
    },
    "V1010": {
      "id": "V1010",
      "num": 1,
      "question": "How many minutes does it take to Pre-Flight a file (Review Content, Add Bleeds, Make Minor Changes)",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current", "Automation"],
      "defaults": [0,0],
      "calc":{},
      "calcVars":{}

    },
    "V1011": {
      "id": "V1011",
      "num": 1,
      "question": "How many minutes does it take to Impose a file",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current", "Automation"],
      "defaults": [0,0],
      "calc":{},
      "calcVars":{}

    },
    "V1012": {
      "id": "V1012",
      "num": 1,
      "question": "Personalize/Variable Data File Development",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": [],
      "defaults": [0],
      "calc":{},
      "calcVars":{}

    },
    "V1013": {
      "id": "V1013",
      "num": 60,
      "question": "Provide details for Variable Data/Personalization Projects:",
      "type": 7,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "defaults": []

    },
    "V1000": {
      "id": "V1000",
      "num": 1,
      "question": "What Types of Jobs Contain Variable Data/Personalization?",
      "type": 2,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": ["Business Cards"],
      "calc":{},
      "calcVars":{}

    },
    "V1001": {
      "id": "V1001",
      "num": 1,
      "question": "Number of Orders per Month for Variable Data Project",
      "type": 2,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "defaults": [],
      "calc":{},
      "calcVars":{}

    },
    "V1002": {
      "id": "V1002",
      "num": 1,
      "question": "Minutes to Develop/Design Base Template",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current","VDP"],
      "defaults": [30,30],
      "calc":{},
      "calcVars":{}

    },
    "V1003": {
      "id": "V1003",
      "num": 1,
      "question": "Minutes to Create a Job Ticket/Work Order for VDP Project",
      "type": 4,
      "hint": "Current value is captured from Job Ticket Minutes for Manual Order with Minimal Job Specs (I1065)",
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current","VDP"],
      "defaults": [0,5],
      "calc":{"Current":"$a"},
      "calcVars":{"a":"I1065.Current"},
      "enabled":[false,true]

    },
    "V1004": {
      "id": "V1004",
      "num": 1,
      "question": "Minutes to Customize Document with Variable Data",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current","VDP"],
      "defaults": [0,2],
      "calc":{},
      "calcVars":{}

    },
    "V1005": {
      "id": "V1005",
      "num": 1,
      "question": "Minutes Required to Send a Proof and Make Any Required Changes",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current","VDP"],
      "defaults": [0,2],
      "calc":{},
      "calcVars":{}

    },
    "V1006": {
      "id": "V1006",
      "num": 1,
      "question": "Minutes to Process File for Production once Approved",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current","VDP"],
      "defaults": [0,5],
      "calc":{},
      "calcVars":{}

    },
    "V1007": {
      "id": "V1007",
      "num": 1,
      "question": "Average Number of Reworks for VDP Projects per Month",
      "type": 2,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "defaults": [],
      "calc":{},
      "calcVars":{}

    },
    "V1008": {
      "id": "V1008",
      "num": 1,
      "question": "Average Cost per VDP Project",
      "type": 2,
      "hint": null,
      "caption": "",
      "dataFormat":"$",
      "cols": [],
      "defaults": [],
      "calc":{},
      "calcVars":{}

    }

  },

  "survey":{
    "S001":{
      "id": "S001",
      "num": 1,
      "type":1,
      "weight": 6.25,
      "question": "Mail Piece Design",
      "caption": "The USPS provides a certification as a MDP (mail piece design professional) that provides the education and information to produce mailing pieces that comply with postal requirements. Having this certification ensures that a subject matter expert is accessible to produce and advise in the development of the proper materials. Having a web portal automates and speeds the process of mail piece design and development.",
      "criteria5": "There is an automated solution in place for the design and development of mailing pieces. At least 1 staff member is certified by the USPS as a mail design professional (MDP) as it relates to addressing accuracy and USPS mail piece design standards. Approvals are sent to customers through a web portal. ",
      "criteria4": "There is an automated solution in place for the design and development of mailing pieces. Approvals are sent to customers through a web portal.",
      "criteria3": "There is a homegrown solution/order form used to initiate mail piece designs. Proofs are emailed and manual edits are made based on customer feedback. Approvals are received via email.",
      "criteria2": "Meetings are held with customers to initiate the development of a mail piece design. It may take several meetings until all changes and approvals are finalized.",
      "criteria1": "Requirements are emailed.  It may take several emails until all changes are finalized. Proof approval is not required.",
      "criteriaNA": "No design services are offered.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S002":{
      "id": "S002",
      "num": 2,
      "type":1,
      "weight": 6.25,
      "question": "Postage Cost",
      "caption": "Pre-sorting mail reduces postage cost based on zip code classifications - 5-Digit, AADC, Mixed AADC, 3-Digit, and Mixed ADC. Evaluate if the a customer pre-sorts within their operation or has a pre-sort bureau to perform the pre-sorting function. Post Presort software tool (i.e., WindowBook) provides solutions to reduce postage costs and provides best practices to become certified as an Intelligent Mail® Full-Service provider. Co-mingling and co-palletizing lowers postage costs as well. Using iMb barcodes expedite and allow for the tracking of mail. Householding combines multiple mail pieces for an address in to one mailing/envelope and reduces postal processing and postage costs.",
      "criteria5": "All mail is pre-sorted and post pre-sorted using leading industry software before submitting to the post office. Mailings are co-mingled or co-palletized. IMb barcodes are applied to expedite and track mail, and receive postal discounts. Multiple mail pieces are consolidated into one envelope and sent to the same household. ",
      "criteria4": "All mail is pre-sorted by the operation before submitting to the post office. Mailings are co-palletized. IMb barcodes are applied to expedite and track mail, and receive postal discounts. Multiple mail pieces are consolidated into one envelope and sent to the same household. ",
      "criteria3": "All mail is pre-sorted by the operation before submitting to the post office. IMb barcodes are used to expedite and track mail, and receive postal discounts. Partnering with a pre-sort mail house for co-mingling.",
      "criteria2": "All mail is pre-sorted by the operation and submitted as 5-digit pieces to the post office. A pre-sort vendor handles smaller jobs and non-qualified pieces",
      "criteria1": "Third party provides pre-sort services or mail is not pre-sorted.",
      "criteriaNA": "Pre-sorting has no benefits and is not required.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S003":{
      "id": "S003",
      "num": 3,
      "type":1,
      "weight": 6.25,
      "question": "Mailing List Quality",
      "caption": "Postage delivery and reduction in the costs associated with returned mail can be impacted when address quality is reviewed and updated for accuracy. Mail optimization software that reviews a list for NCOA (national change of address), DPV (delivery point verification), CAAS (coding accuracy support system), PAVE (pre-sort accuracy, verification and evaluation), removing duplicates and correcting bad addresses. Advanced systems review mailing lists for Death, Incarceration and . PCOA (proprietary/USPS has not been notified of change of address). Providing this data back to the mailing list owner can result in the customer's mailing list being updated and providing more successful delivery of mail.",
      "criteria5": "Operation uses software for data hygiene (CASS, NCOA, PCOA, DPV, Death). The system is integrated with the operation's current systems. The operation processes mailing lists and performs comprehensive address management including correcting bad addresses, and removing duplicate addresses. Data is provided back to the mailing list owner for correcting/updating their mailing lists.",
      "criteria4": "Operation uses software for data hygiene (CASS, NCOA, PCOA, DPV). The system is integrated with the operation's current systems. The operation processes mailing lists and performs comprehensive address management including correcting bad addresses, and removing duplicate addresses. Data is provided back to the mailing list owner  for correcting/updating their mailing lists.",
      "criteria3": "Operation uses software for data hygiene (CASS, NCOA, PCOA).  The operation processes mailing lists and performs comprehensive address management including correcting bad addresses, and removing duplicate addresses.",
      "criteria2": "Third party provides address correction and DPV services.",
      "criteria1": "No address hygiene is performed.",
      "criteriaNA": "It is not necessary to provide address hygiene services.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S004":{
      "id": "S004",
      "num": 4,
      "type":1,
      "weight": 6.25,
      "question": "Addressing",
      "caption": "If all addressing is on the main mail piece and is displayed in a window envelope, there is no additional cost of inkjetting/addressing envelopes or mailing pieces. In addition, the cost of envelopes can be reduced by using standardized blank envelopes where all of the addressing and compay return address information is displayed in an envelopes' window.",
      "criteria5": "There is no addressing post printing. For letters, #10 window envelopes are used where both the return address and recipient's address are in the window. There are no custom printed envelopes used that are imprinted with a return address.",
      "criteria4": "There is no addressing post printing. For letters, #10 window envelopes are used where both the return address and recipient's address are in the window. There are more than 5 custom printed envelopes used that are imprinted with a return address.",
      "criteria3": "There is no addressing post printing. For letters, #10 window envelopes are used where both the return address and recipient's address are in the window. There are more than 10 custom printed envelopes used that are imprinted with a return address.",
      "criteria2": "Addresses are applied post printing of materials with inkjet addressing equipment.",
      "criteria1": "Some mailings are processed with mailing addresses printed on adhesive labels and applied to envelopes.",
      "criteriaNA": "There is no addressing for mailpieces.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S005":{
      "id": "S005",
      "num": 5,
      "type":1,
      "weight": 6.25,
      "question": "Auditing and Tracking",
      "caption": "Evaluate if a customer can track mailpieces through the postal processes, validating that the correct inserts were in a recipient's mailing." +
          "<br><br>" +
          "Regulatory compliance is about evaluating how capable a customer is to validate and prove that a mailpiece was processed and reached its destination or they could be subject to fines." +
          "<br><br>" +
          "Evaluate a company’s ability to prove compliance or disputes by being able to prove who, what, and when a communication was distributed.  This applies for industries that can be negatively impacted by fines associated with regulations or proof is needed that communications were delivered for auditing purposes and comply with PI (Personal Informaton), HIPAA (Health Insurance Portability and Accountability Act) and CCPA (California Consumer Privacy Act). A good example of this is check applications or Explanation of Benefits in the Healthcare industry.",
      "criteria5": "Electronic verification and file based insertion of mail insertion pieces. Bar codes are printed on each mail piece and scanned during the insertion process to ensure the correct inserts are in a recipient's envelope. Systems and procedures are in place to guarantee customer information is safe and compliant during the mail preparation process. Mail delivery is tracked and the customer is informed of recipient receipt data. The tracking data is exported to a mail management system. Data is secure, correct, current and compliant to specific industry rules and USPS Standards. If the operations mailing consistently contain PI, HIPAA and CCPA, they are operating in compliance with SOC 2 Type 2 and HITRUST controls. ",
      "criteria4": "Electronic verification of mail insertion pieces. Bar codes are printed on each mail piece and scanned during the insertion process to ensure the correct inserts are in a recipient's envelope. Systems and procedures are in place to guarantee customer information is safe and compliant during the mail preparation process. Mail delivery is tracked.",
      "criteria3": "Electronic verification of mail insertion pieces. Bar codes are printed on each mail piece and scanned during the insertion process to ensure the correct inserts are in a recipient's envelope. There are procedures in place to ensure secure processing of customer's data.",
      "criteria2": "Electronic verification of mail insertion pieces. Bar codes are printed on each mail piece and scanned during the insertion process to ensure the correct inserts are in a recipient's envelope. There is no way to ensure that a customer's data is safe and secure. ",
      "criteria1": "Electronic verification of mail insertion pieces. Bar codes are printed on each mail piece and scanned during the insertion process to ensure the correct inserts are in a recipient's envelope. There have been data breaches due to not maintaining security of customer's data.",
      "criteriaNA": "No audit or tracking is necessary.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S006":{
      "id": "S006",
      "num": 6,
      "type":1,
      "weight": 6.25,
      "question": "Return Mail Processing",
      "caption": "Review if the operations is maintaining high quality mailing lists by removing bad addresses and updating any address changes and/or establishing a regular process for database updates that will either prevent the mailing to bad addresses or provide address updates as provided by the USPS. These updates should include:  Change of Addresses, Undeliverable Addresses and Merge/Purge Processing.",
      "criteria5": "Return mail is analyzed and data returned to customer in an effort to reduce returned mail. There are improvement plans in place with the goal of reducing returned mail by more than 75%. Returned mail is reprocessed with corrections in order to reach the recipients.  Returned mail is less than 1% of total mailings processed.",
      "criteria4": "Return mail is analyzed and data returned to customer in an effort to reduce returned mail. There are improvement plans in place with the goal of reducing returned mail by more than 50%. Returned mail is reprocessed with corrections in order to reach the recipients.  Returned mail is 1 to 2% of total mailings processed.",
      "criteria3": "Return mail is analyzed and data returned to customer in an effort to reduce returned mail. There are improvement plans in place with the goal of reducing returned mail by more than 35%. Returned mail is reprocessed with corrections in order to reach the recipients.  Returned mail is 3 to 4% of total mailings processed.",
      "criteria2": "Return mail is analyzed and data returned to customer in an effort to reduce returned mail. There are improvement plans in place with the goal of reducing returned mail by more than 25%. Returned mail is reprocessed with corrections in order to reach the recipients.  Returned mail is more than 5% of total mailings processed.",
      "criteria1": "Return mail is analyzed and data returned to customer in an effort to reduce returned mail. There are no improvement plans in place to reduce the amount of returned mail.",
      "criteriaNA": "Return mail processing is not required or processed at this operation.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S007":{
      "id": "S007",
      "num": 7,
      "type":1,
      "weight": 6.25,
      "question": "Operations Productivity",
      "caption": "Is an electronic tracking system (and scanners) utilized to automate the manual task of scheduling and data collection for employee and job data. These systems can provide comprehensive reporting for consistent visbility of project status and delivery details -  employee process hours, postage, permit and meter spend, UPS and FedEx spend reports, etc.",
      "criteria5": "Billable hours are 95% of mail production employee's time. Jobs are scheduled so that there is no employee wait time for projects. Employee productivity is reviewed and improvement plans are in place to increase employee productivity. There is visibility of mail piece and its status from creation to delivery.",
      "criteria4": "Billable hours are 90% of mail production employee's time. Jobs are scheduled so that there is no employee wait time for projects. Employee productivity is reviewed and improvement plans are in place to increase employee productivity.",
      "criteria3": "Billable hours are 85% of mail production employee's time. Jobs are scheduled so that there is no employee wait time for projects. Employee productivity is reviewed and improvement plans are in place to increase employee productivity.",
      "criteria2": "Billable hours are more than 75% of mail production employee's time. Jobs are scheduled so that there is no employee wait time for projects. ",
      "criteria1": "Employee productivity, billable hours and equipment utilization are not reviewed.",
      "criteriaNA": "",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S008":{
      "id": "S008",
      "num": 8,
      "type":1,
      "weight": 6.25,
      "question": "Asset Management",
      "caption": "Are daily/monthly printer utilization reports developed to monitor trends by shift as well as by jobs produced to allow for job and operator efficiency and to assist with capacity planning.",
      "criteria5": "Equipment utilization is monitored to ensure the equipment is right sized for the volumes processed. Integrated system generates a profitability report for each job. Custom profitability reports can be generated based on job characteristics.",
      "criteria4": "Equipment utilization is monitored to ensure the equipment is right sized for the volumes processed. Standalone system generates labor report for each job and day / week / month end. ",
      "criteria3": "Equipment is not utilized to its capacity. Profitability report created by cutting / pasting labor spreadsheets and then making calculations.",
      "criteria2": "Equipment is not utilized to its capacity. Profitability report created by keying in hand written labor tracking and then making calculations.",
      "criteria1": "Equipment is not utilized to its capacity. There is no ability to create profitability reports. The operation does not monitor equipment utilization or track labor.",
      "criteriaNA": "",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S009":{
      "id": "S009",
      "num": 9,
      "type":1,
      "weight": 6.25,
      "question": "Process Automation",
      "caption": "Review the software and technology in place to take advantage of automation and incorporating intelligence/ability to monitor and manage job integrity and project status.",
      "criteria5": "Dedicated mail room staff is located in a centralized location, using mailing system hardware (folder/inserter) with intelligence capabilities and document management software (i.e. Planet Press, Ricoh Process Director) to automate mail preparation and monitor production integrity from job intake through completion utilizing bar codes to monitor processing. Cameras are used to validate and verify mail pieces. The equipment has the features needed to process all of the mailing/envelope types processed.",
      "criteria4": "Dedicated mail room staff is located in a centralized location, using mailing system hardware (folder/inserter) with intelligence capabilities and document management software (i.e. Planet Press, Ricoh Process Director) to automate mail preparation from job intake through completion. Some equipment needs to be upgraded to handle the mailing/envelope types processed.",
      "criteria3": "Dedicated mail room staff is located in a centralized location, using outdated mailing system hardware (folder/inserter not capable of insert verification) and document management software (i.e. Planet Press, Ricoh Process Director) to automate the processing of files. Some equipment needs to be upgraded to handle the mailing/envelope types processed.",
      "criteria2": "Employees are manually inserting or sorting more than 25% of projects. Inserting equipment is aged or cannot handle the types of work processed.",
      "criteria1": "Employees are manually inserting or sorting more than 50% of projects.",
      "criteriaNA": "",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S010":{
      "id": "S010",
      "num": 10,
      "type":1,
      "weight": 6.25,
      "question": "Workflow Integration",
      "caption": "Evaluate how a company integrates data sources to drive efficiency.  Siloed or disparate information can be a barrier that causes increased turnaround time, reduced quality and negatively impacts customer experience. Evaluate how many data sources are necessary to support their operation and where there are gaps integrating the data.",
      "criteria5": "All workflow is processed through a fully automated solution for the receipt of mail to the production operations and routing to specific printers based on job specifications. Jobs are batched based on similarities of production specs. ",
      "criteria4": "There are 1-2 systems disconnected that require manual intervention for processing.",
      "criteria3": "There are 3-4 systems disconnected that require manual intervention for processing.",
      "criteria2": "There are 5-6 systems disconnected that require manual intervention for processing.",
      "criteria1": "No system connectivity or workflow integration.",
      "criteriaNA": "There are not systems to integrate.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S011":{
      "id": "S011",
      "num": 11,
      "type":1,
      "weight":6.25,
      "question": "Innovation",
      "caption": "The USPS provides postage discount incentives throughout the year for different types of projects that incorporate innovation into mail pieces. Taking advantage of these promotions demonstrates that an operation is providing services that are cutting edge. Personalization is becoming mainstream and providing services that access customer profiles and buying habits requires advanced software and skills to process. Batching and consolidating mailings demonstrates savvy and the use of leading edge software to incorporate innovation and efficiency.",
      "criteria5": "The operation is processing mailings and taking advantage of several USPS promotions to leverage current programs and maximize postal savings. Over 25% of projects incorporate customization/ personalization based on customer profile or buying habits. The operation utilizes a software solution to consolidate/batch/merge mailings to increase postal density.",
      "criteria4": "The operation is processing mailings and taking advantage of a few USPS promotions to leverage current programs and maximize postal savings. Over 10% of projects incorporate customization/ personalization based on customer profile. The operation utilizes a software solution to consolidate/batch/merge mailings to increase postal density.",
      "criteria3": "The operation is processing mailings and taking advantage of a few USPS promotions to leverage current programs and maximize postal savings. A few projects per month incorporate customization/ personalization of mailing is offered.",
      "criteria2": "The operation is processing mailings and taking advantage of a few USPS promotions to leverage current programs and maximize postal savings. A few projects per year incorporate customization/ personalization of mailing is offered.",
      "criteria1": "No USPS promotions are utilized and no personalization is offered.",
      "criteriaNA": "USPS promotions or personalization does not apply to this operations type of work.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S012":{
      "id": "S012",
      "num": 12,
      "type":1,
      "weight": 6.25,
      "question": "Quality Control",
      "caption": "Having a software solution imprinting bar codes and validating inserts ensures quality. Having mailing piece counts/reports and confirming entry into the USPS mailing system are essential for validating and tracking status.",
      "criteria5": "A software solution scans imprinted bar codes on the inserting system to validate the correct number of pages and inserts/contents are included in a recipient's mailing. Mail piece counts and mailing recipient materials are validated through a software solution and mailings can be validated and tracked for status. ",
      "criteria4": "A software solution scans imprinted bar codes on the inserting system to validate the correct number of pages and inserts/contents are included in a recipient's mailing. Mail piece counts and mailing recipient materials are validated through a software solution. ",
      "criteria3": "A software solution scans imprinted bar codes on the inserting system to validate the correct number of pages and inserts/contents are included in a recipient's mailing.",
      "criteria2": "Bar codes are not printed on mailing pieces in order to validate the correct number of pages and inserts/ contents are included in a recipient's mailing.",
      "criteria1": "Some mailings are hand processed with no opportunities for mail automation and USPS postal incentives",
      "criteriaNA": "No Q/C processes are needed.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S013":{
      "id": "S013",
      "num": 13,
      "type":1,
      "weight": 6.25,
      "question": "Reporting",
      "caption": "You can't manage or improve what you don’t monitor and having reports auto generated saves a significant amount of administrative time that can be better spent interacting with customers. The USPS Mailer Scorecard provides a dashboard for letter and flat mailing activity. It displays results of mail quality verifications across key initiatives including Full-Service (discounts claims are valid), Move Update (addresses updated within 95 days of adress change), eInduction (containers delivered to proper facility), and Seamless Acceptance (proper mail preparation for discounts claimed and postage paid.",
      "criteria5": "Reports are generated monthly through a software solution to monitor the productivity of employees, equipment output and job data. The USPS Mailer Scorecard is received and reviewed monthly with employees. Improvement plans have been initiated to improve mail scorecard data.",
      "criteria4": "Reports are generated quarterly to monitor the productivity of employees, equipment output and job data. The USPS Mailer Scorecard is received and reviewed quarterly with employees. Improvement plans have been initiated to improve mail scorecard data.",
      "criteria3": "Reports are generated yearly to monitor the productivity of employees, equipment output and job data. The USPS Mailer Scorecard is received and reviewed yearly with employees. ",
      "criteria2": "Reports are generated yearly to monitor the productivity of employees, equipment output and job data. ",
      "criteria1": "Reports are generated manually to monitor equipment output and job data. Or, there are not reports produced to monitor productivity or operation statistics.",
      "criteriaNA": "",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S014":{
      "id": "S014",
      "num": 14,
      "type":1,
      "weight": 6.25,
      "question": "Invoicing",
      "caption": "Secure payment systems streamline invoice processing. Having an invoicing process that is integrated with a company's ERP/financial accounting system saves time and creates effiency for an organization.",
      "criteria5": "All invoicing is integrated with the operations storefront or ERP system and sent to customers at the close of the billing cycle. Invoicing is processed through a secure payment system.",
      "criteria4": "All invoicing is integrated with the operations storefront or ERP system and sent to customers at the close of the billing cycle.",
      "criteria3": "A manual report is exported from one system and imported to another to generate invoices.",
      "criteria2": "Invoices are re-keyed from one system to another.",
      "criteria1": "Invoices are created by keying information from a paper job ticket or invoicing is outsourced.",
      "criteriaNA": "Customers are not invoiced for work",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S015":{
      "id": "S015",
      "num": 15,
      "type":1,
      "weight": 6.25,
      "question": "Customer Service",
      "caption": "Notifications to and the preference for the types of communications are essential to customer satisfaction. Being pro-active reduces the amount of customer inquiries, saving time for the administrative areas of an operation.",
      "criteria5": "Customers are informed of job status through the production process (order received, in process, and estimated shipping/mailing date) in an automated solution. Customers can choose how to receive communications (Email, text, fax or print) and indicate their preference through a software interface.",
      "criteria4": "Customers are informed of job status through the production process (order received and estimated shipping/mailing date) in an automated solution. Customers can choose how to receive communications (Email, text, fax or print) and indicate their preference through a software interface.",
      "criteria3": "Customers are informed of job status through the production process (order received) in an automated solution. Customers can choose how to receive communications (Email, text, fax or print) and indicate their preference through a software interface.",
      "criteria2": "Customers are informed of job status through the production process (order received).",
      "criteria1": "Communications are late and or returned due to poor address quality. SLAs are frequently missed . ",
      "criteriaNA": "",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S016":{
      "id": "S016",
      "num": 16,
      "type":1,
      "weight": 6.25,
      "question": "Disaster Recovery",
      "caption": "Does the customer have a Disaster Recovery Plan for files/data?  Are they prepared for a DR event?  Is it a manual process to enable DR or is it an automated process? This is an opportunity to evaluate the following:<br><br> <ul><li> •  Are there redundant production environments?</li><li> •  Do they have offsite DR?</li><li> •  Where do they backup their printing?</li><li> •  Can the Business Continuity Center offer print and mail DR?</li></ul>",
      "criteria5": "Disaster recovery and business continuity plan are defined and tested regularly. Vulnerability management annual penetration and web application vulnerability testing and annual disaster recovery and business continuity and simulation testing are in place. ",
      "criteria4": "Disaster recovery and business continuity plan are defined and tested regularly. ",
      "criteria3": "Disaster recovery program is in place but annual testing is not performed.",
      "criteria2": "The organization partners with a provider that can process their mail requirements in the event of downtime.",
      "criteria1": "No disaster recovery or business continuity plans are in place.",
      "criteriaNA": "No requirements for distribution of mailings in the event of a disaster.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    }
  }
}















