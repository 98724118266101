


<template>
<div class="pre-customer-profile">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold">{{ options.instructions }}</p>
  </div>
  <div>
    <h2 class="font-bold text-xl pb-2 border-b-2 pl-4">{{ options.label }}</h2>
  </div>
  <div class="" v-if="costs">

    <table class="w-full bg-white border-r border-l text-sm">
      <thead>
        <tr class="">
          <th class="text-left px-4 align-bottom border-r bg-gray-100" colspan="2">Postage Promotions</th>

        </tr>
      </thead>
      <tbody>

      <tr>
        <td>What volume of mail could qualify for postal promotions within a year?</td>
        <td>{{ costs.annualPromoVolume | decimal(0) }}</td>
      </tr>
      <tr class="font-bold">
        <td colspan="2">Postage cost for each piece that qualifies for a postal promotion:</td>
      </tr>
      <tr v-for="rate in rates" :key="rate.key">
        <td>{{ rate.label }}</td>
        <td>${{ costs.rates[rate.key] | decimal(3) }}</td>
      </tr>
      <tr class="font-bold">
        <td>AVERAGE POSTAGE COST</td>
        <td>${{ costs.averagePostageRate | decimal(3) }}</td>
      </tr>
      <tr>
        <td>Discount for postal promotions that qualify?</td>
        <td>{{ costs.discountRate | decimal(2) }}%</td>

      </tr>
      <tr class="font-bold">
        <td>YEARLY COST SAVINGS FOR POSTAL PROMOTIONS</td>
        <td>${{ costs.yearlyCostSavings | decimal(0) }}</td>

      </tr>
      </tbody>

    </table>






    <div class="pt-4 border-t">
      <div class="pb-4 flex justify-between">
        <div class="pr-4">
          <button class="button button-green" @click="$emit('summary')">Save & Go to Summary</button>
        </div>
        <div class="pl-4">
          <button class="button button-blue button-next" @click="$emit('next')">Save & Next ROI <img class="inline-block ml-2 h-3" src="../assets/icons/next-arrow-icon.svg" /></button>
        </div>

      </div>
    </div>

  </div>
</div>
</template>

<script>
//import NumberInput from "@/components/NumberInput";
export default {
    name: "postage-roi-table",
  components: {},
  props: {
      options: {
        default: function(){
          return {
            label: "Mail ROI",
            costsKey: "",
            instructions: "ROI instructions"
          }
        }
      },
    },
    data: function(){
        return {
          rates: [
            { label: "5-Digit Automation", key: "fiveDigit"},
            { label: "AADC", key: "AADC"},
            { label: "Mixed AADC", key: "MixedAADC"},
            { label: "3-Digit", key: "threeDigit"},
            { label: "Mixed ADC", key: "mixedADC"},
            { label: "Meter", key: "meter"},
            { label: "Stamp", key: "stamp"},

          ]
        }
        },
    methods: {
      onChange: function(){
        this.$emit("change");
      }
    },
    computed: {
      costs: function(){
        if(this.$store.state.workflowAssessment && this.$store.state.workflowAssessment.costs){
            return this.$store.state.workflowAssessment.costs.postage;
        }

        return null;
      }
    }
}
</script>

<style scoped>


.pre-customer-profile {

}

td {
  @apply text-right py-2 px-4 border bg-white w-16;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}

</style>