


<template>
<div class="assessment-details flex flex-col h-full relative">
  <div class="text-xs px-8 py-2 pt-4 bg-white">
    <router-link to="/">Home</router-link> /
    <router-link to="/assessments">Assessments</router-link> /
    <span v-if="assessment">{{ assessment.assessmentType }}</span>
  </div>
  <div class="px-8 pt-0 bg-white">
    <div class="flex justify-between">
      <h1 class="text-3xl mb-6 leading-none flex"><span class="mr-2">{{ title }}</span><button v-if="assessment" class="button ml-2 text-xs py-1 px-2 -mb-1" @click="changeUser"><span class="flex items-center"><img src="../assets/icons/settings-icon.svg" class="w-4 h-4 mr-2 opacity-75" /><span>Settings</span></span></button><button v-if="assessment" @click="saveAssessment" :disabled="!$store.state.hasUnsavedChanges" class="button text-xs py-1 px-2 ml-2 -mb-1" :class="$store.state.hasUnsavedChanges?'border-red-600 text-red-600 hover:bg-red-600 hover:text-white':''"><span v-if="$store.state.hasUnsavedChanges">Save Changes</span><span v-else>Saved</span></button></h1>
      <div>
        <button v-if="isAdmin" class="button ml-2 text-xs py-2 px-2 -mb-1" @click="exportSchema"><span class="flex items-center"><span>Question List</span></span></button>
      </div>
    </div>
      <tab-bar :tabs="tabs" :selection="currentTab" @change="setTab" v-if="assessment"></tab-bar>
  </div>
  <div class="flex-grow relative bg-gray-50" v-if="assessment">
    <component :is="currentTab.component" :assessment="assessment" @recalculate="onRecalculate" @tab="showTab" @autosave="saveAssessment" @questionnaire="showQuestionnaire"></component>
  </div>

  <div class="fixed w-full h-full top-0 right-0 bg-black bg-opacity-50 z-50" v-if="questionnairePanel || showUsers"></div>

  <transition name="slide">
    <div class="fixed w-full h-full max-w-xl top-0 right-0 shadow-lg z-50" v-if="questionnairePanel">

      <side-panel-questionnaire :questionnaire="questionnaire" class="side-panel absolute top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll"  @close="closeSidePanel" ></side-panel-questionnaire>
    </div>
  </transition>

  <transition name="slide">
    <div class="fixed w-full h-full max-w-xl top-0 right-0 shadow-lg z-50" v-if="showUsers">

      <side-panel-doc-settings class="side-panel absolute top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll"  :record="assessment" :api="api" :admin="isAdmin" @close="closeSidePanel"></side-panel-doc-settings>

    </div>
  </transition>

</div>

</template>

<script>
import TabBar from "@/components/TabBar";
import AssessmentSurveyPanel from "@/components/AssessmentSurveyPanel";
import AssessmentInterviewPanel from "@/components/AssessmentInterviewPanel";
import AssessmentSolutionCostsPanel from "@/components/AssessmentSolutionCostsPanel";
import MailSolutionCostsPanel from "@/components/MailSolutionCostsPanel";
import AssessmentSummaryPanel from "@/components/AssessmentSummaryPanel";
import AssessmentCcmSummaryPanel from "@/components/AssessmentCcmSummaryPanel";
import AssessmentMapSummaryPanel from "@/components/AssessmentMapSummaryPanel";
import AssessmentPmpSummaryPanel from "@/components/AssessmentPmpSummaryPanel";
import AssessmentMailSummaryPanel from "@/components/AssessmentMailSummaryPanel";
import SidePanelQuestionnaire from "@/components/SidePanelQuestionnaire";
//import SidePanelAssignUser from "@/components/SidePanelAssignUser";
import SidePanelDocSettings from "../components/SidePanelDocSettings";
import {utils, writeFileXLSX} from "xlsx";

//import AssessmentSurveyPanel from "@/components/AssessmentSurveyPanel";
//import AssessmentSurveyPanel from "@/components/AssessmentSurveyPanel";


export default {
  name: "assessment-details",
  components: {
    SidePanelDocSettings,
    SidePanelQuestionnaire,
    AssessmentCcmSummaryPanel,
    AssessmentMapSummaryPanel,
    AssessmentPmpSummaryPanel,
    AssessmentMailSummaryPanel,
    AssessmentSummaryPanel,
    AssessmentSolutionCostsPanel, MailSolutionCostsPanel, TabBar, AssessmentSurveyPanel, AssessmentInterviewPanel},
  mounted: function(){


      this.loadData();
  },

  props: {},
    data: function(){
        return {
          currentTabComponent: "AssessmentSurveyPanel",
          questionnairePanel: false,
          showUsers: false

        }
        },
    methods: {

      exportSchema: function(){



        let schema = this.$sdm.getAssessmentSchema()
        let ccmSchema = this.$sdm.getAssessmentSchema("CCM")
        let mailSchema = this.$sdm.getAssessmentSchema("Mail")
        let mapSchema = this.$sdm.getAssessmentSchema("MAP")
        let pmpSchema = this.$sdm.getAssessmentSchema("PMP")

        let questions = Object.keys(schema.questions).map((id)=>{
          let q = schema.questions[id];
          return [
              q.id,
              q.question,
              q.type,
              q.hint,
            q.caption,
              q.dataFormat
          ]
        })

        let CCMquestions = Object.keys(ccmSchema.questions).map((id)=>{
          let q = ccmSchema.questions[id];
          return [
            q.id,
            q.question,
            q.type,
            q.hint,
            q.caption,
            q.dataFormat
          ]
        })

        let Mailquestions = Object.keys(mailSchema.questions).map((id)=>{
          let q = mailSchema.questions[id];
          return [
            q.id,
            q.question,
            q.type,
            q.hint,
            q.caption,
            q.dataFormat
          ]
        })

        let MAPquestions = Object.keys(mapSchema.questions).map((id)=>{
          let q = mapSchema.questions[id];
          return [
            q.id,
            q.question,
            q.type,
            q.hint,
            q.caption,
            q.dataFormat
          ]
        })

        let PMPquestions = Object.keys(pmpSchema.questions).map((id)=>{
          let q = pmpSchema.questions[id];
          return [
            q.id,
            q.question,
            q.type,
            q.hint,
            q.caption,
            q.dataFormat
          ]
        })

        questions.unshift([
            "ID",
            "Question",
            "Type",
            "Hint",
            "Caption",
            "Format"
        ])

        CCMquestions.unshift([
          "ID",
          "Question",
          "Type",
          "Hint",
          "Caption",
          "Format"
        ])

        Mailquestions.unshift([
          "ID",
          "Question",
          "Type",
          "Hint",
          "Caption",
          "Format"
        ])

        MAPquestions.unshift([
          "ID",
          "Question",
          "Type",
          "Hint",
          "Caption",
          "Format"
        ])

        PMPquestions.unshift([
          "ID",
          "Question",
          "Type",
          "Hint",
          "Caption",
          "Format"
        ])





        let workbook = utils.book_new();
        if(!workbook.Props) workbook.Props = {};
        workbook.Props.Title = "Workflow Assessment Question List";




        console.log('data',questions);
        let listing = utils.aoa_to_sheet(questions)
        let ccmlisting = utils.aoa_to_sheet(CCMquestions)
        let maillisting = utils.aoa_to_sheet(Mailquestions)
        let maplisting = utils.aoa_to_sheet(MAPquestions)
        let pmplisting = utils.aoa_to_sheet(PMPquestions)


        // summary['!cols'] = [
        //   { wpx: 200 }
        // ]
        //
        // details['!cols'] = [
        //   { wpx: 200 }
        // ]
        utils.book_append_sheet(workbook,listing,"Workflow Questions")
        utils.book_append_sheet(workbook,ccmlisting,"CCM Questions")
        utils.book_append_sheet(workbook,maillisting,"Mail Questions")
        utils.book_append_sheet(workbook,maplisting,"MAP Questions")
        utils.book_append_sheet(workbook,pmplisting,"PMP Questions")
        console.log("writing file...")

        writeFileXLSX(workbook, 'Assessment Questions.xlsx');

        console.log("export complete")
      },

      changeUser: function(){
        this.showUsers = true;
      },

      showQuestionnaire: function(){
        this.questionnairePanel = true;
      },

      closeSidePanel: function(){
        this.questionnairePanel = false;
        this.showUsers = false;
      },


      loadData: function(){
        //console.log("loading assessment", this.$route.params.assessment);
        this.$store.state.questionnaire = undefined;
        if(this.$route.params.assessment){
          this.$sdm.loadAssessment(this.$route.params.assessment).then((assessment) => {



            this.$store.commit("setAssessment", assessment);
            this.$sdm.calculate(this.assessment);

          }).catch((error) => {
            console.log("ERROR",error);
          })
        }
      },

      setTab: function(tab){
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: tab.id
          }
        })
        window.scrollTo(0,0);
      },

      showTab: function(tabId){
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: tabId
          }
        })
        window.scrollTo(0,0);
      },

      setSection: function(section){
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: this.currentTab.id,
            section: section.id
          }
        })
      },

      goHome: function(){
        this.$router.push({
          name: "Home"
        })
      },

      saveAssessment: function(){
        // this.$sdm.updateAssessment(this.assessment, this.assessment.id).then(() => {
        //   this.$store.state.hasUnsavedChanges = false;
        // }).catch((error) => {
        //   console.log("Error", error);
        // })
        this.$store.dispatch("autosave");
      },

      onRecalculate: function(){
        if(this.assessment){


          this.$sdm.calculate(this.assessment);
          this.$store.state.hasUnsavedChanges = true;
        }

      }
    },
    computed: {

    api: function(){
        return this.$sdm;
    },

    userLabel: function(){
      if(this.assessment){
        if(this.assessment.userProfile){
          return (this.assessment.userProfile.firstname +" "+ this.assessment.userProfile.lastname);
        }else{
          return "Assign User"
        }
      }
      return ""
    },

    isAdmin: function(){
      return this.$store.getters.hasAdminAccess;
    },

     questionnaire: function(){
       return this.$store.state.questionnaire;
     },

      mailingTabs: function(){
        return [
          { label: "Pre-Assessment", id: "pre", component: "AssessmentSurveyPanel", sections:[
              { label: "Customer Profile", id: "profile", component: "PreCustomerProfile"},
              { label: "Self Assessment", id: "selfassessment", component: "PreSelfAssessment"},
              { label: "Walkthrough Assessment", id: "walkthrough", component: "PreWalkthough"},
            ]},
          { label: "Assessment", id: "assessment", component: "AssessmentInterviewPanel", sections:[
              { label: "Organization Statistics", id: "orgstats", component: "AssessmentQuestions"},
              { label: "Production Volume", id: "volume", component: "AssessmentQuestions"},
              { label: "Mail Stats", id: "mailstats", component: "AssessmentQuestions"},
              { label: "Workflow Processes", id: "workflow", component: "AssessmentQuestions"},
              { label: "QC / Compliance", id: "qc", component: "AssessmentQuestions"},
              { label: "Finance / Postage", id: "finance", component: "AssessmentQuestions"},
              { label: "Returned Mail", id: "returned", component: "AssessmentQuestions"},
              { label: "Production Operations", id: "operations", component: "AssessmentQuestions"},
            ]},
          { label: "Solutions", id: "costs", component: "MailSolutionCostsPanel", sections:[
              { label: "MIS", id: "miscosts", component: "SolutionCosts"},
              { label: "Storefront", id: "storefrontcosts", component: "SolutionCosts"},
              { label: "VDP", id: "vdpcosts", component: "SolutionCosts"},
              { label: "VDP Details", id: "vdpdetails", component: "VdpDetails"},
            ]},
          { label: "Summary", id: "summary", component: "AssessmentMailSummaryPanel", sections:[
              { label: "Savings / Revenue Details", id: "savingsdetails", component: "SavingsDetails"},
              { label: "MIS Summary", id: "missummary", component: "MisSummary"},
              { label: "Storefront Summary", id: "storefrontsummary", component: "StorefrontSummay"},
              { label: "Prep/VDP Summary", id: "vdpsummary", component: "VdpSummary"},
              { label: "Photos", id: "photos", component: "PhotoGallery"},
              { label: "Export", id: "export", component: "ExportOptions"},
            ] },
        ]
      },

      ccmTabs: function(){
        return [
          { label: "Pre-Assessment", id: "pre", component: "AssessmentSurveyPanel", sections:[
              { label: "Customer Profile", id: "profile", component: "PreCustomerProfile"},
              { label: "Self Assessment", id: "selfassessment", component: "PreSelfAssessment"},
              { label: "Walkthrough Assessment", id: "walkthrough", component: "PreWalkthough"},
            ]},
          { label: "Assessment", id: "assessment", component: "AssessmentInterviewPanel", sections:[
              { label: "Organization Statistics", id: "orgstats", component: "AssessmentQuestions"},
              { label: "Estimating / Job Ticketing", id: "estimating", component: "AssessmentQuestions"},
              { label: "Accounting", id: "accounting", component: "AssessmentQuestions"},
              { label: "Production", id: "production", component: "AssessmentQuestions"},
              { label: "Inventory / Shipping", id: "inventory", component: "AssessmentQuestions"},
              { label: "Quality / Misc", id: "quality", component: "AssessmentQuestions"},
              { label: "Reports", id: "reports", component: "AssessmentQuestions"},
            ]},
          { label: "Summary", id: "summary", component: "AssessmentCcmSummaryPanel", sections:[
              { label: "Photos", id: "photos", component: "PhotoGallery"},
              { label: "Export", id: "export", component: "ExportOptions"},
            ] },
        ]
      },

      mapTabs: function(){
        return [
          { label: "Pre-Assessment", id: "pre", component: "AssessmentSurveyPanel", sections:[
              { label: "Customer Profile", id: "profile", component: "PreCustomerProfile"},
              { label: "Self Assessment", id: "selfassessment", component: "PreSelfAssessment"},
              { label: "Walkthrough Assessment", id: "walkthrough", component: "PreWalkthough"},
            ]},
          { label: "Assessment", id: "assessment", component: "AssessmentInterviewPanel", sections:[
              { label: "Organization Data", id: "org", component: "AssessmentQuestions"},
              { label: "Platform", id: "platform", component: "AssessmentQuestions"},
              { label: "Usage", id: "usage", component: "AssessmentQuestions"},
              { label: "Targeting / Engagement", id: "engagement", component: "AssessmentQuestions"},
              { label: "Workflow Automation", id: "automation", component: "AssessmentQuestions"},
              { label: "Reports", id: "reports", component: "AssessmentQuestions"},
            ]},
          { label: "Summary", id: "summary", component: "AssessmentMapSummaryPanel", sections:[
              { label: "Photos", id: "photos", component: "PhotoGallery"},
              { label: "Export", id: "export", component: "ExportOptions"},
            ] },
        ]
      },

      pmpTabs: function(){
        return [
          { label: "Pre-Assessment", id: "pre", component: "AssessmentSurveyPanel", sections:[
              { label: "Customer Profile", id: "profile", component: "PreCustomerProfile"},
              { label: "Self Assessment", id: "selfassessment", component: "PreSelfAssessment"},
              { label: "Walkthrough Assessment", id: "walkthrough", component: "PreWalkthough"},
            ]},
          { label: "Assessment", id: "assessment", component: "AssessmentInterviewPanel", sections:[
              { label: "Organization Data", id: "org", component: "AssessmentQuestions"},
              { label: "Platform", id: "platform", component: "AssessmentQuestions"},
              { label: "Usage", id: "usage", component: "AssessmentQuestions"},
              { label: "Targeting / Engagement", id: "engagement", component: "AssessmentQuestions"},
              { label: "Workflow Automation", id: "automation", component: "AssessmentQuestions"},
              { label: "Reports", id: "reports", component: "AssessmentQuestions"},
            ]},
          { label: "Summary", id: "summary", component: "AssessmentPmpSummaryPanel", sections:[
              { label: "Photos", id: "photos", component: "PhotoGallery"},
              { label: "Export", id: "export", component: "ExportOptions"},
            ] },
        ]
      },

      workflowTabs: function(){


        return [
        { label: "Pre-Assessment", id: "pre", component: "AssessmentSurveyPanel", sections:[
            { label: "Customer Profile", id: "profile", component: "PreCustomerProfile"},
            { label: "What To Look For", id: "prepinfo", component: "PrePrepInfo"},
            { label: "Self Assessment", id: "selfassessment", component: "PreSelfAssessment"},
            { label: "Walkthrough Assessment", id: "walkthrough", component: "PreWalkthough"},
          ]},
        { label: "Assessment", id: "assessment", component: "AssessmentInterviewPanel", sections:[
            { label: "Organization Statistics", id: "orgstats", component: "AssessmentQuestions"},
            { label: "Estimating / Job Ticketing / Prep", id: "estimating", component: "AssessmentQuestions"},
            { label: "Accounting", id: "accounting", component: "AssessmentQuestions"},
            { label: "Production", id: "production", component: "AssessmentQuestions"},
            { label: "Inventory / Shipping", id: "inventory", component: "AssessmentQuestions"},
            { label: "Quality / Misc", id: "quality", component: "AssessmentQuestions"},
            { label: "Reports", id: "reports", component: "AssessmentQuestions"},
          ]},
        { label: "Solutions", id: "costs", component: "AssessmentSolutionCostsPanel", sections:[
            { label: "MIS", id: "miscosts", component: "SolutionCosts"},
            { label: "Storefront", id: "storefrontcosts", component: "SolutionCosts"},
            { label: "VDP", id: "vdpcosts", component: "SolutionCosts"},
            { label: "VDP Details", id: "vdpdetails", component: "VdpDetails"},
          ]},
        { label: "Summary", id: "summary", component: "AssessmentSummaryPanel", sections:[
            { label: "Savings / Revenue Details", id: "savingsdetails", component: "SavingsDetails"},
            { label: "MIS Summary", id: "missummary", component: "MisSummary"},
            { label: "Storefront Summary", id: "storefrontsummary", component: "StorefrontSummay"},
            { label: "Prep/VDP Summary", id: "vdpsummary", component: "VdpSummary"},
            { label: "Photos", id: "photos", component: "PhotoGallery"},
            { label: "Export", id: "export", component: "ExportOptions"},
          ] },
      ]
      },



      tabs: function(){
        if(this.assessment && this.assessment.assessmentType == "CCM"){
          return this.ccmTabs;
        }

        if(this.assessment && this.assessment.assessmentType == "Mail"){
          return this.mailingTabs;
        }

        if(this.assessment && this.assessment.assessmentType == "MAP"){
          return this.mapTabs;
        }

        if(this.assessment && this.assessment.assessmentType == "PMP"){
          return this.pmpTabs;
        }

        return this.workflowTabs;
      },

      assessment: function(){
        return this.$store.state.workflowAssessment;
      },

      title: function(){
        if(this.assessment){
          return this.assessment.customerProfile.organizationName?this.assessment.customerProfile.organizationName:"Unnamed Organization";
        }else{
          return "Loading Record..."
        }
      },

      currentTab: function(){

        var tab = this.tabs.find((tab) => {
          return tab.id == this.$route.query.tab;
        })

        if(!tab){
          tab = this.tabs[0];
        }

        return tab;

      },
    }
}
</script>

<style scoped>


.assessment-details {

}

</style>
