


<template>
<div class="pre-customer-profile">
  <div>
    <h2 class="font-bold text-xl pb-2 border-b-2 pl-4">Prep/VDP Summary</h2>
  </div>
  <div class="">

    <div class="chart-container mb-4 mt-4">
      <roi-chart-vdp-comparison class="absolute w-full h-full" :assessment="assessment"></roi-chart-vdp-comparison>
    </div>



    <roi-table-vdp-details :assessment="assessment"></roi-table-vdp-details>

    <roi-table-vdp v-if="costs" :assessment="assessment"></roi-table-vdp>



  </div>
</div>
</template>

<script>


import RoiTableVdp from "@/components/ROITableVDP";
import RoiTableVdpDetails from "@/components/ROITableVDPDetails";
import RoiChartVdpComparison from "@/components/ROIChartVDPComparison";
export default {
    name: "summary-vdp-tables",
  components: {RoiChartVdpComparison, RoiTableVdpDetails, RoiTableVdp},
  mounted: function() {
      if(this.assessment){
        this.$sdm.calculate(this.assessment);
      }

  },
  props: {
      label: { required: false },
      instructions:  { required: false },
    },
    data: function(){
        return {
          orderCellOverrides: {
            "A:0": { colspan: 10 },
            "A:1": { colspan: 10 }
          }
        }
        },
    methods: {
      onChange: function(){
        this.$emit("change");
      },
      formattedValue: function(cell){
        if(cell.filter){
          if(cell.filter === "$"){
            console.log(cell);
            return this.$options.filters.currency(cell.value);
          }
        }
        return cell.value;
      }
    },
    computed: {

      assessment: function(){
        return this.$store.state.workflowAssessment
      },

      costs: function(){
        if(this.assessment){
          return this.assessment.costs.vdp;
        }
        return null;
      },


    }
}
</script>

<style scoped>


.pre-customer-profile {

}

.chart-container{
  height: 0;
  padding-bottom: 50%;
  position: relative;
}

td {
  @apply text-right py-2 px-4 border bg-white w-16;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}




td[data-null="true"]{

}



</style>