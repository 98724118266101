


<template>



  <table class="vdp-detail text-xs" data-cell-style="AppendixCell">
    <thead>
    <tr>
      <td colspan="2">Variable Data Order Type: <span class="font-bold">{{ costs.orderDetails.orderType }}</span></td>


    </tr>
    <tr>
      <td class="">Number of Orders per Month for Variable Data Project</td>
      <td>{{ costs.orderDetails.vdpOrdersMonthly | decimal(0) }}</td>
    </tr>
    <tr>
      <td class="">Hourly Rate for Design</td>
      <td>${{ costs.orderDetails.designRate | decimal(2) }}</td>
    </tr>
    <tr>
      <td class="">Hourly Rate for Admin (includes benefits)</td>
      <td>${{ costs.orderDetails.adminRateWBenefits | decimal(2) }}</td>
    </tr>

    </thead>
    <tbody>
    <tr>
      <th class="pt-8 text-left pl-4">Order Processing</th>
      <th class="pt-8">Current<br>Mins. per Order</th>
      <th class="pt-8">Current<br>Hrs. per Month</th>
      <th class="pt-8">Current<br>Cost per Month</th>
      <th class="pt-8">Online w/VDP<br>Mins. per Order</th>
      <th class="pt-8">Online w/VDP<br>Hrs. per Month</th>
      <th class="pt-8">Online w/VDP<br>Cost per Month</th>
      <th class="pt-8">Cost Savings<br>per Month</th>
      <th class="pt-8">Cost Savings<br>per Year</th>
      <th class="pt-8">Yearly Man Hour<br>Savings with VDP</th>
    </tr>
    <tr>
      <td>Time to Develop/Design Base Template</td>
      <td>{{ costs.orderDetails.baseTemplate.minPerOrder }}</td>
      <td></td>
      <td></td>
      <td>{{ costs.orderDetails.baseTemplate.vdpMinPerOrder }}</td>
      <td></td>
      <td></td>
      <td colspan="3" style="text-align: center;">Same Amount of time to Develop Template</td>
    </tr>
    <tr>
      <td>Time to Create a Job Ticket/Work Order for VDP Project</td>
      <td>{{ costs.orderDetails.jobTicketVDP.minPerOrder }}</td>
      <td>{{ costs.orderDetails.jobTicketVDP.hoursMonthly | decimal(0) }}</td>
      <td>${{ costs.orderDetails.jobTicketVDP.costMonthly | decimal(0) }}</td>
      <td>{{ costs.orderDetails.jobTicketVDP.vdpMinPerOrder | decimal(0) }}</td>
      <td>{{ costs.orderDetails.jobTicketVDP.vdpHoursMonthly | decimal(0)}}</td>
      <td>${{ costs.orderDetails.jobTicketVDP.vdpCostMonthly | decimal(0) }}</td>
      <td>${{ costs.orderDetails.jobTicketVDP.costSavingsMonthly | decimal(0) }}</td>
      <td>${{ costs.orderDetails.jobTicketVDP.costSavingsAnnual | decimal(0) }}</td>
      <td>{{ costs.orderDetails.jobTicketVDP.hourSavingsAnnual | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Time to Customize Document with Variable Data</td>
      <td>{{ costs.orderDetails.customization.minPerOrder }}</td>
      <td></td>
      <td></td>
      <td>{{ costs.orderDetails.customization.vdpMinPerOrder }}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Time Required to Send a Proof and Make Any Required Changes</td>
      <td>{{ costs.orderDetails.proofing.minPerOrder }}</td>
      <td></td>
      <td></td>
      <td>{{ costs.orderDetails.proofing.vdpMinPerOrder }}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Time to Process File for Production once Approved</td>
      <td>{{ costs.orderDetails.processing.minPerOrder }}</td>
      <td></td>
      <td></td>
      <td>{{ costs.orderDetails.processing.vdpMinPerOrder }}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Total Develop/Design Time per Order</td>
      <td>{{ costs.orderDetails.designOrder.minPerOrder }}</td>
      <td>{{ costs.orderDetails.designOrder.hoursMonthly | decimal(0) }}</td>
      <td>${{ costs.orderDetails.designOrder.costMonthly | decimal(0) }}</td>
      <td>{{ costs.orderDetails.designOrder.vdpMinPerOrder | decimal(0) }}</td>
      <td>{{ costs.orderDetails.designOrder.vdpHoursMonthly | decimal(0) }}</td>
      <td>${{ costs.orderDetails.designOrder.vdpCostMonthly | decimal(0) }}</td>
      <td>${{ costs.orderDetails.designOrder.costSavingsMonthly | decimal(0) }}</td>
      <td>${{ costs.orderDetails.designOrder.costSavingsAnnual | decimal(0) }}</td>
      <td>{{ costs.orderDetails.designOrder.hourSavingsAnnual | decimal(0) }}</td>
    </tr>
    <tr>
      <td>Total Time to Create Job Ticket and Customize each Order</td>
      <td>{{ costs.orderDetails.jobTicketOrder.minPerOrder }}</td>
      <td>{{ costs.orderDetails.jobTicketOrder.hoursMonthly | decimal(0) }}</td>
      <td>${{ costs.orderDetails.jobTicketOrder.costMonthly | decimal(0) }}</td>
      <td>{{ costs.orderDetails.jobTicketOrder.vdpMinPerOrder | decimal(0) }}</td>
      <td>{{ costs.orderDetails.jobTicketOrder.vdpHoursMonthly | decimal(0) }}</td>
      <td>${{ costs.orderDetails.jobTicketOrder.vdpCostMonthly | decimal(0) }}</td>
      <td>${{ costs.orderDetails.jobTicketOrder.costSavingsMonthly | decimal(0) }}</td>
      <td>${{ costs.orderDetails.jobTicketOrder.costSavingsAnnual | decimal(0) }}</td>
      <td>{{ costs.orderDetails.jobTicketOrder.hourSavingsAnnual | decimal(0) }}</td>
    </tr>
<!--    <tr>-->
<!--      <td>Average Number of Reworks for Project Type per Month</td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td>{{ costs.orderDetails.averageReworkMonthly | decimal(0) }}</td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--    </tr>-->
<!--    <tr>-->
<!--      <td>Average Cost per Project</td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td>${{ costs.orderDetails.rework.averageProjectCost | decimal(0) }}</td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--    </tr>-->
<!--    <tr>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td>Rework Cost per Month</td>-->
<!--      <td>${{ costs.orderDetails.rework.costMonthly | decimal(0) }}</td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td>Rework Cost Savings Per Year</td>-->
<!--      <td>${{ costs.orderDetails.rework.costSavingsAnnual | decimal(0) }}</td>-->
<!--      <td></td>-->
<!--    </tr>-->



    </tbody>
    <tfoot>
    <tr>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th class="text-right px-4">${{ costs.orderDetails.totalSavingsAnnual | decimal(0) }}</th>
      <th class="text-left px-4">TOTAL COST SAVINGS PER YEAR</th>
    </tr>
    </tfoot>

  </table>



</template>

<script>
export default {
    name: "roi-table-vdp-details",
    props: ['assessment'],
    data: function(){
        return {}
        },
    methods: {
      formattedValue: function(cell){
        if(cell.filter){
          if(cell.filter === "$"){
            console.log(cell);
            return this.$options.filters.currency(cell.value);
          }
        }
        return cell.value;
      }
    },
    computed: {
      costs: function(){
        if(this.assessment){
          return this.assessment.costs.vdp;
        }
        return null;
      },
    }
}
</script>

<style scoped>


.roitable-vdp {

}

td {
  @apply text-right py-2 px-4 border bg-white w-16;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}




td[data-null="true"]{

}

</style>