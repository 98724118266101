


<template>
<div class="pre-customer-profile">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold">{{ options.instructions }}</p>
  </div>
  <div>
    <h2 class="font-bold text-xl pb-2 border-b-2 pl-4">{{ options.label }}</h2>
  </div>
  <div class="" v-if="costs">

    <p class="p-4">This component is still under development.</p>






    <div class="pt-4 border-t">
      <div class="pb-4 flex justify-between">
        <div class="pr-4">
          <button class="button button-green" @click="$emit('summary')">Save & Go to Summary</button>
        </div>
        <div class="pl-4">
          <button class="button button-blue button-next" @click="$emit('next')">Save & Next ROI <img class="inline-block ml-2 h-3" src="../assets/icons/next-arrow-icon.svg" /></button>
        </div>

      </div>
    </div>

  </div>
</div>
</template>

<script>
//import NumberInput from "@/components/NumberInput";
export default {
    name: "mail-roi-table",
  components: {},
  props: {
      options: {
        default: function(){
          return {
            label: "Mail ROI",
            costsKey: "",
            instructions: "ROI instructions"
          }
        }
      }
    },
    data: function(){
        return {
          capitalRows: [
            { label: "Software", key:"software" },
            { label: "Hardware", key:"hardware" },
            { label: "Installation", key:"installation" },
          ],
          expensesRows: [
            { label: "Annual Lease Expense", key:"lease" },
            { label: "Maintenance", key:"maintenance" },
            { label: "Training", key:"training" },
          ],
          cols: [
              { label: "Initial Investment", key: "initial"},
            { label: "Year 1", key: "year1"},
            { label: "Year 2", key: "year2"},
            { label: "Year 3", key: "year3"},
            { label: "Year 4", key: "year4"},
            { label: "Year 5", key: "year5"},

          ]
        }
        },
    methods: {
      onChange: function(){
        this.$emit("change");
      }
    },
    computed: {
      costs: function(){
        if(this.$store.state.workflowAssessment && this.$store.state.workflowAssessment.costs){
            return this.$store.state.workflowAssessment.costs.returnMail;
        }

        return null;
      }
    }
}
</script>

<style scoped>


.pre-customer-profile {

}

td {
  @apply text-right py-2 px-4 border bg-white w-16;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}

</style>