<template>

    <div class="home flex flex-col h-full relative">
      <div class="text-xs px-8 py-2 pt-4 bg-white">
        <router-link to="/">Home</router-link> /
        <router-link to="/assessments">Assessments</router-link>

      </div>
      <div class="bg-white">

        <div class="flex justify-between w-full pt-0  pb-4 px-8">
          <h1 class="text-3xl leading-none">My Assessments</h1>
          <div class="flex -mt-2 gap-2">
            <button v-if="$store.getters.isAdmin" class="button button-white" @click="downloadReport">Download Report</button>
            <button class="button button-blue" @click="showNewAssessment"><img class="inline-block mr-2 h-3" src="../assets/icons/new-doc-icon.svg" />New Assesssment</button>
          </div>
        </div>


      </div>

      <div class="">
        <div class="border-t px-8 py-2 flex items-center bg-gray-50 relative">
          <div class="relative">
            <div class="inline-block relative">
              <input class="px-2 py-1 text-xs border rounded-full w-64 focus:outline-none focus:ring focus:border-blue-300 bg-white" type="text" placeholder="Filter by Org or User" v-model="searchText" />
              <button class="text-xs py-1 px-2 absolute right-0 rounded-full" v-if="searchText" @click="searchText = ''">&times;</button>
            </div>

            <button class="button text-xs py-1 px-2 ml-2 hidden">Submit</button>
          </div>

        </div>
      </div>

      <div class="border-t px-8 py-4 overflow-y-scroll">
        <table class="w-full">
          <thead>
          <tr class="text-left">
            <th class="md:w-1/4 pr-2 pl-4 hover:underline cursor-pointer" @click="setSort(3)">Organization</th>
            <th class="md:w-1/4 pr-2 hover:underline cursor-pointer" @click="setSort(4)">Type</th>
            <th class="px-2 hover:underline cursor-pointer" @click="setSort(0)">Last Updated</th>
            <th class="px-2 hover:underline cursor-pointer" @click="setSort(1)">User</th>
            <th class=""></th>

          </tr>
          </thead>
          <tbody>
          <tr class="hover:bg-blue-50" v-for="assessment in filteredAssessments" :key="assessment[2]">
            <td class="md:w-1/4 pr-2 pl-4"><router-link :to="getAssessmentUrl(assessment)" class="hover:underline">{{ assessment[3]?assessment[3]:"----" }}</router-link></td>
            <td class=" pr-2"><span>{{ assessment[4] }}</span></td>
            <td class="px-2"><span>{{ assessment[0] | formatTS }}</span></td>
            <td class="px-2"><span v-if="assessment[4]">{{ assessment[1] }}</span></td>
            <td class=""><img @click="deleteRecord(assessment)" class="h-4 opacity-50 hover:opacity-100 cursor-pointer" src="../assets/icons/delete-icon.svg" /></td>

          </tr>
          </tbody>

        </table>
        <p class="pt-4 ml-4" v-if="loading">Loading records...</p>
        <p class="pt-4 ml-4" v-if="assessments && !assessments.length">No records found.</p>
      </div>

      <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="sidePanel"></div>

      <transition name="slide">
        <div class="fixed w-full h-full max-w-xl top-0 right-0 shadow-lg" v-if="sidePanel">
          <side-panel-new-assessment class="side-panel absolute top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll" @close="closeSidePanel" @created="onAssessmentCreated"></side-panel-new-assessment>
        </div>
      </transition>


    </div>


</template>

<script>
// @ is an alias to /src


import SidePanelNewAssessment from "@/components/SidePanelNewAssessment";
import {utils, writeFileXLSX} from "xlsx";
export default {
  name: 'Assessments',
  components: {
    SidePanelNewAssessment

  },
  data: function(){
    return {
       sidePanel: false,
      loading: false,
      sortKey: 0,
      secondarySortKey: 0,
      sortDirection: 1,
      searchText: ""
    }
  },
  mounted: function(){


      //if(!this.$store.getters.assessments.length ){
        this.loadData();
      //}


  },
  methods: {

    setSort(key){

      if(key === this.sortKey){
        this.sortDirection = -this.sortDirection;
        return;
      }

      this.sortDirection = 1;
      this.sortKey = key;
    },

    fullname: function(profile){

      if(profile.lastname){
        if(profile.firstname){
          return (profile.firstname.slice(0,1) + " " + profile.lastname);
        }else{
          return profile.lastname
        }
      }

      return "Unknown"
    },

    deleteRecord: function(record){
      if(confirm("Delete this record permanently?")){
        this.$sdm.deleteAssessment(record[2]).then((response)=> {

          console.log(response);
          this.loadData();
        })
      }
    },


    downloadReport: async function(){
      if(this.$store.getters.isAdmin){

        let report = await this.$sdm.getAssessmentReport()



        let workbook = utils.book_new();
        if(!workbook.Props) workbook.Props = {};
        workbook.Props.Title = "Assessments List";



        let listing = utils.aoa_to_sheet(report)



        // summary['!cols'] = [
        //   { wpx: 200 }
        // ]
        //
        // details['!cols'] = [
        //   { wpx: 200 }
        // ]
        utils.book_append_sheet(workbook,listing,"Assessments")
        writeFileXLSX(workbook, 'Assessment Report.xlsx');

      }
    },

    getAssessmentUrl: function(assessment){
      if(assessment){
        return "/assessments/"+assessment[2];
      }

      return "#";

    },


    loadData: function(){
      this.loading = true;
      return this.$sdm.getAssessments()
      .then((response) => {
        //console.log(response);
        this.loading = false;

        let allowedTypes = [];

        if(this.$store.getters.hasWorkflowAccess){
          allowedTypes.push('Production Workflow')
        }

        if(this.$store.getters.hasCCMAccess){
          allowedTypes.push('CCM')
        }

        if(this.$store.getters.hasMailingAccess){
          allowedTypes.push('Mail')
        }

        if(this.$store.getters.hasMarketingAccess){
          allowedTypes.push('MAP')
          allowedTypes.push('PMP')
        }

        //console.log("Allowed Types", allowedTypes)


        let itemsWithAccess = response.filter((item)=>{
          return allowedTypes.includes(item[4])
        });

        this.$store.commit("setAssessments",itemsWithAccess);
      })
      .catch((error) => {
        console.error(error);
        this.loading = false;
        alert("There was a problem loading your documents.")
      })
    },

    closeSidePanel: function(){
      this.sidePanel = false;
    },

    showNewAssessment: function(){
      this.sidePanel = true;
    },

    onAssessmentCreated: function(){
      this.loadData().then(() => {
        this.closeSidePanel();
      })
    }


  },

  computed: {
    assessments: function(){
      return this.$store.getters.assessments.filter((assessment) => {
        return (assessment[4]);
      });
    },

    sortedAssessments: function(){
      if(this.assessments && this.assessments.length){
        return [...this.assessments].sort((a,b) => {
          if(a[this.sortKey] < b[this.sortKey]){
            return (this.sortDirection);
          }else if(a[this.sortKey] > b[this.sortKey]){
            return -(this.sortDirection)
          }else if(this.sortKey !== this.secondarySortKey){


            if(a[this.secondarySortKey] < b[this.secondarySortKey]){
              return 1;
            }else if(a[this.secondarySortKey] > b[this.secondarySortKey]){
              return -1;
            }
          }

          return 0
        })
      }
      return [];
    },

    filteredAssessments: function(){
      if(this.searchText){
        let searchTerms = this.searchText.toLowerCase();
        let multiword = searchTerms.includes('+');
        let searchWords = searchTerms.split("+");

        return this.sortedAssessments.filter((item)=>{
          let searchIn = `${item[3]} ${item[1]}`;

          if(multiword){

            let foundAll = true;

            for( let i=0; i<searchWords.length; i++){
              foundAll = (searchIn.toLowerCase().search(searchWords[i].trim()) > -1);
              if(!foundAll){
                break;
              }
            }
            return foundAll;

          }else{
            return (searchIn.toLowerCase().search(searchTerms) > -1)
          }
        });



      }else{
        return this.sortedAssessments;
      }
    }
  }
}
</script>
