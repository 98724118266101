


<template>
  <table class="text-xs w-full" data-cell-style="AppendixCell">
    <thead class="">
    <tr>
      <th class="sticky top-0 relative text-left" style="padding-left:1.25em;">Summary</th>
      <th class="sticky top-0 relative font-bold">Yearly Man Hour Savings with MIS</th>
      <th class="sticky top-0 relative font-bold">Yearly Cost Savings with MIS</th>
      <th class="sticky top-0 relative font-bold">Yearly Man Hour Savings with Online Storefront</th>
      <th class="sticky top-0 relative font-bold">Yearly Cost Savings with Online Storefront</th>
      <th v-if="!hideVDP" class="sticky top-0 relative font-bold">Yearly Man Hour Savings with Automated File Prep</th>
      <th v-if="!hideVDP" class="sticky top-0 relative font-bold">Yearly Cost Savings with Automated File Prep</th>

    </tr>
    </thead>
    <tbody v-for="(group) in summaryGroups" :key="group.key">
    <tr class="">
      <td class="row-label">{{ group.label }}</td>
      <td v-for="(col, colIndex) in detailCols" :key="colIndex">{{ resolveValue( 'summary',group.key,col) }}</td>
    </tr>


    </tbody>






    <tbody>
    <tr class="">
      <td class="row-label" data-cell-style="AppendixBoldCell" style="font-weight: bold;">Internal Workflow Savings Subtotal</td>
      <td v-for="(col, colIndex) in detailCols" :key="colIndex">{{ resolveValue( 'summary','internalSubtotal',col) }}</td>
    </tr>
    </tbody>
    <tbody>
    <tr class="">
      <td class="row-label">Cost - External Purchase vs. Internally Produced</td>
      <td></td>
      <td>{{ resolveValue( 'summary','externalVsInternal',{ key: 'costMISSavings', format:'$'}) }}</td>
      <td></td>
      <td>{{ resolveValue( 'summary','externalVsInternal',{ key: 'costStorefrontSavings', format:'$'}) }}</td>
      <td v-if="!hideVDP"></td>
      <td v-if="!hideVDP"></td>

    </tr>
    </tbody>
    <tbody>
    <tr class="">
      <td class="row-label" data-cell-style="AppendixBoldCell" style="font-weight: bold;">Total Cost Savings</td>
      <td v-for="(col, colIndex) in detailCols" :key="colIndex" style="font-weight: bold;">{{ resolveValue( 'summary','totals',col) }}</td>
    </tr>
    </tbody>
    <tbody>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td v-if="!hideVDP"></td>
      <td v-if="!hideVDP"></td>
    </tr>
    <tr>
      <td data-cell-style="AppendixBoldCell" class="text-left pl-4 font-bold" colspan="2">Additional Yearly Revenue</td>
      <td data-cell-style="AppendixBoldCell" style="font-weight: bold;">{{ savings.revenue.total | currency(1) }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
    name: "savings-summary-table",
    props: ['assessment','hideVDP'],
    data: function(){
        return {}
    },
    methods: {
      resolveValue: function(group, row, col){
        //console.log(group,row,col);
        if(this.savings[group][row] !== undefined){
          if(this.savings[group][row][col.key] !== undefined){
            if(col.format === "$"){
              return ("$"+this.$options.filters.decimal(this.savings[group][row][col.key], 0));
            }else{
              return this.$options.filters.decimal(this.savings[group][row][col.key], 0);
            }

          }
        }
      }
    },
  computed: {


    savings: function(){
      if(this.assessment){
        return this.assessment.savings;
      }

      return null;
    },

    detailGroups: function(){
      return [
        { label: "Estimate Development", key: "estimates", rows: [
            { label: "Offset Print Estimates", key: "offset" },
            { label: "Digital Print Estimates", key: "digital" },
            { label: "Large Format Print Estimates", key: "largeFormat" },
            { label: "  Total", key: "subtotal" },
            { label: "Quote letters", key: "quoteLetters" },
          ] },
        { label: "Job Ticket Development", key: "jobTickets", rows: [
            { label: "1) Production", key: "production" },
            { label: "2) Fulfillment Only (already produced and in inventory)", key: "fulfillmentOnly" },
            { label: "Job Tickets Created Manually Each Month (From e-Mail, Phone, Snail Mail)", key: "" },
            { label: "3) Jobs Converted from Estimates", key: "converted" },
            { label: "4) Orders with all Job Specs Identified", key: "withSpecs" },
            { label: "5) Orders - You Develop all Job Specs", key: "developed" },
            { label: "6) Re-order (all production info in system from previous order)", key: "reorder" },
            { label: "7) Fulfillment Orders (already produced and in inventory)", key: "fulfillmentManual" },
            { label: "Total for All Jobs Produced per Year", key: "totals" },
          ] },
        { label: "Inventory", key: "inventory", rows: [
            { label: "Time Spent Managing Inventory", key: "totals" },
          ] },
        { label: "Job Tracking / Scheduling", key: "jobTracking", rows: [
            { label: "Administration/CSR Job Tracking", key: "admin" },
            { label: "Owner/Sales/Production Manager/Supervisor Job Tracking", key: "owner" },
            { label: "Production Meetings", key: "meetings" },
            { label: "Total", key: "totals" },
          ] },
        { label: "Shipment Tracking", key: "shipments", rows: [
            { label: "Amount of Time to Track each UPS/FedEx Package per Job", key: "tracking" },
            { label: "Time to Create Shipping Docs", key: "docs" },
            { label: "Total", key: "totals" },
          ] },
        { label: "Purchasing/Accounts Payable Processing", key: "purchasing", rows: [
            { label: "Create PO", key: "create" },
            { label: "Reconcile PO", key: "reconcile" },
            { label: "Accounts Payable Processing (invoices for purchases for jobs)", key: "processing" },
            { label: "Total", key: "totals" },
          ] },
        { label: "External Purchase vs. Internally Produced", key: "externalVsInternal", rows: [
            { label: "Cost", key: "totals" },
          ] },
        { label: "Billing/Accounts Receivable Processing", key: "billing", rows: [
            { label: "Create Invoice", key: "create" },
            { label: "Log Info into Accounting System", key: "accounting" },
            { label: "Cost of Carrying $s on P&L", key: "plCost" },
            { label: "Total", key: "totals" },
          ] },
        { label: "Labor Tracking", key: "labor", rows: [
            { label: "Time Spent Tracking Labor per Day per Employee", key: "totals" },
          ] },
        { label: "Errors/Rework/Waste", key: "errors", rows: [
            { label: "Reduced Errors - Result of Information Accuracy in Software", key: "reducedSoftware" },
            { label: "Reduced Errors - Content Errors Charged Internally", key: "reducedContent" },
          ] },
        { label: "Billable Customer Changes", key: "changes", rows: [
            { label: "# of Jobs where Billable Changes are Missed when Invoicing", key: "totals" },
          ] },
        { label: "Report Development", key: "reports", rows: [
            { label: "Sales by Customer", key: "sales" },
            { label: "Actual vs. Estimate", key: "actualVsEstimate" },
            { label: "Customer Profitability", key: "customerProfit" },
            { label: "Product Line Profitability", key: "productProfit" },
            { label: "Weekly Sales", key: "weeklySales" },
            { label: "Monthly Sales", key: "monthlySales" },
            { label: "Jobs Shipped and not Invoiced", key: "notInvoiced" },
            { label: "Employee Productivity", key: "productivity" },
            { label: "Real Time Schedule Report", key: "schedule" },
            { label: "Payroll", key: "payroll" },
            { label: "", key: "totals" },
          ] },
        { label: "Miscellaneous", key: "misc", rows: [
            { label: "Opportunity for Cost Savings Not Identified Above", key: "" },
            { label: "Info from Customer - List Area of Savings Opportunity", key: "totals" },
          ] },

      ]
    },

    summaryGroups: function(){
      if(this.hideVDP){
        return [
          { label: "Estimate Development", key: "estimates"},
          { label: "Job Ticket Development", key: "jobTickets"},
          { label: "Inventory", key: "inventory" },
          { label: "Job Tracking / Scheduling", key: "jobTracking" },
          { label: "Shipment Tracking", key: "shipments" },
          { label: "Purchasing/Accounts Payable Processing", key: "purchasing" },
          { label: "Billing/Accounts Receivable Processing", key: "billing" },
          { label: "Labor Tracking", key: "labor" },
          { label: "Errors/Rework/Waste", key: "errors" },
          { label: "Billable Customer Changes", key: "changes" },
          { label: "Report Development", key: "reports" },
          { label: "Miscellaneous", key: "misc" },
        ]
      }else{
        return [
          { label: "Estimate Development", key: "estimates"},
          { label: "Job Ticket Development", key: "jobTickets"},
          { label: "VDP / Personalization", key: "vdp" },
          { label: "Inventory", key: "inventory" },
          { label: "Job Tracking / Scheduling", key: "jobTracking" },
          { label: "Shipment Tracking", key: "shipments" },
          { label: "Purchasing/Accounts Payable Processing", key: "purchasing" },
          { label: "Billing/Accounts Receivable Processing", key: "billing" },
          { label: "Labor Tracking", key: "labor" },
          { label: "Errors/Rework/Waste", key: "errors" },
          { label: "Billable Customer Changes", key: "changes" },
          { label: "Report Development", key: "reports" },
          { label: "Miscellaneous", key: "misc" },
        ]
      }

    },

    detailCols: function(){
      if(this.hideVDP){
        return [
          { key:"hoursMISSavings", format: ""},
          { key:"costMISSavings", format: "$"},
          { key:"hoursStorefrontSavings", format: ""},
          { key:"costStorefrontSavings", format: "$"}
        ]
      }else{
        return [
          { key:"hoursMISSavings", format: ""},
          { key:"costMISSavings", format: "$"},
          { key:"hoursStorefrontSavings", format: ""},
          { key:"costStorefrontSavings", format: "$"},
          { key:"hoursVDPSavings", format: ""},
          { key:"costVDPSavings", format: "$"}
        ]
      }

    },

    costs: function(){
      if(this.assessment){
        return this.assessment.costs.vdp;
      }
      return null;
    },


  }
}
</script>

<style scoped>


.savings-summary-table {

}

tr {
  @apply bg-white;
}

th {
  @apply border bg-gray-100 align-bottom;
  padding: 0.5rem 0.5rem;
}

th.row-label {
  @apply px-4;
}


td {
  @apply text-right border w-16;
  padding: 0.5rem 1.0rem;
  text-align: right;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}

.group-label {
  @apply text-left font-bold underline pt-8;
}
.row-label {
  @apply text-left;
}





td[data-null="true"]{

}

tbody tr:hover td {
  @apply bg-blue-50 cursor-pointer;
}

tr.blank {
  @apply bg-transparent;
}

tr.blank td {
  @apply border-none;
}

</style>