


<template>

  <div>
    <div class="p-1" v-for="(choice,index) in choices" :key="choice" >
      <input class="mr-2 w-4 h-4"  :id="'c'+index" :type="selectOne?'radio':'checkbox'" :value="choice" v-model="displayValue" @change="onChange" >
      <label  class="" :for="'c'+index">{{ choice }}</label>
    </div>
  </div>

</template>

<script>
export default {
    name: "multi-choice-input",
    props: ['value', 'choices','selectOne'],
    data: function(){
          return {
          }
        },
    methods: {
      onChange: function(){
        this.$emit("change");
      }
    },
  computed: {


    displayValue: {
      get: function() {
        if(this.value){
          if(this.selectOne){
            return this.value;
          }else{
            return this.value.split(",");
          }

        }

        return [];
      },
      set: function(modifiedValue) {
        if(this.selectOne){
          this.$emit('input', modifiedValue)
        }else{
          let newValue = modifiedValue.join(",")
          this.$emit('input', newValue)
        }
      }
    }
  }
}
</script>

<style scoped>


.percent-input {

}

</style>