


<template>
<svg class="w-full" width="1000" height="500" ref="chart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 500">
  <rect width="1000" height="500" x="0" y="0" fill="white" stroke="black"></rect>
  <g>
    <text text-anchor="middle" x="275" y="30" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Annual Costs of Print MIS Compared to</text>
    <text text-anchor="middle" x="275" y="50" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Annual Cost Savings and Potential Sales Growth</text>
    <rect width="350" height="300" x="100" y="70" fill="none" stroke="black"></rect>

    <text text-anchor="end" x="90" y="76" font-family="Arial, Helvetica, sans-serif" font-size="12">${{  costsData.ceiling | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="151" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ (costsData.increment * 3) | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="226" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ (costsData.increment * 2) | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="301" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ costsData.increment | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="372" font-family="Arial, Helvetica, sans-serif" font-size="12">$0</text>


    <line x1="100" y1="151" x2="450" y2="151" stroke="black" stroke-width="0.8"></line>
    <line x1="100" y1="226" x2="450" y2="226" stroke="black" stroke-width="0.8"></line>
    <line x1="100" y1="301" x2="450" y2="301" stroke="black" stroke-width="0.8"></line>

    <g v-for="(year, index) in costsData.data" :key="index">
      <rect width="15" :height="year[0]" :x="115 + (index * 70)" :y="370 - year[0]" fill="#2a83c1" stroke="none"></rect>
      <rect width="15" :height="year[1]" :x="130 + (index * 70)" :y="370 - year[1]" fill="#fed789" stroke="none"></rect>
      <rect width="15" :height="year[2]" :x="145 + (index * 70)" :y="370 - year[2]" fill="#4ac44c" stroke="none"></rect>
    </g>




    <text text-anchor="middle" x="135" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Year 1</text>
    <text text-anchor="middle" x="205" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Year 2</text>
    <text text-anchor="middle" x="280" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Year 3</text>
    <text text-anchor="middle" x="350" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Year 4</text>
    <text text-anchor="middle" x="420" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Year 5</text>


    <rect width="15" height="15" x="190" y="420" fill="#2a83c1" stroke="none"></rect>
    <text text-anchor="start" x="215" y="432" font-family="Arial, Helvetica, sans-serif" font-size="12">Annual Costs</text>
    <rect width="15" height="15" x="190" y="440" fill="#fed789" stroke="none"></rect>
    <text text-anchor="start" x="215" y="452" font-family="Arial, Helvetica, sans-serif" font-size="12">Annual Cost Savings</text>
    <rect width="15" height="15" x="190" y="460" fill="#4ac44c" stroke="none"></rect>
    <text text-anchor="start" x="215" y="472" font-family="Arial, Helvetica, sans-serif" font-size="12">Potential Sales Growth</text>

  </g>

  <g>
    <text text-anchor="middle" x="775" y="30" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Print MIS Software Cost Impact</text>
    <text text-anchor="middle" x="775" y="50" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Annual Cost Savings and Potential Sales Growth</text>
    <rect width="350" height="300" x="600" y="70" fill="none" stroke="black"></rect>


    <text text-anchor="end" x="590" y="76" font-family="Arial, Helvetica, sans-serif" font-size="12">${{  impactData.ceiling | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="151" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ (impactData.increment * 3) | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="226" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ (impactData.increment * 2) | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="301" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ impactData.increment | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="372" font-family="Arial, Helvetica, sans-serif" font-size="12">$0</text>

    <line x1="600" y1="151" x2="950" y2="151" stroke="black" stroke-width="0.8"></line>
    <line x1="600" y1="226" x2="950" y2="226" stroke="black" stroke-width="0.8"></line>
    <line x1="600" y1="301" x2="950" y2="301" stroke="black" stroke-width="0.8"></line>

    <g v-for="(year, index) in impactData.data" :key="index">
      <rect width="45" :height="year[0]" :x="615 + (index * 70)" :y="370 - year[0]" fill="#2a83c1" stroke="none"></rect>
      <rect width="45" :height="year[1]" :x="615 + (index * 70)" :y="370 - (year[0] + year[1])" fill="#fed789" stroke="none"></rect>
      <rect width="45" :height="year[2]" :x="615 + (index * 70)" :y="370 - (year[0] + year[1] +year[2])" fill="#4ac44c" stroke="none"></rect>
    </g>

    <text text-anchor="middle" x="635" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Year 1</text>
    <text text-anchor="middle" x="705" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Year 2</text>
    <text text-anchor="middle" x="780" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Year 3</text>
    <text text-anchor="middle" x="850" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Year 4</text>
    <text text-anchor="middle" x="920" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Year 5</text>

    <rect width="15" height="15" x="690" y="420" fill="#2a83c1" stroke="none"></rect>
    <text text-anchor="start" x="715" y="432" font-family="Arial, Helvetica, sans-serif" font-size="12">Annual Costs</text>
    <rect width="15" height="15" x="690" y="440" fill="#fed789" stroke="none"></rect>
    <text text-anchor="start" x="715" y="452" font-family="Arial, Helvetica, sans-serif" font-size="12">Annual Cost Savings</text>
    <rect width="15" height="15" x="690" y="460" fill="#4ac44c" stroke="none"></rect>
    <text text-anchor="start" x="715" y="472" font-family="Arial, Helvetica, sans-serif" font-size="12">Potential Sales Growth</text>

  </g>




</svg>
</template>

<script>
export default {
    name: "roi-chart-mis-potential",
    props: ["assessment"],
    data: function(){
        return {}
        },
    methods: {
      ceilingVal: function(max){

        let multiplier = 1000;
        if(max < 10000){
          multiplier = 100
        }
        if(max < 1000){
          multiplier = 10
        }
        return Math.ceil((max / 4)/multiplier) * 4 * multiplier;
      },
    },
    computed: {




      costsData: function(){
        let years = [];
        let costs = this.assessment.costs.mis;

        let maxValue = 1000;
        let scale = 300;

        for(var i=1; i<6; i++){

          let cost = costs.totals["year"+i];
          let saving = costs.savings["year"+i];
          let growth = costs.salesGrowth["year"+i];

          if(i === 1){
            cost = cost + costs.totals.initial;
            saving = saving + costs.savings.initial;
            growth = growth + costs.salesGrowth.initial;
          }

          if(cost > maxValue){
            maxValue = cost;
          }

          if(saving > maxValue){
            maxValue = saving;
          }

          if(growth > maxValue){
            maxValue = growth;
          }

          years.push([
              cost, saving, growth
          ])
        }





        let ceiling = this.ceilingVal(maxValue * 1.1);

        //console.log(ceiling, maxValue)


        return {
          ceiling: ceiling,
          increment: ceiling / 4,
          data: years.map((year) => {
            return [
              ((year[0] / ceiling) * scale),
              ((year[1] / ceiling) * scale),
              ((year[2] / ceiling) * scale),
            ]
          })
      }

      },

      impactData: function(){
        let years = [];
        let costs = this.assessment.costs.mis;

        // console.log("mis costs",costs)

        let maxValue = 1000;
        let scale = 300;

        for(var i=1; i<6; i++){

          let cost = costs.totals["year"+i];
          let saving = costs.savings["year"+i];
          let growth = costs.salesGrowth["year"+i];

          if(i === 1){
            cost = cost + costs.totals.initial;
            saving = saving + costs.savings.initial;
            growth = growth + costs.salesGrowth.initial;
          }




          let total = (saving - cost) + growth;

          if(saving < cost){
            total = cost + growth
          }

          // console.log("cost impact year "+i);
          // console.log(costs.totals["year"+i], costs.totals.initial, costs.savings["year"+i], costs.savings.initial,costs.salesGrowth["year"+i],costs.salesGrowth.initial)
          // console.log(cost, saving, growth, total);

          if(total > maxValue){
            maxValue = total;
          }


          years.push([
            cost, saving, growth
          ])
        }





        let ceiling = this.ceilingVal(maxValue * 1.1);

        // console.log(years,ceiling)


        let impactData =  {
          ceiling: ceiling,
          increment: ceiling / 4,
          data: years.map((year) => {
            return [
              ((year[0] / ceiling) * scale),
              (((year[1] - year[0]) / ceiling) * scale),
              ((year[2] / ceiling) * scale),
            ]
          })
        }

        // console.log(impactData)

        return impactData;

      }

    }
}
</script>

<style scoped>


.roichart-mispotential {

}

</style>