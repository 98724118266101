<template>
  <div class="pre-self-assessment">
    <div class="mb-8">
      <p class="py-4 px-6 bg-gray-100 border rounded font-bold">The Certified Walkthrough Specialist will complete the Walkthrough Assessment based on additional information gathered during the walkthrough. Provide a score (1-5 or N/A) that most closely represents their operation. There is room in each section to provide unique notes based on your evaluation of the operation.</p>
    </div>
    <div class="list-decimal list-inside text-lg font-bold" v-if="surveyQuestions">
        <div v-for="(question, index) in surveyQuestions" :key="question.id" class=" relative px-6 pt-4 -mx-4 pb-5 border-b hover:bg-white cursor-pointer" :class="(question == focusedQuestion)?'shadow-lg rounded border-l border-r bg-white':''">
          <survey-question :class="(isCCM || isMail)?'pr-12':''" answer-key="final" :num="index + 1" :question="question" :answer="answers[question.id]"  @focus="onFocusQuestion" @info="showInfo" @change="setAnswer" :show-scale="(question == focusedQuestion)"></survey-question>
          <div class="absolute right-0 top-0 mr-2 mt-4" v-if="(isCCM || isMail)">
            <label class="text-xs block text-center text-gray-400 font-normal">Weight</label>
            <number-input class="text-xs w-12 border p-1 px-2 rounded text-right" format="%" precision="0" int="true" v-model="answers[question.id].weight"></number-input>
          </div>
        </div>
    </div>

    <div class="flex justify-end items-center leading-none -mx-4 pt-4 relative" :class="(isCCM || isMail)?'pr-16':'pr-4'">
      <p class="mr-4 text-gray-500">Overall Score<span v-if="(isCCM || isMail)"> (Weighted)</span>:</p>
      <div class="flex-shrink-0 w-12 h-12 border-4 tracking-tighter mx-2 text-center text-2xl font-bold totalAvg flex items-center justify-center" :data-color="scoreColor"><span>{{ weightedScore }}</span></div>
      <div class="absolute right-0 top-0 mr-2 mt-4" v-if="(isCCM || isMail)">
        <label class="text-xs block text-center text-gray-400 font-normal">Total</label>
        <span class="text-xs w-12 p-1 px-2 rounded text-right">{{ totalWeight }}%</span>
      </div>
    </div>

    <div class="pt-4 mt-4 border-t">
      <div class="pb-4 flex justify-between">
        <div class="pr-4">
          <button class="button button-back" @click="goToSection('back')"><img class="inline-block mr-2 h-3 transform rotate-180" src="../assets/icons/next-arrow-icon-black.svg" /> <span v-if="(isCCM || isMail)">Customer Profile</span><span v-else>Self Assessment Scorecard</span></button>
        </div>
        <div class="pl-4">
          <button class="button button-blue button-next" @click="goToSection('next')">Walkthrough Assessment Scorecard <img class="inline-block ml-2 h-3" src="../assets/icons/next-arrow-icon.svg" /></button>
        </div>

      </div>
    </div>

    <transition name="fade-on">
      <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="info">
        <side-panel-survey-rating :question="info"  @close="closeInfo"></side-panel-survey-rating>
      </div>
    </transition>

  </div>
</template>

<script>
import SidePanelSurveyRating from "@/components/SidePanelSurveyRating";
import SurveyQuestion from "@/components/SurveyQuestion";
import bodyScroll from "body-scroll-toggle"
import NumberInput from "@/components/NumberInput";
export default {
  name: "pre-walkthrough-assessment",
  components: {NumberInput, SurveyQuestion, SidePanelSurveyRating},
  mounted: function(){

  },
  props: {},
  data: function () {
    return {
      focusedQuestion: undefined,
      info: undefined
    }
  },
  methods: {

    goToSection: function(section){

      if(this.isCCM && this.totalWeight !== 100){
        alert("Total weight of scores needs to equal 100%. Please adjust your weight values.");
        return;
      }

      if(section === 'back'){
        if(this.isCCM){
          this.$emit('goSection', 'profile')
        } else if(this.isMail){
          this.$emit('goSection', 'profile')
        } else {
          this.$emit('goSection', 'selfassessment')
        }


      }else{
        this.$emit('goSection', 'walkthroughscore')
      }



    },

    onFocusQuestion: function(question){

      if(this.focusedQuestion !== question){
        this.focusedQuestion = question;
      }else{
        this.focusedQuestion = null;
      }
    },

    showInfo: function(question){
      bodyScroll.disable();
      this.info = question;

    },

    closeInfo: function(){

      this.info = undefined
      bodyScroll.enable();
    },

    setAnswer: function(e){

      this.$store.commit("setSurveyAnswer", {
        key: "final",
        value: e.value,
        id: e.question.id
      })

      this.$sdm.calculate(this.$store.state.workflowAssessment);

      this.$store.state.hasUnsavedChanges = true;
    }
  },
  computed: {

    totalWeight: function(){
      return this.surveyQuestions.reduce((total, value) => {
        if(this.answers[value.id].weight){
          return total + this.answers[value.id].weight;
        }
        return total;
      }, 0)
    },

    weightedScore: function(){
      if(this.$store.state.workflowAssessment){
        return this.$store.state.workflowAssessment.scorecard.final;
      }

      return "";
    },

    isCCM: function(){
      if(this.$store.state.workflowAssessment){
        return (this.$store.state.workflowAssessment.customerProfile.assessmentType === "CCM")
      }

      return false;

    },

    isMail: function(){
      if(this.$store.state.workflowAssessment){
        return (this.$store.state.workflowAssessment.customerProfile.assessmentType === "Mail")
      }

      return false;

    },

    surveyQuestions: function(){
      if(this.$store.state.surveyQuestions){
        return Object.keys(this.$store.state.surveyQuestions).map((key) => {
          return this.$store.state.surveyQuestions[key];
        })
      }

      return [];
    },

    answers: function(){
      return this.$store.getters.surveyAnswers;
    },

    scaleColors: function(){
      return this.$store.getters.scaleColorClasses;
    },

    scoreColor: function(){
      if(this.weightedScore){
        return parseInt(this.weightedScore);
      }

      return "NA"
    }
  }
}
</script>

<style scoped>


.pre-self-assessment {

}

ol li:last-child hr {
  display: none;

}


.total[data-color="5"] {
  @apply bg-brand-green text-black;
}

.total[data-color="4"] {
  @apply bg-brand-green-light text-black;
}

.total[data-color="3"] {
  @apply bg-yellow-500 text-black;
}

.total[data-color="2"] {
  @apply bg-red-500 text-black;
}

.total[data-color="1"] {
  @apply bg-red-500 text-black;
}


.totalAvg[data-color="5"] {
  @apply border-brand-green text-brand-green;
}

.totalAvg[data-color="4"] {
  @apply border-brand-green-light text-brand-green-light;
}

.totalAvg[data-color="3"] {
  @apply border-yellow-500 text-yellow-500;
}

.totalAvg[data-color="2"] {
  @apply border-red-500 text-red-500;
}

.totalAvg[data-color="1"] {
  @apply border-red-500 text-red-500;
}

</style>