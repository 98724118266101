<template>
    <div class="hint text-center text-gray-400 select-none cursor-pointer" >
      <div class="flex items-center justify-center">
        <span class="icon inline-block leading-none font-bold text-xs w-6 h-6 p-1 rounded-full border-2">?</span>
        <div class="message" :class="direction"><span>{{ message }}</span></div>
      </div>

    </div>
</template>

<script>
export default {
name: "hint",
  props: ["message", "direction"]
}
</script>

<style scoped>

  .message {
    display: none;
  }

  .message span {
    @apply bg-brand-blue rounded text-white;
    display: block;
    padding: 1em;
    width: 16em;


  }

  .hint:hover .icon {
    @apply bg-brand-blue text-white border-brand-blue;
  }

  .hint:hover .message {

    position: absolute;
    display: inline-block;
    z-index: 100;
  }

</style>