


<template>
<div class="summary-export max-w-3xl">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold" v-if="options.instructions">{{ options.instructions }}</p>
  </div>
  <div>
    <h2 class="font-bold text-xl pb-2 border-b-2">Export Options</h2>
  </div>

  <div class="py-4 border-b flex justify-between items-center">
    <div class="flex items-center">

        <h2 class="text-lg">Self Assessment Scorecard</h2>
    </div>
    <div>
      <button class="button button-blue" @click="onPreview('selfscorecard')">Preview</button>
    </div>
  </div>
  <div class="py-4 border-b flex justify-between items-center">
    <div class="flex items-center">

      <h2 class="text-lg">Production Workflow Scorecard</h2>
    </div>
    <div>
      <button class="button button-blue" @click="onPreview('scorecard')">Preview</button>
    </div>
  </div>
  <div class="py-4 border-b flex justify-between items-center">
    <div class="flex items-center">

      <h2 class="text-lg">Savings Detail Summary</h2>
    </div>
    <div>
      <button class="button button-blue" @click="onPreview('savings')">Preview</button>
    </div>
  </div>
  <div class="py-4 flex justify-between items-center hidden">
    <div class="flex items-center">

      <h2 class="ml-4 text-lg">Photo Gallery (0 images)</h2>
    </div>
    <div>
      <button class="button button-blue" @click="onPreview('photos')">Preview</button>
    </div>
  </div>
  <div class="py-4 pt-8 border-b">
    <div>
      <h2 class="font-bold text-xl pb-2 border-b-2">Findings Report</h2>

    </div>
    <div>
      <h3 class="text-lg font-bold mt-6 mb-2">Background Statement</h3>
      <div class="p-4 border mb-2 rounded" :class="(assessment.exportSettings.option == '1')?'bg-white':''">
        <div class="flex items-center pb-2">
          <input @change="onChange"  type="radio" class="flex-shrink-0 w-6 h-6" value="1" v-model="assessment.exportSettings.option"/>
          <h2 class="ml-4 text-lg font-bold">Option 1</h2>

        </div>
        <div>
          <p>{{ assessment.customerProfile.organizationName }} is seeking recommendations to improve profitability and allow room for growth by streamlining workflow for estimating, customer ordering, job ticket development, data collection, invoicing and reporting.</p>
          <p class="mt-2">To support the efforts of the Print Production Team at {{ assessment.customerProfile.organizationName }} and to determine where Ricoh could help with overall improvements, Ricoh conducted a Production Workflow Assessment to benchmark {{ assessment.customerProfile.organizationName }} against industry best practices.  The assessment looked at the entire production operation with strong focus on removing manual processes and improving overall job flow.</p>
        </div>
        </div>
      <div class="p-4 border rounded mb-4" :class="(assessment.exportSettings.option == '2')?'bg-white':''">
        <div class="flex items-center pb-2">
          <input @change="onChange"  type="radio" class="flex-shrink-0 w-6 h-6" value="2" v-model="assessment.exportSettings.option"/>
          <h2 class="ml-4 text-lg font-bold">Option 2</h2>
        </div>
        <div>
          <p>{{ assessment.customerProfile.organizationName }} is seeking recommendations to improve operational efficiency and customer ease of use by implementing the latest online print ordering technology. To support these efforts and to determine where Ricoh could help with overall improvements, Ricoh conducted a Web to Print Readiness Assessment to benchmark {{ assessment.customerProfile.organizationName }} against industry best practices and determine the appropriate solution to meet the stated goals.  The assessment looked at the overall operation from quote requests and estimating to invoicing and reporting.</p>
        </div>
      </div>

    </div>
    <div class="flex flex-wrap">
      <div class="w-1/2">

        <h3 class="text-lg font-bold mt-6 mb-2">Print MIS Solutions</h3>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="radio" class="flex-shrink-0 w-6 h-6" value="AvantiSlingshot" v-model="assessment.exportSettings.solutions.MIS"/>
          <h2 class="ml-4 text-lg">Avanti Slingshot</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="radio" class="flex-shrink-0 w-6 h-6" value="EfiPace" v-model="assessment.exportSettings.solutions.MIS"/>
          <h2 class="ml-4 text-lg">ePS Pace</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange" type="radio" class="flex-shrink-0 w-6 h-6" value="PrintSmithVision" v-model="assessment.exportSettings.solutions.MIS"/>
          <h2 class="ml-4 text-lg">ePS PrintSmith Vision</h2>
        </div>


      </div>

      <div class="w-1/2">
        <h3 class="text-lg font-bold mt-6 mb-2">File Prep/VDP Solutions</h3>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="prep1" class="flex-shrink-0 w-6 h-6" value="FusionPro" v-model="assessment.exportSettings.solutions.FilePrep"/>
          <h2 class="ml-4 text-lg">Fusion Pro</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="prep2" class="flex-shrink-0 w-6 h-6" value="PrintShopMailConnect" v-model="assessment.exportSettings.solutions.FilePrep"/>
          <h2 class="ml-4 text-lg">Objectif Lune Print Shop Mail</h2>
        </div>

        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="prep3" class="flex-shrink-0 w-6 h-6" value="EFIFieryJobMaster" v-model="assessment.exportSettings.solutions.FilePrep"/>
          <h2 class="ml-4 text-lg">EFI Fiery Job Master</h2>
        </div>

        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="prep4" class="flex-shrink-0 w-6 h-6" value="EFIFieryJobFlow" v-model="assessment.exportSettings.solutions.FilePrep"/>
          <h2 class="ml-4 text-lg">EFI Fiery Job Flow</h2>
        </div>

        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="prep5" class="flex-shrink-0 w-6 h-6" value="ReadyPrint" v-model="assessment.exportSettings.solutions.FilePrep"/>
          <h2 class="ml-4 text-lg">RSA ReadyPrint</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="prep6" class="flex-shrink-0 w-6 h-6" value="EnfocusSwitch" v-model="assessment.exportSettings.solutions.FilePrep"/>
          <h2 class="ml-4 text-lg">Enfocus Switch</h2>
        </div>

      </div>
      <div class="w-1/2">


        <h3 class="text-lg font-bold mt-6 mb-2">Online Storefront Solutions</h3>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="radio" class="flex-shrink-0 w-6 h-6" value="MarcomCentral" v-model="assessment.exportSettings.solutions.Storefront" />
          <h2 class="ml-4 text-lg">Marcom Central</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="radio" class="flex-shrink-0 w-6 h-6" value="WebCRD" v-model="assessment.exportSettings.solutions.Storefront"/>
          <h2 class="ml-4 text-lg">RSA Web CRD</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="radio" class="flex-shrink-0 w-6 h-6" value="MarketDirect" v-model="assessment.exportSettings.solutions.Storefront"/>
          <h2 class="ml-4 text-lg">ePS MarketDirect Storefront</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="radio" class="flex-shrink-0 w-6 h-6" value="PrintNet" v-model="assessment.exportSettings.solutions.Storefront"/>
          <h2 class="ml-4 text-lg">PrintNet</h2>
        </div>


      </div>


      <div class="w-1/2">

        <h3 class="text-lg font-bold mt-6 mb-2">Other Recommended Solutions</h3>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="other1" class="flex-shrink-0 w-6 h-6" value="ColorGap" v-model="assessment.exportSettings.solutions.Other"/>
          <h2 class="ml-4 text-lg">Color Management Services</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="other2" class="flex-shrink-0 w-6 h-6" value="RicohConsultingServices" v-model="assessment.exportSettings.solutions.Other"/>
          <h2 class="ml-4 text-lg">Ricoh Consulting Services</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="other3" class="flex-shrink-0 w-6 h-6" value="ProductionWorkflow" v-model="assessment.exportSettings.solutions.Other"/>
          <h2 class="ml-4 text-lg">Production Workflow Automation Services</h2>
        </div>

        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="other4" class="flex-shrink-0 w-6 h-6" value="MailAssessment" v-model="assessment.exportSettings.solutions.Other"/>
          <h2 class="ml-4 text-lg">Mail Assessment</h2>
        </div>

      </div>



    </div>


  </div>

  <div class="pt-4 border-t">
    <div class="pb-4 flex justify-between">
      <div class="pr-4">

      </div>
      <div class="pl-4">
        <button @click="openSnapshotPreview" class="button button-green mr-4">Generate Snapshot</button>
        <button @click="openPreview" class="button button-blue button-next">Generate Full Report</button>
      </div>

    </div>
  </div>


</div>
</template>

<script>
export default {
    name: "summary-export",
    props: {
      options: {
        default: function(){
          return {
            label: "Solution Costs",
            costsKey: "",
            instructions: "Costs instructions",
          }
        },

      },

    },
    data: function(){
        return {
            hasUnsavedChanges: false
        }
        },
    methods: {
      onPreview: function(id){

        if(id === "selfscorecard"){

          this.$router.push({
            name: "Assessment",
            params: this.$route.params,
            query: {
              tab: "pre",
              section: "selfscore"
            }
          })

        }else if(id === "scorecard"){
          this.$router.push({
            name: "Assessment",
            params: this.$route.params,
            query: {
              tab: "pre",
              section: "walkthroughscore"
            }
          })
        }else if(id === "savings"){
          this.$router.push({
            name: "Assessment",
            params: this.$route.params,
            query: {
              tab: "summary",
              section: "savings"
            }
          })
        }else if(id === "photos"){
          console.log(id);
        }
      },

      onChange: function(e){
        console.log(e.target.value)
        this.hasUnsavedChanges = true;
        this.$emit("change");
      },

      onClickRadio: function(e){
        console.log("radio click",e.target.value)
        // this.hasUnsavedChanges = true;
        // this.$emit("change");
      },


      openPreview: function(){

        let updateSettings = {
          exportSettings: this.assessment.exportSettings
        }
        if(this.hasUnsavedChanges){
          this.$sdm.updateAssessment(updateSettings, this.assessment.id).then(()=>{
            this.$router.push({
              name: "Reports",
              query: {
                r: this.$route.params.assessment,
                t: "pw"
              }
            })
          })
        }else{
          this.$router.push({
            name: "Reports",
            query: {
              r: this.$route.params.assessment,
              t: "pw"
            }
          })
        }

      },

      openSnapshotPreview: function(){

        let updateSettings = {
          exportSettings: this.assessment.exportSettings
        }
        if(this.hasUnsavedChanges){
          this.$sdm.updateAssessment(updateSettings, this.assessment.id).then(()=>{
            this.$router.push({
              name: "Reports",
              query: {
                r: this.$route.params.assessment,
                t: "pws"
              }
            })
          })
        }else{
          this.$router.push({
            name: "Reports",
            query: {
              r: this.$route.params.assessment,
              t: "pws"
            }
          })
        }

      },

      getSolutionFlags: function(){
        let flags = "";
        for (var i=0; i<12; i++){
          flags = flags + (this['solution'+i]?"1":"0");
        }

        return flags;
      }

    },
    computed: {
      assessment: function(){
        return this.$store.state.workflowAssessment;
      }
    }
}
</script>

<style scoped>


.summary-export {

}

</style>