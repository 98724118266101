<template>
  <div class="side-panel absolute top-0 right-0 w-full h-full max-w-3xl bg-white border-l bg-gray-50">
    <div class="flex justify-between px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Saved Equipment Rates</h2>
      <img @click="close" class="h-4 inline-block mt-1 cursor-pointer opacity-50 hover:opacity-100" src="../assets/icons/close-icon.svg" />
    </div>
    <div class="pb-8">
      <p class="px-8" v-if="loading">Loading Records...</p>
      <p class="px-8 py-2" v-if="!loading && !projects.length">There are no saved calculations for this profile.</p>
      <div class="">
          <div v-for="project in projects" :key="project.id" class="border-b py-2 cursor-pointer hover:bg-gray-100" @click="loadProject(project)">
            <div class="flex justify-between items-center px-8">
              <div>
                <p>{{ project.name }}</p>
                <p class="text-xs opacity-50">{{ project.ts | formatTS }}</p>
              </div>
              <div class="flex items-center">
                <div class="flex items-center pr-4">
                  <div class=" pr-2 border-r">
                    <p class="text-xs opacity-50">Color</p>
                    <p class="">${{ project.costColor | decimal(4) }}</p>

                  </div>

                  <div class=" pl-2">
                    <p class="text-xs opacity-50">BW</p>
                    <p class="">${{ project.costBW | decimal(4) }}</p>

                  </div>

                </div>

                <img class="inline-block h-3 ml-2" src="../assets/icons/chevron-right-icon.svg" />

              </div>

            </div>


          </div>

      </div>
    </div>



  </div>
</template>

<script>



export default {
  name: "side-panel-equipment-rates",
  components: {},
  mounted() {
     this.loadProjects();
  },
  props: ["profile"],
  data: function () {
    return {
      projects: [],
      loading: true
    }
  },
  methods: {
    close: function(){
      this.$emit('close');
    },

    loadProject: function(project){
      this.$emit('load', project);
    },

    loadProjects: function(){
        this.loading = true;
        this.$colorCalc.loadEquipmentRatesForProfile(this.profile.userid).then((response) => {
          //console.log(response);
          this.loading = false;
          this.projects = response.data.map((project) => {
            return {
              id: project[0],
              ts: project[1],
              name: project[2],
              costColor: project[3],
              costBW: project[4],

            }
          }).sort((a,b)=>{
            return (b.ts - a.ts)
          })
        })
      .catch((error) => {
        console.error("Error", error);
        this.loading = false;
        alert("Records could not be loaded. Refresh and try again.");
      })
    }
  },
  computed: {}
}
</script>

<style scoped>


.side-panel-new-assessment {

}

</style>