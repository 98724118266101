


<template>
<div class="color-calulator relative h-full bg-gray-50">
  <div class="text-xs px-8 py-4 pt-4 bg-white">
    <router-link to="/">Home</router-link> /
    <router-link to="/calculators">Calculators</router-link> /
  </div>
  <div class="relative border-t-4">
    <div class="p-8 relative">
      <div class="max-w-xl mx-auto mt-4">
          <h1 class="font-bold text-2xl mb-8">What kind of calculation do you want to do today?</h1>

          <div class="bg-white px-6 sm:px-8 py-6 flex items-center shadow rounded border cursor-pointer hover:border-brand-blue" @click="showColorCalc" v-if="hasSpecialtyColorAccess">
            <div class="pr-8 flex-shrink-0">
              <img class="block w-12 h-12 sm:h-20 sm:w-20 object-contain" src="../assets/icons/color-calc-icon.svg" />
            </div>
            <div>
              <h2 class="text-lg font-bold mb-1">Specialty Color Cost Estimate</h2>
              <p class="text-sm text-gray-500">Calculate the additional cost for Specialty Color on a job.</p>
            </div>
            <div class="pl-8 pr-2 sm:pr-4 flex-shrink-0">
              <img class="w-3" src="../assets/icons/chevron-right-icon.svg" />
            </div>
          </div>

        <div class="mt-3 bg-white px-6 sm:px-8 py-6 flex items-center shadow rounded border cursor-pointer hover:border-brand-blue" @click="showRateCalc" v-if="hasEquipmentRatesAccess">
          <div class="pr-8 flex-shrink-0">
            <img class="block w-12 h-12 sm:h-20 sm:w-20 object-contain" src="../assets/icons/gears-icon.svg" />
          </div>
          <div>
            <h2 class="text-lg font-bold mb-1">Equipment Rate Development</h2>
            <p class="text-sm text-gray-500">Calculate an equipment’s impression rate by providing a summary of expenses.</p>
          </div>
          <div class="pl-8 pr-2 sm:pr-4 flex-shrink-0">
            <img class="w-3" src="../assets/icons/chevron-right-icon.svg" />
          </div>
        </div>

        <div class="mt-3 bg-white px-6 sm:px-8 py-6 flex items-center shadow rounded border cursor-pointer hover:border-brand-blue" @click="showProjectCalc" v-if="hasProjectEstimateAccess">
          <div class="pr-8 flex-shrink-0">
            <img class="block w-12 h-12 sm:h-20 sm:w-20 object-contain" src="../assets/icons/estimate-icon.svg" />
          </div>
          <div>
            <h2 class="text-lg font-bold mb-1">Project Cost / Revenue Estimate</h2>
            <p class="text-sm text-gray-500">Create a detailed project estimate to calculate total cost, selling price, and profitablity.</p>
          </div>
          <div class="pl-8 pr-2 sm:pr-4 flex-shrink-0">
            <img class="w-3" src="../assets/icons/chevron-right-icon.svg" />
          </div>
        </div>


      </div>
      <footer class="mt-4 w-full text-center text-sm text-gray-400 p-2"><p>&copy;2021 Ricoh USA, Inc. All Rights Reserved.</p></footer>

    </div>
  </div>



</div>
</template>

<script>


export default {
    name: "calculators",
    props: {},
    mounted: function(){


    },
    data: function(){
        return {

        }
        },
    components: {

    },
    methods: {

      showProjectCalc: function(){
        this.$router.push({
          name: "New Project Estimate"
        })
      },

      showRateCalc: function(){
        this.$router.push({
          name: "New Equipment Rate Calc"
        })
      },


      showColorCalc: function(){
        this.$router.push({
          name: "New Specialty Color Cost Calc"
        })
      }

    },
    computed: {

      profile: function(){
        return this.$store.getters.getUserProfile;
      },

      hasEquipmentRatesAccess: function(){
        return this.$store.getters.hasEquipmentRateAccess
      },

      hasSpecialtyColorAccess: function(){
        return this.$store.getters.hasSpecialtyColorAccess
      },

      hasProjectEstimateAccess: function(){
        return this.$store.getters.hasProjectEstimateAccess
      }
    }
}
</script>

<style scoped>

.color-calulator {

}

</style>