export default {
  "groups":{
    "Organization Statistics":{ "label":"Organization Statistics", "id":"org","questions":["I1001","I1002","I1003","I1004","I1005","I1006","I1007","I1007A","I1008","I1009","I1010A","I1011","I1011A","I1010C","I1010D","I1010E","I1010F","I1010G","I1010H","I1014","I1010","I1013","I1012","I1015","I1016","I1017","I1018","I1019","I1020","I1021","I1022","I1023","I1023A","I1023B","I1024","I1025","I1025A","I1025B","I1025C","I1025D","I1025E","I1025F","I1026","I1027","I1028","I1029","I1030"]},
    "Estimate Development":{ "label":"Estimate Development", "id":"estimates","questions":["I1031","I1032","I1033","I1034","I1035","I1036","I1037","I1037A","I1038","I1039","I1040","I1041","I1042","I1043","I1044","I1044A","I1045","I1046","I1047"]},
    "Job Ticket Development":{ "label":"Job Ticket Development", "id":"jobTickets","questions":["I1048B","I1048","I1048A","I1049","I1050","I1051","I1052","I1053","I1054","I1055","I1056","I1057","I1057A","I1058","I1059","I1060","I1061","I1062","I1063","I1064","I1065","I1066","I1067","I1067A"]},
    "Inventory Management":{ "label":"Inventory Management", "id":"inventory","questions":["I1068","I1069","I1070","I1071","I1072","I1073","I1074","I1075","I1076","I1077","I1078","I1079","I1080","I1081","I1082","I1083","I1083A"]},
    "Purchasing/Accounts Payable Processing":{ "label":"Purchasing/Accounts Payable Processing", "id":"purchasing","questions":["I1084A","I1084","I1085","I1086","I1087","I1088","I1089"]},
    "Job Tracking/Scheduling":{ "label":"Job Tracking/Scheduling", "id":"jobTracking","questions":["I1090","I1091","I1092","I1093","I1094","I1095","I1095A","I1096","I1097","I1098","I1099"]},
    "Production":{ "label":"Production", "id":"production","questions":["I1100","I1101","I1102","I1103","I1104","I1104A","I1104B","I1105","I1105A"]},
    "Billable Customer Changes":{ "label":"Billable Customer Changes", "id":"changes","questions":["I1106","I1107","I1108","I1109"]},
    "Errors/Rework/Waste":{ "label":"Errors/Rework/Waste", "id":"waste","questions":["I1110","I1111","I1112"]},
    "Shipment Tracking":{ "label":"Shipment Tracking", "id":"shipments","questions":["I1113","I1114","I1115","I1116","I1117"]},
    "Billing/Accounts Receivable Processing":{ "label":"Billing/Accounts Receivable Processing", "id":"billing","questions":["I1118","I1119","I1120","I1121","I1122","I1123","I1124","I1125","I1126"]},
    "Labor Tracking":{ "label":"Labor Tracking", "id":"labor","questions":["I1127", "I1127A"]},
    "Report Development":{ "label":"Report Development", "id":"reports","questions":["I1128","I1134","I1135","I1136","I1137","I1138","I1139","I1140","I1141","I1142","I1143","I1129","I1130","I1131","I1132","I1133"]},
    "Miscellaneous":{ "label":"Miscellaneous", "id":"misc","questions":["I1144","I1145","I1146","I1147"]},
    "Sales":{ "label":"Sales", "id":"sales","questions":["I1148","I1149","I1150","I1151"]},
    "Variable Data Order Details":{ "label":"File Prep", "id":"variableData", "questions":["V1010","V1011","V1013","V1000","V1001","V1002","V1003","V1004","V1005","V1006"]}



  },

  "stages":{
    "Organization Statistics":{ "id": "org", "markerLabel": "Organization<br>Statistics", "label": "Organization Statistics", "sections":["org"],
      "instructions": "Please fill out the forms below from information provided by the Customer."
    },
    "Estimating / Job Ticketing":{ "id": "estimating", "markerLabel": "Estimating /<br>Job Ticketing / Prep", "label": "Estimating / Job Ticketing / Prep", "sections":["estimates","jobTickets","variableData"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Accounting":{ "id": "accounting", "markerLabel": "Accounting", "label": "Accounting", "sections":["labor","purchasing","billing"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Production":{ "id": "production", "markerLabel": "Production", "label": "Production", "sections":["jobTracking","production"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Inventory / Shipping":{ "id": "inventory", "markerLabel": "Inventory /<br>Shipping", "label": "Inventory / Shipping", "sections":["inventory","shipments"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Quality / Misc.":{ "id": "quality", "markerLabel": "Quality / Misc.", "label": "Quality / Misc.", "sections":["changes","waste","misc"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Reports":{ "id": "reports", "markerLabel": "Reports", "label": "Reports", "sections":["sales","reports"], "instructions": "Please fill out the forms below from information provided by the Customer."}
  },


  "questions":{

    "I1001":{
      "id": "I1001",
      "num": 1,
      "type":2,
      "question": "Annual Sales",
      "caption": "$",
      "dataFormat":"$",
      "hint": "Current Sales - Sales may increase utilizing Print MIS - additional sales potential is calculated below.",
      "cols":[],
      "defaults":[]
    },
    "I1002":{
      "id": "I1002",
      "num": 2,
      "type":2,
      "question": "Current Print $s Outsourced to Commercial Print Companies",
      "caption": "$",
      "dataFormat":"$",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1003":{
      "id": "I1003",
      "num": 3,
      "type":2,
      "question": "Total Number of Jobs per Year",
      "caption": "#",
      "dataFormat":"#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$a"},
      "calcVars":{"a":"I1157.final"}
    },
    "I1004":{
      "id": "I1004",
      "num": 4,
      "type":2,
      "question": "# of Impressions for Offset Print per Year - Color",
      "caption": "#",
      "dataFormat":"#",
      "hint": "For background information only",
      "cols":[],
      "defaults":[]
    },
    "I1005":{
      "id": "I1005",
      "num": 5,
      "type":2,
      "question": "# of Impressions for Offset Print per Year - Monochrome",
      "caption": "#",
      "dataFormat":"#",
      "hint": "For background information only",
      "cols":[],
      "defaults":[]
    },
    "I1006":{
      "id": "I1006",
      "num": 6,
      "type":2,
      "question": "# of Impressions for Digital Print per Year - Color",
      "caption": "#",
      "dataFormat":"#",
      "hint": "For background information only",
      "cols":[],
      "defaults":[]
    },
    "I1007":{
      "id": "I1007",
      "num": 7,
      "type":2,
      "question": "# of Impressions for Digital Print per Year - Monochrome",
      "caption": "#",
      "dataFormat":"#",
      "hint": "For background information only",
      "cols":[],
      "defaults":[]
    },


    "I1007A":{
      "id": "I1007A",
      "num": 7,
      "type":2,
      "question": "Square Feet of Wide Format Materials Produced per Year",
      "caption": "#",
      "dataFormat":"#",
      "hint": "For background information only",
      "cols":[],
      "defaults":[]
    },

    "I1008":{
      "id": "I1008",
      "num": 8,
      "type":6,
      "question": "Does the Operation Produce Transactional Print (Bills, Statements, EOBs, etc.)",
      "caption": "#",
      "hint": "For background information only",
      "cols":[],
      "defaults":[]
    },
    "I1009":{
      "id": "I1009",
      "num": 9,
      "type":2,
      "question": "If Yes, What is the Monthly Volume for Transactional Print?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "For background information only",
      "cols":[],
      "defaults":[]
    },
    "I1010":{
      "id": "I1010",
      "num": 10,
      "type":2,
      "question": "Total Number of Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": null,
      "cols":[],
      "defaults":[],
      "enabled":[false],
      "calc":{"final":"$a + $b + $c + $d + $e + $f + $g + $h + $i + $j"},
      "calcVars":{"a":"I1010A.final","b":"I1011.final","c":"I1011A.final","d":"I1010C.final","e":"I1010D.final","f":"I1010E.final","g":"I1010F.final","h":"I1010G.final","i":"I1010H.final","j":"I1014.final", "k":"I1127A.final"}
    },

    "I1010A":{
      "id": "I1010A",
      "num": 10,
      "type":2,
      "question": "Number of Management Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$b"},
      "calcVars":{"a":"I1013.final", "b":"I1010.final"}
    },
    "I1010B":{
      "id": "I1010B",
      "num": 10,
      "type":2,
      "question": "Number of Administration Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$a"},
      "calcVars":{"a":"I1013.final"}
    },
    "I1010C":{
      "id": "I1010C",
      "num": 10,
      "type":2,
      "question": "Number of Copyright Clearance Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$b"},
      "calcVars":{"a":"I1013.final", "b":"I1010.final"}
    },
    "I1010D":{
      "id": "I1010D",
      "num": 10,
      "type":2,
      "question": "Number of Pre-Press Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$a, }$b"},
      "calcVars":{"a":"I1013.final", "b":"I1010.final"}
    },
    "I1010E":{
      "id": "I1010E",
      "num": 10,
      "type":2,
      "question": "Number of Production Print Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$a, }$b"},
      "calcVars":{"a":"I1013.final", "b":"I1010.final"}
    },
    "I1010F":{
      "id": "I1010F",
      "num": 10,
      "type":2,
      "question": "Number of Bindery/Finishing Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$a, }$b"},
      "calcVars":{"a":"I1013.final", "b":"I1010.final"}
    },
    "I1010G":{
      "id": "I1010G",
      "num": 10,
      "type":2,
      "question": "Number of Fulfillment/Kitting Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$a, }$b"},
      "calcVars":{"a":"I1013.final", "b":"I1010.final"}
    },
    "I1010H":{
      "id": "I1010H",
      "num": 10,
      "type":2,
      "question": "Number of Shipping/Receiving Employees",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": null,
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$b"},
      "calcVars":{"a":"I1013.final", "b":"I1010.final"}
    },

    "I1011":{
      "id": "I1011",
      "num": 11,
      "type":2,
      "question": "Number of Estimators",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": "This information is needed to determine administrative time savings with software solutions.",
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$b"},
      "calcVars":{"a":"I1013.final", "b":"I1010.final"}
    },
    "I1011A":{
      "id": "I1011A",
      "num": 11,
      "type":2,
      "question": "Number of Administrative Employees/Job Ticket Developers/CSRs",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": "This information is needed to determine administrative time savings with software solutions.",
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$b"},
      "calcVars":{"a":"I1013.final", "b":"I1010.final"}
    },
    "I1012":{
      "id": "I1012",
      "num": 12,
      "type":2,
      "question": "Number of Employees that Monitor Production Job Status",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": "Production Supervisors, Owners, Directors, Sales, Manager who Monitor Job Status.",
      "cols":[],
      "defaults":[]
    },
    "I1013":{
      "id": "I1013",
      "num": 13,
      "type":2,
      "question": "Number of Production Employees-Full Time",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": null,
      "calc":{"final":"$a + $b + $c + $d, }$e"},
      "calcVars":{"a":"I1010D.final", "b":"I1010E.final", "c":"I1010F.final", "d":"I1010G.final","e":"I1127A.final" },
      "cols":[],
      "defaults":[],
      "enabled":[false]
    },
    "I1014":{
      "id": "I1014",
      "num": 14,
      "type":2,
      "question": "Number of Sales People",
      "caption": "#",
      "dataFormat":"0.0",
      "hint": "This information is needed to determine the additional sales that can be attained when less time is spent tracking jobs.",
      "cols":[],
      "calc":{"final":"}$a"},
      "calcVars":{"a":"I1156.final"},
      "defaults":[]
    },
    "I1015":{
      "id": "I1015",
      "num": 15,
      "type":2,
      "question": "Annual Sales per Salesperson",
      "caption": "#",
      "dataFormat":"$",
      "hint": "This information is needed to determine the additional sales that can be attained when less time is spent tracking jobs.",
      "cols":[],
      "calc":{"final":"}$a"},
      "calcVars":{"a":"I1152.final"},
      "defaults":[]
    },
    "I1016":{
      "id": "I1016",
      "num": 16,
      "type":2,
      "question": "Budgeted Hourly Rate for Graphic Designers",
      "caption": "#",
      "dataFormat":"$",
      "precision": 2,
      "hint": "Rate Charged Includes Wages, Equipment/Software, Facility, Management Overhead - Only Needed if Evaluating Variable Data Printing",
      "cols":[],
      "defaults":[]
    },
    "I1017":{
      "id": "I1017",
      "num": 17,
      "type":2,
      "question": "Hourly Administration/Estimator/CSR Wage",
      "caption": "#",
      "dataFormat":"$",
      "precision": 2,
      "hint": "Hourly Rate (without benefits)",
      "cols":[],
      "defaults":[]
    },
    "I1018":{
      "id": "I1018",
      "num": 18,
      "type":2,
      "question": "Hourly Production Management/Supervisory Wage",
      "caption": "#",
      "dataFormat":"$",
      "precision": 2,
      "hint": "Hourly Rate (without benefits)",
      "cols":[],
      "defaults":[]
    },
    "I1019":{
      "id": "I1019",
      "num": 19,
      "type":2,
      "question": "What is your Burden Rate Percentage for Employee Benefits",
      "caption": "#",
      "dataFormat":"%",
      "hint": "Typical Burden/Benefit Rates are 22% w/o defined Pension Plan or 30% with",
      "cols":[],
      "defaults":[]
    },
    "I1020":{
      "id": "I1020",
      "num": 20,
      "type":2,
      "question": "What is your Discount Rate (%) for Analyzing Capital Projects",
      "caption": "#",
      "dataFormat":"%",
      "hint": "We typically use 10%",
      "cols":[],
      "defaults":[]
    },
    "I1021":{
      "id": "I1021",
      "num": 21,
      "type":2,
      "question": "Overtime (Total Annual $$$'s)",
      "caption": "#",
      "dataFormat":"$",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1022":{
      "id": "I1022",
      "num": 22,
      "type":2,
      "question": "Business Days in a Year",
      "caption": "#",
      "dataFormat":"#",
      "hint": "Standard is 255 days per year",
      "cols":[],
      "defaults":[]
    },
    "I1023":{
      "id": "I1023",
      "num": 23,
      "type":2,
      "question": "Business Days per Month",
      "caption": "#",
      "dataFormat":"#",
      "hint": "Standard is 21 days per month",
      "cols":[],
      "defaults":[],
      "calc":{"final":"}$a"},
      "calcVars":{"a":"I1149.MIS"}
    },
    "I1023A":{
      "id": "I1023A",
      "num": 23,
      "type":8,
      "question": "What Professional Associations do you belong to?",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["IPMA/In-Plant Printing and Mailing Association", "PIA/Printing Industries of America", "Printing United", "ACUP/Association of College and University Printers", "SUPDMC/Southeastern University Printing & Digital Managers Conference", "TACUP/Texas Association of College and University Printers","NPSOA/National Print and Sign Owners Association","Other"]

    },
    "I1023B":{
      "id": "I1023B",
      "num": 23,
      "type":8,
      "question": "What Print Publications do you subscribe to?",
      "caption": "#",
      "hint": null,
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "choices":["In-Plant Impressions", "Printing Impressions", "PrintWeek", "Print", "Graphic Arts Magazine", "Printing News", "Industrial Print", "American Printer", "What They Think/Printing News", "Print+Promo", "Publishing Executive", "Other"]
    },
    "I1024":{
      "id": "I1024",
      "num": 24,
      "type":3,
      "question": "The main products and services offered include ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1025":{
      "id": "I1025",
      "num":25 ,
      "type":3,
      "question": "The main customers of the production center include ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1025A":{
      "id": "I1025A",
      "num":25 ,
      "type":3,
      "question": "Digital production equipment includes:",
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "I1025B":{
      "id": "I1025B",
      "num":25 ,
      "type":3,
      "question": "Offset production equipment includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1025C":{
      "id": "I1025C",
      "num":25 ,
      "type":3,
      "question": "Finishing equipment includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1025D":{
      "id": "I1025D",
      "num":25 ,
      "type":3,
      "question": "Mailing equipment includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1025E":{
      "id": "I1025E",
      "num":25 ,
      "type":3,
      "question": "Organization software includes:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1025F":{
      "id": "I1025F",
      "num":25 ,
      "type":3,
      "question": "Plans for new equipment include:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1026":{
      "id": "I1026",
      "num":26 ,
      "type":3,
      "question": "The strengths of the operation include:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1027":{
      "id": "I1027",
      "num": 27,
      "type":3,
      "question": "Opportunities for improvement include:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1028":{
      "id": "I1028",
      "num":28 ,
      "type":3,
      "question": "Growth opportunities that are being considered are:",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1029":{
      "id": "I1029",
      "num":29 ,
      "type":5,
      "question": "Do you have internal or external IT resources?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices":["Internal","External Purchased"]
    },
    "I1030":{
      "id": "I1030",
      "num":30 ,
      "type":3,
      "question": "What is IT's role in new technology investments?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1031":{
      "id": "I1031",
      "num":31 ,
      "type":3,
      "question": "Estimates are received via ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1032":{
      "id": "I1032",
      "num":32 ,
      "type":3,
      "question": "Is there a web to print system in place? If yes, the following system is used ...",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "optional":true
    },
    "I1033":{
      "id": "I1033",
      "num":33 ,
      "type":5,
      "question": "How do you estimate a job (time and materials or price lists)?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices": ["time and materials", "price lists"]
    },
    "I1034":{
      "id": "I1034",
      "num":34 ,
      "type":5,
      "question": "Do you have a standard pricing schedule for repetitive work?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices": ["No", "Yes"]
    },
    "I1035":{
      "id": "I1035",
      "num":35 ,
      "type":3,
      "question": "Do you have a tiered pricing schedule and how is it managed (based on client)?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1036":{
      "id": "I1036",
      "num":36 ,
      "type":3,
      "question": "How do you estimate outsourced/purchased work?",
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[]
    },
    "I1037":{
      "id": "I1037",
      "num":37 ,
      "type":3,
      "question": "If a customer calls with a question on their estimate, a CSR will access it by ...",

      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "I1037A":{
      "id": "I1037A",
      "num":37 ,
      "type":3,
      "question": "Challenges and/or opportunities for estimating include ...",

      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "I1038": {
      "id": "I1038",
      "num": 38,
      "question": "How many Estimates are created Each Month for Offset Printing",
      "type": 4,
      "hint": "Estimates will be Reduced with an Online Storefront, 25% of Current can be used as an Estimate",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "dataFormat":"#",
      "enabled": [true,false,true],
      "calc":{"Current":"}$a,}$b","MIS":"$c","Storefront":"}$d"},
      "calcVars":{"a":".MIS","b":"I1041.Current","c":".Current","d":"I1041.Storefront"}
    },
    "I1039": {
      "id": "I1039",
      "num": 39,
      "question": "How many Estimates are created Each Month for Digital Printing",
      "type": 4,
      "hint": "Estimates will be Reduced with an Online Storefront, 25% of Current can be used as an Estimate",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "dataFormat":"#",
      "enabled": [true,false,true],
      "calc":{"Current":"}$a,}$b","MIS":"$c","Storefront":"}$d"},
      "calcVars":{"a":".MIS","b":"I1041.Current","c":".Current","d":"I1041.Storefront"}
    },
    "I1040": {
      "id": "I1040",
      "num": 40,
      "question": "How many Estimates are created Each Month for Large Format Printing",
      "type": 4,
      "hint": "Estimates will be Reduced with an Online Storefront, 25% of Current can be used as an Estimate",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "dataFormat":"#",
      "enabled": [true,false,true],
      "calc":{"Current":"}$a,}$b","MIS":"$c","Storefront":"}$d"},
      "calcVars":{"a":".MIS","b":"I1041.Current","c":".Current","d":"I1041.Storefront"}
    },
    "I1041": {
      "id": "I1041",
      "num": 41,
      "question": "Total Number of Estimates per Month",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "calc":{
        "Current":"$a + $b + $c, }$j,}$o, }$m",
        "MIS":"$d + $e + $f, }$k, }$p, }$n",
        "Storefront":"$g + $h + $i, }$l,}$q"},
      "defaults": [],
      "calcVars":{
        "a":"I1038.Current","b":"I1039.Current","c":"I1040.Current",
        "d":"I1038.MIS","e":"I1039.MIS","f":"I1040.MIS",
        "g":"I1038.Storefront","h":"I1039.Storefront","i":"I1040.Storefront",
        "j":"I1046.Current","k":"I1046.MIS", "l":"I1046.Storefront",
        "m":".MIS", "n":".Storefront",
        "o":"I1053.Current","p":"I1053.MIS","q":"I1053.Storefront"
      },
      "enabled":[false,false,false]
    },
    "I1042": {
      "id": "I1042",
      "num": 42,
      "question": "How many Minutes does it take to Develop an Offset Estimate?",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,5,0],
      "calc":{"Current":"}$c","MIS":"}$b","Storefront":"$a"},
      "calcVars":{"a":".MIS","b":".Storefront", "c":"I1044A.final"},
      "enabled":[true,true,false]
    },
    "I1043": {
      "id": "I1043",
      "num": 43,
      "question": "How many Minutes does it take to Develop a Digital Estimate?",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,5,0],
      "calc":{"Current":"}$c","MIS":"}$b","Storefront":"$a"},
      "calcVars":{"a":".MIS","b":".Storefront","c":"I1044A.final"},
      "enabled":[true,true,false]
    },
    "I1044": {
      "id": "I1044",
      "num": 44,
      "question": "How many Minutes does it take to Develop a Large Format Estimate?",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,5,0],
      "calc":{"Current":"}$c","MIS":"}$b","Storefront":"$a"},
      "calcVars":{"a":".MIS","b":".Storefront","c":"I1044A.final"},
      "enabled":[true,true,false]
    },

    "I1044A": {
      "id": "I1044A",
      "num": 46,
      "question": "Average minutes to produce each estimate",
      "type": 2,
      "hint": "Should be average of all estimate types",
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "calc":{"final":"($a + $b + $c)/3"},
      "calcVars":{"a":"I1042.Current","b":"I1043.Current","c":"I1044.Current" },
      "defaults": [0,0,0],
      "enabled":[false]
    },

    "I1045": {
      "id": "I1045",
      "num": 45,
      "question": "What percentage of estimates created are converted into jobs?",
      "type": 2,
      "hint": null,
      "caption": "",
      "dataFormat":"%",
      "cols": [],
      "defaults": [25],
      "calc":{"final":"}$a, }$b"},
      "calcVars":{"a":"I1053.Current","b":"I1053.Storefront"}
    },
    "I1046": {
      "id": "I1046",
      "num": 46,
      "question": "How many Quote Letters are produced each Month?",
      "type": 4,
      "hint": "Should Equal Total Number of Estimates per Month (Above)",
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "calc":{"Current":"$a","MIS":"$b","Storefront":"$c"},
      "calcVars":{"a":"I1041.Current","b":"I1041.MIS","c":"I1041.Storefront"},
      "defaults": [],
      "enabled":[false,false,false]
    },
    "I1047": {
      "id": "I1047",
      "num": 47,
      "question": "How many Minutes does it take to create a Quote Letter?",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "calc":{"MIS":"}$b","Storefront":"$a"},
      "calcVars":{"a":".MIS","b":".Storefront"},
      "defaults": [0,1,1],
      "enabled":[true,true,false]
    },

    "I1048": {
      "id": "I1048",
      "num": 48,
      "question": "Job tickets are developed by entering the information received into ...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1048A":{
      "id": "I1048A",
      "num":48 ,
      "type":3,
      "question": "Challenges and/or opportunities for Job Ticket Development include ...",

      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "I1048B": {
      "id": "I1048B",
      "num": 48,
      "question": "Orders are submitted via ...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1049": {
      "id": "I1049",
      "num": 49,
      "question": "How many Job Tickets are created Each Month from Online Storefront (all Job Details attached):",
      "type": 7,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "defaults": []
    },
    "I1050": {
      "id": "I1050",
      "num": 50,
      "question": "1) Production Jobs from Online Storefront",
      "type": 4,
      "hint": "Storefront = Total Jobs minus Fulfillment Only and any Remaining Manual Orders",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "dataFormat":"#",
      "enabled":[true,false,false],
      "calc":{"Current":"}$c,}$b","MIS":"$a","Storefront":"$c-($h + $i + $e + $f),}$g"},
      "calcVars":{"a":".Current","b":".MIS","c":"I1058.Current","d":"I1051.Storefront",
        "e":"I1053.Storefront","f":"I1055.Storefront",
        "g":"I1058.Storefront","h":"I1057.Current","i":"I1051.Current"
      }
    },
    "I1051": {
      "id": "I1051",
      "num": 51,
      "question": "2) Fulfillment Only from Online Storefront (already produced and in inventory)",
      "type": 4,
      "hint": "All Manual Fulfillment Orders (B74) would become Online Storefront Fulfillment Orders",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "dataFormat":"#",
      "enabled":[true,false,false],
      "calc":{"Current":"}$b,}$f,}$c,}$g","MIS":"$a","Storefront":"$d + $a"},
      "calcVars":{"a":".Current","b":".MIS","c":"I1058.Current","d":"I1057.Current","e":"I1051.Current","f":".Storefront", "g":"I1057A.final"}
    },
    "I1052": {
      "id": "I1052",
      "num": 52,
      "question": "How many Job Tickets are created Manually Each Month (from e-mail, phone, snail mail):",
      "type": 7,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "defaults": []
    },
    "I1053": {
      "id": "I1053",
      "num": 53,
      "question": "3) Where an Estimate is Developed and Converted to Job",
      "type": 4,
      "hint": "Current is calculated from % listed in \"percentage of estimates converted into jobs\" and totals in number of estimates per month",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "dataFormat":"#",
      "enabled":[false,false,false],
      "calc":{"Current":"$d * $g * 0.01,}$a","MIS":"$e * $g * 0.01,}$b","Storefront":"$f * $g * 0.01,}$c"},
      "calcVars":{
        "a":".MIS","b":".Storefront","c":"I1058.Current",
        "d":"I1041.Current","e":"I1041.MIS","f":"I1041.Storefront",
        "g":"I1045.final"}
    },
    "I1054": {
      "id": "I1054",
      "num": 54,
      "question": "4) No Estimate - Ordered by Customer with all Job Specs Identified ",
      "type": 4,
      "hint": "Jobs with all Specs Identified would be Processed through Digital Storefront ",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "dataFormat":"#",
      "calc":{"Current":"}$b,}$d","MIS":"$a","Storefront":"}$e"},
      "calcVars":{"a":".Current","b":".MIS", "c":"Storefront","d":"I1058.Current","e":"I1058.Storefront"},
      "enabled":[true,false,false]
    },
    "I1055": {
      "id": "I1055",
      "num": 55,
      "question": "5) No Estimate - Ordered by Customer - You Develop all Job Specs ",
      "type": 4,
      "hint": "There may still be a few Manual Orders with an Online Storefront",
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "calc":{"Current":"}$b,}$d","MIS":"$a","Storefront":"}$d"},
      "calcVars":{"a":".Current","b":".MIS", "c":"Storefront","d":"I1058.Current","e":"I1058.Storefront"},
      "enabled":[true,false,false]
    },
    "I1056": {
      "id": "I1056",
      "num": 56,
      "question": "6) No Estimate - Re-order (all production info in System from Previous Order)",
      "type": 4,
      "hint": "Re-Order Jobs would all be Processed through Digital Storefront ",
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "calc":{"Current":"}$b,}$d","MIS":"$a","Storefront":"}$e"},
      "calcVars":{"a":".Current","b":".MIS", "c":"Storefront","d":"I1058.Current","e":"I1058.Storefront"},
      "enabled":[true,false,false]
    },
    "I1057": {
      "id": "I1057",
      "num": 57,
      "question": "7) No Estimate - Fulfillment Item (already produced and in inventory)",
      "type": 4,
      "hint": "Fulfillment Orders would all be Processed through Digital Storefront",
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "calc":{"Current":"}$b,}$f,}$d,}$g","MIS":"$a","Storefront":"}$e"},
      "calcVars":{"a":".Current","b":".MIS", "c":"Storefront","d":"I1058.Current","e":"I1058.Storefront","f":"I1051.Storefront", "g":"I1057A.final"},
      "enabled":[true,false,false]
    },

    "I1057A": {
      "id": "I1057A",
      "num": 46,
      "question": "Total jobs processed for kitting and fulfillment monthly",
      "type": 2,
      "hint": "I1057 + I1051",
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "calc":{"final":"$a + $b"},
      "calcVars":{"a":"I1051.Current","b":"I1057.Current"},
      "defaults": [0,0,0],
      "enabled":[false]
    },



    "I1058": {
      "id": "I1058",
      "num": 58,
      "question": "Total Number of Jobs Produced per Month",
      "type": 4,
      "hint": "These Total Numbers should be the same for All 3 Columns",
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "enabled":[false,false,false],
      "calc":{"Current":"$a + $b + $c + $d + $e + $f + $g, }$j, }$r, }$i","MIS":"$h","Storefront":"$k + $l + $m + $n + $o + $p + $q, }$s"},
      "calcVars":{
        "a":"I1050.Current","b":"I1051.Current","c":"I1053.Current","d":"I1054.Current",
        "e":"I1055.Current","f":"I1056.Current","g":"I1057.Current","h":".Current",
        "i":"I1050.Storefront","j":".MIS",
        "k":"I1050.Storefront","l":"I1051.Storefront","m":"I1053.Storefront","n":"I1054.Storefront",
        "o":"I1055.Storefront","p":"I1056.Storefront","q":"I1057.Storefront",
        "r":"I1059.Current","s":"I1059.Storefront"
      },
      "calcTriggers":["I1059"]
    },
    "I1059": {
      "id": "I1059",
      "num": 59,
      "question": "Yearly Total",
      "type": 4,
      "hint": "Yearly Total (should be similar to Total Number from Organization Statistics above)",
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "enabled":[false,false,false],
      "calc":{"Current":"$a * 12, }$e","MIS":"$d","Storefront":"$c * 12"},
      "calcVars":{"a":"I1058.Current","b":"I1058.MIS","c":"I1058.Storefront","d":".Current","e":".MIS"}
    },
    "I1060": {
      "id": "I1060",
      "num": 60,
      "question": "How many Minutes does it take to create a Job Ticket:",
      "type": 7,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "defaults": []
    },
    "I1061": {
      "id": "I1061",
      "num": 61,
      "question": "1) Online Storefront Orders - Production",
      "type": 4,
      "hint": "If Online Storefront Orders Do Not Interface with Print MIS, Orders are Manual",
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,5,5],
      "calc":{"Current":"}$b, }$c, }$d","MIS":"$a","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront","d":"I1067A.final"},
      "enabled":[true,false,false]
    },
    "I1062": {
      "id": "I1062",
      "num": 62,
      "question": "2) Online Storefront Orders - Fulfillment Only",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,5,5],
      "calc":{"Current":"}$b, }$c, }$d","MIS":"$a","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront","d":"I1067A.final"},
      "enabled":[true,false,false]
    },
    "I1063": {
      "id": "I1063",
      "num": 63,
      "question": "3) By Converting an Estimate to a Job",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,10,10],
      "calc":{"Current":"}$c, }$d","MIS":"$a","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront","d":"I1067A.final"},
      "enabled":[true,true,false]
    },
    "I1064": {
      "id": "I1064",
      "num": 64,
      "question": "4) Manual - Customer Order with all Job Specs Provided",
      "type": 4,
      "hint": null,
      "dataFormat":"#",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,10,15],
      "calc":{"Current":"}$c, }$d","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront","d":"I1067A.final"},
      "enabled":[true,true,false]
    },
    "I1065": {
      "id": "I1065",
      "num": 65,
      "question": "5) Manual - Order Developed from Minimal Job Specs",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,15,30],
      "calc":{"Current":"}$c, }$d, }$e","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront", "d":"I1067A.final","e":"V1003.Current"},
      "enabled":[true,true,false]
    },
    "I1066": {
      "id": "I1066",
      "num": 66,
      "question": "6) Manual - Re-Order (all production info in System from Previous Order)",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,10,10],
      "calc":{"Current":"}$b, }$c, }$d","MIS":"$a","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront","d":"I1067A.final"},
      "enabled":[true,false,false]
    },
    "I1067": {
      "id": "I1067",
      "num": 67,
      "question": "7) Manual - Fulfillment Orders (already produced and in inventory)",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,10,10],
      "calc":{"Current":"}$b, }$c, }$d","MIS":"$a","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront","d":"I1067A.final"},
      "enabled":[true,false,false]
    },

    "I1067A": {
      "id": "I1067A",
      "num": 46,
      "question": "Average minutes to develop each job ticket",
      "type": 2,
      "hint": "Should be average of all job ticket types",
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "calc":{"final":"(($a + $b + $c + $d + $e + $f + $g ) / 7.0)"},
      "calcVars":{"a":"I1061.Current","b":"I1062.Current","c":"I1063.Current", "d":"I1064.Current","e":"I1065.Current","f":"I1066.Current","g":"I1067.Current"},
      "defaults": [0,0,0],
      "enabled":[false]
    },

    "I1068": {
      "id": "I1068",
      "num": 68,
      "question": "How many Inventory Items Do You Manage that are for Print Production (Paper, Plates, etc.)",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "dataFormat":"#",
      "enabled":[true,false,false],
      "calc":{"Current":"}$b,}$c","MIS":"$a","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1069": {
      "id": "I1069",
      "num": 69,
      "question": "How many Inventory Items Do You Manage for Fulfillment Items",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "dataFormat":"#",
      "enabled":[true,false,false],
      "calc":{"Current":"}$b,}$c","MIS":"$a","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1070": {
      "id": "I1070",
      "num": 70,
      "question": "How many Minutes do you Spend Managing All of Your Inventory Items per Month (Count, Adjust, Allocate)",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,1,1],
      "enabled":[true,true,true]
    },
    "I1071": {
      "id": "I1071",
      "num": 71,
      "question": "Material inventory is managed by ...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1072": {
      "id": "I1072",
      "num": 72,
      "question": "How do you generate, manage and reconcile POs?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1073": {
      "id": "I1073",
      "num": 73,
      "question": "How easy is it to check inventory levels for any particular item?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1074": {
      "id": "I1074",
      "num": 74,
      "question": "Do you have automatic triggers for inventory re-stock/re-orders?",
      "type": 5,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": [],
      "choices": ["No", "Yes"]
    },
    "I1075": {
      "id": "I1075",
      "num": 75,
      "question": "Do you carry finished goods inventory?",
      "type": 5,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": [],
      "choices": ["No", "Yes"]
    },
    "I1076": {
      "id": "I1076",
      "num": 76,
      "question": " - It is managed by...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1077": {
      "id": "I1077",
      "num": 77,
      "question": " - The pick and pack process is accomplished by...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1078": {
      "id": "I1078",
      "num": 78,
      "question": "Do you verify inventory on hand in order to make a due date?",
      "type": 5,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": [],
      "choices": ["No", "Yes"]
    },
    "I1079": {
      "id": "I1079",
      "num": 79,
      "question": " - When do you allocate inventory? (at time of estimate, job ticket or when materials are pulled for job?) ...",
      "type": 8,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": [],
      "selectOne": true,
      "choices": [
          "at time of estimate",
          "job ticket",
          "when materials are pulled for job"
      ]
    },
    "I1080": {
      "id": "I1080",
      "num": 80,
      "question": " - How do you handle POs for inventory?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1081": {
      "id": "I1081",
      "num": 81,
      "question": "Pricing for inventory items is managed by ...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1082": {
      "id": "I1082",
      "num": 82,
      "question": " - Process for handling pricing updates ...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1083": {
      "id": "I1083",
      "num": 83,
      "question": " - What if it is a non-inventory specialty material?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1083A":{
      "id": "I1083A",
      "num":83 ,
      "type":3,
      "question": "Challenges and/or opportunities for Inventory Management include ...",

      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "I1084": {
      "id": "I1084",
      "num": 84,
      "question": "How many Minutes does it take to Create a PO",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,false],
      "defaults": [0,2,0],
      "dataFormat":"#",
      "calc":{"Current":"}$c","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}

    },
    "I1084A": {
      "id": "I1084A",
      "num": 84,
      "question": "Describe how purchase orders are developed and how they are reconciled and processed to accounts payable.",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]

    },
    "I1085": {
      "id": "I1085",
      "num": 85,
      "question": "How many Minutes does it take to Reconcile a PO",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,false],
      "defaults": [0,2,0],
      "dataFormat":"#",
      "calc":{"Current":"}$c","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1086": {
      "id": "I1086",
      "num": 86,
      "question": "How many POs do you Create per Month",
      "type": 4,
      "hint": "Review # of Jobs per Month, An Estimate May be 1 Per Job (Include both Material and Services Purchases)",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,false,false],
      "defaults": [0,0,0],
      "dataFormat":"#",
      "calc":{"Current":"}$b, }$c","MIS":"$a","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1087": {
      "id": "I1087",
      "num": 87,
      "question": "How many Minutes does it take to Process an Invoice",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,false],
      "defaults": [0,3,0],
      "dataFormat":"#",
      "calc":{"Current":"}$c","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1088": {
      "id": "I1088",
      "num": 88,
      "question": "What $ Value of Work Purchased Could be Produced Internally with VDP / Online Storefront Capability",
      "type": 4,
      "hint": "Consider Business Cards, other Variable Data Materials",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [false,false,true],
      "defaults": [0,0,0],
      "dataFormat":"$",
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1089": {
      "id": "I1089",
      "num": 89,
      "question": "Comparing Internally Produced vs. External Purchased Printed Materials What is the % Cost Savings",
      "type": 4,
      "hint": "Refer to Materials Listed in Row 108",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,false,false],
      "defaults": [0,0,0],
      "dataFormat":"%",
      "calc":{"Current":"}$b,}$c","MIS":"$a","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1090": {
      "id": "I1090",
      "num": 90,
      "question": "The production scheduling process is ...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1091": {
      "id": "I1091",
      "num": 91,
      "question": "Does both production and management have visibility of the production schedule?",
      "type": 6,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1092": {
      "id": "I1092",
      "num": 92,
      "question": "What is your visibility into a job's status? The jobs' status is visible to ...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1093": {
      "id": "I1093",
      "num": 93,
      "question": "What solutions/applications do you use to manage your job workflow?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1094": {
      "id": "I1094",
      "num": 94,
      "question": "Job change orders and author alterations are tracked by ...",
      "type": 3,
      "hint": "If they are not tracked, leave blank.",
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1095": {
      "id": "I1095",
      "num": 95,
      "question": "Do you charge for change orders?",
      "type": 5,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": [],
      "choices": ["No", "Yes"]
    },
    "I1095A": {
      "id": "I1095A",
      "num": 95,
      "question": "Average Cost of Billable Changes per Job",
      "type": 2,
      "hint": null,
      "caption": "",
      "dataFormat":"$",
      "cols": [],
      "defaults": []
    },
    "I1096": {
      "id": "I1096",
      "num": 96,
      "question": "How many Minutes does each Admin/CSR spend Tracking Jobs per Day",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,15,0],
      "dataFormat":"#",
      "enabled":[true,true,false],
      "calc":{"Current":"}$c","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1097": {
      "id": "I1097",
      "num": 97,
      "question": "How many Minutes does each Production Manager/Supervisor/Sales spend Tracking Jobs per Day",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,15,0],
      "dataFormat":"#",
      "enabled":[true,true,false],
      "calc":{"Current":"}$c,}$d","MIS":"}$d","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront","d":"I1149.MIS"}
    },
    "I1098": {
      "id": "I1098",
      "num": 98,
      "question": "How many Minutes per Day are Production Meetings",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,30,0],
      "dataFormat":"#",
      "enabled":[true,true,false],
      "calc":{"Current":"}$c","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1099": {
      "id": "I1099",
      "num": 99,
      "question": "# of Employees in Production Meetings",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,0,0],
      "dataFormat":"#",
      "enabled":[true,true,false],
      "calc":{"Current":"}$c","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1100": {
      "id": "I1100",
      "num": 100,
      "question": "The process for preflighting files and prepping for production includes...",
      "type": 3,
      "hint": "Describe the information that accompanies an order. Include info on how employees account for their time and provide info for costing that is included on invoices/chargebacks.",
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1101": {
      "id": "I1101",
      "num": 101,
      "question": "Production employees receive information regarding new job tickets from...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1102": {
      "id": "I1102",
      "num": 102,
      "question": "The shop floor is notified of a project to plan their workload by ...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1103": {
      "id": "I1103",
      "num": 103,
      "question": "How do you manage outsourced pieces of the job?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1104": {
      "id": "I1104",
      "num": 104,
      "question": "What software is used to track jobs?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1104A": {
      "id": "I1104A",
      "num": 104,
      "question": "Is production labor tracked against each job?",
      "type": 5,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": [],
      "choices": ["No", "Yes"]
    },
    "I1104B": {
      "id": "I1104B",
      "num": 104,
      "question": "Production labor is entered by...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1105": {
      "id": "I1105",
      "num": 105,
      "question": "How do you track job change orders and author alterations?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1105A":{
      "id": "I1105A",
      "num":105 ,
      "type":3,
      "question": "Identify any challenges and/or opportunities in your area of responsibility -",

      "hint": null,
      "caption": "",
      "cols":[],
      "defaults":[]
    },
    "I1106": {
      "id": "I1106",
      "num": 106,
      "question": "Are the additional costs for job changes charged back to the customer?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []

    },
    "I1107": {
      "id": "I1107",
      "num": 107,
      "question": "# of Jobs with Billable Changes per Month",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,0,0],
      "enabled":[true,false,false],
      "dataFormat":"#",
      "calc":{"Current":"}$b, }$c","MIS":"$a","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1108": {
      "id": "I1108",
      "num": 108,
      "question": "Average Cost of Billable Changes per Job",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,0,0],
      "enabled":[true,false,false],
      "dataFormat":"$",
      "calc":{"Current":"}$b, }$c","MIS":"$a","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1109": {
      "id": "I1109",
      "num": 109,
      "question": "# of Jobs where Billable Changes are Missed when Invoicing each Month",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,0,0],
      "enabled":[true,true,false],
      "dataFormat":"#",
      "calc":{"Current":"}$c","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1110": {
      "id": "I1110",
      "num": 110,
      "question": "Cost of Rework/Errors on Projects per Month Due to Mis-Communication   (Include Quote Errors, Job Ticket Errors, Production Errors)",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,0,0],
      "enabled":[true,false,false],
      "dataFormat":"$",
      "calc":{"Current":"}$b","MIS":"$a - ($a * $d * 0.01), }$c","Storefront":"$b"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront", "d":"I1112.MIS"}
    },
    "I1111": {
      "id": "I1111",
      "num": 111,
      "question": "Cost of Rework Errors Due to Mistake in Print File Contents - Misspellings, etc.",
      "type": 4,
      "hint": "Error Burden that You Take Responsibility for and Don't Charge Customers",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,0,0],
      "dataFormat":"$",
      "enabled":[true,false,true],
      "calc":{"Current":"}$b","MIS":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1112": {
      "id": "I1112",
      "num": 112,
      "question": "Estimated % Reduction in Errors with Improved/Accurate/Consistent Information in Software",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,75,0],
      "dataFormat":"%",
      "enabled":[false,true,false],
      "calc":{"MIS":"}$a"},
      "calcVars":{"a":"I1110.MIS"}
    },
    "I1113": {
      "id": "I1113",
      "num": 113,
      "question": "How many Minutes are spent Tracking a UPS/FedEx Package per Job?",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,1,0],
      "dataFormat":"#",
      "enabled":[true,true,false],
      "calc":{"MIS":"}$c","Storefront":"$b"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}

    },
    "I1114": {
      "id": "I1114",
      "num": 114,
      "question": "% of Jobs that Require UPS/FedEx Tracking?",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,1,0],
      "dataFormat":"%",
      "enabled":[true,true,false],
      "calc":{"MIS":"}$c","Storefront":"$b"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1115": {
      "id": "I1115",
      "num": 115,
      "question": "How many Minutes are spent Creating Shipping Docs per Job?",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,1,0],
      "dataFormat":"#",
      "enabled":[true,true,false],
      "calc":{"MIS":"}$c","Storefront":"$b"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1116": {
      "id": "I1116",
      "num": 116,
      "question": "# of Jobs with Missed Shipping Costs per Month",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,0,0],
      "dataFormat":"#",
      "enabled":[true,true,false],
      "calc":{"MIS":"}$c","Storefront":"$b"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1117": {
      "id": "I1117",
      "num": 117,
      "question": "Average Shipping Cost per Job",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,0,0],
      "dataFormat":"$",
      "enabled":[true,true,false],
      "calc":{"MIS":"}$c","Storefront":"$b"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1118": {
      "id": "I1118",
      "num": 118,
      "question": "The billing/invoicing process includes ...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1119": {
      "id": "I1119",
      "num": 119,
      "question": "Jobs are invoiced on the following intervals ...",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1120": {
      "id": "I1120",
      "num": 120,
      "question": "Do you need to enter the invoice information into any other systems?",
      "type": 6,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1121": {
      "id": "I1121",
      "num": 121,
      "question": "How are taxes calculated?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1122": {
      "id": "I1122",
      "num": 122,
      "question": "What system do you use to manage receivables?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1123": {
      "id": "I1123",
      "num": 123,
      "question": "How Many Days After Project Completion Do You Invoice Jobs",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,1,0],
      "dataFormat":"#",
      "enabled":[true,true,true]
    },
    "I1124": {
      "id": "I1124",
      "num": 124,
      "question": "Generally, How Many Days Does it Take to Receive Payment After Your Customers are Invoiced",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,30,0],
      "dataFormat":"#",
      "enabled":[true,true,true]
    },
    "I1125": {
      "id": "I1125",
      "num": 125,
      "question": "How many Minutes does it take to Create an Invoice per Job",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,1,0],
      "dataFormat":"#",
      "enabled":[true,true,true]
    },
    "I1126": {
      "id": "I1126",
      "num": 126,
      "question": "How many Minutes does it take to Log Billing Info into an Accounting System per Job",
      "type": 4,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,1,0],
      "dataFormat":"#",
      "enabled":[true,true,true]
    },
    "I1127": {
      "id": "I1127",
      "num": 127,
      "question": "How many Minutes does it take to Track Job Labor per Employee per Day?",
      "type": 4,
      "hint": "Used to calculate time spent tracking labor per day",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [0,0,0],
      "dataFormat":"#",
      "enabled":[true,true,false],
      "calc":{"Current":"}$c","MIS":"}$c","Storefront":"$a"},
      "calcVars":{"a":".MIS","b":".Storefront", "c":"I1127A.final"}
    },

    "I1127A": {
      "id": "I1127A",
      "num": 46,
      "question": "Minutes spent tracking labor per day",
      "type": 2,
      "hint": "Minutes to track job labor per day per employee X total employees",
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "calc":{"final":"$a * $b"},
      "calcVars":{"a":"I1127.Current","b":"I1013.final"},
      "defaults": [0,0,0],
      "enabled":[false]
    },

    "I1128": {
      "id": "I1128",
      "num": 128,
      "question": "What types of reports do you regularly create? Check to activate and enter times for each report type below:",
      "type": 7,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1129": {
      "id": "I1129",
      "num": 129,
      "question": "What is the process for generating those reports?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1130": {
      "id": "I1130",
      "num": 130,
      "question": "How much real time visibility do you have to reports or are they generated at set intervals?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1131": {
      "id": "I1131",
      "num": 131,
      "question": "What is your process for evaluating the profitability of your jobs?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1132": {
      "id": "I1132",
      "num": 132,
      "question": "Can you segment profitability reports by job type?",
      "type": 5,
      "caption": "",
      "hint": null,
      "cols":[],
      "defaults":[],
      "choices": ["No", "Yes"]
    },
    "I1133": {
      "id": "I1133",
      "num": 133,
      "question": "How do you create a new custom report?",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1134": {
      "id": "I1134",
      "num": 134,
      "question": "Sales by Customer (Hours per Month)",
      "type": 4,

      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,true],
      "defaults": [0,0.1,0],
      "dataFormat":"0.0",
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"},
      "optional":true
    },
    "I1135": {
      "id": "I1135",
      "num": 135,
      "question": "Actual vs. Estimate (Hours per Month)",
      "type": 4,

      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,true],
      "defaults": [0,0.1,0.1],
      "dataFormat":"0.0",
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"},
      "optional":true
    },
    "I1136": {
      "id": "I1136",
      "num": 136,
      "question": "Customer Profitability (Hours per Month)",
      "type": 4,

      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,true],
      "defaults": [0,0.1,0.1],
      "dataFormat":"0.0",
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"},
      "optional":true
    },
    "I1137": {
      "id": "I1137",
      "num": 137,
      "question": "Product Line Profitability (Hours per Month)",
      "type": 4,

      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,true],
      "defaults": [0,0.1,0.1],
      "dataFormat":"0.0",
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"},
      "optional":true
    },
    "I1138": {
      "id": "I1138",
      "num": 138,
      "question": "Weekly Sales (Hours per Month)",
      "type": 4,

      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,true],
      "defaults": [0,0.1,0.1],
      "dataFormat":"0.0",
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"},
      "optional":true
    },
    "I1139": {
      "id": "I1139",
      "num": 139,
      "question": "Monthly Sales (Hours per Month)",
      "type": 4,

      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,true],
      "defaults": [0,0.2,0.2],
      "dataFormat":"0.0",
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"},
      "optional":true
    },
    "I1140": {
      "id": "I1140",
      "num": 140,
      "question": "Jobs Shipped and not Invoiced (Hours per Month)",
      "type": 4,
      "hint": "Enter NA if this Report is Not currently produced",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,true],
      "defaults": [0,0.2,0.2],
      "dataFormat":"0.0",
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"},
      "optional":true
    },
    "I1141": {
      "id": "I1141",
      "num": 141,
      "question": "Employee Productivity (Hours per Month)",
      "type": 4,
      "hint": "Enter NA if this Report is Not currently produced",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,true],
      "defaults": [0,0.2,0.2],
      "dataFormat":"0.0",
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"},
      "optional":true
    },
    "I1142": {
      "id": "I1142",
      "num": 142,
      "question": "Real Time Schedule Report (Hours per Month)",
      "type": 4,
      "hint": "Enter NA if this Report is Not currently produced",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,true],
      "defaults": [0,0.5,0.5],
      "dataFormat":"0.0",
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"},
      "optional":true
    },
    "I1143": {
      "id": "I1143",
      "num": 143,
      "question": "Payroll (Hours per Month)",
      "type": 4,
      "hint": "Enter NA if this Report is Not currently produced",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,true,true],
      "defaults": [0,0.2,0.2],
      "dataFormat":"0.0",
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"},
      "optional":true
    },
    "I1144": {
      "id": "I1144",
      "num": 144,
      "question": "List Opportunity for Cost Savings Here Not Identified Above",
      "type": 3,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1145": {
      "id": "I1145",
      "num": 145,
      "question": "Info from Customer - List Area of Savings Opportunity (Hours per month)",
      "type": 4,
      "hint": "List Current Hours Per Month and Estimated Hours Per Month with Print MIS or Digital Storefront",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "defaults": [],
      "dataFormat":"#"
    },
    "I1146": {
      "id": "I1146",
      "num": 146,
      "question": "CSR Function? Check for Yes.",
      "type": 6,
      "hint": "Turn on if CSR Function, leave off if Not",
      "caption": "",
      "cols": [],
      "defaults": [],
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1147": {
      "id": "I1147",
      "num": 147,
      "question": "Management Function? (Check for Yes)",
      "type": 6,
      "hint": "Turn on if Management Function, leave off if Not",
      "caption": "",
      "cols": [],
      "defaults": [],
      "calc":{},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront"}
    },
    "I1148": {
      "id": "I1148",
      "num": 148,
      "question": "Choose One of Two Ways Below to Calculate Additional Sales - DO NOT Enter Info Into Both Options:",
      "type": 7,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": []
    },
    "I1149": {
      "id": "I1149",
      "num": 149,
      "question": "Option 1: How many Hours per Month does each Sales Person Devote to Selling",
      "type": 2,
      "hint": "168 Available Hours in One Month, Sales Hours Will Increase Based on Less Time Spent Job Tracking",
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,false,false],
      "defaults": [],
      "dataFormat":"#",
      "calc":{"Current":"}$b, }$g","MIS":"$a + ((($d-$e)/60)*$f),}$c","Storefront":"$b"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront","d":"I1097.Current","e":"I1097.MIS","f":"I1023.final","g":"I1153.final"}
    },
    "I1150": {
      "id": "I1150",
      "num": 150,
      "question": "Option 1: What % of Additional Hrs. Available to Sales from less Job Tracking will be Devoted to Sales",
      "type": 2,
      "hint": null,
      "caption": "",
      "cols": ["Current","MIS","Storefront"],
      "enabled": [true,false,false],
      "defaults": [],
      "dataFormat":"%",
      "calc":{"Current":"}$b, }$c, }$d","MIS":"$a","Storefront":"$a"},
      "calcVars":{"a":".Current","b":".MIS","c":".Storefront", "d":"I1155.final"}
    },
    "I1151": {
      "id": "I1151",
      "num": 151,
      "question": "Option 2: What % Increase in Sales Do You Feel You Could Gain from less Job Tracking by Sales",
      "type": 2,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": [],
      "dataFormat":"%",
      "calc":{"final":"}$a"},
      "calcVars":{"a":"I1157.final"}
    },
    "I1152": {
      "id": "I1152",
      "num": 152,
      "question": "Current Sales per Sales Person (from Interview Above)",
      "type": 2,
      "hint": "The information will auto populate from information above and will be used to calculate additional sales opportunity.",
      "caption": "",
      "cols": [],
      "defaults": [],
      "enabled":[false],
      "dataFormat":"$",
      "calc":{"final":"$a, }$b"},
      "calcVars":{"a":"I1015.final","b":"I1154.final","c":""}

    },
    "I1153": {
      "id": "I1153",
      "num": 153,
      "question": "Hours Devoted to Sales in One Year",
      "type": 2,
      "hint": "The information will auto populate from information above and will be used to calculate additional sales opportunity.",
      "caption": "",
      "cols": [],
      "defaults": [],
      "dataFormat":"#",
      "enabled":[false],
      "calc":{"final":"$a * 12, }$b"},
      "calcVars":{"a":"I1149.Current","b":"I1154.final","c":""}

    },
    "I1154": {
      "id": "I1154",
      "num": 154,
      "question": "Sales per Hour",
      "type": 2,
      "hint": "The information will auto populate from information above and will be used to calculate additional sales opportunity.",
      "caption": "",
      "cols": [],
      "defaults": [],
      "dataFormat":"$",
      "enabled":[false],
      "calc":{"final":"$a / $b, }$c"},
      "calcVars":{"a":"I1152.final","b":"I1153.final","c":"I1155.final"}

    },
    "I1155": {
      "id": "I1155",
      "num": 155,
      "question": "Sales Growth per Sales Person with Additional Hours to Devote to Sales/Less Job Tracking",
      "type": 2,
      "hint": "The information will auto populate from information above and will be used to calculate additional sales opportunity.",
      "caption": "",
      "cols": [],
      "defaults": [],
      "dataFormat":"$",
      "enabled":[false],
      "calc":{"final":"($a * ($c - $b) * 12) * ($d * 0.01), }$e"},
      "calcVars":{"a":"I1154.final","b":"I1149.Current","c":"I1149.MIS","d":"I1150.Current", "e":"I1156.final"}

    },
    "I1156": {
      "id": "I1156",
      "num": 156,
      "question": "Option 1 Calculation for Total Sales Growth",
      "type": 2,
      "hint": "The information will auto populate from information above and will be used to calculate additional sales opportunity.",
      "caption": "",
      "cols": [],
      "defaults": [],
      "enabled":[false],
      "dataFormat":"$",
      "calc":{"final":"$a * $b"},
      "calcVars":{"a":"I1155.final","b":"I1014.final","c":""}

    },
    "I1157": {
      "id": "I1157",
      "num": 157,
      "question": "Option 2 Calculation for Total Sales Growth",
      "type": 2,
      "hint": "The information will auto populate from information above and will be used to calculate additional sales opportunity.",
      "caption": "",
      "cols": [],
      "defaults": [],
      "dataFormat":"$",
      "enabled":[false],
      "calc":{"final":"$a * 0.01 * $b"},
      "calcVars":{"a":"I1151.final","b":"I1003.final","c":""}

    },
    "V1009": {
      "id": "V1009",
      "num": 60,
      "question": "How many Minutes does it take to prepare files for Print Production:",
      "type": 7,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "defaults": []
    },
    "V1010": {
      "id": "V1010",
      "num": 1,
      "question": "How many minutes does it take to Pre-Flight a file (Review Content, Add Bleeds, Make Minor Changes)",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current", "Automation"],
      "defaults": [0,0],
      "calc":{},
      "calcVars":{}

    },
    "V1011": {
      "id": "V1011",
      "num": 1,
      "question": "How many minutes does it take to Impose a file",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current", "Automation"],
      "defaults": [0,0],
      "calc":{},
      "calcVars":{}

    },
    "V1012": {
      "id": "V1012",
      "num": 1,
      "question": "Personalize/Variable Data File Development",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": [],
      "defaults": [0],
      "calc":{},
      "calcVars":{}

    },
    "V1013": {
      "id": "V1013",
      "num": 60,
      "question": "Provide details for Variable Data/Personalization Projects:",
      "type": 7,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "defaults": []

    },
    "V1000": {
      "id": "V1000",
      "num": 1,
      "question": "What Types of Jobs Contain Variable Data/Personalization?",
      "type": 2,
      "hint": null,
      "caption": "",
      "cols": [],
      "defaults": ["Business Cards"],
      "calc":{},
      "calcVars":{}

    },
    "V1001": {
      "id": "V1001",
      "num": 1,
      "question": "Number of Orders per Month for Variable Data Project",
      "type": 2,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "defaults": [],
      "calc":{},
      "calcVars":{}

    },
    "V1002": {
      "id": "V1002",
      "num": 1,
      "question": "Minutes to Develop/Design Base Template",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current","VDP"],
      "defaults": [30,30],
      "calc":{},
      "calcVars":{}

    },
    "V1003": {
      "id": "V1003",
      "num": 1,
      "question": "Minutes to Create a Job Ticket/Work Order for VDP Project",
      "type": 4,
      "hint": "Current value is captured from Job Ticket Minutes for Manual Order with Minimal Job Specs (I1065)",
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current","VDP"],
      "defaults": [0,5],
      "calc":{"Current":"$a"},
      "calcVars":{"a":"I1065.Current"},
      "enabled":[false,true]

    },
    "V1004": {
      "id": "V1004",
      "num": 1,
      "question": "Minutes to Customize Document with Variable Data",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current","VDP"],
      "defaults": [0,2],
      "calc":{},
      "calcVars":{}

    },
    "V1005": {
      "id": "V1005",
      "num": 1,
      "question": "Minutes Required to Send a Proof and Make Any Required Changes",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current","VDP"],
      "defaults": [0,2],
      "calc":{},
      "calcVars":{}

    },
    "V1006": {
      "id": "V1006",
      "num": 1,
      "question": "Minutes to Process File for Production once Approved",
      "type": 4,
      "hint": null,
      "caption": "",
      "dataFormat":"0.0",
      "cols": ["Current","VDP"],
      "defaults": [0,5],
      "calc":{},
      "calcVars":{}

    },
    "V1007": {
      "id": "V1007",
      "num": 1,
      "question": "Average Number of Reworks for VDP Projects per Month",
      "type": 2,
      "hint": null,
      "caption": "",
      "dataFormat":"#",
      "cols": [],
      "defaults": [],
      "calc":{},
      "calcVars":{}

    },
    "V1008": {
      "id": "V1008",
      "num": 1,
      "question": "Average Cost per VDP Project",
      "type": 2,
      "hint": null,
      "caption": "",
      "dataFormat":"$",
      "cols": [],
      "defaults": [],
      "calc":{},
      "calcVars":{}

    }

  },

  "survey":{
    "S001":{
      "id": "S001",
      "num": 1,
      "type":1,
      "question": "Quote Requests",
      "caption": "Where do estimate/print requests come from?",
      "criteria5": "Quote requests, job specs, or print files are submitted via a commercial or homegrown electronic submission tool.",
      "criteria4": "Quote requests, job specs, or print files are submitted via a home grown electronic submission tool.",
      "criteria3": "Sales rep gets quote request from customer and submits to estimator.",
      "criteria2": "Requests are submitted through a form on company website.",
      "criteria1": "Requests are submitted via email, phone or fax.",
      "criteriaNA": "Fixed price list or no charge back for printing.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S002":{
      "id": "S002",
      "num": 2,
      "type":1,
      "question": "Estimating",
      "caption": "How do you create estimates/price quotes?",
      "criteria5": "Integrated system to enter job specs and provide a cost plus markup estimate.",
      "criteria4": "Stand alone system to enter job specs and provide a cost plus markup estimate.",
      "criteria3": "Fixed price list to quote jobs.",
      "criteria2": "Standard spreadsheet for estimating.",
      "criteria1": "Manual estimates based on estimator knowledge.",
      "criteriaNA": "Estimates not provided prior to production.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S003":{
      "id": "S003",
      "num": 3,
      "type":1,
      "question": "Quoting",
      "caption": "How are quotes presented to the customer?",
      "criteria5": "Web based system for real time price quoting based on job specs.",
      "criteria4": "Integrated system that generates quote letter based on template.",
      "criteria3": "Standard letter form to manually enter pricing info.",
      "criteria2": "Sales rep/estimator manually types quote letter.",
      "criteria1": "Quotes are phoned or emailed to customer.",
      "criteriaNA": "Pricing is not provided to the customer prior to production.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S004":{
      "id": "S004",
      "num": 4,
      "type":1,
      "question": "Job Ticketing",
      "caption": "When customer accepts a quote, what is the process to ticket the job?",
      "criteria5": "Estimate info is converted to job ticket with minimal additional info and NO duplicate entry.",
      "criteria4": "Estimate is converted to job ticket in same system with some duplicate entry.",
      "criteria3": "Estimate is re-keyed into a different system to generate a job ticket.",
      "criteria2": "Job ticket is hand written.",
      "criteria1": "Customer email is printed and acts as job ticket.",
      "criteriaNA": "No job ticket is created.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S005":{
      "id": "S005",
      "num": 5,
      "type":1,
      "question": "Production Planning",
      "caption": "What is the process for assigning production steps?",
      "criteria5": "System assigns production steps based on properties of the job.",
      "criteria4": "CSR/Prod Mgr is able to assign production steps within a job ticketing system.",
      "criteria3": "CSR/Prod Mgr assigns production steps in spreadsheet or on job board.",
      "criteria2": "Production steps are written on job ticket.",
      "criteria1": "Print operator determines production steps based on job properties.",
      "criteriaNA": "Single employee performs all steps and already knows how to complete job.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S006":{
      "id": "S006",
      "num": 6,
      "type":1,
      "question": "Scheduling",
      "caption": "How are the production steps scheduled?",
      "criteria5": "Integrated system that dynamically schedules based on job properties, due date, and priority.",
      "criteria4": "Integrated system that allows Scheduler/Production Manager to schedule jobs.",
      "criteria3": "Job board clearly visible on production floor.",
      "criteria2": "Printed daily scheduling document or spreadsheet.",
      "criteria1": "Schedule based on operator / manager experience.",
      "criteriaNA": "No scheduling necessary - first come, first serve policy.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S007":{
      "id": "S007",
      "num": 7,
      "type":1,
      "question": "Labor Tracking",
      "caption": "How do you track labor associated with a job?",
      "criteria5": "Integrated system that allows data collection by employees by entering job # or scanning ticket.",
      "criteria4": "Stand alone system that allows data collection by employees by entering job # or scanning ticket.",
      "criteria3": "Spreadsheet or other document for employees to manually enter time and activity.",
      "criteria2": "Flat rate labor charges for each manual production step.",
      "criteria1": "Flat rate labor charges regardless of production steps.",
      "criteriaNA": "Labor not tracked or charged for.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S008":{
      "id": "S008",
      "num": 8,
      "type":1,
      "question": "Change Orders/AA's",
      "caption": "What is the process for change orders/author's alterations?",
      "criteria5": "Change requests are recorded, customer is provided applicable price changes, digital job ticket is updated, and workflow is halted/adjusted accordingly.",
      "criteria4": "Change requests are recorded, customer is provided applicable price changes, job ticket is retrieved from shop floor, and new ticket is generated.",
      "criteria3": "Change request triggers CSR to retrieve job ticket from shop floor, make adjustments, and generate new ticket, but change order not recorded.",
      "criteria2": "Change order triggers job to be cancelled and new estimate / job created.",
      "criteria1": "Changes are always provided to customer at no charge.",
      "criteriaNA": "Due to nature of the work, change orders are never needed.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S009":{
      "id": "S009",
      "num": 9,
      "type":1,
      "question": "Device Data Integration",
      "caption": "How are you tracking time and cost associated with each job?",
      "criteria5": "JDF/JMF integration feeds job ticket info and results between MIS system and print devices.",
      "criteria4": "JDF/JMF Integration feeds to MIS for digital but not offset and/or finishing equipment.",
      "criteria3": "Some devices are integrated with JDF/JMF.",
      "criteria2": "I use shop floor data collection or time cards to track costs manually.",
      "criteria1": "I do not track actual costs.",
      "criteriaNA": "Print costs are absorbed and not broken out by individual jobs.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S010":{
      "id": "S010",
      "num": 10,
      "type":1,
      "question": "Profitability Tracking",
      "caption": "How do you track the profitability of a job/set of jobs?",
      "criteria5": "Integrated system generates a profitability report for each job. Custom profitability reports can be generated based on job characteristics.",
      "criteria4": "Stand alone system generates labor report for each job and day / week / month end.",
      "criteria3": "Profitability report created by cutting / pasting labor spreadsheets then making calculations.",
      "criteria2": "Profitability report created by keying in hand written labor tracking then making calculations.",
      "criteria1": "No ability to create profitability report.",
      "criteriaNA": "Company does not track labor at all.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S011":{
      "id": "S011",
      "num": 11,
      "type":1,
      "question": "Inventory",
      "caption": "What triggers are in place to track and replenish inventory?",
      "criteria5": "Integrated system where job plan allocates inventory and thresholds trigger restock orders/POs.",
      "criteria4": "Orders decrement inventory levels and thresholds trigger restock notification.",
      "criteria3": "Warehouse tracks quantities on hand and manually adjusts as pulled. Minimum threshold triggers manual restock notification.",
      "criteria2": "Standard inventory purchases daily / weekly / monthly. Quantity always the same.",
      "criteria1": "Inventory levels are \"eyeballed\" and restocked when they appear to be low.",
      "criteriaNA": "Print operations not in charge of inventory.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S012":{
      "id": "S012",
      "num": 12,
      "type":1,
      "question": "Shipping",
      "caption": "What is the system to generate shipping labels and tracking numbers?",
      "criteria5": "Integrated system that populates shipping specs with info from job ticket. NO duplicate entry required.",
      "criteria4": "Integrated system that populates shipping specs with info from job ticket. Some duplicate entry required.",
      "criteria3": "Shipping info able to be copied/pasted between systems.",
      "criteria2": "Shipping info has to be re-keyed into system from electronic job ticket.",
      "criteria1": "Shipping info has to be re-keyed from paper job ticket.",
      "criteriaNA": "No shipping.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S013":{
      "id": "S013",
      "num": 13,
      "type":1,
      "question": "Invoicing",
      "caption": "How are jobs invoiced?",
      "criteria5": "Integrated system that generates invoice based on estimate, change orders, and populates based on customer's terms.",
      "criteria4": "Integration between two systems that passes job info to populate invoice.",
      "criteria3": "Manual report exported from one system and imported to another to generate invoice.",
      "criteria2": "Invoice has to be re-keyed from digital job ticket system.",
      "criteria1": "Invoice has to be re-keyed from paper job ticket.",
      "criteriaNA": "Customers are not invoiced for work.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S014":{
      "id": "S014",
      "num": 14,
      "type":1,
      "question": "Reporting",
      "caption": "What reports are able to be generated end of month/quarter/year?",
      "criteria5": "Integrated system with many standard reports with ability to create new report by querying anything in database.",
      "criteria4": "Integrated system with many standard reports but difficult to create new report without DB programmer.",
      "criteria3": "Multiple systems to generate reports that can be combined together in Excel.",
      "criteria2": "Multiple systems to generate reports, but in different formats so difficult to combine.",
      "criteria1": "Manual reporting process using Excel.",
      "criteriaNA": "Reporting not required.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S015":{
      "id": "S015",
      "num": 15,
      "type":1,
      "question": "CRM",
      "caption": "How do you manage your customer data base and job history?",
      "criteria5": "CRM system Integrated with Print MIS giving visibility to customer info and job history.",
      "criteria4": "Stand alone web based CRM.\n(ex. Salesforce.com)",
      "criteria3": "Stand alone off line CRM.\n(ex. ACT, home grown data base)",
      "criteria2": "Staff manages opportunities in shared documents.\n(ex. Google Docs, etc.)",
      "criteria1": "Staff manages opportunities individually.",
      "criteriaNA": "No sales staff, no managing opportunities.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    },
    "S016":{
      "id": "S016",
      "num": 16,
      "type":1,
      "question": "Workflow Integration",
      "caption": "How automated is your process for passing data across all systems?",
      "criteria5": "Fully automated - minor information additions.",
      "criteria4": "1-2 systems disconnect (separate estimating and job ticketing).",
      "criteria3": "3-4 systems disconnected (separate estimating, job ticketing, scheduling, purchasing, shipping).",
      "criteria2": "5-6 systems disconnected (inventory, estimating, job ticketing, scheduling, purchasing, shipping).",
      "criteria1": "No system connectivity.",
      "criteriaNA": "No workflow integration.",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 is sub-standard."
    }
  }
}















