


<template>
  <div class="color-calulator bg-gray-50">
    <div class="text-xs px-8 py-2 pt-4 bg-white">
      <router-link to="/">Home</router-link> /
      <router-link to="/calculators">Calculators</router-link> /
      <span>Equipment Rates</span>
    </div>
    <div class="sm:flex justify-between items-center px-8 pt-0 pb-5 bg-white border-b">
      <h1 class="text-3xl leading-none flex items-baseline"><span v-if="profile" class="mr-2">{{ profile.organization?profile.organization:"Production Printing" }}</span></h1>
      <div class="flex pt-4 sm:pt-0 sm:-mt-2 text-sm">
        <button class="button mr-2" @click="showProjects"><img class="inline-block mr-2 h-4" src="../assets/icons/list-icon.svg" /> Saved <span class="hidden sm:inline">Calculations</span></button>
        <div class="dropdown">
          <button class="button relative" >Options</button>
          <div class="options absolute z-50 hidden pt-1">
            <div class="bg-white border rounded">
              <div class="px-4 py-3 border-b cursor-pointer hover:bg-gray-100" @click="saveProject"><img class="w-3 object-contain inline-block mr-1 h-3" src="../assets/icons/save-icon.svg" /> Save Changes</div>
              <div class="px-4 py-3 border-b cursor-pointer hover:bg-gray-100" @click="copyProject"><img class="w-3 object-contain inline-block mr-1 h-3" src="../assets/icons/copy-icon.svg" /> Create Copy</div>
              <div class="px-4 py-3 border-b cursor-pointer hover:bg-gray-100" @click="resetProject"><img class="w-3 object-contain inline-block mr-1 h-3" src="../assets/icons/list-icon.svg" /> Clear All Fields</div>
              <div class="px-4 py-3 border-b cursor-pointer hover:bg-gray-100" @click="newProject"><img class="w-3 object-contain inline-block mr-1 h-3" src="../assets/icons/new-doc-icon-dark.svg" /> Start New Rate</div>

              <div class="px-4 py-3 cursor-pointer hover:bg-red-600 hover:text-white" @click="deleteProject"><svg class="w-3 h-3 inline-block mr-1 ml-0 fill-current" xmlns="http://www.w3.org/2000/svg" width="21" height="27" viewBox="0 0 21 27">
                <path d="M9,28.5a3.009,3.009,0,0,0,3,3H24a3.009,3.009,0,0,0,3-3v-18H9Zm3.69-10.68L14.805,15.7,18,18.885l3.18-3.18L23.3,17.82,20.115,21l3.18,3.18L21.18,26.3,18,23.115,14.82,26.3,12.7,24.18,15.885,21ZM23.25,6l-1.5-1.5h-7.5L12.75,6H7.5V9h21V6Z" transform="translate(-7.5 -4.5)"/>
              </svg>
                Delete Rate Calculation</div>
            </div>
          </div>
        </div>
        <button class="button ml-2 border-red-500 text-red-500" @click="showTutorial"><span class="">Tutorial</span></button>
        <div class="w-8 h-8 p-2 border-gray-300 border-2 rounded-full flex justify-center items-center mt-1 ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="exitProject">
          <img  class="h-3 inline-block " src="../assets/icons/close-icon.svg" />
        </div>

      </div>

    </div>

    <div class="pt-4 px-8 max-w-4xl">



      <div class="mb-4 mr-8">
        <p class="text-sm py-4 px-6 bg-gray-100 border rounded font-bold">Use the Rate Calculator to calculate an equipment’s impression rate. Provide the summary (total) of expenses on this screen or itemize the expenses by clicking the <img class="inline-block h-4" src="../assets/icons/edit-icon.svg" /> button to the right of the summaries.</p>
      </div>

      <h2 class="font-bold uppercase text-xl pb-2 pt-2 mb-2 border-b mr-8">EQUIPMENT RATE DEVELOPMENT <span v-if="loading" class="text-sm opacity-50">Loading...</span></h2>

      <div v-if="rates">
      <div class="pr-8">
        <div class="flex items-center justify-between pb-3 border-b mb-2">
          <label>Equipment Name</label>

          <div class="w-96 relative group cursor-pointer">
            <input class="w-full p-1 px-2 mt-1 rounded text-left border"  v-model="rates.projectName"  @change="onChange" />
          </div>
        </div>

        <div class="flex items-center justify-between pt-1 mt-2 ">
          <label># of Administrative Employees</label>
          <div class="w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right" v-model="rates.summary.adminEmployees" precision="2" @change="onChange"></number-input>
          </div>
        </div>
        <div class="flex items-center justify-between pt-1">
          <label># of Production Employees</label>
          <div class="w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right" v-model="rates.summary.productionEmployees" precision="2" @change="onChange"></number-input>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <label class="">Total Employees</label>
          <div class="w-48">
            <p class="w-full p-1 pt-4 mt-1 px-2 text-right font-bold">{{ rates.summary.totalEmployees | decimal(2) }}</p>
          </div>
        </div>
      </div>

      <div class="mt-4 pr-8">
        <h3 class="font-bold mb-2"><span class="border-b pb-2">Annual Expenses for Entire Operation</span></h3>
        <div class="flex items-center justify-between pt-1">
          <label>Facility Expenses</label>

            <div class="w-48 relative group cursor-pointer border-b hover:border-black">
              <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="rates.overhead.facility.total" format="$" precision="0" @change="onChangeToSummary(rates.overhead.facility)"></number-input>
              <div @click="showSidePanel('facility')" class="absolute right-0 top-0 mt-3 -mr-6 opacity-25 group-hover:opacity-100">
                <img class="h-4" src="../assets/icons/edit-icon.svg" />
              </div>
            </div>


        </div>
        <div class="flex items-center justify-between pt-1">
          <label>Administrative Expenses</label>
          <div class="w-48 relative group cursor-pointer border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="rates.overhead.admin.total" format="$" precision="0" @change="onChangeToSummary(rates.overhead.admin)" ></number-input>
            <div @click="showSidePanel('admin')" class="absolute right-0 top-0 mt-3 -mr-6 opacity-25 group-hover:opacity-100">
              <img class="h-4" src="../assets/icons/edit-icon.svg" />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between pt-1">
          <label>Personnel Expenses</label>
          <div class="w-48 relative group cursor-pointer border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="rates.overhead.personnel.total" format="$" precision="0" @change="onChangeToSummary(rates.overhead.personnel)" ></number-input>
            <div @click="showSidePanel('personnel')" class="absolute right-0 top-0 mt-3 -mr-6 opacity-25 group-hover:opacity-100">
              <img class="h-4" src="../assets/icons/edit-icon.svg" />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <label class="">Total Overhead</label>
          <div class="w-48">
            <p class="w-full p-1 pt-4 px-2 mt-1 text-right font-bold">{{ rates.overhead.total | currency(1) }}</p>
          </div>
        </div>
      </div>

      <div class="mt-4 pr-8">
        <h3 class="font-bold mb-2"><span class="border-b pb-2">Annual Expenses for the Equipment this Rate is being developed for</span></h3>
        <div class="flex items-center justify-between pt-1">
          <label>Equipment Expenses</label>
          <div class="w-48 relative group cursor-pointer border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent"  v-model="rates.equipment.expenses.total" format="$" precision="0" @change="onChangeToSummary(rates.equipment.expenses)"></number-input>
            <div @click="showSidePanel('equipment')" class="absolute right-0 top-0 mt-3 -mr-6 opacity-25 group-hover:opacity-100">
              <img class="h-4" src="../assets/icons/edit-icon.svg" />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between pt-1">
          <label>Allocation of Overhead</label>
          <div class="w-48 relative group border-b">
            <number-input tabindex="-1" class="w-full p-1 px-2 mt-1 rounded text-right pointer-events-none bg-transparent"  v-model="rates.equipment.allocation" format="$" precision="0" readonly></number-input>
          </div>
        </div>

        <div class="flex items-center justify-between">
          <label class="">Total Equipment and Overhead Expense</label>
          <div class="w-48">
            <p class="w-full p-1 px-2 pt-4 mt-1 text-right font-bold">{{ rates.equipment.total | currency(1) }}</p>
          </div>
        </div>
      </div>

      <div class="mt-4 pr-8">
        <div class="flex items-center justify-between pt-1">
          <label>Impression Cost-Color</label>
          <div class="w-48 relative group cursor-pointer border-b hover:border-black" @click="showSidePanel('color')">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent pointer-events-none"  v-model="rates.summary.impressionCostColor" format="$" precision="0" @change="onCostSummaryChange('color')" readonly></number-input>
            <div  class="absolute right-0 top-0 mt-3 -mr-6 opacity-25 group-hover:opacity-100">
              <img class="h-4" src="../assets/icons/edit-icon.svg" />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between pt-1">
          <label>Impression Cost-B/W</label>
          <div class="w-48 relative group cursor-pointer border-b hover:border-black" @click="showSidePanel('bw')" >
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent pointer-events-none"  v-model="rates.summary.impressionCostBW" format="$" precision="0" @change="onCostSummaryChange('bw')" readonly></number-input>
            <div  class="absolute right-0 top-0 mt-3 -mr-6 opacity-25 group-hover:opacity-100">
              <img class="h-4" src="../assets/icons/edit-icon.svg" />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 pr-8">

        <div class="flex items-center justify-between pt-1">
          <label>% Markup/Profit</label>
          <div class="w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right"  v-model="rates.summary.markup" format="%" precision="1" @change="onChange"></number-input>
          </div>
        </div>
      </div>

      <h3 class="font-bold text-brand-blue mt-8"><span class="">Your Calculated Impression Rates</span> <span v-if="warning" class="text-sm text-black bg-yellow-400 p-1">Error: {{ warning }}</span></h3>


      <div class="mt-2 p-4 pt-3 pr-4 mr-2 -ml-4 border border-brand-blue bg-white text-brand-blue rounded">
        <div class="flex items-center justify-between pt-1">
          <label class="">8.5 x 11 Color Impression Rate</label>
          <div class="w-48">
            <p class="text-xl w-full px-2 pb-1 text-right font-bold border-b-2 border-brand-blue text-brand-blue">${{ rates.summary.impressionRateColor | decimal(3) }}</p>
          </div>
        </div>

        <div class="flex items-center justify-between pt-2">
          <label class="">8.5 x 11 B/W Impression Rate</label>
          <div class="w-48">
            <p class="text-xl w-full px-2 pb-1 text-right font-bold border-b-2 border-brand-blue text-brand-blue">${{ rates.summary.impressionRateBW | decimal(3) }}</p>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div class="pt-4 mt-4 border-t mx-8 max-w-4xl">
      <div class="pb-4 sm:flex justify-between">
        <div class="sm:pr-4">
          <button  class="mt-2 sm:mt-0 button mr-4 w-full sm:w-auto" @click="resetProject">Clear Fields</button>


        </div>
        <div class="sm:pl-4 sm:flex">
          <button  class="mt-2 sm:mt-0 button mr-4 w-full sm:w-auto" @click="exitProject">Return to Calculators</button>
          <button class="mt-2 sm:mt-0 button button-blue w-full sm:w-auto" @click="saveProject"><span>Save Calculation <img v-if="saving" class="ml-2 inline-block h-4" src="../assets/icons/tail-spin.svg" /></span></button>
        </div>

      </div>
    </div>


    <transition name="slide">
    <div class="fixed w-full h-full max-w-2xl top-0 right-0 shadow-lg" v-if="sidePanel">
        <component class="side-panel absolute top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll" :is="sidePanel" :profile="profile" @load="loadSavedProject" :has-changes="hasUnsavedChanges"  :rates="rates"  @close="closeSidePanel" @change="onChange"></component>
    </div>
    </transition>

    <transition name="fade-on">
      <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="tutorial">
        <video-window class="" video="speciality-color-tutorial.mp4" title="Tutorial" @close="closeTutorial"></video-window>
      </div>
    </transition>










</div>
</template>

<script>
// import TabBar from "@/components/TabBar";
// import ColorRateCalculatorRates from "@/components/ColorRateCalculatorRates";
// import ColorRateCalculatorOverhead from "@/components/ColorRateCalculatorOverhead";



import NumberInput from "@/components/NumberInput";
import SidePanelRatesFacility from "@/components/ColorCalc/SidePanelRatesFacility";
import SidePanelRatesAdmin from "@/components/ColorCalc/SidePanelRatesAdmin";
import SidePanelRatesPersonnel from "@/components/ColorCalc/SidePanelRatesPersonnel";
import SidePanelRatesEquipment from "@/components/ColorCalc/SidePanelRatesEquipment";
import SidePanelRatesColor from "@/components/ColorCalc/SidePanelRatesColor";
import bodyScroll from "body-scroll-toggle";
import SidePanelEquipmentRates from "@/components/SidePanelEquipmentRates";
import VideoWindow from "@/components/ColorCalc/VideoWindow";

export default {
    name: "equipment-rate-calculator",
  components: {
    SidePanelEquipmentRates,
    SidePanelRatesColor,
    SidePanelRatesEquipment,
    SidePanelRatesPersonnel, SidePanelRatesAdmin, SidePanelRatesFacility, NumberInput, VideoWindow},
  mounted: function(){
    if(this.$route.params.project){
      this.loadProject(this.$route.params.project);
    }else{
      this.rates = this.$colorCalc.generateEquipmentRate();
      this.modalOptions = true;
    }
  },
  props: [],
    data: function(){
        return {
          modalOptions: false,
          rates: undefined,
          modalScrollPos: 0,
          sidePanel: "",
          projectsPanel: false,
          currentTabIndex: 1,
          hasUnsavedChanges: false,
          saving: false,
          loading: false,
          tutorial: false
        }
        },
    methods: {

      showTutorial: function(){
        bodyScroll.disable();
        this.tutorial = true;
      },

      closeTutorial: function(){
        bodyScroll.enable();
        this.tutorial = false;
      },



      showProjects: function(){
        this.showSidePanel('projects');
      },

      copyProject: function(){

        let newName = prompt("Enter the new equipment rate name:");

        if(newName){
          let newProject = this.$colorCalc.copyRecord(this.rates);

          newProject.projectName = newName;

          this.rates = newProject;

          this.saveNewProject();
        }

      },

      saveProject: function(){

        if(!this.rates.projectName){
          window.scrollTo(0,0);
          alert("You must enter a project name.");
          return;
        }else if( !this.rates.id ){
          this.saveNewProject();
          return;
        }

        this.saving = true;
        this.$colorCalc.update(this.rates, this.rates.id, 'rateCalcs')
            .then(() => {
              this.lastSave = Date.now();
              this.saving = false;
              this.hasUnsavedChanges = false;
              alert("This calculation has been saved.");
            }).catch((error) => {
          console.error("Error", error);
          this.saving = false;
          alert("Something went wrong with this operation. Please try again.")
        })

      },

      newProject: function(){
        if(this.hasUnsavedChanges){
          if(!confirm("This will erase any unsaved changes. Click OK to proceed.")) {
            return;
          }
        }


        this.rates = this.$colorCalc.generateEquipmentRate()
        this.hasUnsavedChanges = false;

        this.modalOptions = false;


        if(this.$route.params.project){
          this.$router.push({
            name: "New Equipment Rate Calc",
          })
        }

      },


      saveNewProject: function(){
        this.$colorCalc.create(this.rates, "rateCalcs")
            .then((response) => {
              alert("This project has been saved.");
              if(response.data && response.data.projectName){
                this.rates.id = response.ref.value.id;
               //console.log("redirecting", response.ref);
                this.$router.push({
                  name: "Equipment Rate Calc",
                  params: {
                    project: this.rates.id
                  }
                }).catch(()=>{
                 //console.log("");
                })
              }
            })
            .catch((error) => {
              console.error("Error", error);
              alert("Something went wrong with this operation. Please try again.");
            })
      },

      deleteProject: function(){
        if(this.rates){
          if(confirm('Are you sure you want to delete this calculation?')){

            if(this.rates.id){
              this.$colorCalc.remove(this.rates.id, 'rateCalcs')
                  .then(() => {
                    this.$router.push({
                      name: "Calculators",
                    })
                  })
                  .catch((error) => {
                    console.error(error);
                    alert("There was a problem with this operation. Try again.");

                  })

            }else{
              this.$router.push({
                name: "Calculators",
              })
            }
          }
        }

      },



      resetProject: function(){

        if(confirm("This will clear you current field values. Click OK to proceed.")){

        this.rates.summary = {
          adminEmployees: 0,
          productionEmployees: 0,
          totalEmployees: 0,
          markup: 0,
          impressionCostColor: 0,
          impressionCostBW: 0,
          impressionRateColor: 0,
          impressionRateBW: 0
        };

        this.rates.overhead = {
          facility: {
            details: {
              mortgage: 0,
              taxes: 0,
              utilities: 0,
              maintenance: 0,
              deprecation: 0,
              moving: 0,
              insurance: 0,
              janitorial: 0,
              supplies: 0,
              waste: 0,
              recycling: 0,
              other: 0,
            },
            summarized: true,
            total: 0
          },
          admin: {

            details: {
              banking: 0,
              salestax: 0,
              debt: 0,
              legal: 0,
              consulting: 0,
              unions: 0,
              advertising: 0,
              postal: 0,
              dues: 0,
              donations: 0,
              safety: 0,
              permits: 0,
              office: 0,
              computers: 0,
              software: 0,
              shipping: 0,
              meals: 0,
              transportation: 0,
              vehicleLease: 0,
              vehicleRepairs: 0,
              gas: 0,
              gifts: 0,
              other: 0
            },
            total: 0,
            summarized: true
          },
          personnel: {

            details: {
              payroll: 0,
              medical: 0,
              life: 0,
              workerscomp: 0,
              retirement: 0,
              training: 0,
              education: 0,
              awards: 0,
              bonus: 0,
              other: 0,
              salaries: 0
            },
            total: 0,
            summarized: true
          },
          total: 0
        };

        this.rates.equipment = {
          expenses: {
            lease: 0,
            repairs: 0,
            labor: 0,
            wage: 0,
            operators: 0,
            total: 0,
            summarized: true
          },

          allocation: 0,
          costPerClickColor: 0,
          impressionsColor: 0,
          costPerClickBW: 0,
          impressionsBW: 0,
          total: 0
        };



          this.hasUnsavedChanges = true;
          this.calculate();

        }

      },

      exitProject: function(){
        if(this.hasUnsavedChanges){
          if(confirm("There are unsaved changes to this calculation. If you exit before saving you will lose these changes.")){
            this.$router.push({
              name: "Calculators",
            })
          }
        }else{
          this.$router.push({
            name: "Calculators",
          })
        }
      },

      loadSavedProject: function(project){
       //console.log("load saved project", project);

        bodyScroll.enable();
        this.$router.push({
          name: "Equipment Rate Calc",
          params: {
            project: project.id
          }
        }).catch(() => {
         //console.log("");
        })
        this.loadProject(project.id);
        this.sidePanel = false;
      },

      loadProject: function(id){

        this.loading = true;

        this.$colorCalc.load(id, 'rateCalcs')
            .then((response) => {
              this.rates = response;
              this.loading = false;
            })
            .catch((error) => {
              console.error("Error", error);
              this.loading = false;
            })
      },


      showSidePanel: function(id){

        //bodyScroll.disable();

        this.modalOptions = false;

        if(id == "projects"){
          this.sidePanel = "SidePanelEquipmentRates"
        }

        if(id == "facility"){
          this.sidePanel = "SidePanelRatesFacility"
        }

        if(id == "admin"){
          this.sidePanel = "SidePanelRatesAdmin"
        }

        if(id == "personnel"){
          this.sidePanel = "SidePanelRatesPersonnel"
        }

        if(id == "equipment"){
          this.sidePanel = "SidePanelRatesEquipment"
        }

        if(id == "color"){
          this.sidePanel = "SidePanelRatesColor"
        }

        if(id == "bw"){
          this.sidePanel = "SidePanelRatesColor"
        }

      },

      applyRate: function(){
       //console.log("apply");



      },

      cancel: function(){
       //console.log('close');
          this.$emit('close');
      },

      closeSidePanel: function(){
       //console.log("close panel");
        //bodyScroll.enable();
        this.sidePanel = "";
      },

      calculate: function(){

        this.rates.summary.totalEmployees = this.rates.summary.adminEmployees + this.rates.summary.productionEmployees;
        this.rates.overhead.total = this.rates.overhead.facility.total + this.rates.overhead.admin.total + this.rates.overhead.personnel.total;
        this.rates.equipment.allocation = (this.rates.equipment.expenses.operators / this.rates.summary.productionEmployees) * this.rates.overhead.total;
        this.rates.equipment.total = this.rates.equipment.expenses.total + this.rates.equipment.allocation;

        this.rates.summary.impressionRateColor = this.getColorImpressionRate();
        this.rates.summary.impressionRateBW = this.getBWImpressionRate();


      },

      getColorImpressionRate: function(){
        //=(((((I13/(I13+I15))*B17)+B19)/I13)*B22)+((((I13/(I13+I15))*B17)+B19)/I13)

        let I13 = this.rates.equipment.impressionsColor;
        let I15 = this.rates.equipment.impressionsBW;
        let B17 = this.rates.equipment.total;
        let B19 = this.rates.summary.impressionCostColor;
        let B22 = this.rates.summary.markup * 0.01;

        if(I13){
          return (((((I13/(I13+I15))*B17)+B19)/I13)*B22)+((((I13/(I13+I15))*B17)+B19)/I13);
        }
      },

      getBWImpressionRate: function(){
        //=(((((I15/(I13+I15))*B17)+B20)/I15)*B22)+((((I15/(I13+I15))*B17)+B20)/I15)

        let I13 = this.rates.equipment.impressionsColor;
        let I15 = this.rates.equipment.impressionsBW;
        let B17 = this.rates.equipment.total;
        let B20 = this.rates.summary.impressionCostBW;
        let B22 = this.rates.summary.markup * 0.01;

        if(I15){
          return (((((I15/(I13+I15))*B17)+B20)/I15)*B22)+((((I15/(I13+I15))*B17)+B20)/I15);
        }
      },

      onChange: function(){
       //console.log("change");
        this.calculate();

      },

      onChangeToSummary: function(ref){
       //console.log("change to summary");
        if(ref.total){
          ref.summarized = true;
        }
        this.calculate();

      },

      onCostSummaryChange: function(){
        this.calculate();
      },

      onChangeTab: function(tab){
        this.setTab(tab.id);
      },

      setTab: function(tabId){


        let tgtIndex = this.tabs.findIndex((tab) => {
          return tab.id == tabId;
        })

        if(tgtIndex > -1){
          this.currentTabIndex = tgtIndex;
          window.scrollTo(0,0);
        }

      },
    },
    computed: {

      warning: function(){
        if(this.rates.summary.totalEmployees){
          return ""
        }
        return "# of Employees is not specified!"
      },

      profile: function(){
        return this.$store.getters.getUserProfile;
      },


      tabs: function(){
        return [
          { label: "Rates", id: "rates", component: "ColorRateCalculatorRates"},
          { label: "Overhead Expenses", id: "overhead", component: "ColorRateCalculatorOverhead"},
          { label: "Equipment Expenses", id: "equipment", component: ""},
        ]
      },

      currentTab: function(){
        return this.tabs[this.currentTabIndex];
      },

    }
}
</script>

<style scoped>


.color-rate-calculator {

}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transition: all 0.3s ease;
}

.slide-enter-to {

}

.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-leave {

}

.slide-leave-to {
  transform: translateX(100%);
}

</style>