


<template>
  <div class="summary-export max-w-3xl">
    <div class="mb-8">
      <p class="py-4 px-6 bg-gray-100 border rounded font-bold" v-if="options.instructions">{{ options.instructions }}</p>
    </div>
    <div>
      <h2 class="font-bold text-xl pb-2 border-b-2">Export Options</h2>
    </div>


    <div class="py-4 flex justify-between items-center">
      <div class="flex items-center">

        <h2 class="text-lg">MAP Assessment Scorecard</h2>
      </div>
      <div>
        <button class="button button-blue" @click="onPreview('scorecard')">Preview</button>
      </div>
    </div>
    <div class="hidden py-4 border-b flex justify-between items-center">
      <div class="flex items-center">

        <h2 class="text-lg">ROI Summary</h2>
      </div>
      <div>
        <button class="button button-blue" @click="onPreview('roi')">Preview</button>
      </div>
    </div>
    <div class="py-4 flex justify-between items-center hidden">
      <div class="flex items-center">

        <h2 class="ml-4 text-lg">Photo Gallery (0 images)</h2>
      </div>
      <div>
        <button class="button button-blue" @click="onPreview('photos')">Preview</button>
      </div>
    </div>
    <div class="py-4 pt-8 border-b">
      <div>
        <h2 class="font-bold text-xl pb-2 border-b-2">Findings Report</h2>

      </div>

      <div class="flex flex-wrap">
        <div class="w-1/2">



          <h3 class="text-lg font-bold mt-6 mb-2">Solutions</h3>
          <div class="flex py-2">
            <input @change="onChange"  type="checkbox" id="map1" class="flex-shrink-0 w-6 h-6" value="Robohead" v-model="assessment.exportSettings.solutions.Other"/>
            <h2 class="ml-4 text-lg">Robohead Project Management</h2>
          </div>

          <div class="flex items-center py-2">
            <input @change="onChange" type="checkbox" id="map2" class="flex-shrink-0 w-6 h-6" value="MarcomCentral" v-model="assessment.exportSettings.solutions.Other"/>
            <h2 class="ml-4 text-lg">Marcom Central</h2>
          </div>

          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="map3" class="flex-shrink-0 w-6 h-6" value="RicohMarketingServices" v-model="assessment.exportSettings.solutions.Other"/>
            <h2 class="ml-4 text-lg">Marketing Services</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="map4" class="flex-shrink-0 w-6 h-6" value="CreativeServices" v-model="assessment.exportSettings.solutions.Other"/>
            <h2 class="ml-4 text-lg">Creative Services</h2>
          </div>

          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="map5" class="flex-shrink-0 w-6 h-6" value="DigitalAttribution" v-model="assessment.exportSettings.solutions.Other"/>
            <h2 class="ml-4 text-lg">Digital Attribution</h2>
          </div>


          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="map7" class="flex-shrink-0 w-6 h-6" value="MarketingAutomation" v-model="assessment.exportSettings.solutions.Other"/>
            <h2 class="ml-4 text-lg">Marketing Automation</h2>
          </div>

          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="map8" class="flex-shrink-0 w-6 h-6" value="ProjectManagementConsulting" v-model="assessment.exportSettings.solutions.Other"/>
            <h2 class="ml-4 text-lg">Project Management Consulting</h2>
          </div>

          <div class="flex items-center py-2">
            <input @change="onChange" type="checkbox" id="map9" class="flex-shrink-0 w-6 h-6" value="AugmentedReality" v-model="assessment.exportSettings.solutions.Other"/>
            <h2 class="ml-4 text-lg">Augmented Reality</h2>
          </div>

          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="map10" class="flex-shrink-0 w-6 h-6" value="DigitalMaturity" v-model="assessment.exportSettings.solutions.Other"/>
            <h2 class="ml-4 text-lg">Digital Maturity Assessment</h2>
          </div>







          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="map5" class="flex-shrink-0 w-6 h-6" value="MailAssessment" v-model="assessment.exportSettings.solutions.Other"/>
            <h2 class="ml-4 text-lg">Mail Assessment</h2>
          </div>





        </div>



      </div>


    </div>

    <div class="pt-4 border-t">
      <div class="pb-4 flex justify-between">
        <div class="pr-4">

        </div>
        <div class="pl-4">
          <button @click="openPreview" class="button button-blue button-next">Generate Full Report</button>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "summary-map-export",
  props: {
    options: {
      default: function(){
        return {
          label: "Solution Costs",
          costsKey: "",
          instructions: "Costs instructions",
        }
      },

    },

  },
  data: function(){
    return {
      hasUnsavedChanges: false
    }
  },
  methods: {
    onPreview: function(id){

      if(id === "selfscorecard"){

        this.$router.push({
          name: "Assessment",
          params: this.$route.params,
          query: {
            tab: "pre",
            section: "selfscore"
          }
        })

      }else if(id === "scorecard"){
        this.$router.push({
          name: "Assessment",
          params: this.$route.params,
          query: {
            tab: "pre",
            section: "walkthroughscore"
          }
        })
      }else if(id === "savings"){
        this.$router.push({
          name: "Assessment",
          params: this.$route.params,
          query: {
            tab: "summary",
            section: "savings"
          }
        })
      }else if(id === "roi"){
        this.$router.push({
          name: "Assessment",
          params: this.$route.params,
          query: {
            tab: "summary",
            section: "roi"
          }
        })
      }else if(id === "photos"){
        console.log(id);
      }
    },

    onChange: function(e){
      console.log(e.target.value)
      this.hasUnsavedChanges = true;
      this.$emit("change");
    },

    onClickRadio: function(e){
      console.log("radio click",e.target.value)
      // this.hasUnsavedChanges = true;
      // this.$emit("change");
    },


    openPreview: function(){

      let updateSettings = {
        exportSettings: this.assessment.exportSettings
      }
      if(this.hasUnsavedChanges){
        this.$sdm.updateAssessment(updateSettings, this.assessment.id).then(()=>{
          this.$router.push({
            name: "Reports",
            query: {
              r: this.$route.params.assessment,
              t: "map"
            }
          })
        })
      }else{
        this.$router.push({
          name: "Reports",
          query: {
            r: this.$route.params.assessment,
            t: "map"
          }
        })
      }

    },

    openSnapshotPreview: function(){

      let updateSettings = {
        exportSettings: this.assessment.exportSettings
      }
      if(this.hasUnsavedChanges){
        this.$sdm.updateAssessment(updateSettings, this.assessment.id).then(()=>{
          this.$router.push({
            name: "Reports",
            query: {
              r: this.$route.params.assessment,
              t: "pws"
            }
          })
        })
      }else{
        this.$router.push({
          name: "Reports",
          query: {
            r: this.$route.params.assessment,
            t: "pws"
          }
        })
      }

    },

    getSolutionFlags: function(){
      let flags = "";
      for (var i=0; i<12; i++){
        flags = flags + (this['solution'+i]?"1":"0");
      }

      return flags;
    }

  },
  computed: {
    assessment: function(){
      return this.$store.state.workflowAssessment;
    }
  }
}
</script>

<style scoped>


.summary-export {

}

</style>