


<template>
<div class="question multi-col" :data-inactive="!answer.active">
  <div class="w-full flex items-center">
    <div class="pr-8 w-1/2 relative">
      <label><input class="float-left mt-1 mr-2" v-if="question.optional" type="checkbox" v-model="answer.active" /> <span>{{ question.question }} </span></label>
    </div>
    <div class="flex w-1/2 pl-4 justify-end relative">
      <div class="flex-shrink-0 flex-grow-0 w-1/3">
      <label class="text-xs w-full text-right pr-2 block" v-if="showLabels">{{ question.cols[0] }}</label>
      <number-input class="col-input w-full border p-2 block rounded text-right" type="text" :format="question.dataFormat" v-model="answer.answer[question.cols[0]]" @change="onChange" :disabled="!answer.active || !enabled[0]"></number-input>
        <hint class="block absolute -ml-8 -mt-8" direction="absolute right-0 mr-8" v-if="question.hint" :message="question.hint"></hint>
    </div>
    <div class="flex-shrink-0 flex-grow-0 w-1/3 ml-2" v-if="inputs > 1">
      <label class="text-xs w-full text-right pr-2 block">w/ {{ question.cols[1] }}</label>
      <number-input class="col-input w-full border p-2 block rounded text-right" type="text" :format="question.dataFormat" v-model="answer.answer[question.cols[1]]" @change="onChange" :disabled="!answer.active || !enabled[1]"></number-input>
    </div>
    <div class="flex-shrink-0 flex-grow-0 w-1/3 ml-2" v-if="inputs > 2">
      <label class="text-xs w-full text-right pr-2 block">w/ {{ question.cols[2] }}</label>
      <number-input class="col-input w-full border p-2 block rounded text-right" type="text" :format="question.dataFormat" v-model="answer.answer[question.cols[2]]" @change="onChange" :disabled="!answer.active || !enabled[2]"></number-input>
    </div>
    </div>
  </div>
</div>
</template>

<script>

import NumberInput from "@/components/NumberInput";
import Hint from "@/components/Hint";
export default {
    name: "assessment-interview-question-multi-col",
  components: {NumberInput, Hint},
  props: {
      question: {
        required: true
      },
      answer: {
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {
      onChange: function(e){
        if(e !== null){
          this.$emit("change", e);
        }

      }
    },
    computed: {

      colWidth: function(){
        if(this.inputs == 3){
          return "w-1/3"
        }else{
          return "w-1/2"
        }
      },

      enabled: function(){

        if(this.question.enabled){
          return this.question.enabled;
        }else{
          return [true,true,true];
        }

      },

      currency: function(){
        if(this.question.dataFormat == "$"){
          return "USD"
        }

        return null;
      },

      decimals: function(){
        return (this.question.dataFormat == "0.0")?2:0
      },

      isPercentage: function(){
       return (this.question.dataFormat == "%")
      },

      inputs: function(){
        if(this.question.cols.length > 1){
          return this.question.cols.length
        }else{
          return 1;
        }
      },

      showLabels: function(){
        return this.question.cols.length;
      }
    }
}
</script>

<style scoped>


.question[data-inactive="true"] {
  color: gray;
}

.question[data-inactive="true"] .col-input {
  color: rgba(0,0,0,0.2);
}

.question[data-inactive="true"] .col-input::placeholder {
  color: rgba(0,0,0,0.2);
}

</style>