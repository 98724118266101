


<template>
  <table class="text-xs"  data-cell-style="AppendixCell">
    <thead class="">
    <tr>
      <th class="sticky top-0 relative text-left"><span class="pl-2">Details</span></th>
      <th class="sticky top-0 relative">Current Yearly Hours</th>
      <th class="sticky top-0 relative">Current Yearly Cost</th>
      <th class="sticky top-0 relative">Future Yearly Hours with MIS</th>
      <th class="sticky top-0 relative">Future Yearly Cost with MIS</th>
      <th class="sticky top-0 relative">Yearly Man Hour Savings with MIS</th>
      <th class="sticky top-0 relative">Yearly Cost Savings with MIS</th>
      <th class="sticky top-0 relative">Future Yearly Hours with Online Storefront</th>
      <th class="sticky top-0 relative">Future Yearly Cost with Online Storefront</th>
      <th class="sticky top-0 relative">Yearly Man Hour Savings with Online Storefront</th>
      <th class="sticky top-0 relative">Yearly Cost Savings with Online Storefront</th>

    </tr>
    </thead>
    <tbody v-for="(group, groupIndex) in detailGroups" :key="group.key">
    <tr class="font-bold underline">
      <td class="group-label">{{ group.label }}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr v-for="(row, rowIndex) in group.rows" :key="groupIndex +'-'+ rowIndex">
      <td class="row-label">{{ row.label }}</td>
      <td v-for="(col, colIndex) in detailCols" :key="groupIndex +'-'+ rowIndex + '-'+colIndex">{{ resolveValue( group.key,row.key,col) }}</td>
    </tr>

    </tbody>
    <tbody>
    <tr>
      <th class="row-label">Estimated Yearly Savings for Print MIS and Online Storefront</th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th>{{ savings.hoursMISSavingsAnnual | decimal(0) }}</th>
      <th>{{ savings.costMISSavingsAnnual | currency(1) }}</th>
      <th></th>
      <th></th>
      <th>{{ savings.hoursStorefrontSavingsAnnual | decimal(0) }}</th>
      <th>{{ savings.costStorefrontSavingsAnnual | currency(1) }}</th>
    </tr>
    </tbody>
    <tbody>
    <tr class="blank">
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td class="text-left pl-4 font-bold">Additional Yearly Revenue</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>Addtl. Revenue</td>
    </tr>
    <tr>
      <td class="row-label">Revenue from Shipping Previously Not Allocated to a Job</td>
      <td></td>
      <td>{{ savings.revenue.shipping.cost | currency(1) }}</td>
      <td></td>
      <td>{{ savings.revenue.shipping.costMIS | currency(1) }}</td>
      <td></td>
      <td>{{ savings.revenue.shipping.additional | currency(1) }}</td>
    </tr>
    <tr>
      <td class="row-label">Revenue from Billable Changes Previously Missed</td>
      <td></td>
      <td>{{ savings.revenue.changes.cost | currency(1) }}</td>
      <td></td>
      <td>{{ savings.revenue.changes.costMIS | currency(1) }}</td>
      <td></td>
      <td>{{ savings.revenue.changes.additional | currency(1) }}</td>
    </tr>
    <tr>
      <td class="row-label">Cost of Rework on Projects per Month</td>
      <td></td>
      <td>{{ savings.revenue.rework.cost | currency(1) }}</td>
      <td></td>
      <td>{{ savings.revenue.rework.costMIS | currency(1) }}</td>
      <td></td>
      <td>{{ savings.revenue.rework.additional | currency(1) }}</td>
    </tr>
    <tr>
      <td class="row-label">Forecasted Additional Revenue from All Sales People<small class="block">(Less Time Job Tracking, More Selling)</small></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{{ savings.revenue.forecasting.additional | currency(1) }}</td>
    </tr>
    <tr>
      <th class="row-label">Estimated Additional Yearly Revenue</th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th class="text-right"><span class="pr-2">{{ savings.revenue.total | currency(1) }}</span></th>
    </tr>
    </tbody>
    <tbody v-if="savings.vdp">
    <tr class="blank">
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td class="text-left pl-4 align-bottom font-bold">File Prep Savings Detail</td>
      <td class="align-bottom font-bold bottom-0 relative">Current Yearly Hours</td>
      <td class="align-bottom font-bold bottom-0 relative">Current Yearly Cost</td>
      <td class="align-bottom font-bold bottom-0 relative">Future Yearly Hours with Automation/VDP</td>
      <td class="align-bottom font-bold bottom-0 relative">Future Yearly Cost with Automation/VDP</td>
      <td class="align-bottom font-bold bottom-0 relative">Yearly Man Hour Savings with Automation/VDP</td>
      <td class="align-bottom font-bold bottom-0 relative">Yearly Cost Savings with Automation/VDP</td>
    </tr>
    <tr>
      <td class="row-label">Time to Pre-Flight Files</td>
      <td>{{ savings.vdp.preFlight.hours | decimal(0) }}</td>
      <td>{{ savings.vdp.preFlight.cost | currency(0) }}</td>
      <td>{{ savings.vdp.preFlight.hoursAutomation | decimal(0) }}</td>
      <td>{{ savings.vdp.preFlight.costAutomation | currency(1) }}</td>
      <td>{{ savings.vdp.preFlight.hourSavingsAnnual | decimal(0) }}</td>
      <td>{{ savings.vdp.preFlight.costSavingsAnnual | currency(1) }}</td>
    </tr>
    <tr>
      <td class="row-label">Time to Impose Files</td>
      <td>{{ savings.vdp.imposition.hours | decimal(0) }}</td>
      <td>{{ savings.vdp.imposition.cost | currency(0) }}</td>
      <td>{{ savings.vdp.imposition.hoursAutomation | decimal(0) }}</td>
      <td>{{ savings.vdp.imposition.costAutomation | currency(1) }}</td>
      <td>{{ savings.vdp.imposition.hourSavingsAnnual | decimal(0) }}</td>
      <td>{{ savings.vdp.imposition.costSavingsAnnual | currency(1) }}</td>
    </tr>
    <tr>
      <td class="row-label">Time to Develop/Design Base Template</td>
      <td>{{ savings.vdp.baseTemplate.hours | decimal(0) }}</td>
      <td>{{ savings.vdp.baseTemplate.cost | currency(0) }}</td>
      <td>{{ savings.vdp.baseTemplate.hours | decimal(0) }}</td>
      <td>{{ savings.vdp.baseTemplate.cost | currency(0) }}</td>
      <td>0</td>
      <td>$0</td>
      <td class="text-left">Same Amount of time to Develop Template</td>

    </tr>
<!--    <tr>-->
<!--      <td class="row-label">Time to Create a Job Ticket/Work Order for VDP Project</td>-->
<!--      <td>{{ savings.vdp.jobTicket.hours | decimal(0) }}</td>-->
<!--      <td>{{ savings.vdp.jobTicket.cost | currency(0) }}</td>-->
<!--      <td>{{ savings.vdp.jobTicket.hoursVDP | decimal(0) }}</td>-->
<!--      <td>{{ savings.vdp.jobTicket.costVDP | currency(1) }}</td>-->
<!--      <td>{{ savings.vdp.jobTicket.hourSavingsAnnual | decimal(0) }}</td>-->
<!--      <td>{{ savings.vdp.jobTicket.costSavingsAnnual | currency(1) }}</td>-->
<!--    </tr>-->
    <tr>
      <td class="row-label">Time to Create a Job Ticket/Work Order for VDP Project</td>
      <td>{{ savings.vdp.jobTicket.hours | decimal(0) }}</td>
      <td>{{ savings.vdp.jobTicket.cost | currency(0) }}</td>
      <td>{{ savings.vdp.jobTicket.hoursVDP | decimal(0) }}</td>
      <td>{{ savings.vdp.jobTicket.costVDP | currency(1) }}</td>
      <td>{{ savings.vdp.jobTicket.hourSavingsAnnual | decimal(0) }}</td>
      <td>{{ savings.vdp.jobTicket.costSavingsAnnual | currency(1) }}</td>
    </tr>
    <tr>
      <td class="row-label">Time to Customize Document with Variable Data</td>
      <td>{{ savings.vdp.customization.hours | decimal(0) }}</td>
      <td>{{ savings.vdp.customization.cost | currency(0) }}</td>
      <td>{{ savings.vdp.customization.hoursVDP | decimal(0) }}</td>
      <td>{{ savings.vdp.customization.costVDP | currency(1) }}</td>
      <td>{{ savings.vdp.customization.hourSavingsAnnual | decimal(0) }}</td>
      <td>{{ savings.vdp.customization.costSavingsAnnual | currency(1) }}</td>
    </tr>
    <tr>
      <td class="row-label">Time Required to Send a Proof and Make Any Required Changes</td>
      <td>{{ savings.vdp.proofing.hours | decimal(0) }}</td>
      <td>{{ savings.vdp.proofing.cost | currency(0) }}</td>
      <td>{{ savings.vdp.proofing.hoursVDP | decimal(0) }}</td>
      <td>{{ savings.vdp.proofing.costVDP | currency(1) }}</td>
      <td>{{ savings.vdp.proofing.hourSavingsAnnual | decimal(0) }}</td>
      <td>{{ savings.vdp.proofing.costSavingsAnnual | currency(1) }}</td>
    </tr>
    <tr>
      <td class="row-label">Time to Process File for Production once Approved</td>
      <td>{{ savings.vdp.processing.hours | decimal(0) }}</td>
      <td>{{ savings.vdp.processing.cost | currency(0) }}</td>
      <td>{{ savings.vdp.processing.hoursVDP | decimal(0) }}</td>
      <td>{{ savings.vdp.processing.costVDP | currency(1) }}</td>
      <td>{{ savings.vdp.processing.hourSavingsAnnual | decimal(0) }}</td>
      <td>{{ savings.vdp.processing.costSavingsAnnual | currency(1) }}</td>
    </tr>
    <tr>
      <th class="row-label">Yearly Savings with Automated File Prep</th>
      <th class="text-right">{{ savings.vdp.totals.hours | decimal(0) }}</th>
      <th class="text-right">{{ savings.vdp.totals.cost | currency(0) }}</th>
      <th class="text-right">{{ savings.vdp.totals.hoursVDP | decimal(0) }}</th>
      <th class="text-right">{{ savings.vdp.totals.costVDP | currency(1) }}</th>
      <th class="text-right">{{ savings.vdp.totals.hoursVDPSavings | decimal(0) }}</th>
      <th class="text-right">{{ savings.vdp.totals.costVDPSavings | currency(1) }}</th>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
    name: "savings-details-table",
    props: ['assessment'],
    data: function(){
        return {}
    },
    methods: {
      resolveValue: function(group, row, col){
        if(this.savings[group][row] !== undefined){
          if(this.savings[group][row][col.key] !== undefined){
            if(col.format === "$"){
              return ("$"+this.$options.filters.decimal(this.savings[group][row][col.key], 0));
            }else{
              return this.$options.filters.decimal(this.savings[group][row][col.key], 0);
            }

          }
        }
      }
    },
  computed: {


    savings: function(){
      if(this.assessment){
        return this.assessment.savings;
      }

      return null;
    },

    detailGroups: function(){
      return [
        { label: "Estimate Development", key: "estimates", rows: [
            { label: "Offset Print Estimates", key: "offset" },
            { label: "Digital Print Estimates", key: "digital" },
            { label: "Large Format Print Estimates", key: "largeFormat" },
            { label: "  Total", key: "subtotal" },
            { label: "Quote letters", key: "quoteLetters" },
          ] },
        { label: "Job Ticket Development", key: "jobTickets", rows: [
            { label: "1) Production", key: "production" },
            { label: "2) Fulfillment Only (already produced and in inventory)", key: "fulfillmentOnly" },
            { label: "Job Tickets Created Manually Each Month (From e-Mail, Phone, Snail Mail)", key: "" },
            { label: "3) Jobs Converted from Estimates", key: "converted" },
            { label: "4) Orders with all Job Specs Identified", key: "withSpecs" },
            { label: "5) Orders - You Develop all Job Specs", key: "developed" },
            { label: "6) Re-order (all production info in system from previous order)", key: "reorder" },
            { label: "7) Fulfillment Orders (already produced and in inventory)", key: "fulfillmentManual" },
            { label: "Total for All Jobs Produced per Year", key: "totals" },
          ] },
        { label: "Inventory", key: "inventory", rows: [
            { label: "Time Spent Managing Inventory", key: "totals" },
          ] },
        { label: "Job Tracking / Scheduling", key: "jobTracking", rows: [
            { label: "Administration/CSR Job Tracking", key: "admin" },
            { label: "Owner/Sales/Production Manager/Supervisor Job Tracking", key: "owner" },
            { label: "Production Meetings", key: "meetings" },
            { label: "Total", key: "totals" },
          ] },
        { label: "Shipment Tracking", key: "shipments", rows: [
            { label: "Amount of Time to Track each UPS/FedEx Package per Job", key: "tracking" },
            { label: "Time to Create Shipping Docs", key: "docs" },
            { label: "Total", key: "totals" },
          ] },
        { label: "Purchasing/Accounts Payable Processing", key: "purchasing", rows: [
            { label: "Create PO", key: "create" },
            { label: "Reconcile PO", key: "reconcile" },
            { label: "Accounts Payable Processing (invoices for purchases for jobs)", key: "processing" },
            { label: "Total", key: "totals" },
          ] },
        { label: "External Purchase vs. Internally Produced", key: "externalVsInternal", rows: [
            { label: "Cost", key: "totals" },
          ] },
        { label: "Billing/Accounts Receivable Processing", key: "billing", rows: [
            { label: "Create Invoice", key: "create" },
            { label: "Log Info into Accounting System", key: "accounting" },
            { label: "Cost of Carrying $s on P&L", key: "plCost" },
            { label: "Total", key: "totals" },
          ] },
        { label: "Labor Tracking", key: "labor", rows: [
            { label: "Time Spent Tracking Labor per Day per Employee", key: "totals" },
          ] },
        { label: "Errors/Rework/Waste", key: "errors", rows: [
            { label: "Reduced Errors - Result of Information Accuracy in Software", key: "reducedSoftware" },
            { label: "Reduced Errors - Content Errors Charged Internally", key: "reducedContent" },
          ] },
        { label: "Billable Customer Changes", key: "changes", rows: [
            { label: "# of Jobs where Billable Changes are Missed when Invoicing", key: "totals" },
          ] },
        { label: "Report Development", key: "reports", rows: [
            { label: "Sales by Customer", key: "sales" },
            { label: "Actual vs. Estimate", key: "actualVsEstimate" },
            { label: "Customer Profitability", key: "customerProfit" },
            { label: "Product Line Profitability", key: "productProfit" },
            { label: "Weekly Sales", key: "weeklySales" },
            { label: "Monthly Sales", key: "monthlySales" },
            { label: "Jobs Shipped and not Invoiced", key: "notInvoiced" },
            { label: "Employee Productivity", key: "productivity" },
            { label: "Real Time Schedule Report", key: "schedule" },
            { label: "Payroll", key: "payroll" },
            { label: "", key: "totals" },
          ] },
        { label: "Miscellaneous", key: "misc", rows: [
            { label: "Opportunity for Cost Savings Not Identified Above", key: "" },
            { label: "Info from Customer - List Area of Savings Opportunity", key: "totals" },
          ] },

      ]
    },

    summaryGroups: function(){
      return [
        { label: "Estimate Development", key: "estimates"},
        { label: "Job Ticket Development", key: "jobTickets"},
        { label: "Inventory", key: "inventory" },
        { label: "Job Tracking / Scheduling", key: "jobTracking" },
        { label: "Shipment Tracking", key: "shipments" },
        { label: "Purchasing/Accounts Payable Processing", key: "purchasing" },
        { label: "Billing/Accounts Receivable Processing", key: "billing" },
        { label: "Labor Tracking", key: "labor" },
        { label: "Errors/Rework/Waste", key: "errors" },
        { label: "Billable Customer Changes", key: "changes" },
        { label: "Report Development", key: "reports" },
        { label: "Miscellaneous", key: "misc" },
      ]
    },

    detailCols: function(){
      return [
        { key: "hours", format:""},
        { key:"cost", format: "$"},
        { key:"hoursMIS", format: ""},
        { key:"costMIS", format: "$"},
        { key:"hoursMISSavings", format: ""},
        { key:"costMISSavings", format: "$"},
        { key:"hoursStorefront", format: ""},
        { key:"costStorefront", format: "$"},
        { key:"hoursStorefrontSavings", format: ""},
        { key:"costStorefrontSavings", format: "$"}]
    },

    costs: function(){
      if(this.assessment){
        return this.assessment.costs.vdp;
      }
      return null;
    },


  }
}
</script>

<style scoped>


.savings-summary-table {

}

tr {
  @apply bg-white;
}

th {
  @apply border bg-gray-100 align-bottom;
  padding: 0.5rem 0.5rem;
}

th.row-label {
  @apply px-4;
}


td {
  @apply text-right border w-16;
  padding: 0.5rem 1.0rem;
  text-align: right;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}

.group-label {
  @apply text-left font-bold underline pt-8;
}
.row-label {
  @apply text-left;
}





td[data-null="true"]{

}

tbody tr:hover td {
  @apply bg-blue-50 cursor-pointer;
}

tr.blank {
  @apply bg-transparent;
}

tr.blank td {
  @apply border-none;
}

</style>