


<template>
<div class="summary-export max-w-3xl">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold" v-if="options.instructions">{{ options.instructions }}</p>
  </div>
  <div>
    <h2 class="font-bold text-xl pb-2 border-b-2">Export Options</h2>
  </div>


  <div class="py-4 border-b flex justify-between items-center">
    <div class="flex items-center">

      <h2 class="text-lg">Mail Assessment Scorecard</h2>
    </div>
    <div>
      <button class="button button-blue" @click="onPreview('scorecard')">Preview</button>
    </div>
  </div>
  <div class="py-4 border-b flex justify-between items-center">
    <div class="flex items-center">

      <h2 class="text-lg">ROI Summary</h2>
    </div>
    <div>
      <button class="button button-blue" @click="onPreview('roi')">Preview</button>
    </div>
  </div>
  <div class="py-4 flex justify-between items-center hidden">
    <div class="flex items-center">

      <h2 class="ml-4 text-lg">Photo Gallery (0 images)</h2>
    </div>
    <div>
      <button class="button button-blue" @click="onPreview('photos')">Preview</button>
    </div>
  </div>
  <div class="py-4 pt-8 border-b">
    <div>
      <h2 class="font-bold text-xl pb-2 border-b-2">Findings Report</h2>

    </div>

    <div class="flex flex-wrap">
      <div class="w-1/2">



        <h3 class="text-lg font-bold mt-6 mb-2">Mail Software</h3>
        <div class="flex py-2">
          <input @change="onChange"  type="checkbox" id="mail1" class="flex-shrink-0 w-6 h-6" value="BccMailManagerEtAl" v-model="assessment.exportSettings.solutions.MailSoftware"/>
          <h2 class="ml-4 text-lg">BCC Data Quality & Postal Solutions</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="mail2" class="flex-shrink-0 w-6 h-6" value="Lorton" v-model="assessment.exportSettings.solutions.MailSoftware"/>
          <h2 class="ml-4 text-lg">Lorton</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange" type="checkbox" id="mail3" class="flex-shrink-0 w-6 h-6" value="ProcessDirector" v-model="assessment.exportSettings.solutions.MailSoftware"/>
          <h2 class="ml-4 text-lg">Ricoh Process Director</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange" type="checkbox" id="mail4" class="flex-shrink-0 w-6 h-6" value="PlanetPressConnect" v-model="assessment.exportSettings.solutions.MailSoftware"/>
          <h2 class="ml-4 text-lg">ObjectifLune PlanetPress</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange" type="checkbox" id="mail5" class="flex-shrink-0 w-6 h-6" value="PresConnect" v-model="assessment.exportSettings.solutions.MailSoftware"/>
          <h2 class="ml-4 text-lg">ObjectifLune PresConnect</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange" type="checkbox" id="mail6" class="flex-shrink-0 w-6 h-6" value="QuadientAims" v-model="assessment.exportSettings.solutions.MailSoftware"/>
          <h2 class="ml-4 text-lg">Quadient AIMS</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange" type="checkbox" id="mail7" class="flex-shrink-0 w-6 h-6" value="QuadientBulkMailer" v-model="assessment.exportSettings.solutions.MailSoftware"/>
          <h2 class="ml-4 text-lg">Quadient Bulk Mailer</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange" type="checkbox" id="mail8" class="flex-shrink-0 w-6 h-6" value="ObjectifConnectSend" v-model="assessment.exportSettings.solutions.MailSoftware"/>
          <h2 class="ml-4 text-lg">ObjectifLune Connect Send</h2>
        </div>



      </div>
      <div class="w-1/2">

        <h3 class="text-lg font-bold mt-6 mb-2">Services</h3>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="other1" class="flex-shrink-0 w-6 h-6" value="RicohReturnMail" v-model="assessment.exportSettings.solutions.Other"/>
          <h2 class="ml-4 text-lg">Ricoh Return Mail Services</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="other1" class="flex-shrink-0 w-6 h-6" value="RicohHybridMail" v-model="assessment.exportSettings.solutions.Other"/>
          <h2 class="ml-4 text-lg">Ricoh Hybrid Mail Services</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="other2" class="flex-shrink-0 w-6 h-6" value="RicohConsultingServices" v-model="assessment.exportSettings.solutions.Other"/>
          <h2 class="ml-4 text-lg">Ricoh Business Consulting</h2>
        </div>
        <div class="flex items-center py-2">
          <input @change="onChange"  type="checkbox" id="other3" class="flex-shrink-0 w-6 h-6" value="RicohMarketingServices" v-model="assessment.exportSettings.solutions.Other"/>
          <h2 class="ml-4 text-lg">Ricoh Marketing Services</h2>
        </div>

      </div>



    </div>


  </div>

  <div class="pt-4 border-t">
    <div class="pb-4 flex justify-between">
      <div class="pr-4">

      </div>
      <div class="pl-4">
        <button @click="openPreview" class="button button-blue button-next">Generate Full Report</button>
      </div>

    </div>
  </div>


</div>
</template>

<script>
export default {
    name: "summary-mail-export",
    props: {
      options: {
        default: function(){
          return {
            label: "Solution Costs",
            costsKey: "",
            instructions: "Costs instructions",
          }
        },

      },

    },
    data: function(){
        return {
            hasUnsavedChanges: false
        }
        },
    methods: {
      onPreview: function(id){

        if(id === "selfscorecard"){

          this.$router.push({
            name: "Assessment",
            params: this.$route.params,
            query: {
              tab: "pre",
              section: "selfscore"
            }
          })

        }else if(id === "scorecard"){
          this.$router.push({
            name: "Assessment",
            params: this.$route.params,
            query: {
              tab: "pre",
              section: "walkthroughscore"
            }
          })
        }else if(id === "savings"){
          this.$router.push({
            name: "Assessment",
            params: this.$route.params,
            query: {
              tab: "summary",
              section: "savings"
            }
          })
        }else if(id === "roi"){
          this.$router.push({
            name: "Assessment",
            params: this.$route.params,
            query: {
              tab: "summary",
              section: "roi"
            }
          })
        }else if(id === "photos"){
          console.log(id);
        }
      },

      onChange: function(e){
        console.log(e.target.value)
        this.hasUnsavedChanges = true;
        this.$emit("change");
      },

      onClickRadio: function(e){
        console.log("radio click",e.target.value)
        // this.hasUnsavedChanges = true;
        // this.$emit("change");
      },


      openPreview: function(){

        let updateSettings = {
          exportSettings: this.assessment.exportSettings
        }
        if(this.hasUnsavedChanges){
          this.$sdm.updateAssessment(updateSettings, this.assessment.id).then(()=>{
            this.$router.push({
              name: "Reports",
              query: {
                r: this.$route.params.assessment,
                t: "mail"
              }
            })
          })
        }else{
          this.$router.push({
            name: "Reports",
            query: {
              r: this.$route.params.assessment,
              t: "mail"
            }
          })
        }

      },

      openSnapshotPreview: function(){

        let updateSettings = {
          exportSettings: this.assessment.exportSettings
        }
        if(this.hasUnsavedChanges){
          this.$sdm.updateAssessment(updateSettings, this.assessment.id).then(()=>{
            this.$router.push({
              name: "Reports",
              query: {
                r: this.$route.params.assessment,
                t: "pws"
              }
            })
          })
        }else{
          this.$router.push({
            name: "Reports",
            query: {
              r: this.$route.params.assessment,
              t: "pws"
            }
          })
        }

      },

      getSolutionFlags: function(){
        let flags = "";
        for (var i=0; i<12; i++){
          flags = flags + (this['solution'+i]?"1":"0");
        }

        return flags;
      }

    },
    computed: {
      assessment: function(){
        return this.$store.state.workflowAssessment;
      }
    }
}
</script>

<style scoped>


.summary-export {

}

</style>