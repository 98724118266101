import { saveAs } from 'file-saver'

export default {
    install(Vue){

        function asyncImageLoader(src){
            return new Promise((resolve, reject) => {
                let img = new Image();
                img.onload = () => resolve(img)
                img.onerror = reject;
                img.src = src;
            })
        }

        Vue.prototype.$exportTools = {

            saveSvgToPng: function(svg,filename, w, h){

                let _w = 1000;
                let _h = 1000;

                let svgXML = (new XMLSerializer()).serializeToString(svg);
                let canvas = document.createElement('canvas');

                if(!w && !h){
                    if(svg.getAttribute("width") && svg.getAttribute("height")){
                        _w = parseInt(svg.getAttribute("width"));
                        _h = parseInt(svg.getAttribute("height"));
                    }
                }



                console.log("svg size", w, h);

                canvas.width = _w;
                canvas.height = _h;
                let ctx = canvas.getContext('2d');

                let srcString =  "data:image/svg+xml;base64," + btoa(svgXML);

                asyncImageLoader(srcString).then((tmpImage)=>{
                    ctx.drawImage(tmpImage, 0, 0);
                    canvas.toBlob((blob) => {
                        saveAs(blob,filename+".png")
                    })
                });

            },
        };
    }
}
