import Vue from 'vue'
import VueRouter from 'vue-router'
import Assessments from '../views/Assessments.vue'
//import CcmAssessments from '../views/CcmAssessments.vue'
import Login from '../views/Login.vue'
import Customers from '../views/Customers.vue'
import AssessmentDetails from '../views/AssessmentDetails.vue'
import UserProfile from "@/views/UserProfile";
import ColorProject from "@/views/ProjectEstimateCalculator";
//import ColorProfiles from "@/views/ColorProfiles";
//import ColorProjects from "@/components/ColorProjects";
import ColorCalculators from "@/views/Calculators";
import SpecialtyColorCostCalculator from "@/views/SpecialtyColorCostCalculator";
import EquipmentRateCalculator from "@/views/EquipmentRateCalculator";
//import CloudKitRecords from "@/views/CloudKitRecords";
//import CloudKitRecordDetails from "@/views/CloudKitRecordDetails";

//import Admin from "@/views/Admin";

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),

    meta: {
      requiresAuth: true,
    }
  },

  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminDashboard.vue'),
    meta: {
      requiresAuth: true,
      module: "admin"
    }
  },
  {
    path: '/printshop-config',
    name: 'PrintShopConfigurator',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrintShopConfig.vue'),
    meta: {
      requiresAuth: true,
      module: "printshopconfig"
    }
  },
  {
    path: '/printshop-config/:configuration',
    name: 'PrintShopConfiguratorDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrintShopConfigDetails.vue'),
    meta: {
      requiresAuth: true,
      module: "printshopconfig"
    }
  },

  {
    path: '/needs',
    name: 'NeedsAnalysis',
    component: () => import(/* webpackChunkName: "about" */ '../views/NeedsAnalysis.vue'),
    meta: {
      requiresAuth: true,
      module: "needs"
    }
  },
  {
    path: '/needs/:analysis',
    name: 'NeedsAnalysisDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/NeedsAnalysisDetails.vue'),
    meta: {
      requiresAuth: true,
      module: "needs"
    }
  },
    //Activate these routes for accessing legacy ipad files
  // {
  //   path: '/legacy',
  //   name: 'Legacy',
  //   component: CloudKitRecords,
  //   meta: {
  //     requiresAuth: true,
  //     module: "admin"
  //   }
  // },
  // {
  //   path: '/legacy/:record',
  //   name: 'LegacyRecord',
  //   component: CloudKitRecordDetails,
  //   meta: {
  //     requiresAuth: true,
  //     module: "admin"
  //   }
  // },

  {
    path: '/assessments',
    name: 'Assessments',
    component: Assessments,
    meta: {
      requiresAuth: true,
      module: 'assessments'
    }
  },

  {
    path: '/mailing',
    name: 'MailingAssessments',
    component: () => import(/* webpackChunkName: "about" */ '../views/MailingAssessments.vue'),
    meta: {
      requiresAuth: true,
      module: 'mailing'
    }
  },



  {
    path: '/calculators',
    name: 'Calculators',
    component: ColorCalculators,
    meta: {
      requiresAuth: true,
      module: 'calculators'
    }
  },

  {
    path: '/calculators/project-estimate',
    name: 'New Project Estimate',
    component: ColorProject,
    meta: {
      requiresAuth: true,
      module: 'calculators'
    }
  },

  {
    path: '/calculators/project-estimate/:project',
    name: 'Project Estimate',
    component: ColorProject,
    meta: {
      requiresAuth: true,
      module: 'calculators'
    }
  },

  {
    path: '/calculators/cost',
    name: 'New Specialty Color Cost Calc',
    component: SpecialtyColorCostCalculator,
    meta: {
      requiresAuth: true,
      module: 'calculators'
    }
  },

  {
    path: '/calculators/cost/:project',
    name: 'Specialty Color Cost Calc',
    component: SpecialtyColorCostCalculator,
    meta: {
      requiresAuth: true,
      module: 'calculators'
    }
  },

  {
    path: '/calculators/equipment-rates',
    name: 'New Equipment Rate Calc',
    component: EquipmentRateCalculator,
    meta: {
      requiresAuth: true,
      module: 'calculators'
    }
  },

  {
    path: '/calculators/equipment-rates/:project',
    name: 'Equipment Rate Calc',
    component: EquipmentRateCalculator,
    meta: {
      requiresAuth: true,
      module: 'calculators'
    }
  },



  {
    path: '/profile',
    name: 'Profile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      module: 'profile'
    }
  },



  {
    path: '/admin/user/:userid',
    name: 'EditUser',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      module: 'admin'
    }
  },

  {
    path: '/admin/org/:orgid',
    name: 'EditOrg',
    component: () => import(/* webpackChunkName: "about" */ '../views/CustomerProfile.vue'),
    meta: {
      requiresAuth: true,
      module: 'admin'
    }
  },

  {
    path: '/assessments/:assessment',
    name: 'Assessment',
    component: AssessmentDetails,
    meta: {
      requiresAuth: true,
      module: 'assessments'
    }
  },

  {
    path: '/report/',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportGenerator.vue'),
    meta: {
      requiresAuth: true,
      module: 'assessments'
    }
  },
  {
    path: '/config-report/',
    name: 'ConfigReport',
    component: () => import(/* webpackChunkName: "about" */ '../views/GenericReportGenerator.vue'),
    meta: {
      requiresAuth: true,
      module: 'printshopconfig'
    }
  },
  {
    path: '/needs-report/',
    name: 'NeedsReport',
    component: () => import(/* webpackChunkName: "about" */ '../views/NeedsAnalysisReportGenerator.vue'),
    meta: {
      requiresAuth: true,
      module: 'needs'
    }
  },
  {
    path: '/snapshot/',
    name: 'SnapshotReports',
    component: () => import(/* webpackChunkName: "about" */ '../views/SnapshotReportGenerator.vue'),
    meta: {
      requiresAuth: true,
      module: 'assessments'
    }
  },
  {
    path: '/ccm/:assessment',
    name: 'CCMAssessment',
    component: AssessmentDetails,
    meta: {
      requiresAuth: true,
      module: 'workflow'
    }
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    meta: {
      requiresAuth: true,
      module: 'customers'
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login,
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import(/* webpackChunkName: "about" */ '../views/Reset.vue'),
    meta: {
      module: 'reset'
    }
  },
]

const router = new VueRouter({
  routes
})


export default router
