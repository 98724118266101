<template>
  <div class="side-panel absolute top-0 right-0 w-full h-full bg-white border-l bg-gray-50">
    <div class="flex justify-between px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Online Questionnaire</h2>
      <img @click="close" class="h-4 inline-block mt-1 cursor-pointer opacity-50 hover:opacity-100" src="../assets/icons/close-icon.svg" />
    </div>
    <div class="p-8">

      <p class="">{{ introText }}</p>
      <p class="bg-yellow-200 mt-4 p-1 hidden">This feature is currently disabled for MAP assessments. A multi-user version of this feature will be released soon.</p>
      <div class="mt-8">
        <label>Customer Email</label>
        <div>

          <input type="email" class="w-full block p-2 border mr-4" v-model="email" />


        </div>

      </div>

      <div class="mt-4">
        <label class="block">Access Code:</label>
        <div class="flex">
          <input type="text" readonly class="block w-full p-2 rounded border w-full bg-white mr-4" placeholder="Generate a code for access" :value="questionnaire?questionnaire.code:''"/>
          <button class="w-48 button button-blue flex-shrink-0" @click="generate">Generate Code</button>
        </div>
      </div>

      <div class="mt-4" v-if="questionnaire">
        <label class="block">Questionnaire URL:</label>
        <div class="flex">
          <p class="mt-2">
            <a class="text-brand-blue hover:text-brand-blue-dark underline cursor-pointer" :href="linkUrl" target="_blank">{{ linkUrl }}</a>
          </p>

        </div>
      </div>

      <div class="mt-4" v-if="status">
        <label class="block">Activity:</label>
        <div class="flex">
          <p class="mt-2 p-2 bg-gray-100 border rounded" >

            <span>{{ status }} </span>
            <span v-if="questionnaire && questionnaire.submitted" class="block">
              <a @click="importData" class="underline text-brand-blue hover:text-brand-blue-dark cursor-pointer">Click here to import data.</a>
            </span>
          </p>

        </div>
      </div>


      <div class="mt-6 pt-6 border-t">
        <a class="w-full block button button-green flex-shrink-0 text-center" :href="mailLink" v-if="questionnaire">Send Invite</a>
        <button class="w-full block button flex-shrink-0 text-center" @click="onSendLink" v-else>Send Invite</button>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "side-panel-questionnaire",
  components: {},
  props: ["questionnaire"],
  mounted: function(){
    if(this.questionnaire){
      this.email = this.questionnaire.email;
    }else{
      this.email = this.assessment.customerProfile.customerEmail;
    }
  },
  data: function () {
    return {
        email: ""
    }
  },
  methods: {
    close: function(){
      this.$emit('close');
    },

    importData: function(){
      console.log("import");
      if(confirm("This will write over any data you may already have in this assessment. Are you sure?")){
        this.$sdm.loadQuestionnaire(this.questionnaire.id)
        .then((response) => {
          //this.$sdm.updateAssessmentWithCustomerData(response);
          for(const key in response.customerProfile){
            if(response.customerProfile[key] !== this.assessment.customerProfile[key]){
              this.assessment.customerProfile[key] = response.customerProfile[key];
            }
          }

          for(const key in response.interview){

            let answer = response.interview[key];
            let tgt = this.assessment.interview.questions[key];

            for( const prop in answer ){
              if(prop === "notes"){
                tgt.notes = answer.notes;
              }else{
                tgt.answer[prop] = answer[prop];
              }
            }
          }
          this.$sdm.calculate(this.assessment);

          this.$store.state.hasUnsavedChanges = true;

          this.assessment.importStatus = {
            imported: Date.now(),
            id: this.questionnaire.id,
            email: this.questionnaire.email
          }

          alert("The customer data has been imported.");
        })
      }
    },

    generate: async function(){


      if(this.questionnaire){
        if(!confirm("There is already an existing link for this customer. This will disable the current link. Click OK to create a new one.")){
          return;
        }
      }



      let valid = this.isValidEmail(this.email);
      //console.log("validating email", this.email, valid);
        if(this.email && valid){
          this.assessment.customerProfile.customerEmail = this.email.toLowerCase();

          let q;

          if(this.isMailing){
            q = this.$sdm.generateMailingQuestionnaire(this.assessment);
          } else if(this.isCCM){
            q = this.$sdm.generateCCMQuestionnaire(this.assessment);
          }
          else if(this.isMap){
            q = this.$sdm.generateMapQuestionnaire(this.assessment);
          }
          else if(this.isPmp){
            q = this.$sdm.generatePmpQuestionnaire(this.assessment);
          }else{
            q = this.$sdm.generateWorkflowQuestionnaire(this.assessment);
          }

          q.admin = this.$store.getters.getUserProfile;

          if(this.questionnaire && this.questionnaire.id){

            console.log("Update", q, this.questionnaire)

            await this.$sdm.updateQuestionnaire(q, this.questionnaire.id)
                .then((response) => {
                  if(response.ref){
                    q.id = response.ref.value.id;
                    this.$store.state.questionnaire = q;
                  }
                })
                .catch((error) => {
                  console.log("Error", error);
                })
          }else{
            console.log("New Questionnaire", q)
            await this.$sdm.saveNewQuestionnaire(q)
                .then((response) => {
                  if(response.ref){
                    q.id = response.ref.value.id;
                    this.$store.state.questionnaire = q;
                  }
                })
                .catch((error) => {
                  console.log("Error", error);
                })

          }




        }else{
          alert("Please enter a valid customer email address.");
        }
    },
    isValidEmail: function(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    },

    onSendLink: function(){
      if(!this.questionnaire){
        alert("Please generate an access code first.");
      }

    }
  },
  computed: {

    assessment: function(){
      return this.$store.state.workflowAssessment;
    },

    isCCM: function(){
      if(this.assessment && this.assessment.assessmentType){
        return (this.assessment.assessmentType === 'CCM')
      }
      return false;
    },

    isMailing: function(){
      if(this.assessment && this.assessment.assessmentType){
        return (this.assessment.assessmentType === 'Mail')
      }
      return false;
    },

    isMap: function(){
      if(this.assessment && this.assessment.assessmentType){
        return (this.assessment.assessmentType === 'MAP')
      }
      return false;
    },

    isPmp: function(){
      if(this.assessment && this.assessment.assessmentType){
        return (this.assessment.assessmentType === 'PMP')
      }
      return false;
    },

    introText: function(){

      if(this.isMap){
        return "Generate a custom link to allow a customer to provide information about their Marketing Automation Platform:"
      }

      if(this.isPmp){
        return "Generate a custom link to allow a customer to provide information about their Project Management Platform:"
      }

      if(this.isMailing){
        return "Generate a custom link to allow a customer to provide statistics on their Mail Workflow:"
      }

      if(this.isCCM){
        return "Generate a custom link to allow a customer to provide statistics on their Customer Communications:"
      }else{
        return "Generate a custom link to allow a customer to provide statistics on their Production Printing Workflow:"
      }
    },

    profile: function(){
      return this.$store.getters.getUserProfile();
    },

    linkUrl: function(){
      if(this.questionnaire){
        return "https://workflow.ricohconsultingservices.com/#/"+this.questionnaire.id;
      }

      return ""
    },

    mailLink: function(){



      if(this.email){

        let mailingInvite = "We look forward to working with you to identify initiatives to achieve best-in-class workflow and solutions for your operation.\n\nAs we prepare for the mail workflow assessment, please access the online form through the link below to provide details pertaining to your organization."

        let pwInvite = "We look forward to working with you to identify initiatives to achieve best-in-class workflow and solutions for your operation.\n\nAs we prepare for the workflow assessment, please access the online form through the link below to provide details pertaining to your organization."
        let ccmInvite = "We look forward to working with you to identify initiatives to achieve best-in-class customer communications management for your organization.\n\nAs we prepare for the CCM assessment, please access the online form through the link below to provide details pertaining to your organization."
        let inviteText = this.isCCM?ccmInvite:pwInvite;



        console.log("invite text", this.isCCM, inviteText);

        let subject = encodeURIComponent(this.isCCM?"Ricoh Customer Communication Management Assessment":"Ricoh Production Printing Workflow Assessment");

        if(this.isMailing){
          inviteText = mailingInvite
          subject = encodeURIComponent("Ricoh Mail Workflow Assessment")
        }

        let body = encodeURIComponent(`Hello,\n\n${inviteText} \n\nPlease provide as much detail as possible. It is not necessary to fill in all of the data, but the more details that you provide will allow us to perform a more thorough analysis.\n\nFor areas that are not applicable, please enter a number 0 or leave blank.\n\n---------------------------------------------------------------------------------\nQuestionnaire link for ${this.assessment.customerProfile.organizationName}:\n---------------------------------------------------------------------------------\n\n${this.linkUrl}\n\nAccess Code: ${this.questionnaire.code}\n\n\n`);

        let url =  `mailto:${ this.email }?subject=${subject}&body=${body}`;
        //console.log(url);
        return url;
      }

      return ""
    },

    hasLatestData: function(){
      if(this.questionnaire && this.questionnaire.submitted){
        if(this.assessment.importStatus && (this.assessment.importStatus.imported > this.questionnaire.submitted)){
          return true;
        }else{
          return false;
        }
      }
      return true; //although no data, it still technically up to date
    },

    status: function(){
      if(this.questionnaire){
        if(this.questionnaire.submitted){

          if(this.hasLatestData){
            let time = new Date(this.assessment.importStatus.imported);
            return "Questionnaire data was imported on "+time.toLocaleString()+" and this assessment is up to date.";
          }else{
            let time = new Date(this.questionnaire.submitted);
            return "Customer questionnaire was submitted on "+time.toLocaleString()+".";
          }

        }else{
          return "Customer has not submitted a questionnaire from this link yet."
        }

      }

      return "";
    }



  }
}
</script>

<style scoped>


.side-panel-new-assessment {

}

</style>
