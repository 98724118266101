<template>
  <div class="">
    <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Yearly Impression Costs</h2>
      <div class="text-xs">
        <button class="button border-gray-400 mr-4" @click="save"><img class="-ml-2 cursor-pointer opacity-50 hover:opacity-100 h-3 inline-block transform rotate-180 mr-1" src="../../assets/icons/next-arrow-icon-black.svg" /> Back to Rates</button>
      </div>
    </div>
    <div class="p-8 pt-4">




      <div class="fields pt-2 mb-4">


        <p class="flex items-center justify-between pt-0 text-sm field">
          <label>Cost Per Color Click</label>
          <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="costPerClickColor" format="$" precision="4" @change="onChange"></number-input>
          </span>
        </p>

        <p class="flex items-center justify-between pt-0 text-sm field">
          <label>Estimated Color Impressions per Year</label>
          <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="impressionsColor" precision="0" @change="onChange"></number-input>
          </span>
        </p>

        <div class="total flex items-center justify-between mt-4">
          <label class="font-bold">Total Yearly Color Impression Cost</label>
          <div class="w-48">
            <span class="block w-full px-2 text-right font-bold">{{ impressionCostColor | currency(1) }}</span>
          </div>
        </div>
      </div>
      <div class="fields pt-2 mb-4">
        <p class="flex items-center justify-between mt-8 text-sm field">
          <label>Cost Per B&W Click</label>
          <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="costPerClickBW" format="$" precision="4" @change="onChange"></number-input>
          </span>
        </p>

        <p class="flex items-center justify-between pt-0 text-sm field">
          <label>Estimated B&W Impressions per Year</label>
          <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="impressionsBW" precision="0" @change="onChange"></number-input>
          </span>
        </p>


        <div class="total flex items-center justify-between mt-4">
          <label class="font-bold">Total Yearly BW Impression Cost</label>
          <div class="w-48">
            <span class="block w-full px-2 text-right font-bold">{{ impressionCostBW | currency(1) }}</span>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import NumberInput from "@/components/NumberInput";
export default {
  name: "side-panel-rates-color",
  components: {NumberInput},
  props: ["rates"],
  mounted: function(){
    this.hydrate();
  },
  data: function () {
    return {
      impressionCostColor: 0,
      costPerClickColor: 0,
      impressionsColor: 0,
      impressionCostBW: 0,
      costPerClickBW: 0,
      impressionsBW: 0
    }
  },
  methods: {


    close: function(){
      this.$emit('close');
    },

    cancel: function(){
      this.$emit('close');
    },

    save: function(){

      this.rates.summary.impressionCostColor = this.impressionCostColor;
      this.rates.equipment.costPerClickColor = this.costPerClickColor;
      this.rates.equipment.impressionsColor = this.impressionsColor;
      this.rates.summary.impressionCostBW = this.impressionCostBW;
      this.rates.equipment.costPerClickBW = this.costPerClickBW;
      this.rates.equipment.impressionsBW = this.impressionsBW;
      //this.rates.equipment.total =  this.total


      this.$emit('change');
      this.$emit('close');
    },

    hydrate: function(){

          this.impressionCostColor = this.rates.summary.impressionCostColor;
          this.costPerClickColor =  this.rates.equipment.costPerClickColor;
          this.impressionsColor =  this.rates.equipment.impressionsColor;
          this.impressionCostBW = this.rates.summary.impressionCostBW;
          this.costPerClickBW =  this.rates.equipment.costPerClickBW;
          this.impressionsBW =  this.rates.equipment.impressionsBW;
          //this.total =  this.rates.equipment.total;


    },

    onChange: function(){
      this.calculate();
    },

    calculate: function(){
      this.impressionCostColor = this.impressionsColor * this.costPerClickColor;
      this.impressionCostBW = this.impressionsBW * this.costPerClickBW;
      //this.total =  this.impressionCostColor + this.impressionCostBW;
    }

  },
  computed: {
    
  }
}
</script>




<style scoped>


.side-panel-new-assessment {

}

.fields p:last-of-type {
  @apply border-black border-b pb-2;
}

.fields p:last-of-type .input {
  border-color: transparent;
}


</style>