<template>
  <div class="side-panel absolute top-0 right-0 w-full h-full max-w-2xl bg-white border-l bg-gray-50 overflow-y-scroll">
    <div class="flex justify-between px-8 py-4 border-b bg-white sticky top-0">
      <h2 class="text-xl leading-none">Evaluation Info</h2>
      <img @click="close" class="h-4 inline-block mt-1 cursor-pointer opacity-50 hover:opacity-100" src="../assets/icons/close-icon.svg" />
    </div>
    <div class="p-8 px-6">
      <div class="">

        <h3 class="px-2 font-bold text-xl pb-3 border-b border-gray-500 border-dashed"><span class="mr-2">{{  question.question }}</span></h3>
        <h4 class="px-2 mt-2 caption border-b border-dashed border-gray-500 pt-2 pb-6" v-html="question.caption"></h4>


      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "side-panel-survey-rating",
  components: {},
  mounted() {

  },
  props: ["question"],
  data: function () {
    return {

    }
  },
  methods: {
    close: function(){
      this.$emit('close');
    },

  },
  computed: {

  }
}
</script>

<style>

.caption p + p {
  margin-top: 1em;
}
</style>

<style scoped>


.side-panel-new-assessment {

}

.criteria:hover {
  @apply bg-white;
}


.criteria:hover .marker {
  @apply border-gray-200;
  color: black;
}

.criteria[data-active='true'] {
  @apply bg-blue-50;
}

.criteria[data-active='true'] .marker {
  @apply bg-white;
}

.criteria[data-active='true'] .marker-5{
  border-color: #519F50;
  opacity: 1.0;
  color: #417F30;
}

.criteria[data-active='true'] .marker-4 {
  border-color: #44C644;
  opacity: 1.0;
  font-weight: bold;
  color: #418F40;
}

.criteria[data-active='true'] .marker-3{
  border-color: #F59E0C;
  opacity: 1.0;
  font-weight: bold;
  color: #D57E0C;
}

.criteria[data-active='true'] .marker-2{
  border-color: #ED4343;
  opacity: 1.0;
  font-weight: bold;
  color: #ED4343;
}

.criteria[data-active='true'] .marker-1{
  border-color: #ED4343;
  opacity: 1.0;
  font-weight: bold;
  color: #ED4343;
}

.criteria[data-active='true'] .marker-NA{
  border-color: #E5E7EB;
  opacity: 1.0;
  font-weight: bold;
  color: black;
}

.criteria:last-child {

}






</style>
