<template>
  <div class="">
    <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Yearly Equipment Expenses</h2>
      <div class="text-xs">
        <button class="button border-gray-400 mr-4" @click="save"><img class="-ml-2 cursor-pointer opacity-50 hover:opacity-100 h-3 inline-block transform rotate-180 mr-1" src="../../assets/icons/next-arrow-icon-black.svg" /> Back to Rates</button>
      </div>
    </div>
    <div class="p-8 pt-4">
      <div class="text-sm border-b border-brand-blue mb-2">
        <span class="inline-block pb-1 mr-8 cursor-pointer hover:text-brand-blue hover:opacity-100" :class="equipment.expenses.summarized?'opacity-50':'border-b-4 border-brand-blue font-bold text-brand-blue'" @click="setSummarized(false)">Use Itemized Calculation</span>
        <span class="inline-block pb-1 cursor-pointer hover:text-brand-blue hover:opacity-100" :class="equipment.expenses.summarized?'border-b-4 border-brand-blue font-bold text-brand-blue':'opacity-50'" @click="setSummarized(true)">Use Summarized Value</span>
      </div>

      <div class="" v-if="equipment.expenses.summarized">
        <p class="flex items-center justify-between pt-2 text-sm field">
          <label>Total Equipment Expense</label>
          <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="equipment.expenses.total" format="$" precision="0" @change="onChange"></number-input>
          </span>
        </p>
      </div>


      <div class="fields pt-2 mb-4" v-else >


        <p class="flex items-center justify-between pt-0 text-sm field">
          <label>Lease Payment or Equipment Depreciation</label>
          <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="equipment.expenses.lease" format="$" precision="0" @change="onChange"></number-input>
          </span>
        </p>

        <p class="flex items-center justify-between pt-0 text-sm field">
          <label>Equipment Repair, Maintenance and Supplies</label>
          <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="equipment.expenses.repairs" format="$" precision="0" @change="onChange"></number-input>
          </span>
        </p>

        <p class="flex items-center justify-between pt-0 text-sm field">
          <label>Annual Equipment Operator Labor for this Equipment</label>
          <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="equipment.expenses.labor" format="$" precision="0" @change="onChange"></number-input>
          </span>
        </p>



        <div class="total flex items-center justify-between mt-4">
          <label class="font-bold">Total Equipment Expense</label>
          <div class="w-48">
            <span class="block w-full px-2 text-right font-bold">{{ equipment.expenses.total | currency(1) }}</span>
          </div>
        </div>

      </div>

      <div class="flex items-center justify-between pt-4 text-sm field hidden">
        <label>Operator's Hourly Wage</label>
        <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="equipment.expenses.wage" format="$" precision="2" @change="onChange"></number-input>
          </span>
      </div>

      <div class="flex items-center justify-between pt-0 text-sm field">
        <label>Total # of Employees Operating Equipment</label>
        <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="equipment.expenses.operators" format="" precision="2" @change="onChange"></number-input>
          </span>
      </div>

    </div>
  </div>
</template>

<script>

import NumberInput from "@/components/NumberInput";
export default {
  name: "side-panel-rates-equipment",
  components: {NumberInput},
  props: ["rates"],
  mounted: function(){
    this.hydrate();
  },
  data: function () {
    return {
      equipment: {
        expenses: {
          lease: 0,
          repairs: 0,
          labor: 0,
          wage: 0,
          operators: 0,
          total: 0,
          summarized: false
        }
      },
    }
  },
  methods: {
    setSummarized: function(summarize){
      if(!summarize){

        let currentTotal = this.equipment.expenses.lease + this.equipment.expenses.repairs + this.equipment.expenses.labor;

        if(currentTotal != this.equipment.expenses.total){
          if(confirm("Switching to itemized will calculate your total based on the available itemized expense values. Do you want to switch to an itemized calculation?")){
            this.equipment.expenses.summarized = summarize;
            this.calculate();
          }
        }else{
          this.equipment.expenses.summarized = summarize;
          this.calculate();
        }

      }else{
        this.equipment.expenses.summarized = summarize;
        this.calculate();
      }

    },

    close: function(){
      this.$emit('close');
    },

    cancel: function(){
      this.$emit('close');
    },

    save: function(){

      this.rates.equipment.expenses.lease = this.equipment.expenses.lease;
      this.rates.equipment.expenses.repairs = this.equipment.expenses.repairs;
      this.rates.equipment.expenses.labor = this.equipment.expenses.labor;
      this.rates.equipment.expenses.wage = this.equipment.expenses.wage;
      this.rates.equipment.expenses.operators = this.equipment.expenses.operators;

      this.rates.equipment.expenses.total = this.equipment.expenses.total;
      this.rates.equipment.expenses.summarized = this.equipment.expenses.summarized;
      this.$emit('change');
      this.$emit('close');
    },

    hydrate: function(){

      Object.keys(this.equipment.expenses).forEach((key) => {
        this.equipment.expenses[key] = this.rates.equipment.expenses[key];
      })



    },

    onChange: function(){
      this.calculate();
    },

    calculate: function(){

      if(!this.equipment.expenses.summarized) {
        this.equipment.expenses.total = this.equipment.expenses.lease + this.equipment.expenses.repairs + this.equipment.expenses.labor;
      }
    }

  },
  computed: {
    
  }
}
</script>




<style scoped>


.side-panel-new-assessment {

}

.fields p:last-of-type {
  @apply border-black border-b pb-2;
}

.fields p:last-of-type .input {
  border-color: transparent;
}


</style>