import { evaluate } from "mathjs";

export default {
    install(Vue){

        let tableCache = {}; //used to store cell calculations strings fore evaluation later.

        let styles = {
            "@!": "text-xl font-bold", //tableHeader
            "@:": "w-64", //rowLabel
            "@|": "font-bold text-center", //colHeader,
            "@~": "", //rowStatement,
            "@[": "font-bold", //colHeader,
            "@+": "", //colUnderline
            "@_": "", //rowHeader

        }

        // let formats = {
        //     "$": "currency",
        //     "%": "percentage",
        //     "d": "decimal",
        // }

        function parseExpression(expr, refs, refSource, currentTable){
            let exprFormat = "";

            let frags = expr.split(" ").map((frag) => {



                if((frag == "$") || (frag == "%")) {

                    //exprFormat = formats['@' + frag];
                    exprFormat = frag;
                    return "";
                }else if(frag.startsWith("?")){
                    let keyParts = frag.slice(1).split(".");
                    let key, key2;
                    if(keyParts.length > 1){
                        key2 = keyParts[1];
                    }

                    key = keyParts[0];



                    if(refs[key] || refs[key] === 0 || refs[key] === false){

                        let colKey = "final";
                        let colNum = 0;

                        if(key2){
                            colNum = parseInt(key2);
                            if(refSource[key] && refSource[key].cols.length && refSource[key].cols[colNum]){
                                colKey = refSource[key].cols[colNum];
                            }else{
                                colKey = refSource[key].cols[key2]
                            }

                            if(!colKey){
                                colKey = "final";
                            }

                        }


                        return refs[key].answer[colKey];
                    }else{
                        return "";
                    }
                }else if(frag.startsWith("#")){

                    let cellIndex = frag.slice(1).split(":");
                    let refTableCache = currentTable;
                    let refRow, refCol;

                    if(cellIndex.length === 3){
                        if(tableCache[cellIndex[0]]){
                            refTableCache = tableCache[cellIndex[0]];
                            refCol = cellIndex[1];
                            refRow = cellIndex[2];
                        }
                    }else{
                        refCol = cellIndex[0];
                        refRow = cellIndex[1];
                    }

                    //console.log(refRow, refCol, refTableCache);

                    let refCell = refTableCache[refRow][refCol];
                    return refCell.value;

                }else{
                    return frag;
                }
            });

            return { format: exprFormat, value: frags.join(" "), expr: expr };
        }

        function parseCell(rawValue, refs, refSource, currentTable){
            let cell = { value: "", filter: "", style: "", expr: "", colspan: 1 }

            if(typeof rawValue == "string"){
                if(rawValue.startsWith("@")){
                    let styleCode = rawValue.slice(0,2);
                    let formatCode = ""
                    let _val = rawValue.slice(2);
                    if(_val.startsWith("@")){
                        formatCode = _val.slice(1,2);
                        _val = _val.slice(2);
                    }else if(_val.startsWith("=")){
                        let expr = parseExpression(_val.slice(1), refs, refSource, currentTable);
                        _val = expr.value;
                        formatCode = expr.format;
                        cell.expr = expr.expr;
                    }

                    cell.style = styles[styleCode];

                    if(formatCode){
                        cell.filter = formatCode;
                    }

                    cell.value = _val;

                }else if(rawValue.startsWith("=")){ //this is a number formula

                    let expr = parseExpression(rawValue.slice(1), refs, refSource, currentTable);


                    let evaluated = "";
                    try{
                        evaluated = evaluate(expr.value);
                    }catch{
                        evaluated = expr.value;
                    }

                    cell.value = evaluated;
                    cell.expr = expr.expr;
                    if(expr.format){
                        cell.filter = expr.format;
                    }

                }else{
                    cell.value = rawValue
                }
            }else{
                cell.value = rawValue;
            }

            return cell;
        }

        function parseTable(rawData, refs, refSource, tableName, overrides ){ //ref would be answers, refSource would be source question

            //console.log(overrides);
            if(!tableCache[tableName]){
                tableCache[tableName] = {};
            }

            let currentTable = tableCache[tableName];

            return rawData.map((row, rIndex) => {
                let rowId = rIndex + "";
                currentTable[rowId] = {};

                return row.map((col, cIndex) => {
                    let cLetter = String.fromCharCode(65 + cIndex);
                    let cell = parseCell(col, refs, refSource, currentTable );
                    let cellId = cLetter+":"+rowId;
                    if(overrides && overrides[cellId]){
                        Object.keys(overrides[cellId]).forEach((cellAttr) => {
                            cell[cellAttr] = overrides[cellId][cellAttr];
                        })
                    }

                    tableCache[tableName][rowId][cLetter] = cell;
                    return cell;
                })
            })
        }



        Vue.prototype.$tableParser = {
            parseTable: parseTable,
        };
    }
}