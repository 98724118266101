


<template>
<div class="question">
  <div class="w-full flex items-center">
    <div class="pr-8 flex-grow">
      <label class="pt-2 inline-block font-bold">{{ question.question }}</label>
    </div>
  </div>
</div>
</template>

<script>

export default {
    name: "assessment-interview-question-statement",
  components: {},
  props: {
      question: {
        required: true
      },
      answer: {
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {

    },
    computed: {

    }
}
</script>

<style scoped>


</style>