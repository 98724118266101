


<template>
<div class="select-input">
  <div class="relative">
    <button class="relative w-full bg-white border border-gray-300 rounded shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" @click="toggleOptions">
      <span>{{ displayValue }}</span>
      <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <!-- Heroicon name: selector -->
        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </span>
    </button>
    <div class="absolute z-40 mt-1 w-full rounded bg-white shadow-lg" v-if="showOptions">
      <ul class="max-h-64 rounded-md py-1 text-small ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
        <li v-for="option in options" :key="useId?option.id:option" @click="onSelect(option)" class="text-gray-900 cursor-default select-none relative py-1 pl-3 pr-9 hover:bg-gray-100 cursor-pointer">
          <div>{{ useId?option.label:option }}</div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-4">
            <!-- Heroicon name: check -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" v-if="(useId?option.id:option) == value">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
export default {
    name: "select-input",
    props:{
      value: {

      },

      useId: {
        //uses ID for value and Label for display
        type: Boolean,
        default: false
      },

      options: {
        type: Array,
        default: function(){
          return [];
        }
      },

      promptText: {
        type: String,
        default: "Select"
      }
    },
    data: function(){
        return {
          showOptions: false
        }
        },
    methods: {
      toggleOptions: function(e){
        this.showOptions = !this.showOptions;
        if(this.showOptions){
          e.stopPropagation();
          document.addEventListener('click', ()=>{
            this.showOptions = false;
          }, {once: true});
        }
      },

      onSelect: function(option){
        this.$emit("input", this.useId?option.id:option);
        this.$emit("change");
        this.showOptions = false;
      }
    },
    computed: {
      displayValue: function(){
        if(this.value){
          if(this.useId){
            let item = this.options.find((option) => {
              return (option.id === this.value);
            })
            if(item){
              return item.label;
            }
          }else{
            return this.value;
          }

        }

        return this.promptText;

      }

    }
}
</script>

<style scoped>


.select-input {

}

</style>
