

import workflow from "../schema/workflow"
import ccm from "../schema/ccm"
import mailing from "../schema/mailing"
import marketingMap from "../schema/marketingMap"
import marketingPmp from "../schema/marketingPmp"
import Finance from "financejs"
import faunadb from "faunadb";



// noinspection JSUnusedLocalSymbols
export default {
    install(Vue, options){

        var store = options.store;
        var finance = new Finance();
        var q = Vue.prototype.$db.query;
        var collection = "assessments";

        function getAssessmentSchema(assessmentType){
            if(assessmentType == "CCM"){
                return ccm
            }

            if(assessmentType == "Mail"){
                return mailing
            }

            if(assessmentType == "MAP"){
                return marketingMap
            }

            if(assessmentType == "PMP"){
                return marketingPmp
            }

            return workflow

        }

        function makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }



        function generateWorkflowQuestionnaire(assessment){

            let created = Date.now();
            let questionnaire = {
                created: created,

                submitted: 0,
                code: makeid(8),
                email: assessment.customerProfile.customerEmail,
                documentType: "Production Workflow Questionnaire",
                assessment: assessment.id,
                customerProfile: assessment.customerProfile

            }

            let interview = {
                I1001: { final: 0 },
                I1002: { final: 0 },
                I1003: { final: 0 },
                I1004: { final: 0 },
                I1005: { final: 0 },
                I1006: { final: 0 },
                I1007: { final: 0 },
                I1007A: { final: 0 },
                I1009: { final: 0 },

                I1010A: { final: 0 },
                I1011: { final: 0 },
                I1011A: { final: 0 },
                I1010C: { final: 0 },
                I1010D: { final: 0 },
                I1010E: { final: 0 },
                I1010F: { final: 0 },
                I1010G: { final: 0 },
                I1010H: { final: 0 },

                I1012: { final: 0 },
                I1014: { final: 0 },
                I1015: { final: 0 },
                I1016: { final: 0 },
                I1017: { final: 0 },
                I1018: { final: 0 },
                I1019: { final: 0 },
                I1021: { final: 0 },


                I1024: { notes: "" },
                I1025: { notes: "" },
                I1025A: { notes: "" },
                I1025B: { notes: "" },
                I1025C: { notes: "" },
                I1025E: { notes: "" },

                I1038: { Current: 0 },
                I1039: { Current: 0 },
                I1040: { Current: 0 },
                I1042: { Current: 0 },
                I1043: { Current: 0 },
                I1044: { Current: 0 },
                I1045: { final: 0 },
                I1046: { Current: 0 },
                I1047: { Current: 0 },

                I1050: { Current: 0 },
                I1051: { Current: 0 },
                I1052: { Current: 0 },
                I1053: { Current: 0 },
                I1054: { Current: 0 },
                I1055: { Current: 0 },
                I1056: { Current: 0 },
                I1057: { Current: 0 },


                I1061: { Current: 0 },
                I1062: { Current: 0 },
                I1063: { Current: 0 },
                I1064: { Current: 0 },
                I1065: { Current: 0 },
                I1066: { Current: 0 },
                I1067: { Current: 0 },

                I1068: { Current: 0 },
                I1069: { Current: 0 },
                I1070: { Current: 0 },

                I1084: { Current: 0 },
                I1085: { Current: 0 },
                I1086: { Current: 0 },
                I1087: { Current: 0 },
                I1089: { Current: 0 },

                I1095: { final: 0 },
                I1095A: { Current: 0 },
                I1096: { Current: 0 },
                I1097: { Current: 0 },
                I1098: { Current: 0 },
                I1099: { Current: 0 },

                I1100: { notes: 0 },
                I1102: { notes: 0 },

                I1113: { Current: 0 },
                I1114: { Current: 0 },
                I1115: { Current: 0 },
                I1116: { Current: 0 },
                I1117: { Current: 0 },

                I1123: { Current: 0 },
                I1124: { Current: 0 },
                I1126: { Current: 0 },

                I1127: { Current: 0 },

                V1010: { Current: 0 },
                V1011: { Current: 0 },

                V1000: { final: "" },
                V1001: { final: 0 },
                V1002: { Current: 0 },
                V1004: { Current: 0 },
                V1005: { Current: 0 },
                V1006: { Current: 0 },



            }

            for(const key in interview){
                let q = assessment.interview.questions[key];
                let i = interview[key]
                let prop = Object.keys(i)[0];
                if(prop === "notes"){
                    i.notes = q.notes;
                }else{
                    i[prop] = q.answer[prop];
                }
            }

            questionnaire.interview = interview;

            return questionnaire;


        }

        function generateCCMQuestionnaire(assessment){

            let created = Date.now();
            let questionnaire = {
                created: created,

                submitted: 0,
                code: makeid(8),
                email: assessment.customerProfile.customerEmail,
                documentType: "CCM Questionnaire",
                assessment: assessment.id,
                customerProfile: assessment.customerProfile

            }

            let interview = {
                X107: { final: 0 },
                X108: { final: 0 },
                X109: { final: 0 },
                X110: { final: 0 },
                X111: { final: 0 },
                X112: { final: 0 },
                X113: { final: 0 },
                X114: { final: 0 },
                X115: { final: 0 },
                X116: { final: 0 },
                X118: { final: 0 },
                X119: { final: 0 },

                X120: { final: "", notes:"" },
                X121: { final: "", notes:"" },
                X122: { final: "", notes:"" },
                X123: { final: "", notes:"" },
                X124: { notes:"" },
                X124A: { final:"", notes:"" },
                X125: { notes:"" },
                X126: { notes:"" },
                X127: { final:"", notes:"" },
                X128: { final:"", notes:"" },
                X129: { notes:"" },
                X130: { notes:"" },
                X131: { notes:"" },
                X132: { final:"", notes:"" },
                X133: { final:"", notes:"" },
                X134: { notes:"" },
                X135: { notes:"" },
                X136: { final:"", notes:"" },
                X137: { notes:"" },
                X138: { notes:"" },
                X139: { final:"", notes:"" },
                X140: { notes:"" },
                X141: { final:"", notes:"" },
                X142: { notes:"" },
                X143: { notes:"" },
                X144: { notes:"" },
                X145: { notes:"" },
                X146: { notes:"" },
                X147: { notes:"" },
                X148: { final:"", notes:"" },
                X149: { final:"", notes:"" },
                X149A: { notes:"" },
                X150: { notes:"" },
                X151: { final:"", notes:"" },
                X152: { notes:"" },
                X153: { notes:"" },
                X154: { notes:"" },
                X155: { final:"", notes:"" },
                X156: { notes:"" },
                X157: { notes:"" },
                X158: { final:"", notes:"" },
                X159: { notes:"" },
                X160: { notes:"" },
                X161: { notes:"" },
                X162: { notes:"" },
                X163: { notes:"" },
                X164: { notes:"" },
                X165: { notes:"" },
                X166: { final:"" },
                X167: { notes:"" },
                X168: { notes:"" },
                X169: { notes:"" },
                X170: { notes:"" },
                X171: { final:"" },


            }

            for(const key in interview){
                let q = assessment.interview.questions[key];
                let i = interview[key]
                for(let prop in i) {
                    if (prop === "notes") {
                        i.notes = q.notes;
                    } else {
                        i[prop] = q.answer[prop];
                    }
                }
            }

            questionnaire.interview = interview;

            return questionnaire;


        }

        function generateMailingQuestionnaire(assessment){

            let created = Date.now();
            let questionnaire = {
                created: created,

                submitted: 0,
                code: makeid(8),
                email: assessment.customerProfile.customerEmail,
                documentType: "Mail Workflow Questionnaire",
                assessment: assessment.id,
                customerProfile: assessment.customerProfile

            }

            let interview = {
                I1001: { final: 0 },
                I1003: { final: 0 },
                I1010: { final: 0 },
                X3002B: { final: 0 },
                X3002C: { final: 0 },
                X3010: { final: "", notes:"" },
                X3011: { final: "", notes:"" },
                X3012: { final: "", notes:"" },
                X3016: { final: "", notes:"" },
                X3017: { final: "", notes:"" },
                X3025: { final: "", notes:"" },
                X3026: { final: "", notes:"" },
                X3027: { final: "", notes:"" },
                X3028: { final: "", notes:"" },
                X3038: { final: "", notes:"" },
                X3039: { final: "", notes:"" },
                X3040: { final: "", notes:"" },
                X3050: { final: 0 },
                X3065A: { final: 0 },
                X3065C: { final: 0 },
                X3065E: { final: 0 },
                X3065G: { final: 0 },
                X3067: { final: "", notes:"" },
                X3087: { final: 0 },

            }

            for(const key in interview){
                let q = assessment.interview.questions[key];
                let i = interview[key]

                Object.keys(i).forEach((prop)=>{

                    if(prop === "notes"){
                        i.notes = q.notes;
                    }else{
                        i[prop] = q.answer[prop];
                    }
                })


            }

            questionnaire.interview = interview;

            return questionnaire;


        }

        /* eslint-disable no-unused-vars */
        function generateMapQuestionnaire(assessment){

            let created = Date.now();
            let questionnaire = {
                created: created,

                submitted: 0,
                code: makeid(8),
                email: assessment.customerProfile.customerEmail,
                documentType: "Marketing Automation Workflow Questionnaire",
                assessment: assessment.id,
                customerProfile: assessment.customerProfile

            }

            let interview = {
                X120: { final: "", notes:"" },
                X121: { final: "", notes:"" },
                X122: { final: "", notes:"" },
                X123: { final: "", notes:"" },
                X124: { final: 0 },
                X125: { final: 0 },
                X126: { final: 0 },
                X127: { final: 0 },

                X221: { final: "", notes: ""},
                X222: { final: "", notes: ""},
                X223: { final: "", notes: ""},
                X224: { final: "", notes: ""},
                X225: { final: "", notes: ""},
                X226: { final: "", notes: ""},
                X227: { final: "", notes: ""},
                X228: { final: "", notes: ""},
                X229: { final: "", notes: ""},

                X3101:{ final: "", notes: ""},
                X3102:{ final: "", notes: ""},
                X3103:{ final: "", notes: ""},
                X3104:{ final: "", notes: ""},
                X3106A:{ final: "", notes: ""},
                X3106:{ final: "", notes: ""},
                X3107:{ final: "", notes: ""},
                X3108:{ final: "", notes: ""},
                X3109:{ final: 0 },
                X3110:{ final: "", notes: ""},
                X3111:{ final: "", notes: ""},
                X3112:{ final: "", notes: ""},
                X3113:{ final: "", notes: ""},

                X3200: { final: "", notes: ""},

                X3202: { final: "", notes: ""},
                X3202A: { final: "", notes: ""},
                X3203: { final: 0 },
                X3204: { final: 0 },
                X3205: { final: 0 },
                X3215: { final: 0 },
                X3216: { final: 0 },
                X3206: { final: 0 },
                X3207: { final: 0 },
                X3208: { final: 0 },
                X3220: { final: 0 },
                X3211: { final: 0 },
                X3221: { final: 0 },
                X3225: { final: 0 },
                X3222: { final: "", notes: ""},
                X3223: { final: "", notes: ""},
                X3224: { final: "", notes: ""},

                X3302: { final: 0 },
                X3303: { final: "", notes: ""},
                X3310: { final: 0, notes: ""},
                X3311: { final: "", notes: ""},
                X3312: { final: "", notes: ""},
                X3313: { final: "", notes: ""},
                X3314: { final: "", notes: ""},
                X3315: { final: "", notes: ""},
                X3317: { final: "", notes: ""},
                X3318: { final: "", notes: ""},

                X3401: { final: "", notes: ""},
                X109: { final: "", notes: ""},
                X110: { final: "", notes: ""},
                X3402: { final: "", notes: ""},
                X3403: { final: "", notes: ""},
                X3404: { final: "", notes: ""},
                X3406: { final: "", notes: ""},
                X3409: { final: "", notes: ""},
                X3410: { final: "", notes: ""},
                X3411: { final: "", notes: ""},
                X3413: { final: "", notes: ""},
                X3414: { final: "", notes: ""},
                X3415: { final: "", notes: ""},
                X3416: { final: "", notes: ""},
                X3418: { final: "", notes: ""},
                X3419: { final: "", notes: ""},
                X3420: { final: "", notes: ""},

                X3501: { final: "", notes: ""},
                X3502: { final: "", notes: ""},
                X3503: { final: "", notes: ""},
                X3504: { final: "", notes: ""},
                X3505: { final: "", notes: ""},
                X3506: { final: "", notes: ""},
                X3507: { final: "", notes: ""},
                X3508: { final: "", notes: ""},
                X3509: { final: "", notes: ""},

                X3601: { final: "", notes: ""},
                X3602: { final: "", notes: ""},
                X3603: { final: "", notes: ""},
                X3604: { final: "", notes: ""},

                X3701: { final: "", notes: ""},
                X3702: { final: "", notes: ""},
                X3703: { final: "", notes: ""},
                X3704: { final: "", notes: ""},
                X3705: { final: "", notes: ""},
                X3706: { final: "", notes: ""},

                X3707: { final: "", notes: ""},
                X3708: { final: "", notes: ""},
                X3709: { final: "", notes: ""},
                X3710: { final: "", notes: ""},
                X3711: { final: "", notes: ""},

                X3712: { final: "", notes: ""},
                X3713: { final: "", notes: ""},
                X3714: { final: "", notes: ""},
                X3715: { final: "", notes: ""},
                X3716: { final: "", notes: ""},
                X3717: { final: "", notes: ""},
                X3718: { final: "", notes: ""},
                X3719: { final: "", notes: ""},
                X3720: { final: "", notes: ""},

                X3801: { final: "", notes: ""},
                X3802: { final: "", notes: ""},
                X3803: { final: "", notes: ""},
                X3804: { final: "", notes: ""},
                X3805: { final: "", notes: ""},
                X3806: { final: "", notes: ""},
                X3807: { final: "", notes: ""},
                X3808: { final: "", notes: ""},
                X3809: { final: "", notes: ""},

                X3901: { final: "", notes: ""},
                X3902: { final: "", notes: ""},
                X3903: { final: "", notes: ""}


            }

            for(const key in interview){
                let q = assessment.interview.questions[key];
                let i = interview[key]

                Object.keys(i).forEach((prop)=>{
                    console.log(key, q, i)
                    if(prop === "notes"){
                        i.notes = q.notes;
                    }else{
                        i[prop] = q.answer[prop];
                    }
                })


            }

            questionnaire.interview = interview;

            return questionnaire;


        }

        function generatePmpQuestionnaire(assessment){

            let created = Date.now();
            let questionnaire = {
                created: created,

                submitted: 0,
                code: makeid(8),
                email: assessment.customerProfile.customerEmail,
                documentType: "Project Management Workflow Questionnaire",
                assessment: assessment.id,
                customerProfile: assessment.customerProfile

            }

            let interview = {
                X107: { final: "", notes:"" },

                X108: { final: "", notes: ""},
                X109: { final: "", notes: ""},
                X110: { final: "", notes: ""},
                X111: { final: "", notes: ""},
                X120: { final: "", notes: ""},
                X121: { final: "", notes: ""},
                X122: { final: "", notes: ""},
                X123: { final: "", notes: ""},

                X221: { final: "", notes: ""},
                X222: { final: "", notes: ""},
                X223: { final: "", notes: ""},
                X224: { final: "", notes: ""},
                X225: { final: "", notes: ""},
                X226: { final: "", notes: ""},
                X227: { final: "", notes: ""},
                X228: { final: "", notes: ""},
                X229: { final: "", notes: ""},
                X3100: { final: "", notes: ""},
                X3101: { final: "", notes: ""},
                X3102: { final: "", notes: ""},
                X3106A: { final: "", notes: ""},
                X3106: { final: "", notes: ""},
                X3107: { final: "", notes: ""},
                X3108: { final: "", notes: ""},
                X3109: { final: "", notes: ""},
                X3110: { final: "", notes: ""},
                X3111: { final: "", notes: ""},
                X3112: { final: "", notes: ""},

                X3200: { final: "", notes: ""},
                X3201: { final: "", notes: ""},
                X3202: { final: "", notes: ""},
                X3202A: { final: "", notes: ""},
                X3204: { final: "", notes: ""},
                X3205: { final: "", notes: ""},
                X3206: { final: "", notes: ""},
                X3207: { final: "", notes: ""},
                X3216: { final: "", notes: ""},
                X3208: { final: "", notes: ""},
                X3209: { final: "", notes: ""},
                X3219: { final: "", notes: ""},
                X3220: { final: "", notes: ""},
                X3210: { final: "", notes: ""},
                X3211: { final: "", notes: ""},
                X3222: { final: "", notes: ""},
                X3218: { final: "", notes: ""},
                X3221: { final: "", notes: ""},
                X3225: { final: "", notes: ""},

                X3301: { final: "", notes: ""},
                X3302: { final: "", notes: ""},
                X3304: { final: "", notes: ""},
                X3305: { final: "", notes: ""},
                X3307: { final: "", notes: ""},
                X3308: { final: "", notes: ""},

                X3401: { final: "", notes: ""},
                X3402: { final: "", notes: ""},
                X3403: { final: "", notes: ""},
                X3405: { final: "", notes: ""},
                X3406: { final: "", notes: ""},
                X3407: { final: "", notes: ""},
                X3408: { final: "", notes: ""},
                X3409: { final: "", notes: ""},
                X3410: { final: "", notes: ""},

                X3501: { final: "", notes: ""},
                X3502: { final: "", notes: ""},
                X3503: { final: "", notes: ""},
                X3503A: { final: "", notes: ""},
                X3504: { final: "", notes: ""},
                X3505: { final: "", notes: ""},
                X3506: { final: "", notes: ""},
                X3507: { final: "", notes: ""},

                X3601: { final: "", notes: ""},
                X3602: { final: "", notes: ""},
                X3603: { final: "", notes: ""},
                X3604: { final: "", notes: ""},
                X3605: { final: "", notes: ""},
                X3606: { final: "", notes: ""},

                X3701: { final: "", notes: ""},

                X3801: { final: "", notes: ""},
                X3802: { final: "", notes: ""},
                X3803: { final: "", notes: ""},
                X3804: { final: "", notes: ""},

                X3901: { final: "", notes: ""},
                X3902: { final: "", notes: ""},

                X31001: { final: "", notes: ""},

                X31101: { final: "", notes: ""},
                X31103: { final: "", notes: ""}


            }

            for(const key in interview){
                let q = assessment.interview.questions[key];
                let i = interview[key]

                Object.keys(i).forEach((prop)=>{

                    if(prop === "notes"){
                        i.notes = q.notes;
                    }else{
                        i[prop] = q.answer[prop];
                    }
                })


            }

            questionnaire.interview = interview;

            return questionnaire;


        }

        function createWorkflowAssessment(customerProfile, profile){

            let interviewQuestions = workflow.questions;
            let surveyQuestions = workflow.survey;
            if(!profile){
                profile = store.getters.getUserProfile;
            }
            let created = Date.now();




            let assessment = {

                created: created,

                assessmentType: customerProfile.assessmentType,
                exportSettings: {
                    selfScorecard: false,
                    workflowScorecard: true,
                    savingsDetailSummary: true,
                    photoGallery: false,
                    option: 1,
                    solutions: {
                        MIS: "",
                        VDP: "",
                        FilePrep: [], //added to transition from single selection VDP to multiselection
                        Storefront: "",
                        Other: []
                    }
                },
                survey: {
                    questions: {},
                },
                interview: {
                    questions: {}
                },
                customerProfile: {
                    "customerContact" : "",
                    "customerEmail": "",
                    "assessmentDate" : "",
                    "assessmentType" : customerProfile.assessmentType,
                    "address" : "",
                    "organizationName" : customerProfile.organizationName,
                    "organizationCode": customerProfile.organizationCode,
                    "organizationCode2": customerProfile.organizationCode2,
                    "organizationId": customerProfile.organizationId,
                    "city" : "",
                    "state" : "",
                    "country" : "",
                    "postalcode" : "",
                    "email": "",
                    "phone": ""
                },
                user: profile.userid,
                userProfile: profile,
                costs : {
                    mis : {
                        "savings": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "cashFlow": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "salesGrowth": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "discountRate": 0,
                        "directNPV": 0,
                        "directIRR": 0,
                        "paybackPeriod": 0,

                        "training" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "installation" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "firstMonthToRealization" : 0,
                        "expenseTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "adoptionTime" : 0,
                        "software" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "lease" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "capitalTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "maintenance" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "hardware" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "implementationTime" : 0,
                        "totals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        }
                    },
                    storefront : {

                        "savings": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "cashFlow": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "salesGrowth": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "discountRate": 0,
                        "directNPV": 0,
                        "directIRR": 0,
                        "paybackPeriod": 0,

                        "training" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0

                        },
                        "installation" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "firstMonthToRealization" : 0,
                        "expenseTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "adoptionTime" : 0,
                        "software" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "lease" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "capitalTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "maintenance" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "hardware" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "implementationTime" : 0,
                        "totals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        }
                    },
                    vdp : {

                        "savings": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "cashFlow": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "salesGrowth": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "discountRate": 0,
                        "directNPV": 0,
                        "directIRR": 0,
                        "firstYearCapitalExpense": 0,
                        "firstYearOperatingExpense": 0,
                        "paybackPeriod": 0,

                        "orderDetails": {
                            "orderType": "",
                            "adminRateWBenefits": 0,
                            "designRate": 0,
                            "vdpOrdersMonthly": 0,
                            "baseTemplate": {
                                "minPerOrder": 0,
                                "vdpMinPerOrder": 0,
                                "costSavingsMonthly": 0,
                            },
                            "jobTicketVDP": {
                                "minPerOrder": 0,
                                "hoursMonthly": 0,
                                "costMonthly": 0,
                                "vdpMinPerOrder": 0,
                                "vdpHoursMonthly": 0,
                                "vdpCostMonthly": 0,
                                "costSavingsMonthly": 0,
                                "costSavingsAnnual": 0,
                                "hourSavingsAnnual": 0,
                            },
                            "customization": {
                                "minPerOrder": 0,
                                "vdpMinPerOrder": 0,
                            },
                            "proofing": {
                                "minPerOrder": 0,
                                "vdpMinPerOrder": 0,

                            },
                            "processing": {
                                "minPerOrder": 0,
                                "vdpMinPerOrder": 0,
                            },
                            "designOrder": {
                                "minPerOrder": 0,
                                "hoursMonthly": 0,
                                "costMonthly": 0,
                                "vdpMinPerOrder": 0,
                                "vdpHoursMonthly": 0,
                                "vdpCostMonthly": 0,
                                "costSavingsMonthly": 0,
                                "costSavingsAnnual": 0,
                                "hourSavingsAnnual": 0,
                            },
                            "jobTicketOrder": {
                                "minPerOrder": 0,
                                "hoursMonthly": 0,
                                "costMonthly": 0,
                                "vdpMinPerOrder": 0,
                                "vdpHoursMonthly": 0,
                                "vdpCostMonthly": 0,
                                "costSavingsMonthly": 0,
                                "costSavingsAnnual": 0,
                                "hourSavingsAnnual": 0,
                            },
                            "averageReworkMonthly": 0,
                            "averageCostPerProject": 0,
                            "rework": {
                                "averageMonthly": 0,
                                "averageProjectCost": 0,
                                "costMonthly": 0,
                                "costSavingsAnnual": 0,
                            },
                            "totalSavingsAnnual": 0

                        },

                        "training" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "installation" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "firstMonthToRealization" : 0,
                        "expenseTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "adoptionTime" : 0,
                        "software" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "lease" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "capitalTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "maintenance" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "hardware" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "implementationTime" : 0,
                        "totals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        }
                    }
                },
                savings: {
                    estimates: {
                        offset: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        digital: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        largeFormat: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        quoteLetters: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        subtotal: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        totals: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    },
                    jobTickets: {
                        production: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        fulfillmentOnly: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        converted: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        withSpecs: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        developed: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        reorder: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        fulfillmentManual: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        totals: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    },
                    // filePrep: {
                    //     baseTemplate: {
                    //         hours: 0,
                    //         cost: 0,
                    //         hoursMIS: 0,
                    //         costMIS: 0,
                    //         hoursStorefront: 0,
                    //         costStorefront: 0,
                    //     },
                    //     jobTicketVDP: {
                    //         hours: 0,
                    //         cost: 0,
                    //         hoursMIS: 0,
                    //         costMIS: 0,
                    //         hoursStorefront: 0,
                    //         costStorefront: 0,
                    //     },
                    //     customizeDocVDP: {
                    //         hours: 0,
                    //         cost: 0,
                    //         hoursMIS: 0,
                    //         costMIS: 0,
                    //         hoursStorefront: 0,
                    //         costStorefront: 0,
                    //     },
                    //     sendProof: {
                    //         hours: 0,
                    //         cost: 0,
                    //         hoursMIS: 0,
                    //         costMIS: 0,
                    //         hoursStorefront: 0,
                    //         costStorefront: 0,
                    //     },
                    //     processApproved: {
                    //         hours: 0,
                    //         cost: 0,
                    //         hoursMIS: 0,
                    //         costMIS: 0,
                    //         hoursStorefront: 0,
                    //         costStorefront: 0,
                    //     },
                    //     totalDesign: {
                    //         hours: 0,
                    //         cost: 0,
                    //         hoursMIS: 0,
                    //         costMIS: 0,
                    //         hoursStorefront: 0,
                    //         costStorefront: 0,
                    //     },
                    //     total: {
                    //         hours: 0,
                    //         cost: 0,
                    //         hoursMIS: 0,
                    //         costMIS: 0,
                    //         hoursStorefront: 0,
                    //         costStorefront: 0,
                    //     },
                    //     totals: {
                    //         hours: 0,
                    //         cost: 0,
                    //         hoursMIS: 0,
                    //         hoursMISSavings: 0,
                    //         costMIS: 0,
                    //         costMISSavings: 0,
                    //         hoursStorefront: 0,
                    //         hoursStorefrontSavings: 0,
                    //         costStorefront: 0,
                    //         costStorefrontSavings: 0,
                    //     }
                    // },
                    inventory: {
                        totals: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    },
                    jobTracking: {
                        admin: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        owner: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        meetings: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        totals: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    },
                    shipments: {
                        tracking: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        docs: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        totals: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    },
                    purchasing: {
                        create: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        reconcile: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        processing: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        totals: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    },
                    externalVsInternal: {
                        totals: {
                            cost: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    },
                    billing: {
                        create: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        accounting: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        plCost: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        totals: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    },
                    labor: {
                        totals: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    },
                    errors: {
                        reducedSoftware: {

                            cost: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        reducedContent: {

                            cost: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },

                        totals: {

                            cost: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },

                    },
                    changes: {
                        totals: {
                            cost: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    },
                    reports: {
                        sales: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        actualVsEstimate: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        customerProfit: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        productProfit: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        weeklySales: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        monthlySales: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        notInvoiced: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                       productivity: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        schedule: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        payroll: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            costMIS: 0,
                            hoursStorefront: 0,
                            costStorefront: 0,
                        },
                        totals: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    },
                    misc: {
                        totals: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    },

                    hoursMISSavingsAnnual: 0,
                    costMISSavingsAnnual: 0,
                    hoursStorefrontSavingsAnnual: 0,
                    costStorefrontSavingsAnnual: 0,

                    revenue: {
                        shipping: {
                            cost: 0,
                            costMIS: 0,
                            additional: 0
                        },
                        changes: {
                            cost: 0,
                            costMIS: 0,
                            additional: 0
                        },
                        rework: {
                            cost: 0,
                            costMIS: 0,
                            additional: 0
                        },
                        forecasting: {
                            additional: 0
                        },
                        total: 0
                    },
                    summary: {
                        estimates: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        jobTickets: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },

                        vdp: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                            hoursVDP: 0,
                            hoursVDPSavings: 0,
                            costVDP: 0,
                            costVDPSavings: 0,
                        },
                        inventory: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        jobTracking: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        shipments: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        purchasing: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        billing: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        labor: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        errors: {
                            hours: null,
                            cost: 0,
                            hoursMIS: null,
                            hoursMISSavings: null,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: null,
                            hoursStorefrontSavings: null,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        changes: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        reports: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        misc: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        internalSubtotal: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        externalVsInternal: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        },
                        totals: {
                            hours: 0,
                            cost: 0,
                            hoursMIS: 0,
                            hoursMISSavings: 0,
                            costMIS: 0,
                            costMISSavings: 0,
                            hoursStorefront: 0,
                            hoursStorefrontSavings: 0,
                            costStorefront: 0,
                            costStorefrontSavings: 0,
                        }
                    }


                },
                scorecard: {
                    pre: 0,
                    final: 0
                }
            };
            Object.keys(surveyQuestions).forEach((qId) => {
                assessment.survey.questions[qId] = {
                    answer: {
                        "pre": "",
                        "final": "",
                        "active": true
                    },
                    notes: "",
                    weight: surveyQuestions[qId].weight?surveyQuestions[qId].weight:0,
                    questionID: qId
                }
            })

            Object.keys(interviewQuestions).forEach((qId) => {

                let values = {
                    answer: {
                        "pre": 0,
                        "final": 0,
                        "MIS": 0,
                        "VDP": 0,
                        "Storefront": 0,
                        "Current": 0
                    },
                    hidden: interviewQuestions[qId].hidden?true:false,
                    active: true,
                    notes: "",
                    questionID: qId
                }

                if(interviewQuestions[qId].allowOther){
                    values.answer.final = ""
                }

                let q = interviewQuestions[qId];
                if(q.optional){
                    values.active = false;
                }
                if(q.type == 6){
                    values.answer.final = false;
                }


                if(q.defaults.length){
                    if(q.cols.length){
                        q.cols.forEach((col, index) => {
                            if(q.dataFormat){
                                values.answer[col] = parseFloat(q.defaults[index]);
                            }else{
                                values.answer[col] = q.defaults[index];
                            }

                        })
                    }else{
                        if(q.dataFormat) {
                            values.answer.final = parseFloat(q.defaults[0]);
                        }else{
                            values.answer.final = q.defaults[0];
                        }
                    }
                }

                assessment.interview.questions[qId] = values;
            })

            return assessment;
        }

        function generateInterviewAnswerValues(interviewQuestion){

            console.log(interviewQuestion)
            let qId = interviewQuestion.id;

                let values = {
                    answer: {
                        "pre": 0,
                        "final": 0,
                        "MIS": 0,
                        "VDP": 0,
                        "Storefront": 0,
                        "Current": 0
                    },
                    hidden: interviewQuestion.hidden?true:false,
                    active: true,
                    notes: "",
                    questionID: qId
                }

                if(interviewQuestion.allowOther){
                    values.answer.final = ""
                }

                let q = interviewQuestion;
                if(q.optional){
                    values.active = false;
                }
                if(q.type == 6){
                    values.answer.final = false;
                }


                if(q.defaults.length){
                    if(q.cols.length){
                        q.cols.forEach((col, index) => {
                            if(q.dataFormat){
                                values.answer[col] = parseFloat(q.defaults[index]);
                            }else{
                                values.answer[col] = q.defaults[index];
                            }

                        })
                    }else{
                        if(q.dataFormat) {
                            values.answer.final = parseFloat(q.defaults[0]);
                        }else{
                            values.answer.final = q.defaults[0];
                        }
                    }
                }

                return values

        }

        function createCCMAssessment(customerProfile, profile){

            let interviewQuestions = ccm.questions;
            let surveyQuestions = ccm.survey;

            if(!profile){
                profile = store.getters.getUserProfile;
            }
            let created = Date.now();

            let assessment = {
                assessmentType: customerProfile.assessmentType,
                exportSettings: {
                    selfScorecard: false,
                    workflowScorecard: true,
                    savingsDetailSummary: true,
                    photoGallery: false,
                    option: 1,
                    solutions: {
                        MIS: "",
                        VDP: "",
                        FilePrep: [], //added to transition from single selection VDP to multiselection
                        Storefront: "",
                        Other: []
                    }
                },
                survey: {
                    questions: {},
                },
                interview: {
                    questions: {}
                },
                customerProfile: {
                    "customerContact" : "",
                    "customerEmail": "",
                    "assessmentDate" : "",
                    "assessmentType" : customerProfile.assessmentType,
                    "address" : "",
                    "organizationName" : customerProfile.organizationName,
                    "organizationCode": customerProfile.organizationCode,
                    "organizationCode2": customerProfile.organizationCode2,
                    "organizationId": customerProfile.organizationId,
                    "city" : "",
                    "state" : "",
                    "country" : "",
                    "postalcode" : "",
                    "email": "",
                    "phone": ""
                },
                created: created,
                user: profile.userid,
                userProfile: profile,
                costs : {},
                scorecard: {
                    pre: 0,
                    final: 0
                }
            };
            Object.keys(surveyQuestions).forEach((qId) => {
                assessment.survey.questions[qId] = {
                    answer: {
                        "pre": "",
                        "final": "",
                        "active": true
                    },
                    notes: "",
                    weight: surveyQuestions[qId].weight?surveyQuestions[qId].weight:0,
                    questionID: qId
                }
            })

            Object.keys(interviewQuestions).forEach((qId) => {

                let values = {
                    answer: {
                        "pre": 0,
                        "final": 0,
                        "MIS": 0,
                        "VDP": 0,
                        "Storefront": 0,
                        "Current": 0
                    },
                    hidden: interviewQuestions[qId].hidden?true:false,
                    active: true,
                    notes: "",
                    questionID: qId
                }
                if(interviewQuestions[qId].allowOther){
                    values.answer.final = ""
                }

                let q = interviewQuestions[qId];
                if(q.optional){
                    values.active = false;
                }
                if(q.type == 6){
                    values.answer.final = false;
                }


                if(q.defaults.length){
                    if(q.cols.length){
                        q.cols.forEach((col, index) => {
                            if(q.dataFormat){
                                values.answer[col] = parseFloat(q.defaults[index]);
                            }else{
                                values.answer[col] = q.defaults[index];
                            }

                        })
                    }else{
                        if(q.dataFormat) {
                            values.answer.final = parseFloat(q.defaults[0]);
                        }else{
                            values.answer.final = q.defaults[0];
                        }
                    }
                }

                assessment.interview.questions[qId] = values;
            })

            return assessment;
        }

        function createMailAssessment(customerProfile, profile){

            let interviewQuestions = mailing.questions;
            let surveyQuestions = mailing.survey;

            if(!profile){
                profile = store.getters.getUserProfile;
            }
            let created = Date.now();

            let assessment = {
                assessmentType: customerProfile.assessmentType,
                exportSettings: {
                    selfScorecard: false,
                    workflowScorecard: true,
                    savingsDetailSummary: true,
                    photoGallery: false,
                    option: 1,
                    solutions: {
                        MailSoftware: [],
                        Other: []
                    }
                },
                survey: {
                    questions: {},
                },
                interview: {
                    questions: {}
                },
                customerProfile: {
                    "customerContact" : "",
                    "customerEmail": "",
                    "assessmentDate" : "",
                    "assessmentType" : customerProfile.assessmentType,
                    "address" : "",
                    "organizationName" : customerProfile.organizationName,
                    "organizationCode": customerProfile.organizationCode,
                    "organizationCode2": customerProfile.organizationCode2,
                    "organizationId": customerProfile.organizationId,
                    "city" : "",
                    "state" : "",
                    "country" : "",
                    "postalcode" : "",
                    "email": "",
                    "phone": ""
                },
                created: created,
                user: profile.userid,
                userProfile: profile,
                costs : {
                    solution : {
                        "savings": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "cashFlow": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "salesGrowth": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "discountRate": 0,
                        "directNPV": 0,
                        "directIRR": 0,
                        "paybackPeriod": 0,

                        "training" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "installation" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "firstMonthToRealization" : 0,
                        "expenseTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "adoptionTime" : 0,
                        "software" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "lease" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "capitalTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "maintenance" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "hardware" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "implementationTime" : 0,
                        "totals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        }
                    },
                    totalSavingsAnnual: 0,
                    returnMail: {
                        monthlyVolume: 0,
                        averagePostageCost: 0,
                        averagePrintCost: 0,
                        averageResentBack: 0,
                        monthlyProcessingCost: 0,
                        costToUpdateCRM: 0,
                        percentReprinted: 0,
                        percentResent: 0,
                        percentCleanseReduction: 0,
                        monthlyCostBase: 0,
                        monthlyCostResent: 0,
                        monthlyCostTotal: 0,
                        monthlyCostSavings: 0,
                        yearlyCostTotal: 0,
                        yearlyCostSavings: 0
                    },
                    postage: {
                        annualPromoVolume: 0,
                        rates: {
                            fiveDigit: 0,
                            AADC: 0,
                            MixedAADC: 0,
                            threeDigit: 0,
                            mixedADC: 0,
                            meter: 0,
                            stamp: 0,
                            average: 0
                        },
                        discountRate: 0,
                        yearlyCostSavings: 0
                    },
                    compliance: {
                        yearlyCostSavings: 0
                    },
                    workflow: {
                        hourlyRate: 0,
                        currentMonthlyHoursForTask: 0,
                        futureMonthlyHoursForTask: 0,
                        yearlyCostSavings: 0
                    },
                    buyVsMake: {
                        costPerRecord: 0,
                        yearlyRecordsProcessed: 0,
                        yearlyExternalCleanseCost: 0,
                        yearlyExternalCosts: 0,
                        yearlyHours: 0,
                        yearlyInternalCost: 0,
                        yearlyCostSavings: 0
                    },
                    preSort: {

                        yearlyCurrentVolume: 0,
                        yearlyCurrentCost: 0,
                        yearlyFutureVolume: 0,
                        yearlyFutureCost: 0,


                        fiveDigit: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        AADC: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        mixedAADC: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        AADCNonAuto: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        mixedAADCNonAuto: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        fiveDigitNonAuto: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        threeDigitNonAuto: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        mixedADC: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        meter: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        stamp: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                    }

                },
                scorecard: {
                    pre: 0,
                    final: 0
                }
            };
            Object.keys(surveyQuestions).forEach((qId) => {
                assessment.survey.questions[qId] = {
                    answer: {
                        "pre": "",
                        "final": "",
                        "active": true
                    },
                    notes: "",
                    weight: surveyQuestions[qId].weight?surveyQuestions[qId].weight:0,
                    questionID: qId
                }
            })

            Object.keys(interviewQuestions).forEach((qId) => {

                let values = {
                    answer: {
                        "pre": 0,
                        "final": 0,
                        "Solution": 0,
                        "Current": 0
                    },
                    hidden: interviewQuestions[qId].hidden?true:false,
                    active: true,
                    notes: "",
                    questionID: qId
                }
                if(interviewQuestions[qId].allowOther){
                    values.answer.final = ""
                }

                let q = interviewQuestions[qId];
                if(q.optional){
                    values.active = false;
                }
                if(q.type == 6){
                    values.answer.final = false;
                }


                if(q.defaults.length){
                    if(q.cols.length){
                        q.cols.forEach((col, index) => {
                            if(q.dataFormat){
                                values.answer[col] = parseFloat(q.defaults[index]);
                            }else{
                                values.answer[col] = q.defaults[index];
                            }

                        })
                    }else{
                        if(q.dataFormat) {
                            values.answer.final = parseFloat(q.defaults[0]);
                        }else{
                            values.answer.final = q.defaults[0];
                        }
                    }
                }

                assessment.interview.questions[qId] = values;
            })

            return assessment;
        }

        function createMAPAssessment(customerProfile, profile){

            let interviewQuestions = marketingMap.questions;
            let surveyQuestions = marketingMap.survey;

            if(!profile){
                profile = store.getters.getUserProfile;
            }
            let created = Date.now();

            let assessment = {
                assessmentType: customerProfile.assessmentType,
                exportSettings: {
                    selfScorecard: false,
                    workflowScorecard: true,
                    savingsDetailSummary: true,
                    photoGallery: false,
                    option: 1,
                    solutions: {
                        ProjectManagement: [],
                        Other: []
                    }
                },
                survey: {
                    questions: {},
                },
                interview: {
                    questions: {}
                },
                customerProfile: {
                    "customerContact" : "",
                    "customerEmail": "",
                    "assessmentDate" : "",
                    "assessmentType" : customerProfile.assessmentType,
                    "address" : "",
                    "organizationName" : customerProfile.organizationName,
                    "organizationCode": customerProfile.organizationCode,
                    "organizationCode2": customerProfile.organizationCode2,
                    "organizationId": customerProfile.organizationId,
                    "city" : "",
                    "state" : "",
                    "country" : "",
                    "postalcode" : "",
                    "email": "",
                    "phone": ""
                },
                created: created,
                user: profile.userid,
                userProfile: profile,
                costs : {
                    solution : {
                        "savings": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "cashFlow": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "salesGrowth": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "discountRate": 0,
                        "directNPV": 0,
                        "directIRR": 0,
                        "paybackPeriod": 0,

                        "training" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "installation" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "firstMonthToRealization" : 0,
                        "expenseTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "adoptionTime" : 0,
                        "software" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "lease" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "capitalTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "maintenance" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "hardware" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "implementationTime" : 0,
                        "totals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        }
                    },
                    totalSavingsAnnual: 0,
                    returnMail: {
                        monthlyVolume: 0,
                        averagePostageCost: 0,
                        averagePrintCost: 0,
                        averageResentBack: 0,
                        monthlyProcessingCost: 0,
                        costToUpdateCRM: 0,
                        percentReprinted: 0,
                        percentResent: 0,
                        percentCleanseReduction: 0,
                        monthlyCostBase: 0,
                        monthlyCostResent: 0,
                        monthlyCostTotal: 0,
                        monthlyCostSavings: 0,
                        yearlyCostTotal: 0,
                        yearlyCostSavings: 0
                    },
                    postage: {
                        annualPromoVolume: 0,
                        rates: {
                            fiveDigit: 0,
                            AADC: 0,
                            MixedAADC: 0,
                            threeDigit: 0,
                            mixedADC: 0,
                            meter: 0,
                            stamp: 0,
                            average: 0
                        },
                        discountRate: 0,
                        yearlyCostSavings: 0
                    },
                    compliance: {
                        yearlyCostSavings: 0
                    },
                    workflow: {
                        hourlyRate: 0,
                        currentMonthlyHoursForTask: 0,
                        futureMonthlyHoursForTask: 0,
                        yearlyCostSavings: 0
                    },
                    buyVsMake: {
                        costPerRecord: 0,
                        yearlyRecordsProcessed: 0,
                        yearlyExternalCleanseCost: 0,
                        yearlyExternalCosts: 0,
                        yearlyHours: 0,
                        yearlyInternalCost: 0,
                        yearlyCostSavings: 0
                    },
                    preSort: {

                        yearlyCurrentVolume: 0,
                        yearlyCurrentCost: 0,
                        yearlyFutureVolume: 0,
                        yearlyFutureCost: 0,


                        fiveDigit: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        AADC: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        mixedAADC: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        AADCNonAuto: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        mixedAADCNonAuto: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        fiveDigitNonAuto: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        threeDigitNonAuto: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        mixedADC: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        meter: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        stamp: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                    }

                },
                scorecard: {
                    pre: 0,
                    final: 0
                }
            };
            Object.keys(surveyQuestions).forEach((qId) => {
                assessment.survey.questions[qId] = {
                    answer: {
                        "pre": "",
                        "final": "",
                        "active": true
                    },
                    notes: "",
                    weight: surveyQuestions[qId].weight?surveyQuestions[qId].weight:0,
                    questionID: qId
                }
            })

            Object.keys(interviewQuestions).forEach((qId) => {

                let values = {
                    answer: {
                        "pre": 0,
                        "final": 0,
                        "Solution": 0,
                        "Current": 0
                    },
                    hidden: interviewQuestions[qId].hidden?true:false,
                    active: true,
                    notes: "",
                    questionID: qId
                }
                if(interviewQuestions[qId].allowOther){
                    values.answer.final = ""
                }

                let q = interviewQuestions[qId];
                if(q.optional){
                    values.active = false;
                }
                if(q.type == 6){
                    values.answer.final = false;
                }


                if(q.defaults.length){
                    if(q.cols.length){
                        q.cols.forEach((col, index) => {
                            if(q.dataFormat){
                                values.answer[col] = parseFloat(q.defaults[index]);
                            }else{
                                values.answer[col] = q.defaults[index];
                            }

                        })
                    }else{
                        if(q.dataFormat) {
                            values.answer.final = parseFloat(q.defaults[0]);
                        }else{
                            values.answer.final = q.defaults[0];
                        }
                    }
                }

                assessment.interview.questions[qId] = values;
            })

            return assessment;
        }

        function createPMPAssessment(customerProfile, profile){

            let interviewQuestions = marketingPmp.questions;
            let surveyQuestions = marketingPmp.survey;

            if(!profile){
                profile = store.getters.getUserProfile;
            }
            let created = Date.now();

            let assessment = {
                assessmentType: customerProfile.assessmentType,
                exportSettings: {
                    selfScorecard: false,
                    workflowScorecard: true,
                    savingsDetailSummary: true,
                    photoGallery: false,
                    option: 1,
                    solutions: {
                        ProjectManagement: [],
                        Other: []
                    }
                },
                survey: {
                    questions: {},
                },
                interview: {
                    questions: {}
                },
                customerProfile: {
                    "customerContact" : "",
                    "customerEmail": "",
                    "assessmentDate" : "",
                    "assessmentType" : customerProfile.assessmentType,
                    "address" : "",
                    "organizationName" : customerProfile.organizationName,
                    "organizationCode": customerProfile.organizationCode,
                    "organizationCode2": customerProfile.organizationCode2,
                    "organizationId": customerProfile.organizationId,
                    "city" : "",
                    "state" : "",
                    "country" : "",
                    "postalcode" : "",
                    "email": "",
                    "phone": ""
                },
                created: created,
                user: profile.userid,
                userProfile: profile,
                costs : {
                    solution : {
                        "savings": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "cashFlow": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "salesGrowth": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "discountRate": 0,
                        "directNPV": 0,
                        "directIRR": 0,
                        "paybackPeriod": 0,

                        "training" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "installation" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "firstMonthToRealization" : 0,
                        "expenseTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "adoptionTime" : 0,
                        "software" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "lease" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "capitalTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "maintenance" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "hardware" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "implementationTime" : 0,
                        "totals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        }
                    },
                    totalSavingsAnnual: 0,
                    returnMail: {
                        monthlyVolume: 0,
                        averagePostageCost: 0,
                        averagePrintCost: 0,
                        averageResentBack: 0,
                        monthlyProcessingCost: 0,
                        costToUpdateCRM: 0,
                        percentReprinted: 0,
                        percentResent: 0,
                        percentCleanseReduction: 0,
                        monthlyCostBase: 0,
                        monthlyCostResent: 0,
                        monthlyCostTotal: 0,
                        monthlyCostSavings: 0,
                        yearlyCostTotal: 0,
                        yearlyCostSavings: 0
                    },
                    postage: {
                        annualPromoVolume: 0,
                        rates: {
                            fiveDigit: 0,
                            AADC: 0,
                            MixedAADC: 0,
                            threeDigit: 0,
                            mixedADC: 0,
                            meter: 0,
                            stamp: 0,
                            average: 0
                        },
                        discountRate: 0,
                        yearlyCostSavings: 0
                    },
                    compliance: {
                        yearlyCostSavings: 0
                    },
                    workflow: {
                        hourlyRate: 0,
                        currentMonthlyHoursForTask: 0,
                        futureMonthlyHoursForTask: 0,
                        yearlyCostSavings: 0
                    },
                    buyVsMake: {
                        costPerRecord: 0,
                        yearlyRecordsProcessed: 0,
                        yearlyExternalCleanseCost: 0,
                        yearlyExternalCosts: 0,
                        yearlyHours: 0,
                        yearlyInternalCost: 0,
                        yearlyCostSavings: 0
                    },
                    preSort: {

                        yearlyCurrentVolume: 0,
                        yearlyCurrentCost: 0,
                        yearlyFutureVolume: 0,
                        yearlyFutureCost: 0,


                        fiveDigit: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        AADC: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        mixedAADC: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        AADCNonAuto: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        mixedAADCNonAuto: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        fiveDigitNonAuto: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        threeDigitNonAuto: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        mixedADC: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        meter: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                        stamp: {
                            currentPostage: 0,
                            currentVolume: 0,
                            currentCost: 0,
                            futureVolume: 0,
                            futureCost: 0
                        },
                    }

                },
                scorecard: {
                    pre: 0,
                    final: 0
                }
            };
            Object.keys(surveyQuestions).forEach((qId) => {
                assessment.survey.questions[qId] = {
                    answer: {
                        "pre": "",
                        "final": "",
                        "active": true
                    },
                    notes: "",
                    weight: surveyQuestions[qId].weight?surveyQuestions[qId].weight:0,
                    questionID: qId
                }
            })

            Object.keys(interviewQuestions).forEach((qId) => {

                let values = {
                    answer: {
                        "pre": 0,
                        "final": 0,
                        "Solution": 0,
                        "Current": 0
                    },
                    hidden: interviewQuestions[qId].hidden?true:false,
                    active: true,
                    notes: "",
                    questionID: qId
                }
                if(interviewQuestions[qId].allowOther){
                    values.answer.final = ""
                }

                let q = interviewQuestions[qId];
                if(q.optional){
                    values.active = false;
                }
                if(q.type == 6){
                    values.answer.final = false;
                }


                if(q.defaults.length){
                    if(q.cols.length){
                        q.cols.forEach((col, index) => {
                            if(q.dataFormat){
                                values.answer[col] = parseFloat(q.defaults[index]);
                            }else{
                                values.answer[col] = q.defaults[index];
                            }

                        })
                    }else{
                        if(q.dataFormat) {
                            values.answer.final = parseFloat(q.defaults[0]);
                        }else{
                            values.answer.final = q.defaults[0];
                        }
                    }
                }

                assessment.interview.questions[qId] = values;
            })

            return assessment;
        }

        function createAssessment(customerProfile, userProfile){

            if(customerProfile.assessmentType === "Production Workflow"){
                return createWorkflowAssessment(customerProfile, userProfile);
            }else if(customerProfile.assessmentType === "CCM"){
                return createCCMAssessment(customerProfile, userProfile);
            }else if(customerProfile.assessmentType === "Mail"){
                return createMailAssessment(customerProfile, userProfile);
            }else if(customerProfile.assessmentType === "MAP"){
                return createMAPAssessment(customerProfile, userProfile);
            }else if(customerProfile.assessmentType === "PMP"){
                return createPMPAssessment(customerProfile, userProfile);
            }

        }

        function saveNewAssessment(obj){
            let token = store.getters.getToken;

            var client = new Vue.prototype.$db.Client({ secret: token })

            console.log("creating assessment", obj, collection);


            return client.query(
                q.Create(
                    q.Collection(collection),
                    {
                        data: obj
                    }
                )
            ).then((response) =>{
                return response;
            })


        }

        function saveNewQuestionnaire(obj){
            let token = store.getters.getToken;

            var client = new Vue.prototype.$db.Client({ secret: token })

            console.log("creating assessment", obj, 'questionnaires');


            return client.query(
                q.Create(
                    q.Collection('questionnaires'),
                    {
                        data: obj
                    }
                )
            ).then((response) =>{
                return response;
            })


        }

        function update(recordId, record, user){
            console.log("updating...");

            if(recordId){

                let token = localStorage.getItem("token");
                var client = new faunadb.Client({ secret: token })

                record.lastEdit = {
                    ts: Date.now(),
                    user: user
                }

                return client.query(
                    q.Update(
                        q.Ref(q.Collection(collection), recordId),
                        {
                            data: record
                        }
                    )
                ).then((response) =>{
                    return response;
                })
            }
        }

        function updateAssessment(obj, refId){
            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })
            //console.log("update", refId);

            var updateQ = client.query(
                q.Update(
                    q.Ref(q.Collection(collection), refId),
                    {
                        data: obj
                    })
            )

            return updateQ.then((response) =>{
                return response;
            })
        }

        function updateQuestionnaire(obj, refId){
            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })


            var updateQ = client.query(
                q.Update(
                    q.Ref(q.Collection('questionnaires'), refId),
                    {
                        data: obj
                    })
            )

            return updateQ.then((response) =>{
                return response;
            })
        }

        function deleteAssessment(refId){
            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })


            var updateQ = client.query(
                q.Delete(
                    q.Ref(q.Collection(collection), refId))
            )

            return updateQ.then((response) =>{
                return response;
            })
        }

        function loadAssessment(refId){

            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })



            return client.query(
                q.Get(q.Ref(q.Collection(collection), refId))
            ).then((response) => {


                if(response.data){
                    let record = response.data;
                    record.id = response.ref.value.id;

                    let validatedRecord = conformDataToSchema(record);

                    return validatedRecord;
                }else{
                    throw new Error("Document error.");
                }
            })

        }

        function conformDataToSchema(assessment){
            let schema = getAssessmentSchema(assessment.assessmentType);
            Object.keys(schema.questions).forEach((qId)=>{
                if(!assessment.interview.questions[qId]){
                    console.log(qId, "is not set")
                    let values = generateInterviewAnswerValues(schema.questions[qId]);
                    assessment.interview.questions[qId] = values;
                }
            })

            if(assessment.exportSettings?.solutions){
                if(!assessment.exportSettings?.solutions?.MailSoftware){
                    assessment.exportSettings.solutions.MailSoftware = [];
                }

                if(!assessment.exportSettings?.solutions?.FilePrep){
                    assessment.exportSettings.solutions.FilePrep = [];
                    if(assessment.exportSettings.solutions.VDP){
                        assessment.exportSettings.solutions.FilePrep.push(assessment.exportSettings.solutions.VDP);
                        //if legacy VDP solution copy it to new FilePrep array
                    }
                }
            }

            return assessment;
        }

        function loadQuestionnaire(refId){

            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })



            return client.query(
                q.Get(q.Ref(q.Collection('questionnaires'), refId))
            ).then((response) => {


                if(response.data){
                    let record = response.data;
                    record.id = response.ref.value.id;
                    return record;
                }else{
                    throw new Error("Document error.");
                }
            })

        }

        function loadUserProfile(refId){
            let token = store.getters.getToken;
            console.log("loading profile", refId, token);
            var client = new Vue.prototype.$db.Client({ secret: token })



            return client.query(
                q.Get(q.Ref(q.Collection("users"), refId))
            ).then((response) => {


                if(response.data){
                    let record = response.data;
                    record.id = response.ref.value.id;
                    return record;
                }else{
                    throw new Error("Document error.");
                }
            })

        }

        function getAssessments(){


            let token = store.getters.getToken;
            let user = store.getters.getUserProfile;

            var client = new Vue.prototype.$db.Client({ secret: token })



            if(user.level === 'admin'){
                return client.query(
                    q.Paginate(
                        q.Match(
                            q.Index("assessments_by_ts")
                        ),
                        { size: 400 }
                    ),
                ).then((response) => {

                    if(response.data){
                        return response.data
                    }else{
                        throw new Error("Network error.");
                    }
                })
            }else{

                console.log("Loading for user", user.userid, user.email);
                return client.query(
                    q.Paginate(
                        q.Union(
                            q.Match(
                                q.Index("assessments_for_user_by_ts"),
                                user.userid
                            ),
                            q.Match(
                                q.Index("assessments_for_public_by_ts"),
                                true
                            ),
                            q.Match(q.Index("assessments_for_shared_user_by_ts"), user.email)
                        )
                    ),
                ).then((response) => {

                    if(response.data){
                        return response.data
                    }else{
                        throw new Error("Network error.");
                    }
                })
            }


        }

        async function getAssessmentReport(){


            let token = store.getters.getToken;
            const url = "/api/assessments_list"
            const options = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify(
                    {token}
                )
            }

            let convertTs = function(value, unit){
                if (value) {
                    let precision = (unit === 'ms')?1:1000;
                    let d = new Date(value/precision);
                    return d
                    // return `${d.toLocaleDateString()}${time?(" " + d.toLocaleTimeString()):""}`;
                }
            }

            let data = await fetch(url,options)
                .then(response => response.json())
                .then((report)=>{
                    console.log("report data", report)

                    let surveyQuestions = Object.keys(workflow.survey).map((q)=>{
                        return workflow.survey[q]
                    });
                    console.log("survey",surveyQuestions)

                    let reportData =  report.data.map((rec)=>{
                        let created = convertTs(rec.ts)
                        let values =  [
                            rec.assessmentType,
                            created.toISOString().substring(0,10),
                            created.toLocaleTimeString(),
                            rec.customerProfile.organizationName,
                            rec.customerProfile.organizationCode,
                            rec.customerProfile.organizationCode2,
                            rec.customerProfile.customerEmail,
                            rec.customerProfile.customerContact,
                            rec.customerProfile.assessmentDate,
                            rec.user.email,
                            rec.user.firstname,
                            rec.user.lastname,
                            rec.scorecard.pre?rec.scorecard.pre:"",
                            rec.scorecard.final?rec.scorecard.final:""
                        ]

                        surveyQuestions.forEach((q)=>{
                            values.push(rec.survey?.questions[q.id]?.answer.pre)
                        })
                        surveyQuestions.forEach((q)=>{
                            values.push(rec.survey?.questions[q.id]?.answer.final)
                        })

                        return values;
                    })

                    let colHeaders = [
                        "Assessment Type",
                        "Last Edit Date",
                        "Last Edit Time",
                        "Organization",
                        "Org Code 1",
                        "Org Code 2",
                        "Customer Email",
                        "Customer Contact",
                        "Assessment Date",
                        "User Email",
                        "User First Name",
                        "User Last Name",
                        "Self Assessment Score",
                        "Walkthrough Score"
                    ]

                    surveyQuestions.forEach((q)=>{
                        colHeaders.push(q.id + "-PRE")
                    })
                    surveyQuestions.forEach((q)=>{
                        colHeaders.push(q.id + "-WT")
                    })



                    reportData.unshift(colHeaders)

                    return reportData
                })
                .catch((e)=>{
                    return {
                        error: e
                    }
                })

            return data;


        }

        function getQuestionnairesForAssessment(assessment){

            let token = store.getters.getToken;
            var client = new Vue.prototype.$db.Client({ secret: token })



            return client.query(
                q.Paginate(
                    q.Match(
                        q.Index("questionnaires_for_assessment"),
                        assessment
                    )
                ),
            ).then((response) => {

                if(response.data){
                    return response.data
                }else{
                    throw new Error("Network error.");
                }
            })
        }

        function calculate(assessment){


            if(assessment.assessmentType == "MAP") {
                calculateMAP(assessment)
                return;
            }

            if(assessment.assessmentType == "PMP") {
                calculatePMP(assessment)
                return;
            }

            if(assessment.assessmentType == "Production Workflow") {


                let survey = assessment.survey.questions;
                let surveyScores = Object.keys(survey).map((qId) => {
                    return {
                        id: qId,
                        pre: survey[qId].answer.pre,
                        final: survey[qId].answer.final,
                    }
                })

                let preTotalPoints = 0, preTotalAnswered = 0;
                let finalTotalPoints = 0, finalTotalAnswered = 0;

                surveyScores.forEach((score) => {
                    if(score.pre && (score.pre !== "NA")){
                        preTotalPoints += parseInt(score.pre);
                        preTotalAnswered++;
                    }

                    if(score.final && (score.final !== "NA")){
                        finalTotalPoints += parseInt(score.final);
                        finalTotalAnswered++;
                    }
                })

                if(!assessment['scorecard']){
                    assessment.scorecard = { pre: 0, final: 0};
                }





                assessment.scorecard.pre = preTotalAnswered?(parseInt((preTotalPoints/preTotalAnswered * 1.0) * 10)/10):0;
                assessment.scorecard.final = finalTotalAnswered?(parseInt((finalTotalPoints/finalTotalAnswered * 1.0) * 10)/10):0;



                var iA = assessment.interview.questions;


                iA.I1010.answer.final = iA.I1010A.answer.final +
                    iA.I1011.answer.final +
                    iA.I1011A.answer.final +
                    iA.I1010C.answer.final +
                    iA.I1010D.answer.final +
                    iA.I1010E.answer.final +
                    iA.I1010F.answer.final +
                    iA.I1010G.answer.final +
                    iA.I1010H.answer.final +
                    iA.I1014.answer.final;


                iA.I1013.answer.final = iA.I1010D.answer.final + iA.I1010E.answer.final + iA.I1010F.answer.final + iA.I1010G.answer.final;
                iA.I1038.answer.MIS = iA.I1038.answer.Current;
                iA.I1039.answer.MIS = iA.I1039.answer.Current;
                iA.I1040.answer.MIS = iA.I1040.answer.Current;
                iA.I1041.answer.Current = iA.I1038.answer.Current + iA.I1039.answer.Current + iA.I1040.answer.Current;
                iA.I1041.answer.MIS = iA.I1038.answer.MIS + iA.I1039.answer.MIS + iA.I1040.answer.MIS;
                iA.I1041.answer.Storefront = iA.I1038.answer.Storefront + iA.I1039.answer.Storefront + iA.I1040.answer.Storefront;

                iA.I1042.answer.Storefront = iA.I1042.answer.MIS;
                iA.I1043.answer.Storefront = iA.I1043.answer.MIS;
                iA.I1044.answer.Storefront = iA.I1044.answer.MIS;

                // iA.I1044A.answer.final = (iA.I1042.answer.Current + iA.I1043.answer.Current + iA.I1044.answer.Current) / 3;
                // change to only use non-zero inputs items into divisor
                let estimateItems = [iA.I1042.answer.Current,iA.I1043.answer.Current,iA.I1044.answer.Current,].filter((val)=>{ return val > 0 }).length


                iA.I1044A.answer.final = estimateItems?((iA.I1042.answer.Current + iA.I1043.answer.Current + iA.I1044.answer.Current) / estimateItems):0;


                iA.I1046.answer.Current = iA.I1041.answer.Current;
                iA.I1046.answer.MIS = iA.I1041.answer.MIS;
                iA.I1046.answer.Storefront = iA.I1041.answer.Storefront;

                iA.I1047.answer.Storefront = iA.I1047.answer.MIS;

                iA.I1050.answer.MIS = iA.I1050.answer.Current;
                // iA.I1050.answer.Storefront =  iA.I1058.answer.Current - (iA.I1057.answer.Current + iA.I1053.answer.Storefront + iA.I1055.answer.Storefront);
                // have to move I1050.Storefront AFTER dependent I1058.Current Calculation
                iA.I1051.answer.MIS = iA.I1051.answer.Current;
                iA.I1051.answer.Storefront = iA.I1051.answer.MIS + iA.I1057.answer.MIS;

                iA.I1053.answer.Current = iA.I1041.answer.Current * iA.I1045.answer.final * 0.01;
                iA.I1053.answer.MIS = iA.I1041.answer.MIS * iA.I1045.answer.final * 0.01;
                iA.I1053.answer.Storefront = iA.I1041.answer.Storefront * iA.I1045.answer.final * 0.01;

                iA.I1054.answer.MIS = iA.I1054.answer.Current;
                iA.I1055.answer.MIS = iA.I1055.answer.Current;
                iA.I1056.answer.MIS = iA.I1056.answer.Current;
                iA.I1057.answer.MIS = iA.I1057.answer.Current;

                iA.I1057A.answer.final = iA.I1051.answer.Current + iA.I1057.answer.Current;

                iA.I1058.answer.Current = iA.I1050.answer.Current +
                    iA.I1051.answer.Current +
                    iA.I1052.answer.Current +
                    iA.I1053.answer.Current +
                    iA.I1054.answer.Current +
                    iA.I1055.answer.Current +
                    iA.I1056.answer.Current +
                    iA.I1057.answer.Current;

                //Moved out of sequence to make dependent calculation work from I1058.Current
                //iA.I1050.answer.Storefront = iA.I1058.answer.Current - (iA.I1051.answer.Storefront + iA.I1053.answer.Storefront + iA.I1055.answer.Storefront);

                //New calculation as of 1-2023
                iA.I1050.answer.Storefront = iA.I1050.answer.MIS + iA.I1053.answer.MIS + iA.I1054.answer.MIS + iA.I1055.answer.MIS + iA.I1056.answer.MIS;

                iA.I1058.answer.MIS = iA.I1058.answer.Current;

                console.log("calculating job ticket")

                iA.I1053.answer.Storefront = 0
                iA.I1054.answer.Storefront = 0
                iA.I1055.answer.Storefront = 0
                iA.I1056.answer.Storefront = 0
                iA.I1057.answer.Storefront = 0


                iA.I1058.answer.Storefront = iA.I1050.answer.Storefront +
                    iA.I1051.answer.Storefront +
                    iA.I1052.answer.Storefront +
                    iA.I1053.answer.Storefront +
                    iA.I1054.answer.Storefront +
                    iA.I1055.answer.Storefront +
                    iA.I1056.answer.Storefront +
                    iA.I1057.answer.Storefront;

                iA.I1059.answer.Current = iA.I1058.answer.Current * 12;
                iA.I1059.answer.MIS = iA.I1059.answer.Current;
                iA.I1059.answer.Storefront = iA.I1058.answer.Storefront * 12;

                iA.I1061.answer.MIS = iA.I1061.answer.Current;
                iA.I1061.answer.Storefront = iA.I1061.answer.Current;

                iA.I1062.answer.MIS = iA.I1062.answer.Current;
                iA.I1062.answer.Storefront = iA.I1062.answer.Current;

                //Allow user defined input now
                //iA.I1063.answer.MIS = iA.I1063.answer.Current;
                iA.I1063.answer.Storefront = iA.I1063.answer.Current;

                iA.I1064.answer.Storefront = iA.I1064.answer.Current;
                iA.I1065.answer.Storefront = iA.I1065.answer.Current;

                iA.I1066.answer.MIS = iA.I1066.answer.Current;
                iA.I1066.answer.Storefront = iA.I1066.answer.Current;

                iA.I1067.answer.MIS = iA.I1067.answer.Current;
                iA.I1067.answer.Storefront = iA.I1067.answer.Current;

                let jobTicketItems = [
                    iA.I1061.answer.Current,
                    iA.I1062.answer.Current,
                    iA.I1063.answer.Current,
                    iA.I1064.answer.Current,
                    iA.I1065.answer.Current,
                    iA.I1066.answer.Current,
                    iA.I1067.answer.Current,
                ].filter((val)=>{ return val > 0 }).length


                iA.I1067A.answer.final = jobTicketItems?((iA.I1061.answer.Current +
                    iA.I1062.answer.Current +
                    iA.I1063.answer.Current +
                    iA.I1064.answer.Current +
                    iA.I1065.answer.Current +
                    iA.I1066.answer.Current +
                    iA.I1067.answer.Current
                ) / jobTicketItems):0;

                iA.I1068.answer.MIS = iA.I1068.answer.Current;
                iA.I1068.answer.Storefront = iA.I1068.answer.Current;

                iA.I1069.answer.MIS = iA.I1069.answer.Current;
                iA.I1069.answer.Storefront = iA.I1069.answer.Current;

                iA.I1084.answer.Storefront = iA.I1084.answer.Current;
                iA.I1085.answer.Storefront = iA.I1085.answer.Current;

                iA.I1086.answer.MIS = iA.I1086.answer.Current;
                iA.I1086.answer.Storefront = iA.I1086.answer.Current;

                iA.I1087.answer.Storefront = iA.I1087.answer.Current;

                iA.I1089.answer.MIS = iA.I1089.answer.Current;
                iA.I1089.answer.Storefront = iA.I1089.answer.Current;

                iA.I1096.answer.Storefront = iA.I1096.answer.Current;
                iA.I1097.answer.Storefront = iA.I1097.answer.Current;
                iA.I1098.answer.Storefront = iA.I1098.answer.Current;
                iA.I1099.answer.Storefront = iA.I1099.answer.Current;

                iA.I1107.answer.MIS = iA.I1107.answer.Current;
                iA.I1107.answer.Storefront = iA.I1107.answer.Current;

                iA.I1108.answer.MIS = iA.I1108.answer.Current;
                iA.I1108.answer.Storefront = iA.I1108.answer.Current;

                iA.I1109.answer.Storefront = iA.I1109.answer.Current;

                iA.I1110.answer.MIS = iA.I1110.answer.Current - (iA.I1110.answer.Current * iA.I1112.answer.MIS * 0.01);
                iA.I1110.answer.Storefront = iA.I1110.answer.MIS;

                iA.I1111.answer.MIS = iA.I1111.answer.Current;

                iA.I1113.answer.Storefront = iA.I1113.answer.MIS;
                iA.I1114.answer.Storefront = iA.I1114.answer.MIS;
                iA.I1115.answer.Storefront = iA.I1115.answer.MIS;
                iA.I1116.answer.Storefront = iA.I1116.answer.MIS;
                iA.I1117.answer.Storefront = iA.I1117.answer.MIS;
                iA.I1118.answer.Storefront = iA.I1118.answer.MIS;

                iA.I1127A.answer.final = iA.I1127.answer.Current * iA.I1013.answer.final;

                iA.I1149.answer.MIS = iA.I1149.answer.final + (((iA.I1097.answer.Current - iA.I1097.answer.MIS) / 60) * iA.I1023.answer.final);
                iA.I1149.answer.Storefront = iA.I1149.answer.MIS;

                iA.I1150.answer.MIS = iA.I1150.answer.final;
                iA.I1150.answer.Storefront = iA.I1150.answer.final;

                iA.I1152.answer.final = iA.I1015.answer.final;
                iA.I1153.answer.final = iA.I1149.answer.final * 12;

                iA.I1154.answer.final = iA.I1152.answer.final / iA.I1153.answer.final;


                iA.I1155.answer.final = (iA.I1154.answer.final * (iA.I1149.answer.MIS - iA.I1149.answer.final) * 12) * (iA.I1150.answer.final * 0.01);

                iA.I1156.answer.final = iA.I1155.answer.final * iA.I1014.answer.final;

                iA.I1157.answer.final = iA.I1151.answer.final * 0.01 * iA.I1001.answer.final;

                iA.V1003.answer.Current = iA.I1065.answer.Current

                //COSTS
                var costs = assessment.costs;
                var adminRate = iA.I1017.answer.final * (1.0 + iA.I1019.answer.final * .01);

                //MIS
                costs.mis.capitalTotals.initial = costs.mis.software.initial + costs.mis.hardware.initial + costs.mis.installation.initial;
                costs.mis.capitalTotals.year1 = costs.mis.software.year1 + costs.mis.hardware.year1 + costs.mis.installation.year1;
                costs.mis.capitalTotals.year2 = costs.mis.software.year2 + costs.mis.hardware.year2 + costs.mis.installation.year2;
                costs.mis.capitalTotals.year3 = costs.mis.software.year3 + costs.mis.hardware.year3 + costs.mis.installation.year3;
                costs.mis.capitalTotals.year4 = costs.mis.software.year4 + costs.mis.hardware.year4 + costs.mis.installation.year4;
                costs.mis.capitalTotals.year5 = costs.mis.software.year5 + costs.mis.hardware.year5 + costs.mis.installation.year5;

                costs.mis.expenseTotals.initial = costs.mis.lease.initial + costs.mis.maintenance.initial + costs.mis.training.initial;
                costs.mis.expenseTotals.year1 = costs.mis.lease.year1 + costs.mis.maintenance.year1 + costs.mis.training.year1;
                costs.mis.expenseTotals.year2 = costs.mis.lease.year2 + costs.mis.maintenance.year2 + costs.mis.training.year2;
                costs.mis.expenseTotals.year3 = costs.mis.lease.year3 + costs.mis.maintenance.year3 + costs.mis.training.year3;
                costs.mis.expenseTotals.year4 = costs.mis.lease.year4 + costs.mis.maintenance.year4 + costs.mis.training.year4;
                costs.mis.expenseTotals.year5 = costs.mis.lease.year5 + costs.mis.maintenance.year5 + costs.mis.training.year5;

                costs.mis.totals.initial = costs.mis.expenseTotals.initial + costs.mis.capitalTotals.initial;
                costs.mis.totals.year1 = costs.mis.expenseTotals.year1 + costs.mis.capitalTotals.year1;
                costs.mis.totals.year2 = costs.mis.expenseTotals.year2 + costs.mis.capitalTotals.year2;
                costs.mis.totals.year3 = costs.mis.expenseTotals.year3 + costs.mis.capitalTotals.year3;
                costs.mis.totals.year4 = costs.mis.expenseTotals.year4 + costs.mis.capitalTotals.year4;
                costs.mis.totals.year5 = costs.mis.expenseTotals.year5 + costs.mis.capitalTotals.year5;

                costs.mis.firstMonthToRealization = costs.mis.implementationTime + costs.mis.adoptionTime + 1;

                //Storefront Costs
                costs.storefront.capitalTotals.initial = costs.storefront.software.initial + costs.storefront.hardware.initial + costs.storefront.installation.initial;
                costs.storefront.capitalTotals.year1 = costs.storefront.software.year1 + costs.storefront.hardware.year1 + costs.storefront.installation.year1;
                costs.storefront.capitalTotals.year2 = costs.storefront.software.year2 + costs.storefront.hardware.year2 + costs.storefront.installation.year2;
                costs.storefront.capitalTotals.year3 = costs.storefront.software.year3 + costs.storefront.hardware.year3 + costs.storefront.installation.year3;
                costs.storefront.capitalTotals.year4 = costs.storefront.software.year4 + costs.storefront.hardware.year4 + costs.storefront.installation.year4;
                costs.storefront.capitalTotals.year5 = costs.storefront.software.year5 + costs.storefront.hardware.year5 + costs.storefront.installation.year5;

                costs.storefront.expenseTotals.initial = costs.storefront.lease.initial + costs.storefront.maintenance.initial + costs.storefront.training.initial;
                costs.storefront.expenseTotals.year1 = costs.storefront.lease.year1 + costs.storefront.maintenance.year1 + costs.storefront.training.year1;
                costs.storefront.expenseTotals.year2 = costs.storefront.lease.year2 + costs.storefront.maintenance.year2 + costs.storefront.training.year2;
                costs.storefront.expenseTotals.year3 = costs.storefront.lease.year3 + costs.storefront.maintenance.year3 + costs.storefront.training.year3;
                costs.storefront.expenseTotals.year4 = costs.storefront.lease.year4 + costs.storefront.maintenance.year4 + costs.storefront.training.year4;
                costs.storefront.expenseTotals.year5 = costs.storefront.lease.year5 + costs.storefront.maintenance.year5 + costs.storefront.training.year5;

                costs.storefront.totals.initial = costs.storefront.expenseTotals.initial + costs.storefront.capitalTotals.initial;
                costs.storefront.totals.year1 = costs.storefront.expenseTotals.year1 + costs.storefront.capitalTotals.year1;
                costs.storefront.totals.year2 = costs.storefront.expenseTotals.year2 + costs.storefront.capitalTotals.year2;
                costs.storefront.totals.year3 = costs.storefront.expenseTotals.year3 + costs.storefront.capitalTotals.year3;
                costs.storefront.totals.year4 = costs.storefront.expenseTotals.year4 + costs.storefront.capitalTotals.year4;
                costs.storefront.totals.year5 = costs.storefront.expenseTotals.year5 + costs.storefront.capitalTotals.year5;

                costs.storefront.firstMonthToRealization = costs.storefront.implementationTime + costs.storefront.adoptionTime + 1;

                //VDP Costs


                costs.vdp.orderDetails.orderType = iA.V1000.answer.final;
                costs.vdp.orderDetails.vdpOrdersMonthly = iA.V1001.answer.final;
                costs.vdp.orderDetails.designRate = iA.I1016.answer.final;
                costs.vdp.orderDetails.adminRateWBenefits = adminRate;

                costs.vdp.orderDetails.baseTemplate.minPerOrder = iA.V1002.answer.Current;
                costs.vdp.orderDetails.baseTemplate.vdpMinPerOrder = iA.V1002.answer.VDP;

                costs.vdp.orderDetails.jobTicketVDP.minPerOrder = iA.I1065.answer.Current;
                costs.vdp.orderDetails.jobTicketVDP.hoursMonthly = iA.I1065.answer.Current * costs.vdp.orderDetails.vdpOrdersMonthly / 60.0;
                costs.vdp.orderDetails.jobTicketVDP.costMonthly = costs.vdp.orderDetails.jobTicketVDP.hoursMonthly * costs.vdp.orderDetails.adminRateWBenefits;
                costs.vdp.orderDetails.jobTicketVDP.vdpMinPerOrder = iA.V1003.answer.VDP;
                costs.vdp.orderDetails.jobTicketVDP.vdpHoursMonthly = iA.V1003.answer.VDP * costs.vdp.orderDetails.vdpOrdersMonthly / 60.0;
                costs.vdp.orderDetails.jobTicketVDP.vdpCostMonthly = costs.vdp.orderDetails.jobTicketVDP.vdpHoursMonthly * costs.vdp.orderDetails.adminRateWBenefits;
                costs.vdp.orderDetails.jobTicketVDP.costSavingsMonthly = costs.vdp.orderDetails.jobTicketVDP.costMonthly - costs.vdp.orderDetails.jobTicketVDP.vdpCostMonthly;
                costs.vdp.orderDetails.jobTicketVDP.costSavingsAnnual = costs.vdp.orderDetails.jobTicketVDP.costSavingsMonthly * 12.0;
                costs.vdp.orderDetails.jobTicketVDP.hourSavingsAnnual = (costs.vdp.orderDetails.jobTicketVDP.hoursMonthly - costs.vdp.orderDetails.jobTicketVDP.vdpHoursMonthly) * 12.0;

                costs.vdp.orderDetails.customization.minPerOrder = iA.V1004.answer.Current;
                costs.vdp.orderDetails.customization.vdpMinPerOrder = iA.V1004.answer.VDP;

                costs.vdp.orderDetails.proofing.minPerOrder = iA.V1005.answer.Current;
                costs.vdp.orderDetails.proofing.vdpMinPerOrder = iA.V1005.answer.VDP;

                costs.vdp.orderDetails.processing.minPerOrder = iA.V1006.answer.Current;
                costs.vdp.orderDetails.processing.vdpMinPerOrder = iA.V1006.answer.VDP;

                costs.vdp.orderDetails.designOrder.minPerOrder = costs.vdp.orderDetails.customization.minPerOrder + costs.vdp.orderDetails.proofing.minPerOrder + costs.vdp.orderDetails.processing.minPerOrder;
                costs.vdp.orderDetails.designOrder.hoursMonthly = costs.vdp.orderDetails.designOrder.minPerOrder * costs.vdp.orderDetails.vdpOrdersMonthly / 60.0;
                costs.vdp.orderDetails.designOrder.costMonthly = costs.vdp.orderDetails.designOrder.hoursMonthly * costs.vdp.orderDetails.designRate;
                costs.vdp.orderDetails.designOrder.vdpMinPerOrder = costs.vdp.orderDetails.customization.vdpMinPerOrder + costs.vdp.orderDetails.proofing.vdpMinPerOrder + costs.vdp.orderDetails.processing.vdpMinPerOrder;
                costs.vdp.orderDetails.designOrder.vdpHoursMonthly = costs.vdp.orderDetails.designOrder.vdpMinPerOrder * costs.vdp.orderDetails.vdpOrdersMonthly / 60.0;
                costs.vdp.orderDetails.designOrder.vdpCostMonthly = costs.vdp.orderDetails.designOrder.vdpHoursMonthly * costs.vdp.orderDetails.designRate;
                costs.vdp.orderDetails.designOrder.costSavingsMonthly = costs.vdp.orderDetails.designOrder.costMonthly - costs.vdp.orderDetails.designOrder.vdpCostMonthly;
                costs.vdp.orderDetails.designOrder.costSavingsAnnual = costs.vdp.orderDetails.designOrder.costSavingsMonthly * 12.0;
                costs.vdp.orderDetails.designOrder.hourSavingsAnnual = (costs.vdp.orderDetails.designOrder.hoursMonthly - costs.vdp.orderDetails.designOrder.vdpHoursMonthly) * 12.0;

                costs.vdp.orderDetails.jobTicketOrder.minPerOrder = costs.vdp.orderDetails.jobTicketVDP.minPerOrder + costs.vdp.orderDetails.designOrder.minPerOrder;
                costs.vdp.orderDetails.jobTicketOrder.hoursMonthly = costs.vdp.orderDetails.jobTicketVDP.hoursMonthly + costs.vdp.orderDetails.designOrder.hoursMonthly;
                costs.vdp.orderDetails.jobTicketOrder.costMonthly = costs.vdp.orderDetails.jobTicketVDP.costMonthly + costs.vdp.orderDetails.designOrder.costMonthly;
                costs.vdp.orderDetails.jobTicketOrder.vdpMinPerOrder = costs.vdp.orderDetails.jobTicketVDP.vdpMinPerOrder + costs.vdp.orderDetails.designOrder.vdpMinPerOrder;
                costs.vdp.orderDetails.jobTicketOrder.vdpHoursMonthly = costs.vdp.orderDetails.jobTicketVDP.vdpHoursMonthly + costs.vdp.orderDetails.designOrder.vdpHoursMonthly;
                costs.vdp.orderDetails.jobTicketOrder.vdpCostMonthly = costs.vdp.orderDetails.jobTicketVDP.vdpCostMonthly + costs.vdp.orderDetails.designOrder.vdpCostMonthly;
                costs.vdp.orderDetails.jobTicketOrder.costSavingsMonthly = costs.vdp.orderDetails.jobTicketVDP.costSavingsMonthly + costs.vdp.orderDetails.designOrder.costSavingsMonthly;
                costs.vdp.orderDetails.jobTicketOrder.costSavingsAnnual = costs.vdp.orderDetails.jobTicketVDP.costSavingsAnnual + costs.vdp.orderDetails.designOrder.costSavingsAnnual;
                costs.vdp.orderDetails.jobTicketOrder.hourSavingsAnnual = costs.vdp.orderDetails.jobTicketVDP.hourSavingsAnnual + costs.vdp.orderDetails.designOrder.hourSavingsAnnual;

                costs.vdp.orderDetails.averageReworkMonthly = iA.V1007.answer.final;
                costs.vdp.orderDetails.rework.averageProjectCost = iA.V1008.answer.final;
                costs.vdp.orderDetails.rework.costMonthly = costs.vdp.orderDetails.averageReworkMonthly * costs.vdp.orderDetails.rework.averageProjectCost;
                costs.vdp.orderDetails.rework.costSavingsAnnual = costs.vdp.orderDetails.rework.costMonthly * 12.0;


                //costs.vdp.orderDetails.totalSavingsAnnual = costs.vdp.orderDetails.rework.costSavingsAnnual + costs.vdp.orderDetails.jobTicketOrder.costSavingsAnnual;

                costs.vdp.orderDetails.totalSavingsAnnual = costs.vdp.orderDetails.jobTicketOrder.costSavingsAnnual;



                //Savings Summary

                var savings = assessment.savings;


                savings.estimates.offset.hours = ((iA.I1038.answer.Current * iA.I1042.answer.Current) / 60.0) * 12;
                savings.estimates.offset.cost = savings.estimates.offset.hours * adminRate;
                savings.estimates.offset.hoursMIS = ((iA.I1038.answer.MIS * iA.I1042.answer.MIS) / 60.0) * 12;
                savings.estimates.offset.costMIS = savings.estimates.offset.hoursMIS * adminRate;
                savings.estimates.offset.hoursMISSavings = savings.estimates.offset.hours - savings.estimates.offset.hoursMIS;
                savings.estimates.offset.costMISSavings = savings.estimates.offset.cost - savings.estimates.offset.costMIS;
                savings.estimates.offset.hoursStorefront = ((iA.I1038.answer.Storefront * iA.I1042.answer.Storefront) / 60.0) * 12;
                savings.estimates.offset.costStorefront = savings.estimates.offset.hoursStorefront * adminRate;
                savings.estimates.offset.hoursStorefrontSavings = savings.estimates.offset.hours - savings.estimates.offset.hoursStorefront;
                savings.estimates.offset.costStorefrontSavings = savings.estimates.offset.cost - savings.estimates.offset.costStorefront;

                savings.estimates.digital.hours = ((iA.I1039.answer.Current * iA.I1043.answer.Current) / 60.0) * 12;
                savings.estimates.digital.cost = savings.estimates.digital.hours * adminRate;
                savings.estimates.digital.hoursMIS = ((iA.I1039.answer.MIS * iA.I1043.answer.MIS) / 60.0) * 12;
                savings.estimates.digital.costMIS = savings.estimates.digital.hoursMIS * adminRate;
                savings.estimates.digital.hoursMISSavings = savings.estimates.digital.hours - savings.estimates.digital.hoursMIS;
                savings.estimates.digital.costMISSavings = savings.estimates.digital.cost - savings.estimates.digital.costMIS;
                savings.estimates.digital.hoursStorefront = ((iA.I1039.answer.Storefront * iA.I1043.answer.Storefront) / 60.0) * 12;
                savings.estimates.digital.costStorefront = savings.estimates.digital.hoursStorefront * adminRate;
                savings.estimates.digital.hoursStorefrontSavings = savings.estimates.digital.hours - savings.estimates.digital.hoursStorefront;
                savings.estimates.digital.costStorefrontSavings = savings.estimates.digital.cost - savings.estimates.digital.costStorefront;

                savings.estimates.largeFormat.hours = ((iA.I1040.answer.Current * iA.I1044.answer.Current) / 60.0) * 12;
                savings.estimates.largeFormat.cost = savings.estimates.largeFormat.hours * adminRate;
                savings.estimates.largeFormat.hoursMIS = ((iA.I1040.answer.MIS * iA.I1044.answer.MIS) / 60.0) * 12;
                savings.estimates.largeFormat.costMIS = savings.estimates.largeFormat.hoursMIS * adminRate;
                savings.estimates.largeFormat.hoursMISSavings = savings.estimates.largeFormat.hours - savings.estimates.largeFormat.hoursMIS;
                savings.estimates.largeFormat.costMISSavings = savings.estimates.largeFormat.cost - savings.estimates.largeFormat.costMIS;
                savings.estimates.largeFormat.hoursStorefront = ((iA.I1040.answer.Storefront * iA.I1044.answer.Storefront) / 60.0) * 12;
                savings.estimates.largeFormat.costStorefront = savings.estimates.largeFormat.hoursStorefront * adminRate;
                savings.estimates.largeFormat.hoursStorefrontSavings = savings.estimates.largeFormat.hours - savings.estimates.largeFormat.hoursStorefront;
                savings.estimates.largeFormat.costStorefrontSavings = savings.estimates.largeFormat.cost - savings.estimates.largeFormat.costStorefront;

                savings.estimates.quoteLetters.hours = ((iA.I1046.answer.Current * iA.I1047.answer.Current) / 60.0) * 12;
                savings.estimates.quoteLetters.cost = savings.estimates.quoteLetters.hours * adminRate;
                savings.estimates.quoteLetters.hoursMIS = ((iA.I1046.answer.MIS * iA.I1047.answer.MIS) / 60.0) * 12;
                savings.estimates.quoteLetters.costMIS = savings.estimates.quoteLetters.hoursMIS * adminRate;
                savings.estimates.quoteLetters.hoursMISSavings = savings.estimates.quoteLetters.hours - savings.estimates.quoteLetters.hoursMIS;
                savings.estimates.quoteLetters.costMISSavings = savings.estimates.quoteLetters.cost - savings.estimates.quoteLetters.costMIS;
                savings.estimates.quoteLetters.hoursStorefront = ((iA.I1046.answer.Storefront * iA.I1047.answer.Storefront) / 60.0) * 12;
                savings.estimates.quoteLetters.costStorefront = savings.estimates.quoteLetters.hoursStorefront * adminRate;
                savings.estimates.quoteLetters.hoursStorefrontSavings = savings.estimates.quoteLetters.hours - savings.estimates.quoteLetters.hoursStorefront;
                savings.estimates.quoteLetters.costStorefrontSavings = savings.estimates.quoteLetters.cost - savings.estimates.quoteLetters.costStorefront;


                savings.estimates.subtotal.hours = savings.estimates.offset.hours + savings.estimates.digital.hours + savings.estimates.largeFormat.hours;
                savings.estimates.subtotal.cost = savings.estimates.offset.cost + savings.estimates.digital.cost + savings.estimates.largeFormat.cost;
                savings.estimates.subtotal.hoursMIS = savings.estimates.offset.hoursMIS + savings.estimates.digital.hoursMIS + savings.estimates.largeFormat.hoursMIS;
                savings.estimates.subtotal.costMIS = savings.estimates.offset.costMIS + savings.estimates.digital.costMIS + savings.estimates.largeFormat.costMIS;
                savings.estimates.subtotal.hoursMISSavings = savings.estimates.subtotal.hours - savings.estimates.subtotal.hoursMIS;
                savings.estimates.subtotal.costMISSavings = savings.estimates.subtotal.cost - savings.estimates.subtotal.costMIS;
                savings.estimates.subtotal.hoursStorefront = savings.estimates.offset.hoursStorefront + savings.estimates.digital.hoursStorefront + savings.estimates.largeFormat.hoursStorefront;
                savings.estimates.subtotal.costStorefront = savings.estimates.offset.costStorefront + savings.estimates.digital.costStorefront + savings.estimates.largeFormat.costStorefront;
                savings.estimates.subtotal.hoursStorefrontSavings = savings.estimates.subtotal.hours - savings.estimates.subtotal.hoursStorefront;
                savings.estimates.subtotal.costStorefrontSavings = savings.estimates.subtotal.cost - savings.estimates.subtotal.costStorefront;

                savings.estimates.totals.hours = savings.estimates.subtotal.hours + savings.estimates.quoteLetters.hours;
                savings.estimates.totals.cost = savings.estimates.subtotal.cost + savings.estimates.quoteLetters.cost;
                savings.estimates.totals.hoursMIS = savings.estimates.subtotal.hoursMIS + savings.estimates.quoteLetters.hoursMIS;
                savings.estimates.totals.costMIS = savings.estimates.subtotal.costMIS + savings.estimates.quoteLetters.costMIS;
                savings.estimates.totals.hoursMISSavings = savings.estimates.subtotal.hoursMISSavings + savings.estimates.quoteLetters.hoursMISSavings;
                savings.estimates.totals.costMISSavings = savings.estimates.subtotal.costMISSavings + savings.estimates.quoteLetters.costMISSavings;
                savings.estimates.totals.hoursStorefront = savings.estimates.subtotal.hoursStorefront + savings.estimates.quoteLetters.hoursStorefront;
                savings.estimates.totals.costStorefront = savings.estimates.subtotal.costStorefront + savings.estimates.quoteLetters.costStorefront;
                savings.estimates.totals.hoursStorefrontSavings = savings.estimates.subtotal.hoursStorefrontSavings + savings.estimates.quoteLetters.hoursStorefrontSavings;
                savings.estimates.totals.costStorefrontSavings = savings.estimates.subtotal.costStorefrontSavings + savings.estimates.quoteLetters.costStorefrontSavings;


                savings.jobTickets.production.hours = ((iA.I1050.answer.Current * iA.I1061.answer.Current) / 60.0) * 12;
                savings.jobTickets.production.cost = savings.jobTickets.production.hours * adminRate;
                savings.jobTickets.production.hoursMIS = ((iA.I1050.answer.MIS * iA.I1061.answer.MIS) / 60.0) * 12;
                savings.jobTickets.production.costMIS = savings.jobTickets.production.hoursMIS * adminRate;
                savings.jobTickets.production.hoursStorefront = ((iA.I1050.answer.Storefront * iA.I1061.answer.Storefront) / 60.0) * 12;
                savings.jobTickets.production.costStorefront = savings.jobTickets.production.hoursStorefront * adminRate;
                savings.jobTickets.production.hoursMISSavings = savings.jobTickets.production.hours - savings.jobTickets.production.hoursMIS;
                savings.jobTickets.production.costMISSavings = savings.jobTickets.production.cost - savings.jobTickets.production.costMIS;
                savings.jobTickets.production.hoursStorefrontSavings = savings.jobTickets.production.hours - savings.jobTickets.production.hoursStorefront;
                savings.jobTickets.production.costStorefrontSavings = savings.jobTickets.production.cost - savings.jobTickets.production.costStorefront;


                savings.jobTickets.fulfillmentOnly.hours = ((iA.I1051.answer.Current * iA.I1062.answer.Current) / 60.0) * 12;
                savings.jobTickets.fulfillmentOnly.cost = savings.jobTickets.fulfillmentOnly.hours * adminRate;
                savings.jobTickets.fulfillmentOnly.hoursMIS = ((iA.I1051.answer.MIS * iA.I1062.answer.MIS) / 60.0) * 12;
                savings.jobTickets.fulfillmentOnly.costMIS = savings.jobTickets.fulfillmentOnly.hoursMIS * adminRate;
                savings.jobTickets.fulfillmentOnly.hoursStorefront = ((iA.I1051.answer.Storefront * iA.I1062.answer.Storefront) / 60.0) * 12;
                savings.jobTickets.fulfillmentOnly.costStorefront = savings.jobTickets.fulfillmentOnly.hoursStorefront * adminRate;
                savings.jobTickets.fulfillmentOnly.hoursMISSavings = savings.jobTickets.fulfillmentOnly.hours - savings.jobTickets.fulfillmentOnly.hoursMIS;
                savings.jobTickets.fulfillmentOnly.costMISSavings = savings.jobTickets.fulfillmentOnly.cost - savings.jobTickets.fulfillmentOnly.costMIS;
                savings.jobTickets.fulfillmentOnly.hoursStorefrontSavings = savings.jobTickets.fulfillmentOnly.hours - savings.jobTickets.fulfillmentOnly.hoursStorefront;
                savings.jobTickets.fulfillmentOnly.costStorefrontSavings = savings.jobTickets.fulfillmentOnly.cost - savings.jobTickets.fulfillmentOnly.costStorefront;

                savings.jobTickets.converted.hours = ((iA.I1053.answer.Current * iA.I1063.answer.Current) / 60.0) * 12;
                savings.jobTickets.converted.cost = savings.jobTickets.converted.hours * adminRate;
                savings.jobTickets.converted.hoursMIS = ((iA.I1053.answer.MIS * iA.I1063.answer.MIS) / 60.0) * 12;
                savings.jobTickets.converted.costMIS = savings.jobTickets.converted.hoursMIS * adminRate;
                savings.jobTickets.converted.hoursStorefront = ((iA.I1053.answer.Storefront * iA.I1063.answer.Storefront) / 60.0) * 12;
                savings.jobTickets.converted.costStorefront = savings.jobTickets.converted.hoursStorefront * adminRate;
                savings.jobTickets.converted.hoursMISSavings = savings.jobTickets.converted.hours - savings.jobTickets.converted.hoursMIS;
                savings.jobTickets.converted.costMISSavings = savings.jobTickets.converted.cost - savings.jobTickets.converted.costMIS;
                savings.jobTickets.converted.hoursStorefrontSavings = savings.jobTickets.converted.hours - savings.jobTickets.converted.hoursStorefront;
                savings.jobTickets.converted.costStorefrontSavings = savings.jobTickets.converted.cost - savings.jobTickets.converted.costStorefront;

                savings.jobTickets.withSpecs.hours = ((iA.I1054.answer.Current * iA.I1064.answer.Current) / 60.0) * 12;
                savings.jobTickets.withSpecs.cost = savings.jobTickets.withSpecs.hours * adminRate;
                savings.jobTickets.withSpecs.hoursMIS = ((iA.I1054.answer.MIS * iA.I1064.answer.MIS) / 60.0) * 12;
                savings.jobTickets.withSpecs.costMIS = savings.jobTickets.withSpecs.hoursMIS * adminRate;
                savings.jobTickets.withSpecs.hoursStorefront = ((iA.I1054.answer.Storefront * iA.I1064.answer.Storefront) / 60.0) * 12;
                savings.jobTickets.withSpecs.costStorefront = savings.jobTickets.withSpecs.hoursStorefront * adminRate;
                savings.jobTickets.withSpecs.hoursMISSavings = savings.jobTickets.withSpecs.hours - savings.jobTickets.withSpecs.hoursMIS;
                savings.jobTickets.withSpecs.costMISSavings = savings.jobTickets.withSpecs.cost - savings.jobTickets.withSpecs.costMIS;
                savings.jobTickets.withSpecs.hoursStorefrontSavings = savings.jobTickets.withSpecs.hours - savings.jobTickets.withSpecs.hoursStorefront;
                savings.jobTickets.withSpecs.costStorefrontSavings = savings.jobTickets.withSpecs.cost - savings.jobTickets.withSpecs.costStorefront;

                savings.jobTickets.developed.hours = ((iA.I1055.answer.Current * iA.I1065.answer.Current) / 60.0) * 12;
                savings.jobTickets.developed.cost = savings.jobTickets.developed.hours * adminRate;
                savings.jobTickets.developed.hoursMIS = ((iA.I1055.answer.MIS * iA.I1065.answer.MIS) / 60.0) * 12;
                savings.jobTickets.developed.costMIS = savings.jobTickets.developed.hoursMIS * adminRate;
                savings.jobTickets.developed.hoursStorefront = ((iA.I1055.answer.Storefront * iA.I1065.answer.Storefront) / 60.0) * 12;
                savings.jobTickets.developed.costStorefront = savings.jobTickets.developed.hoursStorefront * adminRate;
                savings.jobTickets.developed.hoursMISSavings = savings.jobTickets.developed.hours - savings.jobTickets.developed.hoursMIS;
                savings.jobTickets.developed.costMISSavings = savings.jobTickets.developed.cost - savings.jobTickets.developed.costMIS;
                savings.jobTickets.developed.hoursStorefrontSavings = savings.jobTickets.developed.hours - savings.jobTickets.developed.hoursStorefront;
                savings.jobTickets.developed.costStorefrontSavings = savings.jobTickets.developed.cost - savings.jobTickets.developed.costStorefront;

                savings.jobTickets.reorder.hours = ((iA.I1056.answer.Current * iA.I1066.answer.Current) / 60.0) * 12;
                savings.jobTickets.reorder.cost = savings.jobTickets.reorder.hours * adminRate;
                savings.jobTickets.reorder.hoursMIS = ((iA.I1056.answer.MIS * iA.I1066.answer.MIS) / 60.0) * 12;
                savings.jobTickets.reorder.costMIS = savings.jobTickets.reorder.hoursMIS * adminRate;
                savings.jobTickets.reorder.hoursStorefront = ((iA.I1056.answer.Storefront * iA.I1066.answer.Storefront) / 60.0) * 12;
                savings.jobTickets.reorder.costStorefront = savings.jobTickets.reorder.hoursStorefront * adminRate;
                savings.jobTickets.reorder.hoursMISSavings = savings.jobTickets.reorder.hours - savings.jobTickets.reorder.hoursMIS;
                savings.jobTickets.reorder.costMISSavings = savings.jobTickets.reorder.cost - savings.jobTickets.reorder.costMIS;
                savings.jobTickets.reorder.hoursStorefrontSavings = savings.jobTickets.reorder.hours - savings.jobTickets.reorder.hoursStorefront;
                savings.jobTickets.reorder.costStorefrontSavings = savings.jobTickets.reorder.cost - savings.jobTickets.reorder.costStorefront;

                savings.jobTickets.fulfillmentManual.hours = ((iA.I1057.answer.Current * iA.I1067.answer.Current) / 60.0) * 12;
                savings.jobTickets.fulfillmentManual.cost = savings.jobTickets.fulfillmentManual.hours * adminRate;
                savings.jobTickets.fulfillmentManual.hoursMIS = ((iA.I1057.answer.MIS * iA.I1067.answer.MIS) / 60.0) * 12;
                savings.jobTickets.fulfillmentManual.costMIS = savings.jobTickets.fulfillmentManual.hoursMIS * adminRate;
                savings.jobTickets.fulfillmentManual.hoursStorefront = ((iA.I1057.answer.Storefront * iA.I1067.answer.Storefront) / 60.0) * 12;
                savings.jobTickets.fulfillmentManual.costStorefront = savings.jobTickets.fulfillmentManual.hoursStorefront * adminRate;
                savings.jobTickets.fulfillmentManual.hoursMISSavings = savings.jobTickets.fulfillmentManual.hours - savings.jobTickets.fulfillmentManual.hoursMIS;
                savings.jobTickets.fulfillmentManual.costMISSavings = savings.jobTickets.fulfillmentManual.cost - savings.jobTickets.fulfillmentManual.costMIS;
                savings.jobTickets.fulfillmentManual.hoursStorefrontSavings = savings.jobTickets.fulfillmentManual.hours - savings.jobTickets.fulfillmentManual.hoursStorefront;
                savings.jobTickets.fulfillmentManual.costStorefrontSavings = savings.jobTickets.fulfillmentManual.cost - savings.jobTickets.fulfillmentManual.costStorefront;




                savings.jobTickets.totals.hours = savings.jobTickets.production.hours + savings.jobTickets.fulfillmentOnly.hours + savings.jobTickets.converted.hours +
                    savings.jobTickets.withSpecs.hours + savings.jobTickets.developed.hours + savings.jobTickets.reorder.hours + savings.jobTickets.fulfillmentManual.hours;
                savings.jobTickets.totals.cost = savings.jobTickets.production.cost + savings.jobTickets.fulfillmentOnly.cost + savings.jobTickets.converted.cost +
                    savings.jobTickets.withSpecs.cost + savings.jobTickets.developed.cost + savings.jobTickets.reorder.cost + savings.jobTickets.fulfillmentManual.cost;
                savings.jobTickets.totals.hoursMIS = savings.jobTickets.production.hoursMIS + savings.jobTickets.fulfillmentOnly.hoursMIS + savings.jobTickets.converted.hoursMIS +
                    savings.jobTickets.withSpecs.hoursMIS + savings.jobTickets.developed.hoursMIS + savings.jobTickets.reorder.hoursMIS + savings.jobTickets.fulfillmentManual.hoursMIS;
                savings.jobTickets.totals.costMIS = savings.jobTickets.production.costMIS + savings.jobTickets.fulfillmentOnly.costMIS + savings.jobTickets.converted.costMIS +
                    savings.jobTickets.withSpecs.costMIS + savings.jobTickets.developed.costMIS + savings.jobTickets.reorder.costMIS + savings.jobTickets.fulfillmentManual.costMIS;
                savings.jobTickets.totals.hoursMISSavings = savings.jobTickets.totals.hours - savings.jobTickets.totals.hoursMIS;
                savings.jobTickets.totals.costMISSavings = savings.jobTickets.totals.cost - savings.jobTickets.totals.costMIS;
                savings.jobTickets.totals.hoursStorefront = savings.jobTickets.production.hoursStorefront + savings.jobTickets.fulfillmentOnly.hoursStorefront + savings.jobTickets.converted.hoursStorefront +
                    savings.jobTickets.withSpecs.hoursStorefront + savings.jobTickets.developed.hoursStorefront + savings.jobTickets.reorder.hoursStorefront + savings.jobTickets.fulfillmentManual.hoursStorefront;
                savings.jobTickets.totals.costStorefront = savings.jobTickets.production.costStorefront + savings.jobTickets.fulfillmentOnly.costStorefront + savings.jobTickets.converted.costStorefront +
                    savings.jobTickets.withSpecs.costStorefront + savings.jobTickets.developed.costStorefront + savings.jobTickets.reorder.costStorefront + savings.jobTickets.fulfillmentManual.costStorefront;
                savings.jobTickets.totals.hoursStorefrontSavings = savings.jobTickets.totals.hours - savings.jobTickets.totals.hoursStorefront;
                savings.jobTickets.totals.costStorefrontSavings = savings.jobTickets.totals.cost - savings.jobTickets.totals.costStorefront;


                savings.vdp = {
                    preFlight: {
                        hours: ((iA.V1010.answer.Current * iA.V1001.answer.final) / 60.0) * 12,
                        cost: (((iA.V1010.answer.Current * iA.V1001.answer.final) / 60.0) * 12) * (iA.I1016.answer.final),
                        hoursAutomation: ((iA.V1010.answer.Automation * iA.V1001.answer.final) / 60.0) * 12,
                        costAutomation: (((iA.V1010.answer.Automation * iA.V1001.answer.final) / 60.0) * 12) * (iA.I1016.answer.final),
                        hourSavingsAnnual: 0,
                        costSavingsAnnual: 0
                    },
                    imposition: {
                        hours: ((iA.V1011.answer.Current * iA.V1001.answer.final) / 60.0) * 12,
                        cost: (((iA.V1011.answer.Current * iA.V1001.answer.final) / 60.0) * 12) * (iA.I1016.answer.final),
                        hoursAutomation: ((iA.V1011.answer.Automation * iA.V1001.answer.final) / 60.0) * 12,
                        costAutomation: (((iA.V1011.answer.Automation * iA.V1001.answer.final) / 60.0) * 12) * (iA.I1016.answer.final),
                        hourSavingsAnnual: 0,
                        costSavingsAnnual: 0
                    },
                    baseTemplate: {
                        hours: ((costs.vdp.orderDetails.baseTemplate.vdpMinPerOrder *  iA.V1001.answer.final) / 60.0) * 12,
                        cost: (((costs.vdp.orderDetails.baseTemplate.vdpMinPerOrder *  iA.V1001.answer.final) / 60.0) * 12) * (iA.I1016.answer.final),
                        hoursVDP: ((costs.vdp.orderDetails.baseTemplate.vdpMinPerOrder *  iA.V1001.answer.final) / 60.0) * 12,
                        costVDP: (((costs.vdp.orderDetails.baseTemplate.vdpMinPerOrder *  iA.V1001.answer.final) / 60.0) * 12) * (iA.I1016.answer.final),
                        hourSavingsAnnual: 0,
                        costSavingsAnnual: 0
                    },
                    jobTicket: {
                        hours: costs.vdp.orderDetails.jobTicketVDP.hoursMonthly * 12,
                        cost: (costs.vdp.orderDetails.jobTicketVDP.hoursMonthly * 12) * (iA.I1016.answer.final),
                        hoursVDP: costs.vdp.orderDetails.jobTicketVDP.vdpHoursMonthly * 12,
                        costVDP: (costs.vdp.orderDetails.jobTicketVDP.vdpHoursMonthly * 12) * (iA.I1016.answer.final),
                        hourSavingsAnnual: 0,
                        costSavingsAnnual: 0
                    },
                    customization: {
                        hours: (costs.vdp.orderDetails.customization.minPerOrder * iA.V1001.answer.final / 60) * 12,
                        cost: ((costs.vdp.orderDetails.customization.minPerOrder * iA.V1001.answer.final / 60) * 12) * (iA.I1016.answer.final),
                        hoursVDP: (costs.vdp.orderDetails.customization.vdpMinPerOrder * iA.V1001.answer.final / 60) * 12,
                        costVDP: ((costs.vdp.orderDetails.customization.vdpMinPerOrder * iA.V1001.answer.final / 60) * 12) * (iA.I1016.answer.final),
                        hourSavingsAnnual: 0,
                        costSavingsAnnual: 0
                    },
                    proofing: {
                        hours: (costs.vdp.orderDetails.proofing.minPerOrder * iA.V1001.answer.final / 60) * 12,
                        cost: ((costs.vdp.orderDetails.proofing.minPerOrder * iA.V1001.answer.final / 60) * 12) * (iA.I1016.answer.final),
                        hoursVDP: (costs.vdp.orderDetails.proofing.vdpMinPerOrder * iA.V1001.answer.final / 60) * 12,
                        costVDP: ((costs.vdp.orderDetails.proofing.vdpMinPerOrder * iA.V1001.answer.final / 60) * 12) * (iA.I1016.answer.final),
                        hourSavingsAnnual: 0,
                        costSavingsAnnual: 0
                    },
                    processing: {
                        hours: (costs.vdp.orderDetails.processing.minPerOrder * iA.V1001.answer.final / 60) * 12,
                        cost: ((costs.vdp.orderDetails.processing.minPerOrder * iA.V1001.answer.final / 60) * 12) * (iA.I1016.answer.final),
                        hoursVDP: (costs.vdp.orderDetails.processing.vdpMinPerOrder * iA.V1001.answer.final / 60) * 12,
                        costVDP: ((costs.vdp.orderDetails.processing.vdpMinPerOrder * iA.V1001.answer.final / 60) * 12) * (iA.I1016.answer.final),
                        hourSavingsAnnual: 0,
                        costSavingsAnnual: 0
                    },
                    subtotal: {
                        hours: 0,
                        cost: 0,
                        hoursVDP: 0,
                        costVDP: 0,
                        hourSavingsAnnual: 0,
                        costSavingsAnnual: 0
                    },
                    totals: {
                        hours: 0,
                        cost: 0,
                        hoursVDP: 0,
                        costVDP: 0,
                        hoursVDPSavings: 0,
                        costVDPSavings: 0
                    }
                }

                savings.vdp.preFlight.hourSavingsAnnual = savings.vdp.preFlight.hours - savings.vdp.preFlight.hoursAutomation;
                savings.vdp.preFlight.costSavingsAnnual = savings.vdp.preFlight.cost - savings.vdp.preFlight.costAutomation;
                savings.vdp.imposition.hourSavingsAnnual = savings.vdp.imposition.hours - savings.vdp.imposition.hoursAutomation;
                savings.vdp.imposition.costSavingsAnnual = savings.vdp.imposition.cost - savings.vdp.imposition.costAutomation;

                savings.vdp.customization.hourSavingsAnnual = savings.vdp.customization.hours - savings.vdp.customization.hoursVDP;
                savings.vdp.customization.costSavingsAnnual = savings.vdp.customization.cost - savings.vdp.customization.costVDP;

                savings.vdp.proofing.hourSavingsAnnual = savings.vdp.proofing.hours - savings.vdp.proofing.hoursVDP;
                savings.vdp.proofing.costSavingsAnnual = savings.vdp.proofing.cost - savings.vdp.proofing.costVDP;

                savings.vdp.jobTicket.hourSavingsAnnual = savings.vdp.jobTicket.hours - savings.vdp.jobTicket.hoursVDP;
                savings.vdp.jobTicket.costSavingsAnnual = savings.vdp.jobTicket.cost - savings.vdp.jobTicket.costVDP;

                savings.vdp.processing.hourSavingsAnnual = savings.vdp.processing.hours - savings.vdp.processing.hoursVDP;
                savings.vdp.processing.costSavingsAnnual = savings.vdp.processing.cost - savings.vdp.processing.costVDP;

                //savings.vdp.designOrder.hours = savings.vdp.baseTemplate.hours + savings.vdp.jobTicket.hours +
                savings.vdp.subtotal.hours = savings.vdp.baseTemplate.hours + savings.vdp.customization.hours + savings.vdp.proofing.hours + savings.vdp.processing.hours + savings.vdp.jobTicket.hours;
                savings.vdp.subtotal.cost = savings.vdp.baseTemplate.cost + savings.vdp.customization.cost + savings.vdp.proofing.cost + savings.vdp.processing.cost + savings.vdp.jobTicket.cost;
                savings.vdp.subtotal.hoursVDP = savings.vdp.baseTemplate.hoursVDP + savings.vdp.customization.hoursVDP + savings.vdp.proofing.hoursVDP + savings.vdp.processing.hoursVDP + savings.vdp.jobTicket.hoursVDP;
                savings.vdp.subtotal.costVDP = savings.vdp.baseTemplate.costVDP + savings.vdp.customization.costVDP + savings.vdp.proofing.costVDP + savings.vdp.processing.costVDP + savings.vdp.jobTicket.costVDP;
                savings.vdp.subtotal.hourSavingsAnnual = savings.vdp.subtotal.hours - savings.vdp.subtotal.hoursVDP;
                savings.vdp.subtotal.costSavingsAnnual = savings.vdp.subtotal.cost - savings.vdp.subtotal.costVDP;

                savings.vdp.totals.hours = savings.vdp.subtotal.hours + savings.vdp.preFlight.hours + savings.vdp.imposition.hours;
                savings.vdp.totals.cost = savings.vdp.subtotal.cost + savings.vdp.preFlight.cost + savings.vdp.imposition.cost;
                savings.vdp.totals.hoursVDP = savings.vdp.subtotal.hoursVDP + savings.vdp.preFlight.hoursAutomation + savings.vdp.imposition.hoursAutomation;
                savings.vdp.totals.costVDP = savings.vdp.subtotal.costVDP + savings.vdp.preFlight.costAutomation + savings.vdp.imposition.costAutomation;
                savings.vdp.totals.hoursVDPSavings = savings.vdp.totals.hours - savings.vdp.totals.hoursVDP;
                savings.vdp.totals.costVDPSavings = savings.vdp.totals.cost - savings.vdp.totals.costVDP;

                savings.inventory.totals.hours = (iA.I1070.answer.Current * 12) / 60;
                savings.inventory.totals.cost = savings.inventory.totals.hours * adminRate;
                savings.inventory.totals.hoursMIS = (iA.I1070.answer.MIS * 12) / 60;
                savings.inventory.totals.costMIS = savings.inventory.totals.hoursMIS * adminRate;
                savings.inventory.totals.hoursMISSavings = savings.inventory.totals.hours - savings.inventory.totals.hoursMIS;
                savings.inventory.totals.costMISSavings = savings.inventory.totals.cost - savings.inventory.totals.costMIS;
                savings.inventory.totals.hoursStorefront = (iA.I1070.answer.Storefront * 12) / 60;
                savings.inventory.totals.costStorefront = savings.inventory.totals.hoursStorefront * adminRate;
                savings.inventory.totals.hoursStorefrontSavings = savings.inventory.totals.hours - savings.inventory.totals.hoursStorefront;
                savings.inventory.totals.costStorefrontSavings = savings.inventory.totals.cost - savings.inventory.totals.costStorefront;

                savings.jobTracking.admin.hours = ((iA.I1096.answer.Current * iA.I1011.answer.final * iA.I1022.answer.final) / 60.0);
                savings.jobTracking.admin.cost = savings.jobTracking.admin.hours * adminRate;
                savings.jobTracking.admin.hoursMIS = ((iA.I1096.answer.MIS * iA.I1011.answer.final * iA.I1022.answer.final) / 60.0);
                savings.jobTracking.admin.costMIS = savings.jobTracking.admin.hoursMIS * adminRate;
                savings.jobTracking.admin.hoursStorefront = ((iA.I1096.answer.Storefront * iA.I1011.answer.final * iA.I1022.answer.final) / 60.0);
                savings.jobTracking.admin.costStorefront = savings.jobTracking.admin.hoursStorefront * adminRate;
                savings.jobTracking.admin.hoursMISSavings = savings.jobTracking.admin.hours - savings.jobTracking.admin.hoursMIS;
                savings.jobTracking.admin.costMISSavings = savings.jobTracking.admin.cost - savings.jobTracking.admin.costMIS;
                savings.jobTracking.admin.hoursStorefrontSavings = savings.jobTracking.admin.hours - savings.jobTracking.admin.hoursStorefront;
                savings.jobTracking.admin.costStorefrontSavings = savings.jobTracking.admin.cost - savings.jobTracking.admin.costStorefront;

                savings.jobTracking.owner.hours = ((iA.I1097.answer.Current * iA.I1011.answer.final * iA.I1022.answer.final) / 60.0);
                savings.jobTracking.owner.cost = savings.jobTracking.owner.hours * adminRate;
                savings.jobTracking.owner.hoursMIS = ((iA.I1097.answer.MIS * iA.I1011.answer.final * iA.I1022.answer.final) / 60.0);
                savings.jobTracking.owner.costMIS = savings.jobTracking.owner.hoursMIS * adminRate;
                savings.jobTracking.owner.hoursStorefront = ((iA.I1097.answer.Storefront * iA.I1011.answer.final * iA.I1022.answer.final) / 60.0);
                savings.jobTracking.owner.costStorefront = savings.jobTracking.owner.hoursStorefront * adminRate;
                savings.jobTracking.owner.hoursMISSavings = savings.jobTracking.owner.hours - savings.jobTracking.owner.hoursMIS;
                savings.jobTracking.owner.costMISSavings = savings.jobTracking.owner.cost - savings.jobTracking.owner.costMIS;
                savings.jobTracking.owner.hoursStorefrontSavings = savings.jobTracking.owner.hours - savings.jobTracking.owner.hoursStorefront;
                savings.jobTracking.owner.costStorefrontSavings = savings.jobTracking.owner.cost - savings.jobTracking.owner.costStorefront;

                savings.jobTracking.meetings.hours = ((iA.I1098.answer.Current * iA.I1011.answer.final * iA.I1022.answer.final) / 60.0);
                savings.jobTracking.meetings.cost = savings.jobTracking.meetings.hours * adminRate;
                savings.jobTracking.meetings.hoursMIS = ((iA.I1098.answer.MIS * iA.I1011.answer.final * iA.I1022.answer.final) / 60.0);
                savings.jobTracking.meetings.costMIS = savings.jobTracking.meetings.hoursMIS * adminRate;
                savings.jobTracking.meetings.hoursStorefront = ((iA.I1098.answer.Storefront * iA.I1011.answer.final * iA.I1022.answer.final) / 60.0);
                savings.jobTracking.meetings.costStorefront = savings.jobTracking.meetings.hoursStorefront * adminRate;
                savings.jobTracking.meetings.hoursMISSavings = savings.jobTracking.meetings.hours - savings.jobTracking.meetings.hoursMIS;
                savings.jobTracking.meetings.costMISSavings = savings.jobTracking.meetings.cost - savings.jobTracking.meetings.costMIS;
                savings.jobTracking.meetings.hoursStorefrontSavings = savings.jobTracking.meetings.hours - savings.jobTracking.meetings.hoursStorefront;
                savings.jobTracking.meetings.costStorefrontSavings = savings.jobTracking.meetings.cost - savings.jobTracking.meetings.costStorefront;

                savings.jobTracking.totals.hours = savings.jobTracking.admin.hours + savings.jobTracking.owner.hours + savings.jobTracking.meetings.hours;
                savings.jobTracking.totals.cost = savings.jobTracking.admin.cost + savings.jobTracking.owner.cost + savings.jobTracking.meetings.cost;
                savings.jobTracking.totals.hoursMIS = savings.jobTracking.admin.hoursMIS + savings.jobTracking.owner.hoursMIS + savings.jobTracking.meetings.hoursMIS;
                savings.jobTracking.totals.costMIS = savings.jobTracking.admin.costMIS + savings.jobTracking.owner.costMIS + savings.jobTracking.meetings.costMIS;
                savings.jobTracking.totals.hoursMISSavings = savings.jobTracking.totals.hours - savings.jobTracking.totals.hoursMIS;
                savings.jobTracking.totals.costMISSavings = savings.jobTracking.totals.cost - savings.jobTracking.totals.costMIS;
                savings.jobTracking.totals.hoursStorefront = savings.jobTracking.admin.hoursStorefront + savings.jobTracking.owner.hoursStorefront + savings.jobTracking.meetings.hoursStorefront;
                savings.jobTracking.totals.costStorefront = savings.jobTracking.admin.costStorefront + savings.jobTracking.owner.costStorefront + savings.jobTracking.meetings.costStorefront;
                savings.jobTracking.totals.hoursStorefrontSavings = savings.jobTracking.totals.hours - savings.jobTracking.totals.hoursStorefront;
                savings.jobTracking.totals.costStorefrontSavings = savings.jobTracking.totals.cost - savings.jobTracking.totals.costStorefront;

                savings.shipments.tracking.hours = ((iA.I1113.answer.Current * iA.I1059.answer.Current) * iA.I1114.answer.Current * 0.01) / 60;
                savings.shipments.tracking.cost = savings.shipments.tracking.hours * adminRate;
                savings.shipments.tracking.hoursMIS = ((iA.I1113.answer.MIS * iA.I1059.answer.Current) * iA.I1114.answer.MIS * 0.01) / 60;
                savings.shipments.tracking.costMIS = savings.shipments.tracking.hoursMIS * adminRate;
                savings.shipments.tracking.hoursStorefront = ((iA.I1113.answer.Storefront * iA.I1059.answer.Current) * iA.I1114.answer.Storefront * 0.01) / 60;
                savings.shipments.tracking.costStorefront = savings.shipments.tracking.hoursStorefront * adminRate;
                savings.shipments.tracking.hoursMISSavings = savings.shipments.tracking.hours - savings.shipments.tracking.hoursMIS;
                savings.shipments.tracking.costMISSavings = savings.shipments.tracking.cost - savings.shipments.tracking.costMIS;
                savings.shipments.tracking.hoursStorefrontSavings = savings.shipments.tracking.hours - savings.shipments.tracking.hoursStorefront;
                savings.shipments.tracking.costStorefrontSavings = savings.shipments.tracking.cost - savings.shipments.tracking.costStorefront;

                savings.shipments.docs.hours = (iA.I1115.answer.Current * iA.I1059.answer.Current) / 60;
                savings.shipments.docs.cost = savings.shipments.docs.hours * adminRate;
                savings.shipments.docs.hoursMIS = ((iA.I1115.answer.MIS * iA.I1059.answer.Current) * iA.I1114.answer.MIS * 0.01) / 60;
                savings.shipments.docs.costMIS = savings.shipments.docs.hoursMIS * adminRate;
                savings.shipments.docs.hoursStorefront = ((iA.I1115.answer.Storefront * iA.I1059.answer.Current) * iA.I1114.answer.Storefront * 0.01) / 60;
                savings.shipments.docs.costStorefront = savings.shipments.docs.hoursStorefront * adminRate;
                savings.shipments.docs.hoursMISSavings = savings.shipments.docs.hours - savings.shipments.docs.hoursMIS;
                savings.shipments.docs.costMISSavings = savings.shipments.docs.cost - savings.shipments.docs.costMIS;
                savings.shipments.docs.hoursStorefrontSavings = savings.shipments.docs.hours - savings.shipments.docs.hoursStorefront;
                savings.shipments.docs.costStorefrontSavings = savings.shipments.docs.cost - savings.shipments.docs.costStorefront;

                savings.shipments.totals.hours = savings.shipments.tracking.hours + savings.shipments.docs.hours;
                savings.shipments.totals.cost = savings.shipments.tracking.cost + savings.shipments.docs.cost;
                savings.shipments.totals.hoursMIS = savings.shipments.tracking.hoursMIS + savings.shipments.docs.hoursMIS;
                savings.shipments.totals.costMIS = savings.shipments.tracking.costMIS + savings.shipments.docs.costMIS;
                savings.shipments.totals.hoursMISSavings = savings.shipments.totals.hours - savings.shipments.totals.hoursMIS;
                savings.shipments.totals.costMISSavings = savings.shipments.totals.cost - savings.shipments.totals.costMIS;
                savings.shipments.totals.hoursStorefront = savings.shipments.tracking.hoursStorefront + savings.shipments.docs.hoursStorefront;
                savings.shipments.totals.costStorefront = savings.shipments.tracking.costStorefront + savings.shipments.docs.costStorefront;
                savings.shipments.totals.hoursStorefrontSavings = savings.shipments.totals.hours - savings.shipments.totals.hoursStorefront;
                savings.shipments.totals.costStorefrontSavings = savings.shipments.totals.cost - savings.shipments.totals.costStorefront;


                savings.purchasing.create.hours = (iA.I1084.answer.Current * iA.I1086.answer.Current) * 12 / 60;
                savings.purchasing.create.cost = savings.purchasing.create.hours * adminRate;
                savings.purchasing.create.hoursMIS = (iA.I1084.answer.MIS * iA.I1086.answer.MIS) * 12 / 60;
                savings.purchasing.create.costMIS = savings.purchasing.create.hoursMIS * adminRate;
                savings.purchasing.create.hoursStorefront = (iA.I1084.answer.Storefront * iA.I1086.answer.Storefront) * 12 / 60;
                savings.purchasing.create.costStorefront = savings.purchasing.create.hoursStorefront * adminRate;
                savings.purchasing.create.hoursMISSavings = savings.purchasing.create.hours - savings.purchasing.create.hoursMIS;
                savings.purchasing.create.costMISSavings = savings.purchasing.create.cost - savings.purchasing.create.costMIS;
                savings.purchasing.create.hoursStorefrontSavings = savings.purchasing.create.hours - savings.purchasing.create.hoursStorefront;
                savings.purchasing.create.costStorefrontSavings = savings.purchasing.create.cost - savings.purchasing.create.costStorefront;

                savings.purchasing.reconcile.hours = (iA.I1085.answer.Current * iA.I1086.answer.Current) * 12 / 60;
                savings.purchasing.reconcile.cost = savings.purchasing.reconcile.hours * adminRate;
                savings.purchasing.reconcile.hoursMIS = (iA.I1085.answer.MIS * iA.I1086.answer.MIS) * 12 / 60;
                savings.purchasing.reconcile.costMIS = savings.purchasing.reconcile.hoursMIS * adminRate;
                savings.purchasing.reconcile.hoursStorefront = (iA.I1085.answer.Storefront * iA.I1086.answer.Storefront) * 12 / 60;
                savings.purchasing.reconcile.costStorefront = savings.purchasing.reconcile.hoursStorefront * adminRate;
                savings.purchasing.reconcile.hoursMISSavings = savings.purchasing.reconcile.hours - savings.purchasing.reconcile.hoursMIS;
                savings.purchasing.reconcile.costMISSavings = savings.purchasing.reconcile.cost - savings.purchasing.reconcile.costMIS;
                savings.purchasing.reconcile.hoursStorefrontSavings = savings.purchasing.reconcile.hours - savings.purchasing.reconcile.hoursStorefront;
                savings.purchasing.reconcile.costStorefrontSavings = savings.purchasing.reconcile.cost - savings.purchasing.reconcile.costStorefront;

                savings.purchasing.processing.hours = (iA.I1087.answer.Current * iA.I1086.answer.Current) * 12 / 60;
                savings.purchasing.processing.cost = savings.purchasing.processing.hours * adminRate;
                savings.purchasing.processing.hoursMIS = (iA.I1087.answer.MIS * iA.I1086.answer.MIS) * 12 / 60;
                savings.purchasing.processing.costMIS = savings.purchasing.processing.hoursMIS * adminRate;
                savings.purchasing.processing.hoursStorefront = (iA.I1087.answer.Storefront * iA.I1086.answer.Storefront) * 12 / 60;
                savings.purchasing.processing.costStorefront = savings.purchasing.processing.hoursStorefront * adminRate;
                savings.purchasing.processing.hoursMISSavings = savings.purchasing.processing.hours - savings.purchasing.processing.hoursMIS;
                savings.purchasing.processing.costMISSavings = savings.purchasing.processing.cost - savings.purchasing.processing.costMIS;
                savings.purchasing.processing.hoursStorefrontSavings = savings.purchasing.processing.hours - savings.purchasing.processing.hoursStorefront;
                savings.purchasing.processing.costStorefrontSavings = savings.purchasing.processing.cost - savings.purchasing.processing.costStorefront;

                savings.purchasing.totals.hours = savings.purchasing.create.hours + savings.purchasing.reconcile.hours + savings.purchasing.processing.hours;
                savings.purchasing.totals.cost = savings.purchasing.create.cost + savings.purchasing.reconcile.cost + savings.purchasing.processing.cost;
                savings.purchasing.totals.hoursMIS = savings.purchasing.create.hoursMIS + savings.purchasing.reconcile.hoursMIS + savings.purchasing.processing.hoursMIS;
                savings.purchasing.totals.costMIS = savings.purchasing.create.costMIS + savings.purchasing.reconcile.costMIS + savings.purchasing.processing.costMIS;
                savings.purchasing.totals.hoursMISSavings = savings.purchasing.totals.hours - savings.purchasing.totals.hoursMIS;
                savings.purchasing.totals.costMISSavings = savings.purchasing.totals.cost - savings.purchasing.totals.costMIS;
                savings.purchasing.totals.hoursStorefront = savings.purchasing.create.hoursStorefront + savings.purchasing.reconcile.hoursStorefront + savings.purchasing.processing.hoursStorefront;
                savings.purchasing.totals.costStorefront = savings.purchasing.create.costStorefront + savings.purchasing.reconcile.costStorefront + +savings.purchasing.processing.costStorefront;
                savings.purchasing.totals.hoursStorefrontSavings = savings.purchasing.totals.hours - savings.purchasing.totals.hoursStorefront;
                savings.purchasing.totals.costStorefrontSavings = savings.purchasing.totals.cost - savings.purchasing.totals.costStorefront;


                savings.externalVsInternal.totals.cost = iA.I1002.answer.final;
                savings.externalVsInternal.totals.costMIS = 0;
                savings.externalVsInternal.totals.costMISSavings = 0;
                savings.externalVsInternal.totals.costStorefront = iA.I1002.answer.final - (iA.I1088.answer.Storefront * iA.I1089.answer.Storefront * .01);
                savings.externalVsInternal.totals.costStorefrontSavings = savings.externalVsInternal.totals.cost - savings.externalVsInternal.totals.costStorefront;

                savings.billing.create.hours = (iA.I1125.answer.Current * iA.I1059.answer.Current) / 60;
                savings.billing.create.cost = savings.billing.create.hours * adminRate;
                savings.billing.create.hoursMIS = (iA.I1125.answer.MIS * iA.I1059.answer.MIS) / 60;
                savings.billing.create.costMIS = savings.billing.create.hoursMIS * adminRate;
                savings.billing.create.hoursStorefront = (iA.I1125.answer.Storefront * iA.I1059.answer.Storefront) / 60;
                savings.billing.create.costStorefront = savings.billing.create.hoursStorefront * adminRate;
                savings.billing.create.hoursMISSavings = savings.billing.create.hours - savings.billing.create.hoursMIS;
                savings.billing.create.costMISSavings = savings.billing.create.cost - savings.billing.create.costMIS;
                savings.billing.create.hoursStorefrontSavings = savings.billing.create.hours - savings.billing.create.hoursStorefront;
                savings.billing.create.costStorefrontSavings = savings.billing.create.cost - savings.billing.create.costStorefront;

                savings.billing.accounting.hours = (iA.I1126.answer.Current * iA.I1059.answer.Current) / 60;
                savings.billing.accounting.cost = savings.billing.accounting.hours * adminRate;
                savings.billing.accounting.hoursMIS = (iA.I1126.answer.MIS * iA.I1059.answer.MIS) / 60;
                savings.billing.accounting.costMIS = savings.billing.accounting.hoursMIS * adminRate;
                savings.billing.accounting.hoursStorefront = (iA.I1126.answer.Storefront * iA.I1059.answer.Storefront) / 60;
                savings.billing.accounting.costStorefront = savings.billing.accounting.hoursStorefront * adminRate;
                savings.billing.accounting.hoursMISSavings = savings.billing.accounting.hours - savings.billing.accounting.hoursMIS;
                savings.billing.accounting.costMISSavings = savings.billing.accounting.cost - savings.billing.accounting.costMIS;
                savings.billing.accounting.hoursStorefrontSavings = savings.billing.accounting.hours - savings.billing.accounting.hoursStorefront;
                savings.billing.accounting.costStorefrontSavings = savings.billing.accounting.cost - savings.billing.accounting.costStorefront;

                savings.billing.plCost.hours = 0;
                savings.billing.plCost.cost = ((iA.I1001.answer.final * iA.I1020.answer.final * 0.01) / 365) * (iA.I1123.answer.Current + iA.I1124.answer.Current);
                savings.billing.plCost.hoursMIS = 0;
                savings.billing.plCost.costMIS = ((iA.I1001.answer.final * iA.I1020.answer.final * 0.01) / 365) * (iA.I1123.answer.MIS + iA.I1124.answer.MIS);
                savings.billing.plCost.costStorefront = ((iA.I1001.answer.final * iA.I1020.answer.final * 0.01) / 365) * (iA.I1123.answer.Storefront + iA.I1124.answer.Storefront);
                savings.billing.plCost.hoursMISSavings = savings.billing.plCost.hours - savings.billing.plCost.hoursMIS;
                savings.billing.plCost.costMISSavings = savings.billing.plCost.cost - savings.billing.plCost.costMIS;
                savings.billing.plCost.hoursStorefrontSavings = savings.billing.plCost.hours - savings.billing.plCost.hoursStorefront;
                savings.billing.plCost.costStorefrontSavings = savings.billing.plCost.cost - savings.billing.plCost.costStorefront;

                savings.billing.totals.hours = savings.billing.create.hours + savings.billing.accounting.hours + savings.billing.plCost.hours;
                savings.billing.totals.cost = savings.billing.create.cost + savings.billing.accounting.cost + savings.billing.plCost.cost;
                savings.billing.totals.hoursMIS = savings.billing.create.hoursMIS + savings.billing.accounting.hoursMIS + savings.billing.plCost.hoursMIS;
                savings.billing.totals.costMIS = savings.billing.create.costMIS + savings.billing.accounting.costMIS + savings.billing.plCost.costMIS;
                savings.billing.totals.hoursMISSavings = savings.billing.totals.hours - savings.billing.totals.hoursMIS;
                savings.billing.totals.costMISSavings = savings.billing.totals.cost - savings.billing.totals.costMIS;
                savings.billing.totals.hoursStorefront = savings.billing.create.hoursStorefront + savings.billing.accounting.hoursStorefront + savings.billing.plCost.hoursStorefront;
                savings.billing.totals.costStorefront = savings.billing.create.costStorefront + savings.billing.accounting.costStorefront + +savings.billing.plCost.costStorefront;
                savings.billing.totals.hoursStorefrontSavings = savings.billing.totals.hours - savings.billing.totals.hoursStorefront;
                savings.billing.totals.costStorefrontSavings = savings.billing.totals.cost - savings.billing.totals.costStorefront;

                savings.labor.totals.hours = ((iA.I1127.answer.Current * iA.I1013.answer.final) * iA.I1022.answer.final) / 60;
                savings.labor.totals.cost = savings.labor.totals.hours * adminRate;
                savings.labor.totals.hoursMIS = ((iA.I1127.answer.MIS * iA.I1013.answer.final) * iA.I1022.answer.final) / 60;
                savings.labor.totals.costMIS = savings.labor.totals.hoursMIS * adminRate;
                savings.labor.totals.hoursMISSavings = savings.labor.totals.hours - savings.labor.totals.hoursMIS;
                savings.labor.totals.costMISSavings = savings.labor.totals.cost - savings.labor.totals.costMIS;
                savings.labor.totals.hoursStorefront = ((iA.I1127.answer.Storefront * iA.I1013.answer.final) * iA.I1022.answer.final) / 60;
                savings.labor.totals.costStorefront = savings.labor.totals.hoursStorefront * adminRate;
                savings.labor.totals.hoursStorefrontSavings = savings.labor.totals.hours - savings.labor.totals.hoursStorefront;
                savings.labor.totals.costStorefrontSavings = savings.labor.totals.cost - savings.labor.totals.costStorefront;

                savings.errors.reducedSoftware.cost = iA.I1110.answer.Current;
                savings.errors.reducedSoftware.costMIS = iA.I1110.answer.MIS;
                savings.errors.reducedSoftware.costMISSavings = savings.errors.reducedSoftware.cost - savings.errors.reducedSoftware.costMIS;
                savings.errors.reducedSoftware.costStorefront = iA.I1110.answer.Storefront;
                savings.errors.reducedSoftware.costStorefrontSavings = savings.errors.reducedSoftware.cost - savings.errors.reducedSoftware.costStorefront;

                savings.errors.reducedContent.cost = iA.I1111.answer.Current;
                savings.errors.reducedContent.costMIS = iA.I1111.answer.MIS;
                savings.errors.reducedContent.costMISSavings = savings.errors.reducedContent.cost - savings.errors.reducedContent.costMIS;
                savings.errors.reducedContent.costStorefront = iA.I1111.answer.Storefront;
                savings.errors.reducedContent.costStorefrontSavings = savings.errors.reducedContent.cost - savings.errors.reducedContent.costStorefront;

                savings.changes.totals.cost = iA.I1108.answer.Current * iA.I1109.answer.Current;
                savings.changes.totals.costMIS = iA.I1108.answer.MIS * iA.I1109.answer.MIS;
                savings.changes.totals.costMISSavings = savings.changes.totals.cost - savings.changes.totals.costMIS;
                savings.changes.totals.costStorefront = iA.I1108.answer.Storefront * iA.I1109.answer.Storefront;
                savings.changes.totals.costStorefrontSavings = savings.changes.totals.cost - savings.changes.totals.costStorefront;


                let reportRows = ["sales", "actualVsEstimate", "customerProfit", "productProfit", "weeklySales", "monthlySales", "notInvoiced", "productivity", "schedule", "payroll"];
                let reportIndex = 1134;

                reportRows.forEach((rowId, index) => {

                    let q = iA["I" + (index + reportIndex)].answer;

                    if(iA["I" + (index + reportIndex)].active){
                        savings.reports[rowId].hours = q.Current * 12;
                        savings.reports[rowId].cost = savings.reports[rowId].hours * adminRate;
                        savings.reports[rowId].hoursMIS = q.MIS * 12;
                        savings.reports[rowId].costMIS = savings.reports[rowId].hoursMIS * adminRate;
                        savings.reports[rowId].hoursStorefront = q.Storefront * 12;
                        savings.reports[rowId].costStorefront = savings.reports[rowId].hoursStorefront * adminRate;
                        savings.reports[rowId].hoursMISSavings = savings.reports[rowId].hours - savings.reports[rowId].hoursMIS;
                        savings.reports[rowId].costMISSavings = savings.reports[rowId].cost - savings.reports[rowId].costMIS;
                        savings.reports[rowId].hoursStorefrontSavings = savings.reports[rowId].hours - savings.reports[rowId].hoursStorefront;
                        savings.reports[rowId].costStorefrontSavings = savings.reports[rowId].cost - savings.reports[rowId].costStorefront;
                    }else{
                        savings.reports[rowId].hours = 0;
                        savings.reports[rowId].cost = 0;
                        savings.reports[rowId].hoursMIS = 0;
                        savings.reports[rowId].costMIS = 0;
                        savings.reports[rowId].hoursStorefront = 0;
                        savings.reports[rowId].costStorefront = 0;
                        savings.reports[rowId].hoursMISSavings = 0;
                        savings.reports[rowId].costMISSavings = 0;
                        savings.reports[rowId].hoursStorefrontSavings = 0;
                        savings.reports[rowId].costStorefrontSavings = 0;
                    }

                })

                savings.reports.totals.hours = reportRows.reduce((total, row) => {
                    return (total + savings.reports[row].hours)
                }, 0)
                savings.reports.totals.cost = reportRows.reduce((total, row) => {
                    return (total + savings.reports[row].cost)
                }, 0)
                savings.reports.totals.hoursMIS = reportRows.reduce((total, row) => {
                    return (total + savings.reports[row].hoursMIS)
                }, 0)
                savings.reports.totals.costMIS = reportRows.reduce((total, row) => {
                    return (total + savings.reports[row].costMIS)
                }, 0)
                savings.reports.totals.hoursMISSavings = savings.reports.totals.hours - savings.reports.totals.hoursMIS;
                savings.reports.totals.costMISSavings = savings.reports.totals.cost - savings.reports.totals.costMIS;
                savings.reports.totals.hoursStorefront = reportRows.reduce((total, row) => {
                    return (total + savings.reports[row].hoursStorefront)
                }, 0)
                savings.reports.totals.costStorefront = reportRows.reduce((total, row) => {
                    return (total + savings.reports[row].costStorefront)
                }, 0)
                savings.reports.totals.hoursStorefrontSavings = savings.reports.totals.hours - savings.reports.totals.hoursStorefront;
                savings.reports.totals.costStorefrontSavings = savings.reports.totals.cost - savings.reports.totals.costStorefront;

                savings.misc.totals.hours = iA.I1145.answer.Current * 12;
                savings.misc.totals.cost = ((iA.I1145.answer.Current * adminRate) + (iA.I1147.answer.Current * adminRate)) * 12;
                savings.misc.totals.hoursMIS = iA.I1145.answer.MIS * 12;
                savings.misc.totals.costMIS = ((iA.I1145.answer.MIS * adminRate) + (iA.I1147.answer.MIS * adminRate)) * 12;
                savings.misc.totals.hoursMISSavings = savings.misc.totals.hours - savings.misc.totals.hoursMIS;
                savings.misc.totals.costMISSavings = savings.misc.totals.cost - savings.misc.totals.costMIS;
                savings.misc.totals.hoursStorefront = iA.I1145.answer.Storefront * 12;
                savings.misc.totals.costStorefront = ((iA.I1145.answer.Storefront * adminRate) + (iA.I1147.answer.Storefront * adminRate)) * 12;
                savings.misc.totals.hoursStorefrontSavings = savings.misc.totals.hours - savings.misc.totals.hoursStorefront;
                savings.misc.totals.costStorefrontSavings = savings.misc.totals.cost - savings.misc.totals.costStorefront;

                let totalFields = ["estimates", "jobTickets", "inventory", "jobTracking", "shipments", "purchasing", "billing", "labor", "reports", "misc"];

                savings.hoursMISSavingsAnnual = 0;
                savings.costMISSavingsAnnual = 0;
                savings.hoursStorefrontSavingsAnnual = 0;
                savings.costStorefrontSavingsAnnual = 0;
                savings.hoursVDPSavingsAnnual = 0;
                savings.costVDPSavingsAnnual = 0;

                totalFields.forEach((field) => {
                    savings.hoursMISSavingsAnnual += savings[field].totals.hoursMISSavings;
                    savings.costMISSavingsAnnual += savings[field].totals.costMISSavings;
                    savings.hoursStorefrontSavingsAnnual += savings[field].totals.hoursStorefrontSavings;
                    savings.costStorefrontSavingsAnnual += savings[field].totals.costStorefrontSavings;
                    savings.hoursVDPSavingsAnnual += savings[field].totals.hoursVDPSavings;
                    savings.costVDPSavingsAnnual += savings[field].totals.costVDPSavings;
                })


                savings.costMISSavingsAnnual += savings.errors.reducedSoftware.costMISSavings;
                savings.costStorefrontSavingsAnnual += savings.errors.reducedSoftware.costStorefrontSavings;
                savings.costMISSavingsAnnual += savings.errors.reducedContent.costMISSavings;
                savings.costStorefrontSavingsAnnual += savings.errors.reducedContent.costStorefrontSavings;

                savings.costMISSavingsAnnual += savings.changes.totals.costMISSavings;
                savings.costStorefrontSavingsAnnual += savings.changes.totals.costStorefrontSavings;


                savings.costMISSavingsAnnual += savings.externalVsInternal.totals.costMISSavings;
                savings.costStorefrontSavingsAnnual += savings.externalVsInternal.totals.costStorefrontSavings;
                savings.costVDPSavingsAnnual += savings.externalVsInternal.totals.costVDPSavings;


                savings.revenue.shipping.cost = iA.I1116.answer.Current * iA.I1117.answer.Current * 12;
                savings.revenue.shipping.costMIS = iA.I1116.answer.MIS * iA.I1117.answer.MIS * 12;
                savings.revenue.shipping.additional = savings.revenue.shipping.cost - savings.revenue.shipping.costMIS;

                savings.revenue.changes.cost = iA.I1108.answer.Current * iA.I1109.answer.Current * 12;
                savings.revenue.changes.costMIS = iA.I1108.answer.MIS * iA.I1109.answer.MIS * 12;
                savings.revenue.changes.additional = savings.revenue.changes.cost - savings.revenue.changes.costMIS;

                savings.revenue.rework.cost = iA.I1110.answer.Current * 12;
                savings.revenue.rework.costMIS = iA.I1110.answer.MIS * 12;
                savings.revenue.rework.additional = savings.revenue.rework.cost - savings.revenue.rework.costMIS;


                savings.revenue.forecasting.additional = iA.I1156.answer.final ? iA.I1156.answer.final : iA.I1157.answer.final;

                savings.revenue.total = savings.revenue.shipping.additional + savings.revenue.changes.additional + savings.revenue.rework.additional + savings.revenue.forecasting.additional;

                savings.errors.totals.cost = savings.errors.reducedSoftware.cost + savings.errors.reducedContent.cost;
                savings.errors.totals.costMIS = savings.errors.reducedSoftware.costMIS + savings.errors.reducedContent.costMIS;
                savings.errors.totals.costMISSavings = savings.errors.reducedSoftware.costMISSavings + savings.errors.reducedContent.costMISSavings;
                savings.errors.totals.costStorefront = savings.errors.reducedSoftware.costStorefront + savings.errors.reducedContent.costStorefront;
                savings.errors.totals.costStorefrontSavings = savings.errors.reducedSoftware.costStorefrontSavings + savings.errors.reducedContent.costStorefrontSavings;




                let summaryFields = ["estimates", "jobTickets", "vdp","inventory", "jobTracking", "shipments", "purchasing", "billing", "changes", "errors", "labor", "reports", "misc"];
                let summaryCols = [ "hours",
                    "cost",
                    "hoursMIS",
                    "hoursMISSavings",
                    "costMIS",
                    "costMISSavings",
                    "hoursStorefront",
                    "hoursStorefrontSavings",
                    "costStorefront",
                    "costStorefrontSavings",
                    "hoursVDP",
                    "hoursVDPSavings",
                    "costVDP",
                    "costVDPSavings"
                 ]


                //
                summaryFields.forEach((sumField, index) => {
                    if(savings[sumField].totals) {


                        summaryCols.forEach(col => {

                            if(!index){
                                //init to zero at start index
                                savings.summary.internalSubtotal[col] = 0;
                                savings.summary.totals[col] = 0;
                            }

                            //console.log(savings, sumField,col)

                            if(!savings.summary[sumField]){
                                savings.summary[sumField] = {}
                            }

                            savings.summary[sumField][col] = savings[sumField].totals[col];

                            if(savings.summary[sumField][col]){
                                savings.summary.internalSubtotal[col] += savings.summary[sumField][col];
                                savings.summary.totals[col] += savings.summary[sumField][col];
                            }

                        })
                    }
                })

                summaryCols.forEach(col => {
                    if(savings.externalVsInternal.totals[col]){
                        savings.summary.externalVsInternal[col] = savings.externalVsInternal.totals[col];
                        savings.summary.totals[col] += savings.externalVsInternal.totals[col];
                    }
                })

                // let annualSolutionSavings = {
                //     "vdp": costs.vdp.orderDetails.rework.costSavingsAnnual + costs.vdp.orderDetails.jobTicketOrder.costSavingsAnnual,
                //     "mis": savings.costMISSavingsAnnual,
                //     "storefront": savings.costStorefrontSavingsAnnual
                // }

                let annualSolutionSavings = {
                    "vdp": costs.vdp.orderDetails.jobTicketOrder.costSavingsAnnual,
                    "mis": savings.costMISSavingsAnnual,
                    "storefront": savings.costStorefrontSavingsAnnual
                }

                //SOLUTION ROI SUMMARYS
                let solutions = ["vdp","mis","storefront"];
                solutions.forEach(solution => {
                    costs[solution].capitalTotals.initial = costs[solution].software.initial + costs[solution].hardware.initial + costs[solution].installation.initial;
                    costs[solution].capitalTotals.year1 = costs[solution].software.year1 + costs[solution].hardware.year1 + costs[solution].installation.year1;
                    costs[solution].capitalTotals.year2 = costs[solution].software.year2 + costs[solution].hardware.year2 + costs[solution].installation.year2;
                    costs[solution].capitalTotals.year3 = costs[solution].software.year3 + costs[solution].hardware.year3 + costs[solution].installation.year3;
                    costs[solution].capitalTotals.year4 = costs[solution].software.year4 + costs[solution].hardware.year4 + costs[solution].installation.year4;
                    costs[solution].capitalTotals.year5 = costs[solution].software.year5 + costs[solution].hardware.year5 + costs[solution].installation.year5;
                    costs[solution].capitalTotals.total = costs[solution].capitalTotals.initial + costs[solution].capitalTotals.year1 + costs[solution].capitalTotals.year2 + costs[solution].capitalTotals.year3 + costs[solution].capitalTotals.year4 + costs[solution].capitalTotals.year5;

                    costs[solution].lease.total = costs[solution].lease.initial + costs[solution].lease.year1 + costs[solution].lease.year2 + costs[solution].lease.year3 + costs[solution].lease.year4 + costs[solution].lease.year5;
                    costs[solution].training.total = costs[solution].training.initial + costs[solution].training.year1 + costs[solution].training.year2 + costs[solution].training.year3 + costs[solution].training.year4 + costs[solution].training.year5;
                    costs[solution].maintenance.total = costs[solution].maintenance.initial + costs[solution].maintenance.year1 + costs[solution].maintenance.year2 + costs[solution].maintenance.year3 + costs[solution].maintenance.year4 + costs[solution].maintenance.year5;

                    costs[solution].expenseTotals.initial = costs[solution].lease.initial + costs[solution].maintenance.initial + costs[solution].training.initial;
                    costs[solution].expenseTotals.year1 = costs[solution].lease.year1 + costs[solution].maintenance.year1 + costs[solution].training.year1;
                    costs[solution].expenseTotals.year2 = costs[solution].lease.year2 + costs[solution].maintenance.year2 + costs[solution].training.year2;
                    costs[solution].expenseTotals.year3 = costs[solution].lease.year3 + costs[solution].maintenance.year3 + costs[solution].training.year3;
                    costs[solution].expenseTotals.year4 = costs[solution].lease.year4 + costs[solution].maintenance.year4 + costs[solution].training.year4;
                    costs[solution].expenseTotals.year5 = costs[solution].lease.year5 + costs[solution].maintenance.year5 + costs[solution].training.year5;

                    costs[solution].expenseTotals.total = costs[solution].expenseTotals.initial + costs[solution].expenseTotals.year1 + costs[solution].expenseTotals.year2 + costs[solution].expenseTotals.year3 + costs[solution].expenseTotals.year4 + costs[solution].expenseTotals.year5;

                    costs[solution].totals.initial = costs[solution].expenseTotals.initial + costs[solution].capitalTotals.initial;
                    costs[solution].totals.year1 = costs[solution].expenseTotals.year1 + costs[solution].capitalTotals.year1;
                    costs[solution].totals.year2 = costs[solution].expenseTotals.year2 + costs[solution].capitalTotals.year2;
                    costs[solution].totals.year3 = costs[solution].expenseTotals.year3 + costs[solution].capitalTotals.year3;
                    costs[solution].totals.year4 = costs[solution].expenseTotals.year4 + costs[solution].capitalTotals.year4;
                    costs[solution].totals.year5 = costs[solution].expenseTotals.year5 + costs[solution].capitalTotals.year5;
                    costs[solution].totals.total = costs[solution].totals.initial + costs[solution].totals.year1 + costs[solution].totals.year2 + costs[solution].totals.year3 + costs[solution].totals.year4 + costs[solution].totals.year5;

                    costs[solution].savings.initial = 0;
                    costs[solution].savings.year1 = annualSolutionSavings[solution];
                    costs[solution].savings.year2 = annualSolutionSavings[solution];
                    costs[solution].savings.year3 = annualSolutionSavings[solution];
                    costs[solution].savings.year4 = annualSolutionSavings[solution];
                    costs[solution].savings.year5 = annualSolutionSavings[solution];
                    costs[solution].savings.total = costs[solution].savings.initial + costs[solution].savings.year1 + costs[solution].savings.year2 + costs[solution].savings.year3 + costs[solution].savings.year4 + costs[solution].savings.year5;

                    if(!costs[solution].salesGrowth) {
                        costs[solution].salesGrowth = {
                            "year2": 0,
                            "year3": 0,
                            "year1": 0,
                            "year4": 0,
                            "initial": 0,
                            "year5": 0,
                            "total": 0
                        }
                    }


                    costs[solution].salesGrowth.initial = 0;
                    costs[solution].salesGrowth.year1 = (solution == "mis")?savings.revenue.total:0;
                    costs[solution].salesGrowth.year2 = (solution == "mis")?savings.revenue.total:0;
                    costs[solution].salesGrowth.year3 = (solution == "mis")?savings.revenue.total:0;
                    costs[solution].salesGrowth.year4 = (solution == "mis")?savings.revenue.total:0;
                    costs[solution].salesGrowth.year5 = (solution == "mis")?savings.revenue.total:0;
                    costs[solution].salesGrowth.total = costs[solution].salesGrowth.initial + costs[solution].salesGrowth.year1 + costs[solution].salesGrowth.year2 + costs[solution].salesGrowth.year3 + costs[solution].salesGrowth.year4 + costs[solution].salesGrowth.year5;

                    costs[solution].cashFlow.initial = costs[solution].salesGrowth.initial + costs[solution].savings.initial - costs[solution].totals.initial;
                    costs[solution].cashFlow.year1 = costs[solution].salesGrowth.year1 + costs[solution].savings.year1 - costs[solution].totals.year1;
                    costs[solution].cashFlow.year2 = costs[solution].salesGrowth.year2 + costs[solution].savings.year2 - costs[solution].totals.year2;
                    costs[solution].cashFlow.year3 = costs[solution].salesGrowth.year3 + costs[solution].savings.year3 - costs[solution].totals.year3;
                    costs[solution].cashFlow.year4 = costs[solution].salesGrowth.year4 + costs[solution].savings.year4 - costs[solution].totals.year4;
                    costs[solution].cashFlow.year5 = costs[solution].salesGrowth.year5 + costs[solution].savings.year5 - costs[solution].totals.year5;
                    costs[solution].cashFlow.total = costs[solution].cashFlow.initial + costs[solution].cashFlow.year1 + costs[solution].cashFlow.year2 + costs[solution].cashFlow.year3 + costs[solution].cashFlow.year4 + costs[solution].cashFlow.year5;

                    costs[solution].discountRate = iA.I1020.answer.final;
                    costs[solution].firstYearCapitalExpense = costs[solution].capitalTotals.initial;
                    costs[solution].firstYearOperatingExpense = costs[solution].expenseTotals.initial + costs[solution].expenseTotals.year1;
                    costs[solution].directNPV = finance.NPV(costs[solution].discountRate, 0, costs[solution].cashFlow.initial, costs[solution].cashFlow.year1, costs[solution].cashFlow.year2, costs[solution].cashFlow.year3, costs[solution].cashFlow.year4, costs[solution].cashFlow.year5)

                    try {
                        costs[solution].directIRR = finance.IRR(costs[solution].cashFlow.initial, costs[solution].cashFlow.year1, costs[solution].cashFlow.year2, costs[solution].cashFlow.year3, costs[solution].cashFlow.year4, costs[solution].cashFlow.year5)
                    } catch {
                        costs[solution].directIRR = "NA"
                    }
                })

                costs.vdp.paybackPeriod = (costs.vdp.totals.initial / costs.vdp.orderDetails.totalSavingsAnnual) * 12;
                costs.vdp.firstMonthToRealization = costs.vdp.implementationTime + costs.vdp.adoptionTime + 1;

                costs.storefront.paybackPeriod = (costs.storefront.totals.initial / annualSolutionSavings.storefront) * 12;
                costs.storefront.firstMonthToRealization = costs.storefront.implementationTime + costs.storefront.adoptionTime + 1;

                costs.mis.paybackPeriod = (costs.mis.totals.initial / annualSolutionSavings.mis) * 12;
                costs.mis.paybackPeriodRevenue = (costs.mis.totals.initial / savings.revenue.total) * 12;
                costs.mis.paybackPeriodRevenuePlusCost = (costs.mis.totals.initial / (annualSolutionSavings.mis + savings.revenue.total)) * 12;
                costs.mis.firstMonthToRealization = costs.mis.implementationTime + costs.mis.adoptionTime + 1;

            }

            if(assessment.assessmentType == "CCM"){

                console.log("calc ccm")

                let iA = assessment.interview.questions;

                if(iA.X124.notes === "has been implemented"){
                    iA.X124A.hidden = false;
                }else{
                    iA.X124A.hidden = true;
                }

                if(iA.X132.notes === "ECM System"){
                    iA.X133.hidden = false;
                }else{
                    iA.X133.hidden = true;
                }

                if(iA.X147.notes === "Yes"){
                    iA.X148.hidden = false;
                    iA.X149.hidden = true;
                }else{
                    iA.X148.hidden = true;
                    iA.X149.hidden = false;
                }

                if(iA.X150.notes === "Yes"){
                    iA.X151.hidden = false;
                }else{
                    iA.X151.hidden = true;
                }

                if(iA.X154.notes === "Yes"){
                    iA.X155.hidden = false;
                    iA.X156.hidden = true;
                }else{
                    iA.X155.hidden = true;
                    iA.X156.hidden = false;
                }


                let survey = assessment.survey.questions;
                let surveyScores = Object.keys(survey).map((qId) => {
                    return {
                        id: qId,
                        pre: survey[qId].answer.pre,
                        final: survey[qId].answer.final,
                    }
                })

                let preTotalPoints = 0, preTotalAnswered = 0, preWeights = 0;
                let finalTotalPoints = 0, finalTotalAnswered = 0, finalWeights = 0;


                surveyScores.forEach((score) => {

                    let weight = ccm.survey[score.id].weight;

                    if(score.pre && (score.pre !== "NA")){

                        preWeights += weight?weight:1;
                        preTotalPoints += (parseInt(score.pre) * weight);
                        preTotalAnswered++;
                    }

                    if(score.final && (score.final !== "NA")){
                        finalWeights += weight?weight:1;
                        finalTotalPoints += (parseInt(score.final) * weight);
                        finalTotalAnswered++;
                    }
                })

                if(!assessment['scorecard']){
                    assessment.scorecard = { pre: 0, final: 0};
                }





                assessment.scorecard.pre = preTotalAnswered?(Math.round((preTotalPoints/preWeights * 1.0) * 10)/10):0;
                assessment.scorecard.final = finalTotalAnswered?(Math.round((finalTotalPoints/finalWeights * 1.0) * 10)/10):0;
            }

            if(assessment.assessmentType === "Mail"){
                console.log("calc mail")

                let iA = assessment.interview.questions;

                iA.X3055B.hidden = true;
                iA.X3055B1.hidden = true;
                iA.X3055B2.hidden = true;
                iA.X3055B3.hidden = true;
                iA.X3055B4.hidden = true;
                iA.X3055B5.hidden = true;
                iA.X3055B6.hidden = true;
                iA.X3055B7.hidden = true;
                iA.X3055B8.hidden = true;
                iA.X3055B9.hidden = true;
                iA.X3055B10.hidden = true;

                if(iA.X3002A.notes === "Yes"){
                    iA.X3002B.hidden = false;
                    iA.X3002C.hidden = false;
                }else{
                    iA.X3002B.hidden = true;
                    iA.X3002C.hidden = true;
                }

                if(iA.X3012A.notes === "Yes"){
                    iA.X3012B.hidden = false;
                    iA.X3012C.hidden = false;
                    iA.X3012D.hidden = false;
                    iA.X3012E.hidden = false;
                }else{
                    iA.X3012B.hidden = true;
                    iA.X3012C.hidden = true;
                    iA.X3012D.hidden = true;
                    iA.X3012E.hidden = true;
                }

                if(iA.X3041.notes === "Yes"){
                    iA.X3041A.hidden = false;
                }else{
                    iA.X3041A.hidden = true;
                }

                if(iA.X3044.notes === "Yes"){
                    iA.X3044B.hidden = false;
                }else{
                    iA.X3044B.hidden = true;
                }

                if(iA.X3045.notes === "Yes"){
                    iA.X3045A.hidden = false;
                    iA.X3045B.hidden = false;
                }else{
                    iA.X3045A.hidden = true;
                    iA.X3045B.hidden = true;
                }

                if(iA.X3073.notes === "Yes"){
                    iA.X3074.hidden = false;
                    iA.X3075.hidden = false;
                }else{
                    iA.X3074.hidden = true;
                    iA.X3075.hidden = true;
                }

                if(iA.X3062.notes === "Yes"){
                    iA.X3062A.hidden = false;
                }else{
                    iA.X3062A.hidden = true;
                }

                iA.X3065.answer.final = iA.X3065A.answer.final +
                    iA.X3065C.answer.final +
                    iA.X3065E.answer.final +
                    iA.X3065G.answer.final

                if(iA.X3076.notes === "Yes"){
                    iA.X3077.hidden = false;
                }else{
                    iA.X3077.hidden = true;
                }

                if(iA.X3080.notes === "Yes"){
                    iA.X3081.hidden = false;
                }else{
                    iA.X3081.hidden = true;
                }


                iA.I1010.answer.final = iA.I1010A.answer.final +
                    iA.I1014.answer.final +
                    iA.I1010B.answer.final +
                    iA.X3001D.answer.final +
                    iA.X3001E.answer.final +
                    iA.X3001F.answer.final +
                    iA.X3001G.answer.final +
                    iA.I1010H.answer.final


                iA.X3001K.answer.final = iA.I1010A.answer.final +
                    iA.I1014.answer.final +
                    iA.I1010B.answer.final

                iA.X3001L.answer.final = iA.X3001D.answer.final +
                    iA.X3001E.answer.final

                let costs = calculateMailROI(iA)



                let survey = assessment.survey.questions;
                let surveyScores = Object.keys(survey).map((qId) => {
                    return {
                        id: qId,
                        pre: survey[qId].answer.pre,
                        final: survey[qId].answer.final,
                    }
                })

                let preTotalPoints = 0, preTotalAnswered = 0, preWeights = 0;
                let finalTotalPoints = 0, finalTotalAnswered = 0, finalWeights = 0;


                surveyScores.forEach((score) => {

                    let weight = mailing.survey[score.id].weight;

                    if(score.pre && (score.pre !== "NA")){

                        preWeights += weight?weight:1;
                        preTotalPoints += (parseInt(score.pre) * weight);
                        preTotalAnswered++;
                    }

                    if(score.final && (score.final !== "NA")){
                        finalWeights += weight?weight:1;
                        finalTotalPoints += (parseInt(score.final) * weight);
                        finalTotalAnswered++;
                    }
                })

                if(!assessment['scorecard']){
                    assessment.scorecard = { pre: 0, final: 0};
                }

                if(!assessment.costs.solution) {
                    assessment.costs.solution = {
                        "savings": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "cashFlow": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "salesGrowth": {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },

                        "discountRate": 0,
                        "directNPV": 0,
                        "directIRR": 0,
                        "paybackPeriod": 0,

                        "training" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "installation" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "firstMonthToRealization" : 0,
                        "expenseTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "adoptionTime" : 0,
                        "software" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "lease" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "capitalTotals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        },
                        "maintenance" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "hardware" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total": 0
                        },
                        "implementationTime" : 0,
                        "totals" : {
                            "year2" : 0,
                            "year3" : 0,
                            "year1" : 0,
                            "year4" : 0,
                            "initial" : 0,
                            "year5" : 0,
                            "total" : 0
                        }
                    }
                }




                assessment.costs = { ...assessment.costs, ...costs};

                let solution = assessment.costs.solution;

                solution.capitalTotals.initial = solution.software.initial + solution.hardware.initial + solution.installation.initial;
                solution.capitalTotals.year1 = solution.software.year1 + solution.hardware.year1 + solution.installation.year1;
                solution.capitalTotals.year2 = solution.software.year2 + solution.hardware.year2 + solution.installation.year2;
                solution.capitalTotals.year3 = solution.software.year3 + solution.hardware.year3 + solution.installation.year3;
                solution.capitalTotals.year4 = solution.software.year4 + solution.hardware.year4 + solution.installation.year4;
                solution.capitalTotals.year5 = solution.software.year5 + solution.hardware.year5 + solution.installation.year5;
                solution.capitalTotals.total = solution.capitalTotals.initial + solution.capitalTotals.year1 +  solution.capitalTotals.year2 +  solution.capitalTotals.year3 +  solution.capitalTotals.year4 +  solution.capitalTotals.year5;

                solution.lease.total = solution.lease.initial + solution.lease.year1 +  solution.lease.year2 +  solution.lease.year3 +  solution.lease.year4 +  solution.lease.year5
                solution.training.total = solution.training.initial + solution.training.year1 +  solution.training.year2 +  solution.training.year3 +  solution.training.year4 +  solution.training.year5
                solution.maintenance.total = solution.maintenance.initial + solution.maintenance.year1 +  solution.maintenance.year2 +  solution.maintenance.year3 +  solution.maintenance.year4 +  solution.maintenance.year5
                solution.totals.total = solution.capitalTotals.total + solution.lease.total + solution.training.total + solution.maintenance.total;


                solution.expenseTotals.initial = solution.lease.initial + solution.maintenance.initial + solution.training.initial;
                solution.expenseTotals.year1 = solution.lease.year1 + solution.maintenance.year1 + solution.training.year1;
                solution.expenseTotals.year2 = solution.lease.year2 + solution.maintenance.year2 + solution.training.year2;
                solution.expenseTotals.year3 = solution.lease.year3 + solution.maintenance.year3 + solution.training.year3;
                solution.expenseTotals.year4 = solution.lease.year4 + solution.maintenance.year4 + solution.training.year4;
                solution.expenseTotals.year5 = solution.lease.year5 + solution.maintenance.year5 + solution.training.year5;

                solution.totals.initial = solution.expenseTotals.initial + solution.capitalTotals.initial;
                solution.totals.year1 = solution.expenseTotals.year1 + solution.capitalTotals.year1;
                solution.totals.year2 = solution.expenseTotals.year2 + solution.capitalTotals.year2;
                solution.totals.year3 = solution.expenseTotals.year3 + solution.capitalTotals.year3;
                solution.totals.year4 = solution.expenseTotals.year4 + solution.capitalTotals.year4;
                solution.totals.year5 = solution.expenseTotals.year5 + solution.capitalTotals.year5;





                solution.savings.initial = 0;
                solution.savings.year1 = costs.totalSavingsAnnual;
                solution.savings.year2 = costs.totalSavingsAnnual;
                solution.savings.year3 = costs.totalSavingsAnnual;
                solution.savings.year4 = costs.totalSavingsAnnual;
                solution.savings.year5 = costs.totalSavingsAnnual;
                solution.savings.total = solution.savings.initial + solution.savings.year1 + solution.savings.year2 + solution.savings.year3 + solution.savings.year4 + solution.savings.year5;

                solution.cashFlow.initial = solution.savings.initial - solution.totals.initial;
                solution.cashFlow.year1 = solution.savings.year1 - solution.totals.year1;
                solution.cashFlow.year2 = solution.savings.year2 - solution.totals.year2;
                solution.cashFlow.year3 = solution.savings.year3 - solution.totals.year3;
                solution.cashFlow.year4 = solution.savings.year4 - solution.totals.year4;
                solution.cashFlow.year5 = solution.savings.year5 - solution.totals.year5;
                solution.cashFlow.total = solution.cashFlow.initial + solution.cashFlow.year1 + solution.cashFlow.year2 + solution.cashFlow.year3 + solution.cashFlow.year4 + solution.cashFlow.year5;

                solution.paybackPeriod = (solution.totals.initial / costs.totalSavingsAnnual) * 12;

                solution.firstMonthToRealization = solution.implementationTime + solution.adoptionTime + 1;
                solution.firstYearCapitalExpense = solution.capitalTotals.initial;
                solution.firstYearOperatingExpense = solution.expenseTotals.initial + solution.expenseTotals.year1;
                solution.directNPV = finance.NPV(solution.discountRate, 0, solution.cashFlow.initial, solution.cashFlow.year1, solution.cashFlow.year2, solution.cashFlow.year3, solution.cashFlow.year4, solution.cashFlow.year5)

                try {
                    solution.directIRR = finance.IRR(solution.cashFlow.initial, solution.cashFlow.year1, solution.cashFlow.year2, solution.cashFlow.year3, solution.cashFlow.year4, solution.cashFlow.year5)
                } catch {
                    solution.directIRR = "NA"
                }

                //let solution = assessment.costs.solution;



                assessment.scorecard.pre = preTotalAnswered?(Math.round((preTotalPoints/preWeights * 1.0) * 10)/10):0;
                assessment.scorecard.final = finalTotalAnswered?(Math.round((finalTotalPoints/finalWeights * 1.0) * 10)/10):0;
            }

        }

        function calculateMAP(assessment){
            let survey = assessment.survey.questions;
            let surveyScores = Object.keys(survey).map((qId) => {
                return {
                    id: qId,
                    pre: survey[qId].answer.pre,
                    final: survey[qId].answer.final,
                }
            })

            let preTotalPoints = 0, preTotalAnswered = 0, preWeights = 0;
            let finalTotalPoints = 0, finalTotalAnswered = 0, finalWeights = 0;


            surveyScores.forEach((score) => {

                let weight = marketingMap.survey[score.id].weight;

                if(score.pre && (score.pre !== "NA")){

                    preWeights += weight?weight:1;
                    preTotalPoints += (parseInt(score.pre) * weight);
                    preTotalAnswered++;
                }

                if(score.final && (score.final !== "NA")){
                    finalWeights += weight?weight:1;
                    finalTotalPoints += (parseInt(score.final) * weight);
                    finalTotalAnswered++;
                }
            })

            if(!assessment['scorecard']){
                assessment.scorecard = { pre: 0, final: 0};
            }

            assessment.scorecard.pre = preTotalAnswered?(Math.round((preTotalPoints/preWeights * 1.0) * 10)/10):0;
            assessment.scorecard.final = finalTotalAnswered?(Math.round((finalTotalPoints/finalWeights * 1.0) * 10)/10):0;


            let iA = assessment.interview.questions;

            iA.X119.answer.final = iA.X112.answer.final +
                iA.X113.answer.final +
                iA.X114.answer.final +
                iA.X115.answer.final +
                iA.X116.answer.final +
                iA.X117.answer.final +
                iA.X118.answer.final ;

            iA.X3225.answer.final = iA.X3203.answer.final +
                iA.X3204.answer.final +
                iA.X3205.answer.final +
                iA.X3215.answer.final +
                iA.X3216.answer.final +
                iA.X3206.answer.final +
                iA.X3207.answer.final +
                iA.X3208.answer.final +
                iA.X3220.answer.final +
                iA.X3221.answer.final +
                iA.X3211.answer.final ;


            iA.X3314.hidden = (iA.X3313.notes === "Yes")?false:true;
            iA.X3315.hidden = (iA.X3313.notes === "No")?false:true;
            iA.X3702.hidden = (iA.X3701.notes === "No")?false:true;
            iA.X3713.hidden = (iA.X3712.notes === "Yes")?false:true;
            iA.X3716.hidden = (iA.X3715.notes === "No")?false:true;
            iA.X3719.hidden = (iA.X3718.notes === "Yes")?false:true;
            iA.X3802.hidden = (iA.X3801.notes === "No")?false:true;
            iA.X3806.hidden = ((iA.X3803.notes === "No") || (iA.X3804.notes === "No") || (iA.X3805.notes === "No"))?false:true;
            iA.X3809.hidden = (iA.X3808.notes === "No")?false:true;



        }

        function calculatePMP(assessment){
            let survey = assessment.survey.questions;
            let surveyScores = Object.keys(survey).map((qId) => {
                return {
                    id: qId,
                    pre: survey[qId].answer.pre,
                    final: survey[qId].answer.final,
                }
            })

            let preTotalPoints = 0, preTotalAnswered = 0, preWeights = 0;
            let finalTotalPoints = 0, finalTotalAnswered = 0, finalWeights = 0;


            surveyScores.forEach((score) => {

                let weight = marketingPmp.survey[score.id].weight;

                if(score.pre && (score.pre !== "NA")){

                    preWeights += weight?weight:1;
                    preTotalPoints += (parseInt(score.pre) * weight);
                    preTotalAnswered++;
                }

                if(score.final && (score.final !== "NA")){
                    finalWeights += weight?weight:1;
                    finalTotalPoints += (parseInt(score.final) * weight);
                    finalTotalAnswered++;
                }
            })

            if(!assessment['scorecard']){
                assessment.scorecard = { pre: 0, final: 0};
            }

            assessment.scorecard.pre = preTotalAnswered?(Math.round((preTotalPoints/preWeights * 1.0) * 10)/10):0;
            assessment.scorecard.final = finalTotalAnswered?(Math.round((finalTotalPoints/finalWeights * 1.0) * 10)/10):0;


            let iA = assessment.interview.questions;

            iA.X3225.answer.final =
                iA.X3204.answer.final +
                iA.X3205.answer.final +
                iA.X3206.answer.final +
                iA.X3207.answer.final +
                iA.X3208.answer.final +
                iA.X3209.answer.final +
                iA.X3210.answer.final +
                iA.X3211.answer.final +
                iA.X3216.answer.final +
                iA.X3218.answer.final +
                iA.X3219.answer.final +
                iA.X3220.answer.final +
                iA.X3221.answer.final +
                iA.X3222.answer.final ;


            iA.X3405.hidden = false;
            iA.X3409.hidden = false;
            iA.X3506.hidden = false;
            iA.X3605.hidden = false;
            iA.X3106.hidden = (iA.X3106A.notes === "Yes")?false:true;
            iA.X3107.hidden = (iA.X3106A.notes === "Yes")?false:true;
            iA.X3108.hidden = (iA.X3106A.notes === "Yes")?false:true;
            iA.X3406.hidden = (iA.X3405.notes === "Yes")?false:true;
            iA.X3407.hidden = (iA.X3405.notes === "No")?false:true;
            iA.X3408.hidden = (iA.X3405.notes === "No")?false:true;
            iA.X3410.hidden = (iA.X3409.notes === "Yes")?false:true;
            iA.X3507.hidden = (iA.X3506.notes === "No")?false:true;
            iA.X3606.hidden = (iA.X3605.notes === "No")?false:true;




        }

        function calculateMailROI(iA){




            let costs =  {
                totalSavingsAnnual: 0,
                returnMail: {
                    monthlyVolume: 0,
                    averagePostageCost: 0,
                    averagePrintCost: 0,
                    averageResentBack: 0,
                    monthlyProcessingCost: 0,
                    costToUpdateCRM: 0,
                    percentReprinted: 0,
                    percentResent: 0,
                    percentReprintedCost: 0,
                    percentResentCost: 0,
                    percentCleanseReduction: 0,
                    monthlyCostReturned: 0,
                    monthlyCostBase: 0,
                    monthlyCostResent: 0,
                    monthlyCostTotal: 0,
                    monthlyCostSavings: 0,
                    yearlyCostTotal: 0,
                    yearlyCostSavings: 0
                },
                postage: {
                    annualPromoVolume: iA.X3003A.answer.final,
                    rates: {
                        fiveDigit: iA.X3055B1.answer.final,
                        AADC: iA.X3055B2.answer.final,
                        MixedAADC: iA.X3055B3.answer.final,
                        threeDigit: iA.X3055B7.answer.final,
                        mixedADC: iA.X3055B8.answer.final,
                        meter: iA.X3055B9.answer.final,
                        stamp: iA.X3055B10.answer.final,
                    },
                    averagePostageRate: 0,
                    discountRate: 0,
                    yearlyCostSavings: 0
                },
                compliance: {
                    pctFinesEliminated: iA.X3043B.answer.final,
                    totalFines: iA.X3042B.answer.final,
                    yearlyCostSavings: (iA.X3043B.answer.final / 100) * iA.X3042B.answer.final
                },
                workflow: {
                    hourlyRate: 0,
                    currentMonthlyHoursForTask: 0,
                    futureMonthlyHoursForTask: 0,
                    yearlyCostSavings: 0
                },
                makeVsBuy: {
                    costPerRecord: 0,
                    yearlyRecordsProcessed: 0,
                    yearlyExternalCleanseCost: 0,
                    yearlyExternalCosts: 0,
                    yearlyHours: 0,
                    yearlyInternalCost: 0,
                    yearlyCostSavings: 0
                },
                preSort: {

                    yearlyCurrentVolume: 0,
                    yearlyCurrentCost: 0,
                    yearlyFutureVolume: 0,
                    yearlyFutureCost: 0,
                    yearlyCostSavings: 0,


                    fiveDigit: {
                        currentPostage: 0,
                        currentVolume: 0,
                        currentCost: 0,
                        futureVolume: 0,
                        futureCost: 0
                    },
                    AADC: {
                        currentPostage: 0,
                        currentVolume: 0,
                        currentCost: 0,
                        futureVolume: 0,
                        futureCost: 0
                    },
                    mixedAADC: {
                        currentPostage: 0,
                        currentVolume: 0,
                        currentCost: 0,
                        futureVolume: 0,
                        futureCost: 0
                    },
                    AADCNonAuto: {
                        currentPostage: 0,
                        currentVolume: 0,
                        currentCost: 0,
                        futureVolume: 0,
                        futureCost: 0
                    },
                    mixedAADCNonAuto: {
                        currentPostage: 0,
                        currentVolume: 0,
                        currentCost: 0,
                        futureVolume: 0,
                        futureCost: 0
                    },
                    fiveDigitNonAuto: {
                        currentPostage: 0,
                        currentVolume: 0,
                        currentCost: 0,
                        futureVolume: 0,
                        futureCost: 0
                    },
                    threeDigitNonAuto: {
                        currentPostage: 0,
                        currentVolume: 0,
                        currentCost: 0,
                        futureVolume: 0,
                        futureCost: 0
                    },
                    mixedADC: {
                        currentPostage: 0,
                        currentVolume: 0,
                        currentCost: 0,
                        futureVolume: 0,
                        futureCost: 0
                    },
                    meter: {
                        currentPostage: 0,
                        currentVolume: 0,
                        currentCost: 0,
                        futureVolume: 0,
                        futureCost: 0
                    },
                    stamp: {
                        currentPostage: 0,
                        currentVolume: 0,
                        currentCost: 0,
                        futureVolume: 0,
                        futureCost: 0
                    },
                }

            }

            let returnMail = costs.returnMail;

            returnMail.monthlyVolume = iA.X3087.answer.final;
            returnMail.monthlyCostBase = returnMail.monthlyVolume * (iA.X3088.answer.final + iA.X3089.answer.final);
            returnMail.averagePostageCost = iA.X3088.answer.final;
            returnMail.averagePrintCost = iA.X3089.answer.final;
            returnMail.averageResentBack = iA.X3090C.answer.final;
            returnMail.monthlyProcessingCost = iA.X3001H.answer.final * ((1 + iA.X3001J.answer.final/100)*iA.X3090.answer.final)
            returnMail.monthlyCostReturned = returnMail.monthlyCostBase + returnMail.monthlyProcessingCost

            returnMail.costToUpdateCRM = iA.X3090E.answer.final * ((1 + iA.X3001J.answer.final/100)*iA.X3001H.answer.final)
            returnMail.percentReprinted = iA.X3090A.answer.final;
            returnMail.percentReprintedCost = (iA.X3090A.answer.final/100) * iA.X3089.answer.final * iA.X3087.answer.final
            returnMail.percentResent = iA.X3090B.answer.final;
            returnMail.percentResentCost = (iA.X3090B.answer.final/100) * iA.X3088.answer.final * iA.X3087.answer.final * iA.X3090C.answer.final
            returnMail.monthlyCostResent = returnMail.percentReprintedCost + returnMail.percentResentCost

            returnMail.monthlyCostTotal = returnMail.monthlyCostResent + returnMail.costToUpdateCRM + returnMail.monthlyCostReturned
            returnMail.percentCleanseReduction = iA.X3090D.answer.final;
            returnMail.monthlyCostSavings = (iA.X3090D.answer.final/100) * returnMail.monthlyCostTotal

            returnMail.yearlyCostTotal = returnMail.monthlyCostTotal * 12;
            returnMail.yearlyCostSavings = returnMail.monthlyCostSavings * 12;

            let postage = costs.postage;

            postage.rates = {
                    fiveDigit: iA.X3055B1.answer.final,
                    AADC: iA.X3055B2.answer.final,
                    MixedAADC: iA.X3055B3.answer.final,
                    threeDigit: iA.X3055B7.answer.final,
                    mixedADC: iA.X3055B8.answer.final,
                    meter: iA.X3055B9.answer.final,
                    stamp: iA.X3055B10.answer.final,
            }

            let validPostageRateKeys = Object.keys(postage.rates).filter((rateKey)=>{
                return (postage.rates[rateKey])
            })

            postage.averagePostageRate = validPostageRateKeys.reduce((total, rateKey)=>{
                return total += postage.rates[rateKey]
            },0) / validPostageRateKeys.length;

            postage.discountRate = iA.X3003B.answer.final;
            postage.yearlyCostSavings = postage.annualPromoVolume * postage.averagePostageRate * (postage.discountRate / 100);

            let workflow = costs.workflow;

            workflow.hourlyRate = iA.X3001H.answer.final * (1 + iA.X3001J.answer.final/100);
            workflow.currentMonthlyHoursForTask = (iA.X3109.answer.Current + iA.X3110.answer.Current + iA.X3111.answer.Current + iA.X3112.answer.Current);
            workflow.futureMonthlyHoursForTask = (iA.X3109.answer.Solution + iA.X3110.answer.Solution + iA.X3111.answer.Solution + iA.X3112.answer.Solution);
            workflow.yearlyCostSavings = (workflow.currentMonthlyHoursForTask - workflow.futureMonthlyHoursForTask) * workflow.hourlyRate * 12;

            let makeVsBuy = costs.makeVsBuy;

            makeVsBuy.costPerRecord = iA.X3012B.answer.final;
            makeVsBuy.yearlyRecordsProcessed = iA.X3012C.answer.final;
            makeVsBuy.yearlyExternalCleanseCost = iA.X3012B.answer.final * iA.X3012C.answer.final;
            makeVsBuy.yearlyExternalCosts = iA.X3012D.answer.final;
            makeVsBuy.yearlyHours = iA.X3012E.answer.final;
            makeVsBuy.yearlyInternalCost = iA.X3012E.answer.final * (iA.X3001I.answer.final * (1 + iA.X3001J.answer.final/100)) ;
            makeVsBuy.yearlyCostSavings = (makeVsBuy.yearlyExternalCosts + makeVsBuy.yearlyExternalCleanseCost) - makeVsBuy.yearlyInternalCost;

            let preSort = costs.preSort;
            preSort.fiveDigit.currentPostage =  iA.X3055B1.answer.final;
            preSort.fiveDigit.currentVolume = iA.I1003.answer.final * (iA.X3055A1.answer.Current/100);
            preSort.fiveDigit.currentCost = iA.I1003.answer.final * (iA.X3055A1.answer.Current/100) * iA.X3055B1.answer.final;
            preSort.fiveDigit.futureVolume = iA.I1003.answer.final * (iA.X3055A1.answer.Solution/100);
            preSort.fiveDigit.futureCost = iA.I1003.answer.final * (iA.X3055A1.answer.Solution/100) * iA.X3055B1.answer.final;

            preSort.AADC.currentPostage =  iA.X3055B2.answer.final;
            preSort.AADC.currentVolume = iA.I1003.answer.final * (iA.X3055A2.answer.Current/100);
            preSort.AADC.currentCost = iA.I1003.answer.final * (iA.X3055A2.answer.Current/100) * iA.X3055B2.answer.final;
            preSort.AADC.futureVolume = iA.I1003.answer.final * (iA.X3055A2.answer.Solution/100);
            preSort.AADC.futureCost = iA.I1003.answer.final * (iA.X3055A2.answer.Solution/100) * iA.X3055B2.answer.final;

            preSort.mixedAADC.currentPostage =  iA.X3055B3.answer.final;
            preSort.mixedAADC.currentVolume = iA.I1003.answer.final * (iA.X3055A3.answer.Current/100);
            preSort.mixedAADC.currentCost = iA.I1003.answer.final * (iA.X3055A3.answer.Current/100) * iA.X3055B3.answer.final;
            preSort.mixedAADC.futureVolume = iA.I1003.answer.final * (iA.X3055A3.answer.Solution/100);
            preSort.mixedAADC.futureCost = iA.I1003.answer.final * (iA.X3055A3.answer.Solution/100) * iA.X3055B3.answer.final;

            preSort.AADCNonAuto.currentPostage =  iA.X3055B4.answer.final;
            preSort.AADCNonAuto.currentVolume = iA.I1003.answer.final * (iA.X3055A4.answer.Current/100);
            preSort.AADCNonAuto.currentCost = iA.I1003.answer.final * (iA.X3055A4.answer.Current/100) * iA.X3055B4.answer.final;
            preSort.AADCNonAuto.futureVolume = iA.I1003.answer.final * (iA.X3055A4.answer.Solution/100);
            preSort.AADCNonAuto.futureCost = iA.I1003.answer.final * (iA.X3055A4.answer.Solution/100) * iA.X3055B4.answer.final;

            preSort.mixedAADCNonAuto.currentPostage =  iA.X3055B5.answer.final;
            preSort.mixedAADCNonAuto.currentVolume = iA.I1003.answer.final * (iA.X3055A5.answer.Current/100);
            preSort.mixedAADCNonAuto.currentCost = iA.I1003.answer.final * (iA.X3055A5.answer.Current/100) * iA.X3055B5.answer.final;
            preSort.mixedAADCNonAuto.futureVolume = iA.I1003.answer.final * (iA.X3055A5.answer.Solution/100);
            preSort.mixedAADCNonAuto.futureCost = iA.I1003.answer.final * (iA.X3055A5.answer.Solution/100) * iA.X3055B5.answer.final;

            preSort.fiveDigitNonAuto.currentPostage =  iA.X3055B6.answer.final;
            preSort.fiveDigitNonAuto.currentVolume = iA.I1003.answer.final * (iA.X3055A6.answer.Current/100);
            preSort.fiveDigitNonAuto.currentCost = iA.I1003.answer.final * (iA.X3055A6.answer.Current/100) * iA.X3055B6.answer.final;
            preSort.fiveDigitNonAuto.futureVolume = iA.I1003.answer.final * (iA.X3055A6.answer.Solution/100);
            preSort.fiveDigitNonAuto.futureCost = iA.I1003.answer.final * (iA.X3055A6.answer.Solution/100) * iA.X3055B6.answer.final;

            preSort.threeDigitNonAuto.currentPostage =  iA.X3055B7.answer.final;
            preSort.threeDigitNonAuto.currentVolume = iA.I1003.answer.final * (iA.X3055A7.answer.Current/100);
            preSort.threeDigitNonAuto.currentCost = iA.I1003.answer.final * (iA.X3055A7.answer.Current/100) * iA.X3055B7.answer.final;
            preSort.threeDigitNonAuto.futureVolume = iA.I1003.answer.final * (iA.X3055A7.answer.Solution/100);
            preSort.threeDigitNonAuto.futureCost = iA.I1003.answer.final * (iA.X3055A7.answer.Solution/100) * iA.X3055B7.answer.final;

            preSort.mixedADC.currentPostage =  iA.X3055B8.answer.final;
            preSort.mixedADC.currentVolume = iA.I1003.answer.final * (iA.X3055A8.answer.Current/100);
            preSort.mixedADC.currentCost = iA.I1003.answer.final * (iA.X3055A8.answer.Current/100) * iA.X3055B8.answer.final;
            preSort.mixedADC.futureVolume = iA.I1003.answer.final * (iA.X3055A8.answer.Solution/100);
            preSort.mixedADC.futureCost = iA.I1003.answer.final * (iA.X3055A8.answer.Solution/100) * iA.X3055B8.answer.final;

            preSort.meter.currentPostage =  iA.X3055B9.answer.final;
            preSort.meter.currentVolume = iA.I1003.answer.final * (iA.X3055A9.answer.Current/100);
            preSort.meter.currentCost = iA.I1003.answer.final * (iA.X3055A9.answer.Current/100) * iA.X3055B9.answer.final;
            preSort.meter.futureVolume = iA.I1003.answer.final * (iA.X3055A9.answer.Solution/100);
            preSort.meter.futureCost = iA.I1003.answer.final * (iA.X3055A9.answer.Solution/100) * iA.X3055B9.answer.final;

            preSort.stamp.currentPostage =  iA.X3055B10.answer.final;
            preSort.stamp.currentVolume = iA.I1003.answer.final * (iA.X3055A10.answer.Current/100);
            preSort.stamp.currentCost = iA.I1003.answer.final * (iA.X3055A10.answer.Current/100) * iA.X3055B10.answer.final;
            preSort.stamp.futureVolume = iA.I1003.answer.final * (iA.X3055A10.answer.Solution/100);
            preSort.stamp.futureCost = iA.I1003.answer.final * (iA.X3055A10.answer.Solution/100) * iA.X3055B10.answer.final;

            preSort.yearlyCurrentVolume = preSort.fiveDigit.currentVolume + preSort.AADC.currentVolume + preSort.mixedAADC.currentVolume + preSort.AADCNonAuto.currentVolume + preSort.mixedAADCNonAuto.currentVolume  + preSort.fiveDigitNonAuto.currentVolume + preSort.threeDigitNonAuto.currentVolume + preSort.mixedADC.currentVolume + preSort.meter.currentVolume + preSort.stamp.currentVolume;
            preSort.yearlyCurrentCost = preSort.fiveDigit.currentCost + preSort.AADC.currentCost + preSort.mixedAADC.currentCost + preSort.AADCNonAuto.currentCost + preSort.mixedAADCNonAuto.currentCost  + preSort.fiveDigitNonAuto.currentCost + preSort.threeDigitNonAuto.currentCost + preSort.mixedADC.currentCost + preSort.meter.currentCost + preSort.stamp.currentCost;
            preSort.yearlyFutureVolume = preSort.fiveDigit.futureVolume + preSort.AADC.futureVolume + preSort.mixedAADC.futureVolume + preSort.AADCNonAuto.futureVolume + preSort.mixedAADCNonAuto.futureVolume  + preSort.fiveDigitNonAuto.futureVolume + preSort.threeDigitNonAuto.futureVolume + preSort.mixedADC.futureVolume + preSort.meter.futureVolume + preSort.stamp.futureVolume;
            preSort.yearlyFutureCost = preSort.fiveDigit.futureCost + preSort.AADC.futureCost + preSort.mixedAADC.futureCost + preSort.AADCNonAuto.futureCost + preSort.mixedAADCNonAuto.futureCost  + preSort.fiveDigitNonAuto.futureCost + preSort.threeDigitNonAuto.futureCost + preSort.mixedADC.futureCost + preSort.meter.futureCost + preSort.stamp.futureCost;

            preSort.yearlyCostSavings = preSort.yearlyCurrentCost - preSort.yearlyFutureCost;


            costs.totalSavingsAnnual = returnMail.yearlyCostSavings + postage.yearlyCostSavings + costs.compliance.yearlyCostSavings + workflow.yearlyCostSavings + makeVsBuy.yearlyCostSavings + preSort.yearlyCostSavings;


            console.log(postage, iA)
            return costs;
        }

        Vue.prototype.$sdm = {
            calculate: calculate,
            getAssessmentSchema: getAssessmentSchema,
            createAssessment: createAssessment,
            saveNewAssessment: saveNewAssessment,
            loadAssessment: loadAssessment,
            loadUserProfile: loadUserProfile,
            update: update, //interface function
            updateAssessment: updateAssessment,
            deleteAssessment: deleteAssessment,
            getAssessments: getAssessments,
            getAssessmentReport: getAssessmentReport,
            generateWorkflowQuestionnaire: generateWorkflowQuestionnaire,
            generateCCMQuestionnaire: generateCCMQuestionnaire,
            generateMailingQuestionnaire: generateMailingQuestionnaire,
            generateMapQuestionnaire: generateMapQuestionnaire,
            generatePmpQuestionnaire: generatePmpQuestionnaire,
            saveNewQuestionnaire: saveNewQuestionnaire,
            loadQuestionnaire: loadQuestionnaire,
            updateQuestionnaire: updateQuestionnaire,
            getQuestionnairesForAssessment:getQuestionnairesForAssessment
        };
    }
}
