


<template>
<div class="color-calulator">
  <div class="text-xs px-8 py-2 pt-4 bg-white">
    <router-link to="/">Home</router-link> /
    <router-link to="/calculators">Calculators</router-link> /
    <span>Project Estimate</span>
  </div>
  <div class="sm:flex justify-between items-center px-8 pt-0 pb-5 bg-white">
    <h1 class="text-3xl leading-none flex items-baseline"><span v-if="profile" class="mr-2">{{ profile.organization?profile.organization:"Production Printing" }}</span><span class="text-xs opacity-75">{{ statusMsg }}</span></h1>
    <div class="flex pt-4 sm:pt-0 sm:-mt-2 text-sm">
      <button class="button mr-2" @click="showProjects"><img class="inline-block mr-2 h-4" src="../assets/icons/list-icon.svg" /> Saved <span class="hidden sm:inline">Estimates</span></button>
      <div class="dropdown">
        <button class="button relative" >Options</button>
        <div class="options absolute z-50 hidden pt-1">
          <div class="bg-white border rounded">
            <div class="px-4 py-3 border-b cursor-pointer hover:bg-gray-100" @click="saveProject"><img class="w-3 object-contain inline-block mr-1 h-3" src="../assets/icons/save-icon.svg" /> Save Changes</div>
            <div class="px-4 py-3 border-b cursor-pointer hover:bg-gray-100" @click="copyProject"><img class="w-3 object-contain inline-block mr-1 h-3" src="../assets/icons/copy-icon.svg" /> Create Copy</div>
            <div class="px-4 py-3 border-b cursor-pointer hover:bg-gray-100" @click="resetProject"><img class="w-3 object-contain inline-block mr-1 h-3" src="../assets/icons/list-icon.svg" /> Clear All Fields</div>
            <div class="px-4 py-3 border-b cursor-pointer hover:bg-gray-100" @click="newProject"><img class="w-3 object-contain inline-block mr-1 h-3" src="../assets/icons/new-doc-icon-dark.svg" /> Start New Project</div>
            <div class="px-4 py-3 cursor-pointer hover:bg-red-600 hover:text-white" @click="deleteProject"><svg class="w-3 h-3 inline-block mr-1 ml-0 fill-current" xmlns="http://www.w3.org/2000/svg" width="21" height="27" viewBox="0 0 21 27">
              <path d="M9,28.5a3.009,3.009,0,0,0,3,3H24a3.009,3.009,0,0,0,3-3v-18H9Zm3.69-10.68L14.805,15.7,18,18.885l3.18-3.18L23.3,17.82,20.115,21l3.18,3.18L21.18,26.3,18,23.115,14.82,26.3,12.7,24.18,15.885,21ZM23.25,6l-1.5-1.5h-7.5L12.75,6H7.5V9h21V6Z" transform="translate(-7.5 -4.5)"/>
            </svg>
              Delete Project</div>
          </div>
        </div>
      </div>
      <button class="button ml-2 border-red-500 text-red-500" @click="showTutorial"><span class="">Tutorial</span></button>
      <div class="w-8 h-8 p-2 border-gray-300 border-2 rounded-full flex justify-center items-center mt-1 ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="exitProject">
        <img  class="h-3 inline-block " src="../assets/icons/close-icon.svg" />
      </div>

    </div>

  </div>



  <div class="relative border-t-4 bg-gray-50" v-if="project">
    <div class="px-8 h-full relative pt-6">
      <h2 class="font-bold uppercase text-xl pb-2">PROJECT COST/REVENUE ESTIMATE <span v-if="loading" class="text-sm opacity-50">Loading...</span></h2>

      <div class="pr-8 max-w-4xl">


        <div class="mb-2 sm:flex border-t pt-4 w-full">
          <div class="mb-4 sm:mb-0 w-96">
            <h3 class="">Project Description</h3>
            <p class="text-sm text-gray-500">Describe the project by name or number.</p>

          </div>
          <div class="mt-6 sm:mt-0 sm:pl-16 w-full">
            <div class="">
              <label class="text-sm">Project Name/Number</label>
              <div class="pt-1">
                <input class="w-full p-1 px-2 rounded border" v-model="project.projectName"  @change="onChange" />
              </div>
            </div>
          </div>
        </div>

        <div class="mb-2 sm:flex border-t pt-4 mt-8 w-full">
          <div class="w-96">
            <h3 class="">Project Quantities</h3>
            <p class="text-sm text-gray-500">Specify the total quantity to be printed as well as number of images in the project and how that breaks down between color and B&W.</p>

          </div>
          <div class="mt-6 sm:mt-0 sm:pl-16 w-full">

            <div class="pb-2">
              <label class="text-sm">Total Quantity</label>
              <div class="w-48">
                <number-input class="border w-full p-1 px-2 rounded text-right" v-model="project.quantity" precision="0" @change="onChange"></number-input>
              </div>
            </div>
            <div class="pb-2">
              <label class="text-sm hint" data-hint="This is the final size of the finished product. For example 5 x 7, 8.5 x 11, etc."><span>Final Size</span></label>
              <div class="w-48">
                <input type="text" class="border w-full p-1 px-2 rounded text-right" v-model="project.finalSize"  @change="onChange" placeholder="5 x 7, 8.5 x 11, etc." />
              </div>
            </div>

            <div class="relative">
              <label class="text-sm inline-block mt-4 mb-1 hint" data-hint="Calculated sum of Color images + B&W images in fields below"><span>Total # of {{ project.finalSize?project.finalSize:'' }} Images = {{ project.pages }}</span></label>
            </div>

            <div class="border -mx-4 -mr-8 sm:-mx-8 px-4 sm:px-8 py-2 relative flex  mb-2 bg-gray-100">

              <div class="py-2 mr-4">
                <label class="hint text-sm" data-hint="(Example: For a Post Card, printed 4 Color on 2 Sides, the # of Images is 2. For a Post Card, Printed 4 Color  on 1  Side, the # of Images is 1. For an 8 Page 8.5 x 11 Booklet, the # of Images is 8)"><span># of Color Images</span></label>
                <div class="w-48 relative">
                  <number-input class="border w-full p-1 px-2 rounded text-right" v-model="project.pagesColor" precision="0" @change="onChange('pagesColor')"></number-input>
                  <span class="mt-1 block text-sm opacity-75">= {{ project.impressionsColor |  decimal(0) }} Color Impressions</span>
                </div>
              </div>

              <div class="py-2">
                <label class="hint text-sm" data-hint="(Example: For a Post Card, printed B&W on 2 Sides, the # of Images is 2. For a Post Card, Printed B&W  on 1  Side, the # of Images is 1. For an 8 Page 8.5 x 11 Booklet, the # of Images is 8)"><span># of B/W Images</span></label>
                <div class="w-48 relative">
                  <number-input class="border w-full p-1 px-2 rounded text-right" v-model="project.pagesBW" precision="0" @change="onChange('pagesBW')"></number-input>
                  <span class="mt-1 block text-sm opacity-75">= {{ project.impressionsBW |  decimal(0) }} B&W Impressions</span>
                </div>
              </div>


            </div>

            <div class="pb-2">
              <label class="text-sm hint" data-hint="This is the sheet size of the material used to print this project."><span>Sheet Size Project will be Printed on</span></label>
              <div class="w-full">
                <select-input :options="paperChoices" prompt-text="Select Paper Size" v-model="project.paperSize" @change="onChange"></select-input>
              </div>
            </div>








            <div class="py-2 hidden">
              <label class="text-sm hint" data-hint="Calculated sum of Color images + B&W images in fields above"><span>Total # of {{ project.finalSize?project.finalSize:"8.5 x 11" }} Images</span></label>
              <div class="w-48">
                <number-input class="border w-full p-1 px-2 rounded text-right" v-model="project.pages" precision="0" @change="onChange('pages')" disabled></number-input>
              </div>
            </div>



            <div class="py-2">
              <label class="hint text-sm" data-hint="(Example: For an 8.5 x 11 Booklet that is 4 or More Pages, the # Up on 8.5 x 11 would be 1, the # Up on 11 x 17, 12 x 18, 13 x 19 would be 2. For a 5 x 7 Postcard - Printed 4 Up on 11 x 17, # Up = 4)"><span># of Final Size Pieces Printed on Sheet Size</span></label>
              <div class="w-48 relative">
                <number-input class="border w-full p-1 px-2 rounded text-right" v-model="project.up" precision="0" @change="onChange"></number-input>
                <span class="absolute left-full w-48 top-0 mt-1 ml-1 block pt-1 text-sm opacity-75">= {{ project.impressions |  decimal(0) }} total impressions</span>
              </div>
            </div>



          </div>

        </div>



        <div class="mb-2 sm:flex border-t pt-4 mt-8 w-full">
          <div class="w-96">
            <h3 class="">Materials</h3>
            <p class="text-sm text-gray-500">What is the unit material cost and how much material spoilage will occur due to specialty toner application?</p>

          </div>
          <div class="mt-6 sm:mt-0 sm:pl-16 w-full">
            <div class="pb-2">
              <label class="text-sm hint" data-hint="Cost of 1 Sheet of Material Printed on the Sheet Size Listed Above"><span>Sheet Size Material Cost <span class="text-xs" v-if="project.paperSize">({{ project.paperSize }})</span></span></label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 rounded border text-right" v-model="project.materialCost" format="$" precision="3" @change="onChange"></number-input>
              </div>
            </div>

            <div class="py-2">
              <label class="text-sm">% of Material Spoilage</label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 rounded border text-right" v-model="project.spoilage" format="%" precision="2" @change="onChange"></number-input>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-2 sm:flex border-t pt-4 mt-8 w-full">
          <div class="w-96">
            <h3 class="">Equipment Operator</h3>
            <p class="text-sm text-gray-500">Specify the operator wage and time needed to change over toner in specialty color.</p>

          </div>
          <div class="mt-6 sm:mt-0 sm:pl-16 w-full">
            <div class="pb-2">
              <label class="text-sm">Operator's Hourly Wage</label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.operatorWage" format="$" precision="2" @change="onChange"></number-input>
              </div>
            </div>

            <div class="py-2">
              <label class="text-sm hint" data-hint="Add info for Changeover only if this project includes Specialty Color"><span>Total Time to Change Over<br>Specialty Color Toner (in Hrs.)</span></label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.changeTime" precision="2" @change="onChange"></number-input>
              </div>
            </div>

          </div>
        </div>



        <div class="mb-2 sm:flex border-t pt-4 mt-8 w-full">
          <div class="w-96">
            <h3 class="">Equipment Rates</h3>
            <p class="text-sm text-gray-500">Enter estimated equipment impression rates here, or use the calculator button to create a detailed rate calculation.</p>

          </div>
          <div class="mt-6 sm:mt-0 sm:pl-16 w-full">

            <div class="border -mx-4 -mr-8 sm:-mx-8 px-4 sm:px-8 py-4 relative">
            <div class="pb-2">
              <label class="text-sm hint" data-hint="Use your 8.5 x 11 color impression rate, this value will be adjusted in the calculation to account for the project sheet size."><span>Cost per Color Click (8.5 x 11)</span></label>
              <div class="w-48 relative">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.costPerClickColor" format="$" precision="3" @change="onChange"></number-input>
                <span class="absolute left-full w-48 top-0 mt-1 ml-1 block pt-1 text-sm opacity-75">= ${{ project.impressionCostColor |  decimal(0) }} impression cost (Color)</span>
              </div>
            </div>

            <div class="py-2">
              <label class="text-sm hint" data-hint="Use your 8.5 x 11 B&W impression rate, this value will be adjusted in the calculation to account for the project sheet size."><span>Cost per B&W Click (8.5 x 11)</span></label>
              <div class="w-48 relative">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.costPerClickBW" format="$" precision="3" @change="onChange"></number-input>
                <span class="absolute left-full w-48 top-0 mt-1 ml-1 block pt-1 text-sm opacity-75">= ${{ project.impressionCostBW |  decimal(0) }} impression cost (B&W)</span>
              </div>
            </div>
              <button tabindex="-1" @click="showRateCalc" class="shadow bg-white p-1 absolute left-full sm:left-0 top-1/2 transform -translate-y-1/2 -translate-x-1/2 border">
                <img class="w-5 h-6" src="../assets/icons/calculator-icon.svg" />
              </button>
            </div>



            <div class="border -mx-4 -mr-8 sm:-mx-8 px-4 sm:px-8 py-4 mt-4 relative">

              <div class="pb-2">
                <label class="text-sm hint" data-hint="# of specialty color impressions (in addition to the Color/Black impressions above)"><span># of Specialty Color Impressions</span></label>
                <div class="w-48 relative">
                  <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.specialtyColor.impressions" precision="0" @change="onChange"></number-input>
                </div>
              </div>

              <div class="py-2">
                <label class="text-sm">Total Cost of Specialty Color Impressions</label>
                <div class="w-48">
                  <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.specialtyColor.totalCost" format="$" precision="2" @change="onChange"></number-input>
                </div>
              </div>

              <button tabindex="-1" @click="showSpecialtyCostCalc" class="shadow bg-white p-1 absolute left-full sm:left-0 top-1/2 transform -translate-y-1/2 -translate-x-1/2 border">
                <img class="w-5 h-6" src="../assets/icons/calculator-icon.svg" />
              </button>

            </div>



            <div class="py-2 mt-2">
              <label class="text-sm hint" data-hint="Note: 25% Benefit Load added to Operator's Wage"><span>Specialty Color Changeover Cost</span></label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.changeoverCost" format="$" precision="2" @change="onChange" disabled></number-input>
              </div>
            </div>


          </div>
        </div>


        <div class="mb-2 sm:flex border-t pt-4 mt-8 w-full">
          <div class="w-96">
            <h3 class="">Other Costs</h3>
            <p class="text-sm text-gray-500">Enter other material costs.</p>

          </div>
          <div class="mt-6 sm:mt-0 sm:pl-16 w-full">



            <div class="pb-2">
              <label class="text-sm">Preflight/File Prep Cost</label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.prepCost" format="$" precision="2" @change="onChange"></number-input>
              </div>
            </div>

            <div class="py-2">
              <label class="text-sm hint" data-hint="This field is calculated using 8.5 x 11 Material Cost and Total Impressions values."><span>Total Material Cost</span></label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.totalMaterialCost" format="$" precision="2" @change="onChange" disabled></number-input>
              </div>
            </div>

            <div class="py-2">
              <label class="text-sm hint" data-hint="Supply Costs for Staples, Spiral Bind, Boxes, etc."><span>Finishing Supplies</span></label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.finishingSupplies" format="$" precision="2" @change="onChange"></number-input>
              </div>
            </div>

            <div class="py-2">
              <label class="text-sm hint" data-hint="Labor Cost for Cutting, Hole Drilling, Score, Collate, etc."><span>Bindery Cost</span></label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.binderyCost" format="$" precision="2" @change="onChange"></number-input>
              </div>
            </div>



          </div>
        </div>

        <div class="mb-2 sm:flex border-t pt-4 mt-8 w-full">
          <div class="w-96">
            <h3 class="">Markup</h3>
            <p class="text-sm text-gray-500">Enter your desired markup rates.</p>

          </div>
          <div class="mt-6 sm:mt-0 sm:pl-16 w-full">



            <div class="pb-2">
              <label class="text-sm">Impression Cost Markup</label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.impressionMarkup" format="%" precision="1" @change="onChange"></number-input>
              </div>
            </div>

            <div class="py-2">
              <label class="text-sm">Specialty Color Markup</label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.specialtyColorMarkup" format="%" precision="1" @change="onChange"></number-input>
              </div>
            </div>

            <div class="py-2">
              <label class="text-sm">PreFlight Markup</label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.prepMarkup" format="%" precision="1" @change="onChange"></number-input>
              </div>
            </div>

            <div class="py-2">
              <label class="text-sm">Material Markup</label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.materialMarkup" format="%" precision="1" @change="onChange"></number-input>
              </div>
            </div>

            <div class="py-2">
              <label class="text-sm">Finishing Markup</label>
              <div class="w-48">
                <number-input class="w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.finishingMarkup" format="%" precision="1" @change="onChange"></number-input>
              </div>
            </div>



          </div>
        </div>

        <div class="mb-2 sm:flex border-t pt-4 mt-8 w-full">
          <div class="w-96">
            <h3 class="">Cost & Revenue Summary</h3>
            <p class="text-sm text-gray-500">Your calculated total cost, pricing and profitablity for this project.</p>

          </div>
          <div class="mt-6 sm:mt-0 sm:pl-16 w-full">



            <div class="pb-2">
              <label class="text-sm">Total Costs</label>
              <div class="w-48">
                <number-input class="calculated w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.totalCost" format="$" precision="2" @change="onChange" disabled></number-input>
              </div>
            </div>

            <div class="py-2">
              <label class="text-sm">Total Selling Price (With Markup)</label>
              <div class="w-48">
                <number-input class="calculated w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.totalPrice" format="$" precision="2" @change="onChange" disabled></number-input>
              </div>
            </div>

            <div class="py-2">
              <label class="text-sm">Profitablity</label>
              <div class="w-48">
                <number-input class="calculated w-full p-1 px-2 mt-1 rounded text-right border" v-model="project.profitability" format="%" precision="1" @change="onChange" disabled></number-input>
              </div>
            </div>

          </div>
        </div>



      </div>
    </div>

    <div class="pt-4 mt-4 border-t mx-8 max-w-4xl">
      <div class="pb-4 sm:flex justify-between">
        <div class="sm:pr-4">
          <button  class="mt-2 sm:mt-0 button mr-4 w-full sm:w-auto" @click="resetProject">Clear Fields</button>


        </div>
        <div class="sm:pl-4 sm:flex">
          <button  class="mt-2 sm:mt-0 button mr-4 w-full sm:w-auto" @click="exitProject">Return to Calculators</button>
          <button class="mt-2 sm:mt-0 button button-blue w-full sm:w-auto" @click="saveProject"><span>Save Estimate <img v-if="saving" class="ml-2 inline-block h-4" src="../assets/icons/tail-spin.svg" /></span></button>
        </div>

      </div>
    </div>

  </div>


  <transition name="fade-on">
    <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="sidePanel && profile">
      <side-panel-projects class="" :profile="profile" @load="loadSavedProject" :has-changes="hasUnsavedChanges"  @close="closeSidePanel"></side-panel-projects>
    </div>
  </transition>

  <transition name="fade-on">
    <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="equipmentRatesPanel && profile">
      <side-panel-equipment-rates class="" :profile="profile" @load="onRateUpdate" :has-changes="false"  @close="closeSidePanel"></side-panel-equipment-rates>
    </div>
  </transition>




  <transition name="fade-on">
    <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="specialtyRatesPanel && profile">
      <side-panel-color-calcs class="" :profile="profile" @load="onSpecialtyCostUpdate" :has-changes="false"  @close="closeSidePanel"></side-panel-color-calcs>
    </div>
  </transition>

  <transition name="fade-on">
    <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="tutorial">
      <video-window class="" video="speciality-color-tutorial.mp4" title="Tutorial" @close="closeTutorial"></video-window>
    </div>
  </transition>



<!--  <transition name="fade-on">-->
<!--    <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="specialtyCostPanel && profile">-->
<!--      <specialty-color-cost-calculator class="" :project="project" :profile="profile"  @close="closeSidePanel" @change="onSpecialtyCostUpdate"></specialty-color-cost-calculator>-->
<!--    </div>-->
<!--  </transition>-->




</div>
</template>

<script>

// import LinkList from "@/components/LinkList";
// import ColorRateCalculator from "@/components/ColorRateCalculator";
// import CalcCustomerProfile from "@/components/CalcCustomerProfile";
// import ColorProjectEstimator from "@/components/ColorProjectEstimator";

import NumberInput from "@/components/NumberInput";
import SidePanelProjects from "@/components/SidePanelProjects";
//import ColorRateCalculator from "@/components/ColorRateCalculator";
import bodyScroll from 'body-scroll-toggle';
import SidePanelEquipmentRates from "@/components/SidePanelEquipmentRates";
import SidePanelColorCalcs from "@/components/SidePanelColorCalcs";
import SelectInput from "@/components/SelectInput";
import VideoWindow from "@/components/ColorCalc/VideoWindow";
//import SpecialtyColorCostCalculator from "@/components/SpecialtyColorCostCalculator";


export default {
    name: "project-estimate-calculator",
    props: {},
    mounted: function(){
      if(this.$route.params.project){
        this.loadProject(this.$route.params.project);
      }else{
        this.project = this.$colorCalc.generateProject();
        this.modalOptions = true;
      }

      //this.colorProfile = this.$colorCalc.generateProfile();

    },
    data: function(){
        return {
          modalOptions: false,
          project: undefined,
          sidePanel: false,
          equipmentRatesPanel: false,
          specialtyRatesPanel:false,
          hasUnsavedChanges: false,
          saving: false,
          loading: false,
          lastSave: "",
          tutorial: false,
          paperChoices: [
            '8.5 x 11',
            '11 x 17',
            '12 x 18',
            '13 x 19',
              '13 x 27.5'
          ]
        }
        },
    components: {
      SelectInput,
      SidePanelColorCalcs,
      SidePanelEquipmentRates,
      SidePanelProjects,
      NumberInput,
      VideoWindow
      //ColorProjectEstimator,
      //CalcCustomerProfile,
      //ColorRateCalculator,
      //LinkList
    },
    methods: {

      showTutorial: function(){
        bodyScroll.disable();
        this.tutorial = true;
      },

      closeTutorial: function(){
        bodyScroll.enable();
        this.tutorial = false;
      },

      showProjects: function(){
        bodyScroll.disable();
        this.sidePanel = true;
        this.modalOptions = false;
      },


      deleteProject: function(){
        if(this.project){
          if(confirm('Are you sure you want to delete this project?')){
            if(this.project.id) {
              this.$colorCalc.remove(this.project.id, 'colorProjects')
                  .then(() => {
                    this.$router.push({
                      name: "Calculators",
                    })
                  })
                  .catch((error) => {
                    console.error(error);
                    alert("There was a problem with this operation. Try again.");

                  })
            }else{
              this.$router.push({
                name: "Calculators",
              })
              }
            }
        }

      },

      showRateCalc: function(){
        bodyScroll.disable();
        this.equipmentRatesPanel = true;

        //alert("Please use our Equipment Rate Calculator to calculate an equipment’s impression rate and enter the values here.");
      },

      showSpecialtyCostCalc: function(){


        bodyScroll.disable();
        this.specialtyRatesPanel = true;
        //alert("Please use our Specialty Color Cost Calculator to calculate the additional cost for Specialty Color on a job and enter the value here.");
      },

      exitProject: function(){

        if(this.hasUnsavedChanges){
          if(confirm("There are unsaved changes to this project. If you exit before saving you will lose these changes.")){
            this.$router.push({
              name: "Calculators",
            })
          }
        }else{
          this.$router.push({
            name: "Calculators",
          })
        }
      },

      onPanelUpdate: function(){
        this.closeSidePanel();
      },

      onRateUpdate: function(e){
       //console.log(e);
        this.project.costPerClickColor = e.costColor;
        this.project.costPerClickBW = e.costBW;
        this.calculate();
        this.equipmentRatesPanel = false;
        bodyScroll.enable();
        this.hasUnsavedChanges = true;
      },

      onSpecialtyCostUpdate: function(e){
       //console.log(e);
        this.project.specialtyColor.impressions = e.impressions;
        this.project.specialtyColor.totalCost = e.totalCost;
        //this.project.costPerClickColor = this.profile.rates.summary.impressionRateColor;
        //this.project.costPerClickBW = this.profile.rates.summary.impressionRateBW;
        this.calculate();
        this.specialtyRatesPanel = false;
        bodyScroll.enable();
        this.hasUnsavedChanges = true;
      },

      saveProject: function(){

         if(!this.project.projectName){
           window.scrollTo(0,0);
           alert("You must enter a project name.");
           return;
         }else if( !this.project.id ){
           this.saveNewProject();
           return;
         }

          this.saving = true;
          this.$colorCalc.update(this.project, this.project.id, 'colorProjects')
        .then(() => {
          this.lastSave = Date.now();
          this.saving = false;
          this.hasUnsavedChanges = false;
          alert("This project has been saved.");
        }).catch((error) => {
          console.error("Error", error);
            this.saving = false;
            alert("Something went wrong with this operation. Please try again.")
          })
      },

      saveNewProject: function(){
        this.$colorCalc.create(this.project, "colorProjects")
        .then((response) => {

          if(response.data && response.data.projectName){
            this.project.id = response.ref.value.id;
            alert("This project has been saved.");
            this.hasUnsavedChanges = false;
           //console.log("redirecting", response.ref);
            this.$router.push({
              name: "Project Estimate",
              params: {
                project: this.project.id
              }
            })
          }
        })
        .catch((error) => {
          console.error("Error", error);
          alert("Something went wrong with this operation. Please try again.");
        })
      },

      newProject: function(){
        if(this.hasUnsavedChanges){
          if(!confirm("This will erase any unsaved changes. Click OK to proceed.")) {
            return;
          }
        }



        this.project = this.$colorCalc.generateProject();
        this.hasUnsavedChanges = false;
        this.modalOptions = false;


        if(this.$route.params.project){
          this.$router.push({
            name: "New Project Estimate",
          })
        }

      },

      copyProject: function(){

        let newName = prompt("Enter the new project name:");

        if(newName){
          let newProject = this.$colorCalc.copyRecord(this.project);

          newProject.projectName = newName;

          this.project = newProject;

          this.saveNewProject();
        }

      },

      resetProject: function(){
          if(confirm("This will clear you current field values. Click OK to proceed.")){
            this.project.quantity = 0;
            this.project.pages = 0;
            this.project.paperSize = "8.5 x 11";
            this.project.pagesColor = 0;
            this.project.pagesBW = 0;
            this.project.up = 1;
            this.project.materialCost = 0;
            this.project.spoilage = 0;
            this.project.impressionsColor = 0;
            this.project.impressionsBW = 0;
            this.project.changeTime = 0;
            this.project.operatorWage = 0;
            this.project.costPerClickColor = 0;
            this.project.costPerClickBW = 0;
            this.project.costPerClickCalculated = false;
            this.project.impressionCostColor = 0;
            this.project.impressionCostBW = 0;
            this.project.changeoverCost = 0;
            this.project.prepCost = 0;
            this.project.totalMaterialCost = 0;
            this.project.finishingSupplies = 0;
            this.project.binderyCost = 0;
            this.project.totalCost = 0;
            this.project.impressionMarkup = 0;
            this.project.specialtyColorMarkup = 0;
            this.project.prepMarkup = 0;
            this.project.materialMarkup = 0;
            this.project.finishingMarkup = 0;
            this.project.totalPrice = 0;
            this.project.profitability = 0;

            this.project.specialtyColor = {
              tonerPrice: {
                clear: 0,
                white: 0,
                yellow: 0,
                pink: 0,
                red: 0
              },
              color: "",
              density: 0,
              percentSpecial: 0,
              paperSize: "8.5 x 11",
              impressions: 0,
              impressionCost: 0,
              totalCost: 0,
              calculated: false
            }

            this.hasUnsavedChanges = true;
            this.calculate();

          }
      },

      closeSidePanel: function(){
       //console.log("close panel");
        this.sidePanel = false;
        this.specialtyRatesPanel = false;
        this.equipmentRatesPanel = false;
        bodyScroll.enable();
      },

      onChange: function(){
       //console.log("change", key);

        if(!this.project.up){
          this.project.up = 1;
        }

        this.calculate();

        this.hasUnsavedChanges = true;




      },

      calculate: function(){
        if(this.project.up){
          this.project.pages = this.project.pagesColor + this.project.pagesBW;

          this.project.impressionsColor = (this.project.quantity * this.project.pagesColor) / this.project.up;
          this.project.impressionsBW = (this.project.quantity * this.project.pagesBW) / this.project.up;
          this.project.impressions = this.project.impressionsColor + this.project.impressionsBW;
          this.project.changeoverCost = (this.project.operatorWage * 1.25) * this.project.changeTime;
          this.project.totalMaterialCost = ((this.project.impressions / 2) * this.project.materialCost) * (1+(this.project.spoilage * 0.01));


          //Double impression cost for larger paper sizes. Assume 8.5 x 11 if not present.
          if(this.project.paperSize && (this.project.paperSize !== '8.5 x 11')){
            this.project.impressionCostColor = this.project.costPerClickColor * this.project.impressionsColor * 2.0;
            this.project.impressionCostBW = this.project.costPerClickBW * this.project.impressionsBW * 2.0;
          }else{
            this.project.impressionCostColor = this.project.costPerClickColor * this.project.impressionsColor;
            this.project.impressionCostBW = this.project.costPerClickBW * this.project.impressionsBW;
          }


          this.project.totalCost = this.project.impressionCostColor + this.project.impressionCostBW + this.project.specialtyColor.totalCost + this.project.changeoverCost + this.project.prepCost + this.project.totalMaterialCost + this.project.finishingSupplies + this.project.binderyCost;
          this.project.totalPrice = this.calculateTotalPrice();
          if(this.project.totalCost){
            this.project.profitability = ((this.project.totalPrice - this.project.totalCost) / this.project.totalCost) * 100;
          }else{
            this.project.profitability = 0;
          }


        }

      },

      calculateTotalPrice: function(){

        let impressionCosts = (this.project.impressionCostColor + this.project.impressionCostBW) * (1 + this.project.impressionMarkup * 0.01);
        let specialtyColorCosts = (this.project.specialtyColor.totalCost + this.project.changeoverCost) * (1 + this.project.specialtyColorMarkup * 0.01);
        let prepCosts = (this.project.prepCost) * (1 + this.project.prepMarkup * 0.01);
        let materialsCosts = (this.project.totalMaterialCost) * (1 + this.project.materialMarkup * 0.01);
        let finishingCosts = (this.project.finishingSupplies) * (1 + this.project.finishingMarkup * 0.01);
        let binderyCosts = (this.project.binderyCost) * (1 + this.project.finishingMarkup * 0.01);

        let price = impressionCosts + specialtyColorCosts + prepCosts + materialsCosts + finishingCosts + binderyCosts;
       //console.log(price);

        return price;

      },



      // loadData: function(){
      //   this.loading = true;
      //   if(this.profile && (this.profile.id == this.$route.params.profile)){
      //    //console.log("profile already loaded")
      //     this.loadProject();
      //   }else{
      //     this.$colorCalc.load(this.$route.params.profile, "colorProfiles")
      //         .then((response) => {
      //          //console.log("profile loaded", response);
      //           this.$store.state.colorProfile = response;
      //           this.loadProject();
      //         })
      //         .catch((error) => {
      //           console.error("Error", error);
      //           this.loading = false;
      //         })
      //   }
      // },

      loadSavedProject: function(project){
        bodyScroll.enable();
        this.$router.push({
          name: "Project Estimate",
          params: {
            project: project.id
          }
        })
        this.loadProject(project.id);
        this.sidePanel = false;

      },

      loadProject: function(id){

          this.loading = true;

          this.$colorCalc.load(id, 'colorProjects')
          .then((response) => {
            if(!response.paperSize){
              response.paperSize = "8.5 x 11";
            }
            this.project = response;
            this.loading = false;
          })
          .catch((error) => {
            console.error("Error", error);
            this.loading = false;
          })
      },

      setSection: function(section){
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: this.$route.query.tab,
            section: section.id
          }
        })

        this.autosave();
      },

      goToSection: function(sectionId){
        this.setSection({ id: sectionId });

      },

      autosave: function(){
       //console.log("simulated save");
      }
    },
    computed: {

      statusMsg: function(){
        if(this.loading){
          return "Loading ...";
        }else if(this.saving) {
          return "Saving ..."
        }else if(this.lastSave) {
          let dateString = (new Date(this.lastSave)).toLocaleTimeString();
          return ("Updated: "+dateString);
        }



        return ""

      },

      profile: function(){
        return this.$store.getters.getUserProfile;
      },
      sections: function(){
        return [
          { label: "Specialty Color Profile", id: "profile", component: "CalcCustomerProfile",
            instructions: ""
          },
          { label: "Rate Calculation", id: "ratecalc", component: "ColorRateCalculator",
            instructions: ""
          },
          { label: "Projects", id: "estimator", component: "ColorProjectEstimator",
            instructions: ""
          }
        ]
      },

      currentSection: function(){
        //console.log("current section?");

        //console.log(this.sections);
        var section = this.sections.find((item) => {
          return item.id == this.$route.query.section;
        })

        if(!section){
          section = this.sections[0];
        }

        return section;
      }
    }
}
</script>

<style scoped>


.color-calulator {

}

.hint {
  position: relative;
  cursor: pointer;

}

.hint > span::after {
  content: "";
  margin-left: 0.5em;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-image: url(../assets/icons/question-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: -0.1em;
}

.hint img {
  opacity: 50%;
}

.hint:hover img {
  opacity: 100%;
}

.hint:hover::after {
  content: attr(data-hint);
  display: block;
  position: absolute;
  bottom: 100%;
  left: 100%;
  background-color: white;
  border: 1px solid gray;
  padding: 1em;
  width: 16em;
  font-size: 0.9em;
  line-height: 1.4em;
  border-radius: 0.2em;

}

input.calculated:disabled {
  @apply bg-blue-50 bg-transparent border-brand-blue;
}



</style>
