


<template>
<svg class="w-full" width="1000" height="420" ref="chart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 420">
  <rect width="1000" height="420" x="0" y="0" fill="white" stroke="black"></rect>
  <g>
    <text text-anchor="middle" x="275" y="30" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Current Workflow Hours vs</text>

    <text text-anchor="middle" x="275" y="50" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Future Workflow Hours with Print MIS</text>
    <rect width="350" height="300" x="100" y="70" fill="none" stroke="black"></rect>

    <text text-anchor="end" x="90" y="76" font-family="Arial, Helvetica, sans-serif" font-size="12">{{  hours.ceiling | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="151" font-family="Arial, Helvetica, sans-serif" font-size="12">{{ (hours.increment * 3) | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="226" font-family="Arial, Helvetica, sans-serif" font-size="12">{{ (hours.increment * 2) | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="301" font-family="Arial, Helvetica, sans-serif" font-size="12">{{ hours.increment | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="372" font-family="Arial, Helvetica, sans-serif" font-size="12">0</text>


    <line x1="100" y1="151" x2="450" y2="151" stroke="black" stroke-width="0.8"></line>
    <line x1="100" y1="226" x2="450" y2="226" stroke="black" stroke-width="0.8"></line>
    <line x1="100" y1="301" x2="450" y2="301" stroke="black" stroke-width="0.8"></line>


      <rect width="100" :height="hours.current" :x="137" :y="370 - hours.current" fill="#2a83c1" stroke="none"></rect>
      <rect width="100" :height="hours.future" :x="312" :y="370 - hours.future" fill="#2a83c1" stroke="none"></rect>






    <text text-anchor="middle" x="187" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Current Yearly Hours</text>
    <text text-anchor="middle" x="362" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Future Yearly Hours with MIS</text>



  </g>

  <g>
    <text text-anchor="middle" x="775" y="30" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Current Workflow Costs vs</text>

    <text text-anchor="middle" x="775" y="50" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Future Workflow Costs with Print MIS</text>
    <rect width="350" height="300" x="600" y="70" fill="none" stroke="black"></rect>

    <text text-anchor="end" x="590" y="76" font-family="Arial, Helvetica, sans-serif" font-size="12">${{  costs.ceiling | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="151" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ (costs.increment * 3) | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="226" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ (costs.increment * 2) | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="301" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ costs.increment | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="372" font-family="Arial, Helvetica, sans-serif" font-size="12">$0</text>


    <line x1="600" y1="151" x2="950" y2="151" stroke="black" stroke-width="0.8"></line>
    <line x1="600" y1="226" x2="950" y2="226" stroke="black" stroke-width="0.8"></line>
    <line x1="600" y1="301" x2="950" y2="301" stroke="black" stroke-width="0.8"></line>


    <rect width="100" :height="costs.current" :x="637" :y="370 - costs.current" fill="#2a83c1" stroke="none"></rect>
    <rect width="100" :height="costs.future" :x="812" :y="370 - costs.future" fill="#2a83c1" stroke="none"></rect>






    <text text-anchor="middle" x="687" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Current Yearly Cost</text>
    <text text-anchor="middle" x="862" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Future Yearly Cost with MIS</text>


  </g>




</svg>
</template>

<script>
export default {
    name: "roi-chart-mis-current-future",
    props: ["assessment"],
    data: function(){
        return {}
        },
    methods: {
      ceilingVal: function(max){

        let multiplier = 1000;
        if(max < 10000){
          multiplier = 100
        }
        if(max < 1000){
          multiplier = 10
        }
        return Math.ceil((max / 4)/multiplier) * 4 * multiplier;
      },
    },
    computed: {

      hours: function(){
         let current = this.assessment.savings.summary.totals.hours;
         let future = this.assessment.savings.summary.totals.hoursMIS;
         let scale = 300;
         let maxValue = (current > future)?current:future;
         let ceiling = this.ceilingVal(maxValue * 1.1);

        return {
          ceiling: ceiling,
          increment: ceiling / 4,
          current: (current/ceiling) * scale,
          future: (future/ceiling) * scale,
        }

      },

      costs: function(){
        let current = this.assessment.savings.summary.internalSubtotal.cost;
        let future = this.assessment.savings.summary.totals.costMIS;
        let scale = 300;
        let maxValue = (current > future)?current:future;
        let ceiling = this.ceilingVal(maxValue * 1.1);

        return {
          ceiling: ceiling,
          increment: ceiling / 4,
          current: (current/ceiling) * scale,
          future: (future/ceiling) * scale,
        }

      },






      costsData: function(){
        let years = [];
        let costs = this.assessment.costs.mis;

        let maxValue = 1000;
        let scale = 300;

        for(var i=1; i<6; i++){

          let cost = costs.totals["year"+i];
          let saving = costs.savings["year"+i];
          let growth = costs.salesGrowth["year"+i];

          if(i === 1){
            cost = cost + costs.totals.initial;
            saving = saving + costs.savings.initial;
            growth = growth + costs.salesGrowth.initial;
          }

          if(cost > maxValue){
            maxValue = cost;
          }

          if(saving > maxValue){
            maxValue = saving;
          }

          if(growth > maxValue){
            maxValue = growth;
          }

          years.push([
              cost, saving, growth
          ])
        }





        let ceiling = this.ceilingVal(maxValue * 1.1);

        console.log(ceiling, maxValue)


        return {
          ceiling: ceiling,
          increment: ceiling / 4,
          data: years.map((year) => {
            return [
              ((year[0] / ceiling) * scale),
              ((year[1] / ceiling) * scale),
              ((year[2] / ceiling) * scale),
            ]
          })
      }

      },

      impactData: function(){
        let years = [];
        let costs = this.assessment.costs.mis;

        let maxValue = 1000;
        let scale = 300;

        for(var i=1; i<6; i++){

          let cost = costs.totals["year"+i];
          let saving = costs.savings["year"+i];
          let growth = costs.salesGrowth["year"+i];

          if(i === 1){
            cost = cost + costs.totals.initial;
            saving = saving + costs.savings.initial;
            growth = growth + costs.salesGrowth.initial;
          }

          let total = (saving - cost) + growth;

          if(total > maxValue){
            maxValue = total;
          }


          years.push([
            cost, saving, growth
          ])
        }





        let ceiling = this.ceilingVal(maxValue * 1.1);

        console.log(ceiling, maxValue)


        return {
          ceiling: ceiling,
          increment: ceiling / 4,
          data: years.map((year) => {
            return [
              ((year[0] / ceiling) * scale),
              (((year[1] - year[0]) / ceiling) * scale),
              ((year[2] / ceiling) * scale),
            ]
          })
        }

      }

    }
}
</script>

<style scoped>


.roichart-mispotential {

}

</style>