


<template>
<div class="pre-assessment-tips">

    <div>
      <h1 class="text-2xl font-bold mt-8 pb-2 border-b-2">Print MIS</h1>
      <h2 class="text-lg font-bold mt-4">Ideal Environment for Print MIS</h2>
      <ul class="list-disc ml-4 mt-2 mb-6">
        <li>Multiple Printers (Ideally Both Offset and Digital) and Finishing Area</li>
        <li>Pre-Press/Design Department</li>
        <li>Estimators and Customer Service Reps</li>
        <li>Need for Providing Job Processing Info to Production Employees</li>
        <li>Need for Providing Real-Time Job Status to Customers</li>
        <li>Job Tickets Stacked Around the Shop</li>
        <li>Warehouse or Inventory Area</li>
      </ul>

      <h2 class="text-lg font-bold mt-4">Key Pain Points without Print MIS</h2>
      <ul class="list-disc ml-4 mt-2 mb-6">
        <li>Inconsistent Estimates</li>
        <li>Difficulty Finding Jobs in the Shop</li>
        <li>Unable to Track Job Costs and Margin Levels</li>
        <li>Difficulty Managing Inventory</li>
        <li>Re-entry of Job Data</li>
        <li>Manual Reporting of Costs, Billing, Productivitiy</li>
      </ul>
    </div>
  <div>
    <h1 class="text-2xl font-bold mt-12 pb-2 border-b-2">Online Storefront</h1>
    <h2 class="text-lg font-bold mt-4">Ideal Environment for Online Storefront</h2>
    <ul class="list-disc ml-4 mt-2 mb-6">
      <li>End Users with Need for Similar Products and Materials - Marketing as well as Operational</li>
      <li>Centralized Source for Ordering Organization Materials</li>
      <li>Need for Brand Control</li>
      <li>Marketing Products that can be Templated or Customized</li>
      <li>Need for Streamlined Reports - May Currently be Performed Manually or Using Spreadsheets</li>
    </ul>

    <h2 class="text-lg font-bold mt-4">Key Pain Points without Online Storefront</h2>
    <ul class="list-disc ml-4 mt-2 mb-6">
      <li>No Centralized Source for Ordering Organization's Materials</li>
      <li>End Users Don't Know Where to Obtain All of the Organization's Products and Materials</li>
      <li>Manual or Unable to Produce Sales/Order Reports</li>
      <li>Difficulty Monitoring or Controlling the Brand - Everyone Produces their Own Marketing Materials</li>
      <li>Ordering System that is Homegrown and Difficult to Update</li>
    </ul>
  </div>
  <div>
    <h1 class="text-2xl font-bold mt-12 pb-2 border-b-2">Marketing Resource Management System</h1>
    <h2 class="text-lg font-bold mt-4">Ideal Environment for Marketing Resource Management System</h2>
    <ul class="list-disc ml-4 mt-2 mb-6">
      <li>(All of the Same Info for Online Storefront)</li>
      <li>Library of Marfketing Assets Containing Logos, Images, Video, Documents</li>
      <li>Need for Multi-Channel Marketing - Print, E-Mail, Web, Social Media</li>
      <li>Image and Document Personalization</li>
      <li>Kitting / Fulfillment</li>
      <li>Direct Mail Processing Including List Sourcing</li>
    </ul>

    <h2 class="text-lg font-bold mt-4">Key Pain Points without Marketing Resource Management</h2>
    <ul class="list-disc ml-4 mt-2 mb-6">
      <li>(All of the Same Info for Online Storefront)</li>
      <li>Multiple Systems for Print, E-Mail,  Social Media, Video Distribution</li>
      <li>Difficulty Managing Large Projects that Contain Varying Products</li>
      <li>Current System Doesn't Integrate with Organization's Print MIS System</li>
    </ul>
  </div>
  <div class="pt-4 mt-4 border-t">
    <div class="pb-4 flex justify-between">
      <div class="pr-4">
        <button class="button button-back" @click="$emit('goSection', 'profile')"><img class="inline-block mr-2 h-3 transform rotate-180" src="../assets/icons/next-arrow-icon-black.svg" /> Customer Profile</button>
      </div>
      <div class="pl-4">
        <button class="button button-blue button-next" @click="$emit('goSection', 'selfassessment')">Begin Self-Assessment <img class="inline-block ml-2 h-3" src="../assets/icons/next-arrow-icon.svg" /></button>
      </div>

    </div>
  </div>
</div>
</template>

<script>
export default {
    name: "pre-assessment-tips",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.pre-assessment-tips {

}

</style>