


<template>
  <div class="question relative" :data-inactive="!answer.active">

    <div class="w-full flex items-center">
      <div class="pr-8 flex-grow">
        <input class="float-left mt-1 mr-2" v-if="question.optional" type="checkbox" v-model="answer.active" @change="onChange"/> <span>{{ question.question }}</span>
      </div>
      <div class="flex-shrink-0 flex-grow-0 w-1/4">
        <hint class="block absolute -ml-8 mt-3" direction="absolute right-0 mr-8" v-if="question.hint" :message="question.hint"></hint>

        <select-input class="bg-white rounded relative" v-model="answer.notes" :options="question.choices" prompt-text="Select" @change="onChange"></select-input>
      </div>
    </div>

  </div>
</template>

<script>
// import PercentInput from "@/components/PercentInput";

import SelectInput from "@/components/SelectInput";
import Hint from "@/components/Hint";
export default {
    name: "assessment-interview-question-select",
  components: {SelectInput, Hint},
  props: {
      question: {
        required: true
      },
      answer: {
        required: true
      }
    },
    data: function(){
        return {

        }
        },
    methods: {
      onChange: function(e){
        this.$emit("change", e);
      }
    },
    computed: {

      enabled: function(){
        if(this.question.enabled){
          return this.question.enabled[0]
        }

        return true;
      },



    }
}
</script>

<style scoped>


.question[data-inactive="true"] {
  color: gray;
}

.question[data-inactive="true"] .col-input {
  color: rgba(0,0,0,0.2);
}

.question[data-inactive="true"] .col-input::placeholder {
  color: rgba(0,0,0,0.2);
}

</style>