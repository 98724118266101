


<template>
<div class="pre-customer-profile">
  <div>
    <h2 class="font-bold text-xl pb-2 border-b-2 pl-4">MIS Summary</h2>
  </div>
  <div class="">

    <div class="w-full chart-container-1 mb-4">
      <roi-chart-mis-potential class="absolute w-full h-full" :assessment="assessment"></roi-chart-mis-potential>
    </div>

    <div class="w-full chart-container-2 mb-8">
      <roi-chart-mis-current-future class="absolute w-full h-full" :assessment="assessment"></roi-chart-mis-current-future>
    </div>




    <roi-table-mis :assessment="assessment"></roi-table-mis>

  </div>
</div>
</template>

<script>


import RoiTableMis from "@/components/ROITableMIS";
import RoiChartMisPotential from "@/components/ROIChartMISPotential";
import RoiChartMisCurrentFuture from "@/components/ROIChartMISCurrentFuture";
export default {
    name: "summary-mis-tables",
  components: {RoiChartMisCurrentFuture, RoiChartMisPotential, RoiTableMis},
  mounted: function() {

  },
  props: {
      label: { required: false },
      instructions:  { required: false },
    },
    data: function(){
        return {
        }
        },
    methods: {
      onChange: function(){
        this.$emit("change");
      },
      formattedValue: function(cell){
        if(cell.filter){
          if(cell.filter === "$"){
            console.log(cell);
            return this.$options.filters.currency(cell.value);
          }
        }
        return cell.value;
      }
    },
    computed: {

      assessment: function(){
        return this.$store.state.workflowAssessment
      },

      costs: function(){
        if(this.assessment){
          return this.assessment.costs.mis;
        }
        return null;
      },


    }
}
</script>

<style scoped>


.pre-customer-profile {

}

.chart-container-1 {
  height: 0;
  padding-bottom: 50%;
  position: relative;
}

.chart-container-2 {
  height: 0;
  padding-bottom: 42%;
  position: relative;
}

td {
  @apply text-right py-2 px-4 border bg-white w-16;

}

td:nth-child(1) {
  @apply pl-4 text-left w-96;
}




td[data-null="true"]{

}



</style>