


<template>
<svg class="w-full" width="1000" height="500" ref="chart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 500">
  <rect width="1000" height="500" x="0" y="0" fill="white" stroke="black"></rect>
  <g>
    <text text-anchor="middle" x="275" y="30" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Current Workflow Hours vs</text>

    <text text-anchor="middle" x="275" y="50" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Future Workflow Hours with Print MIS</text>
    <rect width="350" height="300" x="100" y="70" fill="none" stroke="black"></rect>

    <text text-anchor="end" x="90" y="76" font-family="Arial, Helvetica, sans-serif" font-size="12">{{  hours.ceiling | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="151" font-family="Arial, Helvetica, sans-serif" font-size="12">{{ (hours.increment * 3) | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="226" font-family="Arial, Helvetica, sans-serif" font-size="12">{{ (hours.increment * 2) | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="301" font-family="Arial, Helvetica, sans-serif" font-size="12">{{ hours.increment | decimal(0) }}</text>
    <text text-anchor="end" x="90" y="372" font-family="Arial, Helvetica, sans-serif" font-size="12">0</text>


    <line x1="100" y1="151" x2="450" y2="151" stroke="black" stroke-width="0.8"></line>
    <line x1="100" y1="226" x2="450" y2="226" stroke="black" stroke-width="0.8"></line>
    <line x1="100" y1="301" x2="450" y2="301" stroke="black" stroke-width="0.8"></line>


    <g v-for="(amount, index) in hours.data" :key="index">
      <rect width="100" :height="amount[0]" :x="137 + (index * 175)" :y="370 - amount[0]" fill="#2a83c1" stroke="none"></rect>
      <rect width="100" :height="amount[1]" :x="137 + (index * 175)" :y="370 - (amount[0] + amount[1])" fill="#fed789" stroke="none"></rect>
    </g>






    <text text-anchor="middle" x="187" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Current Hrs. per Year</text>

    <text text-anchor="middle" x="362" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Online w/VDP Hrs. per Year</text>


    <rect width="15" height="15" x="190" y="420" fill="#2a83c1" stroke="none"></rect>
    <text text-anchor="start" x="215" y="432" font-family="Arial, Helvetica, sans-serif" font-size="12">Time to Create Job Ticket</text>
    <rect width="15" height="15" x="190" y="440" fill="#fed789" stroke="none"></rect>
    <text text-anchor="start" x="215" y="452" font-family="Arial, Helvetica, sans-serif" font-size="12">Time to Customize Document</text>


  </g>

  <g>
    <text text-anchor="middle" x="775" y="30" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Current Workflow Costs vs</text>

    <text text-anchor="middle" x="775" y="50" font-family="Arial, Helvetica, sans-serif" font-size="14" font-weight="bold">Future Workflow Costs with Print MIS</text>
    <rect width="350" height="300" x="600" y="70" fill="none" stroke="black"></rect>

    <text text-anchor="end" x="590" y="76" font-family="Arial, Helvetica, sans-serif" font-size="12">${{  cost.ceiling | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="151" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ (cost.increment * 3) | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="226" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ (cost.increment * 2) | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="301" font-family="Arial, Helvetica, sans-serif" font-size="12">${{ cost.increment | decimal(0) }}</text>
    <text text-anchor="end" x="590" y="372" font-family="Arial, Helvetica, sans-serif" font-size="12">$0</text>


    <line x1="600" y1="151" x2="950" y2="151" stroke="black" stroke-width="0.8"></line>
    <line x1="600" y1="226" x2="950" y2="226" stroke="black" stroke-width="0.8"></line>
    <line x1="600" y1="301" x2="950" y2="301" stroke="black" stroke-width="0.8"></line>


    <g v-for="(amount, index) in cost.data" :key="index">
      <rect width="100" :height="amount[0]" :x="637 + (index * 175)" :y="370 - amount[0]" fill="#2a83c1" stroke="none"></rect>
      <rect width="100" :height="amount[1]" :x="637 + (index * 175)" :y="370 - (amount[0] + amount[1])" fill="#fed789" stroke="none"></rect>
    </g>






    <text text-anchor="middle" x="687" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Current Cost per Year</text>

    <text text-anchor="middle" x="862" y="390" font-family="Arial, Helvetica, sans-serif" font-size="12" font-weight="bold">Online w/VDP Cost per Year</text>


    <rect width="15" height="15" x="690" y="420" fill="#2a83c1" stroke="none"></rect>
    <text text-anchor="start" x="715" y="432" font-family="Arial, Helvetica, sans-serif" font-size="12">Cost to Create Job Ticket</text>
    <rect width="15" height="15" x="690" y="440" fill="#fed789" stroke="none"></rect>
    <text text-anchor="start" x="715" y="452" font-family="Arial, Helvetica, sans-serif" font-size="12">Cost to Customize Document</text>



  </g>




</svg>
</template>

<script>
export default {
    name: "roi-chart-vdp-comparison",
    props: ["assessment"],
    data: function(){
        return {}
        },
    methods: {
      ceilingVal: function(max){

        let multiplier = 1000;
        if(max < 10000){
          multiplier = 100
        }
        if(max < 1000){
          multiplier = 10
        }
        return Math.ceil((max / 4)/multiplier) * 4 * multiplier;
      },
    },
    computed: {

      hours: function(){


        let amounts = {
          current : {
            design: this.assessment.costs.vdp.orderDetails.designOrder.hoursMonthly * 12,
            jobTickets: this.assessment.costs.vdp.orderDetails.jobTicketVDP.hoursMonthly * 12
          },
          future : {
            design: this.assessment.costs.vdp.orderDetails.designOrder.vdpHoursMonthly * 12,
            jobTickets: this.assessment.costs.vdp.orderDetails.jobTicketVDP.vdpHoursMonthly * 12
          },

        }

        let maxValue = 1000;
        let scale = 300;
        let total = amounts.current.design + amounts.current.jobTickets;

        if(total > maxValue){
          maxValue = total;
        }

        let ceiling = this.ceilingVal(maxValue * 1.1);

        return {
          ceiling: ceiling,
          increment: ceiling / 4,
          data: [
              [

                (amounts.current.jobTickets/ceiling) * scale,
                (amounts.current.design/ceiling) * scale,

              ],
            [

              (amounts.future.jobTickets/ceiling) * scale,
              (amounts.future.design/ceiling) * scale,

            ]
          ],
        }
      },
      cost: function(){


        let amounts = {
          current : {
            design: this.assessment.costs.vdp.orderDetails.designOrder.costMonthly * 12,
            jobTickets: this.assessment.costs.vdp.orderDetails.jobTicketVDP.costMonthly * 12
          },
          future : {
            design: this.assessment.costs.vdp.orderDetails.designOrder.vdpCostMonthly * 12,
            jobTickets: this.assessment.costs.vdp.orderDetails.jobTicketVDP.vdpCostMonthly * 12
          },

        }

        let maxValue = 1000;
        let scale = 300;
        let total = amounts.current.design + amounts.current.jobTickets;

        if(total > maxValue){
          maxValue = total;
        }

        let ceiling = this.ceilingVal(maxValue * 1.1);

        return {
          ceiling: ceiling,
          increment: ceiling / 4,
          data: [
            [

              (amounts.current.jobTickets/ceiling) * scale,
              (amounts.current.design/ceiling) * scale,

            ],
            [

              (amounts.future.jobTickets/ceiling) * scale,
              (amounts.future.design/ceiling) * scale,

            ]
          ],
        }
      },

    }
}
</script>

<style scoped>


.roichart-mispotential {

}

</style>