


<template>
<div class="survey-question relative" >
  <div class="flex items-center">


    <div class="flex-grow relative">
      <div class="absolute w-full h-full bg-transparent p-6 -mt-2" @click="toggleScale"></div>
      <h2 class="font-bold text-lg pr-4"><span @click="toggleScale">{{ num?(num+'. '):''}}{{ question.question }}</span> <svg @click="showInfo" class="relative ml-2 w-6 h-6 inline-block object-contain text-gray-300 hover:text-brand-blue" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g transform="translate(-254.435 -102.076)">
          <circle cx="13.425" cy="13.425" r="13.425" transform="translate(256 104)" fill="#fff"/>
          <path class="fill-current" d="M15.7,10.834A1.41,1.41,0,0,1,17.1,9.428h.938a1.41,1.41,0,0,1,1.406,1.406v.938a1.41,1.41,0,0,1-1.406,1.406H17.1A1.41,1.41,0,0,1,15.7,11.772Zm5.625,13.594h-7.5V22.553H15.7V16.928H13.821V15.053h5.625v7.5h1.875Zm-3.75-22.5a15,15,0,1,0,15,15,15,15,0,0,0-15-15Zm0,27.188A12.188,12.188,0,1,1,29.758,16.928,12.188,12.188,0,0,1,17.571,29.116Z" transform="translate(251.864 100.148)" />
        </g>
      </svg>
      </h2>
    </div>
    <div @click="toggleScale" class="answer flex-shrink-0 relative score mt-1 w-12 h-12 border-4 flex justify-center items-center select-none"  :data-color="answer.answer[answerKey]" :data-pre="(answerKey == 'final')?answer.answer.pre:false">
      <span class="text-2xl font-bold tracking-tighter">{{ answer.answer[answerKey] }}</span>
    </div>

  </div>
  <div class="transition-all duration-300 overflow-hidden" :class="showScale?'opacity-100':'opacity-0 h-0'" >
    <div>
      <p class="text-base font-normal pr-24" v-if="question.description">{{ question.description }}</p>
      <div class="flex w-full relative mt-4 pt-8 pb-4 border-t border-dashed" >
        <div class="w-1/6 pr-4 flex-grow-0 flex-shrink-0 relative select-none cursor-pointer" v-for="(marker, index) in scale" :key="marker" @click="setAnswer(marker)">
          <div class="h-1 w-full bg-gray-200 absolute mt-5" v-if="index < 4"></div>
          <div class="relative w-10 h-10 border-2 flex justify-center items-center bg-white hover:border-gray-400" :class="(answer.answer[answerKey] === marker)?scaleColors[marker]:''"><span class="text-lg text-black font-bold">{{ marker }}</span></div>
          <p class="text-xs font-normal pt-4" v-html="question['criteria' + marker ]"></p>
        </div>
      </div>
    </div>

  </div>
  <div>
    <textarea class="italic text-sm mt-4 bg-gray-100 border w-full p-2" placeholder="Notes" v-if="answer.notes || showScale" v-model="answer.notes"></textarea>
  </div>


</div>
</template>

<script>
export default {
    name: "survey-question",
    props: {
      question: {
        required: true, // { id, question, caption, criteria, num },
      },
      answer: {
        default: ""
      },
      num: {
        required: false,
        default: 0
      },
      notes: {
        default: ""
      },

      showScale: {
        default: false
      },
      answerKey: {
        default: "pre"
      }

    },
    data: function(){
        return {
          scale: ["5", "4", "3", "2", "1", "NA"],

        }
    },
    methods: {
      toggleScale: function(){
        this.$emit('focus', this.question);
      },

      showInfo: function(){
        this.$emit('info', this.question);
      },

      setAnswer: function(value){
        this.$emit("change", {
          value: value,
          question: this.question
        })
      }
    },
    computed: {
      scaleColors: function(){
        return this.$store.getters.scaleColorClasses;
      }
    }
}
</script>

<style scoped>


.survey-question {

}

.survey-question .score::after {
  content: attr(data-pre);
  position: absolute;
  left: -4em;
  width: 3em;
  text-align: right;
  @apply text-gray-400;

}

.answer[data-color="5"] {
  @apply border-brand-green text-brand-green;
}

.answer[data-color="4"] {
  @apply border-brand-green-light text-brand-green-light;
}

.answer[data-color="3"] {
  @apply border-yellow-500 text-yellow-500;
}

.answer[data-color="2"] {
  @apply border-red-500 text-red-500;
}

.answer[data-color="1"] {
  @apply border-red-500 text-red-500;
}





</style>