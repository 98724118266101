


<template>
<div class="customers">

</div>
</template>

<script>
export default {
    name: "customers",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.customers {

}

</style>