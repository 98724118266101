<template>
  <div class="global-header-bar h-16 bg-white border-b relative">
    <div class="flex justify-between items-center h-full">
      <div class="px-8 py-4">
        <img src="../assets/ricoh-logo.svg" class="h-8" />
      </div>
      <div class="relative pr-4 flex items-center" v-if="isAuthorized && hasAccess">
        <div class="relative" v-if="hasAppsView">
          <div class="relative p-2 hover:bg-gray-100 cursor-pointer" @click="toggleAppsMenu">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 fill-current text-brand-blue-dark" width="24" height="24" viewBox="0 0 24 24" >
              <path id="Icon_material-apps" data-name="Icon material-apps" d="M6,12h6V6H6Zm9,18h6V24H15ZM6,30h6V24H6Zm0-9h6V15H6Zm9,0h6V15H15ZM24,6v6h6V6Zm-9,6h6V6H15Zm9,9h6V15H24Zm0,9h6V24H24Z" transform="translate(-6 -6)"/>
            </svg>
          </div>
          <div v-if="showAppsMenu" class="absolute mt-4 border z-50 right-0 bg-white w-72 transform -translate-x-1/2 left-1/2">

            <div class="border-b flex w-full items-center px-6 py-1 cursor-pointer hover:bg-gray-100 hover:underline" @click="showHome" >
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4" width="30" height="25.5" viewBox="0 0 30 25.5">
                <path d="M15,30V21h6v9h7.5V18H33L18,4.5,3,18H7.5V30Z" transform="translate(-3 -4.5)"/>
              </svg>
              <p class="p-2 py-3 ml-2">Home</p>
            </div>

            <div class="border-b flex w-full items-center px-6 py-1 cursor-pointer hover:bg-gray-100 hover:underline" @click="showAdmin" v-if="hasAdminAccess">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4" width="24" height="24" viewBox="0 0 24 24">
                <path d="M18,18a6,6,0,1,0-6-6A6,6,0,0,0,18,18Zm0,3c-4.005,0-12,2.01-12,6v3H30V27C30,23.01,22.005,21,18,21Z" transform="translate(-6 -6)"/>
              </svg>


              <p class="p-2 py-3 ml-2">Admin</p>
            </div>

            <div class="border-b flex w-full items-center px-6 py-1 cursor-pointer hover:bg-gray-100 hover:underline" @click="showAssessments" v-if="hasWorflowAccess">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4" width="27" height="33" viewBox="0 0 27 33">
                <path id="Icon_material-content-paste" data-name="Icon material-content-paste" d="M28.5,3H22.23a4.482,4.482,0,0,0-8.46,0H7.5a3.009,3.009,0,0,0-3,3V30a3.009,3.009,0,0,0,3,3h21a3.009,3.009,0,0,0,3-3V6A3.009,3.009,0,0,0,28.5,3ZM18,3a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,18,3ZM28.5,30H7.5V6h3v4.5h15V6h3Z" transform="translate(-4.5)"/>
                <path id="Path_30" data-name="Path 30" d="M17.438,15.75h16V18h-16Z" transform="translate(-11.759 2.5)"/>
                <path id="Path_31" data-name="Path 31" d="M17.438,20.25h16V22.5h-16Z" transform="translate(-11.759 2.5)"/>
                <path id="Path_32" data-name="Path 32" d="M17.438,15.75h16V18h-16Z" transform="translate(-11.759 -1.5)"/>
              </svg>

              <p class="p-2 py-3 ml-2">Assessments</p>
            </div>


            <div @click="showCalculators" class="border-b flex w-full items-center py-1 px-6 cursor-pointer hover:bg-gray-100 hover:underline" v-if="hasCalculatorAccess">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4" width="29.813" height="27.563" viewBox="0 0 29.813 27.563">
                <path id="Icon_ionic-ios-color-filter" data-name="Icon ionic-ios-color-filter" d="M31.078,17.5a8.879,8.879,0,0,0-4.366-3.066,8.57,8.57,0,0,0,.1-1.343A8.828,8.828,0,0,0,24.23,6.82a8.76,8.76,0,0,0-12.459,0,8.828,8.828,0,0,0-2.58,6.272,9.512,9.512,0,0,0,.1,1.343A8.9,8.9,0,0,0,5.674,29.18,8.77,8.77,0,0,0,18,29.32a8.77,8.77,0,0,0,12.326-.141A8.911,8.911,0,0,0,31.078,17.5ZM18,6.012a7.067,7.067,0,0,1,7.031,7.08,6.716,6.716,0,0,1-.07.991c-.288-.028-.577-.042-.872-.042a8.731,8.731,0,0,0-6.1,2.461,8.718,8.718,0,0,0-6.1-2.461c-.288,0-.584.014-.872.042a7.741,7.741,0,0,1-.07-.991A7.084,7.084,0,0,1,18,6.012Zm0,14.161c-.127,0-.26-.007-.387-.014a6.707,6.707,0,0,1,.387-.78,6.707,6.707,0,0,1,.387.78A3.554,3.554,0,0,1,18,20.173ZM14.723,29.4a6.921,6.921,0,0,1-2.82.591A7.088,7.088,0,0,1,9.731,16.172a8.914,8.914,0,0,0,2.194,3.347,8.753,8.753,0,0,0,3.459,2.046,8.57,8.57,0,0,0-.1,1.343,8.864,8.864,0,0,0,1.561,5.041A7.162,7.162,0,0,1,14.723,29.4Zm1.1-9.57a7.07,7.07,0,0,1-4.31-3.987c.127-.007.26-.014.387-.014a6.945,6.945,0,0,1,2.82.591,7.042,7.042,0,0,1,2.116,1.448A8.94,8.94,0,0,0,15.827,19.828ZM18,26.438a7.1,7.1,0,0,1-.935-3.53,6.716,6.716,0,0,1,.07-.991c.288.028.577.042.872.042s.584-.014.872-.042a7.741,7.741,0,0,1,.07.991A7.267,7.267,0,0,1,18,26.438Zm3.277-10.02a6.921,6.921,0,0,1,2.82-.591c.127,0,.26.007.387.014a7.07,7.07,0,0,1-4.31,3.987,9.1,9.1,0,0,0-1.013-1.962A7.042,7.042,0,0,1,21.277,16.418Zm2.82,13.57a6.945,6.945,0,0,1-2.82-.591,7.042,7.042,0,0,1-2.116-1.448,8.848,8.848,0,0,0,1.561-5.041,9.511,9.511,0,0,0-.1-1.343,8.8,8.8,0,0,0,3.459-2.046,9.029,9.029,0,0,0,2.194-3.347A7.088,7.088,0,0,1,24.1,29.988Z" transform="translate(-3.094 -4.219)"/>
              </svg>


              <p class="p-2 py-3 ml-2">Cost Calculators</p>
            </div>

            <div @click="showPrintShopConfigurator" class="border-b flex w-full items-center py-1 px-6 cursor-pointer hover:bg-gray-100 hover:underline" v-if="hasPrintShopConfigAccess">

              <svg class="w-4" width="24" height="24" viewBox="0 0 24 24">
                <path fill="currentColor" d="M3,5V21H9V19.5H7V18H9V16.5H5V15H9V13.5H7V12H9V10.5H5V9H9V5H10.5V9H12V7H13.5V9H15V5H16.5V9H18V7H19.5V9H21V3H5A2,2 0 0,0 3,5M6,7A1,1 0 0,1 5,6A1,1 0 0,1 6,5A1,1 0 0,1 7,6A1,1 0 0,1 6,7Z" />
              </svg>


              <p class="p-2 py-3 ml-2">Print Shop Configurator</p>
            </div>
            <div @click="showNeedsAnalysis" class="flex w-full items-center py-1 px-6 cursor-pointer hover:bg-gray-100 hover:underline" v-if="hasNeedsAnalysisAccess">

              <svg xmlns="http://www.w3.org/2000/svg" class="w-4" width="31.507" height="29.257" viewBox="0 0 31.507 29.257">
                <g transform="translate(-2.25 -3.375)">
                  <path d="M26.677,12.537l-6.131,9.38a3.34,3.34,0,0,1,.759,2.13,3.375,3.375,0,0,1-6.75,0,3.7,3.7,0,0,1,.056-.626l-4.05-2.355a3.354,3.354,0,0,1-2.341.942,3.394,3.394,0,0,1-1.659-.436L2.25,25.608V29.63a3.01,3.01,0,0,0,3,3h25.5a3.01,3.01,0,0,0,3-3V14.681l-4.12-2.735a3.373,3.373,0,0,1-2.96.591Z"/>
                  <path  d="M8.227,15.258A3.376,3.376,0,0,1,11.6,18.633c0,.148-.014.3-.028.436L15.8,21.431a3.345,3.345,0,0,1,2.138-.759,3.531,3.531,0,0,1,.731.077l6.145-9.52a3.339,3.339,0,0,1-.626-1.955,3.375,3.375,0,0,1,6.75,0,3.668,3.668,0,0,1-.091.795l2.9,1.941V6.377a3.01,3.01,0,0,0-3-3H5.252a3.01,3.01,0,0,0-3,3V22.5l2.813-2.693a3.375,3.375,0,0,1,3.164-4.549Z"/>
                </g>
              </svg>


              <p class="p-2 py-3 ml-2">Needs Analysis</p>
            </div>
          </div>
        </div>

        <div class="user-badge h-12 mx-4 pl-2 relative border-l font-bold">
          <div class="h-full flex opacity-75 hover:opacity-100 justify-center items-center cursor-pointer" @click="toggleUserMenu">
            <p class="text-sm px-2">{{ fullname }}</p>
          </div>

          <div v-if="showUserMenu" class="font-normal absolute mt-4 border z-50 right-0 bg-white w-64">

            <div class="border-b flex w-full items-center px-6 pt-2 cursor-pointer hover:bg-gray-100 hover:underline" @click="editProfile">

              <svg xmlns="http://www.w3.org/2000/svg" class="w-4" width="44.998" height="36.001" viewBox="0 0 44.998 36.001">
                <path id="Icon_awesome-user-edit" data-name="Icon awesome-user-edit" d="M15.75,18a9,9,0,1,0-9-9A9,9,0,0,0,15.75,18Zm6.3,2.25H20.876a12.24,12.24,0,0,1-10.252,0H9.45A9.452,9.452,0,0,0,0,29.7v2.925A3.376,3.376,0,0,0,3.375,36H22.7a3.376,3.376,0,0,1-.183-1.5L23,30.22l.084-.78.555-.555,5.435-5.435a9.354,9.354,0,0,0-7.024-3.2Zm3.185,10.216-.478,4.289a1.119,1.119,0,0,0,1.237,1.237l4.282-.478,9.7-9.7-5.041-5.041-9.7,9.689ZM44.508,18.907l-2.665-2.665a1.685,1.685,0,0,0-2.377,0L36.809,18.9l-.288.288,5.048,5.041,2.939-2.939a1.693,1.693,0,0,0,0-2.384Z"/>
              </svg>

              <p class="p-2 py-3 ml-2">User Profile</p>
            </div>

            <div class="border-b flex w-full items-center px-6 cursor-pointer hover:bg-gray-100 hover:underline" @click="logout">
              <svg xmlns="http://www.w3.org/2000/svg"  class="w-4" width="36" height="31.5" viewBox="0 0 36 31.5">
                <path id="Icon_open-account-logout" data-name="Icon open-account-logout" d="M13.5,0V4.5h18V27h-18v4.5H36V0ZM9,9,0,15.75,9,22.5V18H27V13.5H9Z"/>
              </svg>



              <p class="p-2 py-3 ml-2">Logout</p>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "global-header-bar",
  props: {},
  mounted: function(){

  },
  data: function () {
    return {
      showAppsMenu: false,
      showUserMenu:false,
    }
  },
  methods: {
    editProfile: function(){
      console.log("edit profile");
      this.showUserMenu = false;
      this.$router.push("/profile");
    },

    toggleAppsMenu: function(e){

      console.log("toggleApps");


      this.showAppsMenu = !this.showAppsMenu;
      this.showUserMenu = false;

      if(this.showAppsMenu){
        e.stopPropagation();
        document.addEventListener('click', ()=>{
          this.showAppsMenu = false;
        }, {once: true});
      }
    },

    toggleUserMenu: function(e){


      console.log("toggleUser");
      this.showUserMenu = !this.showUserMenu;
      this.showAppsMenu = false;

        if(this.showUserMenu){

          e.stopPropagation();
          document.addEventListener('click', ()=>{
            this.showUserMenu = false;
            console.log("global");
          }, {once: true});
        }
    },

    logout: function(){
      console.log("logout");
      this.showUserMenu = false;
      this.$store.commit("logout");
      this.$router.push("/login");

    },

    showAdmin: function(){
      this.$router.push({
        name: "Admin"
      });
    },

    showAssessments: function(){
      this.$router.push({
        name: "Assessments"
      });
    },

    showPrintShopConfigurator: function(){
      this.$router.push({
        name: "PrintShopConfigurator"
      });
    },

    showNeedsAnalysis: function(){
      this.$router.push({
        name: "NeedsAnalysis"
      });
    },

    showHome: function(){
      this.$router.push({
        name: "Home"
      });
    },

    showCalculators: function(){
      this.$router.push({
        name: "Calculators"
      });
    }
  },
  computed: {

    profile: function(){
      return this.$store.getters.getUserProfile;
    },


    hasWorflowAccess: function(){
      return this.$store.getters.hasWorkflowAccess
    },

    hasCalculatorAccess: function(){
      return this.$store.getters.hasCalculatorAccess
    },

    hasPrintShopConfigAccess: function(){
      return this.$store.getters.hasPrintShopConfigAccess
    },

    hasNeedsAnalysisAccess: function(){
      return this.$store.getters.hasNeedsAnalysisAccess
    },

    hasAppsView: function() {
      return (this.$store.getters.isAdmin || this.$store.getters.hasCalculatorAccess || this.$store.getters.hasWorkflowAccess || this.$store.getters.hasPrintShopConfigAccess || this.$store.getters.hasNeedsAnalysisAccess);
    },

    fullname: function(){
      if(this.profile){
        return ((this.profile.firstname?this.profile.firstname:"") + " " + (this.profile.lastname?this.profile.lastname:""));
      }

      return ""
    },

    isAuthorized: function(){
      return this.$store.state.authorized;
    },

    isAdmin: function(){
      return this.$store.getters.isAdmin;
    },

    hasAdminAccess: function(){
      return this.$store.getters.hasAdminAccess;
    },

    hasAccess: function(){
      return this.$store.getters.hasAccess
    }

  }
}
</script>

<style scoped>


.global-header-bar {

}

</style>
