<template>
  <div class="">
    <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Personnel Expenses - All</h2>
      <div class="text-xs">
        <button class="button border-gray-400 mr-4" @click="save"><img class="-ml-2 cursor-pointer opacity-50 hover:opacity-100 h-3 inline-block transform rotate-180 mr-1" src="../../assets/icons/next-arrow-icon-black.svg" /> Back to Rates</button>
      </div>
    </div>
    <div class="p-8 pt-4">
      <div class="text-sm border-b border-brand-blue mb-2">
        <span class="inline-block pb-1 mr-8 cursor-pointer hover:text-brand-blue hover:opacity-100" :class="personnel.summarized?'opacity-50':'border-b-4 border-brand-blue font-bold text-brand-blue'" @click="setSummarized(false)">Use Itemized Calculation</span>
        <span class="inline-block pb-1 cursor-pointer hover:text-brand-blue hover:opacity-100" :class="personnel.summarized?'border-b-4 border-brand-blue font-bold text-brand-blue':'opacity-50'" @click="setSummarized(true)">Use Summarized Value</span>
      </div>

      <div class="" v-if="personnel.summarized">
        <p class="flex items-center justify-between pt-2 text-sm field">
          <label>Total Personnel Overhead</label>
          <span class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="personnel.total" format="$" precision="0" @change="onChange"></number-input>
          </span>
        </p>
      </div>


      <div class="fields pt-2" v-else >


        <p class="flex items-center justify-between pt-0 text-sm field" v-for="(field,index) in fields" :key="index">
          <label v-html="field.label" :class="field.key?'':'pt-4'"></label>
          <span v-if="field.key" class="input w-48 border-b hover:border-black">
            <number-input class="w-full p-1 px-2 mt-1 rounded text-right bg-transparent focus:bg-white" v-model="personnel.details[field.key]" format="$" precision="0" @change="onChange"></number-input>
          </span>
        </p>

        <div class="total flex items-center justify-between mt-4">
          <label class="font-bold">Total Personnel Overhead</label>
          <div class="w-48">
            <span class="block w-full px-2 text-right font-bold">{{ personnel.total | currency(1) }}</span>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import NumberInput from "@/components/NumberInput";
export default {
  name: "side-panel-rates-personnel",
  components: {NumberInput},
  props: ["rates"],
  mounted: function(){
    this.hydrate();
  },
  data: function () {
    return {
      personnel: {
        details: {
          payroll: 0,
          medical: 0,
          life: 0,
          workerscomp: 0,
          retirement: 0,
          training: 0,
          education: 0,
          awards: 0,
          bonus: 0,
          other: 0,
          salaries: 0
        },
        total: 0,
        summarized: false
      },
    }
  },
  methods: {
    setSummarized: function(summarize){
      if(!summarize){

        let currentTotal = Object.keys(this.personnel.details).reduce((total, key) => {
          let value = this.personnel.details[key];
          if (value) {
            total += value;
          }
          return total;
        }, 0)

        if(currentTotal != this.personnel.total){
          if(confirm("Switching to itemized will calculate your total based on the available itemized expense values. Do you want to switch to an itemized calculation?")){
            this.personnel.summarized = summarize;
            this.calculate();
          }
        }else{
          this.personnel.summarized = summarize;
          this.calculate();
        }

      }else{
        this.personnel.summarized = summarize;
        this.calculate();
      }

    },

    close: function(){
      this.$emit('close');
    },

    cancel: function(){
      this.$emit('close');
    },

    save: function(){
      Object.keys(this.personnel.details).forEach((key) => {
         this.rates.overhead.personnel.details[key] = this.personnel.details[key];
      })
      this.rates.overhead.personnel.total = this.personnel.total;
      this.rates.overhead.personnel.summarized = this.personnel.summarized;
      this.$emit('change');
      this.$emit('close');
    },

    hydrate: function(){

      this.personnel.summarized = this.rates.overhead.personnel.summarized;
      Object.keys(this.personnel.details).forEach((key) => {
        this.personnel.details[key] = this.rates.overhead.personnel.details[key];
      })
      this.personnel.total = this.rates.overhead.personnel.total;


    },

    onChange: function(){
      this.calculate();
    },

    calculate: function(){

      if(!this.personnel.summarized) {
        this.personnel.total = Object.keys(this.personnel.details).reduce((total, key) => {
          let value = this.personnel.details[key];
          if (value) {
            total += value;
          }
          return total;
        }, 0)
      }
    }

  },
  computed: {
    fields: function(){
      return [
        { label: "Payroll Taxes", key: "payroll" },
        { label: "Medical Insurance Benefits", key: "medical" },
        { label: "Life Insurance Benefits", key: "life" },
        { label: "Workers Comp", key: "workerscomp" },
        { label: "401k/Retirement Benefits", key: "retirement" },
        { label: "", key: "" },
        { label: "Training/Conference", key: "training" },
        { label: "Educational Assistance", key: "education" },
        { label: "Employee Appreciation/Awards", key: "awards" },
        { label: "Bonus Expense", key: "bonus" },
        { label: "Other Expenses", key: "other" },
        { label: "", key: "" },
        { label: "Non-Production Salaries<br><small>(Management, Administration and Sales - exclude Production employees)</small>", key: "salaries" },
      ]
    }
  }
}
</script>




<style scoped>


.side-panel-new-assessment {

}

.fields p:last-of-type {
  @apply border-black border-b pb-2;
}

.fields p:last-of-type .input {
  border-color: transparent;
}


</style>