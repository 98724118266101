


<template>
<div class="question" :data-inactive="!answer.answer.final">
  <div class="w-full flex items-center">
    <div class="pr-8 flex-grow">

      <label class="pt-2 inline-block">{{ question.question }}</label>
    </div>
    <div class="flex-shrink-0 flex-grow-0 text-right">

      <input class="mt-1 mr-2" type="checkbox" v-model="answer.answer.final" @change="onChange" />

      <label class="yes-label">Check For YES</label>

    </div>
  </div>
</div>
</template>

<script>

export default {
    name: "assessment-interview-question-boolean",
  components: {},
  props: {
      question: {
        required: true
      },
      answer: {
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {
       onChange: function(e){
         this.$emit("change", e);
       }
    },
    computed: {

    }
}
</script>

<style scoped>


.question[data-inactive="true"] .yes-label {
  color: gray;
}

</style>