<template>

        <svg class="w-full"  ref="chart" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" viewBox="0 0 200 190">

          <rect x="0" y="0" height="210" width="200" fill="white"></rect>
          <text font-size="8" font-family="Arial, Helvetica, sans-serif" class="svg-text-title" x="100" y="12" text-anchor="middle">{{ title }}</text>
          <path d="M 18 20 L 180 20"  fill="none" stroke="black" stroke-width="0.2"/>
          <path d="M 18 40 L 180 40"  fill="none" stroke="black" stroke-width="0.2"/>
          <path d="M 18 60 L 180 60"  fill="none" stroke="black" stroke-width="0.2"/>
          <path d="M 18 80 L 180 80"  fill="none" stroke="black" stroke-width="0.2"/>
          <path d="M 18 100 L 180 100"  fill="none" stroke="black" stroke-width="0.2"/>

          <g v-for="row in chartCols" :key="row.id">
              <rect :x="row.x" :y="row.y" :height="row.height" :width="row.width" :fill="row.barColor"  />

            <text font-size="4" font-family="Arial, Helvetica, sans-serif" class="svg-text-col" text-anchor="middle" :x="row.x+3" :y="row.y - 2" fill="#888" v-if="!row.value">NA</text>
              <text font-size="4" font-family="Arial, Helvetica, sans-serif" class="svg-text-label" text-anchor="end" :transform="'rotate(-60,'+(row.x + 4)+',124)'" :x="row.x+5" y="123.5" fill="#000">{{  row.label  }}</text>
          </g>

          <path d="M 20 20 L 20 120 180 120 180 20"  fill="none" stroke="black" stroke-width="0.5"/>


          <text font-size="4" font-family="Arial, Helvetica, sans-serif" class="svg-text-tick" x="13" y="22">5</text>
          <text font-size="4" font-family="Arial, Helvetica, sans-serif" class="svg-text-tick" x="13" y="42">4</text>
          <text font-size="4" font-family="Arial, Helvetica, sans-serif" class="svg-text-tick" x="13" y="62">3</text>
          <text font-size="4" font-family="Arial, Helvetica, sans-serif" class="svg-text-tick" x="13" y="82">2</text>
          <text font-size="4" font-family="Arial, Helvetica, sans-serif" class="svg-text-tick" x="13" y="102">1</text>
          <text font-size="4" font-family="Arial, Helvetica, sans-serif" class="svg-text-tick" x="13" y="122">0</text>

          <g>
            <rect width="190" height="20"  x="5" y="165" stroke="black" fill="none" stroke-width="0.1"></rect>
            <text font-size="10" font-weight="bold" text-anchor="middle" font-family="Arial, Helvetica, sans-serif" x="17" y="176">{{ assessment.scorecard[answerKey] }}</text>
            <text font-size="4" font-weight="bold" text-anchor="middle" font-family="Arial, Helvetica, sans-serif" x="17" y="181">SCORE</text>
            <line x1="28.5" y1="168" x2="28.5" y2="182" stroke-width="0.1" stroke="black"></line>
            <text font-size="3" text-anchor="middle" font-family="Arial, Helvetica, sans-serif" x="44.5" y="176">4.5+ Best-in-Class</text>
            <text font-size="3" text-anchor="middle" font-family="Arial, Helvetica, sans-serif" x="76" y="176">3.5 - 4.5 Very Good</text>
            <text font-size="3" text-anchor="middle" font-family="Arial, Helvetica, sans-serif" x="106" y="176">2.5 - 3.5 Good</text>
            <text font-size="3" text-anchor="middle" font-family="Arial, Helvetica, sans-serif" x="135" y="176">1.5 - 2.5 Sufficient</text>
            <text font-size="3" text-anchor="middle" font-family="Arial, Helvetica, sans-serif" x="172" y="176">0 - 1.5 Sub-Standard</text>
          </g>






        </svg>

</template>

<script>
export default {
    name: "assessment-scorecard",
    props: {
      title: {
        default: "Production Workflow Scorecard"
      },
      answerKey: {
        default: "pre"
      },
      assessment: {
        required: true
      },
      questions: { //survey questions
        required: true
      },
      options: {
        default: function(){
          return {
            viewBoxWidth: 200,
            viewBoxHeight: 200,
          }
        }
      }
    },
    data: function(){
        return {

        }
    },
    methods: {

      exportToPng: function(){
        let svg = this.$refs.chart;
        if(svg){
          let svgXML = (new XMLSerializer()).serializeToString(svg);
          let canvas = this.$refs.canvas;
          let ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          let image = new Image();
          image.src = "data:image/svg+xml;base64," + btoa(svgXML);

          image.onload = function(){
            ctx.drawImage(image, 0, 0);
          }

        }
      },
      exportToPngBlob: function(){
        let svg = this.$refs.chart;
        if(svg){
          let svgXML = (new XMLSerializer()).serializeToString(svg);
          let canvas = this.$refs.canvas;
          let ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          let image = new Image();
          image.src = "data:image/svg+xml;base64," + btoa(svgXML);

          image.onload = function(){
            ctx.drawImage(image, 0, 0);
          }

        }
      }
    },
    computed: {

      scaleColors: function(){
        return this.$store.getters.scaleColors;
      },

      chartBox: function(){
        return {
          x: 20,
          y: 20,
          height: 100,
          width: 160
        }
      },


      answers: function(){
        return this.assessment.survey.questions;
      },

      answerData: function(){
        if(this.questions){
          return Object.keys(this.answers).map((qId) => {
            console.log("answerData", qId)
            return {
              id: qId,
              label: (this.questions[qId]?.shortLabel)?this.questions[qId].shortLabel:this.questions[qId].question,
              pre: this.answers[qId].answer.pre,
              final: this.answers[qId].answer.final,
            }
          })
        }
        return []
      },

      chartCols: function(){
        let cols = this.answerData.length;
        let colWidth = this.chartBox.width / cols;
        let barWidth = 6;
        let barXOffset = (colWidth - barWidth) / 2.0;
        return this.answerData.map((answer, index) => {

            let answerValue = (answer[this.answerKey] && (answer[this.answerKey] !== "NA"))?parseInt(answer[this.answerKey]):0;

            let barHeight = this.chartBox.height * answerValue/5.0;
            let y = this.chartBox.y + (this.chartBox.height - barHeight);
            let x = this.chartBox.x + ((index * colWidth) + barXOffset);
            return {
              label: answer.label,
              id: answer.id,
              value: answerValue,
              height: barHeight,
              width: barWidth,
              x: x,
              y: y,
              barColor: this.scaleColors[answer[this.answerKey]],
              textColor: answerValue?"black":"#ccc"
          }
        })
      },


    }
}
</script>

<style scoped>


.assessment-scorecard {

}

.svg-text-title {
  font-size: 7px;
}

.svg-text-tick {
  font-size: 6px;
}

.svg-text-label {
  font-size: 4px;
}

.svg-text-col {
  font-size: 3px;
}

</style>
